import { createSelector } from 'reselect';
import getMinimumOrderInformation from './getMinimumOrderInformation';
import getOrderMinimumSpendingInclusive from './getOrderMinimumSpendingInclusive';
import getSelectedOffer from './getSelectedOffer';
import getStagedPurchasesTotals from './getStagedPurchasesTotals';
import getRewardsAccrualRate from './getRewardsAccrualRate';
import { getChoiceOrderingMethod, getCouponOrderingMethod } from './config';
import getStagedPurchases from './getStagedPurchases';
import { calculateCouponDiscountedPrice } from '../utils/ordering/calculateCouponDiscount';

const get = (
  minOrderInfo: MinimumOrderInformation | null,
  selectedOffer: Offer | undefined,
  couponDiscountInclusive: boolean,
  stagedPurchasesTotals: PurchaseTotals,
  couponOrderingMethod: ChoiceOrderingMethod | null,
  choiceOrderingMethod: ChoiceOrderingMethod | null,
  stagedPurchases: PurchaseWithTotals[],
  rewardsAccrualRate: number,
): number => {
  const miniSpending = minOrderInfo?.amount || 0;

  let amount = Math.max(0, miniSpending - stagedPurchasesTotals.moneyPrice);

  if (selectedOffer) {
    const discountMoneyPrice = calculateCouponDiscountedPrice(
      selectedOffer as Offer,
      stagedPurchasesTotals,
      stagedPurchases,
      rewardsAccrualRate,
      couponOrderingMethod ?? 'expensive',
      choiceOrderingMethod ?? 'cheapest',
    );

    amount = Math.max(
      0,
      miniSpending -
        (couponDiscountInclusive
          ? discountMoneyPrice
          : stagedPurchasesTotals.moneyPrice),
    );
  }

  return amount;
};
export default createSelector(
  [
    getMinimumOrderInformation,
    getSelectedOffer,
    getOrderMinimumSpendingInclusive,
    getStagedPurchasesTotals,
    getCouponOrderingMethod,
    getChoiceOrderingMethod,
    getStagedPurchases,
    getRewardsAccrualRate,
  ],
  get,
);

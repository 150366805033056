import { STOCK_BALANCE_THRESHOLDS } from '../../constants';

export default function (balance, stockBalanceThresholds) {
  let threshold;

  switch (true) {
    case balance <= 0 ||
      balance <= stockBalanceThresholds?.stockBalanceThreshold0: {
      threshold = STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0;
      break;
    }
    case balance <= stockBalanceThresholds?.stockBalanceThreshold1: {
      threshold = STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_1;
      break;
    }
    case balance <= stockBalanceThresholds?.stockBalanceThreshold2: {
      threshold = STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_2;
      break;
    }
    case balance <= stockBalanceThresholds?.stockBalanceThreshold3: {
      threshold = STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_3;
      break;
    }
    case balance <= stockBalanceThresholds?.stockBalanceThreshold4: {
      threshold = STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_4;
      break;
    }
    default: {
      threshold = null;
      break;
    }
  }

  return threshold;
}

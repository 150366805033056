import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as CouponOrderingMethod | null;

const slice = createSlice({
  name: '$couponOrderingMethod',
  initialState,
  reducers: {
    setCouponOrderingMethod(
      state,
      action: PayloadAction<CouponOrderingMethod | null>,
    ) {
      return action.payload;
    },
  },
});

export const { setCouponOrderingMethod } = slice.actions;
export default slice.reducer;

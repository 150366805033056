import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import lodash from 'lodash';
import setLocalValue from '../utils/setLocalValue';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { logEvent } from '../utils/analytics';
import { RESET_PASSWORD_MODAL_ID } from '../modals/ResetPasswordModal';
import { IN_DEVELOPMENT } from '../constants';
import EVENTS from '../constants/events';
import redcatApiFetch from '../api';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { syncMember } from '../thunks/syncMember';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { useTranslation } from 'react-i18next';
import StandardButton from '../components/StandardButton';
import { REGISTER_MODAL_ID } from '../modals/RegisterModal';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import FormTextField from '../components/FormTextField';
import { OrderingSelectors } from 'polygon-ordering';
import i18next from 'i18next';
import OAuthlogin from './OAuthLogin';
import ScreenLink from './ScreenLink';

export const SIGN_IN_MODAL_ID = 'SIGN_IN_MODAL_ID';
const { getGuestOrderingAvailable, getMember } = OrderingSelectors;

const login = (
  { email, password, reCaptchaToken, mfaToken, mfaCode }: LoginParams,
  successCallback: (result: any) => void,
  finallyCallback: () => void,
  mfaCallback: (token: string) => void,
) => {
  let skipFinally = false;

  redcatApiFetch({
    path: '/api/v1/login',
    method: 'POST',
    body: {
      username: email,
      psw: password,
      'g-recaptcha-response': reCaptchaToken,
      tfa_pin: mfaCode,
      tfa_token: mfaToken,
      auth_type: 'M',
      save_session: '1',
      next: '/',
    },
  })
    .then(result => {
      skipFinally = true;
      logEvent(EVENTS.SIGN_IN);
      successCallback(result);
    })
    .catch(e => {
      const data = lodash.get(e, 'details.json', {});
      if (data.error_code === 3) {
        enqueueSuccessSnackbar(i18next.t('snackbarMessages.verifyPinRequired'));
        mfaCallback(data.additional_info);
      } else {
        logEvent(EVENTS.SIGN_IN_FAILED);
        enqueueErrorSnackbar(e);
      }
    })
    .then(() => {
      if (!skipFinally) {
        finallyCallback();
      }
    });
};

const SignInForm: React.FC<{ onSuccess?: () => void }> = ({ onSuccess }) => {
  const [reCaptchaToken, setReCaptchaToken] = useState<null | string>(null);
  const [mfaToken, setMfaToken] = useState<null | string>(null);
  const { t } = useTranslation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const enableReCaptcha = useAppSelector(state => state.config.enableReCaptcha);
  const reCaptchaSiteKey = useAppSelector(state => state.config.reCaptchaSiteKey);
  const member = useAppSelector(getMember);

  useEffect(() => {
    if (member) {
      dispatch(setCurrentModal(null));
    }
  }, [member]);

  const dispatch = useAppDispatch();
  const guestOrderingEnabled = useAppSelector(getGuestOrderingAvailable);

  const schemas: SchemaType = {
    email: Yup.string().email(t('form.emailValidation')).required(t('form.passwordTooShort')),
    password: Yup.string().required(t('form.required')).min(2, t('form.passwordTooShort')),
  };

  if (mfaToken) {
    schemas.mfaCode = Yup.string().required(t('form.required')).min(4, t('form.passwordTooShort'));
  }

  const validationSchema = Yup.object().shape(schemas);

  const onSubmit = (
    { email, password, mfaCode }: { email: string; password: string; mfaCode: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    const params: LoginParams = {
      email,
      password,
      mfaToken,
      mfaCode,
    };

    if (enableReCaptcha && reCaptchaToken) {
      params.reCaptchaToken = reCaptchaToken;
    }

    login(
      params,
      result => {
        dispatch(setCurrentModal(null));
        if (IN_DEVELOPMENT && result.token) {
          setLocalValue('memberAuthToken', result.token);
        }

        dispatch(syncMember());

        onSuccess?.();
      },
      () => {
        setReCaptchaToken(null);
        setSubmitting(false);
      },
      mfaToken => setMfaToken(mfaToken),
    );
  };

  return (
    <div style={styles.innerContainer}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ email: '', password: '', mfaCode: '' }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, submitForm, handleChange, setFieldValue, isValid }) => {
          return (
            <Form onSubmit={handleSubmit} style={styles.form}>
              <FormTextField
                name="email"
                type="email"
                required
                label={t('field.signIn.email')}
                onChange={e => {
                  setMfaToken(null);
                  setFieldValue('mfaCode', '');
                  handleChange(e);
                }}
                autoFocus
              />

              <FormTextField
                name="password"
                type="password"
                required
                label={t('field.signIn.password')}
                onChange={e => {
                  setMfaToken(null);
                  setFieldValue('mfaCode', '');
                  handleChange(e);
                }}
              />
              {Boolean(mfaToken) && (
                <FormTextField
                  id="mfaCode"
                  name="mfaCode"
                  type="mfaCode"
                  label={t('field.signIn.mfaCode')}
                  required
                  onChange={handleChange}
                />
              )}

              <div>
                <ScreenLink
                  label={t('link.forgotPassword.prefix')}
                  actionLabel={t('link.forgotPassword.action')}
                  url="#"
                  onClick={() => dispatch(setCurrentModal(RESET_PASSWORD_MODAL_ID))}
                  themeKeyLabel="forgotPasswordLinkText"
                  themeKeyActionLabel="forgotPasswordLinkTextRight"
                />
              </div>

              {enableReCaptcha && !reCaptchaToken && (
                <div style={styles.captchaContainer}>
                  <ReCAPTCHA
                    sitekey={reCaptchaSiteKey!}
                    onChange={token => setReCaptchaToken(token)}
                    onErrored={() => {
                      setReCaptchaToken(null);
                      enqueueErrorSnackbar(t('problemWithReCaptchaMessage'));
                    }}
                    size={deviceTypeMobile ? 'compact' : 'normal'}
                  />
                </div>
              )}

              <StandardButton
                label={mfaToken ? t('button.signInWithCode') : t('button.signIn')}
                onClick={() => submitForm()}
                disabled={!isValid || isSubmitting || (enableReCaptcha && !reCaptchaToken)}
                showSpinner={isSubmitting}
                containerStyle={styles.loginButton}
                themeKey="signInSubmitButton"
              />

              <input type="submit" style={styles.hiddenSubmitButton} />
            </Form>
          );
        }}
      </Formik>

      <div style={styles.linkSection}>
        <ScreenLink
          label={t('link.register.prefix')}
          actionLabel={t('link.register.action')}
          url="#"
          onClick={() => dispatch(setCurrentModal(REGISTER_MODAL_ID))}
          themeKeyLabel="registerLinkText"
          themeKeyActionLabel="registerLinkTextRight"
        />
      </div>

      <OAuthlogin />

      {guestOrderingEnabled && (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <ScreenLink
            label={t('link.guestSignIn.prefix')}
            actionLabel={t('link.guestSignIn.action')}
            url={'#'}
            onClick={() => {
              dispatch(setCurrentModal(null));
            }}
            themeKeyLabel="guestLinkText"
            themeKeyActionLabel="guestLinkTextRight"
          />
        </div>
      )}
    </div>
  );
};

export default SignInForm;

const styles: Styles = {
  // do this otherwise any children without a minSize will
  // be squashed if there isn't room (instead of overflow scroll)
  innerContainer: {
    minHeight: 'min-content',
  },
  linkSection: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none',
    marginTop: 10,
    textAlign: 'center',
    fontSize: 14,
  },
  linkLeft: {
    fontFamily: 'inherit',
  },
  linkRight: {
    fontFamily: 'inherit',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  loginButton: {
    marginTop: 20,
    height: 40,
    borderRadius: 5,
  },

  // required to stop iOS from adding a broken "GO" button to the keyboard
  hiddenSubmitButton: {
    visibility: 'hidden',
    position: 'absolute',
  },

  captchaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    // maxWidth: 260,
    marginTop: 15,
  },
  googleButtonContainer: {
    marginTop: 35,
    width: '100%',
  },
  googleButton: {
    width: '100%',
    backgroundColor: 'white',
    fontSize: 16,
    borderRadius: '4px',
  },
  facebookButtonContainer: {
    marginTop: 10,
  },
  facebookIcon: {
    color: 'blue',
    marginRight: 10,
    fontSize: '18px',
    verticalAlign: 'middle',
  },
  appleButtonContainer: {
    marginTop: 10,
    width: '100%',
  },
  appleButton: {
    width: '100%',
    backgroundColor: 'white',
    padding: 10,
    border: '1px solid black',
    borderRadius: '4px',
    fontSize: '16px',
    lineHeight: '16px',
    cursor: 'pointer',
  },
  appleIcon: {
    marginRight: 10,
    fontSize: '18px',
    marginBottom: -2,
  },
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearBuffer } from '../../actions/buffer';

const initialState = null as Menu | null;

const slice = createSlice({
  name: '$bufferMenu',
  initialState,
  reducers: {
    setBufferMenu(state, action: PayloadAction<Menu | null>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(clearBuffer, (state, action) => {
      return initialState;
    });
  },
});

export const { setBufferMenu } = slice.actions;
export default slice.reducer;

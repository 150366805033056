import React from 'react';
import { RiSearchLine } from 'react-icons/ri';

import getThemeLookup from '../selectors/getThemeLookup';

import combineStyles from '../utils/combineStyles';

import TouchableOpacity from './TouchableOpacity';
import { useAppSelector } from '../app/hooks';

interface IProps {
  toggleSearchActive: () => void;
  containerStyle?: React.CSSProperties;
}

const MenuSearchButton: React.FC<IProps> = ({ toggleSearchActive, containerStyle }) => {
  const p = useAppSelector(getThemeLookup);
  const disableMenuSearch = useAppSelector(state => state.config.disableMenuSearch);
  if (disableMenuSearch) {
    return null;
  }

  const color = combineStyles(
    p('categoryBarLabel', ['color']),
    p('menuSearchIcon', ['color']),
  ).color;

  return (
    <div style={combineStyles(styles.wrapper, containerStyle)}>
      <TouchableOpacity onClick={toggleSearchActive} ariaLabel="search">
        <RiSearchLine style={combineStyles(p('menuSearchIcon', ['fontSize']), { color })} />
      </TouchableOpacity>
    </div>
  );
};

const styles: Styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    // paddingLeft: 7,
    paddingRight: 10,
    // paddingTop: 10,
    // paddingBottom: 10,
  },
};

export default MenuSearchButton;

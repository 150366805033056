import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = 'GST';

const slice = createSlice({
  name: 'taxName',
  initialState,
  reducers: {
    setTaxName(state, action: PayloadAction<string>) {
      return action.payload;
    },
  },
});

export const { setTaxName } = slice.actions;

export default slice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import createFavouriteItemFromShadowItem, {
  filterSystemPLUs,
} from '../utils/ordering/createFavouriteItemFromShadowItem';
import { fetchFavouriteOrders } from './fetchFavouriteOrders';
import Api, { FetchParams } from '../utils/Api';

export const editFavouriteOrder = createAsyncThunk(
  '$editFavouriteOrder',
  async (
    data: { id: string; name: string; items?: ShadowItem[] },
    { dispatch, rejectWithValue },
  ) => {
    const { id, name, items } = data;

    try {
      let body: any = {};

      if (!name && !items) {
        throw new Error('invalid arguments');
      }

      if (name) {
        body.Description = name;
      }

      if (items) {
        body.Items = filterSystemPLUs(
          items.map(shadowItem =>
            createFavouriteItemFromShadowItem(shadowItem),
          ),
        );
      }

      const params: FetchParams = {
        path: `/api/v1/profile/favourites/${id}`,
        method: 'PUT',
        body,
      };

      await Api.fetch(params, 'member');
      await dispatch(fetchFavouriteOrders({}));
    } catch (e) {
      console.error('Edit favourite order failed', { e });
      return rejectWithValue(e);
    }
  },
);

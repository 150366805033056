import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderingOperations } from 'polygon-ordering';
import { tyroWrapper } from './tyroPayments';
import { FAILURE_REASON } from '../constants/failureReasons';

export const cancelOrderInProgress = createAsyncThunk(
  '$cancelOrderInProgress',
  async (data: undefined, { rejectWithValue, dispatch }) => {
    try {
      tyroWrapper.cancelCurrentTransaction();
      const reason = FAILURE_REASON.PAYMENT_CANCELLED;

      throw reason;
    } catch (e) {
      console.error('cancelOrderInProgress failed', { e });
      return rejectWithValue(e);
    }
  },
);

import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { Formik } from 'formik';
import FormTextField from '../components/FormTextField';
import FormSelect from '../components/FormSelect';
import history from '../history';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import getThemeLookup from '../selectors/getThemeLookup';
import Text from '../components/Text';
import lodash from 'lodash';
import SendVerificationButton from '../components/SendVerificationButton';
import getProfile from '../selectors/getProfile';

import { OrderingSelectors } from 'polygon-ordering';
const { getLocationsForDisplay } = OrderingSelectors;

export const CLAIM_PREVIOUS_POINTS_ROUTE = '/claim-previous-points';

const ClaimPreviousPointsScreen = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();

  const [submitting, setSubmitting] = useState(false);

  const captchaRef = useRef<ReCAPTCHA>(null);

  const locationsMasterList = useAppSelector(getLocationsForDisplay);
  const locations = lodash.orderBy(
    Object.entries(locationsMasterList ?? {}).map(([k, location]) => ({
      name: location.name,
      id: k,
    })),
    'name',
    'asc',
  );

  const reCaptchaSiteKey = useAppSelector(state => state.config.reCaptchaSiteKey);
  const enableReCaptcha = useAppSelector(state => state.config.enableReCaptcha);

  const profile = useAppSelector(getProfile);

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        {!profile?.verified ? (
          <div style={{ padding: '3em 0px', textAlign: 'center' }}>
            <Text value={t('CardIfNotVerified')} style={{ fontSize: 16 }} />
            <SendVerificationButton containerStyle={{ paddingTop: 20 }} />
          </div>
        ) : (
          <Formik
            initialValues={{ memberNo: '', points: '', lastStore: '', grecaptcha: '' }}
            validationSchema={Yup.object({
              memberNo: Yup.string().required(t('form.required')),
              points: Yup.number().typeError('Number required').required(t('form.required')),
              lastStore: Yup.string().required(t('form.required')),
              grecaptcha: enableReCaptcha ? Yup.string().required('Required') : Yup.string(),
            })}
            onSubmit={(values, { setFieldValue }) => {
              let success = false;
              let message: null | string = null;

              setSubmitting(true);

              RedcatApiHandler.authorisedFetch({
                path: '/api/v1/member/migrate/points',
                method: 'POST',
                body: {
                  identifier: values.memberNo,
                  last_store: values.lastStore,
                  points: values.points,
                  ['g-recaptcha-response']: values.grecaptcha,
                },
              })
                .then(res => {
                  if (res.success) {
                    success = true;
                    enqueueSuccessSnackbar(t('snackbarMessages.pointsClaimed'));
                    history.replace('/');
                  } else {
                    message = res.message;
                  }
                })
                .catch(e => {
                  message = e.message;
                })
                .finally(() => {
                  captchaRef.current?.reset();
                  setSubmitting(false);
                  setFieldValue('grecaptcha', '');
                  if (success) return;

                   enqueueErrorSnackbar(message ?? t('snackbarMessages.cannotCompleteVerification'));
                });
            }}
          >
            {({ setFieldValue, submitForm, handleChange, values, isValid }) => {
              return (
                <>
                  <div style={styles.formFieldsContainer}>
                    <Text
                      style={styles.text}
                      value={t('loyaltyMigration.claimPointsPage.subtitle')}
                    />
                    {/* form fields */}
                    <FormTextField
                      name={'memberNo'}
                      type={'text'}
                      label={t(`field.claimsReceipt.memberNo`)}
                      shrinkLabel={!!values.memberNo}
                      onChange={handleChange}
                      disableStyles
                    />
                    <FormTextField
                      name={'points'}
                      type={'text'}
                      label={t(`field.claimsReceipt.pointsBalance`)}
                      shrinkLabel={!!values.points}
                      onChange={handleChange}
                      disableStyles
                    />
                    <FormSelect
                      name={'lastStore'}
                      label={t(`field.claimsReceipt.lastStoreVisited`)}
                      onChange={handleChange}
                      shrinkLabel={!!values.lastStore}
                      menuItems={locations}
                      disableStyles
                    />
                  </div>
                  {/* captcha */}
                  {enableReCaptcha && !!reCaptchaSiteKey && (
                    <div style={styles.row}>
                      <ReCAPTCHA
                        ref={captchaRef}
                        sitekey={reCaptchaSiteKey}
                        onChange={t => setFieldValue('grecaptcha', t)}
                      />
                    </div>
                  )}

                  {/* submit button */}
                  <div style={styles.submitButtonsContainer}>
                    <StandardButton
                      label={t('loyaltyMigration.claimPointsPage.submitButton')}
                      themeKey="migrationFormButton"
                      onClick={submitForm}
                      disabled={!isValid || submitting}
                      showSpinner={submitting}
                    />
                  </div>
                </>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
};

const styles: Styles = {
  pageContainer: {
    marginTop: 30,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 30,
  },
  formFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 20,
  },
  submitButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 20,
  },
  text: {
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
};

export default ClaimPreviousPointsScreen;

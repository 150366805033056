export default function createIngredientFromChoice(
  choice: ChoiceWithQuantity | NestedChoiceWithQuantity,
  ChoiceSetID: string,
  Ingredients?: CartIngredient[],
): CartIngredient {
  return {
    Price: choice.baseMoneyPrice,
    PLUCode: parseInt(choice.plucode),
    ProductID: parseInt(choice.id),
    Qty: choice.quantity,
    Ingredients: Ingredients ? Ingredients : [],
    ChoicesetID: parseInt(ChoiceSetID),
  };
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { TEXT_PROPERTIES } from '../utils/theme';
import Text from './Text';
import combineStyles from '../utils/combineStyles';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;

const Line = ({
  label,
  amount,
  deemphasised = false,
  emphasised = false,
  skipFormatting,
  hideSymbol = false,
}: {
  label: string;
  amount: number | string;
  deemphasised?: boolean;
  emphasised?: boolean;
  skipFormatting?: boolean;
  hideSymbol?: boolean;
}) => {
  const p = useAppSelector(getThemeLookup);
  const value = useFormattedCurrency({ cents: Number(amount), hideSymbol });

  let additionalStyle;

  if (deemphasised) {
    additionalStyle = p('orderTotalDeemphasised', TEXT_PROPERTIES);
  } else if (emphasised) {
    additionalStyle = null;
  } else {
    additionalStyle = p('orderTotalDeemphasised', ['fontSize', 'fontWeight']);
  }

  return (
    <div style={styles.line}>
      <Text themeKey="orderTotal" style={combineStyles(styles.orderTotalLabel, additionalStyle)}>
        {label}
      </Text>

      <Text themeKey="orderTotal" style={additionalStyle}>
        {Boolean(skipFormatting) ? amount : value}
      </Text>
    </div>
  );
};

export default Line;

const styles: Styles = {
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 6,
  },
};

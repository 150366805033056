import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'enableGoogleLogin',
  initialState: true,
  reducers: {
    setEnableGoogleLogin: (_, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setEnableGoogleLogin } = slice.actions;
export default slice.reducer;

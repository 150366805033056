import LeftMenuDrawer from './LeftMenuDrawer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const Drawers = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div>
      <LeftMenuDrawer />
    </div>
  );
};

export default Drawers;

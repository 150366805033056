import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import { syncMember } from '../thunks/syncMember';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import i18next from 'i18next';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { RootState } from 'store';
import { setOrderDate } from '../slices/orderDate';
import moment from 'moment';
import { setCurrentModal } from '../thunks/setCurrentModal';
import {
  setStagedPurchases,
  addStagedPurchase,
  addStagedPurchases,
} from '../libs/polygon-ordering/src/reducers/currentOrder/stagedPurchases';
import { setOffers } from '../libs/polygon-ordering/src/reducers/currentOrder/offers';
import { setMenu } from '../libs/polygon-ordering/src/reducers/menu';
import { applyBuffer } from '../libs/polygon-ordering/src/operations';

const {
  updateKeyOrderProperty,
  sale,
  finaliseSale,
  validateOrder,
  fetchGiftCard,
  updateMenu,
  stageOpenPurchase,
  resetTempTags,
  removeStagedPurchase,
  adjustStagedPurchaseQuantity,
  applyAutomaticOffer,
} = OrderingOperations;

const { getTempTags } = OrderingSelectors;
export const listenerMiddleware = createListenerMiddleware();

//https://redux-toolkit.js.org/api/createListenerMiddleware

listenerMiddleware.startListening({
  matcher: isAnyOf(updateKeyOrderProperty.fulfilled),
  effect: async (action, listenerApi) => {
    logEvent(EVENTS.UPDATE_KEY_ORDER_PROPERTY);
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(sale.rejected, finaliseSale.rejected),
  effect: async (action, { dispatch }) => {
    const { charged, reason } = action.payload;
    logEvent(EVENTS.CHECKOUT_FAILED, {
      label: `CHARGED: ${charged ? 'Y' : charged == null ? 'M' : 'N'} - REASON: ${reason}`,
    });
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(sale.fulfilled, finaliseSale.fulfilled),
  effect: async (action, { dispatch }) => {
    dispatch(syncMember());
    logEvent(EVENTS.CHECKOUT);
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(validateOrder.pending),
  effect: async () => {
    logEvent(EVENTS.VALIDATE_ORDER);
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(fetchGiftCard.rejected, fetchGiftCard.fulfilled),
  effect: async action => {
    if (action.type === fetchGiftCard.rejected) {
      enqueueErrorSnackbar(i18next.t('problemFetchingGiftCardMessage'));
      logEvent(EVENTS.FETCH_GIFT_CARD_FAILED);
    } else {
      enqueueSuccessSnackbar(i18next.t('successFetchingGiftCardMessage'));
      logEvent(EVENTS.FETCH_GIFT_CARD);
    }
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(updateMenu.fulfilled),
  effect: async (action, { dispatch, getState }) => {
    const saved = (getState() as RootState).orderDate;
    const current = moment().dayOfYear();

    if (saved != current) {
      dispatch(setOrderDate(current));
    }

    logEvent(EVENTS.UPDATE_MENU);
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(stageOpenPurchase.fulfilled),
  effect: async (action, { dispatch }) => {
    const { previouslyStaged, enableUpsell, upSellStaging } = action.payload;
    //keep upsell modal on when selecting upsell item
    if ((!enableUpsell && !upSellStaging) || previouslyStaged) dispatch(setCurrentModal(null));
    logEvent(previouslyStaged ? EVENTS.SAVE_PURCHASE_CHANGES : EVENTS.SAVE_NEW_PURCHASE);
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(setCurrentModal.fulfilled),
  effect: (action, { dispatch, getState }) => {
    const tempTags = getTempTags(getState() as RootState);

    if (!action.meta.args && tempTags?.length !== 0) {
      dispatch(resetTempTags());
    }
  },
});

// listen for changes in the current item and trigger the automatic coupon logic
listenerMiddleware.startListening({
  matcher: isAnyOf(
    setStagedPurchases,
    removeStagedPurchase.fulfilled,
    addStagedPurchase,
    addStagedPurchases,
    adjustStagedPurchaseQuantity,
    setOffers,
    setMenu,
    applyBuffer.fulfilled,
  ),
  effect: async (action, { dispatch }) => {
    dispatch(applyAutomaticOffer({}));
  },
});

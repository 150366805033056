import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import TouchableOpacity from './TouchableOpacity';
import { MdOutlineArrowBackIosNew, MdClose } from 'react-icons/md';
import getThemeLookup from '../selectors/getThemeLookup';

const ModalBackButton = ({
  onClick,
  showCloseButton = false,
}: {
  onClick: () => void;
  showCloseButton?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const Icon = showCloseButton ? MdClose : MdOutlineArrowBackIosNew;
  return (
    <TouchableOpacity onClick={onClick}>
      <Icon
        size={p('reviewOrderBackIcon', ['fontSize']).fontSize || 25}
        color={p('reviewOrderBackIcon', ['color']).color}
      />
    </TouchableOpacity>
  );
};

export default ModalBackButton;

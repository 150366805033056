import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as ChoiceOrderingMethod | null;

const slice = createSlice({
  name: '$choiceOrderingMethod',
  initialState,
  reducers: {
    setChoiceOrderingMethod(
      state,
      action: PayloadAction<ChoiceOrderingMethod | null>,
    ) {
      return action.payload;
    },
  },
});

export const { setChoiceOrderingMethod } = slice.actions;
export default slice.reducer;

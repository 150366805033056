import { createSelector } from 'reselect';

import calculateTotalsForStagedPurchases from '../utils/ordering/calculateTotalsForStagedPurchases';

import getStagedPurchases, { $getStagedPurchases } from './getStagedPurchases';

const get = (purchases: PurchaseWithTotals[]) =>
  calculateTotalsForStagedPurchases(purchases);

const getStagedPurchasesTotals = createSelector([getStagedPurchases], get);

export default getStagedPurchasesTotals;

export const $getStagedPurchasesTotals = createSelector(
  [$getStagedPurchases],
  get,
);

import * as _ from 'lodash';
export default function choiceSetDiscountPlus(
  choiceSetDiscountMap: ChoiceSetDiscountMap[],
): { ChoiceSetDiscountMapObj: { [key: string]: string }; plus: string[] } {
  const ChoiceSetDiscountMapObj: SDict<string> = _.orderBy(
    choiceSetDiscountMap,
    ['NumberOfChoices'],
    ['asc'],
  ).reduce(
    (preValue, currentValue) => ({
      ...preValue,
      [currentValue.NumberOfChoices]: currentValue.PLU,
    }),
    {},
  );
  return {
    ChoiceSetDiscountMapObj,
    plus: _.uniq(Object.values(ChoiceSetDiscountMapObj)),
  };
}

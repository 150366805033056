import React, { useState } from 'react';
import Text from './Text';
import { useTranslation } from 'react-i18next';
import TouchableOpacity from '../components/TouchableOpacity';
import { BiSliderAlt } from 'react-icons/bi';
import 'react-datepicker/dist/react-datepicker.css';
import SaleTypeFilters from './SaleTypeFilters';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setLoyaltyFilters } from '../slices/loyaltyFilters';
import { setLoyaltyDateFilters } from '../slices/loyaltyDateFilters';
import getThemeLookup from '../selectors/getThemeLookup';
import DateFilters from './DateFilters';

const Filters: React.FC = () => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);

  return (
    <div>
      <div
        onClick={() => setShowFilters(!showFilters)}
        className=" px-3"
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          borderRadius: 20,
          border: p('loyaltyFilterBorder', ['border']).border,
          cursor: 'pointer',
          height: 40,
          display: 'flex',
          gap: 6,
          alignItems: 'center',
          userSelect: 'none',
        }}
      >
        <BiSliderAlt style={{ color: p('sliderIcon', ['color']).color, fontSize: 24 }} />
        <Text value={t('title.filter')} themeKey="filterText" style={{ fontSize: 16 }} />
      </div>

      {/**
       * // Todo: Ask Alice for the design of this, this looks and behaves really janky right now
       */}
      {showFilters && (
        <div
          className="shadow position-absolute py-2 px-3"
          style={{
            right: 0,
            backgroundColor: p('loyaltyFilterModals', ['backgroundColor']).backgroundColor,
            borderRadius: 5,
            width: 160,
            zIndex: 1,
          }}
        >
          <Text value={t('title.filter')} themeKey="filterTitle" />
          <div className="d-inline position-absolute " style={{ right: 15 }}>
            <TouchableOpacity
              onClick={() => {
                dispatch(setLoyaltyFilters(null));
                dispatch(setLoyaltyDateFilters(null));
              }}
            >
              <Text value={t('button.resetFilters')} themeKey="resetFilters" />
            </TouchableOpacity>
          </div>

          <SaleTypeFilters />
          <DateFilters />
        </div>
      )}
    </div>
  );
};

export default Filters;

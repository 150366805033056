import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as SDict<Cardset> | null;

const slice = createSlice({
  name: '$cardsets',
  initialState,
  reducers: {
    setCardsets(state, action: PayloadAction<SDict<Cardset> | null>) {
      return action.payload;
    },
  },
});

export const { setCardsets } = slice.actions;
export default slice.reducer;

export default function processPayLaterRule(
  raw: RawPayLaterRule,
): PayLaterRule {
  return {
    locationId: raw.Location == null ? undefined : String(raw.Location),
    state: raw.State || undefined,
    saleType: raw.SaleType || undefined,
    orderingProvider: raw.OrderingProvider || undefined,
    maximumOrder:
      raw.MaximumOrder == 0
        ? 0
        : raw.MaximumOrder
        ? raw.MaximumOrder
        : undefined,
    memberOnly: Boolean(raw.MemberOnly),
  };
}

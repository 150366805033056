import { createSelector } from '@reduxjs/toolkit';
import getItems from './getItems';
import getStagedPurchases from './getStagedPurchases';
import { getDayPartMenuConfig } from './config';
import checkStagePurchaseAvailability from '../utils/ordering/checkStagePurchaseAvailability';

export default (t: any) =>
  createSelector(
    [getItems, getStagedPurchases, getDayPartMenuConfig],
    (items, stagedPurchases, daypartConfig): boolean => {
      let availablity = true;
      for (let purchase of stagedPurchases) {
        availablity =
          availablity &&
          checkStagePurchaseAvailability(purchase, items, daypartConfig);
        if (!availablity) break;
      }

      return !availablity;
    },
  );

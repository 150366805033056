function attemptHistoryAction(callback: (history: any) => void) {
  if (!window._history) {
    console.error('history ref missing');
    return;
  }

  if (!callback) {
    console.error('callback missing');
    return;
  }

  callback(window._history);
}

export default {
  push: (route: string, state?: any) => attemptHistoryAction(history => history.push(route, state)),
  replace: (route: string) => attemptHistoryAction(history => history.replace(route)),
  goBack: () => attemptHistoryAction(history => history.goBack()),
};

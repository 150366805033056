import React, { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import delay from '../utils/delay';
import { setCreditCardComplete } from '../slices/creditCardComplete';
import { Elements } from '@stripe/react-stripe-js';

const PaymentWrapper = ({
  shouldHaveMerchantAccount = false,
  children,
}: {
  shouldHaveMerchantAccount?: boolean;
  children: React.ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const hqGatewayKey = useAppSelector(state => state.config.hqGatewayKey);
  const merchantAccount = useAppSelector(state => state.config.stripeMerchantAccount);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    dispatch(setCreditCardComplete(false));
    readyStripe();
  }, [hqGatewayKey]);

  const readyStripe = () => {
    if (!hqGatewayKey) {
      return;
    }
    setStripe(null);
    delay(1000)
      .then(() =>
        loadStripe(
          hqGatewayKey,
          // The merchant account is required for CVC revalidation (with a saved card).
          // Merchant account is handled on the back end for non-saved card purchases.
          shouldHaveMerchantAccount ? { stripeAccount: merchantAccount! } : undefined,
        ),
      )
      .then(stripe => setStripe(stripe));
  };
  return (
    <Elements key={hqGatewayKey} stripe={stripe}>
      {children}
    </Elements>
  );
};

export default PaymentWrapper;

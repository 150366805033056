import React from 'react';
import { useTranslation } from 'react-i18next';
import TouchableOpacity from './TouchableOpacity';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Text from './Text';
import { Row, Col } from 'reactstrap';
import getThemeLookup from '../selectors/getThemeLookup';
import { IconType } from 'react-icons';
import combineStyles from '../utils/combineStyles';
import { SALE_TYPE_ICONS } from '../constants/saleTypes';
import { adjustSaleType } from '../thunks/adjustSaleType';

const SaleTypeButton = ({
  saleType,
  locationId,
}: {
  saleType: SALE_TYPE;
  locationId?: string | null;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);

  const renderIcon = (Icon: IconType) => {
    return Icon ? (
      <Icon size="30" style={combineStyles(p('saleTypeIcons', ['fontSize', 'color']))} />
    ) : null;
  };

  return (
    <TouchableOpacity onClick={() => dispatch(adjustSaleType({ saleType: saleType, locationId }))}>
      <Row className="my-2 p-3 border" style={{ borderRadius: 5 }}>
        <Col xs="2" className="my-auto">
          <div>{renderIcon(SALE_TYPE_ICONS[saleType])}</div>
        </Col>
        <Col>
          <div>
            <Text value={t(`saleType.${saleType}.name`)} themeKey="pickSaleType" /> <br />
            <Text value={t(`saleType.${saleType}.subtitle`)} themeKey="pickSaleTypeDescription" />
          </div>
        </Col>
      </Row>
    </TouchableOpacity>
  );
};

export default SaleTypeButton;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'reorderableItems',
  initialState: null,
  reducers: {
    //@ts-ignore
    setReorderableItems(state, action: PayloadAction<_Purchase[] | null>) {
      return action.payload;
    },
  },
});

export const { setReorderableItems } = slice.actions;

export default slice.reducer;

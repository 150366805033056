import React, { useState, useEffect, useRef } from 'react';
import lodash from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

import { BRAND_ICON_PREFIX } from '../constants';

import getThemeLookup from '../selectors/getThemeLookup';

import combineStyles from '../utils/combineStyles';

import HorizontalMenuCell from './HorizontalMenuCell';
import TouchableOpacity from './TouchableOpacity';

import { useAppSelector } from '../app/hooks';

import { DEVICE_TYPES } from '../constants';

interface IProps {
  position: string;
  onClick: () => void;
  containerBackgroundColor?: string;
  backgroundColor?: string;
}
const ScrollIndicator: React.FC<IProps> = ({
  position = 'start',
  onClick,
  containerBackgroundColor,
  backgroundColor,
}) => {
  const isStart = position === 'start';

  const IconComponent = isStart ? RiArrowLeftSLine : RiArrowRightSLine;

  return (
    <div
      style={combineStyles(
        styles.scrollIndicatorContainer,
        {
          backgroundColor: containerBackgroundColor,
        },
        isStart ? { left: 0 } : { right: 0 },
      )}
    >
      <TouchableOpacity
        style={combineStyles(
          {
            backgroundColor,
          },
          styles.scrollIndicatorInnerContainer,
        )}
        onClick={onClick}
      >
        <IconComponent size={35} color={containerBackgroundColor} />
      </TouchableOpacity>
    </div>
  );
};

interface HorizontalScrollableMenuProps {
  items: Brand[] | Category[];
  currentItemId: string | null;
  setCurrentItemId: (id: string) => void;
  labelThemeKey: string;
  currentLabelThemeKey: string;
  currentIndicatorThemeKey: string;
  iconSize?: number;
  containerBackgroundColor?: string;
  containerStyle?: React.CSSProperties;
  isBrand?: boolean;
}

const HorizontalScrollableMenu: React.FC<HorizontalScrollableMenuProps> = props => {
  const [showIndicatorStart, setShowIndicatorStart] = useState<boolean>(false);
  const [showIndicatorEnd, setShowIndicatorEnd] = useState<boolean>(false);
  const deviceType = useAppSelector(state => state.deviceType);

  const psbContainerRef = useRef<any>(null);
  interface P extends PerfectScrollbar {
    _ps: any;
  }
  const psbInnerRef = useRef<P>(null);

  useEffect(() => {
    assess();
  }, []);

  const assess = () => {
    if (!psbContainerRef || !psbInnerRef) {
      return;
    }

    const newShowIndicatorStart =
      psbContainerRef.current!.scrollLeft > 15 && deviceType != DEVICE_TYPES.MOBILE;

    const newShowIndicatorEnd =
      psbContainerRef.current!.scrollLeft + psbContainerRef.current!.offsetWidth <
        psbInnerRef.current!._ps.contentWidth - 15 && deviceType != DEVICE_TYPES.MOBILE;

    if (newShowIndicatorStart !== showIndicatorStart || newShowIndicatorEnd !== showIndicatorEnd) {
      setShowIndicatorStart(newShowIndicatorStart);
      setShowIndicatorEnd(newShowIndicatorEnd);
    }
  };

  const p = useAppSelector(getThemeLookup);
  const {
    items,
    currentItemId,
    setCurrentItemId,
    labelThemeKey,
    currentLabelThemeKey,
    currentIndicatorThemeKey,
    iconSize,
    containerBackgroundColor,
    containerStyle,
    isBrand = false,
  } = props;

  const indicatorProps = {
    containerBackgroundColor,
    backgroundColor: p(labelThemeKey, ['color']).color,
  };

  return (
    <div
      style={combineStyles(
        { position: 'relative', overflowX: 'hidden', width: '100%' },
        containerStyle,
      )}
    >
      {showIndicatorStart && (
        <ScrollIndicator
          position="start"
          onClick={() => psbContainerRef.current!.scrollBy({ left: -100 })}
          {...indicatorProps}
        />
      )}
      {showIndicatorEnd && (
        <ScrollIndicator
          position="end"
          onClick={() => psbContainerRef.current!.scrollBy({ left: 100 })}
          {...indicatorProps}
        />
      )}

      <PerfectScrollbar
        containerRef={ref => {
          psbContainerRef.current = ref;
        }}
        ref={psbInnerRef}
        className="hide_x_rail" // we hide the scrollbar using css
        style={styles.mainContainer}
        options={{
          suppressScrollY: true,
          useBothWheelAxes: true, // we use psb this to get mousewheel scrolling
        }}
        onScroll={lodash.throttle(() => {
          assess();
        }, 200)}
      >
        {lodash.map(items, item => (
          // Type Category doesnt have these fields

          <HorizontalMenuCell
            //@ts-ignore
            key={item.id}
            //@ts-ignore
            item={item}
            //@ts-ignore
            isCurrent={item.id === currentItemId}
            setCurrentItemId={setCurrentItemId}
            currentIndicatorColor={p(currentIndicatorThemeKey, ['color']).color}
            labelThemeKey={labelThemeKey}
            currentLabelThemeKey={currentLabelThemeKey}
            iconPathPrefix={BRAND_ICON_PREFIX}
            iconSize={iconSize!}
            isBrand={isBrand}
          />
        ))}
      </PerfectScrollbar>
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflowX: 'auto',
    flex: 1,
    position: 'relative',
    paddingTop: 10,
  },

  scrollIndicatorContainer: {
    height: '100%',
    position: 'absolute',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
  },
  scrollIndicatorInnerContainer: {
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 30,
    height: 30,
  },
};

export default HorizontalScrollableMenu;

import { createSelector } from 'reselect';
import getSaleType from './getSaleType';
import getMaxOrderValueRules from './getMaxOrderValueRules';
import getLocationId from './getLocationId';

export default createSelector(
  [getMaxOrderValueRules, getSaleType, getLocationId],
  (rules, saleType, locationId): MaxOrderValue | null => {
    if (rules) {
      const locationRules = rules.filter(
        eachRule => eachRule.Location === Number(locationId),
      );

      const stateRules = rules.filter(
        eachRule =>
          eachRule.Location !== Number(locationId) && eachRule.State !== null,
      );

      const globalRules = rules.filter(
        eachRule =>
          eachRule.Location !== Number(locationId) && eachRule.State === null,
      );

      if (locationRules.length > 0) {
        const locationRule = locationRules.filter(
          rule => rule.SaleType === saleType,
        )[0];

        const locationGlobalRule = locationRules.filter(
          rule => rule.SaleType === null,
        )[0];

        return locationRule
          ? {
              ...locationRule,
              MaximumOrder: Number(locationRule.MaximumOrder) * 100,
            }
          : {
              ...locationGlobalRule,
              MaximumOrder: Number(locationGlobalRule.MaximumOrder) * 100,
            };
      }

      if (stateRules.length > 0) {
        const stateRule = stateRules.filter(
          rule => rule.SaleType === saleType,
        )[0];

        const stateGlobalRule = stateRules.filter(
          rule => rule.SaleType === null,
        )[0];

        return stateRule
          ? {
              ...stateRule,
              MaximumOrder: Number(stateRule.MaximumOrder) * 100,
            }
          : {
              ...stateGlobalRule,
              MaximumOrder: Number(stateGlobalRule.MaximumOrder) * 100,
            };
      }

      if (globalRules.length > 0) {
        const globalRule = globalRules.filter(
          rule => rule.SaleType === saleType,
        )[0];

        const generalGlobalRule = globalRules.filter(
          rule => rule.SaleType === null,
        )[0];

        return globalRule
          ? {
              ...globalRule,
              MaximumOrder: Number(globalRule.MaximumOrder) * 100,
            }
          : {
              ...generalGlobalRule,
              MaximumOrder: Number(generalGlobalRule.MaximumOrder) * 100,
            };
      }

      return null;
    }

    return null;
  },
);

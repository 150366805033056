const tallyAndValidateNestedChoicesets = (
  choiceSets: (NestedChoiceSetWithQuantity | ChoiceSetWithQuantity)[],
): (ValidatedNestedChoiceSet | ValidatedChoiceSet)[] => {
  return choiceSets.map(set => {
    const quantity = (
      (set.choices as (ChoiceWithQuantity | NestedChoiceWithQuantity)[]) || []
    ).reduce((acc, curr) => (acc += curr.quantity), 0);

    let valid = true;
    if (set.required && !quantity) valid = false;
    if (!('nestedIngredients' in set && set.nestedIngredients)) {
      if (set.max && quantity > set.max) {
        valid = false;
      }

      if (set.min && quantity < set.min) {
        valid = false;
      }
    } else {
      // no checking of the quantity or anything here? I guess it works but it's a little odd
      // for context: This is for the "choose 2 pizzas" choice sets which were duplicated in the processmenuv2 call, "condensed choice sets" which the min === max so they get unrolled into multiple choice sets for the ui (imo doesn't make much sense)
      if (set.min && quantity > 0) valid = true;
    }
    return {
      ...set,
      quantity,
      valid,
    };
  });
};
export default tallyAndValidateNestedChoicesets;

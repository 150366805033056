import { createSelector } from 'reselect';

import getBuffer from './getBuffer';
import getCurrentOrder from './getCurrentOrder';

const getCreditCardPaymentInformation = createSelector(
  [getCurrentOrder],
  order => {
    return order.creditCardPaymentInformation;
  },
);

export default getCreditCardPaymentInformation;

export const $getCreditCardPaymentInformation = createSelector(
  [getCreditCardPaymentInformation, getBuffer],
  (creditCardPaymentInfo, buffer) => {
    if (buffer.creditCardPaymentInformation) {
      return buffer.creditCardPaymentInformation;
    }

    return creditCardPaymentInfo;
  },
);

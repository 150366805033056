import * as lodash from 'lodash';
import { STOCK_BALANCE_THRESHOLDS } from '../../constants';
import determineStockBalanceThreshold from './determineStockBalanceThreshold';

export default (
  purchase: PurchaseWithTotals | undefined,
  stockBalances: StockBalance[] | null,
  nestedPurchased: NestedChoiceSelections | undefined,
  stagedPurchases: PurchaseWithTotals[],
  stockBalanceThresholds: number[] | null,
): [
  SDict<StockBalanceData> | undefined, //processed availability amount based on staged purchase and nested purchase
  number | undefined, //max availability for root item quantity control (need for quantity control in open purchase and staged purchase)
] => {
  if (!stockBalances) return [undefined, undefined];

  const stockBalanceDataMap: SDict<StockBalanceData> = {}; //choices/nested items and rootItem stock balance data
  //create a quantity map for choices with which item in staged purchase but not in open purchase
  const stockBalncesKeys = lodash.uniq(
    stockBalances.map(stock => stock.PLUCode.toString()),
  );

  if (!purchase) {
    const stagedPurchasesQuantityMap = lodash.pickBy(
      calculateItemQuantityInStagedPurchases(stagedPurchases),
      (value, key) => stockBalncesKeys.includes(key),
    );
    stockBalances.forEach(stock => {
      const itemBalance = stock.Balance;
      const stagedChoiceQuantity =
        stagedPurchasesQuantityMap && stagedPurchasesQuantityMap[stock.PLUCode]
          ? stagedPurchasesQuantityMap[stock.PLUCode]
          : undefined;

      const total = stagedChoiceQuantity ? stagedChoiceQuantity : undefined;

      const cartAdjustedBalance = total ? itemBalance - total : itemBalance;

      const stockBalanceThreshold = determineStockBalanceThreshold(
        itemBalance,
        stockBalanceThresholds,
      );

      //this logic to ensure choiceset does not have sold out labels
      const stockBalanceThresholdFromCartAdjustedBalance =
        determineStockBalanceThreshold(
          cartAdjustedBalance,
          stockBalanceThresholds,
        );

      const soldOut =
        stockBalanceThreshold &&
        stockBalanceThreshold ===
          STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0;

      const soldOutByCartSelections =
        stockBalanceThresholdFromCartAdjustedBalance &&
        stockBalanceThresholdFromCartAdjustedBalance ===
          STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0;

      stockBalanceDataMap[stock.PLUCode] = {
        itemBalance,
        cartAdjustedBalance,
        soldOut,
        stockBalanceThreshold: stockBalanceThreshold,
        soldOutByCartSelections,
        maxAvailableQuantity: undefined,
        maxAvailableQuantityChoice: undefined,
      };
    });
    return [stockBalanceDataMap, undefined];
  }

  const {
    item: rootItem,
    plucode: rootPlucode,
    quantity: itemQuantity,
    choiceSets,
    choicesWithQuantity,
  } = purchase;
  //extract all choices/items from current nested purchased and the stock data exist at the same time
  const nestedChoicesWithQuantity =
    extractChoiceWithQuantityFromNestedPurchased(
      nestedPurchased,
      rootItem.choiceSets,
    ).filter(c => stockBalncesKeys.includes(c.plucode));

  //choices in nested purchase quantity map.used for calculate max available quantity for each choice/item
  const nestedChoicesQuantityMap = calculateNestedChoicesQuantity(
    nestedChoicesWithQuantity,
    itemQuantity,
  );

  const stagedPurchasesQuantityMap = lodash.pickBy(
    calculateItemQuantityInStagedPurchases(
      stagedPurchases.filter(p => p.id !== purchase.id),
    ),
    (value, key) => stockBalncesKeys.includes(key),
  );

  //quantity map for standard purchase (open purchase/staged purchase). used for calculate max available quantity
  const standardItemQuantityMap = choicesWithQuantity.length
    ? choicesWithQuantity.reduce(
        (acc, current) => {
          return stockBalncesKeys.includes(current.plucode)
            ? { ...acc, [current.plucode]: current.quantity * itemQuantity }
            : acc;
        },
        { [rootPlucode]: itemQuantity },
      )
    : { [rootPlucode]: itemQuantity };

  const isNested = choiceSets.reduce(
    (acc, current) =>
      acc ||
      (lodash.has(current, 'nestedIngredients') &&
        (current as ValidatedNestedChoiceSet).nestedIngredients &&
        current.displayType !== 'checkbox'),
    false,
  );

  const baseQuantityMap = isNested
    ? nestedChoicesQuantityMap
    : standardItemQuantityMap;

  //generate stock balance data obj
  stockBalances.forEach(stock => {
    const itemBalance = stock.Balance;
    //choices quantity in nested purchase
    const nestedPurchaseQuantity =
      nestedChoicesQuantityMap && nestedChoicesQuantityMap[stock.PLUCode]
        ? nestedChoicesQuantityMap[stock.PLUCode]
        : undefined;

    //choices quantity in staged purchases exclude current purchase(open/staged)
    const stagedChoiceQuantity =
      stagedPurchasesQuantityMap && stagedPurchasesQuantityMap[stock.PLUCode]
        ? stagedPurchasesQuantityMap[stock.PLUCode]
        : undefined;

    let total = undefined;
    if (stagedChoiceQuantity && nestedPurchaseQuantity)
      total = stagedChoiceQuantity + nestedPurchaseQuantity;
    else if (stagedChoiceQuantity) total = stagedChoiceQuantity;
    else if (nestedPurchaseQuantity) total = nestedPurchaseQuantity;

    const cartAdjustedBalance = total ? itemBalance - total : itemBalance;

    //calculate max quantity control for rootItem
    const maxAvailableQuantity =
      lodash.has(baseQuantityMap, stock.PLUCode) &&
      baseQuantityMap![stock.PLUCode] !== 0
        ? stock.PLUCode.toString() === rootPlucode
          ? itemBalance - (stagedChoiceQuantity || 0)
          : Math.trunc(
              (itemBalance - (stagedChoiceQuantity || 0)) /
                (baseQuantityMap![stock.PLUCode] / itemQuantity),
            )
        : undefined;

    //calculate max quantity for choice
    const maxAvailableQuantityChoice =
      baseQuantityMap && baseQuantityMap[stock.PLUCode] !== 0
        ? stock.PLUCode.toString() === rootPlucode
          ? itemBalance - (stagedChoiceQuantity || 0)
          : Math.trunc(
              (itemBalance - (stagedChoiceQuantity || 0)) / itemQuantity,
            )
        : undefined;

    const stockBalanceThreshold = determineStockBalanceThreshold(
      itemBalance,
      stockBalanceThresholds,
    );

    //this logic to ensure choiceset does not have sold out labels
    const stockBalanceThresholdFromCartAdjustedBalance =
      determineStockBalanceThreshold(
        cartAdjustedBalance,
        stockBalanceThresholds,
      );

    const soldOut =
      stockBalanceThreshold &&
      stockBalanceThreshold ===
        STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0;

    const soldOutByCartSelections =
      stockBalanceThresholdFromCartAdjustedBalance &&
      stockBalanceThresholdFromCartAdjustedBalance ===
        STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0;

    stockBalanceDataMap[stock.PLUCode] = {
      itemBalance,
      cartAdjustedBalance,
      soldOut,
      stockBalanceThreshold: stockBalanceThreshold,
      soldOutByCartSelections,
      maxAvailableQuantity,
      maxAvailableQuantityChoice,
    };
  });

  //calculate min available amount by purchase(open purchase/staged purchase)
  const maxAvailableQuantityArr = lodash
    .keys(baseQuantityMap)
    .map(k =>
      lodash.has(stockBalanceDataMap, k)
        ? stockBalanceDataMap[k].maxAvailableQuantity
        : undefined,
    )
    .filter(e => e);

  const purchaseMaxAvailable = lodash.min(maxAvailableQuantityArr);

  return [stockBalanceDataMap, purchaseMaxAvailable];
};

export const calculateItemQuantityInStagedPurchases = (
  stagedPurchases: PurchaseWithTotals[],
): SDict<number> | undefined => {
  if (!stagedPurchases.length) return;
  return stagedPurchases.reduce((acc: SDict<number>, purchase) => {
    const {
      // item: { plucode },
      plucode,
      quantity,
      choicesWithQuantity,
      choiceSelections,
      choiceSets,
    } = purchase;
    const initial = {
      ...acc,
      [plucode]: acc[plucode] ? acc[plucode] + quantity : quantity,
    };

    const selectedNestedChoicesWithQuantity =
      extractNestedChoicesFromStagedPurchase(choiceSelections, choiceSets);

    return [
      ...choicesWithQuantity,
      ...selectedNestedChoicesWithQuantity,
    ].reduce((acc, choice) => {
      return {
        ...acc,
        [choice.plucode]: acc[choice.plucode]
          ? acc[choice.plucode] + choice.quantity * quantity
          : choice.quantity * quantity,
      };
    }, initial);
  }, {});
};

/*populate selected choices quantity for nested choiceset.
 selected choices for nested choiceset stored in choiceSelections only.
 selected choices for standard choiceset stored in both chioceSelections and choiceWithQuantity*/
export const extractNestedChoicesFromStagedPurchase = (
  choiceSelections: NestedChoiceSelections | SDict<string[]> | undefined,
  choiceSets: (ValidatedChoiceSet | ValidatedNestedChoiceSet)[],
): ChoiceWithQuantity[] => {
  //pick nested choicesets
  const nestedChoiceSets = lodash.pickBy(choiceSelections, (value, key) => {
    const set = lodash.find(choiceSets, s => s.key === key);
    return set && lodash.has(set, 'nestedIngredients');
  }) as SDict<SDict<SDict<ChoiceWithQuantity[]>>>;
  //nested choices
  let temp: ChoiceWithQuantity[] = [];
  lodash.toPairs(nestedChoiceSets).forEach(([key, set]) =>
    lodash.toPairs(set).forEach(([key, nestedSet]) =>
      lodash.toPairs(nestedSet).forEach(([key, value]) => {
        temp = [...temp, ...(value as ChoiceWithQuantity[])];
      }),
    ),
  );
  const selectedNestedChoices = lodash.flatten(temp);

  //refactor selected choices with quantity
  return lodash.map(
    lodash.uniqBy(selectedNestedChoices, choice => choice.plucode),
    choice => ({
      ...choice,
      quantity: selectedNestedChoices.filter(c => c.plucode === choice.plucode)
        .length,
    }),
  );
};

export const extractChoiceWithQuantityFromNestedPurchased = (
  nestedPurchased: NestedChoiceSelections,
  choiceSets: (ChoiceSet | NestedChoiceSet)[],
): ChoiceWithQuantity[] => {
  if (!nestedPurchased) return [];
  //calculate nested item stock
  const nestedChoicesWithQuantity: ChoiceWithQuantity[] = [];

  lodash.mapKeys(nestedPurchased, (value, setKey) => {
    const set = choiceSets.find(s => s.key === setKey) as
      | ChoiceSet
      | NestedChoiceSet;
    if (set && 'nestedIngredients' in set && set.nestedIngredients) {
      lodash.mapKeys(value, (nestedValue, nestedKey) => {
        const choice = (set.choices || []).find(e => e.id === nestedKey);
        nestedChoicesWithQuantity.push({
          ...choice,
          quantity: 1,
          moneyPrice: choice?.baseMoneyPrice,
        } as ChoiceWithQuantity);
        const nestedChoices = lodash
          .values(nestedValue)
          .forEach(choiceArr => nestedChoicesWithQuantity.push(...choiceArr));
      });
    } else {
      const nestedValues = lodash.values(value as ChoiceWithQuantity[]);
      nestedChoicesWithQuantity.push(...nestedValues);
    }
  });

  return nestedChoicesWithQuantity.filter(c => c);
};

const calculateNestedChoicesQuantity = (
  nestedChoicesWithQuantity: ChoiceWithQuantity[],
  itemQuantity: number = 1,
): SDict<number> | undefined => {
  if (!nestedChoicesWithQuantity.length) return;
  const result: SDict<number> = nestedChoicesWithQuantity.reduce(
    (acc, choice) => {
      return {
        ...acc,
        [choice.plucode]: acc[choice.plucode]
          ? acc[choice.plucode] + itemQuantity
          : itemQuantity,
      };
    },
    {} as SDict<number>,
  );

  return result;
};

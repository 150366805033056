import { OrderingConstants, OrderingSelectors } from 'polygon-ordering';

import { store } from '../store';

const { STOCK_BALANCE_THRESHOLDS } = OrderingConstants;

const { getStockBalanceThresholds } = OrderingSelectors;

export default function (balance) {
  const state = store.getState();
  const stockBalanceThresholds = getStockBalanceThresholds(state);
  let threshold;

  switch (true) {
    case balance <= stockBalanceThresholds?.stockBalanceThreshold0: {
      threshold = STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0;
      break;
    }
    case balance <= stockBalanceThresholds?.stockBalanceThreshold1: {
      threshold = STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_1;
      break;
    }
    case balance <= stockBalanceThresholds?.stockBalanceThreshold2: {
      threshold = STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_2;
      break;
    }
    case balance <= stockBalanceThresholds?.stockBalanceThreshold3: {
      threshold = STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_3;
      break;
    }
    case balance <= stockBalanceThresholds?.stockBalanceThreshold4: {
      threshold = STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_4;
      break;
    }
    default: {
      threshold = null;
      break;
    }
  }

  return threshold;
}

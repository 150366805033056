import { combineReducers, Reducer, AnyAction } from 'redux';

import { resetOrder } from '../../../actions/currentOrder';
import name from './name';
import familyName from './familyName';
import email from './email';
import mobile from './mobile';

const rootReducer: Reducer<_Purchaser, AnyAction> = combineReducers({
  name,
  familyName,
  email,
  mobile,
});

const resetWrapper = (
  state: ReturnType<typeof rootReducer>,
  action: AnyAction,
) => {
  return rootReducer(
    action.type !== resetOrder.type ? state : undefined,
    action,
  );
};

export default resetWrapper;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';

const initialState = null as GPSCoordinates | null;

const slice = createSlice({
  name: '$deliveryCoordinates',
  initialState,
  reducers: {
    setDeliveryCoordinates(
      state,
      action: PayloadAction<GPSCoordinates | null>,
    ) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(applyBufferData, (state, action) => {
      const { deliveryCoordinates } = action.payload;
      return deliveryCoordinates === undefined ? state : deliveryCoordinates;
    });
  },
});

export const { setDeliveryCoordinates } = slice.actions;
export default slice.reducer;

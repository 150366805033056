import { styled } from '@mui/system';
import React, {FC} from 'react';
import { CSSTransition} from 'react-transition-group';

import { useAppSelector, useAppDispatch } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

const Wrapper = styled(CSSTransition)(({ theme }) => {
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  return {
    position: 'absolute',
    bottom: !deviceTypeMobile ? '1.25em' : '',
    left: '50%',
    zIndex: 9999,
    
    '&.transition-enter': {
      opacity: 0,
      transform: 'scale(0.8)',
    },
    '&.transition-enter-active': {
      opacity: 1,
      transition: 'all 250ms cubic-bezier(0.95, 0.09, 0.01, 0.91)',
      transform: 'scale(1)',
    },
    '&.transition-exit': {
      opacity: 1,
    },
    '&.transition-exit-active': {
      opacity: 0,
      transition: 'all 250ms cubic-bezier(0.83, 0.09, 0.01, 0.91)',
    },
    
    hr: {
      margin: '0.5em 0',
    },
  }
});

type Props = {
  isOpen: boolean
  children: React.ReactNode
}

const DropdownContainer: FC<Props> = ({isOpen, children}) => {
  return (
    <Wrapper
      in={isOpen} 
      timeout={300} 
      classNames="transition">

      {children}
    </Wrapper>
  )
}

export default DropdownContainer;

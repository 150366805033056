import lodash from 'lodash';

export default function removeUnusedNestedChoiceSelections(
  purchase: _Purchase,
  allItems: Items,
): _Purchase {
  const item = allItems[purchase.itemId];

  const conditionalChoiceSetKeysByPlu = lodash.mapValues(
    item.conditionalChoiceSets,
    setIds =>
      lodash
        .chain(setIds)
        .map(id => item.choiceSets.find(set => set.id === id))
        .filter()
        .map(set => (set as ChoiceSet).key)
        .value(),
  );

  const allConditionalChoiceSetKeys = lodash
    .chain(conditionalChoiceSetKeysByPlu)
    .values()
    .flatten()
    .uniq()
    .value();

  const allIngredientPlus = lodash
    .chain(purchase.choiceSelections as ChoiceSelections)
    .values()
    .flatten()
    .uniq()
    .value();

  const activeConditionalChoiceSetKeys = lodash
    .chain(conditionalChoiceSetKeysByPlu)
    .pick(allIngredientPlus)
    .values()
    .flatten()
    .value();

  const inactiveConditionalChoiceSetKeys = lodash.difference(
    allConditionalChoiceSetKeys,
    activeConditionalChoiceSetKeys,
  );

  const choiceSelections: Record<string, string[]> = {};

  lodash.keys(purchase.choiceSelections).forEach(key => {
    choiceSelections[key] = inactiveConditionalChoiceSetKeys.includes(key)
      ? []
      : ((purchase.choiceSelections || {})[key] as string[]);
  });

  return {
    ...purchase,
    choiceSelections,
  };
}

import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import { resetOrder, sale, finaliseSale } from '../../operations';

const initialState = false;

const slice = createSlice({
  name: '$saleInProgress',
  initialState,
  reducers: {
    setSaleInProgress(state, action: PayloadAction<boolean>) {
      return Boolean(action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, () => {
        return initialState;
      })
      .addMatcher(isAnyOf(sale.pending, finaliseSale.pending), () => true)
      .addMatcher(
        isAnyOf(
          sale.rejected,
          finaliseSale.rejected,
          finaliseSale.fulfilled,
          sale.fulfilled,
        ),
        () => false,
      );
  },
});

export const { setSaleInProgress } = slice.actions;
export default slice.reducer;

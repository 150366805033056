import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'googleLoginButtonClientKey',
  initialState: null as null | string,
  reducers: {
    setGoogleLoginButtonClientKey(state, action: PayloadAction<string | null>) {
      return action.payload || null;
    },
  },
});

export const { setGoogleLoginButtonClientKey } = slice.actions;

export default slice.reducer;

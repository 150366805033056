import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';
import { resetOrder } from '../../actions/currentOrder';

const initialState = null as CreditCardPaymentInformation | null;

const slice = createSlice({
  name: '$creditCardInformation',
  initialState,
  reducers: {
    setCreditCardPaymentInformation(
      state,
      action: PayloadAction<CreditCardPaymentInformation | null>,
    ) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addCase(applyBufferData, (state, action) => {
        const { creditCardPaymentInformation } = action.payload;
        return creditCardPaymentInformation === undefined
          ? state
          : creditCardPaymentInformation;
      });
  },
});

export const { setCreditCardPaymentInformation } = slice.actions;
export default slice.reducer;

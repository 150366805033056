import { styled } from '@mui/system';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import TouchableOpacity from './TouchableOpacity';
import checkIcon from '../images/check.svg';
import getThemeLookup from '../selectors/getThemeLookup';

const CheckWrapper = styled(TouchableOpacity)(({ theme }) => {
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const p = useAppSelector(getThemeLookup);

  const color = p('check', ['color']).color ?? '#C21213';
  const backgroundColorHover = p('checkHover', ['backgroundColor']).backgroundColor ?? '#C4C4C4';
  const backgroundColor = p('check', ['backgroundColor']).backgroundColor ?? '#EBEBEB';

  const borderRadius = theme.palette.borderRadius ?? 5;
  const size = theme.palette.size ?? 35;

  const isWebkit = navigator.userAgent.indexOf('AppleWebKit') != -1;

  return {
    display: 'flex',
    gap: '0.75em',
    alignItems: 'center',

    ".check-wrapper": {
      height: size,
      width: size,
      minHeight: size,
      minWidth: size,
      position: 'relative',
      borderRadius: borderRadius,
      overflow: 'hidden',
      background: backgroundColor,
      transition: 'all 300ms cubic-bezier(0.95, 0.07, 0, 0.92)',
    },
    
    // The icon of the check-wrapper
    ".check-wrapper:after": {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      background: `url(${checkIcon})`,
      backgroundSize: '60%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      opacity: 0,
      transform: 'scale(0.25)',
      transition: 'all 300ms cubic-bezier(0.95, 0.07, 0, 0.92)'
    },

    // This is to remove any possible artifacting
    ".check-wrapper:before": {
      borderRadius: borderRadius,
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',

      outline: `0px solid ${color}`,
      outlineOffset: 0, 
      transition: 'all 300ms cubic-bezier(0.95, 0.07, 0, 0.92)',
    },
    "&:hover .check-wrapper": {
      background: backgroundColorHover,
      transitionTimingFunction: "cubic-bezier(0, 0.71, 0.02, 0.95)"
    },
    
    ".check-wrapper.checked:before": { 
      /* opacity: 1,
      transition: 'opacity 0.3s',
      transitionDelay: '1000ms', */

      outline: `${size / 2}px solid ${color}`,
      outlineOffset: size / -2, 
    },
    
    ".checked": {
      background: isWebkit ? color : '',
      transition: isWebkit ? 'background 0.1s' : '',
      transitionDelay: isWebkit ? '150ms' : '',
    },

    ".checked:after": {
      opacity: 1,
      transform: 'scale(1)'
    }
  }
});

export default CheckWrapper;
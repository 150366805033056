import React from 'react';
import { useTranslation } from 'react-i18next';
import { checkCategoryAvailability, OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES } from '../utils/theme';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import TouchableOpacity from '../components/TouchableOpacity';
import { PURCHASE_EDITOR_MODAL_ID } from '../modals/PurchaseEditorModal';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { logEvent, viewProductDetailPage } from '../utils/analytics';
import EVENTS from '../constants/events';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import MenuItemDesktop from './MenuItemDesktop';
import MenuItemMobile from './MenuItemMobile';
import { Col } from 'reactstrap';
import lodash from 'lodash';

const {
  getStockBalanceDataByItem,
  getOpenPurchase,
  getItemAvailability,
  getDayPartMenuConfig,
  getBrands,
  getRootCategory,
  getCategoryAvailabilityById,
} = OrderingSelectors;
const { openPurchase } = OrderingOperations;

interface IProps {
  item: Item;
  brandId?: string;
  category: Category;
}
const MenuItem: React.FC<IProps> = ({ item, brandId, category }) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const relaxMenuItemHeightConsistency = useAppSelector(
    state => state.config.relaxMenuItemHeightConsistency,
  );
  const brands = useAppSelector(getBrands);
  const rootCategory = useAppSelector(getRootCategory);
  const dispatch = useAppDispatch();

  const categories = rootCategory?.subCategories || [];

  const brand = brands.find(brand => brand.id === brandId)?.name || null;

  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  // what this looked like before I touched it, I don't understand why we are getting stock balance for an item but passing in the open purchase
  // we are in the menu screen, there shouldn't be an open purchase right?
  // const stockBalanceData = useAppSelector(
  //   getStockBalanceDataByItem(item, useAppSelector(getOpenPurchase) as PurchaseWithTotals),
  // );

  const stockBalanceData = useAppSelector(
    getStockBalanceDataByItem(item, useAppSelector(getOpenPurchase)),
  );
  const [daypartAvailability, availabilityString] = useAppSelector(
    getItemAvailability(item, category.id),
  );
  const { hideUnavailableItems } = useAppSelector(getDayPartMenuConfig);
  const [catAvailability] = useAppSelector(getCategoryAvailabilityById(category.id));
  const hideUnavailableItem = hideUnavailableItems && !daypartAvailability;

  const availabilityTimeString =
    item.availability && catAvailability
      ? t('itemAvailable', { itemAvailable: availabilityString })
      : '';
  const checkIfDisabled =
    stockBalanceData && (stockBalanceData.soldOut || stockBalanceData.soldOutByCartSelections);

  const openItem = () => {
    dispatch(openPurchase({ itemId: item.id, brandId, mimCategoryId: category.id })).then(re => {
      dispatch(setCurrentModal({ modalId: PURCHASE_EDITOR_MODAL_ID, params: { category } }));
      logEvent(EVENTS.OPEN_ITEM);
      viewProductDetailPage(item, null, [], brand, category?.name);
    });
  };

  const mainContainerStyle = combineStyles(
    p('menuItem', CONTAINER_PROPERTIES),
    relaxMenuItemHeightConsistency && { marginBottom: 'auto' },
    (checkIfDisabled || !daypartAvailability) && { opacity: 0.7 },
  );

  return hideUnavailableItem ? null : (
    <Col sm="6" md="4" lg="3" xl="3" className=" mb-5" key={item.id}>
      <div
        style={combineStyles(
          styles.opacityBackstop,
          p('menuItemOpacityBackstop', ['backgroundColor']), // prevent menu background colour leaking through when item hovered
        )}
      >
        <TouchableOpacity
          ariaLabel={item.name}
          style={mainContainerStyle}
          onClick={openItem}
          hoverStyle={p('menuItemHovered', ['outline'])}
          disabled={checkIfDisabled || false}
        >
          {deviceTypeMobile ? (
            <MenuItemMobile item={item} availabilityTimeString={availabilityTimeString} />
          ) : (
            <MenuItemDesktop item={item} availabilityTimeString={availabilityTimeString} />
          )}
        </TouchableOpacity>
      </div>
    </Col>
  );
};

const styles: Styles = {
  opacityBackstop: {
    borderRadius: 2,
  },

  dietary_info: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
};

export default MenuItem;

import { createSelector } from 'reselect';

import {
  DELIVERY_PROVIDERS,
  PAYMENT_METHOD_UNAVAILABLE_REASON,
} from '../constants';

import getSaleType from './getSaleType';
import getDeliveryProvider from './getDeliveryProvider';
import getEnabledPaymentMethods from './getEnabledPaymentMethods';
import { PAYMENT_METHOD } from '../constants/paymentMethod';
import { SALE_TYPE } from '../constants/saleType';

export default createSelector(
  [getEnabledPaymentMethods, getSaleType, getDeliveryProvider],
  (
    enabledPaymentMethods,
    saleType,
    deliveryProvider,
  ): PAYMENT_METHOD_UNAVAILABLE_REASON[] => {
    const reasons = [];

    if (!enabledPaymentMethods.includes(PAYMENT_METHOD.PAY_LATER)) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.NOT_ENABLED);
    }

    if (
      saleType === SALE_TYPE.DELIVERY &&
      deliveryProvider?.id != DELIVERY_PROVIDERS.CLIENT_MANAGED
    ) {
      reasons.push(
        PAYMENT_METHOD_UNAVAILABLE_REASON.DELIVERY_PROVIDER_NOT_COMPATIBLE,
      );
    }

    return reasons;
  },
);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import TouchableOpacity from './TouchableOpacity';

const CloseIcon = ({ onClick }: { onClick: () => void }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <TouchableOpacity onClick={onClick}>
      <span style={{ fontSize: 25 }}>&times;</span>
    </TouchableOpacity>
  );
};

export default CloseIcon;

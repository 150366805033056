import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetFeedback, feedbackInitialState } from '../reducers/feedback';
import Api, { FetchParams } from '../utils/Api';

export const createFeedback = createAsyncThunk(
  'fetchFeedback',
  async (
    data: {
      body: Feedback;
      disableCleanup?: boolean;
    },
    { dispatch, rejectWithValue },
  ) => {
    const params: FetchParams = {
      path: '/api/v1/profile/feedbackloop',
      method: 'POST',
      body: data.body,
    };

    try {
      const response = await Api.fetch(params, 'member');
      if (!data.disableCleanup) {
        dispatch(resetFeedback());
      }
      //coupon_given
      return response;
    } catch (e) {
      console.warn('Fetch feedback failed', { e });
      return rejectWithValue(e);
    }
  },
);

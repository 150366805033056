import { dollarsToCents } from '../misc';

export default function (
  rawInfo: RawHolidaySurchargeInformation,
): SurchargeInstruction {
  const isPercentage = Boolean(rawInfo.SurchargeIsPercentage);

  return {
    description: rawInfo.SurchargeDesc || 'Holiday Surcharge',
    type: rawInfo.SurchargeType,
    amount: isPercentage
      ? rawInfo.Surcharge
      : dollarsToCents(rawInfo.Surcharge),
    isPercentage: Boolean(rawInfo.SurchargeIsPercentage),
  };
}

//         "HolidaySurchargeInfo": {
//             "Surcharge": 15.0,
//             "SurchargeDesc": "Holiday Surcharge",
//             "SurchargeIsPercentage": true,
//             "SurchargeType": 8
//         },

import { createSelector } from '@reduxjs/toolkit';
import { getDayPartMenuConfig } from './config';
import getItems from './getItems';
import checkStagePurchaseAvailability from '../utils/ordering/checkStagePurchaseAvailability';

export default (purchaseWithTotals?: PurchaseWithTotals) =>
  createSelector(
    [getDayPartMenuConfig, getItems],
    (daypartConfig, items): boolean => {
      return checkStagePurchaseAvailability(
        purchaseWithTotals,
        items,
        daypartConfig,
      );
    },
  );

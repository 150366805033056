import { createSelector } from 'reselect';

const _getLocalForage = (state: EntireFrontendState) =>
  state.localForage || { menu: null, cachedMenu: null };

const getLocalForage = createSelector(
  [_getLocalForage],
  (localForage: LocalForageState) => localForage,
);

export default getLocalForage;

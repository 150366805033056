import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Feedback = {
  recidMember: -1,
  recidOrder: -1,

  store: {
    storeId: -1,
    storeName: '',
    rating: 0,

    additionalDetails: '',
  },
  items: [],

  couponGiven: false,
};

const slice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setFeedback(state, action: PayloadAction<Feedback>) {
      let feedback = action.payload as unknown as Feedback;
      return feedback;
    },
    resetFeedback(state) {
      return initialState;
    },
  },
});

export const { setFeedback, resetFeedback } = slice.actions;
export const feedbackInitialState = initialState;

export default slice.reducer;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Modal } from 'reactstrap';
import ModalHeader from '../components/ModalHeader';
import FormTextField from '../components/FormTextField';
import { FormikValues, useFormikContext } from 'formik';
import StandardButton from '../components/StandardButton';
import { OrderingOperations } from 'polygon-ordering';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import { setDesiredDeliveryAddress } from '../slices/desiredDeliveryAddress';

const { saveAddress } = OrderingOperations;

const SaveAddressModal = ({ isOpen }: { isOpen: boolean }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { handleChange, values, setFieldValue } = useFormikContext<FormikValues>();
  const [requestInProgress, setRequestInProgress] = useState(false);
  return (
    <Modal isOpen={isOpen} style={{ marginTop: '25%', padding: 25 }}>
      <ModalHeader
        backClick={() => setFieldValue('showSaveAddressModal', false)}
        title="Address Name"
        showCloseButton
      />

      <div className="m-4 px-4">
        <FormTextField
          required
          name="saveAddressAlias"
          type="text"
          label="Name"
          onChange={handleChange}
        />

        <StandardButton
          label="Save Address"
          themeKey="continueButton"
          disabled={values.saveAddressAlias === '' || requestInProgress}
          onClick={() => {
            setRequestInProgress(true);
            dispatch(
              saveAddress({
                SDAddress1: values.street_name,
                SDAddress2: values.locality,
                SDAlias: values.saveAddressAlias,
                SDLatitude: values.lat,
                SDLongitude: values.lng,
              }),
            )
              .unwrap()
              .then(() => {
                setFieldValue('showSaveAddressModal', false);
                setFieldValue('page', values.page - 1);
                dispatch(
                  setDesiredDeliveryAddress({
                    formatted_address: values.formatted_address,
                    street_name: values.street_name,
                    locality: values.locality,
                    lat: Number(values.lat),
                    lng: Number(values.lng),
                  }),
                );
                setRequestInProgress(false);
              })
              .catch(e => {
                enqueueErrorSnackbar(e || e.message || e.reason);
                setRequestInProgress(false);
              });
          }}
        />
      </div>
    </Modal>
  );
};

export default SaveAddressModal;

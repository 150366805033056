import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFeedback, feedbackInitialState } from '../reducers/feedback';
import Api, { FetchParams } from '../utils/Api';

export const fetchFeedback = createAsyncThunk(
  'fetchFeedback',
  async (
    data: {
      orderID?: string;
      uuid?: string;
    },
    { dispatch, rejectWithValue },
  ) => {
    if (Object.keys(data).length === 0) {
      console.warn('No id passed to fetch feedback');
      return rejectWithValue({ message: 'No id passed to fetch feedback' });
    }

    const { uuid, orderID } = data;

    const params: FetchParams = {
      method: 'GET',
      path:
        /**
         * If uuid isn't present in data use authenticated endpoint
         *
         * order -> This is used when the user tries to add feedback to a past order
         *
         * member -> This one passes in the UUID, this kinda acts as an obscured
         * unique public code for the feedback of an order.
         */

        `/api/v1/profile/feedbackloop/${
          !uuid ? `member/${orderID}` : `order/${orderID}?uuid=${uuid}`
        }`,
    };
    try {
      dispatch(setFeedback(feedbackInitialState));

      if (uuid) {
        const response = await Api.fetch(params, 'none');
        dispatch(setFeedback(response.data));
        return;
      }

      const response = await Api.fetch(params, 'member');
      dispatch(setFeedback(response.data));
      return;
    } catch (e) {
      console.warn('Fetch feedback failed', { e });
      return rejectWithValue(e);
    }
  },
);

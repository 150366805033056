import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors } from 'polygon-ordering';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import OrderTotalsPaymentSummary from './OrderTotalsPaymentSummary';
import { useAppSelector } from '../app/hooks';
import Surcharges from './Surcharges';
import PointsSummary from './PointsSummary';
import TotalsSummary from './TotalsSummary';
import Warnings from './Warnings';

const { getOrderTotals } = OrderingSelectors;

interface IProps {
  pointsVisible?: boolean;
  orderCompleted?: boolean;
  useMobileScreen?: boolean | null;
  totalOverride?: number;
}
const OrderTotals: React.FC<IProps> = () => {
  const p = useAppSelector(getThemeLookup);
  const orderTotals = useAppSelector(getOrderTotals);

  if (!orderTotals) {
    return null;
  }

  return (
    <div style={styles.mainContainer}>
      <div
        style={combineStyles(
          styles.horizontalSeperator,
          p('orderTotalsSeperator', ['backgroundColor']),
        )}
      />

      <div style={styles.totalWrapper}>
        <PointsSummary />
        <Surcharges />
        <OrderTotalsPaymentSummary />
        <TotalsSummary />
        <Warnings />
      </div>
    </div>
  );
};

const styles: Styles = {
  totalWrapper: {
    padding: '1.5em 2.25em',
  },
  mainContainer: {
    //padding: '1.5em 2.25em'
  },
  horizontalSeperator: {
    height: 1,
    width: '100%',
  },
};

export default OrderTotals;

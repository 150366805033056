import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';

const initialState = null as PAYMENT_METHOD | null;

const slice = createSlice({
  name: 'currentPaymentMethod',
  initialState,
  reducers: {
    setCurrentPaymentMethod(state, action: PayloadAction<PAYMENT_METHOD | null>) {
      return action.payload || initialState;
    },
  },
});

export const { setCurrentPaymentMethod } = slice.actions;

export default slice.reducer;

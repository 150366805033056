import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getExtremelyShortScreen from '../selectors/getExtremelyShortScreen';
import getOrderInProgress from '../selectors/getOrderInProgress';
import getProfile from '../selectors/getProfile';
import combineStyles from '../utils/combineStyles';
import { COMPLETED_ORDER_SCREEN_ROUTE, INITIAL_SCREEN_ROUTE } from '../hooks/useRoutes';
import ScreenHero from '../components/ScreenHero';
import ScreenFloating from '../components/ScreenFloating';
import RedirectAndPreserveSearch from '../components/RedirectAndPreserveSearch';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Text from '../components/Text';
import StripeProvider from '../components/StripeProvider';
import OrderingWrapper from '../components/OrderingWrapper';
import OrderSummary from '../components/OrderSummary';
import OrderTotals from '../components/OrderTotals';
import PlaceOrderButton from '../components/PlaceOrderButton';
import SendVerificationButton from '../components/SendVerificationButton';
import { initiateCheckout } from '../utils/analytics';
import lodash from 'lodash';

const {
  getOrderSubmitted,
  getMember,
  getGuestOrderingAvailable,
  getStagedPurchases,
  getRootCategory,
  getBrands,
  getOrderTotals,
} = OrderingSelectors;

const { fetchStockBalances } = OrderingOperations;

export const REVIEW_ORDER_SCREEN_ROUTE = '/review-order';

const ReviewOrderScreen = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const orderInProgress = useAppSelector(getOrderInProgress);
  const orderSubmitted = useAppSelector(getOrderSubmitted);
  const extremelyShortScreen = useAppSelector(getExtremelyShortScreen);
  const enableStockPolling = useAppSelector(state => state.config.enableStockPolling);
  const stockPollingInterval = useAppSelector(state => state.config.stockPollingInterval);
  const interval = useRef<NodeJS.Timeout | null>(null);
  const [checkPLUQuantityOnLoad, setCheckPLUQuantityOnLoad] = useState(true);
  const profile = useAppSelector(getProfile);
  const member = useAppSelector(getMember);
  const guestOrderingAvailable = useAppSelector(getGuestOrderingAvailable);
  const orderTotals = useAppSelector(getOrderTotals);
  const stagedPurchases = useAppSelector(getStagedPurchases);
  const brands = useAppSelector(getBrands);
  const rootCategory = useAppSelector(getRootCategory);

  const categories = rootCategory?.subCategories || [];

  const dispatch = useAppDispatch();

  const handleBeginCheckoutAnalytics = () => {
    initiateCheckout(
      stagedPurchases.map(purchaseItem => {
        const category =
          categories.find(category =>
            lodash.map(category.items, 'id').find(id => id === purchaseItem.item.id),
          )?.name || null;
        const brand = brands.find(({ id }) => purchaseItem.brandId === id)?.name || null;
        return {
          ...purchaseItem.item,
          quantity: purchaseItem.quantity,
          totalPrice: purchaseItem.moneyPrice,
          choicesWithQuantity: purchaseItem.choicesWithQuantity,
          brand,
          category,
        };
      }),
      orderTotals!.discountedMoneyPrice,
    );
  };

  useEffect(() => {
    handleBeginCheckoutAnalytics();
    if (checkPLUQuantityOnLoad) {
      dispatch(fetchStockBalances());
      setCheckPLUQuantityOnLoad(false);
    }
    if (enableStockPolling && stockPollingInterval) {
      interval.current = setInterval(
        () => dispatch(fetchStockBalances()),
        stockPollingInterval * 1000,
      );
      return () => {
        clearInterval(interval.current as NodeJS.Timeout);
      };
    }
  }, []);

  const useMobileScreen = deviceTypeMobile || extremelyShortScreen;

  const ScreenComponent = useMobileScreen || extremelyShortScreen ? ScreenHero : ScreenFloating;

  if (orderSubmitted) {
    return <RedirectAndPreserveSearch to={COMPLETED_ORDER_SCREEN_ROUTE} />;
  }

  if (!orderInProgress) {
    return <RedirectAndPreserveSearch to={INITIAL_SCREEN_ROUTE} />;
  }

  const mustBeVerifiedMember = !profile?.verified && !guestOrderingAvailable;

  return (
    <StripeProvider>
      <ScreenComponent parentContainerStyle={useMobileScreen ? {} : styles.desktopParentContainer}>
        <div
          style={combineStyles(
            !useMobileScreen && styles.mainContainerDesktop,
            p('screen', ['backgroundColor']),
            p('reviewOrderScreen', ['backgroundColor']),

            !useMobileScreen && p('reviewOrderScreenDesktop', ['backgroundColor', 'boxShadow']),
            useMobileScreen && p('reviewOrderScreenMobile', ['backgroundColor']),
          )}
        >
          <OrderingWrapper
            title={t('title.checkout')}
            bottomContainer={
              member && mustBeVerifiedMember ? (
                <>
                  <div style={styles.mustSignInSection}>
                    <Text style={p('reviewOrderUnverifiedText', ['color'])}>{t('memberVerificationRequirment')}</Text>
                  </div>
                  <SendVerificationButton />
                </>
              ) : (
                <OrderTotals pointsVisible />
              )
            }
          >
            <OrderSummary />
          </OrderingWrapper>
        </div>

        <PlaceOrderButton />
      </ScreenComponent>
    </StripeProvider>
  );
};

const styles: Styles = {
  mainContainerMobile: {},

  desktopParentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  mainContainerDesktop: {
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    maxWidth: 600,
    margin: '50px 30px 50px',
  },

  mobileCheckoutButton: {
    width: 250,
  },

  mustSignInSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },

  notesTitle: {
    marginTop: 10,
  },

  mobileBalances: {
    marginBottom: 15,
  },

  driverNotes: {
    marginTop: 15,
  },

  fraudPreventionMessageContainer: {
    alignSelf: 'center',
    marginBottom: 50,
    maxWidth: 320,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  fraudPreventionMessage: {
    textAlign: 'center',
    marginTop: 10,
  },

  offerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  offerSection: {
    marginTop: 0,
    marginBottom: 30,
  },
};

export default ReviewOrderScreen;

import { createSelector } from 'reselect';

import getUnenhancedOpenPurchase from './getUnenhancedOpenPurchase';
import getUnenhancedStagedPurchases from './getUnenhancedStagedPurchases';

export default createSelector(
  [getUnenhancedOpenPurchase, getUnenhancedStagedPurchases],
  (openPurchase, stagedPurchases) => {
    if (!openPurchase) {
      return undefined;
    }

    return stagedPurchases.some(
      stagedPurchase => stagedPurchase.id === openPurchase.id,
    );
  },
);

import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getSaleType from './getSaleType';
import { SALE_TYPE } from '../constants/saleType';

export default createSelector(
  [getCurrentOrder, getSaleType],
  (currentOrder, saleType) => {
    const { table } = currentOrder;

    let hasTable = false;

    if (saleType === SALE_TYPE.DINE_IN) {
      hasTable = true;
    }

    if (saleType === SALE_TYPE.TABLE_ORDER) {
      hasTable = true;
    }
    if (hasTable === false) {
      return undefined;
    }

    return table || undefined;
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = ['email', 'mobile'] as PurchaserDetails[];

const slice = createSlice({
  name: '$requiredGuestPurchaserDetails',
  initialState,
  reducers: {
    setRequiredGuestPurchaserDetails(
      state,
      action: PayloadAction<PurchaserDetails[]>,
    ) {
      return action.payload || [];
    },
  },
});

export const { setRequiredGuestPurchaserDetails } = slice.actions;
export default slice.reducer;

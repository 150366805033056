import processAddress from './processAddress';

export default function processDeliveryEstimateBase(
  rawDeliveryEstimate: RawDeliveryEstimateBase,
): DeliveryEstimateBase {
  return {
    available: Boolean(rawDeliveryEstimate.DeliveryAvailable),
    address: processAddress(rawDeliveryEstimate.DeliveryAddress),

    coordinates: {
      latitude: rawDeliveryEstimate.DeliveryAddress.Latitude as number,
      longitude: rawDeliveryEstimate.DeliveryAddress.Longitude as number,
    },
  };
}

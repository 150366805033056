import { AnyAction, combineReducers, Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import bufferReservationId from './bufferReservationId';
import creditCardPaymentInformation from './creditCardPaymentInformation';
import deliveryAddress from './deliveryAddress';
import deliveryCoordinates from './deliveryCoordinates';
import deliveryProvider from './deliveryProvider';
import deliverySurcharges from './deliverySurcharges';
import deliveryTime from './deliveryTime';
import timeEstimateReturned from './timeEstimateReturned';
import locationId from './locationId';
import menu from './menu';
import minimumOrderInformation from './minimumOrderInformation';
import orderingProviderSurchargeInstructions from './orderingProviderSurchargeInstructions';
import offers from './offers';
import readyToApply from './readyToApply';
import saleType from './saleType';
import locationDeliveryEstimates from './locationDeliveryEstimates';
import paymentGatewayConfig from './paymentGatewayConfig';
import { resetOrder } from '../../operations';

const rootReducer: Reducer<OrderingBuffer, AnyAction> = combineReducers({
  bufferReservationId,
  creditCardPaymentInformation,
  readyToApply,
  locationId,
  menu,
  saleType,
  offers,
  deliveryAddress,
  deliveryCoordinates,
  deliveryProvider,
  deliverySurcharges,
  deliveryTime,
  minimumOrderInformation,
  orderingProviderSurchargeInstructions,
  locationDeliveryEstimates,
  paymentGatewayConfig,
  timeEstimateReturned,
});

//reset reducer
//https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store

const resetWrapper = (
  state: ReturnType<typeof rootReducer>,
  action: AnyAction,
) => {
  return rootReducer(
    action.type !== resetOrder.type ? state : undefined,
    action,
  );
};

export default resetWrapper;

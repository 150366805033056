import { createSelector } from 'reselect';
import lodash from 'lodash';

import { getLoyaltyRequiresVerification } from './config';
import getMember from './getMember';

export default createSelector(
  [getMember, getLoyaltyRequiresVerification],
  (member, loyaltyRequiresVerification): boolean => {
    return loyaltyRequiresVerification && !lodash.get(member, 'verified');
  },
);

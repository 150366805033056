import { createSelector } from 'reselect';

import getBuffer from './getBuffer';
import getCurrentOrder from './getCurrentOrder';

const getMinimumOrderInformation = createSelector([getCurrentOrder], order => {
  return order.minimumOrderInformation;
});

export default getMinimumOrderInformation;

export const $getMinimumOrderInformation = createSelector(
  [getMinimumOrderInformation, getBuffer],
  (orderMinInfo, buffer) => {
    if (buffer.minimumOrderInformation) {
      return buffer.minimumOrderInformation;
    }

    return orderMinInfo;
  },
);

import { createSelector } from 'reselect';

import getOrderNotReadyReasons from './getOrderNotReadyReasons';

import { ORDER_NOT_READY_REASON } from '../constants';

const purchaserReasons = [
  ORDER_NOT_READY_REASON.PURCHASER_EMAIL_INVALID,
  ORDER_NOT_READY_REASON.PURCHASER_MOBILE_INVALID,
  ORDER_NOT_READY_REASON.PURCHASER_NAME_INVALID,
  ORDER_NOT_READY_REASON.PURCHASER_FAMILY_NAME_INVALID,
];

export default createSelector([getOrderNotReadyReasons], (reasons): boolean => {
  const nonPurchaser = reasons.filter(
    reason => !purchaserReasons.includes(reason),
  );

  return nonPurchaser.length === 0;
});

import { createSelector } from 'reselect';

import {
  getApiHandlerReady,
  getCouponOrderingMethod,
  getChoiceOrderingMethod,
} from './config';
import getEnabledPaymentMethods from './getEnabledPaymentMethods';

export default createSelector(
  [
    getApiHandlerReady,
    getCouponOrderingMethod,
    getChoiceOrderingMethod,
    getEnabledPaymentMethods,
  ],
  (
    apiHandlerReady,
    couponOrderingMethod,
    choiceOrderingMethod,
    enabledPaymentMethods,
  ): boolean => {
    if (!enabledPaymentMethods.length) {
      console.log('Enabled payment methods dont exist', {
        enabledPaymentMethods,
      });
    }

    return (
      apiHandlerReady &&
      !!couponOrderingMethod &&
      !!choiceOrderingMethod &&
      !!enabledPaymentMethods.length
    );
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../actions/currentOrder';
import { removePaymentGatewayToken } from '../thunks/removePaymentGatewayToken';

const initialState: PaymentGateWayToken[] = [];

const slice = createSlice({
  name: '$paymentGatewayTokens',
  initialState,
  reducers: {
    setPaymentGatewayTokens(
      state,
      action: PayloadAction<PaymentGateWayToken[] | null>,
    ) {
      return action.payload ? action.payload : [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(removePaymentGatewayToken.pending, (state, action) => {
        return state.filter(pgToken => pgToken.token === action.meta.arg.token);
      })
      .addCase(resetOrder, (state, action) => {
        const { preserveMember } = action.payload;
        return preserveMember ? state : initialState;
      });
  },
});

export const { setPaymentGatewayTokens } = slice.actions;
export default slice.reducer;

import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGiftcardSurcharge } from '../reducers/giftcardSurcharge';
import delay from '../utils/delay';

export const fetchGiftcardSurcharge = createAsyncThunk(
  'fetchGiftcardSurcharge',
  async (_data: undefined, { dispatch }) => {
    const path = '/api/v1/giftcard-surcharge';
    await delay(1000);
    const response = await RedcatApiHandler.fetch({ method: 'GET', path });
    dispatch(setGiftcardSurcharge(response.data));
  },
);

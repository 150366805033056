import { createSelector } from 'reselect';
import getOrdering from './getOrdering';

const getOrderMinimumSpendingInclusive = createSelector(
  [getOrdering],
  (ordering: OrderingState) =>
    Boolean(ordering.config.includeCouponDiscountSurcharge),
);

export default getOrderMinimumSpendingInclusive;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import {
  Step,
  Stepper,
  StepConnector,
  StepLabel,
  createTheme,
  ThemeProvider,
  StepIconProps,
  styled,
  stepLabelClasses,
} from '@mui/material';
import { stepConnectorClasses } from '@mui/material/StepConnector';

import { OrderingSelectors, OrderingConstants } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';
import getSaleStatusSteps from '../selectors/getSaleStatusSteps';

import combineStyles from '../utils/combineStyles';
import { useAppSelector } from '../app/hooks';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

import { STATUS_STEP_ICONS } from '../constants/statusSteps';

import Text from './Text';
import { TEXT_PROPERTIES } from '../utils/theme';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

const { getSaleType } = OrderingSelectors;

const { READY_TO_COLLECT } = OrderingConstants;

interface Step {
  id: number | string;
  value: string;
  complete: boolean;
  inProgress: boolean;
}

const CustomStepIconStyles = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
  p: (key: string, properties: ThemeProperties) => React.CSSProperties;
}>(({ theme, ownerState, p }) => ({
  zIndex: 1,
  color: ownerState.completed
    ? p('orderStatusStepUnFinished', ['color']).color || 'white'
    : p('orderStatusStepUnFinished', ['color']).color || 'lightgrey',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  transition: '0.45s all cubic-bezier(0.75, 0.03, 0, 0.96)',
  ...p('orderStatusStepUnFinished', ['backgroundColor']),
  ...(ownerState.completed && p('orderStatusStepIcon', ['fontWeight'])),

  '&:after': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    ...p('orderStatusStepIcon', ['backgroundColor']),
    borderRadius: '100%',
    zIndex: -1,
    transition: '0.45s all cubic-bezier(0.4, 0.07, 0, 0.94)',
    transitionDelay: '0.15s',
    transform: ownerState.completed ? 'scale(1)' : 'scale(0)',
  },
}));

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  const p = useAppSelector(getThemeLookup);

  const icons: { [index: string]: string } = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
  };

  return (
    <div
      style={{
        width: 50,
        height: 50,
        border: '1px solid #D9D9D9',
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CustomStepIconStyles p={p} ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </CustomStepIconStyles>
    </div>
  );
};

const CustomStepsConnector = styled(StepConnector)<{
  p: (key: string, properties: ThemeProperties) => React.CSSProperties;
}>(({ theme, p }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: combineStyles(
    {
      top: 18.5,
      padding: '3px 0',
      borderTop: '1px solid #D9D9D9',
      borderBottom: '1px solid #D9D9D9',
      background: 'white',

      // this will allow the circles look they were unionized
      transform: 'scaleX(0.86)',
    },
    p('orderStatusStepsConnectorBackground', ['backgroundColor']),
  ),
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}:after`]: {
      transform: 'scaleX(1)',
    },
  },
  [`& .${stepConnectorClasses.line}:after`]: combineStyles(
    {
      content: "''",
      height: '100%',
      width: '100%',
      display: 'block',
      transform: 'scaleX(0)',
      transformOrigin: 'left',
      transition: '0.75s all cubic-bezier(0, 0.74, 0, 0.96)',
    },
    p('orderStatusStepsConnector', ['backgroundColor']),
  ),

  [`& .${stepConnectorClasses.line}`]: {
    height: 6,
    border: 0,
    position: 'relative',
    background: 'white',
    transform: 'scaleX(2.1)',
  },
}));

const CustomStepper = styled(Stepper)(({ theme }) => ({
  [`.${stepLabelClasses.label}`]: {
    marginTop: '0.75em !important',
  },
}));

const OrderStatus: React.FC = () => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const saleType = useAppSelector(getSaleType);
  const saleStatusSteps = useAppSelector(getSaleStatusSteps);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  const getLabelKey = (step: Step) => {
    let labelKey = `statusSteps.${step.value}.complete`;

    if (step.value === READY_TO_COLLECT) {
      labelKey = `${labelKey}.${saleType}`;
    }

    return labelKey;
  };

  return (
    <div style={deviceTypeMobile ? {} : styles.mainContainer}>
      <CustomStepper activeStep={0} alternativeLabel connector={<CustomStepsConnector p={p} />}>
        {saleStatusSteps.map((step, index) => (
          <Step completed={step.complete} key={index + 1}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              sx={{
                // Label completed
                '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel': {
                  color: p('orderStatusStepLabelUnfinished', ['color']).color,
                },
                // Label completed
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                  color: p('orderStatusStepLabel', ['color']).color,
                },
              }}
            >
              {t(getLabelKey(step))}
            </StepLabel>
          </Step>
        ))}
      </CustomStepper>
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    maxWidth: 370,
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 5,
    // width: 150,
  },
  orderStatusIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: 38,
    height: 38,
    borderRadius: '55%',
    border: '1px solid lightgrey',
  },
  orderStatusCompleteIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    width: 28,
    height: 28,
    borderRadius: '55%',
  },
  progressLabel: {
    marginTop: '0.85em',
  },
};

export default OrderStatus;

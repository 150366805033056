import React from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { FaLocationArrow } from 'react-icons/fa';
import { FormikValues, useFormikContext } from 'formik';
import TouchableOpacity from './TouchableOpacity';
import { setMapCenter } from '../slices/mapCenter';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import getUserLocation from '../selectors/getUserLocation';
import { FaToggleOff, FaToggleOn } from 'react-icons/fa';
import Text from './Text';

import { styled } from '@mui/system';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import combineStyles from '../utils/combineStyles';

const SearchWrapper = styled('div')({
  borderRadius: 30,
  backgroundColor: 'whitesmoke',
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  //marginRight: 5,
  height: '2.4em',
  alignItems: 'center',
  padding: '0 0.75em',
  transition: '0.25s all ease-out',
  outline: '#DFDFDF solid',
  outlineWidth: 0,
  outlineOffset: -1,

  '&:hover': {
    background: '#EBEBEB',
  },

  '&:focus-within': {
    outlineWidth: 1,
    background: '#fff',
  },
});

const LocationSearch = ({
  placeholder,
  showSaveSwitch = false,
}: {
  placeholder?: string;
  showSaveSwitch?: boolean;
}) => {
  const p = useAppSelector(getThemeLookup);
  const { values, handleChange, setFieldValue } = useFormikContext<FormikValues>();
  const userCoords = useAppSelector(getUserLocation);
  const dispatch = useAppDispatch();

  return (
    <div>
      <SearchWrapper
        style={combineStyles([
          p('searchBar', CONTAINER_PROPERTIES),
          { backgroundColor: p('searchBar', CONTAINER_PROPERTIES).backgroundColor || 'whitesmoke' },
        ])}
      >
        <TouchableOpacity>
          <RiSearchLine size={20} color={p('searchBarIcon', ['color']).color || 'silver'} />
        </TouchableOpacity>

        <input
          className="location-search px-2"
          name="formatted_address"
          placeholder={placeholder}
          value={values.formatted_address}
          onChange={e => {
            setFieldValue('buttonActive', false);
            handleChange(e);
          }}
          style={{ border: 'none', width: '100%', backgroundColor: 'transparent' }}
        />
        <TouchableOpacity onClick={() => dispatch(setMapCenter(userCoords))}>
          <FaLocationArrow size={15} color={p('searchBarIcon', ['color']).color || 'silver'} />
        </TouchableOpacity>
      </SearchWrapper>

      {showSaveSwitch && (
        <div className="mt-2">
          <TouchableOpacity onClick={() => setFieldValue('saveAddress', !values.saveAddress)}>
            {values.saveAddress ? (
              <FaToggleOn
                size={25}
                color={p('saveAddressToggleActive', ['color']).color || 'red'}
              />
            ) : (
              <FaToggleOff size={25} color={p('saveAddressToggle', ['color']).color || 'silver'} />
            )}
            <Text
              style={combineStyles([
                { fontSize: 10, padding: 5 },
                p('saveAddressTitle', TEXT_PROPERTIES),
              ])}
            >
              Save address
            </Text>
          </TouchableOpacity>
        </div>
      )}
    </div>
  );
};

export default LocationSearch;

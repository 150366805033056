import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as ENERGY_TYPE[] | null;

const slice = createSlice({
  name: '$energyDisplayTypes',
  initialState,
  reducers: {
    setEnergyDisplayTypes(state, action: PayloadAction<ENERGY_TYPE[] | null>) {
      return action.payload;
    },
  },
});

export const { setEnergyDisplayTypes } = slice.actions;
export default slice.reducer;

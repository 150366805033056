import { createSelector } from 'reselect';
import getCurrentOrder from './getCurrentOrder';
import getTempTags from './getTempTags';
import getAllergenDietaryDetails from './getAllergenDietaryDetails';

export default createSelector(
  [
    getCurrentOrder,
    (
      {},
      options: number[] | null | undefined,
      includeTempTags: boolean = true,
    ) => ({ options, includeTempTags }),
    getTempTags,
    getAllergenDietaryDetails,
  ],
  (order, args, tempTags, details) => {
    const { options, includeTempTags } = args;
    const { tagsAllergenDietary } = order;
    let allergenTags: TagAllergenDietary[] = [],
      dietaryTags: TagAllergenDietary[] = [],
      customTags: TagAllergenDietary[] = [];
    if (!tagsAllergenDietary || !options)
      return { allergenTags, dietaryTags, customTags };
    options?.forEach(option => {
      const tag = tagsAllergenDietary.find(t => t.recid === option);
      if (tag) {
        if (tag.tag_type === 'ALLERGEN') allergenTags.push(tag);
        if (tag.tag_type === 'DIETARY') dietaryTags.push(tag);
        if (tag.tag_type === 'CUSTOM') customTags.push(tag);
      }
    });

    if (!includeTempTags) {
      return {
        allergenTags: [...allergenTags],
        dietaryTags: [...dietaryTags],
        customTags: [...customTags],
      };
    }

    const tempAllergenTags = details.filter(
      tagDetails =>
        tagDetails.tag_type === 'ALLERGEN' &&
        tempTags?.includes(tagDetails.recid),
    );
    const tempDietaryTags = details.filter(
      tagDetails =>
        tagDetails.tag_type === 'DIETARY' &&
        tempTags?.includes(tagDetails.recid),
    );

    const tempCustomTags = details.filter(
      tagDetails =>
        tagDetails.tag_type === 'CUSTOM' &&
        tempTags?.includes(tagDetails.recid),
    );

    return {
      allergenTags: [...allergenTags, ...tempAllergenTags],
      dietaryTags: [...dietaryTags, ...tempDietaryTags],
      customTags: [...customTags, ...tempCustomTags],
    };
  },
);

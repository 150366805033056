import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from './StandardButton';
import { OrderingHooks, OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import { RiAddLine, RiPencilFill } from 'react-icons/ri';

const { useFormattedCurrency } = OrderingHooks;
const { getOpenPurchasePreviouslyStaged, getOpenPurchase } = OrderingSelectors;
const { stageOpenPurchase } = OrderingOperations;

const StagedPurchaseEditorModalProceed = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const previouslyStaged = useAppSelector(getOpenPurchasePreviouslyStaged);
  const openPurchase = useAppSelector(getOpenPurchase)!;

  const price = useFormattedCurrency({ cents: openPurchase?.moneyPrice });
  const { valid } = openPurchase;

  return (
    <StandardButton
      themeKey="modalProceedButton"
      label={`${
        previouslyStaged ? t('button.purchaseEditor.modified') : t('button.purchaseEditor.new')
      } ${price}${valid ? '' : '*'} `}
      LeftIconComponent={previouslyStaged ? RiPencilFill : RiAddLine}
      onClick={() => {
        dispatch(stageOpenPurchase({}));
      }}
      disabled={disabled}
      disabledStyle={styles.disabledButton}
      style={styles.button}
    />
  );
};

export default StagedPurchaseEditorModalProceed;

const styles = {
  disabledButton: {
    opacity: 0.9,
  },
  button: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
};

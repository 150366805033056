import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchPaymentIntent = createAsyncThunk(
  '$fetchPaymentIntent',
  async (
    data: {
      id: string;
      customer: string;
      total: number;
    },
    { dispatch },
  ) => {
    const path = '/api/v1/sale/intent';

    return RedcatApiHandler.fetch({ method: 'POST', path, body: data });
  },
);

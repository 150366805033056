import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { storeProfile } from '../slices/profile';
import { fetchSavedCards } from './fetchSavedCards';

export const fetchProfile = createAsyncThunk(
  'fetchProfile',
  async (_data: undefined, { dispatch }) => {
    const path = '/api/v1/profile';

    return RedcatApiHandler.authorisedFetch({ method: 'GET', path }).then(response => {
      dispatch(storeProfile(response.data));
      dispatch(fetchSavedCards());
    });
  },
);

import { dollarsToCents } from '../misc';
import { OFFER_TYPE } from '../../constants/offerType';
import { OFFER_FOR_PURCHASE, OFFER_FOR_ENTIRE_ORDER } from '../../constants';
import { bogoValidation, calculateBOGODiscount } from './bogoCoupon';

export const calculateCouponDiscountedPrice = (
  selectedOffer: Offer,
  stagedPurchasesTotals: StagedPurchasesTotals,
  purchasedItems: PurchaseWithTotals[],
  rewardsAccrualRate: number,
  couponOrderingMethod: CouponOrderingMethod,
  choiceOrderingMethod: ChoiceOrderingMethod,
): number => {
  const { moneyPrice: purchasesMoneyPrice, discountedMoneyPrice } =
    stagedPurchasesTotals;
  let couponAmount = 0;
  let discountedAmount = purchasesMoneyPrice;
  if (OFFER_FOR_ENTIRE_ORDER.includes(selectedOffer.type)) {
    if (selectedOffer.type === OFFER_TYPE.AMOUNT_OFF_ORDER) {
      couponAmount = dollarsToCents(selectedOffer.amount);
    } else if (selectedOffer.type === OFFER_TYPE.PERCENTAGE_OFF_ORDER) {
      // NOTE: OFFER_FOR_ENTIRE_ORDER coupons don't include surcharges
      // this looks right but due to floating point limits can be wrong
      // orderMoneyDiscount = (selectedOffer.amount / 100) * purchasesMoneyPrice;

      // Max: "this seems to work" LOL
      // this seems to work

      // Max the above comment does not inspire confidence in your algebra
      // e.g. 20% off from a $20 item
      // a = 100% - 20% = 80%
      const a = 100 - selectedOffer.amount;
      // b = 2000c * 80% = 2000c * (0.8 * 100) = 160000cc
      const b = purchasesMoneyPrice * a;
      // nomd = 160000cc / 100.0 - 2000c
      const negativeOrderMoneyDiscount = b / 100.0 - purchasesMoneyPrice;
      // ca = -nomd
      couponAmount = negativeOrderMoneyDiscount * -1;

      // ca = -((2000c * (100% - 20%)) / 100.0 - 2000c)
      //    = - ((cents * (1 - percentage)) / 100 - cents)
      //    = cents * percentage / 100

      if (selectedOffer.limit !== 0) {
        couponAmount = Math.min(couponAmount, selectedOffer.limit);
      }
    }
    couponAmount = Math.round(couponAmount);
    discountedAmount = purchasesMoneyPrice - couponAmount;

    return discountedAmount < 0 ? 0 : discountedAmount;
  }

  //items specfic coupons
  if (OFFER_FOR_PURCHASE.includes(selectedOffer.type)) {
    return discountedMoneyPrice;
  }

  //bogo coupons
  if (
    selectedOffer.type === OFFER_TYPE.AMOUNT_OFF_BOGO ||
    selectedOffer.type === OFFER_TYPE.PERCENTAGE_OFF_BOGO
  ) {
    if (bogoValidation(selectedOffer, purchasedItems, choiceOrderingMethod)) {
      const { discountedMoneyPrice } = calculateBOGODiscount(
        selectedOffer,
        purchasedItems,
        stagedPurchasesTotals,
        rewardsAccrualRate,
        [],
        couponOrderingMethod,
        choiceOrderingMethod,
      );
      return discountedMoneyPrice;
    } else {
      return purchasesMoneyPrice;
    }
  }
  return 0;
};

import React from 'react';
// import GoogleLogin from 'react-google-login';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import { BsFacebook, BsApple } from 'react-icons/bs';
import AppleLogin from 'react-apple-login';
import { OrderingSelectors } from 'polygon-ordering';
import setLocalValue from '../utils/setLocalValue';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { IN_DEVELOPMENT } from '../constants';
import redcatApiFetch from '../api';
import { registerUser } from '../utils/analytics';
import { syncMember } from '../thunks/syncMember';
import { setCurrentModal } from '../thunks/setCurrentModal';
import HorizontalLine from '../components/HorizontalLine';
import { OAUTH_EXTRA_INFO_MODAL_ID } from '../modals/OAuthExtraInfoModal';
import { enqueueErrorSnackbar } from '../utils/snackbar';

const { getOrderTotals, getStagedPurchases, getOAuthExtraInformationConfig } = OrderingSelectors;
const OAuthLogin = () => {
  const dispatch = useAppDispatch();
  const orderTotals = useAppSelector(getOrderTotals);
  const stagedPurchases = useAppSelector(getStagedPurchases);
  const googleLoginButtonClientKey = useAppSelector(
    state => state.config.googleLoginButtonClientKey,
  );
  const facebookLoginButtonClientId = useAppSelector(
    state => state.config.facebookLoginButtonClientId,
  );
  const appleLoginButtonClientKey = useAppSelector(state => state.config.appleLoginButtonClientKey);
  const enableGoogleLogin = useAppSelector(state => state.config.enableGoogleLogin);
  const enableAppleLogin = useAppSelector(state => state.config.enableAppleLogin);
  const enableFacebookLogin = useAppSelector(state => state.config.enableFacebookLogin);
  const oAuthExtraInformationConfig = useAppSelector(getOAuthExtraInformationConfig);
  const { enableOAuthAdditionalDetails } = oAuthExtraInformationConfig || {};

  const oauthVerify = async (response: any, path: string) => {
    try {
      const res = await redcatApiFetch({
        path,
        method: 'POST',
        body: { response },
      });

      if (res.data.NewUser) {
        if (enableOAuthAdditionalDetails) dispatch(setCurrentModal(OAUTH_EXTRA_INFO_MODAL_ID));
        registerUser(
          res.data.Provider,
          res.success,
          orderTotals,
          stagedPurchases.map(purchaseItem => purchaseItem.item),
        );
      }
      //@ts-ignore
      if (IN_DEVELOPMENT && res.token) {
        //@ts-ignore
        setLocalValue('memberAuthToken', res.token);
      }
      dispatch(syncMember());
    } catch (e) {
      enqueueErrorSnackbar(e.message);
    }
  };

  const googleLogin = (response: any) => {
    oauthVerify(response, '/sso/google/redirect');
  };

  const facebookLogin = async (response: any) => {
    if (response.status !== 'unknown') {
      oauthVerify(response, '/sso/facebook/redirect');
    }
  };

  const responseAppleLogin = (response: any) => {
    if (response.authorization) {
      response.authorization.redirectURI =
        window.location.protocol + '//' + window.location.host + window.location.pathname;
    }

    if (!response.error) {
      oauthVerify(response, '/sso/apple/redirect');
    }
  };
  const showHorizantalLine =
    (appleLoginButtonClientKey ||
      facebookLoginButtonClientId ||
      googleLoginButtonClientKey !== null) &&
    (enableAppleLogin || enableFacebookLogin || enableGoogleLogin);

  return (
    <>
      {showHorizantalLine && <HorizontalLine text="or" />}
      {enableGoogleLogin && googleLoginButtonClientKey && (
        <GoogleOAuthProvider clientId={googleLoginButtonClientKey}>
          <div style={styles.googleButtonContainer}>
            <GoogleLogin
              width="260"
              logo_alignment="center"
              text="continue_with"
              onSuccess={googleLogin}
            />
          </div>
        </GoogleOAuthProvider>
      )}

      {enableFacebookLogin && facebookLoginButtonClientId && (
        <div style={styles.facebookButtonContainer}>
          <FacebookLogin
            appId={facebookLoginButtonClientId}
            // autoLoad={true}
            fields="name,email"
            callback={facebookLogin}
            textButton="Continue with Facebook"
            cssClass="facebookLoginButton"
            icon={<BsFacebook style={styles.facebookIcon} />}
          />
        </div>
      )}
      {enableAppleLogin && appleLoginButtonClientKey && (
        <div style={styles.appleButtonContainer}>
          <AppleLogin
            scope="email name"
            {...{
              clientId: appleLoginButtonClientKey,
              redirectURI:
                window.location.protocol + '//' + window.location.host + window.location.pathname,
            }}
            usePopup={true}
            responseType="code id_token"
            responseMode="form_post"
            render={(renderProps: any) => (
              <button style={styles.appleButton} onClick={renderProps.onClick}>
                <BsApple style={styles.appleIcon} />
                Continue with Apple
              </button>
            )}
            callback={responseAppleLogin}
          />
        </div>
      )}
    </>
  );
};

export default OAuthLogin;

const styles: Styles = {
  // do this otherwise any children without a minSize will
  // be squashed if there isn't room (instead of overflow scroll)
  googleButtonContainer: {
    marginTop: 35,
    width: '100%',
  },
  googleButton: {
    width: '100%',
    backgroundColor: 'white',
    fontSize: 16,
    borderRadius: '4px',
  },
  facebookButtonContainer: {
    marginTop: 10,
  },
  facebookIcon: {
    color: 'blue',
    marginRight: 10,
    fontSize: '18px',
    verticalAlign: 'middle',
  },
  appleButtonContainer: {
    marginTop: 10,
    width: 260,
  },
  appleButton: {
    width: '100%',
    backgroundColor: 'white',
    padding: 10,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    fontSize: '14px',
    lineHeight: '16px',
    cursor: 'pointer',
  },
  appleIcon: {
    marginRight: 10,
    fontSize: '18px',
    marginBottom: -2,
  },
};

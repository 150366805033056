import { createSelector } from 'reselect';
import lodash from 'lodash';

import { OrderingSelectors } from 'polygon-ordering';

import distanceBetweenCoordinates from '../utils/calculateDistanceBetweenCoords';

import getUserLocation from './getUserLocation';

const { getLocationsForDisplay } = OrderingSelectors;

export default createSelector(
  [getLocationsForDisplay, getUserLocation],
  (locations, userLocation) => {
    return lodash.mapValues(locations, location => ({
      ...location,
      distance: distanceBetweenCoordinates(userLocation, {
        latitude: location.latitude,
        longitude: location.longitude,
      }),
    }));
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as OrderingWindowPadding | null;

const slice = createSlice({
  name: '$orderingWindowPadding',
  initialState,
  reducers: {
    setOrderingWindowPadding(
      state,
      action: PayloadAction<OrderingWindowPadding | null | undefined>,
    ) {
      return action.payload;
    },
  },
});

export const { setOrderingWindowPadding } = slice.actions;
export default slice.reducer;

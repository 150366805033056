import { createSelector } from 'reselect';
import { RootState } from 'store';
import { p } from '../utils/theme';

import getTheme from './getTheme';

const getEnableThemeKeyHints = (state: RootState) => state.config.enableThemeKeyHints;

export default createSelector(
  [getTheme, getEnableThemeKeyHints],
  (theme: Themes, enableThemeKeyHints: boolean) => {
    return (key: string, properties: ThemeProperties) =>
      p(theme, key, properties, { enableThemeKeyHints });
  },
);

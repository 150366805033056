import React, { useCallback, useEffect } from 'react';
import Text from '../components/Text';
import { useTranslation } from 'react-i18next';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import combineStyles from '../utils/combineStyles';
import LoyaltyTitle from '../components/LoyaltyTitle';
import RatingStars from '../components/RatingStars';

import { useFormikContext, FormikValues } from 'formik';
import TextField from '@mui/material/TextField';

export const FEEDBACK_STORE_SCREEN = '/feedback/store';

const SubFeedbackStars = ({
  label,
  setStars,
  stars,
  starLabel,
}: {
  label: string;
  setStars: (number: number) => void;
  stars: number;
  starLabel?: {
    lowest?: string;
    highest?: string;
  };
}) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  return (
    <div style={styles.subFeedbackStars}>
      <Text
        style={combineStyles(p('defaultText', TEXT_PROPERTIES), p('subFeedback', TEXT_PROPERTIES))}
      >
        {label}
      </Text>
      <RatingStars
        setStars={setStars}
        stars={stars}
        label={starLabel}
        labelContainerStyle={{
          // We cancel out the height via margin bottom
          // to make the text floating so that when this
          // is used in a flex row the stars are vertically
          // aligned to the label next to it.
          marginBottom: -34,
        }}
      />
    </div>
  );
};

const FeedbackStoreScreen = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  const { values, handleChange, setFieldValue, handleBlur } = useFormikContext<FormikValues>();
  const formValues = values as Feedback;
  const storeValues = formValues.store;

  console.log({ formValues });
  if (!storeValues) return <div>Backend didn't send store object during feedback loop fetch</div>;

  const setStarsForStore = useCallback((newValue: number) => {
    setFieldValue(`store.rating`, newValue);
  }, []);

  const generateSetStarsCallback = (ratingType: string) => {
    return useCallback(
      (newValue: number) => {
        setFieldValue(`store.subRatings.${ratingType}`, newValue);
      },
      [setFieldValue, ratingType],
    );
  };

  const setStarsSpeed = generateSetStarsCallback('speed');
  const setStarsAccuracy = generateSetStarsCallback('accuracy');
  const setStarsQuality = generateSetStarsCallback('quality');

  const subRatings = storeValues.subRatings;

  return (
    <div>
      <LoyaltyTitle
        title={t('title.feedback.store.overall', {
          storeName: storeValues.storeName,
        })}
        titleTranslated
      />

      <div style={styles.initialRatingStarsWrapper}>
        <RatingStars setStars={setStarsForStore} stars={storeValues.rating} />
      </div>

      {storeValues.rating > 0 && (
        <div>
          {subRatings && (
            <>
              {subRatings.speed && (
                <SubFeedbackStars
                  label={t('title.feedback.store.speed')}
                  setStars={setStarsSpeed}
                  stars={subRatings.speed}
                  starLabel={{
                    lowest: 'Slow',
                    highest: 'Fast',
                  }}
                />
              )}
              {subRatings.accuracy && (
                <SubFeedbackStars
                  label={t('title.feedback.store.accuracy')}
                  setStars={setStarsAccuracy}
                  stars={subRatings.accuracy}
                  starLabel={{
                    lowest: 'Poor',
                    highest: 'Great',
                  }}
                />
              )}
              {subRatings.quality && (
                <SubFeedbackStars
                  label={t('title.feedback.store.quality')}
                  setStars={setStarsQuality}
                  stars={subRatings.quality}
                  starLabel={{
                    lowest: 'Bad',
                    highest: 'Awesome',
                  }}
                />
              )}
            </>
          )}

          <TextField
            multiline
            rows={6}
            label={t('form.placeholder.feedback.store')}
            style={{
              width: '100%',
            }}
            value={storeValues.additionalDetails}
            onChange={handleChange('store.additionalDetails')}
            onBlur={handleBlur('store.additionalDetails')}
          />
        </div>
      )}
    </div>
  );
};

export default FeedbackStoreScreen;

const styles: Styles = {
  subFeedbackStars: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    //width: '100%',
    marginBottom: 60,
  },
  initialRatingStarsWrapper: {
    marginTop: 50,
    marginBottom: 30,
  },
};

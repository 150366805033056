import { checkTimeAvailability } from './daypartMenuAvailability';

export default (
  purchase?: PurchaseWithTotals,
  items?: Items,
  daypartConfig?: any,
): boolean => {
  if (!purchase) return false;
  const { item: rootItem, choicesWithQuantity, sizeId } = purchase;

  const dayPartTimeBracket = daypartConfig
    ? daypartConfig.dayPartTimeBracket
    : 0;
  const isComposite = !!rootItem.sizes.length;
  const item =
    (isComposite && rootItem.sizes.find(s => s.id === sizeId)) || rootItem;

  const { availability, days, start, end } = item;

  let stagePurchaseAvail = checkTimeAvailability(
    availability,
    days,
    start,
    end,
    dayPartTimeBracket,
  );

  if (!items) return availability ? stagePurchaseAvail : true;

  choicesWithQuantity.forEach(c => {
    const choice = items[c.id];
    const { availability, days, start, end } = choice;
    stagePurchaseAvail =
      stagePurchaseAvail &&
      (availability
        ? checkTimeAvailability(
            availability,
            days,
            start,
            end,
            dayPartTimeBracket,
          )
        : true);
  });
  return stagePurchaseAvail;
};

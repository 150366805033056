import lodash from 'lodash';
import moment from 'moment';

import { safeDate } from '../misc';
import combineAddressParts from '../ordering/combineAddressParts';
import processTradingHours from './processTradingHours';
import { SALE_TYPE } from '../../constants/saleType';

export default function processLocationShort(
  rawLocation: RawLocationShort,
): POSLocationShort {
  const brandIds = (rawLocation.VirtualBrands || []).map(String);
  let physicalBrand;

  if (rawLocation.BrandID != null) {
    physicalBrand = rawLocation.BrandID;
    brandIds.unshift(rawLocation.BrandID);
  }

  return {
    id: rawLocation.StoreID,
    name: rawLocation.LocationName,
    openingTime: safeDate(rawLocation.OpeningTimeToday),
    closingTime: safeDate(rawLocation.ClosingTimeToday),
    openNow: rawLocation.OpenNow,
    latitude: rawLocation.Latitude,
    longitude: rawLocation.Longitude,
    status: rawLocation.StoreStatus,
    phone: rawLocation.Phone,
    online: rawLocation.StoreStatus === 'Online',
    // TODO
    supportedSaleTypes: Object.entries(rawLocation.SaleTypes ?? {}).reduce(
      (sum, [saleType, isAvailable]) => {
        isAvailable && sum.push(parseInt(saleType));
        return sum;
      },
      [] as SALE_TYPE[],
    ),
    hidden: false,

    address: {
      address1: rawLocation.Address1,
      address2: rawLocation.Address2,
      postCode: rawLocation.PostCode,
      state: rawLocation.State,
      suburb: rawLocation.Suburb,
      country: rawLocation.Country,
      combinedShort: combineAddressParts([
        rawLocation.Address1,
        rawLocation.Address2,
        rawLocation.Suburb,
      ]),
      combinedLong: combineAddressParts([
        rawLocation.Address1,
        rawLocation.Address2,
        rawLocation.Suburb,
        rawLocation.State,
        rawLocation.PostCode,
      ]),
    },

    brandIds,
    physicalBrand,
    averageOrderWaitTime: rawLocation.AvgOrderTime || 0,
    outOfSessionOrderingEnabled: rawLocation.OrderAfterHours ?? true,

    _isFullData: false,
  };
}

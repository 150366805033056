import { IN_DEVELOPMENT, PERSIST_KEY } from '../constants';

// NOTE: to run locally, use the devtools to configure a localStorage key/value like:
// orderingEndpoint: "https://example.redcatcloud.com.au"

// In production the endpoint will be determined by inspecting the current URL

export function promptForDevEndpoint(): string | null {
  const currentEndpoint = localStorage.getItem('orderingEndpoint');

  let newEndpoint = prompt(
    `Please enter server for DEV mode:

(To edit this value later, look in Local Storage for a key named 'orderingEndpoint')`,
    currentEndpoint || 'https://example.redcatcloud.com.au',
  );

  newEndpoint = newEndpoint || currentEndpoint;

  if (newEndpoint) {
    // fix up missing protocol
    if (!newEndpoint.startsWith('http')) {
      newEndpoint = `http${newEndpoint.endsWith('redcatcloud.com.au') ? 's' : ''}://${newEndpoint}`;
    }

    localStorage.setItem('orderingEndpoint', newEndpoint);
  } else {
    alert('no endpoint configured');
  }

  if (newEndpoint !== currentEndpoint) {
    localStorage.removeItem(`persist:${PERSIST_KEY}`);
    window.location.reload();
  }

  return newEndpoint;
}

export function determineEndpoint(): string | null {
  if (IN_DEVELOPMENT) {
    let endpoint = localStorage.getItem('orderingEndpoint');

    if (!endpoint) {
      endpoint = promptForDevEndpoint();
    }

    return endpoint;
  } else {
    const currentURL = new URL(window.location.href);
    return currentURL.origin;
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { FetchParams } from '../utils/Api';
import { fetchFavouriteOrders } from './fetchFavouriteOrders';

export const deleteFavouriteOrder = createAsyncThunk(
  '$deleteFavouriteOrder',
  async (data: { id: string }, { dispatch, rejectWithValue }) => {
    const { id } = data;

    try {
      const params: FetchParams = {
        path: `/api/v1/profile/favourites/${id}`,
        method: 'DELETE',
      };

      await Api.fetch(params, 'member');

      await dispatch(fetchFavouriteOrders({}));
    } catch (e) {
      console.error('Delete favourite order failed', { e });
      return rejectWithValue(e);
    }
  },
);

import React, { useEffect, useRef } from 'react';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors, determineLocationSessions } from 'polygon-ordering';

import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import LocationListItem from './LocationListItem';
import calculateDistanceBetweenCoords from '../utils/calculateDistanceBetweenCoords';
import SectionTitle from './SectionTitle';

const {
  getLocationDeliveryEstimates,
  getLocationsForDisplay,
  getOrderingWindowPadding,
  getDeliveryCoordinates,
  getDeliveryRequiresLocationSelection,
} = OrderingSelectors;

const DeliveryLocations = ({
  selectedLocationId,
  setSelectedLocationId,
}: {
  selectedLocationId: string | null;
  setSelectedLocationId: (id: string) => void;
}) => {
  const sectionRef = useRef<null | HTMLDivElement>(null);
  const locationDeliveryEstimates = useAppSelector(getLocationDeliveryEstimates);
  const locations = useAppSelector(getLocationsForDisplay);
  const orderingWindowPadding = useAppSelector(getOrderingWindowPadding);
  const deliveryRequiresLocationSelection = useAppSelector(getDeliveryRequiresLocationSelection);

  const userLocation = useAppSelector(state => state.currentUserLocation);
  const deliveryLocation = useAppSelector(getDeliveryCoordinates);
  const selectedAddress = useAppSelector(state => state.desiredDeliveryAddress);
  const selectedAddressLocation = selectedAddress && {
    latitude: selectedAddress.lat,
    longitude: selectedAddress.lng,
  };
  const effectiveUserLocation = userLocation ?? deliveryLocation ?? selectedAddressLocation;

  const estimateLocations =
    effectiveUserLocation &&
    locationDeliveryEstimates
      .map(estimate => {
        let location = locations[estimate.locationId];

        if (!location) {
          return null;
        }

        return {
          ...location,
          ...determineLocationSessions(location, orderingWindowPadding, SALE_TYPE.DELIVERY),
          distance: calculateDistanceBetweenCoords(effectiveUserLocation, {
            latitude: location.latitude,
            longitude: location.longitude,
          }),
          bestDeliveryTime: estimate.bestDeliveryTime,
        };
      })
      .filter(location => location != null);

  useEffect(() => {
    if (deliveryRequiresLocationSelection && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [deliveryRequiresLocationSelection]);

  return (
    <div className="m-3 p-2" ref={sectionRef}>
      <SectionTitle value="SELECT LOCATION" />
      <div className="mx-1 my-2">
        {estimateLocations?.map((location: any) => (
          <LocationListItem
            location={location}
            key={location.id}
            onClick={() => setSelectedLocationId(location.id)}
            activateBgColor={selectedLocationId === location.id}
          />
        ))}
      </div>
    </div>
  );
};

export default DeliveryLocations;

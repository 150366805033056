import React from 'react';
import { useTranslation } from 'react-i18next';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text'; // TODO: rework this to use Text component
import { useAppDispatch, useAppSelector } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

export const ITEM_CART_AVAILABILITY_MODAL = 'ITEM_CART_AVAILABILITY_MODAL';

const ItemCartAvailabilityModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);

  const closeModal = () => dispatch(setCurrentModal(null));

  return (
    <Modal
      title={t('itemCartWarningTitle')}
      desktopMaxWidth={500}
      desktopMinWidth={500}
      enableCloseButton={false}
    >
      <Text
        block
        style={{
          textAlign: 'center',
          padding: isMobileDevice ? 0 : '0px 25px',
          fontSize: isMobileDevice ? 14 : 16,
        }}
        value={t('unavailablePurchasesWarning.text')}
        themeKey="messageModalMessage"
      />
      <div style={{ padding: isMobileDevice ? '15px 0px' : '10px 0px' }}>
        <StandardButton
          label={t('button.ok')}
          onClick={() => {
            closeModal();
          }}
          themeKey="messageModalButton"
        />
      </div>
    </Modal>
  );
};

export default ItemCartAvailabilityModal;

import moment from 'moment';

export default function calculateOrderingWindow(
  openingTime: string | undefined,
  closingTime: string | undefined,
  averageOrderWaitTime: number = 0,
  openingPadding: number = 0,
  closingPadding: number = 0,
) {
  return {
    orderingWindowStart: moment(openingTime)
      .add(openingPadding, 'minutes')
      .add(averageOrderWaitTime, 'minutes')
      .toISOString(),
    orderingWindowEnd: moment(closingTime)
      .subtract(closingPadding, 'minutes')
      .subtract(averageOrderWaitTime, 'minutes')
      .toISOString(),
  };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import lodash from 'lodash';

const MAXIMUM_CACHE_AGE_DAYS = 7;

const initialState: AddressCacheState = {};

const slice = createSlice({
  name: 'addressCache',
  initialState,
  reducers: {
    cacheAddress(state, action: PayloadAction<AddressCache>) {
      const { results, address } = action.payload;

      return {
        ...state,
        [address]: {
          dayOfYear: moment().dayOfYear(),
          results,
        },
      };
    },
    cleanAddressCache(state) {
      // NOTE: this is a bad action because the result differs based on when it runs

      return lodash.pickBy(
        state,
        ({ dayOfYear }: { dayOfYear: number }) =>
          Math.abs(dayOfYear - moment().dayOfYear()) < MAXIMUM_CACHE_AGE_DAYS,
      );
    },
  },
});

export const { cacheAddress, cleanAddressCache } = slice.actions;

export default slice.reducer;

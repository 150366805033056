import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../actions/currentOrder';

const initialState = null as FavouriteOrder[] | null;

const slice = createSlice({
  name: '$favouriteOrders',
  initialState,
  reducers: {
    setFavouriteOrders(state, action: PayloadAction<FavouriteOrder[] | null>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      const { preserveMember } = action.payload;
      return preserveMember ? state : initialState;
    });
  },
});

export const { setFavouriteOrders } = slice.actions;
export default slice.reducer;

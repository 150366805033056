import { createSelector } from 'reselect';

import { OrderingSelectors, OrderingConstants } from 'polygon-ordering';

const { getOrderNotReadyReasons } = OrderingSelectors;
const { ORDER_NOT_READY_REASON } = OrderingConstants;

export default createSelector([getOrderNotReadyReasons], orderNotReadyReasons => {
  return orderNotReadyReasons.includes(ORDER_NOT_READY_REASON.MINIMUM_ORDER_NOT_MET);
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setBufferDeliveryProvider } from '../reducers/buffer/deliveryProvider';
import { setBufferDeliverySurcharges } from '../reducers/buffer/deliverySurcharges';
import { setBufferDeliveryTime } from '../reducers/buffer/deliveryTime';
import { setBufferLocationId } from '../reducers/buffer/locationId';
import { setBufferMinimumOrderInformation } from '../reducers/buffer/minimumOrderInformation';
import { setBufferOrderingProviderSurchargeInstructions } from '../reducers/buffer/orderingProviderSurchargeInstructions';
import { setBufferTimeEstimateReturned } from '../reducers/buffer/timeEstimateReturned';
import { setBestEstId } from '../reducers/currentOrder/bestEstId';

export const applyLocationEstimate = createAsyncThunk(
  '$applyLocationEstimate',
  async (locationEstimate: LocationDeliveryEstimate, { dispatch }) => {
    try {
      dispatch(setBufferDeliveryProvider(locationEstimate.deliveryProvider));
      dispatch(setBufferDeliverySurcharges(locationEstimate.surcharges));
      dispatch(setBufferDeliveryTime(locationEstimate.bestDeliveryTime));
      dispatch(
        setBufferTimeEstimateReturned(locationEstimate.timeEstimateReturned),
      );
      dispatch(setBufferLocationId(locationEstimate.locationId));
      dispatch(
        setBufferMinimumOrderInformation(
          locationEstimate.minimumOrderInformation,
        ),
      );
      dispatch(
        setBufferOrderingProviderSurchargeInstructions(
          locationEstimate.orderingProviderSurchargeInstructions,
        ),
      );
      dispatch(setBestEstId(locationEstimate.bestEstId));
    } catch (e) {
      console.log('Apply Location Estimate Failed', e);
    }
  },
);

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import { OrderingHooks, OrderingSelectors } from 'polygon-ordering';

// selectors
import getConfig from '../selectors/getConfig';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import Text from '../components/Text';
import combineStyles from '../utils/combineStyles';
import getProfile from '../selectors/getProfile';

// components
import { centsToDollars, formattedDollars } from '../utils/misc';

const { useFormattedCurrency } = OrderingHooks;
const { getMember } = OrderingSelectors;

const CreditLoyaltyBalance = () => {
  const profile = useAppSelector(getProfile);
  const loyaltyTiers = useAppSelector(state => state.loyaltyTiers);
  const tier = loyaltyTiers?.filter(tier => tier.tierName === profile?.loyaltyTierName)[0];
  const enableTieredLoyalty = useAppSelector(state => state.config.enableTieredLoyalty);

  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const member = useAppSelector(getMember);
  const config = useAppSelector(getConfig);

  const { loyaltyMoneyName, loyaltyPointsName, displayPointsAsWholeNumber } = config;
  const creditLoyaltyThreshold = config.creditLoyaltyThreshold ?? 10; // this should never default to 10 but it won't error now

  const getPointsText = (val: number) =>
    displayPointsAsWholeNumber ? Math.round(val) : val.toFixed(1);

  const moduloPoints = (member?.pointsBalance ?? 0) % creditLoyaltyThreshold;

  return (
    <div style={styles.mainContainer}>
      {/* progress bar with points on one end and price on the other, and a string saying how far from the next reward */}
      <div style={styles.creditLoyaltyTopLine}>
        {/* points/money line */}
        {/* STAMPCARDS-TODO: figure out some css magic to make the balances wrap their text */}
        <div
          style={combineStyles(
            styles.creditLoyaltyBalanceContainer,
            enableTieredLoyalty && {
              color: tier?.tierTextColour,
            },
          )}
        >
          <Text
            themeKey="accountBalanceAmount_cr"
            style={combineStyles(
              enableTieredLoyalty && {
                color: tier?.tierTextColour,
              },
            )}
          >
            {getPointsText(member?.pointsBalance ?? 0)}
          </Text>
          <div
            style={combineStyles({ marginLeft: 5 }, p('creditLoyaltyPointsText', TEXT_PROPERTIES))}
          >
            {loyaltyPointsName}
          </div>
        </div>
        <div
          style={combineStyles(
            styles.creditLoyaltyBalanceContainer,
            styles.creditLoyaltyMoneyContainer,
            enableTieredLoyalty && {
              color: tier?.tierTextColour,
            },
          )}
        >
          <Text
            themeKey="accountBalanceAmount_cr"
            style={combineStyles(
              enableTieredLoyalty && {
                color: tier?.tierTextColour,
              },
            )}
          >
            {useFormattedCurrency({ cents: member?.moneyBalance })}
          </Text>
          <div
            style={combineStyles(p('creditLoyaltyPointsText', TEXT_PROPERTIES), {
              marginLeft: 5,
              textAlign: 'start',
            })}
          >
            {loyaltyMoneyName}
          </div>
        </div>
      </div>
      <div style={styles.progressBarContainer}>
        <div
          style={combineStyles(
            styles.progressBarBackground,
            p('creditLoyaltyProgressDefault', CONTAINER_PROPERTIES),
          )}
        >
          <div
            style={combineStyles(
              styles.progressBarProgress,
              p('creditLoyaltyProgress', CONTAINER_PROPERTIES),
              {
                width: `${10 + 80 * (moduloPoints / creditLoyaltyThreshold)}%`,
              },
              enableTieredLoyalty && {
                backgroundColor: tier?.tierTextColour,
              },
            )}
          >
            {/* little triangle on the end */}
            <div
              style={{
                position: 'absolute',
                right: -10,

                borderTop: '10px solid transparent',
                borderBottom: '10px solid transparent',
                borderLeft: `10px solid ${
                  (enableTieredLoyalty && tier?.tierTextColour) ||
                  p('creditLoyaltyProgress', CONTAINER_PROPERTIES)['backgroundColor'] ||
                  '#000000'
                }`,
              }}
            />
          </div>
        </div>
      </div>
      <div style={styles.bottomLine}>
        <Text
          style={combineStyles(
            enableTieredLoyalty && {
              color: tier?.tierTextColour,
            },
            p('creditLoyaltyPointsText', TEXT_PROPERTIES),
          )}
        >
          {t('accountPage.creditLoyalty.progressMessage', {
            points: getPointsText(creditLoyaltyThreshold - moduloPoints),
          })}
        </Text>
      </div>
    </div>
  );
};

export default CreditLoyaltyBalance;

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  creditLoyaltyTopLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  creditLoyaltyBalanceContainer: {
    // flexGrow: 1,
    flexShrink: 1,
    flexBasis: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'start',
  },
  creditLoyaltyMoneyContainer: {
    textAlign: 'end',
  },
  progressBarContainer: {
    height: 20,
    marginBottom: 10,
  },
  progressBarBackground: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  progressBarProgress: {
    position: 'relative',
    height: '100%',
    width: '10%',
  },
  bottomLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
};

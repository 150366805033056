import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearBuffer, setBufferReadyToApply } from '../../actions/buffer';

const initialState = false;

const slice = createSlice({
  name: '$bufferReadyToApply',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clearBuffer, (state, action) => {
        return initialState;
      })
      .addCase(setBufferReadyToApply, (state, action) => {
        return Boolean(action.payload.readyToApply);
      });
  },
});

export default slice.reducer;

import { createSelector } from 'reselect';
import lodash from 'lodash';

import getStagedPurchases from './getStagedPurchases';
import getStockBalances from './getStockBalances';

export default createSelector(
  [getStagedPurchases, getStockBalances],
  (stagedPurchases, stockBalances): CartQuantityCheck => {
    let cartContainsInvalidQuantities: boolean = false;
    let trackQuantityUpdates: { [key: number]: number } = {};

    let quantityMap: { [key: number]: number } = {};
    let iterateStagedPurchases: { [key: number]: number } = {};

    // Create map of cart PLUs and their quantities
    stagedPurchases.map(stagedPurchase => {
      lodash.update(quantityMap, stagedPurchase.item.id, n => {
        return n ? n + stagedPurchase.quantity : stagedPurchase.quantity;
      });
      if (stagedPurchase.choicesWithQuantity.length) {
        stagedPurchase.choicesWithQuantity.map(choice => {
          lodash.update(quantityMap, choice.id, n => {
            return n
              ? n + choice.quantity * stagedPurchase.quantity
              : choice.quantity * stagedPurchase.quantity;
          });
        });
      }
    });

    // Update map based on known stock balances
    Object.entries(quantityMap).map(([k, v]) => {
      const stockBalanceObject = lodash.find(stockBalances, [
        'PLUCode',
        parseInt(k),
      ]);
      // TODO: how should we define the type and get rid of the error?
      const balance =
        stockBalanceObject?.Balance != undefined
          ? stockBalanceObject?.Balance
          : undefined;
      // TODO: v can't be found?
      if (balance != undefined && balance < v) {
        lodash.update(quantityMap, k, () => {
          return balance;
        });
      }
    });

    // Update quantities of each StagedPurchase based on quantityMap
    Object.entries(stagedPurchases).map(([key, stagedPurchaseObject]) => {
      // single items only
      const itemBalanceOfIndividualItem =
        quantityMap[parseInt(stagedPurchaseObject.item.id)];
      const itemId = stagedPurchaseObject.item.id;

      if (stagedPurchaseObject.quantity > itemBalanceOfIndividualItem) {
        trackQuantityUpdates = {
          ...trackQuantityUpdates,
          [itemId]: itemBalanceOfIndividualItem,
        };
        cartContainsInvalidQuantities = true;
      } else {
        lodash.update(
          iterateStagedPurchases,
          stagedPurchaseObject.item.id,
          n => {
            return n
              ? n + stagedPurchaseObject.quantity
              : stagedPurchaseObject.quantity;
          },
        );

        // instead of checking item against quantityMap you could directly look into the stockBalances
        if (
          iterateStagedPurchases[parseInt(stagedPurchaseObject.item.id)] >
          quantityMap[parseInt(stagedPurchaseObject.item.id)]
        ) {
          trackQuantityUpdates = {
            ...trackQuantityUpdates,
            [itemId]: itemBalanceOfIndividualItem,
          };
          cartContainsInvalidQuantities = true;
        }
      }

      // choice sets only
      if (stagedPurchaseObject.choicesWithQuantity.length > 0) {
        stagedPurchaseObject.choicesWithQuantity.map(choice => {
          const itemBalance: number = quantityMap[parseInt(choice.id)];
          const choiceId: number = parseInt(choice.id);

          if (choice.quantity * stagedPurchaseObject.quantity > itemBalance) {
            trackQuantityUpdates = {
              ...trackQuantityUpdates,
              [choiceId]: itemBalance,
            };
            cartContainsInvalidQuantities = true;
          } else {
            lodash.update(iterateStagedPurchases, choice.id, n => {
              return n
                ? n + choice.quantity * stagedPurchaseObject.quantity
                : choice.quantity * stagedPurchaseObject.quantity;
            });

            if (
              iterateStagedPurchases[parseInt(choice.id)] >
              quantityMap[parseInt(choice.id)]
            ) {
              trackQuantityUpdates = {
                ...trackQuantityUpdates,
                [choiceId]: itemBalance,
              };
              cartContainsInvalidQuantities = true;
            }
          }
        });
      }
    });

    return { cartContainsInvalidQuantities, trackQuantityUpdates };
  },
);

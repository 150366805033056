import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGiftCard as setGiftCardAction } from '../reducers/currentOrder/giftCard';
import { getGiftCard, getImportedGiftCardRegex } from '../selectors';
import validateGiftCard from '../utils/ordering/validateGiftCard';
import { fetchGiftCard } from './fetchGiftCard';
import { updateSelectedPaymentMethods } from './updateSelectedPaymentMethods';

export const setGiftCard = createAsyncThunk(
  '$setGiftCard',
  async (
    data: {
      giftCard: Partial<_GiftCard> | null;
      suppressAutoFetch?: boolean | null;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const { giftCard, suppressAutoFetch } = data;
    try {
      dispatch(setGiftCardAction(giftCard));

      const storedGiftCard = getGiftCard(getState() as EntireFrontendState);
      const importedGiftCardRegex = getImportedGiftCardRegex(
        getState() as EntireFrontendState,
      );
      const valid = validateGiftCard(storedGiftCard, importedGiftCardRegex);

      if (valid && !suppressAutoFetch) {
        await dispatch(fetchGiftCard({ giftCard: storedGiftCard })).unwrap();

        dispatch(
          updateSelectedPaymentMethods({
            giftCardValidated: true,
          }),
        );
      }
    } catch (e) {
      console.warn('Set gift card failed', { e });
      return rejectWithValue(e);
    }
  },
);

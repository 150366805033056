import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = [] as string[];

const slice = createSlice({
  name: '$availableTableNumbes',
  initialState,
  reducers: {
    setAvailableTableNumbers(state, action: PayloadAction<string[]>) {
      return action.payload;
    },
  },
});

export const { setAvailableTableNumbers } = slice.actions;

export default slice.reducer;

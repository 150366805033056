import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';

const initialState = true;

const slice = createSlice({
  name: '$rememberCreditCard',
  initialState,
  reducers: {
    setRememberCreditCard(state, action: PayloadAction<any>) {
      return Boolean(action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      return initialState;
    });
  },
});

export const { setRememberCreditCard } = slice.actions;
export default slice.reducer;

import { createSelector } from 'reselect';

import getStagedPurchasesTotals from './getStagedPurchasesTotals';
import getStagedPurchaseCount from './getStagedPurchaseCount';

export default createSelector(
  [getStagedPurchasesTotals, getStagedPurchaseCount],
  (totals, count): string => {
    return `${totals.moneyPrice},${count}`;
  },
);

import { replaceOrAppend } from '../../utils/misc';
import sameOrIdenticalPurchase from './sameOrIdenticalPurchase';

export default function (state: _Purchase[], purchase: _Purchase) {
  return replaceOrAppend(
    state,
    purchase,
    el => sameOrIdenticalPurchase(el, purchase),
    prev => {
      if (prev.length === 1 && prev[0].id === purchase.id) {
        // same id, we are replacing with newer version
        return purchase;
      }

      let target: _Purchase;

      if (prev.length === 1) {
        target = prev[0];
      } else {
        target = prev.find(p => p.id !== purchase.id) as _Purchase;
      }

      return {
        ...target,
        quantity: target.quantity + purchase.quantity,
      };
    },
  );
}

import React from 'react';
import { useAppDispatch } from '../app/hooks';
import { AuthHook } from 'polygon-utils';
import { signOut } from '../thunks/signOut';

const AuthHookSubscriber = () => {
  const dispatch = useAppDispatch();
  AuthHook.subscribe('SIGN_OUT', () => {
    dispatch(signOut());
  });
  return null;
};

export default AuthHookSubscriber;

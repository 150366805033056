import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';
import { resetOrder } from '../../actions/currentOrder';
import { safeDate } from '../../utils/misc';
import moment from 'moment';

const initialState = null as string | null;

const slice = createSlice({
  name: '$timeEstimateReturned',
  initialState,
  reducers: {
    setTimeEstimateReturned(state, action: PayloadAction<string | null>) {
      let value = safeDate(moment().toISOString());

      return value || initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addCase(applyBufferData, (state, action) => {
        const { timeEstimateReturned } = action.payload;
        return timeEstimateReturned === undefined
          ? state
          : timeEstimateReturned;
      });
  },
});

export const { setTimeEstimateReturned } = slice.actions;
export default slice.reducer;

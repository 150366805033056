import React, { useState } from 'react';
import { useAppSelector } from '../app/hooks';
import TagAllergenDietary from './TagAllergenDietary';
import { OrderingSelectors } from 'polygon-ordering';
import TouchableOpacity from './TouchableOpacity';
import Text from '../components/Text';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';

const { getAllergenDietaryTags } = OrderingSelectors;

// Made this component thinking it could be used in multiple areas
// however the purchase editor modal wants each list seperated so this
// is a little bit redundant
const AllergenTagList = ({
  item,
  includeTempTags = true,
}: {
  item: Item;
  includeTempTags?: boolean;
}) => {
  const { allergenTags, dietaryTags, customTags } = useAppSelector(state =>
    getAllergenDietaryTags(state, item.tags, includeTempTags),
  );
  const [dietaryCount, setDietaryCount] = useState(3);

  const hiddenDietaryTagCount = dietaryTags.length > 3 ? dietaryTags.length - 3 : 0;

  const p = useAppSelector(getThemeLookup);

  return (
    <>
      {(dietaryTags.length > 0 || allergenTags.length > 0 || customTags.length > 0) && (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 5,
              flexWrap: 'wrap',
              padding: '10px 0',
            }}
          >
            {dietaryTags.length > 0 &&
              dietaryTags
                .slice(0, dietaryCount)
                .map((diet, index) => <TagAllergenDietary option={diet} key={index} />)}

            {dietaryCount !== dietaryTags.length && hiddenDietaryTagCount > 0 && (
              <TouchableOpacity
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => setDietaryCount(dietaryTags.length)}
              >
                <div>+{hiddenDietaryTagCount}</div>
              </TouchableOpacity>
            )}

            {customTags.length > 0 &&
              customTags.map((custom, index) => (
                <TagAllergenDietary option={custom} key={index} themeKey="customTagWarning" />
              ))}
          </div>
          <div>
            {allergenTags.length > 0 && (
              <Text style={combineStyles({ fontSize: 12 }, p('menuItemContains', ['color']))}>
                Contains: {allergenTags.map(allergen => allergen.display_name).join(', ')}
              </Text>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AllergenTagList;

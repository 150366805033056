export default function processBrand(rawBrand: RawBrand): Brand {
  return {
    id: String(rawBrand.ID),
    active: Boolean(rawBrand.Active),
    bitmask: rawBrand.Bitmask,
    imagePath: rawBrand.Image || undefined,
    name: rawBrand.Name,
    sortOrder: rawBrand.SortOrder,
  };
}

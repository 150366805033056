import { createSelector } from 'reselect';

import sameAndIdenticalPurchase from '../utils/ordering/sameAndIdenticalPurchase';

import getUnenhancedOpenPurchase from './getUnenhancedOpenPurchase';
import getUnenhancedStagedPurchases from './getUnenhancedStagedPurchases';

export default createSelector(
  [getUnenhancedOpenPurchase, getUnenhancedStagedPurchases],
  (openPurchase, stagedPurchases) => {
    if (!openPurchase) {
      return undefined;
    }

    return !stagedPurchases.some(
      stagedPurchase =>
        sameAndIdenticalPurchase(openPurchase, stagedPurchase) &&
        openPurchase.quantity === stagedPurchase.quantity,
    );
  },
);

import React, { useEffect } from 'react';
import SignUpForm from '../components/SignUpForm';
import Text from '../components/Text';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../app/hooks';
import { setCurrentModal } from '../thunks/setCurrentModal';

export const REGISTER_NEW_USER_ROUTE = '/register';
export const SIGN_UP_ROUTE = '/sign-up';

const SignUpScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    //Remove the Sign In modal
    dispatch(setCurrentModal(null));
  }, []);

  return (
    <>
      <Text themeKey="modalTitle" style={styles.title}>
        {t('title.modal.memberRegister')}
      </Text>
      <SignUpForm />
    </>
  );
};

const styles: Styles = {
  title: {
    display: 'flex',
    fontSize: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default SignUpScreen;

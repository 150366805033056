import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { OrderingOperations } from 'polygon-ordering';
import { setOrderDate } from '../slices/orderDate';
import { persistor, RootState } from '../store';

const { startNewOrder } = OrderingOperations;
export const checkDate = createAsyncThunk(
  'checkDate',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    const orderDate = (getState() as RootState).orderDate;
    if (orderDate == null) {
      return;
    }

    const today = moment().dayOfYear();

    if (today !== orderDate) {
      dispatch(setOrderDate(null));

      persistor.flush();

      dispatch(startNewOrder({ preserveMember: true }));
    }
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSelectedOffer } from '../reducers/currentOrder/selectedOffer';
import { setOffers } from '../reducers/currentOrder/offers';
import { setPaymentGatewayTokens } from '../reducers/paymentGatewayTokens';
import { setHistoricalOrders } from '../reducers/historicalOrders';
import { setFavouriteOrders } from '../reducers/favouriteOrders';
import { setMemberData } from '../reducers/member';
import { setTransactionHistory } from '../reducers/transactionHistory';
import { getEnableDynamicPaymentGatewayConfig } from '../selectors';
import { fetchPaymentGatewayConfig } from './fetchPaymentGatewayConfig';

export const removeMember = createAsyncThunk(
  '$removeMember',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setSelectedOffer(null));
      dispatch(setOffers(null));
      dispatch(setPaymentGatewayTokens(null));
      dispatch(setHistoricalOrders(null));
      dispatch(setFavouriteOrders(null));
      dispatch(setMemberData(null));
      dispatch(setTransactionHistory(null));

      const enableDynamicPaymentGatewayConfig =
        getEnableDynamicPaymentGatewayConfig(getState() as EntireFrontendState);

      if (enableDynamicPaymentGatewayConfig) {
        await dispatch(fetchPaymentGatewayConfig({}));
      }
    } catch (e) {
      console.error('Remove member failed', { e });
      return rejectWithValue(e);
    }
  },
);

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Modal } from 'reactstrap';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { setCurrentModal } from '../thunks/setCurrentModal';
import ModalHeader from '../components/ModalHeader';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import StandardButton from '../components/StandardButton';
import DeliveryTime from '../components/DeliveryTime';
import DeliveryAddress from '../components/DeliveryAddress';
import { Formik } from 'formik';
import { OrderingSelectors, OrderingConstants, OrderingOperations } from 'polygon-ordering';
import moment from 'moment';
import DeliveryMap from '../components/DeliveryMap';
import DeliveryTimeWarning from '../components/DeliveryTimeWarning';
import { setDesiredDeliveryAddress } from '../slices/desiredDeliveryAddress';
import { addToRecentLocations } from '../slices/recentLocations';
import SaveAddressModal from './SaveAddressModal';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import DeliveryNotes from '../components/DeliveryNotes';
import DeliveryLocations from '../components/DeliveryLocations';
import { RiArrowRightFill } from 'react-icons/ri';
import Text from '../components/Text';
import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';
import { adjustSaleType } from '../thunks/adjustSaleType';
import { applyBufferDeliveryEstimate } from '../thunks/applyBufferDeliveryEstimate';
import { confirmLocationEstimate } from '../thunks/confirmLocationEstimate';
import { performDeliveryEstimate } from '../thunks/performDeliveryEstimate';
import { adjustOrder } from '../thunks/adjustOrder';
import { getEnableMultipleDeliveryEstimate } from '../libs/polygon-ordering/src/selectors/config';

export const DELIVERY_DETAILS_MODAL_ID = 'DELIVERY_DETAILS_MODAL_ID';
const { ASAP_TIME } = OrderingConstants;
const {
  getBufferDeliveryTime,
  getDeliveryReadyToApply,
  getDeliveryRequiresLocationSelection,
  getKeyOrderPropertyUpdateInProgress,
  getMember,
  getLocationDeliveryEstimates,
  getLocationsForDisplay,
} = OrderingSelectors;
const { setDeliveryNotes } = OrderingOperations;

const DeliveryDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const keyOrderPropertyUpdateInProgress = useAppSelector(getKeyOrderPropertyUpdateInProgress);
  const bufferDeliveryTime = useAppSelector(getBufferDeliveryTime);
  const desiredDeliveryTime = useAppSelector(state => state.desiredDeliveryTime);
  const effectiveDeliveryAddress = useAppSelector(state => state.desiredDeliveryAddress);
  const effectiveDeliveryTime = desiredDeliveryTime || bufferDeliveryTime;
  const recentLocations = useAppSelector(state => state.recentLocations);
  const deliveryReadyToApply = useAppSelector(getDeliveryReadyToApply);
  const deliveryRequiresLocationSelection = useAppSelector(getDeliveryRequiresLocationSelection);
  const [selectedLocationId, setSelectedLocationId] = useState<null | string>(null);
  const deliveryEstimateInProgress = useAppSelector(state => state.deliveryEstimateInProgress);
  const displayableSaleTypes = useAppSelector(getDisplayableSaleTypes);
  const deliveryEstimateFailed = useAppSelector(state => state.deliveryEstimateFailed);
  const member = useAppSelector(getMember);
  const inProgress = keyOrderPropertyUpdateInProgress || deliveryEstimateInProgress;
  const locations = useAppSelector(getLocationsForDisplay);
  const locationDeliveryEstimates = useAppSelector(getLocationDeliveryEstimates);
  const enableMultipleDeliveryEstimate = useAppSelector(getEnableMultipleDeliveryEstimate);

  useEffect(() => {
    if (!effectiveDeliveryAddress) return;
    dispatch(
      performDeliveryEstimate({
        deliveryAddress: effectiveDeliveryAddress?.formatted_address!,
        desiredDeliveryTime: effectiveDeliveryTime,
      }),
    );
  }, [effectiveDeliveryAddress, enableMultipleDeliveryEstimate]);

  useEffect(() => {
    // If multi delivery estimate is disabled then auto select the first location
    const singleDeliverySingleLocation =
      !enableMultipleDeliveryEstimate && locationDeliveryEstimates.length;
    // If multi delivery estimate is enabled but there is only one location to select then auto select it
    const multiDeliverySingleLocation =
      enableMultipleDeliveryEstimate && locationDeliveryEstimates.length === 1;
    // If multi delivery estimate is enabled and there is multiple locations to select from then don't auto select anything. The user has to manually select one.

    if (singleDeliverySingleLocation || multiDeliverySingleLocation) {
      const locationId = locations[locationDeliveryEstimates[0]?.locationId]?.id;
      if (locationId) setSelectedLocationId(locationId);
    }
  }, [locationDeliveryEstimates.length, enableMultipleDeliveryEstimate]);

  return (
    <Modal
      isOpen={true}
      scrollable
      fullscreen={deviceTypeMobile ? true : false}
      toggle={() => {
        dispatch(setCurrentModal(null));
      }}
      fade={false}
    >
      <Formik
        initialValues={{
          deliveryNotes: '',
          buttonActive: false,
          showSaveAddressModal: false,
          saveAddressAlias: '',
          formatted_address: '',
          street_name: '',
          locality: '',
          lat: '',
          lng: '',
          resetMapLocation: false,
          page: 1,
          showPicker: effectiveDeliveryTime !== ASAP_TIME ? true : false,
          saveAddress: true,
          time:
            effectiveDeliveryTime !== ASAP_TIME
              ? moment(effectiveDeliveryTime).format('h:mm a')
              : '',
        }}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => {
          return (
            <>
              <SaveAddressModal isOpen={values.showSaveAddressModal} />

              <div style={{ width: '100%', marginBottom: 10 }}>
                <ModalHeader
                  backClick={() => {
                    if (values.page === 1) {
                      dispatch(setCurrentModal(null));
                    } else {
                      setFieldValue('page', values.page - 1);
                    }
                  }}
                  title={
                    values.page === 1
                      ? t(`saleType.${SALE_TYPE.DELIVERY}.name`)
                      : 'Add Delivery Address'
                  }
                />
              </div>

              <div style={{ overflow: 'auto', height: '100vh', paddingBottom: 100 }}>
                {values.page === 1 && (
                  <div>
                    <DeliveryTime />
                    <DeliveryTimeWarning />
                    <DeliveryAddress />
                    <DeliveryNotes />
                    {deliveryRequiresLocationSelection && (
                      <DeliveryLocations
                        selectedLocationId={selectedLocationId}
                        setSelectedLocationId={id => setSelectedLocationId(id)}
                      />
                    )}

                    {(deliveryEstimateFailed || deliveryRequiresLocationSelection) &&
                      displayableSaleTypes.includes(SALE_TYPE.PICKUP) && (
                        <div style={styles.fallbackToPickupContainer}>
                          <Text
                            themeKey="fallbackToPickupMessage"
                            value={t('fallbackToPickupMessage')}
                          />

                          <StandardButton
                            themeKey="fallbackToPickupButton"
                            label={t('button.fallbackToPickup')}
                            RightIconComponent={RiArrowRightFill}
                            disabled={inProgress}
                            onClick={() =>
                              dispatch(
                                adjustSaleType({
                                  saleType: SALE_TYPE.PICKUP,
                                  locationId: selectedLocationId,
                                }),
                              )
                            }
                          />
                        </div>
                      )}
                  </div>
                )}
                {values.page === 2 && <DeliveryMap />}
              </div>
              <div className="position-absolute" style={{ bottom: 0, width: '100%', padding: 25 }}>
                <StandardButton
                  label="Continue"
                  themeKey="continueButton"
                  onClick={async () => {
                    if (values.page === 1) {
                      if (deliveryReadyToApply || deliveryRequiresLocationSelection) {
                        await dispatch(
                          adjustOrder({
                            saleType: SALE_TYPE.DELIVERY,
                            locationId: selectedLocationId!,
                            confirmLocationDeliveryEstimate: true,
                          }),
                        );
                      }

                      if (deliveryReadyToApply) {
                        logEvent(EVENTS.CONFIRM_DELIVERY_DETAILS);
                        dispatch(applyBufferDeliveryEstimate());
                      } else {
                        if (deliveryRequiresLocationSelection) {
                          dispatch(confirmLocationEstimate({ locationId: selectedLocationId! }));
                        } else {
                          logEvent(EVENTS.CHECK_DELIVERY_DETAILS);

                          dispatch(
                            performDeliveryEstimate({
                              deliveryAddress: effectiveDeliveryAddress?.formatted_address!,
                              desiredDeliveryTime: effectiveDeliveryTime,
                            }),
                          );
                        }
                      }

                      if (values.deliveryNotes !== '') {
                        dispatch(setDeliveryNotes(values.deliveryNotes));
                        logEvent(EVENTS.CHANGE_DRIVER_NOTES);
                      }
                    }

                    if (values.page === 2) {
                      if (values.saveAddress && member) {
                        setFieldValue('showSaveAddressModal', true);
                      } else {
                        setFieldValue('page', values.page - 1);
                        const effectiveAddressAlreadyInRecents = Boolean(
                          recentLocations?.filter(
                            location => location.street_name === values.street_name,
                          ).length > 0,
                        );

                        if (!effectiveAddressAlreadyInRecents) {
                          dispatch(
                            addToRecentLocations({
                              formatted_address: values.formatted_address,
                              street_name: values.street_name,
                              locality: values.locality,
                              lat: Number(values.lat),
                              lng: Number(values.lng),
                            }),
                          );
                        }

                        dispatch(
                          setDesiredDeliveryAddress({
                            formatted_address: values.formatted_address,
                            street_name: values.street_name,
                            locality: values.locality,
                            lat: Number(values.lat),
                            lng: Number(values.lng),
                          }),
                        );
                      }
                    }
                  }}
                  disabled={
                    (effectiveDeliveryAddress
                      ? values.page === 1
                        ? false
                        : !values.buttonActive
                      : !values.buttonActive) ||
                    ((deliveryReadyToApply || deliveryRequiresLocationSelection) &&
                      !selectedLocationId) ||
                    inProgress
                  }
                  showSpinner={keyOrderPropertyUpdateInProgress}
                  containerStyle={{ marginLeft: '10%', marginRight: '10%' }}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DeliveryDetails;

const styles: Styles = {
  fallbackToPickupContainer: {
    // backgroundColor: 'pink',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',

    // minHeight: 100,
    padding: 25,
  },
};

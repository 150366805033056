import { createSelector } from 'reselect';
import lodash from 'lodash';

import { OrderingSelectors } from 'polygon-ordering';

import getMinimumOrderAmountNotMet from './getMinimumOrderAmountNotMet';

const { getMinimumOrderInformation, getSurcharges } = OrderingSelectors;

export default createSelector(
  [getMinimumOrderInformation, getSurcharges, getMinimumOrderAmountNotMet],
  (minimumOrderInformation, surcharges = [], minimumOrderAmountNotMet) => {
    if (
      !minimumOrderInformation ||
      !lodash.get(minimumOrderInformation, 'amount', 0) ||
      !minimumOrderAmountNotMet
    ) {
      return false;
    }

    const notMinOrderSurcharge = ({ description }: { description: string }) =>
      description === minimumOrderInformation.surchargeDescription;

    const nonMinOrderSurchargesPresent = Boolean(surcharges.filter(notMinOrderSurcharge).length);

    if (nonMinOrderSurchargesPresent) {
      return false;
    }

    return true;
  },
);

import { createSelector } from 'reselect';
import moment from 'moment';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

const getTimeEstimateReturned = createSelector([getCurrentOrder], order =>
  order.timeEstimateReturned
    ? moment(order.timeEstimateReturned).format()
    : null,
);

export default getTimeEstimateReturned;

export const $getTimeEstimateReturned = createSelector(
  [getTimeEstimateReturned, getBuffer],
  (timeEstimateReturned, buffer) => {
    if (buffer.timeEstimateReturned != null) {
      return moment(buffer.timeEstimateReturned).format();
    }

    return timeEstimateReturned;
  },
);

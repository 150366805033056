import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';

const initialState = null as Surcharge[] | null;

const slice = createSlice({
  name: '$deliverySurcharges',
  initialState,
  reducers: {
    setDeliverySurcharges(state, action: PayloadAction<Surcharge[] | null>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(applyBufferData, (state, action) => {
      const { deliverySurcharges } = action.payload;
      return deliverySurcharges === undefined ? state : deliverySurcharges;
    });
  },
});

export const { setDeliverySurcharges } = slice.actions;
export default slice.reducer;

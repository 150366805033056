import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingConstants, OrderingOperations } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { paymentInfo } from '../utils/analytics';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import lodash from 'lodash';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';

import RadioCheck from './RadioCheck';

const { updateSelectedPaymentMethods, resetSelectedPaymentMethods } = OrderingOperations;

const {
  getAvailablePaymentMethods,
  getEnabledPaymentMethods,
  getSaleType,
  getSelectedPaymentMethods,
  getTotalCoveredBySubPayments,
  getOrderTotals,
  getStagedPurchases,
} = OrderingSelectors;

const { PAYMENT_GATEWAY_PAYMENT_METHODS } = OrderingConstants;

interface IProps {
  disabled?: boolean;
  children?: React.ReactNode;
  selected?: () => boolean;
  alreadySelected?: boolean;
  allowSelectionWhenUnavailable?: boolean;
  additionalBehaviour?: () => void;
  right?: React.ReactNode | null;
  subtitle?: React.ReactNode | null;
  label?: string;
  method: PAYMENT_METHOD;
}

const PaymentMethod: React.FC<IProps> = ({
  method,
  disabled,
  label,
  right,
  children,
  subtitle,
  additionalBehaviour,
  allowSelectionWhenUnavailable = false,
  selected,
  alreadySelected,
}) => {
  const p = useAppSelector(getThemeLookup);
  const checkoutInProgress = useAppSelector(state => state.checkoutInProgress);
  const saleType = useAppSelector(getSaleType);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const totalCoveredBySubPayments = useAppSelector(getTotalCoveredBySubPayments);
  const availablePaymentMethods = useAppSelector(getAvailablePaymentMethods);
  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const orderTotals = useAppSelector(getOrderTotals);
  const stagedPurchases = useAppSelector(getStagedPurchases);
  // This isMultiPaymentAllowed means multi payment is enabled in PC
  const isMultiPaymentAllowed = useAppSelector(state => state.config.multiPaymentsAllowed);

  // This enableMultiPayments means whether user clicked on the button and enabled multi payment
  const enableMultiPayments = useAppSelector(state => state.enableMultiPayments);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  if (!enabledPaymentMethods.includes(method)) {
    return null;
  }

  alreadySelected = !selected
    ? Boolean(lodash.find(selectedPaymentMethods, ['method', method]))
    : selected();

  const notAvailable = !availablePaymentMethods.includes(method);

  const totalCoveredAndNotSelected = totalCoveredBySubPayments && !alreadySelected;

  // Checks if no member methods have been selected
  const disabledByOtherSelections = !selectedPaymentMethods.some(selectedPaymentMethod => {
    if (!PAYMENT_GATEWAY_PAYMENT_METHODS.includes(selectedPaymentMethod.method)) {
      return true;
    }
    return false;
  })
    ? false
    : isMultiPaymentAllowed && enableMultiPayments
    ? totalCoveredAndNotSelected
    : false;

  return (
    <TouchableOpacity
      style={styles.mainContainer}
      onClick={e => {
        if (!alreadySelected) {
          paymentInfo(
            stagedPurchases.map(purchaseItem => {
              return {
                ...purchaseItem.item,
                totalPrice: purchaseItem.moneyPrice,
              };
            }),
            orderTotals!.discountedMoneyPrice,
            method,
          );
        }

        if ((e?.target as HTMLDivElement).tagName === 'INPUT') {
          if (!alreadySelected) {
            dispatch(
              updateSelectedPaymentMethods({
                paymentMethod: method,
                alreadySelected,
              }),
            ).catch(err => {
              enqueueErrorSnackbar(err.message || err);
            });
          }
        } else {
          if (!enableMultiPayments) {
            dispatch(resetSelectedPaymentMethods({}));
          }

          dispatch(
            updateSelectedPaymentMethods({
              paymentMethod: method,
              alreadySelected,
            }),
          ).catch(err => {
            enqueueErrorSnackbar(err.message || err);
          });
        }
        if (additionalBehaviour) {
          additionalBehaviour();
        }
      }}
      disabled={
        checkoutInProgress ||
        disabled ||
        (notAvailable && !allowSelectionWhenUnavailable) ||
        disabledByOtherSelections
      }
      disabledStyle={styles.disabled}
      enabledDefault
      skipMouseEvent
      ariaLabel={label}
    >
      <div style={styles.rightContainer}>
        <div style={styles.topContainer}>
          <RadioCheck checked={alreadySelected} />

          {method === PAYMENT_METHOD.PAY_LATER ? (
            <Text themeKey="paymentMethod" style={styles.paymentType}>
              {label || t(`paymentMethod.${method}.${saleType}`)}
            </Text>
          ) : (
            <Text style={styles.paymentType} themeKey="paymentMethod">
              {label || t(`paymentMethod.${method}`)}
            </Text>
          )}
          {right == null ? <div /> : right}
        </div>

        <div>{subtitle}</div>

        <div>{alreadySelected ? children : undefined}</div>
      </div>
    </TouchableOpacity>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 6,
    padding: '0 2.25em',
  },

  icon: {
    margin: '3px 10px 0 0',
  },

  rightContainer: {
    flex: 1,
  },

  topContainer: {
    paddingTop: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  disabled: {
    opacity: 0.5,
    filter: 'grayscale(100%)',
  },
  paymentType: { marginRight: 'auto', paddingLeft: 10 },
};

export default PaymentMethod;

import { createSelector } from 'reselect';

import { getConfig } from './config';
import getSaleType from './getSaleType';
import getLocation from './getLocation';

export default createSelector(
  [getConfig, getLocation, getSaleType],
  (config, location, saleType) => {
    let orderingEnabled = false;

    if (config.enableGuestOrdering) {
      orderingEnabled = true; // global variable true for all locations
    }

    if (location == null) {
      return orderingEnabled;
    }

    const { guestOrderingEnabled, guestOrderingSaleTypes } = location;

    if (!guestOrderingEnabled) {
      return orderingEnabled; // defaults to global variablee
    }

    if (
      guestOrderingSaleTypes.includes(saleType) ||
      guestOrderingSaleTypes.length === 0
    ) {
      orderingEnabled = true;
    } else {
      orderingEnabled = false;
    }

    return orderingEnabled;
  },
);

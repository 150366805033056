import { createSelector } from 'reselect';
import lodash from 'lodash';

import getEnabledPaymentMethods from './getEnabledPaymentMethods';
import getMember from './getMember';
import getOutstandingAmountByPaymentMethod from './getOutstandingAmountByPaymentMethod';
import getNonVerifiedMemberPresent from './getNonVerifiedMemberPresent';

import { PAYMENT_METHOD_UNAVAILABLE_REASON } from '../constants';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector(
  [
    getEnabledPaymentMethods,
    getMember,
    getOutstandingAmountByPaymentMethod,
    getNonVerifiedMemberPresent,
  ],
  (
    enabledPaymentMethods,
    member,
    outstandingByPaymentMethod,
    nonVerifiedMemberPresent,
  ): PAYMENT_METHOD_UNAVAILABLE_REASON[] => {
    const reasons = [];

    if (!enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_MONEY)) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.NOT_ENABLED);
    }

    if (!Boolean(member)) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_MISSING);
    }

    const validMemberPresent =
      !!member && !nonVerifiedMemberPresent && member.registered;

    if (!validMemberPresent) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_INVALID);
    }

    /* The below code is checking to see if this individual payment method can cover
    the full purchase price. We want to move this somewhere else and get it to check
    if all subPayments combined can cover the full purchase price. */

    const balance = member?.moneyBalance || 0;

    if (balance <= 0) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.INSUFFICIENT_BALANCE);
    }

    return reasons;
  },
);

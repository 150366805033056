import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'profileFields',
  initialState: null as null | ProfileFields[],
  reducers: {
    setProfileFields(state, action: PayloadAction<ProfileFields[]>) {
      return action.payload;
    },
  },
});

export const { setProfileFields } = slice.actions;
export default slice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrderingProvider } from '../selectors/config';
import getLocationId, { $getLocationId } from '../selectors/getLocationId';
import getSaleType, { $getSaleType } from '../selectors/getSaleType';
import lodash from 'lodash';
import Api, { ApiResponse, FetchParams } from '../utils/Api';
import processMinimumOrderInformation from '../utils/processors/processMinimumOrderInformation';
import processHolidaySurchargeInformation from '../utils/processors/processHolidaySurchargeInformation';
import processServiceFeeInformation from '../utils/processors/processServiceFeeInformation';
import processCreditCardPaymentInformation from '../utils/processors/processCreditCardPaymentInformation';
import { setBufferMinimumOrderInformation } from '../reducers/buffer/minimumOrderInformation';
import { setBufferOrderingProviderSurchargeInstructions } from '../reducers/buffer/orderingProviderSurchargeInstructions';
import { setBufferCreditCardPaymentInformation } from '../reducers/buffer/creditCardPaymentInformation';
import { setMinimumOrderInformation } from '../reducers/currentOrder/minimumOrderInformation';
import { setCreditCardPaymentInformation } from '../reducers/currentOrder/creditCardPaymentInformation';
import { setOrderingProviderSurchargeInstructions } from '../reducers/currentOrder/orderingProviderSurchargeInstructions';
import getDeliveryProvider, {
  $getDeliveryProvider,
} from '../selectors/getDeliveryProvider';

export const fetchOrderingProviderDetails = createAsyncThunk(
  '$fetchOrderingProviderDetails',
  async (
    data: {
      bufferMode?: boolean;
      date?: string;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { bufferMode = false, date } = data;

    try {
      const orderingProvider = getOrderingProvider(
        getState() as EntireFrontendState,
      );
      const saleType = bufferMode
        ? $getSaleType(getState() as EntireFrontendState)
        : getSaleType(getState() as EntireFrontendState);

      const locationId = bufferMode
        ? $getLocationId(getState() as EntireFrontendState)
        : getLocationId(getState() as EntireFrontendState);

      const deliveryProvider = bufferMode
        ? $getDeliveryProvider(getState() as EntireFrontendState)
        : getDeliveryProvider(getState() as EntireFrontendState);

      const urlParams = new URLSearchParams(
        //@ts-ignore
        lodash.pickBy(
          {
            sale_type: saleType,
            ordering_provider: orderingProvider,
            ...(deliveryProvider
              ? { delivery_provider: deliveryProvider.id }
              : {}),
            ...(date ? { order_date: date } : {}),
          },
          value => value != null,
        ),
      );

      const params: FetchParams = {
        path: `/api/v1/stores/${locationId}/order_provider_details?${urlParams.toString()}`,
        method: 'GET',
      };

      const response: ApiResponse = await Api.fetch(params);

      const processedMinOrderInfo = processMinimumOrderInformation(
        response.data.MinimumOrderInfo as RawMinimumOrderInformation,
      );

      const orderingProviderSurchargeInstructions: SurchargeInstruction[] = [];

      if (response.data.HolidaySurchargeInfo) {
        orderingProviderSurchargeInstructions.push(
          processHolidaySurchargeInformation(
            response.data
              .HolidaySurchargeInfo as RawHolidaySurchargeInformation,
          ),
        );
      }

      if (response.data.ServiceFeeInfo) {
        orderingProviderSurchargeInstructions.push(
          processServiceFeeInformation(
            response.data.ServiceFeeInfo as RawServiceFeeInformation,
          ),
        );
      }

      const processedCreditCardPaymentInfo = response.data.CreditCardPaymentInfo
        ? processCreditCardPaymentInformation(
            response.data
              .CreditCardPaymentInfo as RawCreditCardPaymentInformation,
          )
        : null;

      if (bufferMode) {
        dispatch(setBufferMinimumOrderInformation(processedMinOrderInfo));
        dispatch(
          setBufferOrderingProviderSurchargeInstructions(
            orderingProviderSurchargeInstructions,
          ),
        );
        dispatch(
          setBufferCreditCardPaymentInformation(processedCreditCardPaymentInfo),
        );
      } else {
        dispatch(
          setOrderingProviderSurchargeInstructions(
            orderingProviderSurchargeInstructions,
          ),
        );
        dispatch(setMinimumOrderInformation(processedMinOrderInfo));
        dispatch(
          setCreditCardPaymentInformation(processedCreditCardPaymentInfo),
        );
      }
    } catch (e) {
      console.error('Fetch ordering provider details failed', e);
      return rejectWithValue(e);
    }
  },
);

// take a "purchase object", convert to a "shadow item", which is used for favourite orders
// basically stripping unused data and putting everything into one object
export default function createShadowPurchaseFromPurchase(
  item: Item,
  quantity: number,
  choiceSets?: (ValidatedChoiceSet | ValidatedNestedChoiceSet)[],
  sizeId?: string,
): ShadowItem {
  const shadowChoiceSets: SDict<ShadowItem[]> = {};

  // recursively strip data from the choice sets
  choiceSets?.forEach(choiceSet => {
    const choiceSetArray: ShadowItem[] = [];
    choiceSet.choices.forEach(
      (choice: ChoiceWithQuantity | NestedChoiceWithQuantity) => {
        if (choice.quantity) {
          choiceSetArray.push(
            createShadowPurchaseFromPurchase(
              choice as unknown as Item,
              choice.quantity,
              (('choiceSets' in choice && choice.choiceSets) ||
                undefined) as ValidatedChoiceSet[],
            ),
          );
        }
      },
    );
    if (!choiceSetArray.length) return;
    if (shadowChoiceSets[choiceSet.id]) {
      shadowChoiceSets[choiceSet.id].push(...choiceSetArray);
    } else {
      shadowChoiceSets[choiceSet.id] = choiceSetArray;
    }
  });

  const sizeItem =
    (!!sizeId &&
      !!item.sizes.length &&
      item.sizes.find(size => size.id === sizeId)) ||
    undefined;

  return {
    name: item.name,
    // I don't like this ! usage here, but it should be technically correct because an item only doesn't have a plu when it is composite, in which case one of its variety items will have a PLU
    plucode: (item.plucode ?? sizeItem?.plucode)!,
    id: sizeItem?.id ?? item.id,
    quantity,
    choiceSets: shadowChoiceSets,
    compositeId: (!!sizeItem && item.id) || undefined,
  };
}

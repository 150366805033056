import { createSelector } from 'reselect';

import getOrderTotals from './getOrderTotals';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector([getOrderTotals], (totals):
  | OutstandingAmount
  | undefined => {
  if (!totals) {
    return undefined;
  }

  const { discountedMoneyPrice, pointsPrice } = totals;

  return {
    [PAYMENT_METHOD.MEMBER_POINTS]: pointsPrice,
    [PAYMENT_METHOD.MEMBER_REWARDS]: discountedMoneyPrice,
    [PAYMENT_METHOD.MEMBER_MONEY]: discountedMoneyPrice,
    [PAYMENT_METHOD.EFTPOS]: discountedMoneyPrice,
    [PAYMENT_METHOD.GIFT_CARD]: discountedMoneyPrice,
    [PAYMENT_METHOD.CREDIT_CARD]: discountedMoneyPrice,
    [PAYMENT_METHOD.SAVED_CARD]: discountedMoneyPrice,
    [PAYMENT_METHOD.APPLE_PAY]: discountedMoneyPrice,
    [PAYMENT_METHOD.GOOGLE_PAY]: discountedMoneyPrice,
    [PAYMENT_METHOD.ALIPAY]: discountedMoneyPrice,
    [PAYMENT_METHOD.FREE]: discountedMoneyPrice,
    [PAYMENT_METHOD.PAY_LATER]: discountedMoneyPrice,
  };
});

import { createSelector } from '@reduxjs/toolkit';
import getOpenPurchase from './getOpenPurchase';

export default (choiceSetKey: string | undefined) =>
  createSelector(
    [getOpenPurchase],
    (openPurchase): ChoiceSet | NestedChoiceSet | undefined => {
      if (!openPurchase || !choiceSetKey) return;
      const { choiceSets } = openPurchase;
      return choiceSets.find(set => set.key === choiceSetKey);
    },
  );

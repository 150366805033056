import * as lodash from 'lodash';
import { checkCategoryAvailability } from './daypartMenuAvailability';

//change the category availability checking. an item maybe in different category
const itemCategoryAvailibilityValidate = (
  id: string,
  subMenus: MenuNode[],
  categoriesInfo: CategoryInfo[],
): boolean => {
  const categoriesByItem = [] as string[];
  if (!id || !subMenus || subMenus.length === 0) return true;
  subMenus.forEach(cat => {
    if (cat.items.includes(id)) {
      categoriesByItem.push(cat.id);
    }
    if (cat.subMenus && cat.subMenus.length > 0) {
      cat.subMenus.forEach(sub => {
        if (sub.items.includes(id)) categoriesByItem.push(sub.id);
      });
    }
  });

  const categoriesArr = lodash.uniq(categoriesByItem);
  return lodash.reduce(
    categoriesArr,
    (acc, current) => {
      const cat = categoriesInfo.find(c => c.id === current);
      const [_catAvailability] = checkCategoryAvailability(cat);
      return acc && _catAvailability;
    },
    true as boolean,
  );
};
export default itemCategoryAvailibilityValidate;

import React from 'react';
import { BiSliderAlt } from 'react-icons/bi';

import getThemeLookup from '../selectors/getThemeLookup';

import combineStyles from '../utils/combineStyles';

import TouchableOpacity from './TouchableOpacity';
import { useAppSelector } from '../app/hooks';
import Text from './Text';
import { OrderingSelectors } from 'polygon-ordering';

const { getMenuFilterTags } = OrderingSelectors;

interface IProps {
  toggleFilterActive: () => void;
  containerStyle?: React.CSSProperties;
}

const MenuFilterButton: React.FC<IProps> = ({ toggleFilterActive, containerStyle }) => {
  const p = useAppSelector(getThemeLookup);
  const disableMenuSearch = useAppSelector(state => state.config.disableMenuSearch);
  const filterTags = useAppSelector(getMenuFilterTags);

  if (disableMenuSearch) {
    return null;
  }

  const color = combineStyles(
    p('categoryBarLabel', ['color']),
    p('menuSearchIcon', ['color']),
  ).color;

  return (
    <div style={combineStyles(styles.wrapper, containerStyle)}>
      <TouchableOpacity onClick={toggleFilterActive} ariaLabel="search">
        <div
          style={combineStyles(
            styles.badge,
            filterTags && filterTags?.length > 0 && styles.badgeBorder,
          )}
        >
          <BiSliderAlt style={combineStyles(p('menuSearchIcon', ['fontSize']), { color })} />
          {filterTags && filterTags?.length > 0 && (
            <Text style={styles.filterNumber}>{filterTags?.length}</Text>
          )}
        </div>
      </TouchableOpacity>
    </div>
  );
};

const styles: Styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    paddingRight: 20,
  },
  filterNumber: { marginLeft: 5, fontSize: 14, color: 'white' },
  badge: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 10,
    paddingRight: 5,
    paddingLeft: 5,
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: 'center',
  },
  badgeBorder: {
    border: '1px solid white',
  },
};

export default MenuFilterButton;

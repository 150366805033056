import { createSelector } from 'reselect';

import getEnabledPaymentMethods from './getEnabledPaymentMethods';
import getUnavailableReasonsFree from './getUnavailableReasonsFree';
import getUnavailableReasonsSavedCard from './getUnavailableReasonsSavedCard';
import getUnavailableReasonsMemberMoney from './getUnavailableReasonsMemberMoney';
import getUnavailableReasonsMemberPoints from './getUnavailableReasonsMemberPoints';
import getUnavailableReasonsMemberRewards from './getUnavailableReasonsMemberRewards';
import getUnavailableReasonsGiftCard from './getUnavailableReasonsGiftCard';
import getUnavailableReasonsPayLater from './getUnavailableReasonsPayLater';

import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector(
  [
    getEnabledPaymentMethods,
    getUnavailableReasonsFree,
    getUnavailableReasonsSavedCard,
    getUnavailableReasonsMemberMoney,
    getUnavailableReasonsMemberPoints,
    getUnavailableReasonsMemberRewards,
    getUnavailableReasonsGiftCard,
    getUnavailableReasonsPayLater,
  ],
  (
    enabledPaymentMethods,
    unavailableReasonsFree,
    unavailableReasonsSavedCard,
    unavailableReasonsMemberMoney,
    unavailableReasonsMemberPoints,
    unavailableReasonsMemberRewards,
    unavailableReasonsGiftCard,
    unavailableReasonsPayLater,
  ): PAYMENT_METHOD[] => {
    return enabledPaymentMethods.filter(method => {
      switch (method) {
        case PAYMENT_METHOD.MEMBER_MONEY: {
          return !unavailableReasonsMemberMoney.length;
        }
        case PAYMENT_METHOD.MEMBER_REWARDS: {
          return !unavailableReasonsMemberRewards.length;
        }
        case PAYMENT_METHOD.MEMBER_POINTS: {
          return !unavailableReasonsMemberPoints.length;
        }
        case PAYMENT_METHOD.FREE: {
          return !unavailableReasonsFree.length;
        }
        case PAYMENT_METHOD.SAVED_CARD: {
          return !unavailableReasonsSavedCard.length;
        }
        case PAYMENT_METHOD.GIFT_CARD: {
          return !unavailableReasonsGiftCard.length;
        }
        case PAYMENT_METHOD.PAY_LATER: {
          return !unavailableReasonsPayLater.length;
        }
        default:
          return true; // TODO: make disabled by default. disable eftpos if not configured
      }
    });
  },
);

import React from 'react';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { useTranslation } from 'react-i18next';
import { OrderingOperations } from 'polygon-ordering';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { TEXT_PROPERTIES } from '../utils/theme';
import TextButton from './TextButton';

const { fetchInvoice } = OrderingOperations;

const ResendInvoice = ({ invoiceID }: { invoiceID: string }) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  const dispatch = useAppDispatch();

  return (
    <TextButton
      onClick={() => {
        dispatch(fetchInvoice({ invoiceID }))
          .unwrap()
          .then(() => enqueueSuccessSnackbar(t('snackbarMessages.success')))
          .catch(err => enqueueErrorSnackbar(err.message));
      }}
      value={t('button.resendInvoice')}
      textStyle={p('resendInvoice', TEXT_PROPERTIES)}
    />
  );
};

export default ResendInvoice;

import { createSelector } from 'reselect';
import lodash from 'lodash';

import getEnabledPaymentMethods from './getEnabledPaymentMethods';
import getOutstandingAmountByPaymentMethod from './getOutstandingAmountByPaymentMethod';
import getGiftCard from './getGiftCard';

import { PAYMENT_METHOD_UNAVAILABLE_REASON } from '../constants';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector(
  [getEnabledPaymentMethods, getOutstandingAmountByPaymentMethod, getGiftCard],
  (
    enabledPaymentMethods,
    outstandingByPaymentMethod,
    giftCard,
  ): PAYMENT_METHOD_UNAVAILABLE_REASON[] => {
    const reasons = [];

    if (!enabledPaymentMethods.includes(PAYMENT_METHOD.GIFT_CARD)) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.NOT_ENABLED);
    }

    const balance = giftCard.moneyBalance || 0;

    if (balance <= 0) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.INSUFFICIENT_BALANCE);
    }

    if (!giftCard.active) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.GIFT_CARD_INACTIVE);
    }

    return reasons;
  },
);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Api, { FetchParams } from '../utils/Api';

export const fetchDietaryOptions = createAsyncThunk(
  '$fetchDietaryOptions',
  async (data: undefined, { rejectWithValue }) => {
    try {
      const fetchParams: FetchParams = {
        path: '/api/v1/public/dietaries',
        method: 'GET',
      };
      const response = await Api.fetch(fetchParams);
      return response.data;
    } catch (e) {
      console.error('Fetch dietary options failed', { e });
      rejectWithValue(e);
    }
  },
);

const dietariesOptionsSlice = createSlice({
  name: '$dietaryOptions',
  initialState: null,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDietaryOptions.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});
export default dietariesOptionsSlice.reducer;

import { createSelector } from 'reselect';

import getStagedPurchases from './getStagedPurchases';

export default createSelector(
  [getStagedPurchases],
  (stagedPurchases): boolean => {
    if (!stagedPurchases) {
      return true;
    }

    return stagedPurchases.reduce(
      (acc: boolean, purchase) => purchase.valid && acc,
      true,
    );
  },
);

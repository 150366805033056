import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setPurchaser } from '../../../actions/currentOrder';
import processMobileNumber from '../../../utils/processors/processMobileNumber';

const initialState = null as string | null;

const slice = createSlice({
  name: '$purchaserMobile',
  initialState,
  reducers: {
    setPurchaserMobile(state, action: PayloadAction<string | null>) {
      return processMobileNumber(action.payload);
    },
    adjustPurchaserMobile(
      state,
      action: PayloadAction<string | 'clear' | 'delete'>,
    ) {
      switch (action.payload) {
        case 'clear': {
          return initialState;
        }
        case 'delete': {
          return (state || '').slice(0, -1);
        }
        default:
          return `${state || ''}${action.payload}`.trimLeft();
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(setPurchaser, (state, action) => {
      return processMobileNumber(action.payload.mobile);
    });
  },
});

export const { setPurchaserMobile, adjustPurchaserMobile } = slice.actions;
export default slice.reducer;

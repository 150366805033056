import moment from 'moment';

export default (
  availability: boolean,
  days: Day[] = [],
  start: string | null = '',
  end: string | null = '',
  dayInFull?: boolean,
): string => {
  if (!availability) return '';
  // const currentDay = moment().format('dddd');

  // if (
  //   days.length > 0 &&
  //   !days.includes(currentDay.toUpperCase() as unknown as Day)
  // )
  //   return daysTostring(days, dayInFull);

  // const dayStr = days.length !== 7 ? daysTostring(days, dayInFull) : '';
  const dayStr = daysTostring(days, dayInFull);
  if (start && end) return `${timeToString(start, end)} ${dayStr}`;

  return '';
};

const daysTostring = (days: Day[], dayInFull?: boolean): string => {
  const convertDayString = (d: Day, full?: boolean): string => {
    const s =
      d.toString().toUpperCase().slice(0, 1) +
      d.toString().toLowerCase().slice(1);
    return full ? s : s.slice(0, 3);
  };
  return days.map(day => convertDayString(day, dayInFull)).join(', ');
};

const timeToString = (start: string, end: string): string => {
  if (start && end) {
    const date = moment().format('yyyy-MM-DD');
    return `${moment(`${date} ${start}`).format('hh:mm A')} - ${moment(
      `${date} ${end}`,
    ).format('hh:mm A')}`;
  }
  return '';
};

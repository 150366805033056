import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

export default createSelector(
  [getCurrentOrder],
  (order): Offer[] => order.offers,
);

export const $getUnenhancedOffers = createSelector(
  [getCurrentOrder, getBuffer],
  (order, buffer): Offer[] => buffer.offers || order.offers,
);

import { createSelector } from 'reselect';
import i18next from 'i18next';

import { OrderingSelectors } from 'polygon-ordering';
import { RootState } from 'store';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

const { getSaleType, getSurcharges } = OrderingSelectors;

const getFlattenDeliverySurcharges = (state: RootState) => state.config.flattenDeliverySurcharges;

export default createSelector(
  [getSurcharges, getFlattenDeliverySurcharges, getSaleType],
  (surcharges = [], flattenDeliverySurcharges = true, saleType) => {
    let deliverySurcharge = {
      description: i18next.t('flattenedDeliverySurcharge'),
      amount: 0,
    };

    const result = [];

    surcharges.forEach(surcharge => {
      if (
        flattenDeliverySurcharges &&
        (surcharge.description || i18next.t('surchargeNameFallback'))
          .toLowerCase()
          .startsWith('delivery')
      ) {
        deliverySurcharge = {
          ...deliverySurcharge,
          amount: deliverySurcharge.amount + surcharge.amount,
        };
      } else {
        result.push(surcharge);
      }
    });

    if (flattenDeliverySurcharges && deliverySurcharge.amount) {
      result.push(deliverySurcharge);
    }

    if (saleType === SALE_TYPE.DELIVERY && deliverySurcharge.amount === 0) {
      result.push(deliverySurcharge);
    }

    return result;
  },
);

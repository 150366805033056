import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import Line from './Line';
import { OrderingSelectors } from 'polygon-ordering';

const { getOrderTotals } = OrderingSelectors;

const TotalsSummary = () => {
  const { t } = useTranslation();
  const orderTotals = useAppSelector(getOrderTotals);

  return (
    <div style={{ paddingTop: '1em' }}>
      {Boolean(orderTotals!.orderMoneyDiscount) && (
        <Line
          label={t('totals.discount')}
          amount={orderTotals!.orderMoneyDiscount * -1}
          deemphasised
        />
      )}

      <div>
        <Line label={t('totals.total')} amount={orderTotals!.discountedMoneyPrice} emphasised />
      </div>
    </div>
  );
};

export default TotalsSummary;

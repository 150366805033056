import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import combineStyles from '../utils/combineStyles';
import TagAllergenDietary from '../components/TagAllergenDietary';
import Text from '../components/Text';
import { useAppSelector } from '../app/hooks';
import Energy from '../components/Energy';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getThemeLookup from '../selectors/getThemeLookup';
const { getStockBalanceDataByItem, getAllergenDietaryTags, getOpenPurchase } = OrderingSelectors;

const { useFormattedCurrency } = OrderingHooks;

const ItemTopHeader = ({
  item,
  tags: tagIds,
  availabilityTimeString,
}: {
  item: Item;
  tags?: number[];
  availabilityTimeString?: string;
}) => {
  const { t } = useTranslation();
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);
  const multiSized = !!item && !!item.sizes && item.sizes.length > 1;
  const priceShowing = item ? (multiSized ? item.minSizeBaseMoneyPrice : item.baseMoneyPrice) : 0;
  const price = useFormattedCurrency({ cents: priceShowing });
  const tags = useAppSelector(state => getAllergenDietaryTags(state, tagIds));
  const stockBalanceData = useAppSelector(
    getStockBalanceDataByItem(item, useAppSelector(getOpenPurchase) as PurchaseWithTotals),
  );

  const showingKilojoules = multiSized ? item.minSizeKilojoules : item.kilojoules;
  const hasRequiredPaidChoices = item.choiceSets.some(
    choiceSet => choiceSet.min && choiceSet.choices.some(choice => choice.baseMoneyPrice),
  );
  const priceString = priceShowing
    ? `${
        ((hasRequiredPaidChoices || multiSized) && t('button.purchaseEditor.basePricePrefix')) || ''
      }${price}`
    : null;

  const baseTextSize = { fontSize: isMobileDevice ? 14 : 16 };
  const titleTextSize = { fontSize: isMobileDevice ? 20 : 22 };

  const p = useAppSelector(getThemeLookup);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        gap: 20,
        paddingBottom: 0,
      }}
    >
      {/* top row (title -- dietary+tags) */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: item ? 'flex-start' : 'center',
        }}
      >
        {/* title */}
        {!!item && (
          <Text
            themeKey="purchaseEditorName"
            style={combineStyles(titleTextSize, { flex: 1 }, p('purchaseEditorText', ['color']))}
          >
            {item.name}
          </Text>
        )}

        {!item && tags && tags.allergenTags.length > 0 && (
          <Text style={combineStyles({ fontSize: 12 }, p('purchaseEditorContains', ['color']))}>
            Contains: {tags.allergenTags.map(allergen => allergen.display_name).join(', ')}
          </Text>
        )}

        {/* dietary+tags */}
        {tags && (tags.dietaryTags.length > 0 || tags.customTags.length > 0) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: 5,
              flexWrap: 'wrap',
              maxWidth: '30%',
            }}
          >
            {tags?.dietaryTags.map((diet, index) => (
              <TagAllergenDietary option={diet} key={index} />
            ))}
            {tags?.customTags.map((custom, index) => (
              <TagAllergenDietary option={custom} key={index} themeKey="customTagWarning" />
            ))}
          </div>
        )}
      </div>

      {/* middle line: price -=- energy */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // marginBottom: 5,
        }}
      >
        {!!item && !!priceShowing && (
          <Text themeKey="menuItemPrice" style={combineStyles(baseTextSize)}>
            {priceString}
          </Text>
        )}

        {!!item && !!showingKilojoules && <Energy kilojouleCount={showingKilojoules} />}
      </div>

      {/* description */}
      {!!item && !!item.description && (
        <Text themeKey="purchaseEditorDescription" style={baseTextSize}>
          {item.description}
        </Text>
      )}

      {/* stock? */}
      {!!stockBalanceData && stockBalanceData.stockBalanceThreshold && (
        <Text
          themeKey={
            stockBalanceData.stockBalanceThreshold ? stockBalanceData.stockBalanceThreshold : ''
          }
          style={{
            marginTop: 25,
            marginBottom: 5,
          }}
        >
          {t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
        </Text>
      )}
      {availabilityTimeString && (
        <div>
          <Text themeKey="purchaseEditorDescription" style={baseTextSize}>
            {availabilityTimeString}
          </Text>
        </div>
      )}
      {/* allergens */}
      {!!item && !!tags && tags.allergenTags.length > 0 && (
        <Text style={combineStyles({ fontSize: 12 }, p('purchaseEditorContains', ['color']))}>
          Contains: {tags.allergenTags.map(allergen => allergen.display_name).join(', ')}
        </Text>
      )}
    </div>
  );
};

export default ItemTopHeader;

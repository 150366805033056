import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'showUnstartedCoupons',
  initialState: false,
  reducers: {
    setShowUnstartedCoupons(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setShowUnstartedCoupons } = slice.actions;

export default slice.reducer;

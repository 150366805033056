import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';
import { setCreditCardComplete } from '../slices/creditCardComplete';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES } from '../utils/theme';
import Text from './Text';
import PaymentMethod from './PaymentMethod';
import SaveCardCheckbox from './SaveCardCheckbox';
import '../styles/stripe.css';
import { loadStripe } from '@stripe/stripe-js';
import getPaymentGatewayPublicKey from '../selectors/getPaymentGatewayPublicKey';
import { useTranslation } from 'react-i18next';

const { updateSelectedPaymentMethods } = OrderingOperations;
const { getSelectedPaymentMethods } = OrderingSelectors;

const PaymentMethodCreditCard = () => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const paymentGatewayPublicKey = useAppSelector(getPaymentGatewayPublicKey);

  const alreadySelected = selectedPaymentMethods.some(selectedPaymentMethod => {
    if (selectedPaymentMethod.method === PAYMENT_METHOD.CREDIT_CARD) {
      return true;
    }
    return false;
  });

  return (
    <PaymentMethod
      method={PAYMENT_METHOD.CREDIT_CARD}
      right={alreadySelected ? <SaveCardCheckbox /> : undefined}
      subtitle={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: -8,
            marginLeft: 45,
          }}
          className={!alreadySelected ? 'my-2' : ''}
        >
          {alreadySelected && (
            <span id="cvc-helper">
              <Text themeKey="cvcHelper">{t('cvcHelper')}</Text>
            </span>
          )}
        </div>
      }
      additionalBehaviour={() => {
        loadStripe(paymentGatewayPublicKey!);
      }}
    >
      <div style={styles.contentContainer}>
        <div style={combineStyles(styles.input, p('input', CONTAINER_PROPERTIES))}>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  backgroundColor: 'transparent', // workaround for issue with darkmode
                  color: p('stripeElementText', ['color']).color,
                },
              },
            }}
            onChange={event => dispatch(setCreditCardComplete(event.complete))}
            onFocus={() => {
              if (!alreadySelected) {
                dispatch(
                  updateSelectedPaymentMethods({
                    paymentMethod: PAYMENT_METHOD.CREDIT_CARD,
                    alreadySelected,
                  }),
                );
              }
              logEvent(EVENTS.CHANGE_PAYMENT_METHOD, {
                label: t(`paymentMethod.${PAYMENT_METHOD.CREDIT_CARD}`),
              });
            }}
          />
        </div>
      </div>
    </PaymentMethod>
  );
};

const styles = {
  contentContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  input: {
    borderRadius: 4,
    border: '1px solid #c4c4c4',
  },
};

export default PaymentMethodCreditCard;

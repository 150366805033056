import { createSelector } from 'reselect';

const _getOrdering = (state: EntireFrontendState) => state.ordering;

const getOrdering = createSelector(
  [_getOrdering],
  (ordering: OrderingState) => ordering,
);

export default getOrdering;

// is there any benefit to sing a reselect selector here?

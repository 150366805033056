import React from 'react';

export default function HorizontalLine({ text }: { text?: string }) {
  return (
    <div style={styles.horizontalLine}>
      <span style={styles.horizantalText}>{text}</span>
    </div>
  );
}

const styles: Styles = {
  horizontalLine: {
    position: 'relative',
    height: 1,
    width: '100%',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  horizantalText: {
    fontSize: 14,
    marginTop: '-10px',
    backgroundColor: 'white',
    fontFamily: 'Arial, Helvetica, sans-serif',
    position: 'absolute',
    paddingLeft: 10,
    paddingRight: 10,
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
};

import lodash from 'lodash';

type Params = {
  ClientId: number;
  Version?: string;
};

type Value = string | number;

function isNone(value?: Value) {
  return value == null || value === '';
}

// converts an object into url params
// handles url encoding
export function toURLParamsString(params: Params, addPrefix: boolean, removeEmpty: boolean = true) {
  const result = lodash
    .chain(params)
    .toPairs()
    .map(([key, value]) => {
      if (removeEmpty && isNone(value)) {
        return undefined;
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value as Value & boolean)}`;
    })
    .filter()
    .value()
    .join('&');

  if (addPrefix) {
    return `?${result}`;
  }

  return result;
}

export function withParams(
  path: string,
  params: Params,
  addPrefix: boolean = true,
  removeEmpty: boolean = true,
) {
  return `${path}${toURLParamsString(params, addPrefix, removeEmpty)}`;
}

// 'a' => 'a'
// 'a', 'b' => 'a/b'
// 'a//', 'b', '/c//' => 'a/b/c'
// 'a///', null, undefined, 'b', '////c//' => 'a/b/c'
export function joinPath(parts: string[] = [], trailing: boolean = false, char: string = '/') {
  let result = '';

  parts.forEach((part, index) => {
    if (!part) {
      return;
    }

    const partAsString = part.toString();

    if (index === 0) {
      result = partAsString;
      return;
    }

    let left = result;
    let right = partAsString;

    while (left.endsWith(char)) {
      left = left.slice(0, -1 * char.length);
    }

    while (right.startsWith(char)) {
      right = right.slice(char.length);
    }

    result = `${left}${char}${right}`;
  });

  if (trailing && !result.endsWith(char)) {
    result = `${result}${char}`;
  }

  while (!trailing && result.endsWith(char)) {
    result = result.slice(0, -1 * char.length);
  }

  return result;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as number | null;

const slice = createSlice({
  name: '$stockBalanceIgnoreThreshold',
  initialState,
  reducers: {
    setStockBalanceIgnoreThreshold(state, action: PayloadAction<number>) {
      return action.payload;
    },
  },
});

export const { setStockBalanceIgnoreThreshold } = slice.actions;
export default slice.reducer;

import { RedcatApiHandler } from 'polygon-utils';

export interface ApiResponse {
  [key: string]: any;
  data: any;
  success: any;
  error?: any;
}

type HTTPMethods = 'GET' | 'PUT' | 'POST' | 'DELETE';

export interface FetchParams {
  method: HTTPMethods;
  path: string;
  body?: {};
  applicationErrorAllowed?: boolean;
}

export type AuthenticationMethod = 'trusted' | 'member' | 'none';

export default class Api {
  static handler?: typeof RedcatApiHandler;

  static setHandler(handler: typeof RedcatApiHandler) {
    Api.handler = handler;
  }

  static fetch(
    params: FetchParams,
    authenticationMethod: AuthenticationMethod = 'none',
  ) {
    if (Api.handler) {
      switch (authenticationMethod) {
        case 'trusted':
          return Api.handler.trustedFetch(params);
        case 'member':
          return Api.handler.authorisedFetch(params);
        case 'none':
          return Api.handler.fetch(params);
      }
    }

    return Promise.reject('API handler not ready');
  }
}

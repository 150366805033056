export enum INPUT_FIELDS {
  CurrentPassword = 'CurrentPassword',
  Password = 'Password',
  ConfirmPassword = 'ConfirmPassword',
}

export const CURRENT_PASSWORD_FIELD = {
  id: 'CurrentPassword',
  inputType: 'password',
  registerMode: 'required',
  editMode: 'editable',
  required: true,
};

import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'brazeSdkEndpoint',
  initialState: null as string | null,
  reducers: {
    setBrazeSdkEndpoint(state, action) {
      return action.payload || null;
    },
  },
});

export const { setBrazeSdkEndpoint } = slice.actions;

export default slice.reducer;

import { dollarsToCents, safeDate } from '../misc';

function stripBarcodeTerminators(barcode: string) {
  return String(barcode).replace(/[#;?]/g, '');
}

function safeStringArray(input?: any[]) {
  return (input || []).map(target => String(target));
}

export default function processOffer(rawOffer: RawOffer): Offer {
  return {
    id: String(rawOffer.ID),
    hash: stripBarcodeTerminators(rawOffer.Hash),
    name: rawOffer.ProgramName,
    available: Boolean(rawOffer.Available),
    description: rawOffer.Description,
    longDescription: rawOffer.LongDescription,
    startDate: safeDate(rawOffer.StartDate),
    expiryDate: safeDate(rawOffer.ExpiryDate),
    image: rawOffer.ImageURL,
    redeemed: Boolean(rawOffer.Redeemed),
    type: rawOffer.DiscountType,
    amount: rawOffer.DiscountAmount,
    limit: rawOffer.DiscountLimit ? dollarsToCents(rawOffer.DiscountLimit) : 0,
    barcode: rawOffer.Barcode,

    targetPLUs: safeStringArray(rawOffer.PLUList),
    targetPLUClasses: safeStringArray(rawOffer.PLUClassList),
    targetPLUCategories: safeStringArray(rawOffer.PLUCategoryList),
    saleTypes: rawOffer.SaleTypeList || [],

    //bogo coupons
    qualifyingPLUs: safeStringArray(rawOffer.QualifyingPLUList),
    qualifyingPLUClasses: safeStringArray(rawOffer.QualifyingPLUClassList),
    qualifyingPLUCategories: safeStringArray(
      rawOffer.QualifyingPLUCategoryList,
    ),

    //minimum order coupon
    MinOrderValue: rawOffer.MinOrderValue,

    // automatic: true,
    // enforced: true,
    automatic:
      rawOffer.EnforceRedemptionType === 1 ||
      rawOffer.EnforceRedemptionType === 2,
    enforced: rawOffer.EnforceRedemptionType === 2,
    availableAtLocation: Boolean(rawOffer.AvailableAtLocation),
    availableAtTime: Boolean(rawOffer.AvailableAtTime),
  };
}

import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { finaliseSale, sale } from '../../operations';

const initialState = null as null | string;

const slice = createSlice({
  name: 'currentSavedCard',
  initialState,
  reducers: {
    setSavedCard(state, action: PayloadAction<string | null>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      isAnyOf(sale.fulfilled, finaliseSale.fulfilled),
      () => null,
    );
  },
});
export const { setSavedCard } = slice.actions;
export default slice.reducer;

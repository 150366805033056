import { createAsyncThunk } from '@reduxjs/toolkit';
import redcatApiFetch from '../api';

import { withParams } from '../utils/url';
import { THEME_CLIENT_ID } from '../constants';
import lodash from 'lodash';
import json5 from 'json5'; // more forgiving json parser

export const fetchThemeOverrides = createAsyncThunk(
  'fetchThemeOverrides',
  async (_data: undefined, { dispatch, rejectWithValue }) => {
    try {
      const response = await redcatApiFetch({
        method: 'GET',
        path: withParams('/api/v1/public/themes', {
          ClientId: THEME_CLIENT_ID,
          Version: process.env.REACT_APP_VERSION,
        }),
      });

      let themeOverrides = lodash.get(response, 'data.Body') || {};

      if (typeof themeOverrides === 'string') {
        themeOverrides = json5.parse(themeOverrides);
      }

      return themeOverrides;
    } catch (e) {
      console.error('Fetch theme overrides failed:', e);
      return rejectWithValue(e);
    }
  },
);

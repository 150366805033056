import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';
import { resetOrder } from '../../actions/currentOrder';
import { SALE_TYPE } from '../../constants/saleType';

const initialState = SALE_TYPE.PICKUP;

const slice = createSlice({
  name: '$saleType',
  initialState,
  reducers: {
    setSaleType(state, action: PayloadAction<SALE_TYPE>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addCase(applyBufferData, (state, action) => {
        const { saleType } = action.payload;
        return saleType === undefined ? state : saleType;
      });
  },
});

export const { setSaleType } = slice.actions;
export default slice.reducer;

import React from 'react';
import PurchaseGiftCardOptions from './PurchaseGiftCardOptions';
import SuperPaymentForm from '../components/SuperPaymentForm';
import PaymentProvider from '../components/PaymentProvider';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { OrderingSelectors } from 'polygon-ordering';
import { useAppSelector } from '../app/hooks';
import NonMemberGiftCardFields from './NonMemberGiftCardFields';
import { useTranslation } from 'react-i18next';

const { getMember } = OrderingSelectors;

const PurchaseGiftCardForSelf: React.FC = () => {
  const { t } = useTranslation();
  const member = useAppSelector(getMember);
  const maxGiftCardValue = useAppSelector(state => state.config.maxGiftCardValue);

  const memberSchema = {
    Amount: Yup.string()
      .required(t('form.required'))
      .test('is-invalid-number', t('form.invalidNumber'), value => {
        if (value) return /^\d+$/.test(value);
        return false;
      })
      // TODO: Globalisation currency
      .test('is-less-than-max-amount', `Maximum $${maxGiftCardValue}`, value => {
        if (value) return Number(value) <= maxGiftCardValue;
        return false;
      }),
  };

  const nonMemberSchema = {
    FirstName: Yup.string().required(t('form.required')),
    LastName: Yup.string().required(t('form.required')),
    Email: Yup.string().required(t('form.required')),
    ConfirmEmail: Yup.string().required(t('form.required')),
    Amount: Yup.string()
      .required(t('form.required'))
      .test('is-invalid-number', t('form.invalidNumber'), value => {
        if (value) return /^\d+$/.test(value);
        return false;
      })
      .test('is-less-than-max-amount', `Maximum $${maxGiftCardValue}`, value => {
        if (value) return Number(value) <= maxGiftCardValue;
        return false;
      }),
  };
  const memberValidationSchema = Yup.object().shape(memberSchema);
  const nonMemberValidationSchema = Yup.object().shape(nonMemberSchema);

  const handleFormSubmit = () => {};

  const memberInitialValues = { Amount: '' };
  const nonMemberInitialValues = {
    Amount: '',
    FirstName: '',
    LastName: '',
    Email: '',
    ConfirmEmail: '',
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={member ? memberValidationSchema : nonMemberValidationSchema}
      initialValues={member ? memberInitialValues : nonMemberInitialValues}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          {!member && <NonMemberGiftCardFields />}
          <PurchaseGiftCardOptions />
          <SuperPaymentForm
            route={member ? 'memberPurchaseGiftCardForSelf' : 'nonMemberPurchaseGiftCardForSelf'}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PurchaseGiftCardForSelf;

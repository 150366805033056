import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = 0;

const slice = createSlice({
  name: '$futureOrderingLimitCatering',
  initialState,
  reducers: {
    setFutureOrderingLimitCatering(state, action: PayloadAction<number>) {
      return Number(action.payload ? action.payload : 0);
    },
  },
});

export const { setFutureOrderingLimitCatering } = slice.actions;
export default slice.reducer;

import { dollarsToCents } from '../misc';

export default function processMember(rawMember: RawMember): _GiftCard {
  return {
    active: ![0, false].includes(rawMember.Active),
    number: rawMember.MemberNo,
    expiryDate: rawMember.ExpiryDate,
    moneyBalance: dollarsToCents(rawMember.Money),
    pin: rawMember.VerifyPIN,
  };
}

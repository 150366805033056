import React from 'react';
//import Icon from 'react-native-vector-icons/AntDesign';
import { StarFilled } from '@ant-design/icons';

import { useAppSelector, useAppDispatch } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import TouchableOpacity from './TouchableOpacity';
import combineStyles from '../utils/combineStyles';
import Text from '../components/Text';
import tinycolor from 'tinycolor2';

/*import Animated, { BounceIn, BounceOut } from 'react-native-reanimated'; */

const RatingStars = ({
  stars,
  setStars,
  spacing = 2,
  label,
  labelContainerStyle,
}: {
  stars: number;
  setStars: (number: number) => void;
  spacing?: number;
  toLabel?: string;
  label?: {
    lowest?: string;
    highest?: string;
  };
  labelContainerStyle?: React.CSSProperties;
}) => {
  const p = useAppSelector(getThemeLookup);

  const filled = p('ratingFilled', ['color']).color;
  const unfilled = tinycolor(p('ratingUnFilled', ['color']).color)
    .darken(10)
    .toRgbString();

  return (
    <div>
      {/* Top */}
      <div style={styles.ratingWrapper}>
        {[...Array(5)].map((x, i) => (
          <TouchableOpacity
            onClick={() => {
              setStars(i + 1);
            }}
            key={i}
          >
            <StarFilled
              style={{
                fontSize: 42,
                color: i + 1 <= stars ? filled : unfilled,
              }}
            />
          </TouchableOpacity>
        ))}
      </div>

      {/* Bottom */}
      {label && (
        <div style={combineStyles(styles.labelRow, labelContainerStyle)}>
          <Text style={p('starLabel', TEXT_PROPERTIES)}>{label?.lowest}</Text>
          <Text style={p('starLabel', TEXT_PROPERTIES)}>{label?.highest}</Text>
        </div>
      )}
    </div>
  );
};

export default RatingStars;

// TODO: make this ratingWrapper and put css in there
const styles: Styles = {
  ratingWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    gap: '0.25em',
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 34,

    // Without this it looks unbalanced
    // had to go against the design with this one.
    padding: '0 8px',
  },
};

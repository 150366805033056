import React, { createRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TouchableOpacity from './TouchableOpacity';
import Text from './Text';
import { RiArrowDropRightLine, RiArrowDropDownLine } from 'react-icons/ri';
import { IconType } from 'react-icons/lib';
import combineStyles from '../utils/combineStyles';

const DropDownWrapper: React.FC<{
  text: React.ReactNode;
  dropDown: boolean;
  toggleCollapse: () => void;
  IconLeft?: IconType | undefined;
  leftIconClick?: () => void;
  textStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  childrenWrapperStyle?: React.CSSProperties;
  enableTransition?: boolean;
  disabled?: boolean;
}> = ({
  children,
  text,
  dropDown = false,
  toggleCollapse,
  IconLeft,
  leftIconClick,
  headerStyle,
  childrenWrapperStyle,
  enableTransition = true,
  disabled = false,
}) => {
  // get expande height (this is used to transition):
  const [childrenHeight, setChildrenHeight] = useState(0);
  const childrenRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (childrenRef.current && childrenRef.current.clientHeight) {
      setChildrenHeight(childrenRef.current.clientHeight);
    }
  }, [childrenRef]);

  return (
    <div style={styles.container}>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <div style={combineStyles(styles.TouchableOpacityContainer, headerStyle)}>
        {IconLeft && (
          <IconButton onClick={leftIconClick}>
            <IconLeft />
          </IconButton>
        )}
        <TouchableOpacity
          onClick={toggleCollapse}
          style={styles.buttonContainer}
          disabled={disabled}
        >
          <div style={styles.left}>{text}</div>

          {!disabled && (
            <RiArrowDropRightLine
              size={24}
              style={combineStyles(
                {
                  transform: 'rotate(0deg)',
                  transition: 'all 0.3s cubic-bezier(0.72, 0.11, 0.01, 0.93)',
                },

                // When dropdown is toggled
                dropDown && {
                  transform: 'rotate(90deg)',
                },
              )}
            />
          )}
        </TouchableOpacity>
      </div>

      <div
        style={combineStyles(
          styles.childrenWrapper,
          dropDown && {
            height: childrenHeight,
            opacity: 1,
            transform: 'scale(1)',
          },
        )}
      >
        <div style={combineStyles({ marginTop: '1em' }, childrenWrapperStyle)} ref={childrenRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default DropDownWrapper;

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },

  childrenWrapper: {
    transition:
      'height 0.3s cubic-bezier(0, 0.61, 0.01, 0.93), opacity 0.45s cubic-bezier(0.72, 0.11, 0.01, 0.93), transform 0.4s cubic-bezier(0, 0.61, 0.01, 0.93)',
    overflow: 'hidden',
    height: 0,
    opacity: 0,
    transform: 'scale(0.9)',
  },

  left: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  TouchableOpacityContainer: {
    display: 'flex',
    gap: 5,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  leftIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

import { createSelector } from 'reselect';

import { OrderingSelectors } from 'polygon-ordering';
import { RootState } from 'store';

const { getLocation } = OrderingSelectors;

const getWhitelist = (state: RootState) => state.locationWhitelist || [];

export default createSelector([getLocation, getWhitelist], (location, whitelist) => {
  if (whitelist.length === 1 && whitelist[0] !== location?.id) {
    return false;
  }

  return true;
});

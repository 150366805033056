import lodash from 'lodash';

export default function determineSaleCharged(e: any) {
  const searchResults = [
    lodash.get(e, 'details.json.additional_info.sale_charged'),
    lodash.get(e, 'details.json.extra_info.sale_charged'),

    // future-proofing against potential error shape changes
    lodash.get(e, 'details.additional_info.sale_charged'),
    lodash.get(e, 'details.extra_info.sale_charged'),
    lodash.get(e, 'additional_info.sale_charged'),
    lodash.get(e, 'extra_info.sale_charged'),
  ].filter(result => result != null);

  if (!searchResults.length) {
    return undefined;
  }

  return searchResults.some(result => result);
}

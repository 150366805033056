import { createSelector } from 'reselect';

import getOrderNotReadyReasons from './getOrderNotReadyReasons';

import { ORDER_NOT_READY_REASON } from '../constants';

export default createSelector([getOrderNotReadyReasons], (reasons): boolean => {
  const nonTable = reasons.filter(
    reason => reason !== ORDER_NOT_READY_REASON.TABLE_REQUIRED,
  );

  return nonTable.length === 0;
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'headerBrandName',
  initialState: null as string | null,
  reducers: {
    setHeaderBrandName(state, action: PayloadAction<string>) {
      return action.payload || null;
    },
  },
});

export const { setHeaderBrandName } = slice.actions;

export default slice.reducer;

import React from 'react';

import ChoiceSetCheckbox from './ChoiceSetCheckbox';
// import ChoiceSetGrid from './ChoiceSetGrid';
// import ChoiceSetList from './ChoiceSetList';
import StagedChoiceSetTags from './StagedChoiceSetTags';

const COMPONENT_MAP = {
  checkbox: ChoiceSetCheckbox,
  //   grid: ChoiceSetGrid,
  //   list: ChoiceSetList,
  tags: StagedChoiceSetTags,
};

const StagedChoiceSet = ({
  choiceSet,
  adjustOpenPurchaseChoice,
  hideNegativeIngredients,
  stagedQty,
}) => {
  const Component = COMPONENT_MAP[choiceSet.displayType] || StagedChoiceSetTags;

  const choiceSetWithoutNegativePricedChoices = {
    ...choiceSet,
    choices: choiceSet.choices.filter(choice => choice.baseMoneyPrice >= 0),
  };

  return (
    <Component
      adjustChoice={adjustOpenPurchaseChoice}
      choiceSet={hideNegativeIngredients ? choiceSetWithoutNegativePricedChoices : choiceSet}
      stagedQty={stagedQty}
    />
  );
};

export default StagedChoiceSet;

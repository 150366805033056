import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import Text from '../components/Text';
import TouchableOpacity from '../components/TouchableOpacity';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';
import { REGISTER_MODAL_ID } from '../modals/RegisterModal';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';

const { getGuestOrderingAvailable, getMember } = OrderingSelectors;

const MustSignIn: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const guestOrderingAvailable = useAppSelector(getGuestOrderingAvailable);
  const member = useAppSelector(getMember);
  const mustSignIn = !guestOrderingAvailable && !member;

  return mustSignIn ? (
    <div>
      <div className="text-center my-4">
        <TouchableOpacity
          onClick={() => dispatch(setCurrentModal({ modalId: SIGN_IN_MODAL_ID }))}
          ariaLabel="sign in"
          //  ref={touchableRef}
        >
          <Text style={combineStyles({ fontSize: 12 }, p('noGuestOrderingText', ['color']))}>
            {t('guestOrderMessageBeginning')}
            <Text themeKey="noGuestOrderingLink">{t('signInLink')}</Text>
            {t('guestOrderMessageConjuction')}
            <Text
              themeKey="noGuestOrderingLink"
              onClick={() => dispatch(setCurrentModal({ modalId: REGISTER_MODAL_ID }))}
            >
              {t('registerLink')}
            </Text>
            {t('guestOrderMessageEnding')}
          </Text>
        </TouchableOpacity>
      </div>
    </div>
  ) : null;
};

export default MustSignIn;

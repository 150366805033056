import { createSelector } from 'reselect';
import lodash from 'lodash';

import getMember from './getMember';
import { getCardSets, getConfig } from './config';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector(
  [getMember, getConfig, getCardSets],
  (member, config, cardsets): number => {
    if (
      !member ||
      !cardsets ||
      !config.enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_REWARDS)
    ) {
      return 0;
    }

    const memberCardset = cardsets[member.cardsetId];

    return lodash.get(memberCardset, 'pointsAwardRate', 0);
  },
);

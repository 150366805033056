import { createSelector } from 'reselect';
import lodash from 'lodash';

import getOrdering from './getOrdering';
import { getEnableDynamicPaymentGatewayConfig } from './config';
import getPaymentGatewayConfig from './getPaymentGatewayConfig';

interface PaymentMethod {
  card?: {
    last4: string;
  };
  id: string;
  type: string;
  customer: string;
}

export default createSelector(
  [getOrdering, getEnableDynamicPaymentGatewayConfig, getPaymentGatewayConfig],
  (ordering, enableDynamicPaymentGatewayConfig, paymentGatewayConfig) => {
    if (enableDynamicPaymentGatewayConfig) {
      return (
        lodash
          .chain(paymentGatewayConfig)
          .get('data.paymentMethods', [])
          //@ts-ignore
          .filter(method => method.type === 'card')
          .map((method: PaymentMethod) => ({
            token: method.id,
            displayValue: method.card?.last4,
            gateway: 'stripe', // TODO: revise when doing adyen
            customer: method.customer,
          }))
          .value()
      );
    } else {
      return ordering.paymentGatewayTokens;
    }
  },
);

import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import TouchableOpacity from '../components/TouchableOpacity';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';

const Hamburger = ({ onClick }: { onClick: () => void }) => {
  const p = useAppSelector(getThemeLookup);
  return (
    <TouchableOpacity onClick={onClick}>
      <GiHamburgerMenu style={p('headerHamburgerButton', ['fontSize', 'color'])} />
    </TouchableOpacity>
  );
};

export default Hamburger;

import lodash from 'lodash';

import createCartIngredientsFromBoostSwap from '../boost/createCartIngredientsFromBoostSwap';
import { createIngredientsFromChoiceSetV2 as createIngredientsFromChoiceSet } from './createIngredientsFromChoiceSet';

const createCartItemFromPurchase = (
  purchase: Purchase,
  choiceOrderingMethod: ChoiceOrderingMethod,
  brands: Brand[],
  rootCategory?: Category,
  boostIngredients?: BoostIngredient[],
): CartItem => {
  const { item: rootItem, choiceSets, quantity, sizeId, plucode } = purchase;
  const isComposite = !!rootItem.sizes.length;
  const item =
    (isComposite && rootItem.sizes.find(s => s.id === sizeId)) || rootItem;

  let { brandId } = purchase;

  if (!brandId && brands.length) {
    // TODO: handle !shouldFlattenCategories
    const categories = lodash.get(rootCategory, 'subCategories', []);

    const firstMatchingCategory = categories.find(
      category =>
        category.items.some(
          catItem =>
            catItem.id === item.id ||
            catItem.sizes.some(catItemSize => catItemSize.id === item.id),
        ) && category.brandId,
    );

    if (firstMatchingCategory) {
      brandId = firstMatchingCategory.brandId;
    }
  }

  const brandAvailable = brandId && brands.some(brand => brand.id === brandId);

  // turn the nested chocie sets into ingredients
  const nestedIngredientsByConditionalPlu = lodash.mapValues(
    item.conditionalChoiceSets,
    nestedChoiceSetIds =>
      lodash
        .chain(nestedChoiceSetIds)
        .map(id => choiceSets.find(choiceSet => id === choiceSet.key)) // map id into actual choice set
        .filter(choiceSet => Boolean(choiceSet)) // in case the find fails
        .map(choiceSet =>
          createIngredientsFromChoiceSet(
            choiceSet as ValidatedChoiceSet, // typescript doesn't understand we filtered out missing sets
            choiceOrderingMethod,
          ),
        )
        .flatten()
        .value(),
  );

  // build a list of nested choice set ids
  const allNestedChoiceSetIds = lodash
    .chain(item.conditionalChoiceSets)
    .values()
    .flatten()
    .value();

  // build a list of immediate choice sets
  const immediateChoiceSets: ValidatedChoiceSet[] = [];

  choiceSets.forEach(choiceSet => {
    if (!allNestedChoiceSetIds.includes(choiceSet.key)) {
      immediateChoiceSets.push(choiceSet);
    }
  });

  // build a list of ingredients from all of the immediate choice sets
  let ingredients: CartIngredient[] = [];

  immediateChoiceSets.forEach(choiceSet => {
    ingredients = [
      ...ingredients,
      ...(boostIngredients && choiceSet.key.includes('swap')
        ? createCartIngredientsFromBoostSwap(boostIngredients, choiceSet)
        : createIngredientsFromChoiceSet(choiceSet, choiceOrderingMethod)),
    ];
  });

  // add nested ingredients (if any)
  ingredients = ingredients.map(ingredient => ({
    ...ingredient,
    Ingredients:
      ingredient.Ingredients && !ingredient.Ingredients.length
        ? nestedIngredientsByConditionalPlu[ingredient.ProductID]
        : ingredient.Ingredients || [],
  }));

  return {
    Price: item.baseMoneyPrice,
    PLUCode: parseInt(plucode),
    ProductID: parseInt(item.id),
    CompositeProductID: (isComposite && parseInt(rootItem.id)) || undefined,
    Qty: quantity,
    Ingredients: ingredients,
    BrandID: brandAvailable ? Number(brandId) : undefined,
  };
};

export default createCartItemFromPurchase;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setMemberData } from '../reducers/member';
import processMember from '../utils/processors/processMember';
import { fetchHistoricalOrders } from './fetchHistoricalOrders';
import { fetchOffers } from './fetchOffers';
import {
  getEnableDynamicPaymentGatewayConfig,
  getOrderingProvider,
} from '../selectors/config';
import { ORDERING_PROVIDER } from '../constants/orderingProvider';
import { fetchTransactionHistory } from './fetchTransactionHistory';
import { fetchPaymentGatewayConfig } from './fetchPaymentGatewayConfig';
import { fetchPaymentGatewayTokens } from './fetchPaymentGatewayTokens';
import { fetchFavouriteOrders } from './fetchFavouriteOrders';
import { fetchLocationOffers } from './fetchLocationOffers';
import delay from '../utils/delay';

export const setMember = createAsyncThunk(
  '$setMember',
  async (
    data: {
      member?: Member;
      rawMember?: RawMember;
      supressFetchingAssociatedData?: boolean;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const { member, rawMember, supressFetchingAssociatedData } = data;

    try {
      if (!member && !rawMember) {
        throw new Error('no member data supplied');
      }

      let processedMember = member;
      const orderingProvider = getOrderingProvider(
        getState() as EntireFrontendState,
      );

      if (rawMember) {
        processedMember = processMember(rawMember);
      }

      await dispatch(setMemberData(processedMember!));

      // TODO: refactor the api fetch kiosk signing function so that we can...
      //  perform all of these calls simultaneously and await them all using Promise.all([])

      // this was originally named fetchMemberOffers which may have been more appropriate, I (Heath) renamed this as I thought it was only relevant for kiosk
      //  however it has more logic included for non-kiosk
      await dispatch(fetchLocationOffers());

      // hoisted from below if statement to speed up:
      // This offers logic needs to be refactored, currently on web/app the user will see all of their expired and redeemed coupons until they start an order
      // because the FE relies on the BE to filter out the coupons properly (see Offer.available in getOffers.ts)
      if (orderingProvider !== ORDERING_PROVIDER.KIOSK) {
        await dispatch(fetchOffers({}));
      }

      // I think the best solution would be to have a function for kiosk that is separate from the normal function
      //  and also remove the promocode logic from the fetchOffers function.
      //  Then filter out expired and redeemed coupons on the FE so that there's no issues with timezones or anything

      // Actually I think ideally payment gateway and location based fetches shouldn't be triggered by the user logging in, instead they should be triggered when the location changes

      await dispatch(
        fetchFavouriteOrders({
          authenticationMethod:
            orderingProvider === ORDERING_PROVIDER.KIOSK
              ? 'trusted'
              : member || rawMember
              ? 'member'
              : 'none',
        }),
      );
      await dispatch(fetchHistoricalOrders({}));

      if (orderingProvider === ORDERING_PROVIDER.KIOSK) {
        return processedMember;
      } else {
        // removed from here and moved above for speed
        // await dispatch(fetchOffers({}));
        await dispatch(fetchTransactionHistory({}));

        if (!supressFetchingAssociatedData) {
          const enableDynamicPaymentGatewayConfig =
            getEnableDynamicPaymentGatewayConfig(
              getState() as EntireFrontendState,
            );

          if (enableDynamicPaymentGatewayConfig) {
            dispatch(fetchPaymentGatewayConfig({}));
          }
        }

        dispatch(fetchPaymentGatewayTokens({}));
      }

      return processedMember;
    } catch (e) {
      console.error('Set member failed', e);
      return rejectWithValue(e);
    }
  },
);

export default function flattenCategories(
  category: Category,
  parentSubcategoryCollector?: Category[],
): Category[] {
  const rootSubcategoryCollector: Category[] = [];

  // decides which collector to use
  // uses the new root collector if no collector was passed into this function
  const determineCollector = () =>
    parentSubcategoryCollector
      ? parentSubcategoryCollector
      : rootSubcategoryCollector;

  if (category.items.length) {
    // if current node has items, include it in the collector
    // (without its subcategorys which are handled in the next section)
    determineCollector().push({
      ...category,
      subCategories: [],
    });
  }

  // recursively call this function on each subcategory of this node
  // pass in a collector for those nodes to use
  [...category.subCategories].forEach(subCategory => {
    flattenCategories(subCategory, determineCollector());
  });

  // return the relevant collector (only really relevant at the initial call site)
  return determineCollector();
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';

import debug from '../utils/debug';
import { RootState } from 'store';

const { updateMenu: updateOrderingMenu, clearBuffer } = OrderingOperations;

const { getRootCategory } = OrderingSelectors;

export const updateMenu = createAsyncThunk(
  'updateMenu',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      const menuPresent = Boolean(getRootCategory(getState() as RootState));

      debug('updateMenu', { menuPresent });

      if (!menuPresent) {
        return;
      }

      dispatch(clearBuffer({ preserveReservationId: false }));

      dispatch(updateOrderingMenu({ isSubFlow: false, autoApply: true }));
    } catch (e) {
      debug(false, 'something went wrong updating the menu', { e });
      return rejectWithValue(e);
    }
  },
);

import React from 'react';
import { useAppSelector } from '../app/hooks';

// selectors
import getConfig from '../selectors/getConfig';

import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { OrderingHooks } from 'polygon-ordering';
import getProfile from '../selectors/getProfile';

const { useFormattedCurrency } = OrderingHooks;

const MemberBalance = ({
  amount,
  label,
  isCurrency,
}: {
  amount: number;
  label: string;
  isCurrency?: boolean;
}) => {
  const profile = useAppSelector(getProfile);
  const loyaltyTiers = useAppSelector(state => state.loyaltyTiers);
  const tier = loyaltyTiers?.filter(tier => tier.tierName === profile?.loyaltyTierName)[0];
  const enableTieredLoyalty = useAppSelector(state => state.config.enableTieredLoyalty);
  const p = useAppSelector(getThemeLookup);
  const config = useAppSelector(getConfig);

  const amountStyle = combineStyles(
    styles.amount,
    p('accountBalanceAmount', TEXT_PROPERTIES),
    enableTieredLoyalty && { color: tier?.tierTextColour },
  );

  return (
    <div style={styles.mainContainer}>
      <div style={amountStyle}>
        {isCurrency
          ? useFormattedCurrency({ cents: amount, hideZeroCents: true })
          : config.displayPointsAsWholeNumber
          ? Math.floor(amount)
          : amount}
      </div>

      <div
        style={combineStyles(
          styles.label,
          p('accountBalanceLabel', TEXT_PROPERTIES),
          enableTieredLoyalty && { color: tier?.tierTextColour },
        )}
      >
        {label}
      </div>
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  amount: {
    textAlign: 'center',
    lineHeight: '100%',
    marginBottom: 10,
  },
  label: {
    textAlign: 'center',
  },
};

export default MemberBalance;

// BOGO == "Buy One, Get One"

import * as _ from 'lodash';
import { sumObjectProperties, dollarsToCents } from '../misc';
import calculateChoiceSetTotals from './calculateChoiceSetTotals';
import { OFFER_TYPE } from '../../constants/offerType';

// this logic needs refactor, could be done a fair bit simpler and not sure why bogo needs its own file
interface TempItem {
  purchaseID: string;
  pluCode: string | undefined;
  baseMoneyPrice: number;
  quantity: number;
}

export const getQualifyAndTargetItems = (
  offer: Offer,
  purchasedItems: PurchaseWithTotals[],
  choiceOrderingMethod: ChoiceOrderingMethod = 'expensive',
): { qItems: TempItem[]; tItems: TempItem[]; sItems: TempItem[] } => {
  const {
    qualifyingPLUs,
    qualifyingPLUClasses,
    qualifyingPLUCategories,
    targetPLUs,
    targetPLUClasses,
    targetPLUCategories,
  } = offer;
  let qItems: TempItem[] = [];
  let tItems: TempItem[] = [];
  let sItems: TempItem[] = [];

  for (let e of purchasedItems) {
    let shared = 0;

    const item: TempItem = {
      purchaseID: e.id,
      pluCode: e.plucode,
      baseMoneyPrice: e.baseMoneyPrice,
      quantity: e.quantity,
    };

    if (
      qualifyingPLUs?.includes(e.plucode) ||
      qualifyingPLUClasses?.includes(e.class) ||
      qualifyingPLUCategories?.includes(e.category)
    ) {
      shared++;
      qItems.push(item);
    }
    if (
      targetPLUs?.includes(e.plucode) ||
      targetPLUClasses?.includes(e.class) ||
      targetPLUCategories?.includes(e.category)
    ) {
      shared++;
      tItems.push(item);
    }

    if (shared === 2) sItems.push(item);
    const choiceSetTotals = sumObjectProperties(
      e.choiceSets.map((set: ValidatedChoiceSet) =>
        calculateChoiceSetTotals(set, choiceOrderingMethod),
      ),
      ['moneyPrice', 'pointsPrice', 'pointsAward'],
    );

    if (e['choicesWithQuantity'].length) {
      for (let choice of e['choicesWithQuantity']) {
        const choiceItem: TempItem = {
          purchaseID: e.id,
          pluCode: choice.id,
          baseMoneyPrice: choice.baseMoneyPrice,
          quantity: choice.quantity * e.quantity,
        };
        // shared = 0;

        if (
          !!choiceItem.pluCode &&
          qualifyingPLUs?.includes(choiceItem.pluCode)
        ) {
          shared++;
          const index = _.findIndex(
            qItems,
            e =>
              e.purchaseID === choiceItem.purchaseID &&
              e.pluCode === choiceItem.pluCode,
          );
          if (index > -1) {
            qItems[index].quantity += choiceItem.quantity;
          } else {
            qItems.push(choiceItem);
          }
        }
        // if (
        //   targetPLUs?.includes(choiceItem.pluCode) &&
        //   choiceSetTotals['moneyPrice'] > 0
        // ) {
        //   if (choiceItem.baseMoneyPrice > 0) {
        //     if (shared === 1) {
        //       tItems.push(choiceItem);
        //       shared++;
        //     } else if (tItems.length) {
        //       const index = _.findIndex(
        //         tItems,
        //         e =>
        //           e.purchasedItemID === choiceItem.purchasedItemID &&
        //           e.pluCode === choiceItem.pluCode,
        //       );
        //       if (index > -1) {
        //         tItems[index].quantity += choiceItem.quantity;
        //       } else {
        //         tItems.push(choiceItem);
        //       }
        //     } else {
        //       tItems.push(choiceItem);
        //     }
        //   }
        // }
        // if (shared === 2) {
        //   sItems.push(choiceItem);
        // }
      }
    }
  }

  return {
    qItems,
    tItems,
    sItems,
  };
};
//bogo coupon validation
export const bogoValidation = (
  selectedOffer: Offer,
  purchasedItems: PurchaseWithTotals[],
  choiceOrderingMethod: ChoiceOrderingMethod,
): boolean => {
  const { qItems, tItems } = getQualifyAndTargetItems(
    selectedOffer,
    purchasedItems,
    choiceOrderingMethod,
  );
  // why are we shallow copying here?
  let qualifyItems = [...qItems];
  let targetItems = [...tItems];

  if (!qualifyItems.length || !targetItems.length) return false;

  if (qualifyItems.length === 1 && targetItems.length === 1) {
    // why would the plucodes not be equal if the purchase ids are equal?
    if (
      qualifyItems[0].pluCode === targetItems[0].pluCode &&
      qualifyItems[0].purchaseID === targetItems[0].purchaseID
    ) {
      if (qualifyItems[0].quantity < 2) return false;
    }
  }

  return true;
};
//bogo coupon discount calculation - with choiceWithQuantity
export const calculateBOGODiscount = (
  offer: Offer,
  purchasedItems: PurchaseWithTotals[],
  purchaseTotals: StagedPurchasesTotals,
  rewardsAccrualRate: number,
  surcharges: Surcharge[],
  couponOrderingMethod: CouponOrderingMethod = 'expensive',
  choiceOrderingMethod: ChoiceOrderingMethod = 'cheapest',
): OrderTotals => {
  const {
    pointsPrice,
    pointsAward,
    moneyPrice: purchasesMoneyPrice,
    moneyDiscount: purchasesMoneyDiscount,
    discountedMoneyPrice: purchasesDiscountedMoneyPrice,
  } = purchaseTotals;

  let orderMoneyDiscount = 0;
  const { amount: surchargesMoneyPrice } = sumObjectProperties(
    surcharges || [],
    ['amount'],
  );

  const moneyPrice = purchasesMoneyPrice + surchargesMoneyPrice;

  let discountedMoneyPrice =
    purchasesDiscountedMoneyPrice + surchargesMoneyPrice;

  const { qItems, tItems, sItems } = getQualifyAndTargetItems(
    offer,
    purchasedItems,
    choiceOrderingMethod,
  );
  let qualifyItems = [...qItems];
  let targetItems = [...tItems];
  let sharedItems = [...sItems];

  const rewardsAward = rewardsAccrualRate
    ? Math.round(discountedMoneyPrice / rewardsAccrualRate)
    : 0;

  const returnValue = {
    pointsPrice,
    pointsAward,
    rewardsAward,
    moneyPrice,
    purchasesMoneyDiscount,
    orderMoneyDiscount,
    moneyDiscount: purchasesMoneyDiscount + orderMoneyDiscount,
    discountedMoneyPrice,
    purchasesMoneyPrice,
    surchargesMoneyPrice,
  };

  if (!qualifyItems.length || !targetItems.length)
    //invalid coupon if no qualifyitem or targetitem
    return returnValue;

  if (qualifyItems.length === 1 && targetItems.length === 1) {
    //invalid coupon if there is only one qualify item which is the only one target item at the same time
    if (
      // why on this line are these objects accessed by string key rather than .?
      qualifyItems[0]['pluCode'] === targetItems[0]['pluCode'] &&
      qualifyItems[0]['purchaseID'] === targetItems[0]['purchaseID']
    ) {
      if (qualifyItems[0]['quantity'] < 2) return returnValue;
    }
  }

  // find max target price item
  let qualifyItemsCounts = 0;
  for (let e of qualifyItems) {
    //get qualify items count
    qualifyItemsCounts += e['quantity'];
  }
  let maxDiscountItem: any = _.maxBy(targetItems, 'baseMoneyPrice'); //max target item price
  let minDiscountItem: any = _.minBy(targetItems, 'baseMoneyPrice'); //min target item price

  if (sharedItems.length && qualifyItemsCounts === 1) {
    //if only one qualify item exist and it is in the target item lists as well then remove the item from target items list
    targetItems = targetItems.filter(
      e => e['pluCode'] !== qualifyItems[0]['pluCode'],
    );
  }

  if (couponOrderingMethod.toLocaleLowerCase() === 'expensive') {
    if (
      //if the item with min price in target items exists in qualify items as well and the quantity is 1 then remove this item from target items list
      _.findIndex(
        qualifyItems,
        e =>
          e['pluCode'] === minDiscountItem['pluCode'] &&
          e['purchaseID'] === minDiscountItem['purchaseID'],
      ) > -1 &&
      qualifyItemsCounts === 1
    ) {
      targetItems = targetItems.filter(
        e =>
          e['pluCode'] !== minDiscountItem['pluCode'] &&
          e['purchaseID'] !== minDiscountItem['purchaseID'],
      );
    }
    minDiscountItem = _.minBy(targetItems, 'baseMoneyPrice'); //the cheapest item in target items
  } else {
    if (
      //if the item with max price in target items exists in qualify items as well and the quantity is 1 then remove this item from target items list

      _.findIndex(
        qualifyItems,
        e =>
          e['pluCode'] === maxDiscountItem['pluCode'] &&
          e['purchaseID'] === maxDiscountItem['purchaseID'],
      ) > -1 &&
      qualifyItemsCounts === 1
    ) {
      targetItems = targetItems.filter(
        e =>
          e['pluCode'] !== maxDiscountItem['pluCode'] ||
          e['purchaseID'] !== maxDiscountItem['purchaseID'],
      );
    }

    maxDiscountItem = _.maxBy(targetItems, 'baseMoneyPrice'); //the most expensive item in target items
  }

  if (offer.type === OFFER_TYPE.AMOUNT_OFF_BOGO) {
    //get the discount based on offer.amount, offer.limit and the max target item price

    orderMoneyDiscount = Math.min(
      dollarsToCents(offer.amount),
      couponOrderingMethod.toLocaleLowerCase() === 'expensive'
        ? minDiscountItem['baseMoneyPrice']
        : maxDiscountItem['baseMoneyPrice'],
    );

    if (offer.limit)
      orderMoneyDiscount = Math.min(orderMoneyDiscount, offer.limit);
  } else if (offer.type === OFFER_TYPE.PERCENTAGE_OFF_BOGO) {
    orderMoneyDiscount =
      (offer.amount *
        (couponOrderingMethod.toLocaleLowerCase() === 'expensive'
          ? minDiscountItem['baseMoneyPrice']
          : maxDiscountItem['baseMoneyPrice'])) /
      100.0;

    if (offer.limit !== 0) {
      orderMoneyDiscount = Math.min(orderMoneyDiscount, offer.limit);
    }
    orderMoneyDiscount = Math.round(orderMoneyDiscount);
  }

  orderMoneyDiscount = Math.min(
    orderMoneyDiscount,
    couponOrderingMethod.toLocaleLowerCase() === 'expensive'
      ? minDiscountItem['baseMoneyPrice']
      : maxDiscountItem['baseMoneyPrice'],
  );

  discountedMoneyPrice = Math.max(0, moneyPrice - orderMoneyDiscount);
  returnValue.orderMoneyDiscount = orderMoneyDiscount;
  returnValue.discountedMoneyPrice = discountedMoneyPrice;
  returnValue.moneyDiscount = purchasesMoneyDiscount + orderMoneyDiscount;

  return returnValue;
};

import lodash from 'lodash';

export default function choiceIsThisIngredient(
  ingredient: BoostIngredient,
  choiceId: string,
) {
  return lodash
    .values(ingredient.choiceMap)
    .some(choice => !!choice && choice.id === choiceId);
}

import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

const getDeliveryCoordinates = createSelector(
  [getCurrentOrder],
  order => order.deliveryCoordinates,
);

export default getDeliveryCoordinates;

export const $getDeliveryCoordinates = createSelector(
  [getDeliveryCoordinates, getBuffer],
  (orderDeliveryCoordinates, buffer) => {
    if (buffer.deliveryCoordinates != null) {
      return buffer.deliveryCoordinates;
    }

    return orderDeliveryCoordinates;
  },
);

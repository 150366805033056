import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'stringOverrides',
  initialState: null as null | string[],
  reducers: {
    setStringOverrides(state, action: PayloadAction<string[]>) {
      return action.payload || null;
    },
  },
});

export const { setStringOverrides } = slice.actions;

export default slice.reducer;

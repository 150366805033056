import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';
import processPromoCode from '../../utils/processors/processPromoCode';

const initialState = null as string | null;

const slice = createSlice({
  name: '$promoCode',
  initialState,
  reducers: {
    setPromoCode(state, action: PayloadAction<string | null>) {
      return processPromoCode(action.payload);
    },
    adjustPromoCode(state, action: PayloadAction<'clear' | 'delete' | string>) {
      switch (action.payload) {
        case 'clear': {
          return initialState;
        }
        case 'delete': {
          return (state || '').slice(0, -1);
        }
        default:
          return `${state || ''}${action.payload}`.trimLeft();
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      return initialState;
    });
  },
});

export const { setPromoCode, adjustPromoCode } = slice.actions;
export default slice.reducer;

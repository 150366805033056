import { createSelector } from '@reduxjs/toolkit';
import getNestedItemStockBalancesData from './getNestedItemStockBalancesData';

// what should this function do?
// I'm going to modify this to account for composite items because it didn't seem to previously
export default (item: Item, purchase: PurchaseWithTotals | undefined) =>
  createSelector(
    [getNestedItemStockBalancesData(purchase)],
    ([stockBalanceDataMap]): StockBalanceData | undefined => {
      const sizeItem =
        (!!purchase?.sizeId &&
          item.sizes &&
          item.sizes.length &&
          item.sizes.find(s => s.id === purchase.sizeId)) ||
        item;

      if (!sizeItem.plucode || !stockBalanceDataMap) return;
      return stockBalanceDataMap[sizeItem.plucode];
    },
  );

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { processLoyaltyTier } from '../utils/processors/processLoyaltyTiers';

const initialState = null as null | LoyaltyTier[];

const slice = createSlice({
  name: 'loyaltytiers',
  initialState,
  reducers: {
    setLoyaltyTiers(state, action: PayloadAction<LoyaltyTier[]>) {
      let loyaltyTiers = action.payload as unknown as LoyaltyTier[];
      let newLoyaltyTiers = loyaltyTiers.map(rawLoyaltyTier => processLoyaltyTier(rawLoyaltyTier));
      return newLoyaltyTiers;
    },
  },
});

export const { setLoyaltyTiers } = slice.actions;

export default slice.reducer;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { makeStyles } from '@material-ui/core';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import DropDownWrapper from './DropDownWrapper';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { RiCloseCircleFill } from 'react-icons/ri';
import Text from './Text';

const { getNotes } = OrderingSelectors;
const { setNotes } = OrderingOperations;

const OrderNotes: React.FC = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const orderNotes = useAppSelector(getNotes);
  const dispatch = useAppDispatch();
  const placeholder = t('placeholder.orderNotes');
  const [dropDown, setDropDown] = useState(false);

  const toggleCollapse = () => {
    setDropDown(preState => !preState);
  };

  const useStyles = makeStyles(() => ({
    root: {
      '& ::-webkit-calendar-picker-indicator': {
        filter: `invert(${p('fieldIcons', ['color']).color})`,
      },
      //label - placeholder background color
      '& .MuiFormLabel-root': {
        backgroundColor: p('labelBackgroundColor', ['color']).color,
        /* borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5, */
      },

      //text field errors
      '&  .MuiFormHelperText-root.Mui-error': {
        backgroundColor: p('helperBackground', ['color']).color,
        color: p('helperColor', ['color']).color,
      },

      // focused color for textfield with variant='outlined'
      '& .MuiOutlinedInput-root': {
        //textfield background color
        background: p('fieldBackground', ['color']).color,

        //text field border unfocused
        '& fieldset': {
          borderColor: p('fieldDefaultBorder', ['color']).color,
        },
        //text field border focused
        '&.Mui-focused fieldset': {
          borderColor: p('fieldBorderFocus', ['color']).color,
        },
        //text field border hover
        '&:hover fieldset': {
          borderColor: p('fieldHoverBorderColor', ['color']).color,
        },
      },
      // label color when unfocused
      '& .MuiInputLabel-root': {
        color: `${p('fieldDefaultBorder', ['color']).color} !important`,
      },
      // label color when focused
      '& .MuiInputLabel-root.Mui-focused': {
        color: `${p('fieldDefaultBorder', ['color']).color} !important`,
      },
      //checkbox
      '&$checked': {
        color: p('checkboxCheckedColor', ['color']).color,
      },
    },

    checked: {},
  }));

  const muiStyles = useStyles();

  const sharedInputProps = {
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton disabled={!orderNotes.length} onClick={() => dispatch(setNotes(''))}>
            <RiCloseCircleFill />
          </IconButton>
        </InputAdornment>
      ),
      style: {
        //textfield text filled colors
        color: p('textColor', ['color']).color,
        ...p('formTextFieldInput', ['color']),
      },
    },
    InputLabelProps: {
      style: {
        //textfield label - placeholder colors
        color: p('labelColor', ['color']).color,
      },
    },
  };

  return (
    <DropDownWrapper
      text={<Text style={p('cartNotes', ['color'])}>{t('button.orderNote.addOrderNote')}</Text>}
      dropDown={dropDown}
      toggleCollapse={toggleCollapse}
      headerStyle={{ padding: wrapperPaddings }}
      childrenWrapperStyle={{ padding: '0 2.25em 1.2em' }}
    >
      <div className="my-2">
        <TextField
          multiline
          minRows={2}
          maxRows={2}
          type="text"
          variant="outlined"
          size="small"
          value={orderNotes || ''}
          fullWidth
          onChange={event => {
            dispatch(setNotes(event?.target?.value));
            logEvent(EVENTS.CHANGE_ORDER_NOTES);
          }}
          InputProps={sharedInputProps.InputProps}
          InputLabelProps={{
            shrink: true,
            ...sharedInputProps.InputLabelProps,
          }}
          className={muiStyles.root}
          label={placeholder}
        />
      </div>
    </DropDownWrapper>
  );
};

export default OrderNotes;

const wrapperPaddings = '0 2.25em';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearBuffer } from '../../actions/buffer';

const initialState = null as MinimumOrderInformation | null;

const slice = createSlice({
  name: '$bufferMinimumOrderInformation',
  initialState,
  reducers: {
    setBufferMinimumOrderInformation(
      state,
      action: PayloadAction<MinimumOrderInformation | null>,
    ) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(clearBuffer, (state, action) => {
      return initialState;
    });
  },
});

export const { setBufferMinimumOrderInformation } = slice.actions;
export default slice.reducer;

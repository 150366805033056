import React from 'react';
import CheckoutButton from './CheckoutButton';
import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector } from '../app/hooks';
import combineStyles from '../utils/combineStyles';

const PlaceOrderButton = () => {
  const p = useAppSelector(getThemeLookup);
  return (
    <div style={combineStyles(styles.bottomBar, p('reviewOrderBottomBar', ['backgroundColor']))}>
      <CheckoutButton containerStyle={styles.buttonContainer} />
    </div>
  );
};

export default PlaceOrderButton;

const styles: Styles = {
  buttonContainer: {
    borderRadius: 5,
    width: '100%',
    maxWidth: 400,
    padding: '0.925em 1.25em',
    margin: 0,
  },
  bottomBar: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    zIndex: 99,
    justifyContent: 'center',
    padding: '0.75em',
    minHeight: 80,
    height: 80,
  },
};

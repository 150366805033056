import React from 'react';
import { FaCircle } from 'react-icons/fa';

import combineStyles from '../utils/combineStyles';

interface IProps {
  color?: string;
  lat?: number;
  lng?: number;
}

const UserMapMarker: React.FC<IProps> = ({ color = '#3F69EB' }) => (
  <FaCircle style={combineStyles(styles.marker, { color })} />
);

const styles: Styles = {
  marker: {
    fontSize: 11,
    boxShadow: '0px 0px 0px 2.5px rgba(255,255,255,1)',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)', // by default markers are position using top left corner
  },
};

export default UserMapMarker;

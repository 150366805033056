import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'appleLoginButtonClientId',
  initialState: null as null | string,
  reducers: {
    setAppleLoginButtonClientId(state, action: PayloadAction<string | null>) {
      return action.payload || null;
    },
  },
});

export const { setAppleLoginButtonClientId } = slice.actions;

export default slice.reducer;

import { createSelector } from 'reselect';

// utils
import { partitionFieldsByVisibility, isNotExcluded, isReadOnly } from '../utils/fields';

import getProfileFields from './getProfileFields';

export default createSelector([getProfileFields], profileFields => {
  if (profileFields) {
    const updateFields: RegisterFields[] = profileFields
      ?.map(field => ({
        ...field,
        registerMode: undefined,
        editMode: undefined,
        mode: isReadOnly(field, 'updateProfileScreen'),
        inputType: isNotExcluded(field, 'updateProfileScreen') ? field.inputType : 'hidden',
        baseInputType: field.inputType,
        required: field.registerMode === 'required' && field.editMode !== 'hidden',
      }))
      .filter(field => field.mode !== 'hidden');

    return { all: updateFields, ...partitionFieldsByVisibility(updateFields) };
  }
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingConstants } from 'polygon-ordering';
import Text from './Text';
import PaymentMethod from './PaymentMethod';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { useAppSelector } from '../app/hooks';

const { getMember, getUnavailableReasonsMemberPoints, getOrderTotals } = OrderingSelectors;
const { PAYMENT_METHOD_UNAVAILABLE_REASON } = OrderingConstants;

const PaymentMethodMemberPoints: React.FC = () => {
  const { t } = useTranslation();
  const member = useAppSelector(getMember);
  const unavailableReasonsMemberPoints = useAppSelector(getUnavailableReasonsMemberPoints);
  const orderTotals = useAppSelector(getOrderTotals);

  if (!member) {
    return null;
  }

  // This is a patch that we are doing to solve the issue of members making an order with not enough member points.
  // The actual fix will be done after Chatime goes live with MIM. We know the fix, but just to avoid risk at the last moment we are not applying that fix.
  const memberNotHaveEnoughPoints =
    member && orderTotals && member.pointsBalance < orderTotals.pointsPrice * 100;

  const reason =
    unavailableReasonsMemberPoints[0] ||
    (memberNotHaveEnoughPoints
      ? PAYMENT_METHOD_UNAVAILABLE_REASON.INSUFFICIENT_BALANCE
      : undefined);

  return (
    <PaymentMethod
      disabled={reason ? true : false}
      method={PAYMENT_METHOD.MEMBER_POINTS}
      right={
        <Text themeKey="paymentMethodAvailableBalance">
          {t('rewardsAvailable') + member.pointsBalance}
        </Text>
      }
      subtitle={
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {reason ? (
            <Text themeKey="paymentMethodUnavailableReason">
              {t(`paymentMethodUnavailableReason.${reason}`)}
            </Text>
          ) : (
            <Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>
          )}
        </div>
      }
    ></PaymentMethod>
  );
};

export default PaymentMethodMemberPoints;

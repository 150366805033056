import { removeUndefinedKeys } from '../../utils/misc';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSaleStatus } from '../../operations';
import { resetOrder } from '../../operations';

const initialState = null as Sale | null;

const slice = createSlice({
  name: '$saleDetails',
  initialState,
  reducers: {
    setSaleDetails(state, action: PayloadAction<Sale>) {
      return action.payload;
    },
    setSaleStatus(state, action: PayloadAction<number>) {
      return {
        ...state,
        status: action.payload,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSaleStatus.fulfilled, (state, action) => {
        const { saleDetails } = action.payload;
        return state
          ? { ...state, ...removeUndefinedKeys(saleDetails) }
          : saleDetails || initialState;
      })
      .addCase(resetOrder, (state, action) => {
        return initialState;
      });
  },
});

export const { setSaleDetails, setSaleStatus } = slice.actions;
export default slice.reducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import MemberBalances from '../components/MemberBalances';
import { getDefaultDropdownMenu, getLoyaltyDropdownMenu } from '../utils/getDropdownMenu';
import { useLocation } from 'react-router-dom';
import StandardButton from '../components/StandardButton';
import combineStyles from '../utils/combineStyles';
import history from '../history';
import getThemeLookup from '../selectors/getThemeLookup';
import { logEvent, orderNow } from '../utils/analytics';
import EVENTS from '../constants/events';
import { signOut } from '../thunks/signOut';
import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';
import determineCalloutOffsets from '../utils/determineCalloutOffsets';
import Text from '../components/Text';
import { setCurrentDrawer } from '../slices/currentDrawer';
import Drawer from './Drawer';
import { removeUserSession } from '../utils/analytics';
import { LOYALTY_MIGRATION_ROUTE } from '../screens/LoyaltyMigrationScreen';

export const LEFT_MENU_DRAWER = 'LEFT_MENU_DRAWER';

const { updateSelectedPaymentMethods } = OrderingOperations;
const { getMember } = OrderingSelectors;

const LeftMenuDrawer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const member = useAppSelector(getMember);
  const currentDrawer = useAppSelector(state => state.currentDrawer);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const loyaltyMenu = getLoyaltyDropdownMenu();
  const loyaltyDisabled = useAppSelector(state => state.config.disableLoyalty);
  const selfRef = React.useRef<HTMLDivElement>(null);
  const enableLoyaltyMigration = useAppSelector(state => state.config.enableLoyaltyMigration);

  const menus = !member
    ? getDefaultDropdownMenu()
    : loyaltyDisabled
    ? getDefaultDropdownMenu()
    : getLoyaltyDropdownMenu();
  const p = useAppSelector(getThemeLookup);

  const { pathname } = useLocation();

  return (
    <Drawer position="left" open={currentDrawer === LEFT_MENU_DRAWER ? true : false}>
      {member && (
        <div style={{ paddingLeft: '2.25em' }}>
          <Text style={{ fontSize: 14, fontWeight: 'bold' }}>
            {member?.givenName + ' ' + member?.surname}
          </Text>
        </div>
      )}
      {member && (
        <div style={{ padding: '0 calc(2.25em - 15px) 0.25em' }}>
          <MemberBalances />
        </div>
      )}

      {enableLoyaltyMigration && !member && (
        <StandardButton
          containerStyle={styles.button}
          themeKey="loyaltyMigrationMenuButton"
          label={t('leftMenu.buttons.loyaltyMigration')}
          onClick={() => {
            dispatch(setCurrentDrawer(null));
            history.push(LOYALTY_MIGRATION_ROUTE);
          }}
        />
      )}

      <StandardButton
        containerStyle={combineStyles(styles.button)}
        themeKey="orderOnlineButton"
        label={t('button.orderOnline')}
        onClick={() => {
          dispatch(setCurrentDrawer(null));
          orderNow();
          history.push('/');
        }}
      />
      {!member && (
        <StandardButton //login button
          ref={selfRef}
          invisibleContainer
          onClick={() => {
            dispatch(setCurrentDrawer(null));
            dispatch(
              setCurrentModal({
                modalId: SIGN_IN_MODAL_ID,
                calloutOffsets: determineCalloutOffsets(
                  (
                    selfRef as React.MutableRefObject<HTMLDivElement>
                  ).current.getBoundingClientRect(),
                ),
              }),
            );
          }}
          label={t('button.headerMemberAbsent')}
          themeKey="defaultDropdownMenuButton"
          containerStyle={combineStyles(
            styles.button,
            deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
          )}
        />
      )}

      {menus.map((screen, index) => (
        <div key={index}>
          <StandardButton
            label={t(screen.label)}
            themeKey={
              pathname === screen.path ||
              (screen.subRoutes &&
                screen.subRoutes?.filter(route => route.path === pathname).length !== 0)
                ? 'activeMemberButton'
                : 'defaultDropdownMenuButton'
            }
            containerStyle={combineStyles(
              styles.button,
              deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
              pathname === screen.path && p('activeMemberButton', ['borderLeft']),
              screen.subRoutes?.filter(route => route.path === pathname).length !== 0 &&
                screen.subRoutes &&
                p('activeMemberButton', ['borderLeft']),
              { display: 'block' },
            )}
            onClick={() => {
              history.push(screen.path);
              if (!screen.subRoutes) {
                dispatch(setCurrentDrawer(null));
              }
            }}
          />
          {screen.subRoutes != undefined &&
            screen.subRoutes?.filter(route => route.path === pathname).length != 0 && (
              <div style={styles.subRouteWrapper}>
                {screen.subRoutes?.map(route => (
                  <div key={route.label}>
                    <StandardButton
                      key={index}
                      label={t(route.label)}
                      themeKey={pathname === route.path ? 'activeSubButton' : 'defaultSubButton'}
                      containerStyle={combineStyles(
                        styles.button,
                        styles.subRouteButton,
                        deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
                      )}
                      onClick={() => {
                        history.push(route.path);
                        dispatch(setCurrentDrawer(null));
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
        </div>
      ))}
      {member && (
        <div>
          <StandardButton
            invisibleContainer
            containerStyle={combineStyles(
              deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
              styles.button,
            )}
            label={t('button.signOut')}
            onClick={() => {
              if (window.confirm(t('confirm.signOut'))) {
                logEvent(EVENTS.SIGN_OUT);
                dispatch(signOut());
                removeUserSession();
                history.push('/');
                dispatch(updateSelectedPaymentMethods({ clearPaymentMethods: true }));
                dispatch(setCurrentDrawer(null));
              }
            }}
            themeKey="defaultDropdownMenuButton"
          />
        </div>
      )}
    </Drawer>
  );
};

export default LeftMenuDrawer;

const styles: Styles = {
  button: {
    textTransform: 'uppercase',
    justifyContent: 'left',
    margin: 0,
    padding: '0.7em 2.25em',
  },

  subRouteButton: {
    padding: '0 2.25em 0 3.25em',
  },

  subRouteWrapper: {
    gap: '0.75em',
    // display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1em',
  },
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as GoogleMapsLocation[] | null;

const slice = createSlice({
  name: 'nearbyLocations',
  initialState,
  reducers: {
    setNearbyLocations(state, action: PayloadAction<GoogleMapsLocation[] | null>) {
      return action.payload;
    },
  },
});

export const { setNearbyLocations } = slice.actions;

export default slice.reducer;

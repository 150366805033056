import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors } from 'polygon-ordering';
import Text from './Text';
import PaymentMethod from './PaymentMethod';
import { useAppSelector } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';

const { getUnavailableReasonsPayLater } = OrderingSelectors;

const PaymentMethodPayLater = () => {
  const { t } = useTranslation();
  const unavailableReasonsPayLater = useAppSelector(getUnavailableReasonsPayLater);
  const reason = unavailableReasonsPayLater[0];

  return (
    <PaymentMethod
      method={PAYMENT_METHOD.PAY_LATER}
      subtitle={<div className="my-2" />}
      right={
        reason ? (
          <Text themeKey="paymentMethodUnavailableReason">
            {t(`paymentMethodUnavailableReason.${reason}`)}
          </Text>
        ) : null
      }
    />
  );
};

export default PaymentMethodPayLater;

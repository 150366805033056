import { dollarsToCents } from '../misc';

export default function processSaleItem(
  rawSaleItem: RawSaleItem,
  mim: boolean = true,
): SaleItem {
  // recursive sale item object from MIM (how it should have been done all along)
  const choiceSets =
    (mim &&
      Object.fromEntries(
        Object.entries(rawSaleItem.ChoiceSets ?? {}).map(
          ([choiceSetID, rawItems]) => [
            choiceSetID,
            rawItems.map(rawItem => processSaleItem(rawItem, true)),
          ],
        ),
      )) ||
    undefined;

  // old style order item
  const ingredients =
    (!mim &&
      (rawSaleItem.Ingredients ?? []).map(rawIngredient =>
        processSaleItem(rawIngredient, false),
      )) ||
    undefined;

  return {
    name: rawSaleItem.DisplayName || rawSaleItem.Name || rawSaleItem.Item,
    pointsPrice: rawSaleItem.Points,
    moneyPrice: dollarsToCents(rawSaleItem.Price),
    quantity: rawSaleItem.Qty,
    plucode: String(rawSaleItem.PLUCode),
    gst: rawSaleItem.GST,
    brandId: rawSaleItem.BrandID ? String(rawSaleItem.BrandID) : undefined,
    redeemPoints: rawSaleItem.RedeemPoints,
    id: String(rawSaleItem.recid_pro),
    choiceSets,
    ingredients,
    compositeId:
      (rawSaleItem.CompositeProductID !== undefined &&
        rawSaleItem.CompositeProductID !== null &&
        String(rawSaleItem.CompositeProductID)) ||
      undefined,
  };
}

import processDeliveryEstimateBase from './processDeliveryEstimateBase';
import processLocationDeliveryEstimate from './processLocationDeliveryEstimate';

export default function processDeliveryEstimate(raw: any) {
  const estimateBase = processDeliveryEstimateBase(raw);

  let locationEstimates: LocationDeliveryEstimate[] = [];

  if (raw?.Estimates == null) {
    locationEstimates.push(processLocationDeliveryEstimate(raw));
  } else {
    locationEstimates = raw.Estimates.map(processLocationDeliveryEstimate);
  }

  return {
    estimateBase,
    locationEstimates,
  };
}

import { createAction } from '@reduxjs/toolkit';
import { SALE_TYPE } from '../constants/saleType';

export const clearBuffer = createAction(
  '$CLEAR_BUFFER',
  (data: { preserveReservationId?: boolean }) => {
    const { preserveReservationId } = data;
    return {
      payload: {
        preserveReservationId,
      },
    };
  },
);

export const setBufferReadyToApply = createAction(
  '$BUFFER_READY_TO_APPLY',
  (readyToApply: boolean) => {
    return {
      payload: {
        readyToApply,
      },
    };
  },
);

export const applyBufferData = createAction(
  '$APPLY_BUFFER_DATA',
  (data: {
    creditCardPaymentInformation?: CreditCardPaymentInformation | null;
    deliveryAddress?: TokenisedAddress | null;
    deliveryCoordinates?: GPSCoordinates | null;
    deliveryProvider?: DeliveryProvider | null;
    deliverySurcharges?: Surcharge[] | null;
    deliveryTime?: string | null;
    locationId?: string;
    menu?: Menu;
    minimumOrderInformation?: MinimumOrderInformation | null;
    offers?: Offer[];
    openPurchase?: _Purchase | null;
    orderingProviderSurchargeInstructions?: SurchargeInstruction[] | null;
    paymentGatewayConfig?: PaymentGatewayConfig | null;
    saleType?: SALE_TYPE;
    stagedPurchases?: _Purchase[];
    timeEstimateReturned?: string | null;
  }) => {
    const {
      creditCardPaymentInformation,
      deliveryAddress,
      deliveryCoordinates,
      deliveryProvider,
      deliverySurcharges,
      deliveryTime,
      locationId,
      menu,
      minimumOrderInformation,
      offers,
      openPurchase,
      orderingProviderSurchargeInstructions,
      paymentGatewayConfig,
      saleType,
      stagedPurchases,
      timeEstimateReturned,
    } = data;

    return {
      payload: {
        creditCardPaymentInformation,
        deliveryAddress,
        deliveryCoordinates,
        deliveryProvider,
        deliverySurcharges,
        deliveryTime,
        locationId,
        menu,
        minimumOrderInformation,
        offers,
        openPurchase,
        orderingProviderSurchargeInstructions,
        paymentGatewayConfig,
        saleType,
        stagedPurchases,
        timeEstimateReturned,
      },
    };
  },
);

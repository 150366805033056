import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearBuffer } from '../../actions/buffer';
import { SALE_TYPE } from '../../constants/saleType';

const initialState = null as SALE_TYPE | null;

const slice = createSlice({
  name: '$bufferSaleType',
  initialState,
  reducers: {
    setBufferSaleType(state, action: PayloadAction<SALE_TYPE | null>) {
      return action.payload == null ? SALE_TYPE.PICKUP : action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(clearBuffer, (state, action) => {
      return initialState;
    });
  },
});

export const { setBufferSaleType } = slice.actions;
export default slice.reducer;

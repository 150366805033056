import React from 'react';
import { MENU_SCREEN_ROUTE, REVIEW_ORDER_SCREEN_ROUTE } from '../hooks/useRoutes';
import { useAppSelector } from '../app/hooks';
import ModalBackButton from './ModalBackButton';
import Text from './Text';
import history from '../history';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

const OrderingWrapper = ({
  title,
  subtitle,
  children,
  bottomContainer,
}: {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  bottomContainer?: React.ReactNode;
}) => {
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  return (
    <div
      className="position-relative"
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <div>
        <div
          style={
            deviceTypeMobile
              ? {
                  position: 'sticky',
                  top: 0,
                  padding: '1em 0',
                  background: '#fff',
                  zIndex: 999,
                }
              : {}
          }
        >
          <div className="mx-2" style={{ position: 'absolute' }}>
            <ModalBackButton
              onClick={() => history.push(MENU_SCREEN_ROUTE, { from: REVIEW_ORDER_SCREEN_ROUTE })}
            />
          </div>

          <div
            style={{
              flex: 1,
              flexDirection: 'row',
              textAlign: 'center',
              fontWeight: '500',

              margin: !deviceTypeMobile ? '1.5rem 0' : 0,
            }}
          >
            <Text value={title} themeKey="checkoutTitle" />
            {subtitle && <Text value={subtitle} themeKey="checkoutTitle" />}
          </div>
        </div>

        <div style={{ marginTop: 15 }}>{children}</div>
      </div>
      <div
        style={{
          marginTop: 'auto',
        }}
      >
        {bottomContainer}
      </div>
    </div>
  );
};

export default OrderingWrapper;

import { objectMap } from '../misc';

// Favourite items come from PX in either the old format (for a pre-mim favourite order) or the new format
export default function processFavouriteItem(
  rawSaleItem: RawSaleItem,
  mim: boolean = true,
): ShadowItem {
  // new format
  const choiceSets =
    (mim &&
      !!rawSaleItem.ChoiceSets &&
      objectMap<RawSaleItem[], ShadowItem[]>(
        rawSaleItem.ChoiceSets,
        (choiceSetChoices, choiceSetId) =>
          choiceSetChoices.map(choice => processFavouriteItem(choice, mim)),
      )) ||
    undefined;

  // old format
  const ingredients =
    (!mim &&
      !!rawSaleItem.Ingredients &&
      rawSaleItem.Ingredients.map(ingredient =>
        processFavouriteItem(ingredient, mim),
      )) ||
    undefined;

  return {
    name: rawSaleItem.Name || rawSaleItem.DisplayName,
    plucode: String(rawSaleItem.PLUCode),
    quantity: Number(rawSaleItem.Qty),

    id:
      (typeof rawSaleItem.recid_pro === 'number' &&
        String(rawSaleItem.recid_pro)) ||
      undefined,
    compositeId:
      (typeof rawSaleItem.CompositeProductID === 'number' &&
        String(rawSaleItem.CompositeProductID)) ||
      undefined,

    choiceSets,
    ingredients,
  };
}

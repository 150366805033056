import calculateStockBalanceData from './calculateStockBalanceData';

export default function (choices, invalidQty) {
  let choiceSoldOut,
    choiceInvalid = false;

  if (choices.length) {
    choices.map(choice => {
      const choiceBalData = calculateStockBalanceData(choice.id);
      if (choiceBalData.soldOut) {
        choiceSoldOut = true;
      }
      if (invalidQty?.hasOwnProperty(choice.id) || choice.quantity > choiceBalData.itemBalance) {
        choiceInvalid = true;
      }
    });
  }

  return { choiceSoldOut, choiceInvalid };
}

import React, { useState, createRef } from 'react';
import lodash from 'lodash';

import combineStyles from '../utils/combineStyles';

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  disabledStyle?: React.CSSProperties;
  disabled?: boolean;
  activeStyle?: React.CSSProperties;
  hoverStyle?: React.CSSProperties;
  className?: string;
  onClick?: (e?: React.TouchEvent | React.MouseEvent) => void;
  enableHoverEffect?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isScrollChild?: boolean;
  ariaLabel?: string;
  enabledDefault?: boolean;
  skipMouseEvent?: boolean;
  enablePropagation?: boolean;
}

interface Coords {
  x?: number;
  y?: number;
}

const TouchableOpacity = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  if (!ref) {
    ref = createRef<HTMLDivElement>();
  }

  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  const [skipMouseEvent, setSkipMouseEvent] = useState(false);
  const [coords, setCoords] = useState<Coords>({});

  const getCurrentCoords = () => {
    const { left: x, top: y } = (
      ref as React.MutableRefObject<HTMLDivElement>
    ).current.getBoundingClientRect();
    return { x, y };
  };

  const touchStart = (e: React.TouchEvent) => {
    const { enablePropagation, disabled, onClick } = props;

    if (disabled || !onClick) {
      return;
    }

    if (!enablePropagation) {
      e.stopPropagation();
    }

    setActive(true);
    setInitialCoords(getCurrentCoords());
  };

  const setInitialCoords = ({ x, y }: Coords) => setCoords({ x, y });

  const mouseDown = (e: React.MouseEvent) => {
    const { enablePropagation, disabled, onClick } = props;

    if (disabled || !onClick) {
      return;
    }

    if (!enablePropagation) {
      e.stopPropagation();
    }

    setActive(true);
  };

  const move = () => {
    if (active) {
      setActive(false);
    }
  };

  const touchEnd = (e: React.TouchEvent) => {
    const { onClick, enablePropagation, enabledDefault, disabled, skipMouseEvent } = props;

    if (disabled || !onClick) {
      return;
    }
    const { x, y } = coords;

    if (!enablePropagation) {
      e.stopPropagation();
    }

    if (!enabledDefault) {
      e.preventDefault();
    }

    setActive(false);

    if (skipMouseEvent) {
      setSkipMouseEvent(true);
    }

    const current = getCurrentCoords();

    const currentTargetElement = document.elementFromPoint(
      e.nativeEvent.changedTouches[0].clientX,
      e.nativeEvent.changedTouches[0].clientY,
    );
    if (
      (ref as React.MutableRefObject<HTMLDivElement>).current.contains(currentTargetElement) &&
      current.x === x &&
      current.y === y
    ) {
      onClick(e);
    }
  };

  const mouseUp = (e: React.MouseEvent) => {
    const { onClick, enabledDefault, disabled } = props;

    setActive(false);

    if (disabled || !onClick || skipMouseEvent) {
      setSkipMouseEvent(false);
      return;
    }

    if (!enabledDefault) {
      e.preventDefault();
    }

    if (!disabled && active) {
      onClick(e);
    }
  };
  const getBoundingClientRect = () =>
    (ref as React.MutableRefObject<HTMLDivElement>).current.getBoundingClientRect();
  const scrollIntoView = (...args: any[]) =>
    (ref as React.MutableRefObject<HTMLDivElement>).current.scrollIntoView(...args);

  const {
    ariaLabel,
    className,
    onClick,
    disabled,
    disabledStyle,
    style,
    hoverStyle,
    isScrollChild,
    onMouseEnter,
    onMouseLeave,
    enableHoverEffect,
    activeStyle,
    children,
  } = props;

  return (
    <div
      aria-label={ariaLabel}
      role="button"
      ref={ref}
      className={className}
      style={combineStyles(
        // Default:
        { 
          userSelect: 'none', 
          WebkitUserSelect: 'none', 
          WebkitTouchCallout: 'none', 
          transition: '0.2s all cubic-bezier(0, 0.58, 0.06, 0.94)'
        },

        // When on click included:
        Boolean(onClick) && !disabled && { cursor: 'pointer' },

        // Disabled:
        (!onClick || disabled) && combineStyles({cursor: 'default'}, disabledStyle),
        style,
        
        // Active:
        !disabled && active && onClick && combineStyles({ opacity: 0.5 }, activeStyle),
        
        !disabled &&
          !active &&
          onClick &&
          enableHoverEffect &&
          hover &&
          combineStyles({ opacity: 0.9 }, hoverStyle),
      )}
      onTouchStart={touchStart}
      onTouchEnd={touchEnd}
      onTouchMove={isScrollChild && active ? lodash.throttle(move, 100) : undefined}
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      onMouseEnter={() => {
        if (onMouseEnter) {
          onMouseEnter();
        }

        if (enableHoverEffect) {
          setHover(true);
        }
      }}
      onMouseLeave={() => {
        setActive(false);

        if (onMouseLeave) {
          onMouseLeave();
        }

        if (enableHoverEffect) {
          setHover(false);
        }
      }}
    >
      {children}
    </div>
  );
});

export default TouchableOpacity;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../actions/currentOrder';
import lodash from 'lodash';

const initialState = null as TransactionHistory[] | null;

const slice = createSlice({
  name: '$transactionHistory',
  initialState,
  reducers: {
    setTransactionHistory(
      state,
      action: PayloadAction<TransactionHistory[] | null>,
    ) {
      return lodash.orderBy(
        action.payload,
        ['TxnDate', 'TxnType'],
        ['desc', 'desc'],
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      const { preserveMember } = action.payload;
      return preserveMember ? state : initialState;
    });
  },
});

export const { setTransactionHistory } = slice.actions;
export default slice.reducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import getThemeLookup from '../selectors/getThemeLookup';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Text from './Text';
import combineStyles from '../utils/combineStyles';
import { RiAlertFill } from 'react-icons/ri';

const Warning = ({
  message,
  visible,
  showAlertIcon = false,
}: {
  message: string;
  visible: boolean;
  showAlertIcon?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  return (
    <div>
      {visible && (
        <div style={styles.messageContainer}>
          {showAlertIcon && (
            <RiAlertFill
              style={combineStyles(
                styles.messageIcon,
                p('orderMessage', ['color', 'fontSize']),
                p('orderMessageIcon', ['color', 'fontSize']),
              )}
            />
          )}
          <Text themeKey="warningMessage">{t(`${message}`)}</Text>
        </div>
      )}
    </div>
  );
};

export default Warning;

const styles: Styles = {
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: 15,
  },
  messageIcon: {
    marginRight: 6,
  },
};

import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

export const LANGUAGES: Language[] = [
  { code: 'en', name: 'English', native: 'English' },
  { code: 'jp', name: 'Japanese', native: '日本語' },
  { code: 'es', name: 'Spanish', native: 'Español' },
  { code: 'de', name: 'German', native: 'Deutsch' },
];

export interface LangResource extends Resource {} // i18next.Resource {}
export function initialiseI18next(language = 'en', resources: LangResource) {
  return (
    i18next
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      //init i18next
      //for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        compatibilityJSON: 'v3',
        lng: language,
        fallbackLng: LANGUAGES.map(lng => lng.code),
        debug: process.env.NODE_ENV !== 'production',
        resources,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      })
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import constructPaymentRequest from '../utils/constructPaymentRequest';
import { setPaymentFormInUse } from '../slices/paymentFormInUse';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { setPaymentMethod } from '../slices/paymentMethod';
import { setPaymentFormFilled } from '../slices/paymentFormFilled';
import { setSavedCardToken } from '../slices/savedCardToken';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { store, RootState } from '../store';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { setGoogleReCaptcha } from '../slices/googleReCaptcha';
import { GIFTCARD_PURCHASE_SUCCESSFUL_MODAL } from '../modals/GiftcardSuccessModal';
import { purchaseGiftCard } from '../utils/analytics';

export const processPayment = createAsyncThunk(
  'processPayment',
  async (data: ProcessPaymentData, { dispatch, getState, rejectWithValue }) => {
    const paymentMethod = (getState() as RootState).paymentMethod;
    const { body, path, modal } = constructPaymentRequest(data);
    const profile = (store.getState() as RootState).profile;

    const fetch = profile ? RedcatApiHandler.authorisedFetch : RedcatApiHandler.fetch;

    return fetch({ method: 'POST', path, body })
      .then(response => {
        if (response.success) {
          if (
            paymentMethod?.method !== PAYMENT_METHOD.APPLE_PAY &&
            paymentMethod?.method !== PAYMENT_METHOD.GOOGLE_PAY
          ) {
            if (modal !== '') {
              dispatch(
                setCurrentModal({
                  modalId: modal,
                  params: {
                    giftcard: response.data,
                  },
                }),
              );
            } else {
              enqueueSuccessSnackbar(response.data.msg || 'Success');
            }
          }
          dispatch(setPaymentFormInUse(false));
          dispatch(setPaymentMethod(null));
          dispatch(setPaymentFormFilled(false));
          dispatch(setSavedCardToken(null));
          dispatch(setGoogleReCaptcha(null));
          if (modal === GIFTCARD_PURCHASE_SUCCESSFUL_MODAL) purchaseGiftCard(response.data.Amount);
        }

        return response;
      })
      .catch(err => {
        if (
          paymentMethod?.method !== PAYMENT_METHOD.APPLE_PAY &&
          paymentMethod?.method !== PAYMENT_METHOD.GOOGLE_PAY &&
          err.details.json.error_code !== 206
        ) {
          dispatch(setPaymentFormInUse(false));
          dispatch(setGoogleReCaptcha(null));
          enqueueErrorSnackbar(err);
        }

        err = { ...err, modal };

        return rejectWithValue(err);
      });
  },
);

interface _3dsData {
  orderInfo: any;
  actionResponse: {
    payment_intent_id: string;
  };
  modal: string;
}

export const process3ds = createAsyncThunk(
  'process3ds',
  async (data: _3dsData, { dispatch, getState, rejectWithValue }) => {
    const profile = (store.getState() as RootState).profile;

    const { modal } = data;

    const fetch = profile ? RedcatApiHandler.authorisedFetch : RedcatApiHandler.fetch;
    const path = profile ? '/api/v1/sale/finalise' : '/api/v1/sale/finalise/nonmember';

    return fetch({ method: 'POST', path, body: data })
      .then(response => {
        const { data, success } = response;

        if (success) {
          if (modal !== '') {
            dispatch(
              setCurrentModal({
                modalId: modal,
                params: {
                  giftcard: response.data,
                },
              }),
            );
          } else {
            enqueueSuccessSnackbar(data.msg ? data.msg : 'Success');
          }
          dispatch(setPaymentFormInUse(false));
          dispatch(setPaymentMethod(null));
          dispatch(setPaymentFormFilled(false));
          dispatch(setSavedCardToken(null));
        }
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
        dispatch(setPaymentFormInUse(false));
        return rejectWithValue(err);
      });
  },
);

import lodash from 'lodash';

export default function identicalPurchase(
  purchaseA: _Purchase,
  purchaseB: _Purchase,
): boolean {
  // Destructuring
  const {
    itemId: itemA = null,
    choiceSelections: choicesA = null,
    sizeId: sizeA = null,
  } = purchaseA;

  const {
    itemId: itemB = null,
    choiceSelections: choicesB = null,
    sizeId: sizeB = null,
  } = purchaseB;

  const itemsHaveSameId = itemA === itemB;
  const itemsHaveSameChoiceSelections = lodash.isEqual(choicesA, choicesB);
  const itemsHaveSameSizes = lodash.isEqual(sizeA, sizeB);

  return itemsHaveSameId && itemsHaveSameChoiceSelections && itemsHaveSameSizes;
}

import { createSelector } from 'reselect';

import getLocalForage from './getLocalForage';
import getPersistedMenu from './getPersistedMenu';
import getPlatform, { PLATFORM } from '../utils/getPlatform';

const platform = getPlatform();

export default createSelector(
  [getLocalForage, getPersistedMenu, (_: any, saleType: SALE_TYPE) => saleType],
  (
    localForage: LocalForageState,
    persistedMenu,
    saleType,
  ):
    | {
        etag: string;
        menu?: [
          MenuNode,
          _Items,
          ChoiceSets,
          RedCat.UpsellChoiceSet[],
          string | undefined | null,
          CategoryInfo[],
        ];
      }
    | undefined => {
    return platform == PLATFORM.WEB
      ? localForage.cachedMenu
        ? localForage.cachedMenu[saleType] || undefined
        : undefined
      : persistedMenu
      ? persistedMenu[saleType] || undefined
      : undefined;
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { ORDERING_PROVIDER } from '../constants/orderingProvider';
import { setCachedMenu } from '../reducers/cachedMenu';
import { getEftposConfig, getExcludedChoiceSets } from '../selectors/config';
import getCachedMenu from '../selectors/getCachedMenu';
import Api, { FetchParams } from '../utils/Api';
import Logger from '../utils/Logger';
import processMenu from '../utils/processors/processMenuV2';
import { blankOrString, mapBooleanToNumber } from '../utils/sagas';

export const defaultMenuProviderV2 = createAsyncThunk(
  '$defaultMenuProviderV2',
  async (data: MenuProviderParams, { dispatch, rejectWithValue, getState }) => {
    const {
      locationId,
      saleType,
      orderingProvider,
      defaultSelectedOnly = false,
    } = data;

    try {
      const path = [
        '/api/v1/stores/',
        locationId,
        '/menu?',
        `&sale_type=${saleType}`,
        blankOrString(
          `&ordering_provider=${orderingProvider}`,
          orderingProvider == null,
        ),
        blankOrString(
          `&default_selected_only=${mapBooleanToNumber(defaultSelectedOnly)}`,
          !defaultSelectedOnly,
        ),
      ].join('');

      const requestParams: FetchParams = {
        path,
        method: 'GET',
      };

      const timeStart = moment();
      const response = await Api.fetch(requestParams);

      const timeEnd = moment();
      const responseTime = timeEnd.diff(timeStart);

      if (orderingProvider === ORDERING_PROVIDER.KIOSK) {
        const eftposConfig = getEftposConfig(getState() as EntireFrontendState);

        Logger.logUpstream({
          DeviceID: eftposConfig.deviceId as string,
          StoreID: locationId!,
          Action: 'FECTH_MENU',
          DateTime: new Date().toLocaleString('en', {
            timeZone: 'Australia/Sydney',
          }),
          Data: {
            Endpoint: path,
            SentBody: null,
            ResponseCode: response && 200, // if there is data we get from the api then we know it will always we 200 otherwise we are throwing a communication error anyway
            ResponseBody: {
              data: {
                Etag: response.data?.Etag,
                alcoholic_items: response.data?.alcoholic_items,
                categories: [],
                category_ids: response.data?.category_ids,
                choicesets: [],
                display_name: response.data?.display_name,
                name: response.data?.name,
                products: [],
                recid: response.data?.recid,
              },
              success: response.success,
            },
            ResponseTime: responseTime,
          },
        });

        //   console.log(
        //     '---------------- @LogUpstream Fetch Api Call -------------------',
        //   );
        //   console.log({
        //     DeviceID: eftposConfig.deviceId as string,
        //     StoreID: currentLocationId!,
        //     Action: 'FECTH_MENU',
        //     DateTime: new Date().toLocaleString('en', {
        //       timeZone: 'Australia/Sydney',
        //     }), //TODO: may need to change the format
        //     Data: {
        //       Endpoint: path,
        //       SentBody: null,
        //       ResponseCode: response && 200,
        //       ResponseBody: {
        //         ...{ ...response.data, choiceset: [], products: [] },
        //         ...response.success,
        //       },
        //       ResponseTime: responseTime,
        //     },
        //   });
        //   console.log('-------------------------------------------');
      }

      const rawMenu = response.data as unknown as RedCat.RawMenu;

      const excludeChoiceSets = getExcludedChoiceSets(
        getState() as EntireFrontendState,
      );

      const cachedMenu = getCachedMenu(
        getState() as EntireFrontendState,
        saleType,
      );
      if (cachedMenu) {
        const { etag, menu } = cachedMenu;
        if (etag == response.data['Etag']) {
          if (menu) {
            const [
              rootMenuNode,
              items,
              choiceSets,
              upsells,
              upsellTitle,
              allCategoryInfo,
              boostIngredients = null,
            ] = menu;

            return [
              rootMenuNode,
              items,
              choiceSets,
              upsells,
              upsellTitle,
              allCategoryInfo,
              boostIngredients,
            ];
          } else {
            const processed_menu = processMenu(
              rawMenu,
              true,
              excludeChoiceSets!,
            );
            dispatch(
              setCachedMenu({
                saleType,
                menu: processed_menu,
                etag: response.data['Etag'],
              }),
            );
            return processed_menu;
          }
        } else {
          const processed_menu = processMenu(rawMenu, true, excludeChoiceSets!);
          dispatch(
            setCachedMenu({
              saleType,
              menu: processed_menu,
              etag: response.data['Etag'],
            }),
          );
          return processed_menu;
        }
      } else {
        const processed_menu = processMenu(rawMenu, true, excludeChoiceSets!);
        dispatch(
          setCachedMenu({
            saleType,
            menu: processed_menu,
            etag: response.data['Etag'],
          }),
        );
        return processed_menu;
      }
    } catch (e) {
      console.error('Default menu provider v2 thunk failed');
      return rejectWithValue(e);
    }
  },
);

export default function translateChoiceSelectionsBetweenItems(
  previousSelections: ChoiceSelections | NestedChoiceSelections,
  previousItem: Item | undefined,
  nextItem: Item,
): ChoiceSelections | NestedChoiceSelections {
  const nextSelections: ChoiceSelections | NestedChoiceSelections = {};

  nextItem?.choiceSets?.forEach(nextSet => {
    if ('nestedIngredients' in nextSet) {
    } else nextSelections[nextSet.key] = [];
  });

  if (!previousSelections) {
    return nextSelections;
  }

  Object.keys(previousSelections).forEach(currentSetKey => {
    const previousSet = previousItem?.choiceSets.find(
      set => set.key === currentSetKey,
    );

    const matchingSet =
      nextItem?.choiceSets.find(set => set.key === previousSet?.key) ??
      nextItem?.choiceSets.find(set => set.id === previousSet?.id) ??
      // hack fix for if reordering an unavailable item and switching to a menu where the menu is available
      nextItem?.choiceSets.find(set => set.id === currentSetKey);

    if (matchingSet && (previousSet || !previousItem)) {
      const choiceMap: SDict<string> = {};
      const isNestedSet =
        'nestedIngredients' in matchingSet &&
        (matchingSet as NestedChoiceSet).nestedIngredients;

      if (isNestedSet) {
        nextSelections[matchingSet.key] = previousSelections[currentSetKey];
      } else {
        // cringe-worthy typescript usage, this supports string ids as selections or duplicated choice objects
        nextSelections[matchingSet.key] = (
          previousSelections[currentSetKey] as (string | ChoiceWithQuantity)[]
        ).map((choiceSelection: string | ChoiceWithQuantity) => {
          return typeof choiceSelection === 'string'
            ? choiceSelection
            : (choiceSelection as ChoiceWithQuantity).id;
        });
      }
    }
  });

  return nextSelections;
}

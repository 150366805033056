import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'thresholdOrderingProviders',
  initialState: null as string[] | null,
  reducers: {
    setThresholdOrderingProviders(state, action: PayloadAction<string[] | null>) {
      return action.payload || null;
    },
  },
});

export const { setThresholdOrderingProviders } = slice.actions;

export default slice.reducer;

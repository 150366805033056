import { createAsyncThunk } from '@reduxjs/toolkit';
import lodash from 'lodash';
import { RedcatApiHandler } from 'polygon-utils';
import { getLocation } from '../selectors';
import Logger from '../utils/Logger';

export const checkStoreStatus = createAsyncThunk(
  'checkStoreStatus',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      const location = getLocation(
        getState() as EntireFrontendState,
      ) as POSLocation;

      const response = await RedcatApiHandler.fetch({
        method: 'GET',
        path: `/api/v1/stores/${location.id}`,
      });

      Logger.log('checking store status....', undefined);

      const valid = response.success;
      const storeStatus = response?.data?.StoreStatus;

      if (!valid) {
        Logger.log('Failed to call the Store Api:', undefined);
        Logger.log('---- STORE STATUS ERROR:', undefined, response);
      }

      Logger.log('check store status: ', undefined);
      Logger.log(JSON.stringify(storeStatus), undefined);

      return response;
    } catch (e) {
      console.error('Fetch theme overrides failed', { e });
      return rejectWithValue(e);
    }
  },
);

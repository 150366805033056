import lodash from 'lodash';

type ErrorType = {
  error?: string | undefined;
  details?: {} | undefined;
  extra_info?: any;
} & Error;

function isValidationError(e: ErrorType) {
  return [e.error, e.message].includes('Validation Error');
}

export function apiErrorToString(e: ErrorType) {
  if (isValidationError(e)) {
    return validationErrorToString(e);
  } else {
    let text =
      lodash.get(e, 'details.json.error') || lodash.get(e, 'message') || 'A problem has occurred';

    const additionalInfo = lodash.get(e, 'details.json.additional_info');

    if (typeof additionalInfo === 'string') {
      text = `${text}
      ${additionalInfo}`;
    }

    return text;
  }
}

function validationErrorLineToString({ msg, path }: { msg: string; path: [] }) {
  return `${msg} ${path && path.length ? `(${path.join(' > ')})` : ''}`;
}

function validationErrorToString(e: ErrorType) {
  const info = lodash.get(e, 'details.json.extra_info') || lodash.get(e, 'extra_info') || [];

  const multiple = info.length > 1;

  if (!info || !info.length) {
    return 'Validation Error';
  }

  const top = `Validation Error${multiple ? 's' : ''}:`;

  const lines = info?.map(validationErrorLineToString);

  if (!multiple) {
    return `${top} ${lines[0]}`;
  }

  return [top, ...lines].join('\n');
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: '$enableDynamicPaymentGatewayConfig',
  initialState,
  reducers: {
    setEnableDynamicPaymentGatewayConfig(
      state,
      action: PayloadAction<boolean>,
    ) {
      return Boolean(action.payload);
    },
  },
});

export const { setEnableDynamicPaymentGatewayConfig } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'firstSyncComplete',
  initialState: false,
  reducers: {
    setFirstSyncComplete(state, action: PayloadAction<boolean>) {
      return true;
    },
  },
});

export const { setFirstSyncComplete } = slice.actions;

export default slice.reducer;

import { objectMap } from '../misc';

// NOTE: server-friendly format
interface FavouriteItem {
  Qty: number;
  PLUCode: number;
  // Ingredients?: FavouriteItem[];
  ChoiceSets?: SDict<FavouriteItem[]>;

  CompositeProductID?: number;
  recid_pro?: number;
}

// PLUcode is not the primary key, but this is what we have
// PLUcodes less than 20 are generated by the system, we filter these out here
export function filterSystemPLUs(input: FavouriteItem[]): FavouriteItem[] {
  return input.filter(item => item.PLUCode >= 20);
}

// create object to send to PX
function createFavouriteItemFromShadowItem(
  shadowItem: ShadowItem,
): FavouriteItem {
  return {
    PLUCode: parseInt(shadowItem.plucode),
    Qty: shadowItem.quantity,
    ChoiceSets:
      shadowItem.choiceSets &&
      objectMap(shadowItem.choiceSets, choiceSetItems =>
        choiceSetItems.map(createFavouriteItemFromShadowItem),
      ),

    CompositeProductID:
      (!!shadowItem.compositeId && parseInt(shadowItem.compositeId)) ||
      undefined,
    recid_pro: (!!shadowItem.id && parseInt(shadowItem.id)) || undefined,
  };
}

export default createFavouriteItemFromShadowItem;

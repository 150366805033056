export default function processAddress(
  rawAddress: RawTokenisedAddress,
): TokenisedAddress {
  return {
    unit: rawAddress.Unit,
    streetNumber: rawAddress.StreetNumber,
    streetName: rawAddress.StreetName,
    postcode: rawAddress.Postcode,
    state: rawAddress.State,
    suburb: rawAddress.Suburb,
    country: rawAddress.Country,
  };
}

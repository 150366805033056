import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const resendInvoice = createAsyncThunk(
  '$resendInvoice',
  async (data: { id: string }) => {
    const { id } = data;
    const path = `/api/v1/profile/orders/invoice/${id}`;

    return RedcatApiHandler.authorisedFetch({
      path,
      method: 'GET',
    });
  },
);

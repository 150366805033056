export function processLoyaltyTier(raw) {
  return {
    tierColour: raw.Colour,
    tierTextColour: raw.TextColour,
    tierName: raw.Name,
    tierThreshold: raw.PromotionThreshold,
    nextTier: raw.NextTier,
    id: raw.ID,
  };
}

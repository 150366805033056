import React, { useEffect, useRef, useState } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';

import { OrderingSelectors } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';
import getEditLocationAvailable from '../selectors/getEditLocationAvailable';

import combineStyles from '../utils/combineStyles';

import Text from './Text';
import TouchableOpacity from './TouchableOpacity';

import { LOCATION_MODAL_ID } from '../modals/LocationModal';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSLine } from 'react-icons/ri';
import { Dropdown, DropdownMenu } from 'reactstrap';
import { adjustOrder } from '../thunks/adjustOrder';
import { setHandleURLParamsInProgress } from '../slices/handleURLParamsInProgress';
import { DELIVERY_DETAILS_MODAL_ID } from '../modals/DeliveryDetailsModal';

import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import DropdownContainer from './DropdownContainer';

const { getLocation, getSaleType, getEnabledSaleTypes, getTable } = OrderingSelectors;

const availableSaleTypes = [
  SALE_TYPE.PICKUP,
  SALE_TYPE.DELIVERY,
  SALE_TYPE.DINE_IN,
  SALE_TYPE.TAKE_AWAY,
  SALE_TYPE.TABLE_ORDER,
  SALE_TYPE.CATERING,
];

const MenuLocationDetails: React.FC = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const location = useAppSelector(getLocation);
  const saleType = useAppSelector(getSaleType);
  const editLocationAvailable = useAppSelector(getEditLocationAvailable);
  const enableMenuLocationDetails = useAppSelector(state => state.config.enableMenuLocationDetails);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const enabledSaleTypes = useAppSelector(getEnabledSaleTypes);
  const displayableSaleTypes = availableSaleTypes.filter(option =>
    enabledSaleTypes.includes(option),
  );
  const remainingSaleTypes = displayableSaleTypes?.filter(t => t !== saleType);
  const table = useAppSelector(getTable);
  const currentDrawer = useAppSelector(state => state.currentDrawer);
  const currentModal = useAppSelector(state => state.currentModalId);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const mousedownHandler = (event: Event) => {
      // close dropdown if click happened outside of the dropdown
      if (menuRef?.current && !menuRef?.current?.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    // listen for clicks on the whole document
    document.addEventListener('mousedown', mousedownHandler);

    return () => {
      // cleanup
      document.removeEventListener('mousedown', mousedownHandler);
    };
  }, [menuRef]);

  useEffect(() => {
    if (currentDrawer || currentModal) {
      setIsOpen(false);
    }
  }, [currentDrawer, currentModal]);

  if (!location || !enableMenuLocationDetails) {
    return null;
  }

  return (
    <div ref={menuRef}>
      <div style={deviceTypeMobile ? styles.mobileContainer : styles.mainContainer}>
        <TouchableOpacity
          onClick={() => {
            setIsOpen(open => !open);
          }}
          style={combineStyles(
            !deviceTypeMobile && styles.buttonContainer,
            editLocationAvailable && !deviceTypeMobile && styles.editable,
            p(deviceTypeMobile ? 'menuLocationDetailsMobile' : 'menuLocationDetails', [
              'backgroundColor',
            ]),
          )}
          disabled={!editLocationAvailable}
          ariaLabel={editLocationAvailable ? 'edit location' : 'location'}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: deviceTypeMobile ? 0 : '0.25em',
            }}
          >
            <Text
              themeKey="menuLocationDetailsName"
              style={deviceTypeMobile ? { ...p('menuLocationDetailsNameMobile', ['color']) } : {}}
            >
              {location?.name}
            </Text>
            <Text
              themeKey="menuLocationDetailsSaleType"
              style={combineStyles(
                deviceTypeMobile
                  ? {
                      ...p('menuLocationDetailsSaleTypeMobile', ['color', 'textAlign', 'fontSize']),
                    }
                  : {},

                { paddingLeft: 5 },
              )}
            >
              {saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER
                ? table
                  ? `Table ${table}`
                  : `Enter table`
                : t(`saleType.${saleType}.name`)}
              <RiArrowDownSLine size={20} />
            </Text>
          </div>
        </TouchableOpacity>
      </div>

      <DropdownContainer isOpen={isOpen}>
        <Dropdown isOpen={isOpen} toggle={() => {}}>
          <DropdownMenu
            style={{
              transform: 'translate(-50%, 0)',
              padding: '0.35em',
              borderRadius: '10px',
              border: 'none',
              boxShadow: 'rgb(0 0 0 / 16%) 0px 4px 17px -1px, rgb(0 0 0 / 9%) 0px 2px 2px -1px',
              width: 'max-content',
            }}
          >
            <TouchableOpacity
              style={{
                textAlign: 'left',
                padding: '0.6em 0.75em',
                borderRadius: '6px',
              }}
              hoverStyle={{
                background: '#EBEBEB',
              }}
              enableHoverEffect
              onClick={() => {
                setIsOpen(status => !status);
                if (saleType === SALE_TYPE.DELIVERY) {
                  dispatch(
                    setCurrentModal({
                      modalId: DELIVERY_DETAILS_MODAL_ID,
                      params: { saleType },
                    }),
                  );
                } else if (saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER) {
                  dispatch(
                    setCurrentModal({
                      modalId: LOCATION_MODAL_ID,
                      params: {
                        saleType,
                        locationId: Number(location?.id),
                        action: () => {
                          dispatch(setCurrentModal(null));
                        },
                      },
                    }),
                  );
                } else {
                  dispatch(
                    setCurrentModal({
                      modalId: LOCATION_MODAL_ID,
                      params: { saleType },
                    }),
                  );
                }
              }}
            >
              <Text style={{ fontSize: 14 }}>
                Change{' '}
                {`${
                  saleType === SALE_TYPE.TABLE_ORDER || saleType === SALE_TYPE.DINE_IN
                    ? 'Table'
                    : saleType === SALE_TYPE.DELIVERY
                    ? 'Delivery Address'
                    : `${t(`saleType.${saleType}.name`)} Location`
                }`}
              </Text>
            </TouchableOpacity>

            {remainingSaleTypes.length > 0 ? <hr></hr> : null}

            {remainingSaleTypes.map((type, index) => (
              <TouchableOpacity
                style={{
                  textAlign: 'left',
                  padding: '0.6em 0.75em',
                  borderRadius: '6px',
                }}
                hoverStyle={{
                  background: '#EBEBEB',
                }}
                enableHoverEffect
                onClick={async () => {
                  setIsOpen(status => !status);

                  if (type === SALE_TYPE.DELIVERY) {
                    dispatch(
                      setCurrentModal({
                        modalId: DELIVERY_DETAILS_MODAL_ID,
                        params: { saleType: type },
                      }),
                    );
                  } else if (type === SALE_TYPE.DINE_IN || type === SALE_TYPE.TABLE_ORDER) {
                    if (saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER) {
                      await dispatch(
                        adjustOrder({
                          saleType: type,
                          locationId: location?.id,
                        }),
                      );
                    } else {
                      dispatch(
                        setCurrentModal({
                          modalId: LOCATION_MODAL_ID,
                          params: {
                            saleType: type,
                            locationId: Number(location?.id),
                            action: async () => {
                              await dispatch(
                                adjustOrder({
                                  saleType: type,
                                  locationId: location?.id,
                                }),
                              );
                            },
                          },
                        }),
                      );
                    }
                  } else {
                    dispatch(setHandleURLParamsInProgress(true));
                    await dispatch(
                      adjustOrder({
                        saleType: type,
                        locationId: location?.id,
                      }),
                    );
                    dispatch(setHandleURLParamsInProgress(false));
                  }
                }}
                key={type}
              >
                <Text style={{ fontSize: 14 }}>{t(`saleType.${type}.name`)}</Text>
              </TouchableOpacity>
            ))}
          </DropdownMenu>
        </Dropdown>
      </DropdownContainer>
    </div>
  );
};

const styles: Styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5em 40px',
    borderRadius: '50px',
  },
  mobileContainer: {},
  mainContainer: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    alignItems: 'center',
    textAlign: 'center',
    transform: 'translate(-50%,0)',
  },
  editable: {
    marginBottom: '1.8em',
  },
  editIcon: {
    opacity: 0.9,
    marginLeft: -4,
    marginRight: -4,
  },
};

export default MenuLocationDetails;

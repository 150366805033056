import { createSelector } from 'reselect';
import { RootState } from 'store';

import { formatDate } from '../utils/misc';

import getConfig from './getConfig';

export const _getProfile = (state: RootState) => state.profile;

export default createSelector([_getProfile, getConfig], (profile, config) => {
  return profile
    ? {
        ...profile,
        fullName: `${profile.givenNames} ${profile.surname}`.trim().replace(/ +(?= )/g, ''),
        verified: !!profile.verificationDate || !config.loyaltyRequiresVerification,
        registrationDate: formatDate(profile.registrationDate),
        isRedcatStaff:
          profile.emailAddress!.endsWith('@redcat.com.au') && !!profile.verificationDate,
      }
    : undefined;
});

import { createAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export const setPurchaser = createAction(
  '$SET_PURCHASER',
  (data: _Purchaser) => {
    return {
      payload: data,
    };
  },
);

export const resetOrder = createAction(
  '$RESET_ORDER',
  (data: { preserveMember: boolean; uuid?: string }) => {
    const { preserveMember, uuid = uuidv4() } = data;
    return {
      payload: {
        preserveMember,
        uuid,
      },
    };
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setBufferDeliveryAddress } from '../reducers/buffer/deliveryAddress';
import { setBufferDeliveryCoordinates } from '../reducers/buffer/deliveryCoordinates';
import { setBufferLocationDeliveryEstimates } from '../reducers/buffer/locationDeliveryEstimates';
import { getOrderingProvider } from '../selectors/config';
import { $getDeliveryAddressString } from '../selectors/getDeliveryAddressString';
import { $getDeliveryTime } from '../selectors/getDeliveryTime';
import { ASAP_TIME, FAILURE_REASON } from '../constants';
import moment from 'moment';
import { $getStagedPurchaseCount } from '../selectors/getStagedPurchaseCount';
import lodash from 'lodash';
import { $getOrderTotals } from '../selectors/getOrderTotals';
import Api, { ApiResponse, FetchParams } from '../utils/Api';
import combineTokenisedAddress from '../utils/ordering/combineTokenisedAddress';
import processAddress from '../utils/processors/processAddress';
import processDeliveryEstimate from '../utils/processors/processDeliveryEstimate';
import { applyLocationEstimate } from './applyLocationEstimate';

export const fetchDeliveryEstimate = createAsyncThunk(
  '$fetchDeliveryEstimate',
  async (
    data: {
      deliveryAddress?: string;
      desiredDeliveryTime?: string;
      locationId?: string;
      multiple?: boolean;
      forceASAPDeliveryEstimate?: boolean;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    let reason = FAILURE_REASON.UNKNOWN;
    let userReason: string | undefined;
    let systemReason: string | undefined;
    let matchedAddressString: string | undefined;

    try {
      const {
        deliveryAddress,
        desiredDeliveryTime,
        locationId,
        multiple = false,
        forceASAPDeliveryEstimate = false,
      } = data;

      const performMultiple = locationId != null ? false : multiple;

      const addressString =
        deliveryAddress ||
        $getDeliveryAddressString(getState() as EntireFrontendState);

      if (!addressString) {
        reason = FAILURE_REASON.MISSING_PARAMETER;
        throw new Error('address required');
      }

      let deliveryTime =
        desiredDeliveryTime ||
        $getDeliveryTime(getState() as EntireFrontendState) ||
        ASAP_TIME;

      if (deliveryTime != ASAP_TIME) {
        deliveryTime = moment(deliveryTime).format();
      }

      if (forceASAPDeliveryEstimate) {
        deliveryTime = ASAP_TIME;
      }

      const orderTotals = $getOrderTotals(getState() as EntireFrontendState);
      const total = lodash.get(orderTotals, 'purchasesMoneyPrice', 0);

      const stagedPurchaseCount =
        $getStagedPurchaseCount(getState() as EntireFrontendState) || 0;
      const orderingProvider = getOrderingProvider(
        getState() as EntireFrontendState,
      );

      const urlParams = new URLSearchParams(
        //@ts-ignore
        lodash.pickBy(
          {
            desiredTime: deliveryTime,
            address: addressString,
            itemTotal: total,
            qtyTotal: stagedPurchaseCount,
            orderingProvider,
            store: locationId,
            multiple: performMultiple,
          },
          value => value != null,
        ),
      );

      const params: FetchParams = {
        path: `/api/v1/delivery/best-estimate?${urlParams.toString()}`,
        method: 'GET',
      };

      const response: ApiResponse = await Api.fetch(params);

      console.log('fetched delivery estimate', { response, params });

      const rawEstimate = response?.data as RawDeliveryEstimateBase;

      if (!rawEstimate.DeliveryAvailable) {
        if (rawEstimate.DeliveryAddress) {
          reason = FAILURE_REASON.DELIVERY_UNAVAILABLE;
          matchedAddressString = combineTokenisedAddress(
            processAddress(rawEstimate.DeliveryAddress),
          );
        } else {
          reason = FAILURE_REASON.ADDRESS_NOT_FOUND;
        }

        userReason = rawEstimate.UserNotPossibleReason;
        systemReason = rawEstimate.NotPossibleReason;

        throw new Error(
          `delivery not available: ${rawEstimate.NotPossibleReason}`,
        );
      }

      const { estimateBase, locationEstimates } =
        processDeliveryEstimate(rawEstimate);

      dispatch(setBufferDeliveryAddress(estimateBase.address));
      dispatch(setBufferDeliveryCoordinates(estimateBase.coordinates));

      if (performMultiple) {
        dispatch(setBufferLocationDeliveryEstimates(locationEstimates));
      } else {
        dispatch(applyLocationEstimate(locationEstimates[0]));
        dispatch(setBufferLocationDeliveryEstimates(locationEstimates));
      }

      return {
        multiple: performMultiple,
      };
    } catch (e) {
      console.warn('Fetch delivery estimate failed', { e });
      const _userReason = lodash.get(e, 'details.json.data.NotPossibleReason');
      const _systemReason = lodash.get(
        e,
        'details.json.data.UserNotPossibleReason',
      );
      return rejectWithValue({
        e,
        userReason: userReason || _userReason,
        reason,
        systemReason: systemReason || _systemReason,
      });
    }
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as ShadowItem[] | null;

const slice = createSlice({
  name: '$cart',
  initialState,
  reducers: {
    setShadowCart: (state, action: PayloadAction<ShadowItem[] | null>) => {
      return action.payload;
    },
    addToShadowCart: (state, action: PayloadAction<ShadowItem[] | null>) => {
      return action.payload === null
        ? state
        : [...(state || []), ...action.payload];
    },
  },
});

export const { setShadowCart, addToShadowCart } = slice.actions;
export default slice.reducer;

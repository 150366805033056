import React from 'react';
import { useAppSelector } from '../app/hooks';
import SectionTitle from './SectionTitle';
import AddressItem from './AddressItem';

const RecentLocations = () => {
  const recentLocations = useAppSelector(state => state.recentLocations);

  return (
    <div className="my-2">
      {recentLocations.length > 0 && <SectionTitle value="Recent" />}
      {recentLocations?.map(location => (
        <AddressItem location={location} key={location.formatted_address} />
      ))}
    </div>
  );
};

export default RecentLocations;

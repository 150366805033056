// NOTE: in order to be usable inside a .d.ts file, this enum
// must not be exported/imported explicitly. This makes it kind of global.
// It must also be a const enum, otherwise it will fail (only at runtime,
// the compiler will lie and say everything is fine)
import { DUMMY } from './dummy';

export enum ORDERING_PROVIDER {
  // NONE = null, // Q: is this used?
  REDCAT = 1,
  DELIVEROO = 2,
  POS = 3,
  UBER = 4,
  INDEPENDENT = 5,
  FOODORA = 6,
  MENULOG = 7,
  ORDERUP = 8,
  KIOSK = 9,
  GOOGLE = 10,
  MOBI2GO = 11,
  DOORDASH = 12,
  TABSQUARE = 14,
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as LocationCoordinates | null;

const slice = createSlice({
  name: 'mapCenter',
  initialState,
  reducers: {
    setMapCenter(state, action: PayloadAction<LocationCoordinates | null>) {
      return action.payload;
    },
  },
});

export const { setMapCenter } = slice.actions;

export default slice.reducer;

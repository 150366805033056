import React from 'react';
import { useAppSelector } from '../app/hooks';
import { Marker } from '@react-google-maps/api';
import getUserLocation from '../selectors/getUserLocation';

const CurrentLocationMarker = () => {
  const userCoords = useAppSelector(getUserLocation);

  return (
    <Marker
      icon={{
        path: window.google?.maps?.SymbolPath.CIRCLE,
        scale: 7,
        fillOpacity: 1,
        strokeWeight: 2,
        fillColor: '#5384ED',
        strokeColor: '#ffffff',
      }}
      position={{
        lat: userCoords.latitude,
        lng: userCoords.longitude,
      }}
    />
  );
};

export default CurrentLocationMarker;

import { sumObjectProperties } from '../misc';
import choiceSetDiscountPlus from './choiceSetDiscountPlus';
import sortChoices from './sortChoices';
// nested mim item friendly recursive tallying function
// takes in a choice selections object and an item's choice sets and calculates the total prices for the item
// able to handle old-style and mim-style choice selections (ideally there should be one format)
export default function calculateChoiceSelectionsTotals(
  choiceSelections: NestedChoiceSelections | SDict<string[]>,
  choiceSets: ChoiceSet[],
  choiceOrderingMethod: ChoiceOrderingMethod,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
): { moneyPrice: number; pointsPrice: number; pointsAward: number } {
  const sumsTotal = { moneyPrice: 0, pointsPrice: 0, pointsAward: 0 };
  Object.entries(choiceSelections ?? {}).forEach(([key, selections]) => {
    const choiceSet = choiceSets.find(cs => cs.key === key || cs.id === key);
    if (!choiceSet) return;
    const idBased =
      selections instanceof Array &&
      selections.every(selection => typeof selection === 'string');

    /**
     * When the quantity of a choice is incremented it is added to the selections as a new element. But the
     * quantity of the choice is also incremented. It looks like this:
     *
     * [ { name: "Pearls", quantity: 1 } ]    <-    pearls
     * [ { name: "Pearls", quantity: 2 }, { name: "Pearls", quantity: 2 } ]    <-  2 pearls
     * [ { name: "Pearls", quantity: 3 }, { name: "Pearls", quantity: 3 }, { name: "Pearls", quantity: 3 } ]    <-  3 pearls
     *
     * This breaks the calculation of the choice price because it will do this:
     * price x quantity x quantity
     *
     * It's unclear why duplicates are added to selections but due to the risk of breaking something else when trying
     * to fix it it was decided to simply remove the duplicates here.
     */
    if (idBased) {
      //Removing duplicate IDs
      selections = [...new Set(selections)];
    } else if (Array.isArray(selections)) {
      //Removing duplicate objects
      const uniqueMap = new Map();
      selections.forEach((obj: any) => {
        uniqueMap.set(obj.id, obj);
      });
      selections = Array.from(uniqueMap.values());
    }

    const nested =
      'nestedIngredients' in choiceSet &&
      (choiceSet as NestedChoiceSet).nestedIngredients;
    let choiceObjects = selections;
    if (idBased) {
      // old-style top-level choice with a list of ids instead of objects
      choiceObjects = (selections as string[])
        .map(id => choiceSet.choices.find(c => c.id === id))
        .filter(o => !!o);
    } else {
      // For some reason, when the selections are not ID based, the quantity is not provided.
      // Need to find the quantity from the choices and add them to the selection objects.
      choiceObjects = choiceObjects.length
        ? choiceObjects.map((co: any) => {
            const foundQuantity = choiceSet.choices.find(
              choice => choice.id === co.id,
              //@ts-ignore
            )?.quantity;
            return { ...co, quantity: foundQuantity };
          })
        : selections;
    }

    // Need to add the discount items if applicable (Chatime mixins)
    if (choiceSetDiscountConfig) {
      const { choiceSetKey, choiceSetDiscountMap } = choiceSetDiscountConfig;
      if (choiceSet.name.toLowerCase() === choiceSetKey?.toLowerCase()) {
        const { plus } = choiceSetDiscountPlus(choiceSetDiscountMap || []);
        const discountItems = choiceSet.choices.filter(choice => {
          return plus.some(plu => plu === choice.plucode);
        });
        choiceObjects = [...choiceObjects, ...discountItems];
      }
    }

    if (nested) {
      // mim nested item selection
      // recursive case
      Object.entries(selections).forEach(([id, nestedSelections]) => {
        const nestedItem = choiceSet.choices.find(c => c.id === id);
        if (!nestedItem) return;
        // TODO: check nestedselections for validity
        const sums = calculateChoiceSelectionsTotals(
          nestedSelections as NestedChoiceSelections,
          (nestedItem as unknown as Item).choiceSets,
          choiceOrderingMethod,
          choiceSetDiscountConfig,
        );
        sumsTotal.moneyPrice += sums.moneyPrice + nestedItem.baseMoneyPrice;
        // TODO: MiM points spec?
        sumsTotal.pointsPrice += sums.pointsPrice + nestedItem.basePointsPrice;
        sumsTotal.pointsAward += sums.pointsAward + nestedItem.basePointsAward;
      });
    } else {
      // This was mostly copied from OLO2. There was some different code here before but it didn't calculate choice set discounts (Chatime mixins).
      let freeChoicesRemaining = choiceSet.free;
      const sortedChoices = sortChoices(choiceObjects, choiceOrderingMethod);
      const result = sumObjectProperties(
        sortedChoices.map(choice => {
          let adjustedQuantity = choice.quantity;
          if (freeChoicesRemaining && choice.quantity) {
            const adjustment = Math.min(freeChoicesRemaining, choice.quantity);
            freeChoicesRemaining -= adjustment;
            adjustedQuantity = choice.quantity - adjustment;
          }
          return {
            moneyPrice: choice.baseMoneyPrice * adjustedQuantity,
            pointsPrice: choice.basePointsPrice * adjustedQuantity,
            pointsAward: choice.basePointsAward * adjustedQuantity,
          };
        }),
      );
      sumsTotal.moneyPrice += result.moneyPrice || 0;
      sumsTotal.pointsPrice += result.pointsPrice || 0;
      sumsTotal.pointsAward += result.pointsAward || 0;
    }
  });
  return sumsTotal;
}

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import lodash from 'lodash';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES, SELECT_PROPERTIES } from '../utils/theme';
import generateMomentsInRange from '../utils/generateMomentsInRange';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
const {
  getPickupTime,
  getFutureOrderingLimit,
  getFutureOrderingLimitCatering,
  getSaleType,
  getCateringEarliestOrderTime,
} = OrderingSelectors;

const { setPickupTime, fetchOrderingProviderDetails } = OrderingOperations;

const DateSelector = ({
  themeKey,
  minuteStep,
  earliestTime,
  latestTime,
  timePadding,
}: {
  themeKey: string;
  minuteStep: number;
  earliestTime: string;
  latestTime: string;
  timePadding: number;
}) => {
  const pickupTime = useAppSelector(getPickupTime);
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState(moment(pickupTime).startOf('day').toISOString());
  const saleType = useAppSelector(getSaleType);
  const futureOrderingLimit = useAppSelector(getFutureOrderingLimit) || 0;
  const futureOrderingLimitCatering = useAppSelector(getFutureOrderingLimitCatering);
  const duration =
    saleType === SALE_TYPE.CATERING
      ? futureOrderingLimitCatering >= 1
        ? futureOrderingLimitCatering
        : 1
      : futureOrderingLimit;

  const earliestCateringOrderTime = useAppSelector(getCateringEarliestOrderTime);

  const dates = generateDateInRange(duration!, saleType, earliestCateringOrderTime);

  useEffect(() => {
    setSelectedDate(moment(pickupTime).startOf('day').toISOString());

    if (saleType === SALE_TYPE.CATERING && moment(pickupTime).isBefore(dates[0], 'day')) {
      syncDate();
    }
  }, [pickupTime]);

  const handleDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(e.target.value);
    syncDate(e.target.value);
  };

  const syncDate = (value?: HTMLSelectElement['value']) => {
    const newDate = {
      y: moment(value).year(),
      M: moment(value).month(),
      d: moment(value).date(),
    };

    if (saleType === SALE_TYPE.CATERING && moment(pickupTime).isBefore(dates[0], 'day')) {
      newDate.d = dates[0].date(); // update day
      newDate.M = dates[0].month();
      newDate.y = dates[0].year();
    }

    const earliestTimeMoment = moment(earliestTime);

    const nowWithPaddingMoment = moment().add(timePadding, 'minutes');
    const earliestSelectableMoment = lodash.orderBy(
      [earliestTimeMoment, nowWithPaddingMoment],
      lodash.identity,
      'desc',
    )[0];

    const latestSelectableMoment = moment(latestTime);
    const currentTime = generateMomentsInRange(
      earliestSelectableMoment,
      latestSelectableMoment,
      minuteStep,
    )[0];

    const newTime = {
      h: moment(saleType === SALE_TYPE.CATERING ? earliestTimeMoment : currentTime).hour(),
      m: moment(saleType === SALE_TYPE.CATERING ? earliestTimeMoment : currentTime).minute(),
      s: moment(saleType === SALE_TYPE.CATERING ? earliestTimeMoment : currentTime).second(),
    };
    //@ts-ignore
    dispatch(setPickupTime(moment({ ...newDate, ...newTime })));
    dispatch(
      fetchOrderingProviderDetails({ date: moment({ ...newDate, ...newTime }).format('l') }),
    );
    logEvent(EVENTS.CHANGE_PICKUP_TIME);
  };

  return (
    <select
      id="pickup_date"
      name="pickupdate"
      value={selectedDate}
      onChange={handleDateChange}
      style={combineStyles(
        { textAlignLast: 'center' },
        p('defaultText', TEXT_PROPERTIES),
        p('input', SELECT_PROPERTIES),
        p(themeKey, SELECT_PROPERTIES),
        { width: 170 },
      )}
    >
      {dates.map((day, index) => (
        <option key={index} value={moment(day).startOf('day').toISOString()}>
          {moment(day).format('ddd, D MMM, YY')}
        </option>
      ))}
    </select>
  );
};

const generateDateInRange = (
  duration: number,
  saleType: SALE_TYPE,
  requiredTime: moment.Moment,
) => {
  const results = [];
  for (let i = 0; i < duration + 1; i++) {
    if (saleType === SALE_TYPE.CATERING) {
      const futureDate = moment()
        .clone()
        .add(i, 'd')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }); // reset timer to compare only dates

      if (requiredTime.diff(futureDate, 'day') <= 0) {
        results.push(moment().clone().add(i, 'd'));
      }
    } else {
      results.push(moment().clone().add(i, 'd'));
    }
  }

  return results;
};

export default DateSelector;

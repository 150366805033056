import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getMember from './getMember';

export default createSelector(
  [getCurrentOrder, getMember],
  (order, member): Purchaser => {
    const { purchaser } = order;

    const effectiveName =
      purchaser.name !== null
        ? purchaser.name
        : member?.givenName != null
        ? member.givenName
        : null;

    const effectiveFamilyName =
      purchaser.familyName !== null
        ? purchaser.familyName
        : member?.surname != null
        ? member.surname
        : null;

    const effectiveMobile =
      purchaser.mobile !== null
        ? purchaser.mobile
        : member?.mobile != null
        ? member.mobile
        : null;

    const effectiveEmail =
      purchaser.email !== null
        ? purchaser.email
        : member?.email != null
        ? member.email
        : null;

    return {
      ...purchaser,
      effective: {
        name: effectiveName,
        familyName: effectiveFamilyName,
        mobile: effectiveMobile,
        email: effectiveEmail,
      },
    };
  },
);

import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import TextField from '@mui/material/TextField';

import Text from '../components/Text';
import RatingThumbs from '../components/RatingThumbs';
import { FormikValues, useFormikContext } from 'formik';
import combineStyles from '../utils/combineStyles';

const FeedbackItem = ({
  feedbackItem,
  itemIndex,
}: {
  feedbackItem: ItemFeedback;
  itemIndex: number;
}) => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();

  const { handleChange, setFieldValue, validateForm, handleBlur } =
    useFormikContext<FormikValues>();

  const itemField = `items[${itemIndex}]`;

  const setIsGoodCallback = useCallback((newValue: boolean) => {
    setFieldValue(`${itemField}.isGood`, newValue);
  }, []);

  useEffect(() => {
    validateForm();
  }, []);

  return (
    <div
      style={{
        marginBottom: 32,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '10 0',
        }}
      >
        <Text
          style={combineStyles(
            p('defaultText', TEXT_PROPERTIES),
            p('subFeedback', TEXT_PROPERTIES),
          )}
        >
          {feedbackItem.itemName}
        </Text>

        <RatingThumbs isGood={feedbackItem.isGood} setIsGood={setIsGoodCallback} />
      </div>

      {feedbackItem.isGood != null && (
        <div
          style={{
            marginTop: '0.75em',
          }}
        >
          <TextField
            //placeholderTextColor={p('offerPickerPlaceholderText', ['color']).color}
            multiline
            rows={3}
            //style={[{ minHeight: 125 }, p('defaultText', TEXT_PROPERTIES)]}
            //activeOutlineColor={p('activeOutline', ['color']).color}
            //outlineColor={p('outline', ['borderColor']).color}
            label={t('form.placeholder.feedback.items')}
            onChange={handleChange(`${itemField}.additionalDetails`)}
            onBlur={handleBlur(`${itemField}.additionalDetails`)}
            value={feedbackItem.additionalDetails}
            style={{
              width: '100%',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FeedbackItem;

const styles: Styles = {};

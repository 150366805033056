import { useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import { useAppSelector } from '../app/hooks';

const libraries: any = ['places'];

const MapWrapper = ({ children }: { children: React.ReactNode }) => {
  const googleMapsApiKey = useAppSelector(state => state.config.googleMapsApiKey);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey!,
    libraries,
  });
  return <div>{isLoaded && children}</div>;
};

export default MapWrapper;

import { createSelector } from 'reselect';
import lodash from 'lodash';

import enhanceItem from '../utils/ordering/enhanceItem';

import getMenu, { $getMenu } from './getMenu';

const get = (menu: Menu | undefined): Items | undefined => {
  if (!menu || !menu.items) {
    return undefined;
  }

  // Use Object.entries to iterate over the menu items
  const enhancedItems: Items = {};
  for (const [key, item] of Object.entries(menu.items)) {
    enhancedItems[key] = enhanceItem(item, menu);
  }

  return enhancedItems;
};

export default createSelector([getMenu], get);

export const $getItems = createSelector([$getMenu], get);

export default function choiceSelectionsFromBase(
  choiceSets: ChoiceSet[],
  keyProperty: 'key' | 'id' = 'key',
  stockBalanceDataMap?: SDict<StockBalanceData>,
): ChoiceSelections {
  const result: ChoiceSelections = {};

  const preSelect = (c: Choice): string[] => {
    return stockBalanceDataMap && c.plucode in stockBalanceDataMap
      ? (stockBalanceDataMap[c.plucode].cartAdjustedBalance || 0) >=
        c.baseQuantity
        ? Array(c.baseQuantity || 0).fill(c.id)
        : []
      : Array(c.baseQuantity || 0).fill(c.id);
  };
  choiceSets.forEach(choiceSet => {
    let selections: string[] = [];
    choiceSet.choices.forEach(choice => {
      selections = [...selections, ...preSelect(choice)];
    });

    result[choiceSet[keyProperty]] = selections;
  });

  return result;
}

import React from 'react';
import GiftCardForm from '../components/GiftCardForm';

export const ADD_LOYALTY_WALLET_ROUTE = '/add-loyalty-wallet';

const AddLoyaltyWalletScreen: React.FC = () => {
  return (
    <div>
      <GiftCardForm showWallet />
    </div>
  );
};

export default AddLoyaltyWalletScreen;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../app/hooks';
import combineStyles from '../utils/combineStyles';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text'; // TODO: rework this to use Text component
import { SIGN_IN_MODAL_ID } from './SignInModal';
import { REGISTER_MODAL_ID } from './RegisterModal';
import { REVIEW_ORDER_SCREEN_ROUTE } from '../screens/ReviewOrderScreen';
import history from '../history';

export const JOIN_LOYALTY_MODAL = 'JOIN_LOYALTY_MODAL';

const JoinLoyaltyModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <Modal
      title={t('joinLoyaltyProgram')}
      desktopMaxWidth={350}
      desktopMinWidth={350}
      desktopContainerStyle={styles.modalDesktopContainer}
      clickBackgroundToClose
    >
      <Text
        block
        style={combineStyles(styles.message, {
          marginTop: 15,
        })}
        value={t('joinLoyaltyProgramMessage')}
        themeKey="messageModalMessage"
      />

      <StandardButton
        label={t('button.joinNow')}
        onClick={() => dispatch(setCurrentModal(REGISTER_MODAL_ID))}
        containerStyle={styles.okButton}
        themeKey="messageModalButton"
      />
      <StandardButton
        label={t('button.signIn')}
        onClick={() => dispatch(setCurrentModal(SIGN_IN_MODAL_ID))}
        containerStyle={{
          marginTop: 10,
          padding: 5,
        }}
        themeKey="messageModalBorderButton"
      />
      <StandardButton
        label={t('button.continueAsGuest')}
        onClick={() => {
          dispatch(setCurrentModal(null));
          history.push(REVIEW_ORDER_SCREEN_ROUTE);
        }}
        themeKey="transparentButton"
      />
    </Modal>
  );
};

export default JoinLoyaltyModal;

const styles: Styles = {
  modalDesktopContainer: {
    padding: '45px 40px 10px 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  message: {
    textAlign: 'center',
  },

  okButton: {
    marginTop: 40,
    padding: 10,
  },
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearBuffer } from '../../actions/buffer';
import lodash from 'lodash';

const initialState = null as Offer[] | null;

const mergeOffers = (previousOffers: any = [], newOffers: Offer[] = []) =>
  lodash.unionBy(newOffers, previousOffers, 'id');

const slice = createSlice({
  name: '$bufferOffers',
  initialState,
  reducers: {
    setBufferOffers(state, action: PayloadAction<Offer[] | null>) {
      return action.payload;
    },
    addBufferOffers: (state, action: PayloadAction<Offer[]>) => {
      return mergeOffers(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(clearBuffer, (state, action) => {
      return initialState;
    });
  },
});

export const { setBufferOffers, addBufferOffers } = slice.actions;
export default slice.reducer;

import React from 'react';
import Modal from '../components/Modal';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { setCurrentModal } from '../thunks/setCurrentModal';
import SaleTypeFilters from '../components/SaleTypeFilters';
import DateFilters from '../components/DateFilters';
export const FILTERS_MODAL_ID = 'FILTERS_MODAL_ID';

const FiltersModal = () => {
  const { t } = useTranslation();
  const deviceType = useAppSelector(state => state.deviceType);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const dispatch = useAppDispatch();

  if (deviceType === 1) {
    return null;
  }
  return (
    <Modal
      clickBackgroundToClose
      closeButtonLabel={t('button.modalClose.dismiss')}
      // desktopModalType={DESKTOP_MODAL_TYPES.CALLOUT}
      desktopMaxWidth={200}
      desktopMinWidth={200}
      desktopContainerStyle={styles.modalDesktopContainer}
      mobileContainerStyle={styles.modalMobileContainer}
      hideFloatingHeader
    >
      {deviceTypeMobile && (
        <h1
          style={{ paddingLeft: 20, cursor: 'pointer' }}
          onClick={() => dispatch(setCurrentModal(null))}
        >
          &times;
        </h1>
      )}
      <div style={{ paddingLeft: 40, paddingRight: 40 }}>
        <SaleTypeFilters />
        <DateFilters />
      </div>
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    borderRadius: 2,
  },
  modalMobileContainer: {
    padding: 0,
    height: '100vh',
    // paddingTop: 30,
  },

  bodyMobile: {
    // paddingLeft: 30,
  },
  buttonMobile: {
    paddingLeft: 40,
  },
  buttonDesktop: {
    paddingLeft: 20,
    borderTop: '1px solid lightgrey',
  },

  button: {
    justifyContent: 'left',
    margin: 0,
  },
};

export default FiltersModal;

import { createSelector } from 'reselect';
import * as EmailValidator from 'email-validator';

import getPurchaser from './getPurchaser';
import getRequiredPurchaserDetails from './getRequiredPurchaserDetails';

export default createSelector(
  [getPurchaser, getRequiredPurchaserDetails],
  (purchaser, requiredPurchaserDetails): boolean => {
    if (!purchaser) {
      return false;
    }

    const required = requiredPurchaserDetails.includes('email');

    const email = purchaser.effective.email || '';

    if (!email) {
      if (required) {
        return false;
      } else {
        return true;
      }
    }

    return EmailValidator.validate(email);
  },
);

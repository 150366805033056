import { createAsyncThunk } from '@reduxjs/toolkit';
import getItems from '../selectors/getItems';
import { v4 as uuidv4 } from 'uuid';
import purchaseFromItem from '../utils/ordering/purchaseFromItem';

export const openNestedItemPurchase = createAsyncThunk(
  '$openNestedItemPurchase',
  async (
    data: {
      itemId: string;
      brandId?: string;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { itemId, brandId } = data;
      const allItems = getItems(getState() as EntireFrontendState);

      if (!allItems) {
        throw new Error('allItems is undefined');
      }

      const item = allItems[itemId];
      const purchase = purchaseFromItem(
        item,
        uuidv4(),
        undefined,
        undefined,
        brandId,
      );

      return { purchase };
    } catch (e) {
      console.error('Open purchase failed', { e });
      return rejectWithValue(e);
    }
  },
);

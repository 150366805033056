import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { validateOrder } from '../../thunks/validateOrder';
import { resetOrder } from '../../actions/currentOrder';

const initialState = false;

const slice = createSlice({
  name: '$validationInProgress',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(validateOrder.pending, (state, action) => {
        return true;
      })
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addMatcher(
        isAnyOf(validateOrder.pending, validateOrder.rejected),
        (state, action) => {
          return false;
        },
      );
  },
});

export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = true;

const slice = createSlice({
  name: '$enableMultiplePayments',
  initialState,
  reducers: {
    setEnableMultiplePayments(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setEnableMultiplePayments } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'maxGiftCardValue',
  initialState: 500 as number,
  reducers: {
    setMaxGiftCardValue(state, action: PayloadAction<number>) {
      return action.payload;
    },
  },
});

export const { setMaxGiftCardValue } = slice.actions;
export default slice.reducer;

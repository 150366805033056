import React from 'react';

import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import TouchableOpacity from '../components/TouchableOpacity';

//@ts-ignore
import UilThumbsDown from '@iconscout/react-unicons/icons/uil-thumbs-down';
//@ts-ignore
import UilThumbsUp from '@iconscout/react-unicons/icons/uil-thumbs-up';
import combineStyles from '../utils/combineStyles';
import tinycolor from 'tinycolor2';

const RatingThumbs = ({
  isGood,
  setIsGood,
  spacing = 15,
  size = 35,
  padding = 5,
}: {
  isGood: boolean | null;
  setIsGood: (isGood: boolean) => void;
  spacing?: number;
  size?: number;
  padding?: number;
}) => {
  const p = useAppSelector(getThemeLookup);

  const filled = p('ratingFilled', ['color']).color;
  const unfilled = tinycolor(p('ratingUnFilled', ['color']).color)
    .darken(10)
    .toRgbString();

  const iconSize = size - padding * 2;
  const circleStyle: React.CSSProperties = {
    borderRadius: size,
    height: size,
    width: size,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  };

  const unselected = isGood == null;

  return (
    <div style={styles.ratingWrapper}>
      <TouchableOpacity
        style={combineStyles(circleStyle, {
          backgroundColor: !unselected && isGood ? filled : unfilled,
          marginRight: spacing,
        })}
        onClick={() => {
          setIsGood(true);
        }}
      >
        <UilThumbsUp color={p('choiceChecked', ['color']).color} size={iconSize} />
      </TouchableOpacity>
      <TouchableOpacity
        style={combineStyles(circleStyle, {
          backgroundColor: !unselected && !isGood ? filled : unfilled,
        })}
        onClick={() => {
          setIsGood(false);
        }}
      >
        <UilThumbsDown color={p('choiceChecked', ['color']).color} size={iconSize} />
      </TouchableOpacity>
    </div>
  );
};

export default RatingThumbs;

const styles: Styles = {
  ratingWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
};

import React from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingOperations, OrderingConstants } from 'polygon-ordering';
import { TextField } from '@mui/material';
import { InputAdornment, MenuItem, IconButton } from '@material-ui/core';
import { RiCloseCircleFill } from 'react-icons/ri';
import { makeStyles } from '@material-ui/core';

const { getSelectedOffer, getOffers } = OrderingSelectors;
const { selectOffer } = OrderingOperations;

const CouponsDropdown = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const offers = useAppSelector(getOffers);
  const selectedOffer = useAppSelector(getSelectedOffer);
  const useStyles = makeStyles(() => ({
    root: {
      // default label color
      '& .MuiInputLabel-root': {
        color: 'gray !important',
      },
      // label color when focused
      '& .MuiInputLabel-root.Mui-focused': {
        color: 'gray !important',
      },
    },
  }));
  const muiStyles = useStyles();
  return (
    <div style={{ marginBottom: 10 }}>
      <TextField
        value={selectedOffer?.id ?? ''}
        size="small"
        label={t('applyPromoCode.coupons')}
        fullWidth
        select={true}
        onChange={e => dispatch(selectOffer({ offerId: e.target.value, userAdded: true }))}
        SelectProps={{
          renderValue: id => selectedOffer?.name,
          IconComponent:
            (!!selectedOffer &&
              (() => {
                return (
                  <IconButton
                    // I don't like this padding stuff but I had to make it line up with the promocode input (OrderOffer.tsx)
                    style={{ padding: 9 }}
                    onClick={() => dispatch(selectOffer({ offerId: null, userAdded: true }))}
                  >
                    <RiCloseCircleFill size={20} color="silver" />
                  </IconButton>
                );
              })) ||
            undefined,
        }}
        className={muiStyles.root}
      >
        {offers?.map(offer => (
          <MenuItem value={offer.id} key={offer.id}>
            {offer.name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default CouponsDropdown;

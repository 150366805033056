import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';
import { resetOrder, fetchPaymentIntent } from '../../operations';

const initialState = false;

const slice = createSlice({
  name: '$cvcCheckInProgress',
  initialState,
  reducers: {
    setCvcCheckInProgress(state, action: PayloadAction<boolean>) {
      return Boolean(action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, () => {
        return initialState;
      })
      .addMatcher(isAnyOf(fetchPaymentIntent.pending), () => true)
      .addMatcher(isAnyOf(fetchPaymentIntent.fulfilled), () => false);
  },
});

export const { setCvcCheckInProgress } = slice.actions;
export default slice.reducer;

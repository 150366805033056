import { createSelector } from 'reselect';
import moment from 'moment';

import getCurrentOrder from './getCurrentOrder';

export default createSelector([getCurrentOrder], currentOrder => {
  const { pickupTime } = currentOrder;

  return pickupTime ? moment(pickupTime).format() : undefined;
});

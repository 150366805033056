import sortChoices from './sortChoices';
import createIngredientFromChoice from './createIngredientFromChoice';

export default function createIngredientsFromChoiceSet(
  choiceSet: ChoiceSetWithQuantity,
  choiceOrderingMethod: ChoiceOrderingMethod,
): CartIngredient[] {
  let freeChoicesRemaining = choiceSet.free;
  const ingredients: CartIngredient[] = [];

  const sortedChoices = sortChoices(choiceSet.choices, choiceOrderingMethod);

  sortedChoices.forEach(choice => {
    let remainingQuantity = choice.quantity;
    let freeQuantity = 0;

    if (freeChoicesRemaining && choice.quantity) {
      freeQuantity = Math.min(freeChoicesRemaining, choice.quantity);
      freeChoicesRemaining -= freeQuantity;
      remainingQuantity = choice.quantity - freeQuantity;
    }

    if (freeQuantity) {
      ingredients.push(
        createIngredientFromChoice(
          {
            ...choice,
            quantity: freeQuantity,
            baseMoneyPrice: 0,
          },
          choiceSet.id,
        ),
      );
    }

    if (remainingQuantity) {
      ingredients.push(
        createIngredientFromChoice(
          {
            ...choice,
            quantity: remainingQuantity,
          },
          choiceSet.id,
        ),
      );
    }
  });

  return ingredients;
}

//create cart item for nested ingredients
export const createIngredientsFromChoiceSetV2 = (
  choiceSet: ChoiceSetWithQuantity | NestedChoiceSetWithQuantity,
  choiceOrderingMethod: ChoiceOrderingMethod,
): CartIngredient[] => {
  const ingredients: CartIngredient[] = [];

  let freeChoicesRemaining = choiceSet.free;

  const sortedChoices = sortChoices(choiceSet.choices, choiceOrderingMethod);

  sortedChoices.forEach(choice => {
    let remainingQuantity = choice.quantity;
    let freeQuantity = 0;

    if (freeChoicesRemaining && choice.quantity) {
      freeQuantity = Math.min(freeChoicesRemaining, choice.quantity);
      freeChoicesRemaining -= freeQuantity;
      remainingQuantity = choice.quantity - freeQuantity;
    }
    const cartItems: CartIngredient[] = [];
    if (freeQuantity) {
      cartItems.push(
        createIngredientFromChoice(
          {
            ...choice,
            quantity: freeQuantity,
            baseMoneyPrice: 0,
          },
          choiceSet.id,
        ),
      );
    }

    if (remainingQuantity) {
      cartItems.push(
        createIngredientFromChoice(
          {
            ...choice,
            quantity: remainingQuantity,
          },
          choiceSet.id,
        ),
      );
    }

    // disgusting typing patch fix here
    if (
      'choiceSets' in choice &&
      (choice as NestedChoiceWithQuantity).choiceSets &&
      cartItems.length === 1
    ) {
      let nestedChoicesets: CartIngredient[] = [];
      (choice as NestedChoiceWithQuantity).choiceSets.forEach(set => {
        nestedChoicesets = [
          ...nestedChoicesets,
          ...createIngredientsFromChoiceSetV2(set, choiceOrderingMethod),
        ];
      });
      cartItems[0].Ingredients = nestedChoicesets;
    }

    ingredients.push(...cartItems);
  });

  return ingredients;
};

import { createSelector } from 'reselect';

import getLocationBrandsWithMenuRepresentation from './getLocationBrandsWithMenuRepresentation';

const getCurrentMenuBrandId = (state: EntireFrontendState) =>
  state.ordering.currentMenuBrandId;
const getDisableAllBrandsMenuTab = (state: EntireFrontendState) =>
  state.ordering.config.disableAllBrandsMenuTab;

export default createSelector(
  [
    getCurrentMenuBrandId,
    getLocationBrandsWithMenuRepresentation,
    getDisableAllBrandsMenuTab,
  ],
  (
    currentMenuBrandId,
    locationBrandsWithMenuRepresentation,
    disableAllBrandsMenuTab,
  ) => {
    const currentBrandAvailable = locationBrandsWithMenuRepresentation.some(
      brand => brand.id === currentMenuBrandId,
    );
    const result = currentBrandAvailable ? currentMenuBrandId : null;

    if (
      result == null &&
      disableAllBrandsMenuTab &&
      locationBrandsWithMenuRepresentation.length
    ) {
      return locationBrandsWithMenuRepresentation[0].id;
    }

    return result;
  },
);

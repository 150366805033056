import React from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdArrowDropdownCircle, IoMdArrowDropupCircle } from 'react-icons/io';

import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import Banner from './Banner';
import { useAppSelector } from '../app/hooks';
import combineStyles from '../utils/combineStyles';

interface IProps {
  choiceSet: ValidatedChoiceSet;
  collapsed?: boolean;
  toggleCollapsed: (id: string) => void;
  hideCaret?: boolean;
}

const NestedChoiceSetBanner: React.FC<IProps> = ({
  choiceSet,
  collapsed,
  toggleCollapsed,
  hideCaret = false,
}) => {
  const { t } = useTranslation();
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);
  const collapsableChoiceSetsEnabled = useAppSelector(
    state => state.config.collapsableChoiceSetsEnabled,
  );

  // const hideFreeChoiceMessage = useAppSelector(state => state.config.hideFreeChoiceMessage);

  let rightLabel = '';
  let leftLabel = '';

  leftLabel = choiceSet.name;
  rightLabel = choiceSet.required || choiceSet.min ? 'REQUIRED' : 'OPTIONAL';

  const labelTextSize = { fontSize: isMobileDevice ? 12 : 14 };

  return (
    <Banner
      sticky
      LabelIconComponent={
        !hideCaret
          ? collapsableChoiceSetsEnabled
            ? !collapsed
              ? IoMdArrowDropdownCircle
              : IoMdArrowDropupCircle
            : undefined
          : undefined
      }
      leftLabel={leftLabel}
      rightLabel={rightLabel}
      leftLabelStyle={combineStyles(styles.leftLabel, labelTextSize)}
      rightLabelStyle={labelTextSize}
      containerStyle={styles.container}
      problem={!choiceSet.valid}
      onClick={collapsableChoiceSetsEnabled ? () => toggleCollapsed(choiceSet.id) : undefined}
    />
  );
};

const styles: Styles = {
  emphasized: {
    fontWeight: 900,
  },
  container: {
    zIndex: 5, // to be higher than textShadows inside sibling choice set components
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    backgroundColor: 'transparent',
    position: 'relative',
  },
  leftLabel: {
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
  },
};

export default NestedChoiceSetBanner;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'minimumPasswordLength',
  initialState: 0,
  reducers: {
    setMinimumPasswordLength(state, action: PayloadAction<number>) {
      return action.payload;
    },
  },
});

export const { setMinimumPasswordLength } = slice.actions;

export default slice.reducer;

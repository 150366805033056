import { v4 as uuidv4 } from 'uuid';
import { determineFullLink } from './utils';
import AuthHook from './utils/AuthHook';

// TODO: investigate moving class-level variables/methods to instance
// TODO: revise and standardise error handling/object
// pull in error helpers and typings from polygon-ordering

export default class RedcatApiHandler {
  // types are 'application', 'http' and 'connectivity'
  static RedcatApiException(message: string, details: any) {
    return {
      name: 'RedcatApiException',
      message,
      details: { ...details, type: details.type || 'application' },
    };
  }

  static preRequestHook: (request: RequestDetails) => void;

  static postRequestHook: (request: RequestDetails) => void;

  static badAuthTokenHook: (error: any) => any;

  static requestErrorHook: (error: any) => void;

  static signRequestHook: (params: SignRequestParams) => Promise<string>;

  static getDeviceIdHook: () => string;

  static endpointDeterminer: () => string;

  static authTokenDeterminer: () => string;

  static headerDeterminer: () => {};

  static additionalParamsDeterminer: () => {};

  static additionalBodyContentDeterminer: ({
    method,
    path,
    body,
  }: {
    method: HTTPMethods;
    path: string;
    body: {} | undefined;
  }) => {};

  static headerOverridesDeterminer: () => {};

  static fetchImplementation?: FetchImplementation;

  static determineFullLinkWithEndpoint(path: string) {
    let endpoint = '';

    if (RedcatApiHandler.endpointDeterminer) {
      endpoint = RedcatApiHandler.endpointDeterminer();
    }

    return determineFullLink(endpoint, path);
  }

  // TODO: split this function into smaller parts
  static fetch({
    method,
    path,
    body: bodyArg,
    requestId,
    headers,
    applicationErrorAllowed,
    additionalParams: additionalParamsArg = {},
    fallbackValue,
    blockErrorHook,
    loggerCallback,
  }: FetchParams): Promise<ApiResponse> {
    let body = bodyArg;

    let endpoint = '';

    if (RedcatApiHandler.endpointDeterminer) {
      endpoint = RedcatApiHandler.endpointDeterminer();
    }

    const url = `${endpoint || ''}${path.charAt(0) === '/' ? '' : '/'}${path}`;

    let baseHeaders = {};

    if (RedcatApiHandler.headerDeterminer) {
      baseHeaders = RedcatApiHandler.headerDeterminer();
    }

    let additionalParams = additionalParamsArg;

    if (RedcatApiHandler.additionalParamsDeterminer) {
      additionalParams = {
        ...additionalParams,
        ...RedcatApiHandler.additionalParamsDeterminer(),
      };
    }

    if (RedcatApiHandler.additionalBodyContentDeterminer) {
      body = {
        ...body,
        ...RedcatApiHandler.additionalBodyContentDeterminer({
          method,
          path,
          body,
        }),
      };
    }

    let overrideHeaders = {};

    if (RedcatApiHandler.headerOverridesDeterminer) {
      overrideHeaders = RedcatApiHandler.headerOverridesDeterminer();
    }

    const params: HTTPParams = {
      ...additionalParams,
      method,
      headers: { ...baseHeaders, ...headers, ...overrideHeaders },
    };

    if (body) {
      params['body'] = JSON.stringify(body);
      params.headers!['Content-type'] = 'application/json';
    }

    const request: RequestDetails = {
      url,
      params,
      requestId: requestId || uuidv4(),
    };

    return new Promise((resolve, reject) => {
      try {
        if (RedcatApiHandler.preRequestHook) {
          RedcatApiHandler.preRequestHook(request); // e.g. () => CookieManager.clearAll()
        }
      } catch (e) {
        reject(e);
      }

      return resolve(undefined);
    })
      .then(() =>
        (RedcatApiHandler.fetchImplementation || fetch)(
          url,
          params as RequestInit, // Force type definition to fit fetch expected params type
        ),
      )
      .then(response => {
        loggerCallback &&
          loggerCallback(JSON.stringify({ reponse: response.json(), status: response.status }));

        if (!response.ok) {
          throw RedcatApiHandler.RedcatApiException('communication error', {
            request,
            response,
            type: 'http',
          });
        }

        if (response.status === 403) {
          const authHook = AuthHook.get('SIGN_OUT');
          if (authHook) {
            authHook();
          }
        }

        const contentType = response.headers.get('content-type');

        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json().then(json => {
            if (!applicationErrorAllowed && !json.success) {
              throw RedcatApiHandler.RedcatApiException(json.error || 'application error', {
                request,
                json,
              });
            }

            return json;
          });
        }

        if (contentType && contentType.indexOf('text/') !== -1) {
          return response.text();
        }

        return response.blob();
      })
      .then(value => {
        if (RedcatApiHandler.postRequestHook) {
          RedcatApiHandler.postRequestHook(request);
        }

        return value;
      })
      .catch(e => {
        let error = { ...e };

        // NOTE: exception properties are non-enumerable
        // spreading a normal js exception (not a RedcatApiException object) results in `{}`
        if (e.name !== 'RedcatApiException') {
          error = RedcatApiHandler.RedcatApiException(e.message || 'communication error', {
            stack: e.stack,
            type: 'connectivity',
            request,
          });
        }

        if (
          error.details.type === 'http' &&
          error.details.response.status === 403 && // NOTE: should we include other codes?
          RedcatApiHandler.badAuthTokenHook
        ) {
          RedcatApiHandler.badAuthTokenHook(error);
        }

        if (RedcatApiHandler.requestErrorHook && !blockErrorHook && fallbackValue === undefined) {
          RedcatApiHandler.requestErrorHook(error);
        }

        if (RedcatApiHandler.postRequestHook) {
          RedcatApiHandler.postRequestHook(request);
        }

        if (fallbackValue) {
          return fallbackValue;
        }

        throw error;
      });

    // TODO: use `finally` again when the following bug has been fixed:
    // Promise with "chained" finally gobbles return value · Issue #17972
    // https://github.com/facebook/react-native/issues/17972
    // .finally(() => {
    //   if (RedcatApiHandler.postRequestHook) {
    //     RedcatApiHandler.postRequestHook(request);
    //   }
    // });
  }

  static authorisedFetch({
    method,
    path,
    body,
    requestId,
    applicationErrorAllowed,
    additionalParams,
    fallbackValue,
    blockErrorHook,
  }: FetchParams): Promise<ApiResponse> {
    // console.info('authorised fetch started');

    const authToken = RedcatApiHandler.authTokenDeterminer();
    const headers: HTTPHeaders = { 'X-Redcat-Authtoken': authToken };
    return RedcatApiHandler.fetch({
      method,
      path,
      body,
      requestId,
      headers,
      applicationErrorAllowed,
      additionalParams,
      fallbackValue,
      blockErrorHook,
    });
  }

  // TODO: implement
  static async trustedFetch({
    method,
    path,
    body,
    requestId,
    applicationErrorAllowed,
    additionalParams,
    fallbackValue,
    blockErrorHook,
    loggerCallback,
  }: FetchParams): Promise<ApiResponse> {
    // console.info('trusted fetch started');

    const timeStamp = new Date().getTime();
    const headers: HTTPHeaders = {
      'X-Redcat-TimeStamp': timeStamp,
    };

    if (RedcatApiHandler.getDeviceIdHook) {
      headers['X-Redcat-DeviceID'] = RedcatApiHandler.getDeviceIdHook();
    }

    if (RedcatApiHandler.signRequestHook) {
      headers['X-Redcat-RequestSignature'] = await RedcatApiHandler.signRequestHook({
        body,
        method,
        timeStamp,
        path,
      });
    }

    return await RedcatApiHandler.fetch({
      method,
      path,
      body,
      requestId,
      headers,
      applicationErrorAllowed,
      additionalParams,
      fallbackValue,
      blockErrorHook,
    });
  }
}

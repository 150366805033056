import forge, { pki } from "node-forge";

import { deterministicSerialiser, sha256 } from "./utils";

(forge as any).options.usePureJavaScript = true;

// TODO: review if this needs to be class and/or using class variables

export default class RSA {
  static RSAException(message: string, details: {}) {
    return {
      name: "RSAException",
      message,
      details,
    };
  }

  static preGenerateKeyPairHook: (message: string) => void;

  static getKeyPair: () => KeyPair;

  static compareKeyPairs(remotePublicKey: string): boolean {
    try {
      let keyPair;
      if (RSA.getKeyPair) {
        keyPair = RSA.getKeyPair();
      } else {
        throw RSA.RSAException("RSA getKeyPair property missing", {});
      }
      return remotePublicKey === keyPair.publicKey && typeof keyPair.privateKey !== "undefined";
    } catch (e) {
      throw RSA.RSAException("An error occured while comparing key pairs", {
        // @ts-ignore
        message: e.message,
      });
    }
  }

  static generateKeyPair(): KeyPair {
    try {
      if (RSA.preGenerateKeyPairHook) {
        RSA.preGenerateKeyPairHook("Generating RSA keyPair");
      }
      const keyPair = forge.pki.rsa.generateKeyPair({ bits: 512, e: 0x10001 });
      const privateKey = forge.pki.privateKeyToPem(keyPair.privateKey);
      const publicKey = forge.pki.publicKeyToPem(keyPair.publicKey);
      return { privateKey, publicKey };
    } catch (e) {
      throw RSA.RSAException("An error occured while generating the key pair", {
        // @ts-ignore
        message: e.message,
      });
    }
  }

  static signRequest({ body, method, timeStamp, path }: SignRequestParams): string {
    try {
      const hash = sha256(
        deterministicSerialiser({
          body,
          method,
          timeStamp,
          path,
        }),
      );

      let keyPair;
      if (RSA.getKeyPair) {
        keyPair = RSA.getKeyPair();
      } else {
        throw RSA.RSAException("RSA getKeyPair property missing", {});
      }

      const privateKey = forge.pki.privateKeyFromPem(keyPair.privateKey);
      const md = forge.md.sha256.create();
      md.update(hash, "utf8");
      return forge.util.encode64((privateKey as pki.rsa.PrivateKey).sign(md));
    } catch (e) {
      throw RSA.RSAException("An error occured while signing the request", {
        // @ts-ignore
        message: e.message,
      });
    }
  }
}

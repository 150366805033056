import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';
import { resetOrder } from '../../actions/currentOrder';
import { stagePurchaseFromItem } from '../../operations';
import { stageOpenPurchase } from '../../operations';
import addPurchase from '../../utils/ordering/addPurchase';
import { replaceOrAppend } from '../../utils/misc';

const initialState: _Purchase[] = [];
const slice = createSlice({
  name: '$stagedPurchases',
  initialState,
  reducers: {
    setStagedPurchases(state, action: PayloadAction<_Purchase[]>) {
      return action.payload || initialState;
    },
    removeStagedPurchase(state, action: PayloadAction<string>) {
      return state.filter(purchase => purchase.id !== action.payload);
    },
    addStagedPurchase(state, action: PayloadAction<_Purchase>) {
      return addPurchase(state, action.payload);
    },
    addStagedPurchases(state, action: PayloadAction<_Purchase[]>) {
      let newState = [...state];

      action.payload.forEach(purchase => {
        newState = addPurchase(newState, purchase);
      });
      return newState;
    },
    adjustStagedPurchaseQuantity(
      state,
      action: PayloadAction<{
        purchaseId: string;
        adjustment?: number;
        newQuantity?: number;
      }>,
    ) {
      const { purchaseId, adjustment = 0, newQuantity } = action.payload;

      return replaceOrAppend(
        state,
        undefined,
        el => el.id === purchaseId,
        prev => ({
          ...prev[0],
          quantity:
            newQuantity != null ? newQuantity : prev[0].quantity + adjustment,
        }),
      );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addCase(applyBufferData, (state, action) => {
        const { stagedPurchases } = action.payload;
        return stagedPurchases === undefined ? state : stagedPurchases;
      });
  },
});

export const {
  setStagedPurchases,
  // removeStagedPurchase,
  addStagedPurchases,
  addStagedPurchase,
  adjustStagedPurchaseQuantity,
} = slice.actions;
export default slice.reducer;

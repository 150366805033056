import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import delay from '../utils/delay';
import { Elements } from '@stripe/react-stripe-js';
import getPaymentGatewayPublicKey from '../selectors/getPaymentGatewayPublicKey';
import { setCreditCardComplete } from '../slices/creditCardComplete';
import { getSelectedPaymentMethods } from '../libs/polygon-ordering/src/selectors';

const StripeProvider = ({
  shouldHaveMerchantAccount = false,
  children,
}: {
  shouldHaveMerchantAccount?: boolean;
  children: React.ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const paymentGatewayPublicKey = useAppSelector(getPaymentGatewayPublicKey);
  const merchantAccount = useAppSelector(state => state.config.stripeMerchantAccount);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);

  useEffect(() => {
    dispatch(setCreditCardComplete(false));
    readyStripe();
  }, [paymentGatewayPublicKey, selectedPaymentMethods]);

  const readyStripe = () => {
    if (!paymentGatewayPublicKey) {
      return;
    }

    setStripe(null);

    // NOTE: here we use the main stripe account because cc payment details can only be saved on the main account, they are then funneled in the backend to the merchant connect accounts
    // ADDITIONAL NOTE: we still need to specify the connected account so we can revalidate the cvc on a saved card.
    delay(1000)
      .then(() =>
        loadStripe(
          paymentGatewayPublicKey,
          shouldHaveMerchantAccount ? { stripeAccount: merchantAccount! } : undefined,
        ),
      )
      .then(stripe => setStripe(stripe));
  };

  return (
    <Elements key={paymentGatewayPublicKey} stripe={stripe}>
      {children}
    </Elements>
  );
};

export default StripeProvider;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderingOperations, OrderingConstants, OrderingSelectors } from 'polygon-ordering';

import debug from '../utils/debug';
import history from '../history';

import { setCurrentModal } from './setCurrentModal';
import i18next from 'i18next';
import { MESSAGE_MODAL_ID } from '../modals/MessageModal';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { RootState } from 'store';
import getConfig from '../selectors/getConfig';
import { MENU_SCREEN_ROUTE, PICK_BRAND_SCREEN_ROUTE } from '../hooks/useRoutes';

const { updateKeyOrderProperty } = OrderingOperations;
const { getLocationBrandsWithMenuRepresentation } = OrderingSelectors;
const { SALE_TYPES } = OrderingConstants;

export const confirmLocationEstimate = createAsyncThunk(
  'confirmLocationEstimate',
  async (
    data: {
      locationId: string;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { locationId } = data;
    debug('applying buffered delivery estimate');

    try {
      await dispatch(
        updateKeyOrderProperty({
          locationId,
          confirmLocationDeliveryEstimate: true,
        }),
      );
      const modalParams = (getState() as RootState).modalParams;

      const popUpTitle = i18next.t(`preSaleMessage.title.${SALE_TYPES.DELIVERY}`);
      const popUpMessage = i18next.t(`preSaleMessage.message.${SALE_TYPES.DELIVERY}`);

      const representedBrands = getLocationBrandsWithMenuRepresentation(getState() as RootState);

      const multipleBrands = representedBrands.length > 1;

      const deviceTypeMobile = getDeviceTypeMobile(getState() as RootState);
      const enableBrandSelection = getConfig(getState() as RootState).enableBrandSelection;
      const showBrandPicker = deviceTypeMobile && enableBrandSelection && multipleBrands;

      modalParams?.allowRedirect !== false &&
        history.replace(showBrandPicker ? PICK_BRAND_SCREEN_ROUTE : MENU_SCREEN_ROUTE);

      dispatch(setCurrentModal(null));

      if (popUpTitle !== '' && popUpMessage !== '') {
        dispatch(
          setCurrentModal({
            modalId: MESSAGE_MODAL_ID,
            params: {
              title: popUpTitle,
              message: popUpMessage,
            },
            clearReturnModalDetails: true,
          }),
        );
      }
    } catch (e) {
      console.error('Confirm location estimate thunk failed', e);
      return rejectWithValue(e);
    }
  },
);

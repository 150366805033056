import { createAsyncThunk } from '@reduxjs/toolkit';
import { setThemeOverrides } from '../slices/config/themeOverrides';
import { fetchThemeOverrides } from './fetchThemeOverrides';

export const applyServerThemeOverrides = createAsyncThunk(
  'applyServerThemeOverrides',
  async (_data: undefined, { dispatch, rejectWithValue }) => {
    try {
      const themeOverrides = await dispatch(fetchThemeOverrides()).unwrap();
      dispatch(setThemeOverrides(themeOverrides));

      const asJSON = JSON.stringify(themeOverrides, null, 2);
      localStorage.setItem('orderingDevThemeOverrides', asJSON);
    } catch (e) {
      console.error('Apply server theme overrides failed:', e);
      return rejectWithValue(e);
    }
  },
);

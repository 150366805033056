import { createSlice, PayloadAction } from '@reduxjs/toolkit';

//TODO: figure out the default value
const slice = createSlice({
  name: 'enableSelfDelete',
  initialState: true,
  reducers: {
    setEnableSelfDelete(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setEnableSelfDelete } = slice.actions;

export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: PAYMENT_METHOD[] = [];

const slice = createSlice({
  name: '$enablePaymentMethods',
  initialState,
  reducers: {
    setEnabledPaymentMethods(state, action: PayloadAction<PAYMENT_METHOD[]>) {
      return action.payload || [];
    },
  },
});

export const { setEnabledPaymentMethods } = slice.actions;
export default slice.reducer;

import React from 'react';
import combineStyles from '../utils/combineStyles';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';

import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { TEXT_PROPERTIES } from '../utils/theme';

const TextButton = ({
  onClick,
  value,
  textStyle,
}: {
  onClick: () => void;
  value: string;
  textStyle: React.CSSProperties;
}) => {
  const p = useAppSelector(getThemeLookup);

  return (
    <TouchableOpacity onClick={onClick}>
      <Text value={value} style={combineStyles([p('textButton', TEXT_PROPERTIES), textStyle])} />
    </TouchableOpacity>
  );
};

export default TextButton;

const styles: Styles = {};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setBrands } from '../operations';
import Api, { FetchParams } from '../utils/Api';
import processBrand from '../utils/processors/processBrand';

export const fetchBrands = createAsyncThunk(
  '$fetchBrands',
  async (_data: undefined, { dispatch, rejectWithValue }) => {
    try {
      const params: FetchParams = {
        path: '/api/v1/brands',
        method: 'GET',
      };

      const response = await Api.fetch(params);

      const rawOrders: RawBrand[] = response.data;
      const processedBrands = rawOrders
        .map(processBrand)
        .filter(brand => brand.active);

      dispatch(setBrands(processedBrands));
    } catch (e) {
      console.error('Fetch brands failed', { e });
      return rejectWithValue(e);
    }
  },
);

import Text from './Text';
import React from 'react';
import { OrderingHooks, OrderingSelectors } from 'polygon-ordering';
import { useAppSelector } from '../app/hooks';
import OrderDetailsPurchasesChoice from './OrderDetailsPurchasesChoice';

const { getOrderTotals } = OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;

// flatten the choices out of the choicesets in a nested item
const flattenChoiceSets = (
  choiceSets: ChoiceSetWithQuantity[],
): ChoiceWithQuantity[] | undefined => {
  return choiceSets?.reduce((flattened, choiceSet) => {
    flattened.push(...choiceSet.choices.filter(c => !!c.quantity));

    return flattened;
  }, [] as ChoiceWithQuantity[]);
};

const OrderDetailsPurchases = ({ stagedPurchase }: { stagedPurchase: PurchaseWithTotals }) => {
  const price = useFormattedCurrency({ cents: stagedPurchase.baseMoneyPrice });
  const orderTotals = useAppSelector(getOrderTotals);

  return (
    <>
      <div style={styles.items}>
        <Text
          themeKey="orderDetailsCart"
          style={styles.description}
          value={stagedPurchase.item.name}
        />

        <Text
          themeKey="orderDetailsCart"
          style={styles.qty}
          value={String(stagedPurchase.quantity)}
        />
        <Text
          themeKey="orderDetailsCart"
          style={styles.points}
          value={!!orderTotals?.pointsAward ? String(stagedPurchase.pointsAward) : ''}
        />
        <Text themeKey="orderDetailsCart" style={styles.price} value={price} />
      </div>
      {/* This was stolen from the similar price breakdown in StagedPurchase.tsx */}
      {stagedPurchase?.choicesWithQuantity?.map((choice, index) => (
        <React.Fragment key={index}>
          <OrderDetailsPurchasesChoice stagedPurchaseChoice={choice} />
          {'choiceSets' in choice &&
            flattenChoiceSets((choice as NestedChoiceWithQuantity).choiceSets)?.map(
              (choice: any, index: number) => (
                <OrderDetailsPurchasesChoice key={index} stagedPurchaseChoice={choice} nested />
              ),
            )}
        </React.Fragment>
      ))}
    </>
  );
};

export default OrderDetailsPurchases;

const styles = {
  description: {
    width: '50%',
    fontWeight: 'bold',
  },
  qty: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
  points: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
  },
  price: {
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  items: {
    display: 'flex',
    margin: '5px 0 5px 0',
  },
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PurchaseGiftCardForSelf from '../components/PurchaseGiftCardForSelf';
import PurchaseGiftCardForOthers from '../components/PurchaseGiftCardForOthers';
import BadgeOptions from '../components/BadgeOptions';
import { OrderingOperations } from 'polygon-ordering';
import { useAppDispatch } from '../app/hooks';

import { styled, createTheme, ThemeProvider } from '@mui/system';
export const PURCHASE_GIFT_CARD_ROUTE = '/purchase-gift-card';

const BadgeOptionsWrapper = styled('div')(({ theme }) => {
  return {
    '.row': {
      margin: '10px 0 1.5em',
    },
  };
});

const { fetchGiftcardSurcharge } = OrderingOperations;

const PurchaseGiftCardScreen: React.FC = () => {
  const { t } = useTranslation();
  const [gift, setGift] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchGiftcardSurcharge());
  }, []);

  return (
    <div>
      <BadgeOptionsWrapper>
        <BadgeOptions
          active={gift}
          setActive={status => setGift(status)}
          activeKey="buyAsGift"
          inactiveKey="buyForMyself"
        />
      </BadgeOptionsWrapper>

      {gift ? <PurchaseGiftCardForOthers /> : <PurchaseGiftCardForSelf />}
    </div>
  );
};

export default PurchaseGiftCardScreen;

// NOTE: in order to be usable inside a .d.ts file, this enum
// must not be exported/imported explicitly. This makes it kind of global.
// It must also be a const enum, otherwise it will fail (only a runtime,
// the compiler will say everything is fine)

// NOTE: Max Craigie you are incorrectly using .d.ts files

import { DUMMY } from './dummy';

export enum OFFER_TYPE {
  // constant discount
  AMOUNT_OFF_ORDER = 2,
  // percentage discount
  PERCENTAGE_OFF_ORDER = 1,
  // override the price of a PLU or ingredient
  PRICE_OVERRIDE_ITEM_OR_INGREDIENT = 112,
  // constant discount off item?
  AMOUNT_OFF_ITEM = 6,
  // percentage discount off item
  PERCENTAGE_OFF_ITEM = 81,
  // percentage discount off item that has to exist
  //  if this coupon is added, a plu is added alongside
  // the added PLU should be flagged somewhere to be ignored in other coupon calculations (TODO)
  PERCENTAGE_OFF_ITEM_POS_ENFORCED = 13,
  // percentage discount off item that has to exist,
  //  including it's ingredients?
  PERCENTAGE_OFF_ITEM_AND_INGREDIENTS = 5,
  //BOGO COUPONS (buy one, get one)
  // amount discount off second item
  //  if multiple selected?
  AMOUNT_OFF_BOGO = 113,
  // percentage discount off second item
  //  if multiple selected?
  PERCENTAGE_OFF_BOGO = 114,
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'apiHandlerReady',
  initialState: false,
  reducers: {
    setApiHandlerReady(state, action: PayloadAction<boolean>) {
      return true;
    },
  },
});

export const { setApiHandlerReady } = slice.actions;

export default slice.reducer;

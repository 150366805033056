import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function HistoryRefSaver() {
  let history = useHistory();

  useEffect(() => {
    window._history = history;
  });

  return null;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { FAILURE_REASON } from '../constants';
import { setPromoCode, updateSelectedPaymentMethods } from '../operations';
import { getPromoCode } from '../selectors';
import { fetchOffers } from './fetchOffers';
import { selectOffer } from './selectOffer';

export const applyPromoCode = createAsyncThunk(
  '$applyPromoCode',
  async (
    data: {
      promocode?: string;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    let reason: FailureReason;

    try {
      let { promocode: promoCodeSafe } = data;

      if (!promoCodeSafe) {
        promoCodeSafe = getPromoCode(getState() as EntireFrontendState);

        if (!promoCodeSafe) {
          reason = FAILURE_REASON.MISSING_PARAMETER;
          throw new Error('no promo code');
        }
      }

      dispatch(setPromoCode(null));

      const { offers } = await dispatch(
        fetchOffers({ promoCode: promoCodeSafe, bufferMode: true }),
      ).unwrap();

      const offer = offers[0];

      await dispatch(
        selectOffer({
          offerId: offer.id,
          userAdded: true, // ??? TODO: test this
        }),
      ).unwrap();

      await dispatch(updateSelectedPaymentMethods({ giftCardValidated: true }));
    } catch (e) {
      console.warn('Apply promo code failed', { e });
      return rejectWithValue({ e, reason });
    }
  },
);

export type FailureReason =
  | undefined
  | FAILURE_REASON.FETCH_FAILED
  | FAILURE_REASON.MISSING_PARAMETER
  | FAILURE_REASON.SELECT_OFFER_FAILED;

import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import setLocalValue from '../utils/setLocalValue';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import { IN_DEVELOPMENT } from '../constants';
import redcatApiFetch from '../api';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import FormTextField from '../components/FormTextField';
import { SIGN_IN_MODAL_ID } from './SignInModal';
import { REGISTER_MODAL_ID } from './RegisterModal';
import ScreenLink from '../components/ScreenLink';

export const RESET_PASSWORD_MODAL_ID = 'RESET_PASSWORD_MODAL_ID';

interface LinkTypes {
  label: string;
  actionLabel: string;
  url: string;
  openInNewTab?: boolean;
  onClick: () => void;
}

type ForgetPasswordParams = {
  email: string;
  reCaptchaToken?: string;
};

const ResetPasswordModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [resetPasswordResponse, setResetPasswordResponse] = useState<null | string>(null);
  const [reCaptchaToken, setReCaptchaToken] = useState<null | string>(null);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const enableReCaptcha = useAppSelector(state => state.config.enableReCaptcha);
  const enableReCaptchaForForgetPassword = useAppSelector(
    state => state.config.enableReCaptchaForForgetPassword,
  );
  const reCaptchaSiteKey = useAppSelector(state => state.config.reCaptchaSiteKey);

  const schemas: { email: Yup.StringSchema } = {
    email: Yup.string().email(t('form.emailValidation')).required(t('form.required')),
  };

  const validationSchema = Yup.object().shape(schemas);

  const onSubmit = (
    { email }: { email: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    const params: ForgetPasswordParams = {
      // Encode the email address
      email: encodeURIComponent(email),
    };

    if (enableReCaptcha && enableReCaptchaForForgetPassword && reCaptchaToken) {
      params.reCaptchaToken = reCaptchaToken;
    }

    setResetPasswordResponse(null);

    setSubmitting(false);
    getForgetPasswordLink(params);
  };

  const getForgetPasswordLink = (params: any) => {
    redcatApiFetch({
      path:
        enableReCaptcha && enableReCaptchaForForgetPassword && reCaptchaToken
          ? `/api/v1/profile/reset_password?Username=${params.email}&g-recaptcha-response=${params.reCaptchaToken}`
          : `/api/v1/profile/reset_password?Username=${params.email}`,
      method: 'GET',
    })
      .then(res => {
        setResetPasswordResponse(res.data);
        setReCaptchaToken(null);

        // dispatch(setCurrentModal(null));
        //@ts-ignore TODO: is this really required??
        if (IN_DEVELOPMENT && res.token) {
          //@ts-ignore
          setLocalValue('memberAuthToken', res.token);
        }

        // TODO: Redirect this Maybe?
        // dispatch(syncMember());
      })
      .catch(err => console.log(err));
  };

  return (
    <Modal
      title={t('title.modal.resetPassword')}
      desktopMaxWidth={400}
      desktopMinWidth={400}
      desktopContainerStyle={styles.modalDesktopContainer}
      mobileContainerStyle={styles.modalMobileContainer}
    >
      <div style={styles.innerContainer}>
        {!resetPasswordResponse && (
          <Formik
            validationSchema={validationSchema}
            initialValues={{ email: '' }}
            onSubmit={onSubmit}
          >
            {({
              handleSubmit,
              isSubmitting,
              values,
              submitForm,
              handleChange,
              setFieldValue,
              isValid,
            }) => {
              return (
                <Form onSubmit={handleSubmit} style={styles.form}>
                  <FormTextField
                    name="email"
                    type="email"
                    required
                    label={t(`field.signUp.email`)}
                    onChange={e => {
                      // setMfaToken(null);
                      // setFieldValue('mfaCode', '');
                      handleChange(e);
                    }}
                  />

                  {enableReCaptcha && enableReCaptchaForForgetPassword && !reCaptchaToken && (
                    <div style={styles.captchaContainer}>
                      <ReCAPTCHA
                        sitekey={reCaptchaSiteKey!}
                        onChange={token => setReCaptchaToken(token)}
                        onErrored={() => {
                          setReCaptchaToken(null);
                          enqueueErrorSnackbar(t('problemWithReCaptchaMessage'));
                        }}
                        size={deviceTypeMobile ? 'compact' : 'normal'}
                      />
                    </div>
                  )}

                  <StandardButton
                    label={t('button.submit')}
                    onClick={() => submitForm()}
                    disabled={
                      !isValid ||
                      values.email === '' ||
                      isSubmitting ||
                      (enableReCaptcha && enableReCaptchaForForgetPassword && !reCaptchaToken)
                    }
                    showSpinner={isSubmitting}
                    containerStyle={styles.loginButton}
                    themeKey="signInSubmitButton"
                  />
                </Form>
              );
            }}
          </Formik>
        )}

        {resetPasswordResponse && (
          <div style={styles.captchaContainer}>{t('resetYourPasswordMessage')}</div>
        )}

        <div style={styles.linkSection}>
          <ScreenLink
            label={t('link.signIn.prefix')}
            actionLabel={t('link.signIn.action')}
            url="#"
            onClick={() => dispatch(setCurrentModal(SIGN_IN_MODAL_ID))}
            themeKeyLabel="signInLink"
            themeKeyActionLabel="signInLinkRight"
          />
          <ScreenLink
            label={t('link.register.prefix')}
            actionLabel={t('link.register.action')}
            url="#"
            onClick={() => dispatch(setCurrentModal(REGISTER_MODAL_ID))}
            themeKeyLabel="registerLink"
            themeKeyActionLabel="registerLinkRight"
          />
        </div>
      </div>
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    padding: '70px 70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  // do this otherwise any children without a minSize will
  // be squashed if there isn't room (instead of overflow scroll)
  innerContainer: {
    minHeight: 'min-content',
  },
  linkSection: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none',
    marginTop: 10,
    textAlign: 'center',
  },
  linkLeft: {
    fontFamily: 'inherit',
  },
  linkRight: {
    fontFamily: 'inherit',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
  },
  loginButton: {
    marginTop: 20,
    height: 40,
    borderRadius: 5,
  },

  captchaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 260,
    marginTop: 15,
  },
};

export default ResetPasswordModal;

// NOTE: in order to be usable inside a .d.ts file, this enum
// must not be exported/imported explicitly. This makes it kind of global.
// It must also be a const enum, otherwise it will fail (only at runtime,
// the compiler will lie and say everything is fine)

export enum SALE_TYPE {
  DINE_IN = 100,
  TAKE_AWAY = 101,
  PICKUP = 102,
  DELIVERY = 103,
  DRIVE_THRU = 104,
  TABLE_ORDER = 105,
  WEB_ORDERING = 106,
  CATERING = 107,
}

import { createSelector } from 'reselect';
import lodash from 'lodash';

import { getConfig, getEnableDineInNameMode } from './config';

import getSaleType from './getSaleType';
import getMember from './getMember';
import { SALE_TYPE } from '../constants/saleType';

export default createSelector(
  [getConfig, getSaleType, getEnableDineInNameMode, getMember],
  (config, saleType, enableDineInNameMode, member): PurchaserDetails[] => {
    const result: PurchaserDetails[] = [...config.requiredPurchaserDetails];

    switch (saleType) {
      case SALE_TYPE.DELIVERY:
        result.push('email');
        result.push('mobile');
        result.push('name');
        result.push('familyName');
        break;
      case SALE_TYPE.PICKUP:
      case SALE_TYPE.WEB_ORDERING: // behave the same as pickup
      case SALE_TYPE.TAKE_AWAY:
      case SALE_TYPE.CATERING:
        result.push('name');
        break;
      case SALE_TYPE.DINE_IN:
        if (enableDineInNameMode) {
          result.push('name');
        }
        break;
      default:
        break;
    }

    if (!member) {
      config.requiredGuestPurchaserDetails.forEach(detail => {
        if (detail === 'mobile' && !config.enableNonMemberPhoneNumberRequired)
          return;
        result.push(detail);
      });
    }

    return lodash.uniq(result);
  },
);

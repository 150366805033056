import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: '$enableMultipleDeliveryEstimate',
  initialState,
  reducers: {
    setEnableMultipleDeliveryEstimate(state, action: PayloadAction<boolean>) {
      return Boolean(action.payload);
    },
  },
});

export const { setEnableMultipleDeliveryEstimate } = slice.actions;
export default slice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderingSelectors } from 'polygon-ordering';

import debug from '../utils/debug';
import { RootState } from 'store';

import history from '../history';
import { SIGN_UP_ROUTE } from '../screens/SignUpScreen';
import { PROFILE_ROUTE } from '../screens/ProfileScreen';

const { getMember } = OrderingSelectors;

export const adjustRegistrationCode = createAsyncThunk(
  'adjustRegistrationCode',
  async (data: { registrationCode?: string }, { getState }) => {
    const { registrationCode } = data;
    debug('adjusting registration code', { registrationCode });

    const member = getMember(getState() as RootState);
    if (Boolean(member)) {
      //Registration code is not useful if the the user is not logged in. Just navigate to the profile screen.
      history.push(PROFILE_ROUTE);
    } else {
      //Navigate to sign up screen.
      //@ts-ignore
      history.push({
        pathname: SIGN_UP_ROUTE,
        state: { registrationCode },
      });
    }
  },
);

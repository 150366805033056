import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchParams } from '../utils/Api';
import { setReducedLocations } from '../reducers/locations';
import { normaliseArray } from '../utils/misc';
import processLocationShort from '../utils/processors/processLocationShort';

export const fetchReducedLocations = createAsyncThunk(
  '$fetchReducedLocations',
  async (data: undefined, { dispatch, rejectWithValue }) => {
    try {
      const params: FetchParams = {
        path: `/api/v1/stores/reduced`,
        method: 'GET',
      };

      const response = await RedcatApiHandler.fetch(params);
      const processedLocations = response.data.map(processLocationShort);

      dispatch(
        setReducedLocations({
          locationsShort: normaliseArray(processedLocations, 'id'),
        }),
      );
    } catch (e) {
      console.warn(e);
      return rejectWithValue(e);
    }
  },
);

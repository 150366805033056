import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setCurrentModalId } from './currentModalId';

const initialState = null as string | null;

const slice = createSlice({
  name: 'previousModalId',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      setCurrentModalId,
      (state, action: PayloadAction<ModalData | null | string>) => {
        if (typeof action.payload === 'string') {
          return initialState;
        }

        const { previousModalId } = action.payload || {};
        return previousModalId || initialState;
      },
    );
  },
});

export default slice.reducer;

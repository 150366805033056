import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';

const initialState = 0;

const slice = createSlice({
  name: '$loyaltyTopUp',
  initialState,
  reducers: {
    setTopUpValue(state, action: PayloadAction<null | number>) {
      return action.payload === null ? initialState : action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      return initialState;
    });
  },
});

export const { setTopUpValue } = slice.actions;

export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'multiPaymentsAllowed',
  initialState: false,
  reducers: {
    setMultiPaymentsAllowed(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setMultiPaymentsAllowed } = slice.actions;

export default slice.reducer;

import { IN_DEVELOPMENT } from '../constants';
import getLocalValue from './getLocalValue';
import cookie from 'cookie';

export default function () {
  if (IN_DEVELOPMENT) {
    return getLocalValue('memberAuthToken') != null;
  } else {
    const parsed = cookie.parse(document.cookie);
    return Boolean(parsed['polygon_t']);
  }
}

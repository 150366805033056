import { createSelector } from 'reselect';
import lodash from 'lodash';

import { OrderingSelectors } from 'polygon-ordering';
import { RootState } from 'store';

const { getLocationsForDisplay } = OrderingSelectors;

const getLocationWhitelist = (state: RootState) => state.locationWhitelist;

export default createSelector(
  [getLocationsForDisplay, getLocationWhitelist],
  (locations, locationWhitelist) =>
    lodash.values(locations).filter(location => {
      const whitelisted = locationWhitelist.includes(String(location.id));
      return !locationWhitelist.length || whitelisted;
    }),
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { FetchParams } from '../utils/Api';
import processHistoricalOrder from '../utils/processors/processHistoricalOrder';
import { setSingleOrderDetails } from '../reducers/singleOrderDetails';

export const fetchSingleOrderDetails = createAsyncThunk(
  '$fetchSingleOrderDetails',
  async (
    data: {
      orderId: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const params: FetchParams = {
        path: `/api/v1/sale/order/${data.orderId}`,
        method: 'GET',
      };

      const response = await Api.fetch(params, 'none');
      const rawOrders: RawHistoricalOrder[] = response.data;

      const processedOrders = rawOrders.map(processHistoricalOrder);

      dispatch(setSingleOrderDetails(processedOrders[0]));
    } catch (e) {
      console.error('Fetch historical orders failed', e);
      return rejectWithValue(e);
    }
  },
);

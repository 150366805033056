import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'firebaseMessagingSenderId',
  initialState: null as null | string,
  reducers: {
    setFirebaseMessagingSenderId(state, action: PayloadAction<string | null>) {
      return action.payload || null;
    },
  },
});

export const { setFirebaseMessagingSenderId } = slice.actions;

export default slice.reducer;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { getMember } from '../libs/polygon-ordering/src/selectors';
import LoyaltyWrapper from '../components/LoyaltyWrapper';

const PrivateRoute: React.FC<{
  exact: boolean;
  path: string;
  component: React.ElementType;
  hideHero?: boolean;
}> = ({ component: Component, path, exact, hideHero }) => {
  const member = useAppSelector(getMember);

  return (
    <Route
      path={path}
      exact={exact}
      render={props =>
        member ? (
          <LoyaltyWrapper hideHero={hideHero}>
            <Component {...props} />
          </LoyaltyWrapper>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;

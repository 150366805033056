import { getCurrencySymbol } from '../selectors/config';
import { useSelector } from 'react-redux';
import { centsToDollars } from '../utils/misc';

const useFormattedCurrency = ({
  cents,
  dollars, // technically shouldn't be dollars but currency units or something
  hideZeroCents,
  hideSymbol,
}: {
  // use either but not both
  cents?: number;
  hideZeroCents?: boolean;
  hideSymbol?: boolean;
  dollars?: number;
}) => {
  const symbol = useSelector(getCurrencySymbol);
  const amount = typeof cents == 'number' ? centsToDollars(cents) : dollars;

  if (amount == undefined) {
    return '$ERR';
  }

  const result = `${amount < 0 ? '-' : ''}${
    Boolean(hideSymbol) ? '' : symbol
  }${Math.abs(amount).toFixed(2)}`;

  if (hideZeroCents && result.endsWith('.00')) {
    return result.slice(0, -3);
  }

  return result;
};

export default useFormattedCurrency;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setPaymentGatewayTokens } from '../reducers/paymentGatewayTokens';
import Api, { FetchParams, AuthenticationMethod } from '../utils/Api';
import processPaymentGatewayToken from '../utils/processors/processPaymentGatewayToken';
import { getMember } from '../selectors';

export const fetchPaymentGatewayTokens = createAsyncThunk(
  '$fetchPaymentGatewayTokens',
  async (
    data: {
      authenticationMethod?: AuthenticationMethod;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const member = getMember(getState() as EntireFrontendState);
    const { authenticationMethod = member ? 'member' : 'none' } = data;

    try {
      const params: FetchParams = {
        path: '/api/v1/profile/pgtokens',
        method: 'GET',
      };

      const response = await Api.fetch(params, authenticationMethod);

      const rawTokens: RawPaymentGatewayToken[] = response.data;

      const processedTokens = rawTokens.map(processPaymentGatewayToken);

      dispatch(setPaymentGatewayTokens(processedTokens));
    } catch (e) {
      console.warn('Fetch payment gateway tokens failed', { e });
      return rejectWithValue(e);
    }
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UPSELL_ITEM_DISPLAY_TYPE } from '../../constants';

const slice = createSlice({
  name: 'upsellItemHideGrey',
  initialState: UPSELL_ITEM_DISPLAY_TYPE.HIDE,
  reducers: {
    setUpsellItemHideGrey(undefined, action: PayloadAction<number>) {
      return action.payload;
    },
  },
});
export const { setUpsellItemHideGrey } = slice.actions;
export default slice.reducer;

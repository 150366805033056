import moment from 'moment';

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function formatDate(date, format) {
  if (!date) {
    return undefined;
  }

  return moment(date).format(format || 'YYYY-MM-DD');
}

export function stripBarcodeTerminators(string) {
  if (string) {
    return string.replace(/[#;?]/g, '');
  }

  return string;
}

export function findClosestNumber(goal, options) {
  return options.reduce((prev, curr) =>
    Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev,
  );
}

export function truncate(string, n, useWordBoundary) {
  if (string.length <= n) {
    return string;
  }

  const subString = string.substr(0, n - 1);

  return `${useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString}…`;
}

export function groupByProperty(arr, property) {
  return arr.reduce((rv, x) => {
    (rv[x[property]] = rv[x[property]] || []).push(x);
    return rv;
  }, {});
}

// creates a copy of the array before reversing
export function reverseArray(arr) {
  const copy = [...arr];
  copy.reverse();
  return copy;
}

export function centsToDollars(cents) {
  return cents !== null && cents !== undefined ? cents / 100 : undefined;
}

export function formattedDollars(dollars, symbol, hideZeroCents) {
  if (dollars == undefined) {
    return '$ERR';
  }

  const result = `${dollars < 0 ? '-' : ''}${symbol || (symbol === null ? '' : '$')}${Math.abs(
    dollars,
  ).toFixed(2)}`;

  if (hideZeroCents) {
    return result.replace(/\.?0+$/, '');
  }

  return result;
}

export function dollarsToCents(dollars) {
  return dollars !== null && dollars !== undefined ? dollars * 100 : undefined;
}

export function hashString(string) {
  let hash = 0,
    i,
    chr;
  if (string.length === 0) {
    return hash;
  }
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function groupBy(items, key) {
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );
}

export function safeString(str) {
  return (str || '').trim().toLowerCase();
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as CachedMenu | null;

const slice = createSlice({
  name: '$cachedMenu',
  initialState,
  reducers: {
    setCachedMenu(
      state,
      action: PayloadAction<{
        saleType: SALE_TYPE;
        menu?: [
          MenuNode,
          _Items,
          ChoiceSets,
          RedCat.UpsellChoiceSet[],
          string | undefined | null,
          CategoryInfo[],
        ];
        etag: string;
      }>,
    ) {
      const { saleType, menu, etag } = action.payload;
      if (state) {
        return {
          ...state,
          [saleType]: { menu, etag },
        };
      } else {
        return {
          [saleType]: { menu, etag },
        };
      }
    },
  },
});

export const { setCachedMenu } = slice.actions;
export default slice.reducer;

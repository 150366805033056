import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: EftposConfig = {
  merchantId: '',
  terminalId: '',
  pairingKey: '',
};

const slice = createSlice({
  name: '$eftpos',
  initialState,
  reducers: {
    setEftposConfig(state, action: PayloadAction<EftposConfig>) {
      return action.payload;
    },
  },
});

export const { setEftposConfig } = slice.actions;
export default slice.reducer;

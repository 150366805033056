import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as number[] | null;

const slice = createSlice({
  name: '$stockBalanceThresholds',
  initialState,
  reducers: {
    setStockBalanceThresholds(state, action: PayloadAction<any>) {
      return action.payload;
    },
  },
});

export const { setStockBalanceThresholds } = slice.actions;
export default slice.reducer;

// NOTE: in order to be usable inside a .d.ts file, this enum
// must not be exported/imported explicitly. This makes it kind of global.
// It must also be a const enum, otherwise it will fail (only at runtime,
// the compiler will lie and say everything is fine)
import { DUMMY } from './dummy';

export enum CARD_TYPE {
  LOYALTY = 'L',
  REWARDS = 'R',
  GIFT = 'G',
  VISITOR = 'V',
  ANONYMOUS = 'A',
  HYBRID = 'H',
}

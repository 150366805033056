import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { FetchParams, AuthenticationMethod } from '../utils/Api';
import processHistoricalOrder from '../utils/processors/processHistoricalOrder';
import { setHistoricalOrders } from '../reducers/historicalOrders';
import { getOrderingProvider } from '../selectors/config';
import { getMember } from '../selectors';
import { ORDERING_PROVIDER } from '../constants/orderingProvider';

export const fetchHistoricalOrders = createAsyncThunk(
  '$fetchHistoricalOrders',
  async (
    data: {
      authenticationMethod?: AuthenticationMethod;
      limit?: number;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    let { authenticationMethod = 'member' } = data;

    try {
      const orderingProvider = getOrderingProvider(
        getState() as EntireFrontendState,
      );
      const member = getMember(getState() as EntireFrontendState);

      let path =
        orderingProvider === ORDERING_PROVIDER.KIOSK
          ? `/api/v1/profile/trusted-orders/${member!.memberNumber}`
          : '/api/v1/profile/orders';

      if (data.limit) {
        path = path + `?limit=${data.limit}&sort=recid&dir=desc`;
      }

      const params: FetchParams = {
        path: path,
        method: 'GET',
      };

      if (orderingProvider === ORDERING_PROVIDER.KIOSK) {
        authenticationMethod = 'trusted';
      }

      const response = await Api.fetch(params, authenticationMethod);
      const rawOrders: RawHistoricalOrder[] = response.data;

      const processedOrders = rawOrders.map(processHistoricalOrder);
      dispatch(setHistoricalOrders(processedOrders));
    } catch (e) {
      console.error('Fetch historical orders failed', e);
      return rejectWithValue(e);
    }
  },
);

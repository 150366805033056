import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import getPaymentGatewayPublicKey from '../selectors/getPaymentGatewayPublicKey';
import { loadStripe, PaymentRequest, Stripe } from '@stripe/stripe-js';
import { useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { validate } from '../thunks/validate';
import { RiRefreshLine } from 'react-icons/ri';
import { setCheckoutInProgress } from '../slices/checkoutInProgress';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

const { updateSelectedPaymentMethod, sale, updateKeyOrderProperty } = OrderingOperations;
const {
  getSelectedPaymentMethods,
  getPaymentGatewayConfig,
  getSaleType,
  getOrderTotals,
  getMember,
  getStripeCurrency,
} = OrderingSelectors;

const PAYMENT_GATEWAY_METHODS = [PAYMENT_METHOD.APPLE_PAY, PAYMENT_METHOD.GOOGLE_PAY];

const PaymentRequestButton = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const elements = useElements();
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const paymentGatewayPublicKey = useAppSelector(getPaymentGatewayPublicKey);
  const paymentGatewayConfig = useAppSelector(getPaymentGatewayConfig);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [total, setTotal] = useState<number>();
  const saleType = useAppSelector(getSaleType);
  const orderTotals = useAppSelector(getOrderTotals);
  const payment_gateway = selectedPaymentMethods.filter(options =>
    PAYMENT_GATEWAY_METHODS.includes(options.method),
  )[0];
  const member = useAppSelector(getMember);
  const stripeCurrency = useAppSelector(getStripeCurrency);

  // make our own stripe object to override the global one because we need to use the store's merchant account for google/apple pay
  const [stripe, setStripe] = useState<Stripe | null>();
  useEffect(() => {
    loadStripe(paymentGatewayPublicKey!, {
      stripeAccount: paymentGatewayConfig?.merchantAccount,
    }).then(setStripe);
  }, []);

  useEffect(() => {
    // when a payment request hasn't been initialised yet, initialise one (again should only happen once right?)
    // this messy useeffect logic used in this file should probably be refactored
    if (!stripe || !elements || !total || paymentRequest) return;

    const pr = stripe.paymentRequest({
      // TODO: globalisation?
      country: 'AU', // TODO: fix to avoid stripe overseas fees
      currency: stripeCurrency,
      total: {
        label: 'Payment Total:',
        amount: total,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then(result => {
      if (result) {
        setPaymentRequest(pr);
      }
    });
  }, [stripe, elements, total, paymentRequest]);

  useEffect(() => {
    // when the payment methods change (shouldn't happen more than once right?) update the order total
    const method = selectedPaymentMethods.find(selectedPaymentMethod => {
      if (
        selectedPaymentMethod.method === PAYMENT_METHOD.APPLE_PAY ||
        selectedPaymentMethod.method === PAYMENT_METHOD.GOOGLE_PAY
      ) {
        return true;
      }
    });

    setTotal(method ? method.amount : 0);
  }, [selectedPaymentMethods]);

  useEffect(() => {
    // when the total changes (it shouldn't change more than once right?) update the stripe payment request
    setPaymentRequest(paymentRequest => {
      if (paymentRequest && total) {
        paymentRequest.update({
          total: {
            label: 'Payment Total:',
            amount: total,
          },
        });
      }
      return paymentRequest;
    });
  }, [total]);

  /**
   * Perform a validation when the button loads to see if delivery time needs updating.
   * Also validate when order total changes.
   */
  useEffect(() => {
    if (saleType === SALE_TYPE.DELIVERY) {
      dispatch(validate());
    }
  }, [orderTotals]);

  useEffect(() => {
    if (!paymentRequest) return;

    paymentRequest.on('cancel', () => {
      dispatch(setCheckoutInProgress(false));
    });

    paymentRequest.on('paymentmethod', async ({ paymentMethod, complete }) => {
      let subPayment = { ...payment_gateway };
      subPayment.token = paymentMethod?.id;

      //   //update payment method with token details
      // this caused some circular dependency react state issues, maybe this component needs a refactor
      dispatch(updateSelectedPaymentMethod(subPayment));

      const response = await dispatch(
        sale({
          route: 'checkout',
          authenticationMethod: member ? 'member' : 'none',
        }),
      ).unwrap();

      if (response.success) {
        complete('success');
      } else {
        complete('fail');
      }

      if (saleType === SALE_TYPE.DELIVERY) {
        await dispatch(
          updateKeyOrderProperty({
            autoApply: true,
            updateDeliveryEstimate: true,
            forceASAPDeliveryEstimate: false,
          }),
        );
        window.alert('Your estimated delivery time has been updated. Please try again.');
      }

      dispatch(setCheckoutInProgress(false));
    });
    // unsubscribe, this may not be necessary
    return () => {
      paymentRequest.off('cancel');
      paymentRequest.off('paymentmethod');
    };
  }, [paymentRequest]);

  return paymentRequest ? (
    <div style={styles.stripeButtonContainer} id="payment-request-button">
      <PaymentRequestButtonElement
        options={{ paymentRequest }}
        onClick={() => {
          dispatch(setCheckoutInProgress(true));
        }}
      />
    </div>
  ) : (
    <div style={styles.spinnerContainer}>
      <RiRefreshLine className="spinner" style={styles.loadingSpinner} />
    </div>
  );
};

export default PaymentRequestButton;

const styles: Styles = {
  stripeButtonContainer: {
    maxWidth: 400,
    flexGrow: 1,
  },

  spinnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  loadingSpinner: {
    fontSize: 24,
    marginTop: 16,
  },
};

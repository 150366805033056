import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../operations';

const initialState = null as null | number;

const slice = createSlice({
  name: '$bestEstId',
  initialState,
  reducers: {
    setBestEstId(state, action: PayloadAction<number>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, () => initialState);
  },
});

export const { setBestEstId } = slice.actions;

export default slice.reducer;

import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';
import { finaliseSale, sale } from '../../operations';

const initialState = 0;

const slice = createSlice({
  name: '$txnInProgressOrderTotal',
  initialState,
  reducers: {
    setTxnInProgressOrderTotal(state, action: PayloadAction<number>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addMatcher(
        isAnyOf(sale.fulfilled, finaliseSale.fulfilled),
        () => initialState,
      );
  },
});

export const { setTxnInProgressOrderTotal } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as PayLaterRule[] | null;

const slice = createSlice({
  name: '$payLaterRules',
  initialState,
  reducers: {
    setPayLaterRules(state, action: PayloadAction<PayLaterRule[] | null>) {
      return action.payload;
    },
  },
});

export const { setPayLaterRules } = slice.actions;
export default slice.reducer;

import { createSelector } from 'reselect';

import enhancePurchase from '../utils/ordering/enhancePurchase';
import stagedPurchasesWithTotals from '../utils/ordering/stagedPurchasesWithTotals';

import getItems, { $getItems } from './getItems';
import getUnenhancedStagedPurchases, {
  $getUnenhancedStagedPurchases,
} from './getUnenhancedStagedPurchases';
import getSelectedOffer from './getSelectedOffer';
import {
  getCouponOrderingMethod,
  getChoiceOrderingMethod,
  getChoiceSetDiscountConfig,
} from './config';
import getMenu, { $getMenu } from './getMenu';

const get = (
  orderItems: Items | undefined,
  menu: Menu | undefined,
  stagedPurchases: _Purchase[],
  couponOrderingMethod: CouponOrderingMethod | null,
  choiceOrderingMethod: ChoiceOrderingMethod | null,
  selectedOffer: Offer | undefined,
  choiceSetDiscountConfig: ChoiceSetQuantityDiscountConfig | undefined,
): PurchaseWithTotals[] => {
  if (!orderItems || !couponOrderingMethod || !choiceOrderingMethod || !menu) {
    return [];
  }

  // TODO: look at this
  const enhanced = stagedPurchases
    .map(
      purchase =>
        !purchase.unavailable &&
        enhancePurchase(
          purchase,
          menu,
          choiceOrderingMethod,
          choiceSetDiscountConfig,
        ),
    )
    .filter(purchase => Boolean(purchase)) as Purchase[];

  // we use "as" because ts isn't picking up that filter is removing undefined elements
  // https://github.com/microsoft/TypeScript/issues/16069

  return stagedPurchasesWithTotals(
    enhanced,
    choiceOrderingMethod,
    selectedOffer,
    couponOrderingMethod,
    choiceSetDiscountConfig,
  );
};

export default createSelector(
  [
    getItems,
    getMenu,
    getUnenhancedStagedPurchases,
    getCouponOrderingMethod,
    getChoiceOrderingMethod,
    getSelectedOffer,
    getChoiceSetDiscountConfig,
  ],
  get,
);

export const $getStagedPurchases = createSelector(
  [
    $getItems,
    $getMenu,
    $getUnenhancedStagedPurchases,
    getCouponOrderingMethod,
    getChoiceOrderingMethod,
    getSelectedOffer,
    getChoiceSetDiscountConfig,
  ],
  get,
);

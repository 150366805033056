import { createSelector } from 'reselect';
import lodash from 'lodash';
import { RootState } from 'store';

import THEME_DEFAULTS from '../constants/theme';

const getThemeOverrides = (state: RootState) => state.config?.themeOverrides;

export default createSelector([getThemeOverrides], overrides => {
  const safeOverrides = lodash
    .chain(overrides)
    .pickBy()
    .mapValues((property: ThemeProperties) => lodash.pickBy(property, instance => instance != null))
    .value();

  return lodash.chain(THEME_DEFAULTS).cloneDeep().merge(safeOverrides).value();
});

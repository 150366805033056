import React, { useState, useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Text from './Text';
import PaymentMethod from './PaymentMethod';
import RedcatImage from './RedcatImage';
import { useAppSelector } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import '../styles/stripe.css';
import { OrderingSelectors } from 'polygon-ordering';

const { getStripeCurrency } = OrderingSelectors;

const PaymentMethodGooglePay = () => {
  const [googlePayEnabled, setGooglePayEnabled] = useState<boolean | null>(null);
  const stripe = useStripe();
  const elements = useElements();
  const stripeCurrency = useAppSelector(getStripeCurrency);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'AU', // TODO: fix to avoid stripe overseas fees
      currency: stripeCurrency,
      total: {
        label: 'Payment Total:',
        amount: 0,
      },
    });

    pr.canMakePayment().then(result => {
      if (result) {
        if (result.googlePay) {
          setGooglePayEnabled(true);
        }
      }
    });
  }, [stripe, elements]);

  return (
    <>
      {googlePayEnabled && (
        <PaymentMethod
          method={PAYMENT_METHOD.GOOGLE_PAY}
          right={
            <div style={styles.buttonContainer}>
              <div style={styles.markContainer}>
                <RedcatImage
                  imagePath={
                    'https://assets.redcatcloud.com.au/webordering/GPay_Acceptance_Mark_800.png'
                  }
                  imageStyle={{ objectFit: 'contain' }}
                  size={56}
                />
              </div>
            </div>
          }
          subtitle={<Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>}
        ></PaymentMethod>
      )}
    </>
  );
};

const styles: Styles = {
  buttonContainer: {
    position: 'relative',
  },
  markContainer: {
    position: 'absolute',
    top: -28,
    left: 'auto',
    right: 0,
  },
};

export default PaymentMethodGooglePay;

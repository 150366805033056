import { dollarsToCents, safeDate, undefinedIfNull } from '../misc';

export default function processMember(rawMember: RawMember): Member {
  return {
    id: String(rawMember.ID),
    memberNumber: rawMember.MemberNo,
    givenName: undefinedIfNull(rawMember.GivenNames),
    surname: undefinedIfNull(rawMember.Surname),
    fullName:
      (rawMember.GivenNames ?? '') +
      (rawMember.GivenNames && rawMember.Surname ? ' ' : '') +
      (rawMember.Surname ?? ''),
    email: undefinedIfNull(rawMember.Email),
    mobile: undefinedIfNull(rawMember.Mobile),
    verified: Boolean(rawMember.VerificationDate),
    moneyBalance: dollarsToCents(rawMember.Money),
    pointsBalance: rawMember.Points,
    // pretty sure this shouldn't need to exist and is incorrect
    // rewards are supposed to be equivalent to dollars and are stored as such in tbl_members in the same field as points
    // rewardsBalance: dollarsToCents(rawMember.Points), // TODO: check this is the case
    active: Boolean(rawMember.Active),
    verifyPin: undefinedIfNull(rawMember.VerifyPIN),
    type: (rawMember.CardType || '').toUpperCase(),
    expiryDate: safeDate(rawMember.ExpiryDate) as string,
    registered: Boolean(rawMember.RegistrationDate),
    cardsetId: String(rawMember.CardsetID),
    barcode: String(rawMember.Barcode),
    dateOfBirth: undefinedIfNull(rawMember.DateOfBirth),
    phone: undefinedIfNull(rawMember.Phone),
    savedAddresses: rawMember.SavedDeliveryAddresses?.map(rawAddress => ({
      street_name: rawAddress.Address1,
      locality: rawAddress.Address2,
      lat: rawAddress.Latitude,
      lng: rawAddress.Longitude,
      formatted_address: rawAddress.Address1 + ' ' + rawAddress.Address2,
      alias: rawAddress.Alias,
      id: rawAddress.ID,
    })),
    stampcardText: rawMember.StampcardText,
    stampsEarned: rawMember.StampsEarned,
    stampcardsFilled: rawMember.StampcardsFilled,
  };
}

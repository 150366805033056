import { createSelector } from 'reselect';
import lodash from 'lodash';

import getSaleType from './getSaleType';
import getOrderTotals from './getOrderTotals';

import { getEnabledSaleTypes, getBrands } from './config';

const _getLocations = (state: EntireFrontendState) => state.ordering.locations;

export default createSelector(
  [_getLocations, getEnabledSaleTypes, getBrands, getSaleType, getOrderTotals],
  (
    locations,
    enabledSaleTypes,
    brands,
    saleType,
    orderTotals,
  ): SDict<POSLocation> | SDict<POSLocationShort> | undefined => {
    if (!locations) {
      return undefined;
    }

    return lodash.mapValues(locations as SDict<POSLocation>, location => {
      const filteredBrands = brands.filter(brand =>
        (location.brandIds || []).includes(brand.id),
      );

      const sortedBrands = lodash.sortBy(filteredBrands, 'sortOrder');

      let { averageOrderWaitTime } = location;

      function accumulateLeadTime(orderLeadTime: OrderLeadTimeThreshold) {
        if (orderLeadTime.saleType !== saleType) {
          return;
        }

        if (orderTotals == undefined) {
          return;
        }

        if (orderTotals.moneyPrice > orderLeadTime.moneyLower) {
          averageOrderWaitTime += orderLeadTime.time || 0;
        }
      }
      if (location.orderLeadTimes != null) {
        location.orderLeadTimes.forEach(accumulateLeadTime);
      }

      return {
        ...location,
        supportedSaleTypes: location.supportedSaleTypes.filter(saleType => {
          return enabledSaleTypes.includes(saleType);
        }),
        brands: sortedBrands,
        averageOrderWaitTime,
      };
    });
  },
);

import React, { useEffect } from 'react';
import { OrderingSelectors } from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import PaymentMethodMemberPoints from './PaymentMethodMemberPoints';
import PaymentMethodMemberRewards from './PaymentMethodMemberRewards';
import PaymentMethodGiftCard from './PaymentMethodGiftCard';
import PaymentMethodMemberMoney from './PaymentMethodMemberMoney';
import PaymentMethodSavedCard from './PaymentMethodSavedCard';
import PaymentMethodCreditCard from './PaymentMethodCreditCard';
import PaymentMethodPayLater from './PaymentMethodPayLater';
import PaymentMethodApplePay from './PaymentMethodApplePay';
import PaymentMethodGooglePay from './PaymentMethodGooglePay';
import { useAppSelector } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import PayWithMultiplePaymentsButton from './PayWithMultiplePaymentsButton';
import HorizontalLine from './HorizontalLine';
import StripeProvider from './StripeProvider';

const {
  getAvailablePaymentMethods,
  getEnabledPaymentMethods,
  getPaymentGatewayTokens,
  getMember,
  getSaleType,
} = OrderingSelectors;

const MULTIPLE_PAYMENT_METHODS = [
  PAYMENT_METHOD.MEMBER_POINTS,
  PAYMENT_METHOD.GIFT_CARD,
  PAYMENT_METHOD.MEMBER_MONEY,
  PAYMENT_METHOD.MEMBER_REWARDS,
];

// TODO: use this to put the unavailable methods at the bottom?
// https://stackoverflow.com/a/55996695/8706204

const PaymentMethods: React.FC = () => {
  const p = useAppSelector(getThemeLookup);
  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const member = useAppSelector(getMember);
  const savedCards: PaymentGateWayToken[] = useAppSelector(getPaymentGatewayTokens);
  const saleType = useAppSelector(getSaleType);
  const isDineIn = saleType === SALE_TYPE.TABLE_ORDER;

  // This isMultiPaymentAllowed means multi payment is enabled in PC
  const isMultiPaymentAllowed = useAppSelector(state => state.config.multiPaymentsAllowed);

  // This enableMultiPayments means whether user clicked on the button and enabled multi payment
  const enableMultiPayments = useAppSelector(state => state.enableMultiPayments);

  const availablePaymentMethods = useAppSelector(getAvailablePaymentMethods);

  const showMultiPaymentButton =
    isMultiPaymentAllowed &&
    !enableMultiPayments &&
    (MULTIPLE_PAYMENT_METHODS.filter(method => availablePaymentMethods.includes(method)).length >
      0 ||
      enabledPaymentMethods.includes(PAYMENT_METHOD.GIFT_CARD));

  return (
    <div style={styles.mainContainer}>
      {!isDineIn && (
        <>
          <PaymentMethodApplePay />
          <PaymentMethodGooglePay />
          <StripeProvider shouldHaveMerchantAccount>
            {savedCards.map(savedCard => (
              <PaymentMethodSavedCard key={savedCard.token} savedCard={savedCard} />
            ))}
          </StripeProvider>
          <PaymentMethodCreditCard />
        </>
      )}

      <PaymentMethodPayLater />

      {!isDineIn && (
        <>
          <div>
            {(member && enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_REWARDS)) ||
            enabledPaymentMethods.includes(PAYMENT_METHOD.GIFT_CARD)
              ? enableMultiPayments && (
                  <div
                    style={combineStyles(
                      styles.horizontalSeperator,
                      p('orderTotalsSeperator', ['backgroundColor']),
                    )}
                  />
                )
              : undefined}
          </div>

          <PaymentMethodGiftCard />
          <PaymentMethodMemberMoney />

          {enableMultiPayments &&
            member &&
            enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_POINTS) && (
              <div
                style={combineStyles(
                  styles.horizontalSeperator,
                  p('orderTotalsSeperator', ['backgroundColor']),
                )}
              />
            )}

          <PaymentMethodMemberPoints />
          <PaymentMethodMemberRewards />
        </>
      )}

      {showMultiPaymentButton && <PayWithMultiplePaymentsButton />}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    paddingTop: 15,
  },
  horizontalSeperator: {
    height: 1,
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
  },
};

export default PaymentMethods;

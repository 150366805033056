import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

export default createSelector([getCurrentOrder], (order): string | undefined =>
  order.locationId == null ? undefined : order.locationId,
);

export const $getLocationId = createSelector(
  [getCurrentOrder, getBuffer],
  (order, buffer): string | undefined => {
    if (buffer.locationId != null) {
      return buffer.locationId;
    }

    if (order.locationId != null) {
      return order.locationId;
    }

    return undefined;
  },
);

import { createSelector } from 'reselect';

import getBuffer from './getBuffer';
import getLocationDeliveryEstimates from './getLocationDeliveryEstimates';

import { SALE_TYPE } from '../constants/saleType';
import { getEnableMultipleDeliveryEstimate } from './config';

export default createSelector(
  [getBuffer, getLocationDeliveryEstimates, getEnableMultipleDeliveryEstimate],
  (buffer, locationDeliveryEstimates, enableMultipleDeliveryEstimate) =>
    !!locationDeliveryEstimates.length &&
    buffer.saleType === SALE_TYPE.DELIVERY &&
    enableMultipleDeliveryEstimate,
);

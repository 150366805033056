import React, { useRef, useEffect } from 'react';

const ScrollAnchor: React.FC<{
  identifier: string;
  reportRef: (id: string, ref: React.RefObject<HTMLDivElement>) => void;
}> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const { identifier, reportRef } = props;
  useEffect(() => {
    if (identifier && reportRef) {
      reportRef(identifier, ref);
    }
  }, [identifier]);

  return <div ref={ref} />;
};

export default ScrollAnchor;

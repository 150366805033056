import { createSelector } from 'reselect';
import lodash from 'lodash';

import getOrdering from './getOrdering';
import getLocations from './getLocations';

export default createSelector(
  [getOrdering, getLocations],
  (ordering, locations): EnhancedHistoricalOrder | undefined => {
    if (!ordering.singleOrderDetails) {
      return undefined;
    }

    const location = lodash.get(
      locations,
      ordering.singleOrderDetails.locationId,
    );
    const locationName = lodash.get(location, 'name');

    return {
      ...ordering.singleOrderDetails,
      locationName: locationName || 'Unknown Location',
    };
  },
);

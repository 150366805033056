import { dollarsToCents } from '../misc';

export default function (
  rawInfo: RawServiceFeeInformation,
): SurchargeInstruction {
  const isPercentage = Boolean(rawInfo.ServiceFeeIsPercentage);

  return {
    description: rawInfo.ServiceFeeDesc || 'Service Fee',
    type: rawInfo.ServiceFeeType,
    amount: isPercentage
      ? rawInfo.ServiceFee
      : dollarsToCents(rawInfo.ServiceFee),
    isPercentage,
  };
}

//         "ServiceFeeInfo": {
//             "ServiceFee": 0,
//             "ServiceFeeIsPercentage": false,
//             "ServiceFeeType": 0
//         }

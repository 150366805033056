import { createSelector } from '@reduxjs/toolkit';
import getMenu from './getMenu';
import getItems from './getItems';
import getNestedItemStockBalancesData from './getNestedItemStockBalancesData';
import getAllCategoryInfos from './getAllCategoryInfos';
import {
  getUpsellDisplayLimit,
  getUpsellUnavailableDisplayType,
} from './config';
import {
  checkTimeAvailability,
  validateUpsellItems,
  itemCategoryAvailibilityValidate,
} from '../utils/ordering';
import { UPSELL_ITEM_DISPLAY_TYPE } from '../constants';
type Availability = {
  availability?: boolean;
  start?: string | null;
  end?: string | null;
  days?: Day[];
};
export default (item: Item | undefined) =>
  createSelector(
    [
      getMenu,
      getItems,
      getNestedItemStockBalancesData(undefined),
      getUpsellDisplayLimit,
      getUpsellUnavailableDisplayType,
      getAllCategoryInfos,
    ],
    (
      menu,
      items,
      [stockBalanceDataMap],
      upsellAmountByItem = 3,
      unavailableItemDisplay,
      categories,
    ): (Item & { upsellAvailable: boolean })[] => {
      if (!item || !menu) return [];
      const { upsellChoiceSets } = item;
      if (!upsellChoiceSets || !((upsellChoiceSets || []).length > 0) || !items)
        return [];
      const {
        choiceSets,
        rootMenuNode: { subMenus },
      } = menu;

      const set = choiceSets[upsellChoiceSets[0]] as ChoiceSet & Availability;

      if (!set) return [];
      //check upsell choiceset availability
      const setAvailability = checkTimeAvailability(
        Boolean(set.availability),
        set.days,
        set.start,
        set.end,
      );
      if (!setAvailability) return [];
      //upsell item can not be item itself
      const upSellItems = set.choices
        .filter(choice => choice.id !== item.id)
        .map(choice => items[choice.id])
        .filter(
          c =>
            c &&
            itemCategoryAvailibilityValidate(c.id, subMenus, categories || []),
        );

      const validatedUpSellItems = validateUpsellItems(
        upSellItems,
        stockBalanceDataMap,
      );

      const result =
        unavailableItemDisplay === UPSELL_ITEM_DISPLAY_TYPE.GREYOUT
          ? validatedUpSellItems
          : validatedUpSellItems?.filter(item => item.upsellAvailable);

      return (result || []).slice(0, upsellAmountByItem);
    },
  );

import { createSelector } from 'reselect';

import getEnabledPaymentMethods from './getEnabledPaymentMethods';
import getOutstandingAmountByPaymentMethod from './getOutstandingAmountByPaymentMethod';
import getStagedPurchaseCount from './getStagedPurchaseCount';

import { PAYMENT_METHOD_UNAVAILABLE_REASON } from '../constants';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector(
  [
    getEnabledPaymentMethods,
    getOutstandingAmountByPaymentMethod,
    getStagedPurchaseCount,
  ],
  (
    enabledPaymentMethods,
    outstandingByPaymentMethod,
    stagedPurchaseCount,
  ): PAYMENT_METHOD_UNAVAILABLE_REASON[] => {
    const reasons = [];

    if (!enabledPaymentMethods.includes(PAYMENT_METHOD.FREE)) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.NOT_ENABLED);
    }

    if (
      !outstandingByPaymentMethod ||
      outstandingByPaymentMethod[PAYMENT_METHOD.FREE] !== 0
    ) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.TOTAL_INVALID);
    }

    if (!stagedPurchaseCount) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.CART_NOT_COMPATIBLE);
    }

    return reasons;
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as string | null;

const slice = createSlice({
  name: 'currentModalId',
  initialState,
  reducers: {
    setCurrentModalId(state, action: PayloadAction<ModalData | null | string>) {
      if (typeof action.payload === 'string') {
        return action.payload || initialState;
      }

      const { modalId } = action.payload || {};

      return modalId || initialState;
    },
  },
});

export const { setCurrentModalId } = slice.actions;

export default slice.reducer;

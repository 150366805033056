import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setPurchaser } from '../../../actions/currentOrder';

const initialState = null as string | null;

const slice = createSlice({
  name: '$purchaserEmail',
  initialState,
  reducers: {
    setPurchaserEmail(state, action: PayloadAction<string | null>) {
      return action.payload;
    },
    adjustPurchaserEmail(state,action:PayloadAction<string|'clear'|'delete'>){
       switch (action.payload) {
        case 'clear': {
          return initialState;
        }
        case 'delete': {
          return (state || '').slice(0, -1);
        }
        default:
          return `${state || ''}${action.payload}`.trimLeft();
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(setPurchaser, (state, action) => {
      return action.payload.email;
    });
  },
});

export const { setPurchaserEmail,adjustPurchaserEmail } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = true;

const slice = createSlice({
  name: '$loyaltyRequiresVerification',
  initialState,
  reducers: {
    setLoyaltyRequiresVerification(state, action: PayloadAction<boolean>) {
      return Boolean(action.payload);
    },
  },
});

export const { setLoyaltyRequiresVerification } = slice.actions;
export default slice.reducer;

import React from 'react';
import { Row, Col } from 'reactstrap';
import Text from './Text';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;

const TransactionDetails = ({
  transaction,
  order,
}: {
  transaction: Transaction;
  order: TransactionHistory;
}) => {
  const amount = transaction?.Total || transaction?.Price * transaction?.Qty || 0;
  const price = useFormattedCurrency({
    cents: amount,
  });
  const value = String(order.TxnType == 5 ? transaction.Points.toString() : price);

  return (
    <div>
      <Row>
        <Col>
          <Text themeKey="loyaltyOrderItems" value={transaction.Item} />{' '}
          <Text
            themeKey="loyaltyOrderItems"
            value={transaction.Qty ? 'x' + transaction.Qty.toString() : ''}
          />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <Text themeKey="loyaltyOrderItems" value={value} />
        </Col>
      </Row>
    </div>
  );
};

export default TransactionDetails;

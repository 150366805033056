import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../components/Modal';
import SignInForm from '../components/SignInForm';

export const SIGN_IN_MODAL_ID = 'SIGN_IN_MODAL_ID';

const SignInModal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Modal
      // title={t('title.modal.signIn')}
      desktopMaxWidth={400}
      desktopMinWidth={400}
      desktopContainerStyle={styles.modalDesktopContainer}
      mobileContainerStyle={styles.modalMobileContainer}
      closeButtonLeft
    >
      <SignInForm />
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    padding: '70px 70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
};

export default SignInModal;

import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoyaltyTiers } from '../slices/loyaltyTiers';

export const fetchLoyaltyTiers = createAsyncThunk(
  'fetchLoyaltyTiers',
  async (_data: undefined, { dispatch }) => {
    const path = '/api/v1/loyaltytiers';

    return RedcatApiHandler.fetch({ method: 'GET', path }).then(response => {
      dispatch(setLoyaltyTiers(response.data));
    });
  },
);

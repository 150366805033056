import lodash from 'lodash';
import { OrderingSelectors } from 'polygon-ordering';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';
import { useAppSelector } from '../app/hooks';
import combineStyles from '../utils/combineStyles';
import MenuItem from './MenuItem';
import Text from './Text';

const { getDayPartMenuConfig, getCategoryAvailabilityById } = OrderingSelectors;
interface IProps {
  category: Category;
  reportRef?: (id: string, ref: React.RefObject<HTMLDivElement>) => void;
}
const MenuCategory: React.FC<IProps> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const { category, reportRef } = props;
  const disableCategoryCount = useAppSelector(state => state.config.disableCategoryCount);
  const { t } = useTranslation();

  useEffect(() => {
    if (reportRef) {
      reportRef(category.id, ref);
    }
  }, []);

  const daypartMenu = useAppSelector(getDayPartMenuConfig);
  const { hideUnavailableCategories } = daypartMenu;
  const [daypartAvailability, menuAvailablity] = useAppSelector(
    getCategoryAvailabilityById(category.id),
  );
  const availabilityTimeString = t('menuAvailablity', {
    menuAvailablity,
  });

  return !(!daypartAvailability && hideUnavailableCategories) ? (
    <section ref={ref} style={combineStyles(styles.category)}>
      <div style={styles.categoryName}>
        <Text
          themeKey="menuCategoryHeading"
          style={combineStyles(styles.heading, !daypartAvailability && { opacity: 0.5 })}
        >
          {`${category.name} `}
          {!disableCategoryCount ? (
            <Text themeKey="menuCategoryHeadingCount">{`(${category.items.length})`}</Text>
          ) : null}
        </Text>
        {category.availability && (
          <Text value={availabilityTimeString} style={styles.availability} />
        )}
      </div>

      <div>
        <Row>
          {lodash.map(category?.items, item => (
            <MenuItem key={item.id} item={item} brandId={category.brandId} category={category} />
          ))}
        </Row>
      </div>
    </section>
  ) : null;
};
const styles: Styles = {
  availability: {
    fontSize: 12,
    opacity: 0.6,
  },
  categoryName: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1em',
  },
  category: {
    marginBottom: '2em',
  },
  heading: {
    display: 'inline-block', // should this be on all Text components?
  },
};

export default MenuCategory;

import { OFFER_TYPE } from '../../constants/offerType';

function checkChoice(
  choice: ChoiceWithQuantity | NestedChoiceWithQuantity,
  targetPLUs: string[],
): boolean {
  // checking the id here is wrong (this is a known bug) TODO: fix
  // also fix for composites?
  if (targetPLUs.includes(choice.plucode)) {
    return true;
  }

  if ('choiceSets' in choice && choice.choiceSets && choice.choiceSets.length) {
    return choice.choiceSets.some(choiceSet =>
      choiceSet.choices.some(nestedChoice =>
        checkChoice(nestedChoice, targetPLUs),
      ),
    );
  }

  return false;
}

export default function isOfferValidForPurchases(
  offer: Offer,
  purchases: PurchaseWithTotals[],
  items: Items,
): boolean {
  let valid = false;

  const noPLURestrictions = !offer.targetPLUs.length;
  const hasTargets =
    purchases.length > 0 &&
    (purchases.some(purchase => offer.targetPLUs.includes(purchase.plucode)) ||
      noPLURestrictions);

  switch (offer.type) {
    case OFFER_TYPE.AMOUNT_OFF_ORDER:
    case OFFER_TYPE.PERCENTAGE_OFF_ORDER:
      valid = hasTargets && purchases.some(purchase => purchase.moneyPrice);
      break;

    case OFFER_TYPE.PRICE_OVERRIDE_ITEM_OR_INGREDIENT:
      valid =
        hasTargets ||
        purchases.some(purchase =>
          purchase.choicesWithQuantity.some(
            choice =>
              noPLURestrictions || checkChoice(choice, offer.targetPLUs),
          ),
        );
      break;

    case OFFER_TYPE.AMOUNT_OFF_ITEM:
    case OFFER_TYPE.PERCENTAGE_OFF_ITEM:
    case OFFER_TYPE.PERCENTAGE_OFF_ITEM_AND_INGREDIENTS:
      valid = hasTargets;
      break;

    case OFFER_TYPE.PERCENTAGE_OFF_ITEM_POS_ENFORCED:
      // Do we need to check the menu to make sure the item is available here?
      // answer: I reckon
      valid =
        !!offer.targetPLUs.length &&
        !!Object.values(items).find(
          item => item.plucode === offer.targetPLUs[0],
        );
      break;

    case OFFER_TYPE.AMOUNT_OFF_BOGO:
    case OFFER_TYPE.PERCENTAGE_OFF_BOGO:
      const qualifyingPurchases = purchases.filter(
        purchase =>
          offer.qualifyingPLUs?.find(plu => plu === purchase.plucode) ||
          !offer.qualifyingPLUs?.length,
      );
      const targetPurchases = purchases.filter(
        purchase =>
          offer.targetPLUs.find(plu => plu === purchase.plucode) ||
          noPLURestrictions,
      );

      if (qualifyingPurchases.length && targetPurchases.length) {
        valid = true;
        if (qualifyingPurchases.length < 2 && targetPurchases.length < 2) {
          if (
            qualifyingPurchases[0].id === targetPurchases[0].id &&
            targetPurchases[0].quantity < 2
          ) {
            valid = false;
          }
        }
      }
      break;
  }
  return valid;
}

import { createSelector } from 'reselect';

import getTable from './getTable';
import { getDisableTableNumberSaleBody } from './config';

export default createSelector(
  [getTable, getDisableTableNumberSaleBody],
  (table, disableTableNumberSaleBody) => ({
    ...(disableTableNumberSaleBody === false
      ? { TableNumber: String(table) }
      : {}),
    PickupTime: 'ASAP',
  }),
);

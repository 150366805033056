import lodash from 'lodash';

/*
Uses a supplied schema to map a given object to a new one.

Valid Schemas:

- Strings
Any value in the input with a key matching the schema are added to the new object

- Object
For each key/value pair in the schema, any values in the input are added to the
new object. Their key in the new object is the the (string) value from the schema.

If the schema value is not truthy, the schema key/pair is ignored.

- Function
The entire input is provided to the function, and the result of the function is merged
into the new object.

- Array
The schema can be an array of any of the above types. They are evaluated left to right
and all modify the same new object that is returned.

Examples:

omniMapper({ a: 1 }, 'a') -> { a: 1 }

omniMapper({ a: { b: 1 } }, 'a.b') -> { b: 1 }

omniMapper({ a: { b: 1 } }, { c: 'a.b' }) -> { c: 1 }

omniMapper({ a: 1 }, 'b') -> { b: undefined }

omniMapper({ a: 1 }, ['a', 'b']) -> { a: 1, b: undefined }

omniMapper({ a: 1 }, ({ a }) => ({ b: a })) -> { b: 1 }

omniMapper({ a: 1 }, { a: 'b' }) -> { b: 1 }

omniMapper({ a: 1, c: 2 }, { a: 'b', c: 'd' }) -> { b: 1, d: 2 }

omniMapper({ a: 1, c: 2 }, [{ a: 'b' }, 'c']) -> { b: 1, c: 2 }

omniMapper({ a: 1 }, { b: ({a}) => a }) -> { b: 1 }

omniMapper({ a: 1, b: 20, c: 'test' }, [({ a }) => ({ e: a }), 'b', { c: 'd' }]) -> { e: 1, b: 20, d: 'test' }
*/

export default function omniMapper(
  inputValues: { [key: string]: any },
  inputSchema: { [key: string]: any },
) {
  let output: { [key: string]: any } = {};

  const handleString = (str: string) => {
    const keys = (str || '').split('.');
    const tailKey = keys[keys.length - 1];

    output[tailKey] = lodash.get(inputValues, str);
  };

  const handleObject = (obj: { [key: string]: any }) => {
    lodash.keys(obj).forEach(key => {
      const value = obj[key];

      if (typeof value === 'function') {
        output[key] = value(inputValues);
      } else if (value) {
        output[value] = lodash.get(inputValues, key);
      }
    });
  };

  const handleFunction = (fn: (inputValues: {}) => {}) => {
    output = { ...output, ...fn(inputValues) };
  };

  const handleSchema = (schema: any) => {
    if (typeof schema === 'string') {
      handleString(schema);
    } else if (typeof schema === 'function') {
      handleFunction(schema);
    } else if (Array.isArray(schema)) {
      schema.forEach(handleSchema);
    } else if (typeof schema === 'object' && schema != null) {
      handleObject(schema);
    }
  };

  handleSchema(inputSchema);

  return output;
}

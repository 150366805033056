import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as lodash from 'lodash';
import { OrderingSelectors } from 'polygon-ordering';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import combineStyles from '../utils/combineStyles';
import { setCurrentModal } from '../thunks/setCurrentModal';
import getOAuthExtraFields from '../selectors/getOAuthExtraFields';
import getOauthExtraInfoSchema from '../selectors/getOauthExtraInfoSchema';
import getOAuthExtraFileldInitialValues from '../selectors/getOAuthExtraFileldInitialValues';
import Modal from '../components/Modal';
import FormFields from '../components/RegistrationFields';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text';
import { syncMember } from '../thunks/syncMember';

export const OAUTH_EXTRA_INFO_MODAL_ID = 'OAUTH_EXTRA_INFO_MODAL_ID';

const { getOAuthExtraInformationConfig, getMember } = OrderingSelectors;
const OAuthExtraInfoModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [rewardGiven, setRewardGiven] = useState(false);
  const fields = useAppSelector(getOAuthExtraFields);
  const schema = useAppSelector(getOauthExtraInfoSchema);
  const initialValues = useAppSelector(getOAuthExtraFileldInitialValues);
  const oauthExtraInfoConfig = useAppSelector(getOAuthExtraInformationConfig);
  const member = useAppSelector(getMember);
  if (member?.mobile && lodash.has(initialValues.visible, 'Mobile')) {
    initialValues.visible.Mobile = member.mobile;
  }

  const {
    oAuthAdditionalInfoModalTitle,
    oAuthAdditionalInfoModalSubTitle,
    oAuthRewardModalDesc,
    enableRewardModal,
  } = oauthExtraInfoConfig || {};
  const title = submitted ? t('thanksForSignUp') : oAuthAdditionalInfoModalTitle || '';
  const subTitle = submitted
    ? enableRewardModal && oAuthRewardModalDesc
      ? oAuthRewardModalDesc
      : t('checkEmail')
    : oAuthAdditionalInfoModalSubTitle;

  const _handleSubmit = async (data: FormikFields, setSubmitting: (v: boolean) => void) => {
    try {
      if (submitted) dispatch(setCurrentModal(null));
      else {
        const res = await RedcatApiHandler.authorisedFetch({
          path: 'api/v1/profile/oauth_details',
          method: 'PUT',
          body: {
            mobile: data.Mobile,
            dateofbirth: data.DateOfBirth,
            postcode: data.Postcode,
            recid_plo: data.FavouriteStore,
            sendsms: data.SendSMS,
            sendemail: data.SendEmail,
          },
        });
        if (res.success) {
          dispatch(syncMember());
          setSubmitted(true);
          if (res.reward_given) {
            setRewardGiven(true);
          }
        }
      }
    } catch (e) {
      enqueueErrorSnackbar(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={title}
      desktopMaxWidth={400}
      desktopMinWidth={400}
      desktopContainerStyle={combineStyles(
        styles.modalDesktopContainer,
        submitted && { paddingBottom: 20 },
      )}
      closeButtonLeft
    >
      <div style={styles.innerContainer}>
        <Formik
          onSubmit={() => {}}
          initialValues={initialValues.visible}
          validationSchema={schema.visible}
        >
          {({ handleSubmit, isSubmitting, setSubmitting, isValid, dirty, values }) => (
            <Form onSubmit={handleSubmit}>
              <div style={styles.form}>
                {subTitle && (
                  <div style={styles.description}>
                    <Text>{subTitle}</Text>
                  </div>
                )}
                {!submitted && (
                  <div>
                    {fields!.visible.map((field: RegisterFields, index: any) => (
                      <FormFields field={field} key={index} />
                    ))}
                  </div>
                )}
                <div style={{ marginTop: 30, marginBottom: 30 }}>
                  <StandardButton
                    label={t('button.continue')}
                    containerStyle={styles.button}
                    disabled={Boolean(isSubmitting || !isValid || !dirty)}
                    themeKey="signInSubmitButton"
                    onClick={() => {
                      _handleSubmit(values, setSubmitting);
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    // padding: '50px 70px',
  },
  innerContainer: {
    minHeight: 'min-content',
    justifyContent: 'space-between',
    // marginTop: '1rem',
  },
  description: {
    paddingBottom: 30,
    textAlign: 'center',
  },
  form: {
    minHeight: 420,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};
export default OAuthExtraInfoModal;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { setOpenPurchase } from '../reducers/currentOrder/openPurchase';
import {
  getNestedItemStockBalancesData,
  getOpenPurchasePreviouslyStaged,
} from '../selectors';
import getItems from '../selectors/getItems';
import getUnenhancedOpenPurchase from '../selectors/getUnenhancedOpenPurchase';
import removeUnusedNestedChoiceSelections from '../utils/ordering/removeUnusedNestedChoiceSelections';
import { addStagedPurchase } from '../reducers/currentOrder/stagedPurchases';
import purchaseFromItem from '../utils/ordering/purchaseFromItem';

export const stageOpenPurchase = createAsyncThunk(
  '$stageOpenPurchase',
  async (
    data: {
      choiceSelections?: NestedChoiceSelections;
      enableUpsell?: boolean;
      itemId?: string;
      brandId?: string;
      upSellStaging?: boolean;
    },
    { getState, rejectWithValue, dispatch },
  ) => {
    const { choiceSelections, enableUpsell, itemId, brandId, upSellStaging } =
      data;
    try {
      const allItems = getItems(getState() as EntireFrontendState);
      const [stockBalanceDataMap] = getNestedItemStockBalancesData(undefined)(
        getState() as EntireFrontendState,
      );

      let openPurchase = upSellStaging
        ? createOpenPurchaseByUpsellItem(
            itemId,
            allItems,
            brandId,
            undefined,
            stockBalanceDataMap,
          )!
        : getUnenhancedOpenPurchase(getState() as EntireFrontendState)!;

      if (choiceSelections) {
        openPurchase = {
          ...openPurchase,
          choiceSelections,
        };

        dispatch(setOpenPurchase(openPurchase));
      }

      const previouslyStaged = getOpenPurchasePreviouslyStaged(
        getState() as EntireFrontendState,
      );

      dispatch(
        addStagedPurchase(
          removeUnusedNestedChoiceSelections(openPurchase!, allItems!),
        ),
      );

      return {
        purchase: removeUnusedNestedChoiceSelections(openPurchase!, allItems!),
        previouslyStaged,
        enableUpsell,
        upSellStaging,
      };
    } catch (e) {
      console.error('Stage open purchase failed', { e });
      return rejectWithValue(e);
    }
  },
);

const createOpenPurchaseByUpsellItem = (
  itemId: string | undefined,
  allItems: SDict<Item> | undefined,
  brandId?: string,
  choiceSelections?: ChoiceSelections | NestedChoiceSelections,
  stockBalanceDataMap?: SDict<StockBalanceData>,
): _Purchase | null => {
  if (!itemId || !allItems || !allItems[itemId]) return null;
  return purchaseFromItem(
    allItems[itemId],
    uuidv4(),
    choiceSelections,
    undefined,
    brandId,
    undefined,
    stockBalanceDataMap,
  );
};

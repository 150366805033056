import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setCurrentPaymentMethod } from './currentPaymentMethod';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';

const initialState = null as string | null;

const slice = createSlice({
  name: 'currentSavedCardToken',
  initialState,
  reducers: {
    setCurrentSavedCardToken(state, action: PayloadAction<string | null>) {
      return action.payload || initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      setCurrentPaymentMethod,
      (state, action: PayloadAction<PAYMENT_METHOD | null>) => {
        if (action.payload !== PAYMENT_METHOD.SAVED_CARD) {
          return initialState;
        }

        return state;
      },
    );
  },
});

export const { setCurrentSavedCardToken } = slice.actions;

export default slice.reducer;

import React, { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import FormTextField from '../components/FormTextField';
import FormCheckBox from '../components/FormCheckBox';
import FormSelect from '../components/FormSelect';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormDateFields from '../components/FormDateFields';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PasswordStrengthBar from 'react-password-strength-bar';
import combineStyles from '../utils/combineStyles';
import { logEvent } from '../utils/analytics';
import getTermsAndConditionsLink from '../selectors/getTermsAndConditionsLink';
import { useAppSelector } from '../app/hooks';
import { EVENT_CATEGORIES } from '../constants/events';
import { useTranslation } from 'react-i18next';
import { determineEndpoint } from '../utils/endpoint';
import getThemeLookup from '../selectors/getThemeLookup';
import { TEXT_PROPERTIES } from '../utils/theme';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { INPUT_FIELDS } from '../constants/inputfields';

const Link: React.FC<{
  url: string;
  openInNewTab?: boolean;
  onClick?: () => void;
  children?: React.ReactChildren;
}> = ({ url, openInNewTab, onClick, children }) => {
  const p = useAppSelector(getThemeLookup);
  return (
    <a
      href={url}
      style={combineStyles(styles.link, p('signInLink', ['fontFamily', 'fontWeight']))}
      target={openInNewTab ? '_blank' : undefined}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

const RegistrationFields = ({
  field,
  showStrengthBar,
}: {
  field: RegisterFields;
  showStrengthBar?: boolean;
}) => {
  const formik = useFormikContext<FormikFields>();
  const { values, handleBlur, handleChange, submitCount } = formik;
  const termsAndConditionsLink = useAppSelector(getTermsAndConditionsLink);
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  // State to toggle password visibility eye icon
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  // @Manan TODO Review
  const handleTogglePasswordVisibility = (event: any) => {
    if (inputRef.current) {
      const cursorPosition = inputRef.current.selectionStart;
      setShowPassword(show => !show);
      setTimeout(() => {
        if (inputRef.current && cursorPosition) {
          inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // Setting the state to default after successfull submission
  useEffect(() => {
    if (submitCount > 0) {
      setShowPassword(false);
    }
  }, [submitCount]);

  const _getTermsAndConditionsLink = () => {
    let _termsAndConditionsLink;
    try {
      _termsAndConditionsLink = String(new URL(termsAndConditionsLink!));
    } catch (e) {
      _termsAndConditionsLink = String(determineEndpoint()! + termsAndConditionsLink);
    }
    return (
      <span>
        {t('termsPrefix')}{' '}
        <Link url={_termsAndConditionsLink} openInNewTab={true}>
          {t('termsAndContition')}
        </Link>
      </span>
    );
  };

  const renderFormField = () => {
    const { inputType, label, editMode } = field;
    if (editMode === 'hidden') return;

    // Showing input props if this is a password field and values has length
    const showInputProps =
      inputType === 'password' &&
      field?.id &&
      values[field.id as keyof FormikFields] &&
      (values[field.id as keyof FormikFields] as string).length;

    switch (inputType) {
      case 'email':
        return (
          <FormControl key={field.id} fullWidth>
            <FormTextField
              name={field.id}
              type="email"
              required
              label={t(`field.signUp.${field.id}`)}
              onChange={handleChange}
            />
          </FormControl>
        );
      case 'number':
        return (
          <FormControl key={field.id} fullWidth>
            <FormTextField
              name={field.id}
              type="number"
              required
              label={t(`field.signUp.${field.id}`)}
              onChange={handleChange}
            />
          </FormControl>
        );
      case 'text':
        return (
          <FormControl key={field.id} fullWidth>
            <FormTextField
              name={field.id}
              // type={inputType === 'number' ? 'digits' : field.inputType}
              type={field.inputType}
              onChange={handleChange}
              label={t(`field.signUp.${field.id}`)}
              required={[field.mode, field.registerMode].includes('required')}
              disabled={field.editMode === 'display'}
              shrinkLabel={false}
            />
          </FormControl>
        );
      case 'password':
        return (
          <FormControl key={field.id} fullWidth>
            <FormTextField
              name={field.id}
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              label={label!}
              required={field.mode === 'required'}
              disabled={field.editMode === 'display'}
              inputRef={inputRef}
              InputProps={
                showInputProps
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleTogglePasswordVisibility}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <IoEyeOff /> : <IoEye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
            {showInputProps && showStrengthBar && (
              <PasswordStrengthBar
                password={String(values[field.id as keyof FormikFields])}
                style={combineStyles(styles.passwordStrengthBar)}
                scoreWordStyle={combineStyles(p('defaultText', TEXT_PROPERTIES), {
                  fontSize: '0.6rem',
                  opacity: '0.6',
                })}
                scoreWords={[
                  t(`field.signUp.passwordStrengthBarScoreWords.weak`),
                  t(`field.signUp.passwordStrengthBarScoreWords.weak`),
                  t(`field.signUp.passwordStrengthBarScoreWords.okay`),
                  t(`field.signUp.passwordStrengthBarScoreWords.good`),
                  t(`field.signUp.passwordStrengthBarScoreWords.strong`),
                ]}
                shortScoreWord={t(`field.signUp.passwordStrengthBarScoreWords.short`)}
              />
            )}
          </FormControl>
        );
      case 'sendemail':
      case 'sendsms':
      case 'terms':
      case 'checkbox':
        return (
          <FormControl key={field.id} fullWidth>
            <FormCheckBox
              required={field.mode === 'required'}
              onChange={handleChange}
              name={field.id}
              disabled={field.editMode === 'display'}
              label={
                inputType === 'terms' ? _getTermsAndConditionsLink() : t(`field.signUp.${field.id}`)
              }
            />
          </FormControl>
        );
      case 'radio':
        return (
          <RadioGroup
            id={field.id}
            name={field.id}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[field.id as keyof FormikFields]}
          >
            {field.possibleValues?.map(({ value, label }: any, index: number) => (
              <FormControlLabel
                key={index}
                value={value}
                control={
                  <Radio
                    disabled={field.editMode === 'display'}
                    required={field.mode === 'required'}
                  />
                }
                label={label}
              />
            ))}
          </RadioGroup>
        );
      case 'select':
        return (
          <FormControl key={field.id} fullWidth>
            <FormSelect
              required={field.mode === 'required'}
              label={t(`field.signUp.${field.id}`)}
              name={field.id}
              onChange={handleChange}
              disabled={field.editMode === 'display'}
              menuItems={field?.possibleValues}
            />
          </FormControl>
        );
      case 'date':
      case 'datetime':
      case 'time':
        return (
          <FormControl key={field.id} fullWidth>
            <FormDateFields
              type={field.inputType}
              disabled={field.editMode === 'display'}
              required={field.mode === 'required'}
              name={field.id}
              label={t(`field.signUp.${field.id}`)}
              onChange={handleChange}
            />
          </FormControl>
        );
      default:
        logEvent(
          { action: 'noComponentForField', category: EVENT_CATEGORIES.FAILURE },
          { label: 'noComponentForField', value: JSON.stringify(field) },
        );
        return null;
    }
  };

  return <div>{renderFormField()}</div>;
};

const styles: Styles = {
  passwordStrengthBar: {
    marginTop: '1rem',
    fontSize: '0.7rem',
  },
  link: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '0.8rem',
  },
};

export default RegistrationFields;

import { createSelector } from 'reselect';

import { OrderingSelectors } from 'polygon-ordering';
import { RootState } from 'store';

const { getPaymentGatewayTokens } = OrderingSelectors;

const getCurrentSavedCardToken = (state: RootState) => state.currentSavedCardToken;

export default createSelector(
  [getCurrentSavedCardToken, getPaymentGatewayTokens],
  (currentSavedCardToken, savedCards) => {
    //savedCards type any?
    return (savedCards || []).some(
      (card: PaymentGateWayToken) => card.token === currentSavedCardToken,
    );
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: AttemptsConfig = {
  submitSale: 1,
  validateOrder: 1,
};

const slice = createSlice({
  name: '$attempts',
  initialState,
  reducers: {
    setAttemptsConfig(state, action: PayloadAction<AttemptsConfig>) {
      return action.payload;
    },
  },
});

export const { setAttemptsConfig } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';

const initialState = null as DeliveryProvider | null;

const slice = createSlice({
  name: '$deliveryProvider',
  initialState,
  reducers: {
    setDeliveryProvider(state, action: PayloadAction<DeliveryProvider | null>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(applyBufferData, (state, action) => {
      const { deliveryProvider } = action.payload;
      return deliveryProvider === undefined ? state : deliveryProvider;
    });
  },
});

export const { setDeliveryProvider } = slice.actions;
export default slice.reducer;

import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

const getDeliverySurcharges = createSelector(
  [getCurrentOrder],
  order => order.deliverySurcharges,
);

export default getDeliverySurcharges;

export const $getDeliverySurcharges = createSelector(
  [getDeliverySurcharges, getBuffer],
  (orderDeliverySurcharges, buffer) => {
    if (buffer.deliverySurcharges != null) {
      return buffer.deliverySurcharges;
    }

    return orderDeliverySurcharges;
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSelectedOffer } from '../selectors';
import getLocationId, { $getLocationId } from '../selectors/getLocationId';
import Api, { FetchParams, AuthenticationMethod } from '../utils/Api';
import processOffer from '../utils/processors/processOffer';
import { addOffers, setOffers } from '../reducers/currentOrder/offers';
import { setBufferOffers, addBufferOffers } from '../reducers/buffer/offers';

export const fetchOffers = createAsyncThunk(
  '$fetchOffers',
  async (
    data: {
      promoCode?: string;
      authenticationMethod?: AuthenticationMethod;
      bufferMode?: boolean;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      promoCode,
      authenticationMethod = 'member',
      bufferMode = false,
    } = data;

    const locationId = bufferMode
      ? $getLocationId(getState() as EntireFrontendState)
      : getLocationId(getState() as EntireFrontendState);

    try {
      const selectedOffer = getSelectedOffer(getState() as EntireFrontendState);

      if (!locationId) {
        throw new Error('no location id');
      }

      let pathSuffix = `?hideexpired=1&hideredeemed=1&storeid=${locationId}`;

      if (promoCode) {
        pathSuffix = `/${promoCode}?storeid=${locationId}`;
      } else if (selectedOffer) {
        pathSuffix += `&include_extra=${encodeURIComponent(
          selectedOffer.hash,
        )}`;
      }

      const params: FetchParams = {
        path: `/api/v1/profile/coupons/ordering${pathSuffix}`,
        method: 'GET',
      };

      const response = await Api.fetch(params, authenticationMethod);

      const rawOffers: RawOffer[] = response.data;
      const processedOffers = rawOffers.map(processOffer);

      if (bufferMode && promoCode) {
        dispatch(addBufferOffers(processedOffers));
        dispatch(addOffers(processedOffers));
      } else if (bufferMode) {
        dispatch(setBufferOffers(processedOffers));
      } else if (promoCode) {
        dispatch(addOffers(processedOffers));
      } else {
        dispatch(setOffers(processedOffers));
      }

      return {
        offers: processedOffers,
      };
    } catch (e) {
      if (!locationId) {
        //skip
        // console.warn('Skipping store specific offers - no location id', {
        //   e,
        // });
      } else {
        console.warn('Fetch offers failed', { e });
      }

      return rejectWithValue(e);
    }
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'logoImagePath',
  initialState: null as string | null,
  reducers: {
    setLogoImagePath(state, action: PayloadAction<string>) {
      return action.payload;
    },
  },
});

export const { setLogoImagePath } = slice.actions;

export default slice.reducer;

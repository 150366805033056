import React from 'react';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector } from '../app/hooks';
import { TEXT_PROPERTIES } from '../utils/theme';

const ScreenLink = ({
  label,
  actionLabel,
  url,
  openInNewTab,
  onClick,
  themeKeyLabel,
  themeKeyActionLabel,
}: LinkTypes) => {
  const p = useAppSelector(getThemeLookup);

  const themeKeyLink =
    Object.keys(p(themeKeyLabel, TEXT_PROPERTIES)).length === 0 ? 'signInLink' : themeKeyLabel;

  const themeKeyLinkRight =
    Object.keys(p(themeKeyActionLabel, TEXT_PROPERTIES)).length === 0
      ? 'signInLinkRight'
      : themeKeyActionLabel;

  return (
    <a
      href={url}
      style={combineStyles(styles.link, p(themeKeyLink, TEXT_PROPERTIES))}
      target={openInNewTab ? '_blank' : undefined}
      onClick={onClick}
    >
      <span
        style={combineStyles(styles.linkLeft, p(themeKeyLink, TEXT_PROPERTIES))}
      >{`${label} `}</span>
      <span style={combineStyles(styles.linkRight, p(themeKeyLinkRight, TEXT_PROPERTIES))}>
        {actionLabel}
      </span>
    </a>
  );
};

export default ScreenLink;

const styles: Styles = {
  link: {
    textDecoration: 'none',
    marginTop: 10,
    textAlign: 'center',
    fontSize: 14,
  },
  linkLeft: {
    fontFamily: 'inherit',
  },
  linkRight: {
    fontFamily: 'inherit',
  },
};

import { createSelector } from '@reduxjs/toolkit';
import getCurrentOrder from './getCurrentOrder';
import getStagedPurchases from './getStagedPurchases';
import { getStockBalanceThresholds } from './config';
import calcStockBalanceDataNested from '../utils/ordering/calcStockNestedItem';

export default (
  purchase: PurchaseWithTotals | undefined,
  nestedPurchased?: NestedChoiceSelections,
) =>
  createSelector(
    [getCurrentOrder, getStagedPurchases, getStockBalanceThresholds],
    (
      ordering,
      stagedPurchases,
      stockBalanceThresholds,
    ): [SDict<StockBalanceData> | undefined, number | undefined] => {
      const { stockBalances: stockBalancesApi } = ordering;
      return calcStockBalanceDataNested(
        purchase,
        stockBalancesApi,
        nestedPurchased,
        stagedPurchases,
        stockBalanceThresholds,
      );
    },
  );

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderingSelectors, OrderingOperations, OrderingHooks } from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';
import Text from './Text';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import Line from './Line';

const { updateSelectedPaymentMethods } = OrderingOperations;

const { getSelectedPaymentMethods, getRemainingTotal, getOrderTotals, getSaleType } =
  OrderingSelectors;

interface IProps {
  useMobileScreen?: boolean;
}

const OrderTotalsPaymentSummary: React.FC<IProps> = ({ useMobileScreen }) => {
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const saleType = useAppSelector(getSaleType);
  const orderTotals = useAppSelector(getOrderTotals);
  const remainingTotal = useAppSelector(getRemainingTotal);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const enableMultiPayments = useAppSelector(state => state.enableMultiPayments);

  useEffect(() => {
    dispatch(updateSelectedPaymentMethods({}));
  }, [orderTotals?.discountedMoneyPrice, orderTotals?.pointsPrice]);

  const { t } = useTranslation();

  if (!orderTotals && orderTotals !== undefined) {
    return null;
  }

  const showTotal = useMobileScreen;

  return (
    <div style={styles.mainContainerDesktop}>
      {useMobileScreen && (
        <div
          style={combineStyles(
            styles.horizontalSeperator,
            p('orderTotalsSeperator', ['backgroundColor']),
          )}
        />
      )}

      {selectedPaymentMethods.length > 0
        ? selectedPaymentMethods.map(selectedPaymentMethod => (
            <Line
              key={selectedPaymentMethod.method}
              label={
                selectedPaymentMethod.method == PAYMENT_METHOD.PAY_LATER
                  ? t(`paymentMethod.${selectedPaymentMethod.method}.${saleType}`)
                  : t(`paymentMethod.${selectedPaymentMethod.method}`)
              }
              amount={Number(selectedPaymentMethod.amount)}
              deemphasised
            />
          ))
        : null}

      {enableMultiPayments && (
        <Line
          label={t('orderTotalPaymentSummaryLabel')}
          amount={
            selectedPaymentMethods.length > 0 ? remainingTotal : orderTotals!.discountedMoneyPrice
          }
          deemphasised
        />
      )}

      {showTotal && (
        <div style={styles.totalContainer}>
          <Line label={t('totals.total')} amount={orderTotals!.discountedMoneyPrice} emphasised />
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainerDesktop: {
    /* paddingBottom: 5,
    paddingTop: 5, */
  },

  horizontalSeperator: {
    height: 1,
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  totalContainer: {
    paddingTop: 20,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 6,
  },
  orderTotalLabel: {},
};

export default OrderTotalsPaymentSummary;

import React from 'react';

const PoweredByUberDirectBadge: React.FC = () => (
  <div style={{ width: 220, marginTop: 15 }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 660.59 151.61">
      <defs>
        <style>{'.cls-1{fill:#fff;}'}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Outlined">
          <g id="Delivered_Horizontal_blk">
            <rect width="660.59" height="151.61" rx="75.81" />
            <path
              className="cls-1"
              d="M95.54,76a24.49,24.49,0,0,1-2,9.83,25.3,25.3,0,0,1-23.36,15.3H52.27V50.69H70.2a24.71,24.71,0,0,1,9.86,2,25.82,25.82,0,0,1,8.06,5.4A25,25,0,0,1,95.54,76ZM55.72,53.78V98H70.27a20.86,20.86,0,0,0,8.49-1.73,22.36,22.36,0,0,0,6.95-4.71,22.36,22.36,0,0,0,4.68-24.16,22.2,22.2,0,0,0-4.68-7.06,22.56,22.56,0,0,0-6.95-4.78,20.57,20.57,0,0,0-8.49-1.77Z"
            />
            <path
              className="cls-1"
              d="M98.42,83.23a18.43,18.43,0,0,1,1.4-7.16,18.65,18.65,0,0,1,3.82-5.84,17.81,17.81,0,0,1,12.85-5.36,16.2,16.2,0,0,1,6.66,1.4,17.58,17.58,0,0,1,5.44,3.78,17.93,17.93,0,0,1,3.63,5.58,17.72,17.72,0,0,1,1.33,6.88v1.66H101.73a15.81,15.81,0,0,0,1.48,5.69,15.61,15.61,0,0,0,3.27,4.6,15,15,0,0,0,4.68,3.06,14.31,14.31,0,0,0,5.62,1.12,14.66,14.66,0,0,0,7-1.69A15,15,0,0,0,129,92.3l2.66,1.8a18.53,18.53,0,0,1-6.37,5.51,17.83,17.83,0,0,1-8.53,2,18.14,18.14,0,0,1-13.07-5.4,18.35,18.35,0,0,1-5.29-13Zm18.07-15.41a14,14,0,0,0-5.29,1,14.45,14.45,0,0,0-4.43,2.81,15.7,15.7,0,0,0-3.24,4.25,15.36,15.36,0,0,0-1.66,5.25H130.1a14.4,14.4,0,0,0-1.41-5.25,15.22,15.22,0,0,0-3-4.25,13.55,13.55,0,0,0-4.18-2.81A12.51,12.51,0,0,0,116.49,67.82Z"
            />
            <path className="cls-1" d="M138.81,101.09V50.69h3.31v50.4Z" />
            <path
              className="cls-1"
              d="M150.76,58a2.48,2.48,0,0,1-1.8-.79,2.55,2.55,0,0,1-.79-1.88,2.42,2.42,0,0,1,.79-1.83,2.51,2.51,0,0,1,3.6,0,2.42,2.42,0,0,1,.79,1.83,2.55,2.55,0,0,1-.79,1.88A2.48,2.48,0,0,1,150.76,58Zm1.59,43.13H149V65.45h3.32Z"
            />
            <path
              className="cls-1"
              d="M155.66,65.45h3.53L171.57,95.9l12.31-30.45h3.46l-14.62,35.64h-2.45Z"
            />
            <path
              className="cls-1"
              d="M185.9,83.23a18.25,18.25,0,0,1,1.4-7.16,18.65,18.65,0,0,1,3.82-5.84A17.81,17.81,0,0,1,204,64.87a16.2,16.2,0,0,1,6.66,1.4,17.42,17.42,0,0,1,5.43,3.78,17.77,17.77,0,0,1,5,12.46v1.66H189.21A15.81,15.81,0,0,0,194,94.46a14.82,14.82,0,0,0,4.68,3.06,14.28,14.28,0,0,0,5.62,1.12A14.6,14.6,0,0,0,211.2,97a15,15,0,0,0,5.3-4.65l2.66,1.8a18.53,18.53,0,0,1-6.37,5.51,17.83,17.83,0,0,1-8.53,2,18.14,18.14,0,0,1-13.07-5.4,18.35,18.35,0,0,1-5.29-13ZM204,67.82a14,14,0,0,0-5.29,1,14.33,14.33,0,0,0-4.43,2.81A15.7,15.7,0,0,0,191,75.89a15.14,15.14,0,0,0-1.66,5.25h28.23a14.6,14.6,0,0,0-1.41-5.25,15.22,15.22,0,0,0-3-4.25A13.67,13.67,0,0,0,209,68.83,12.55,12.55,0,0,0,204,67.82Z"
            />
            <path
              className="cls-1"
              d="M238.53,68.33a8.14,8.14,0,0,0-3.64.79,8.31,8.31,0,0,0-2.77,2.19,9.86,9.86,0,0,0-1.76,3.28,12.63,12.63,0,0,0-.62,4v22.54h-3.31V65.45h3.31v5.18a10,10,0,0,1,3.42-4,9.19,9.19,0,0,1,5.22-1.47h2.52v3.17Z"
            />
            <path
              className="cls-1"
              d="M241.48,83.23a18.43,18.43,0,0,1,1.4-7.16,18.65,18.65,0,0,1,3.82-5.84,17.92,17.92,0,0,1,5.72-3.92,17.71,17.71,0,0,1,7.13-1.44,16.2,16.2,0,0,1,6.66,1.4,17.67,17.67,0,0,1,10.41,16.24v1.66H244.79a16,16,0,0,0,1.48,5.69,15.61,15.61,0,0,0,3.27,4.6,15,15,0,0,0,4.68,3.06,14.31,14.31,0,0,0,5.62,1.12,14.66,14.66,0,0,0,7-1.69,15,15,0,0,0,5.29-4.65l2.66,1.8a18.53,18.53,0,0,1-6.37,5.51,17.83,17.83,0,0,1-8.53,2,18.14,18.14,0,0,1-13.07-5.4,18.35,18.35,0,0,1-5.29-13Zm18.07-15.41a14,14,0,0,0-5.29,1,14.45,14.45,0,0,0-4.43,2.81,16.12,16.12,0,0,0-3.24,4.25,15.34,15.34,0,0,0-1.65,5.25h28.22a14.39,14.39,0,0,0-1.4-5.25,15.25,15.25,0,0,0-3-4.25,13.51,13.51,0,0,0-4.17-2.81A12.56,12.56,0,0,0,259.55,67.82Z"
            />
            <path
              className="cls-1"
              d="M313.77,101.09V93.46a16.56,16.56,0,0,1-6.19,6.08,17.64,17.64,0,0,1-8.79,2.19,18.11,18.11,0,0,1-17-11.23,18.87,18.87,0,0,1-1.4-7.27,17.92,17.92,0,0,1,1.44-7.16,18.22,18.22,0,0,1,9.76-9.76,17.92,17.92,0,0,1,7.16-1.44,17.47,17.47,0,0,1,8.75,2.23,16.85,16.85,0,0,1,6.23,6.12V50.69h3.31v50.4Zm.07-17.86a15.27,15.27,0,0,0-1.19-6,15.43,15.43,0,0,0-8-8.2,14.05,14.05,0,0,0-5.83-1.23,14.21,14.21,0,0,0-5.9,1.23,15.07,15.07,0,0,0-4.76,3.31,15.88,15.88,0,0,0-3.2,4.89,15.8,15.8,0,0,0,0,12,16.31,16.31,0,0,0,3.2,4.93,14.31,14.31,0,0,0,4.76,3.31,14.67,14.67,0,0,0,5.9,1.19,14.49,14.49,0,0,0,5.87-1.22,15,15,0,0,0,4.79-3.32,15.88,15.88,0,0,0,3.2-4.89A15.4,15.4,0,0,0,313.84,83.23Z"
            />
            <path
              className="cls-1"
              d="M358.91,65.45l10.15,30.24,9-30.24h3.31l-10.8,35.64h-2.73l-10.51-31-10.44,31h-2.81l-10.8-35.64h3.38l9,30.09,10.15-30.09Z"
            />
            <path
              className="cls-1"
              d="M386.56,58a2.48,2.48,0,0,1-1.8-.79,2.55,2.55,0,0,1-.79-1.88,2.42,2.42,0,0,1,.79-1.83,2.51,2.51,0,0,1,3.6,0,2.42,2.42,0,0,1,.79,1.83,2.55,2.55,0,0,1-.79,1.88A2.48,2.48,0,0,1,386.56,58Zm1.58,43.13h-3.31V65.45h3.31Z"
            />
            <path
              className="cls-1"
              d="M400.6,99.43A5.76,5.76,0,0,1,398.8,95V68.47h-6.63v-3h6.63V54.57h3.31V65.45h8.71v3h-8.71V94.61a3.29,3.29,0,0,0,1,2.55,4.06,4.06,0,0,0,2.85.9h4.89v3h-5.33A6.92,6.92,0,0,1,400.6,99.43Z"
            />
            <path
              className="cls-1"
              d="M418.81,101.09H415.5V50.69h3.31V71.5a11.46,11.46,0,0,1,4.76-4.87,14.29,14.29,0,0,1,7.05-1.76,13.33,13.33,0,0,1,5.37,1,12.89,12.89,0,0,1,4.13,2.8,12.22,12.22,0,0,1,2.67,4.18,14.18,14.18,0,0,1,.93,5.15v23h-3.31V78.62a12.44,12.44,0,0,0-.72-4.28,10,10,0,0,0-2.05-3.42,9.16,9.16,0,0,0-3.24-2.23,10.85,10.85,0,0,0-4.21-.79,11.87,11.87,0,0,0-4.61.86,11,11,0,0,0-3.6,2.37,10.48,10.48,0,0,0-2.34,3.6,12.3,12.3,0,0,0-.83,4.54Z"
            />
            <path
              className="cls-1"
              d="M470,81.79a15.61,15.61,0,0,0,.94,5.51,12.63,12.63,0,0,0,2.59,4.25,11.32,11.32,0,0,0,3.93,2.7,12.84,12.84,0,0,0,4.93.93,12.09,12.09,0,0,0,8.82-3.7,12.62,12.62,0,0,0,2.63-4.22,15.27,15.27,0,0,0,.93-5.47V50.69h7.56v50.4h-7.49V96.41a17.42,17.42,0,0,1-5.94,4.1,18.82,18.82,0,0,1-7.38,1.44,20.08,20.08,0,0,1-7.52-1.4,17.7,17.7,0,0,1-6.05-4,18.81,18.81,0,0,1-4-6.23,21.4,21.4,0,0,1-1.48-8.1V50.69H470ZM508,50.69h7.27V69.05a19.34,19.34,0,0,1,5.83-4,17.57,17.57,0,0,1,7.2-1.48,18.94,18.94,0,0,1,7.56,1.52A19.06,19.06,0,0,1,546,75.31a19,19,0,0,1,1.51,7.49A18.68,18.68,0,0,1,546,90.25a19.22,19.22,0,0,1-10.23,10.19,18.94,18.94,0,0,1-7.56,1.51,17.77,17.77,0,0,1-7.23-1.47,19.16,19.16,0,0,1-5.87-4v4.61H508Zm7.13,32.11a12.55,12.55,0,0,0,3.67,9,13.13,13.13,0,0,0,4,2.74,12.3,12.3,0,0,0,5,1,12.05,12.05,0,0,0,4.89-1,12.76,12.76,0,0,0,4-2.74,13.32,13.32,0,0,0,2.7-4,12.3,12.3,0,0,0,1-5,12.6,12.6,0,0,0-1-5,13.22,13.22,0,0,0-2.7-4.06,11.88,11.88,0,0,0-4-2.7,12.65,12.65,0,0,0-13.82,2.7,13.15,13.15,0,0,0-2.74,4.06A12.59,12.59,0,0,0,515.08,82.8Zm35.35-.07a18.92,18.92,0,0,1,1.44-7.35,19.59,19.59,0,0,1,4-6.08,19.06,19.06,0,0,1,20.81-4.21,17.49,17.49,0,0,1,5.83,4,18.31,18.31,0,0,1,3.81,6,20.5,20.5,0,0,1,1.37,7.6V85.1H557.77a12.44,12.44,0,0,0,4.14,7.45,12.41,12.41,0,0,0,3.67,2.2,12,12,0,0,0,4.36.79,12.59,12.59,0,0,0,10.58-5.4L585.78,94a19.2,19.2,0,0,1-15.84,7.85,20.28,20.28,0,0,1-7.67-1.44,18.83,18.83,0,0,1-6.19-4,19.22,19.22,0,0,1-4.14-6.09A18.84,18.84,0,0,1,550.43,82.73ZM569.15,70A10.86,10.86,0,0,0,562,72.54a12.23,12.23,0,0,0-4.06,6.8h22.39a11.93,11.93,0,0,0-4-6.8A10.86,10.86,0,0,0,569.15,70Zm38,.87a7.64,7.64,0,0,0-5.9,2.52,9.73,9.73,0,0,0-2.3,6.84v20.88h-7.28V64.37h7.2V68.9a10.19,10.19,0,0,1,3.57-3.52,10,10,0,0,1,5.22-1.3h2.52v6.77Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
);

export default PoweredByUberDirectBadge;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RedcatApiHandler } from 'polygon-utils';
import { OrderingOperations, OrderingApi } from 'polygon-ordering';

import debug from '../utils/debug';
import { determineEndpoint } from '../utils/endpoint';

import { clearCookies } from '../thunks/clearCookies';

import { setApiHandlerReady } from '../slices/apiHandlerReady';

export const configureApiHandler = createAsyncThunk(
  'configureApiHandler',
  async (_data: undefined, { dispatch }) => {
    const endpoint = determineEndpoint();

    debug('api endpoint', { endpoint });

    RedcatApiHandler.endpointDeterminer = () => endpoint!;

    RedcatApiHandler.additionalParamsDeterminer = () => {
      const memberAuthTokenOverridePresent = localStorage.getItem('memberAuthToken') != null;

      // don't send auth cookie if an override exists
      return { credentials: memberAuthTokenOverridePresent ? 'omit' : 'same-origin' };
    };
    RedcatApiHandler.authTokenDeterminer = () => {
      // if override not present, cookies will be used instead
      return localStorage.getItem('memberAuthToken')!;
    };

    RedcatApiHandler.badAuthTokenHook = error => {
      debug(false, 'badAuthTokenHook');
      console.log('403 Unauthorised.', { error });
      console.log('CLEARING COOKIES');
      dispatch(clearCookies());
    };

    RedcatApiHandler.requestErrorHook = e => {
      console.error(e);
    };

    OrderingApi.setHandler(RedcatApiHandler);
    dispatch(OrderingOperations.setApiHandlerReady(true)); // TODO: remove this from OM
    dispatch(setApiHandlerReady(true));
  },
);

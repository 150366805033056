import moment from 'moment';
import calculateAvailabilityTime from './calculateDaypartMenuAvailabilityTime';

export const checkCategoryAvailability = (
  cat: Partial<Category | CategoryInfo> | undefined,
  dayInFull?: boolean,
): [boolean, string] => {
  if (!cat) return [true, ''];
  const { availability, days, start, end } = cat;
  const timeString = calculateAvailabilityTime(
    !!availability,
    days,
    start,
    end,
    dayInFull,
  );

  return [
    checkTimeAvailability(Boolean(availability), days, start, end),
    timeString,
  ];
};

export const checkTimeAvailability = (
  availability: boolean,
  days: Day[] = [],
  start: string | null = '',
  end: string | null = '',
  timeBracket: number = 0,
): boolean => {
  if (!availability) return true;
  if (!(days.length > 0) && !start && !end) return true;
  const currentDay = moment().format('dddd');

  if (
    days.length > 0 &&
    !days.includes(currentDay.toUpperCase() as unknown as Day)
  )
    return false;
  const date = `${moment().format('yyyy-MM-DD')}`;

  if (start && moment(`${date} ${start}`).diff(moment()) > 0) return false;

  if (end && moment().diff(moment(`${date} ${end}`), 'minute') > timeBracket)
    return false;
  return true;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../actions/currentOrder';

const initialState = null as HistoricalOrder | null;

const slice = createSlice({
  name: '$singleOrderDetails',
  initialState,
  reducers: {
    setSingleOrderDetails(
      state,
      action: PayloadAction<HistoricalOrder | null>,
    ) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      const { preserveMember } = action.payload;
      return preserveMember ? state : initialState;
    });
  },
});

export const { setSingleOrderDetails } = slice.actions;
export default slice.reducer;

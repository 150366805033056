import React from 'react';
import ScreenHero from './ScreenHero';
import { useAppSelector } from '../app/hooks';
import { Col } from 'reactstrap';
import LoyaltyTitle from './LoyaltyTitle';
import { useLocation } from 'react-router-dom';
import LoyaltySubTitle from './LoyaltySubTitle';
import { v4 as uuid } from 'uuid';
import getThemeLookup from '../selectors/getThemeLookup';
import LoyaltyTier from './LoyaltyTier';
import { REWARDS_SCREEN_ROUTE } from '../screens/RewardsScreen';
import { getLoyaltyDropdownMenu } from '../utils/getDropdownMenu';
import combineStyles from '../utils/combineStyles';

const LoyaltyWrapper = ({
  children,
  hideHero,
  showBackOnMobile,
}: {
  children: React.ReactNode;
  hideHero?: boolean;
  showBackOnMobile?: boolean;
}) => {
  const deviceType = useAppSelector(state => state.deviceType);
  const { pathname } = useLocation();
  const p = useAppSelector(getThemeLookup);
  const screens = getLoyaltyDropdownMenu();
  const enableTieredLoyalty = useAppSelector(state => state.config.enableTieredLoyalty);

  return (
    <ScreenHero disablePSB={true} forceHideHero={hideHero} showBackOnMobile={showBackOnMobile}>
      <div
        className={`d-flex ${deviceType === 1 ? 'pb-5' : ''}`}
        style={{
          backgroundColor:
            deviceType === 1
              ? p('loyaltyBackground', ['backgroundColor']).backgroundColor
              : p('loyaltyMobileBackground', ['backgroundColor']).backgroundColor,
          minHeight: '100%',
        }}
      >
        <div className="container d-flex flex-grow-1">
          <div
            className="row d-flex flex-grow-1"
            style={{ paddingTop: deviceType === 1 ? (hideHero ? 50 : 100) : '' }}
          >
            <div className="mx-auto d-flex flex-grow-1" style={{ maxWidth: 660, padding: 0 }}>
              <div
                className="d-flex flex-grow-1"
                style={{
                  borderRadius: deviceType === 1 ? 5 : 0,
                  backgroundColor: p('loyaltyModals', ['backgroundColor']).backgroundColor,
                }}
              >
                {enableTieredLoyalty && pathname === REWARDS_SCREEN_ROUTE && <LoyaltyTier />}

                <div
                  className={`pb-5 ${
                    deviceType === 1 ? 'pt-3' : 'px-4'
                  } d-flex flex-grow-1 flex-column`}
                  style={combineStyles(
                    {
                      maxWidth: 500,
                      margin: '0 auto',
                    },
                    p('loyaltyCardBackground', ['backgroundColor']),
                  )}
                >
                  {screens.map(screen =>
                    pathname === screen.path
                      ? screen.title && (
                          <div key={uuid()}>
                            <LoyaltyTitle title={screen.title} />
                            {screen.subTitle && <LoyaltySubTitle title={screen.subTitle} />}
                          </div>
                        )
                      : screen.subRoutes?.map(
                          route =>
                            route.path === pathname && (
                              <div key={uuid()}>
                                <LoyaltyTitle title={screen.title!} />
                                {route.subTitle && <LoyaltySubTitle title={route.subTitle} />}
                              </div>
                            ),
                        ),
                  )}

                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScreenHero>
  );
};

export default LoyaltyWrapper;

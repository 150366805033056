import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import ModalHeader from '../components/ModalHeader';
import { setCurrentModal } from '../thunks/setCurrentModal';
import BadgeOptions from '../components/BadgeOptions';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { determineLocationSessions, OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import lodash from 'lodash';
import getLocationsWithDistance from '../selectors/getLocationsWithDistance';
import StandardButton from '../components/StandardButton';
import { Modal } from 'reactstrap';
import LocationSearch from '../components/LocationSearch';
import { Formik } from 'formik';
import Locations from '../components/Locations';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import LocationListItem from '../components/LocationListItem';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import TableNumberReview from '../components/TableNumberReview';
import { adjustOrder } from '../thunks/adjustOrder';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';

export const LOCATION_MODAL_ID = 'LOCATION_MODAL_ID';

const { fetchLocations, setTable, updateKeyOrderProperty } = OrderingOperations;
const { getOrderingWindowPadding, getKeyOrderPropertyUpdateInProgress } = OrderingSelectors;

const LocationModal = ({ params }: { params: ModalDataParams }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { saleType, locationId, action } = params;
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const keyOrderPropertyUpdateInProgress = useAppSelector(getKeyOrderPropertyUpdateInProgress);
  const orderingWindowPadding = useAppSelector(getOrderingWindowPadding);
  const [filteredLocations, setFilteredLocations] = useState<MapLocation[]>([]);
  const allLocations = useAppSelector(getLocationsWithDistance);
  const [page, setPage] = useState(action ? 2 : 1);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [allEnhancedLocations, setAllEnhancedLocations] = useState<MapLocation[]>([]);
  const p = useAppSelector(getThemeLookup);

  useEffect(() => {
    // I hate this, this is a hack fix that I did, something must have changed in merges, TODO: refactor everything with less boilerplate (600 hours)
    dispatch(updateKeyOrderProperty({}));
    dispatch(fetchLocations({}));
  }, []);

  useEffect(() => {
    syncAddress();
  }, [allLocations]);

  const syncAddress = async () => {
    const enhancedLocations = lodash.mapValues(allLocations, l => ({
      ...l,
      ...determineLocationSessions(l, orderingWindowPadding, saleType),
    })) as EnhancedLocations;

    const locationsFilteredBySaleType = lodash.pickBy(enhancedLocations, l =>
      l?.supportedSaleTypes?.includes(saleType as SALE_TYPE),
    );

    let locationsArray = lodash.orderBy(
      Object.keys(locationsFilteredBySaleType).map(id => ({
        ...enhancedLocations[id],
      })),
      ['distance'],
      ['asc'],
    );

    const nonFilterLocations = Object.keys(allLocations).map(id => ({ ...enhancedLocations[id] }));

    locationsArray = locationsArray.sort((a: any, b: any) => b?.online - a?.online);
    setFilteredLocations(locationsArray);
    setAllEnhancedLocations(nonFilterLocations);
  };

  return (
    <Modal
      isOpen={true}
      scrollable
      fullscreen={deviceTypeMobile ? true : false}
      toggle={() => dispatch(setCurrentModal(null))}
      // size="lg"
      fade={true}
      modalTransition={{
        baseClass: 'rc-scale-in',
      }}
    >
      <Formik
        initialValues={{
          formatted_address: '',
          listView: true,
          locationId: (locationId as string) || '',
          resetMapLocation: false,
          table: '',
        }}
        onSubmit={() => {}}
      >
        {({ values, setFieldValue }) => {
          const selectedLocation = filteredLocations.filter(
            location => location.id === values.locationId,
          )[0];

          return (
            <>
              <div
                style={combineStyles(
                  { width: '100%', marginBottom: 10 },
                  p('locationModalBackground', ['backgroundColor']),
                )}
              >
                <ModalHeader
                  backClick={() => {
                    if (action) {
                      dispatch(setCurrentModal(null));
                    } else {
                      if (
                        (saleType === SALE_TYPE.TABLE_ORDER || saleType === SALE_TYPE.DINE_IN) &&
                        page !== 1
                      ) {
                        setPage(page => page - 1);
                      } else {
                        dispatch(setCurrentModal(null));
                      }
                    }
                  }}
                  title={t(`saleType.${saleType}.name`)}
                />
                {page === 1 && (
                  <div style={deviceTypeMobile ? styles.badgeOptionsMobile : styles.badgeOptions}>
                    <LocationSearch />

                    <BadgeOptions
                      active={values.listView}
                      setActive={status => setFieldValue('listView', status)}
                      activeKey="button.listView"
                      inactiveKey="button.mapView"
                    />
                  </div>
                )}
              </div>
              <div
                style={combineStyles(
                  {
                    overflow: 'overlay',
                    height: '100vh',
                    paddingBottom: 100,
                  },
                  p('locationModalBackground', ['backgroundColor']),
                )}
              >
                {page === 1 && <Locations locations={filteredLocations} />}
                {page === 2 && (
                  <TableNumberReview
                    location={
                      allEnhancedLocations.filter(
                        location => location.id === String(values.locationId),
                      )[0]
                    }
                  />
                )}
              </div>

              <div className="position-absolute" style={{ bottom: 0, width: '100%', padding: 25 }}>
                {!values.listView && values.locationId !== '' && (
                  <LocationListItem
                    activateBgColor={values.listView && values.locationId === selectedLocation.id}
                    location={
                      filteredLocations.filter(location => location.id === values.locationId)[0]
                    }
                  />
                )}
                <StandardButton
                  label={t('button.continue')}
                  themeKey="continueButton"
                  onClick={async () => {
                    if (action) {
                      setRequestInProgress(true);
                      dispatch(setTable(values.table));
                      action();
                    } else {
                      if (saleType === SALE_TYPE.TABLE_ORDER || saleType === SALE_TYPE.DINE_IN) {
                        if (page === 1) {
                          setPage(page + 1);
                        } else {
                          dispatch(setTable(values.table));
                          setRequestInProgress(true);
                          dispatch(
                            adjustOrder({
                              saleType,
                              locationId: (locationId as string) || values.locationId,
                            }),
                          ).catch(() => setRequestInProgress(false));

                          logEvent(EVENTS.PICK_LOCATION_AND_SALE_TYPE, {
                            label: `ST:${saleType} - LOC:${values.locationId}`,
                          });
                          logEvent(EVENTS.SET_TABLE_NUMBER);
                        }
                      } else {
                        dispatch(adjustOrder({ saleType, locationId: values.locationId }));
                        logEvent(EVENTS.PICK_LOCATION_AND_SALE_TYPE, {
                          label: `ST:${saleType} - LOC:${values.locationId}`,
                        });
                      }
                    }
                  }}
                  disabled={
                    saleType === SALE_TYPE.TABLE_ORDER || saleType === SALE_TYPE.DINE_IN
                      ? page !== 1
                        ? values.table === '' || requestInProgress
                        : !filteredLocations.filter(
                            location => location.id === values.locationId,
                          )[0]?.online || keyOrderPropertyUpdateInProgress
                      : saleType === SALE_TYPE.CATERING
                      ? values.locationId === '' || keyOrderPropertyUpdateInProgress
                      : !filteredLocations.filter(location => location.id === values.locationId)[0]
                          ?.online || keyOrderPropertyUpdateInProgress
                  }
                  showSpinner={keyOrderPropertyUpdateInProgress}
                  containerStyle={{ marginLeft: '10%', marginRight: '10%', borderRadius: 5 }}
                />
              </div>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default LocationModal;

const styles: Styles = {
  modalContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'relative',
  },
  modalMobileContainer: {
    // https://stackoverflow.com/a/30523342/8706204
    WebkitMaskImage: '-webkit-radial-gradient(white, black)',
    borderRadius: 0,
  },
  badgeOptions: {
    marginLeft: '10%',
    marginRight: '10%',
    gap: '0.5em',
    flexDirection: 'column',
    display: 'flex',
  },
  badgeOptionsMobile: {
    marginLeft: 10,
    marginRight: 10,
    gap: '1em',
    flexDirection: 'column',
    display: 'flex',
  },
};

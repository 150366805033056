import React, { useState } from 'react';
import lodash from 'lodash';
import * as Yup from 'yup';
import setLocalValue from '../utils/setLocalValue';
import combineStyles from '../utils/combineStyles';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { logEvent } from '../utils/analytics';
import { IN_DEVELOPMENT } from '../constants';
import EVENTS from '../constants/events';
import redcatApiFetch from '../api';
import getThemeLookup from '../selectors/getThemeLookup';
import getRegisterProfileFields from '../selectors/getRegisterProfileFields';
import getRegisterProfileSchema from '../selectors/getRegisterProfileSchema';
import getRegisterProfileInitialValues from '../selectors/getRegisterProfileInitialValues';
import getConfig from '../selectors/getConfig';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';
import StandardButton from '../components/StandardButton';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { registerUser } from '../utils/analytics';
import { OrderingSelectors } from 'polygon-ordering';
import { Formik, Form, FormikHelpers } from 'formik';
import RegistrationFields from '../components/RegistrationFields';
import { syncMember } from '../thunks/syncMember';
import { MESSAGE_MODAL_ID } from '../modals/MessageModal';
import FormTextField from '../components/FormTextField';
import { useLocation } from 'react-router-dom';
import { INPUT_FIELDS } from '../constants/inputfields';
import ScreenLink from './ScreenLink';

const { getOrderTotals, getStagedPurchases } = OrderingSelectors;

const RegisterForm = () => {
  const { t } = useTranslation();
  const pathname = useLocation();
  const registrationFields = useAppSelector(getRegisterProfileFields);
  const registratonFieldSchema = useAppSelector(getRegisterProfileSchema);
  const registrationFieldInitialValues = useAppSelector(state =>
    getRegisterProfileInitialValues(state, pathname),
  );
  const config = useAppSelector(getConfig);
  const dispatch = useAppDispatch();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const { enableReCaptcha, reCaptchaSiteKey } = config;
  const orderTotals = useAppSelector(getOrderTotals);
  const stagedPurchases = useAppSelector(getStagedPurchases);

  //recaptcha settings
  const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);
  //tfa
  const [mfaToken, setMfaToken] = useState(null);
  const onReCaptchaChange = (reCaptchaToken: string | null) => {
    setReCaptchaToken(reCaptchaToken);
  };

  const onReCaptchaError = () => {
    clearReCaptchaToken();

    enqueueErrorSnackbar(t('problemWithReCaptchaMessage'));
  };

  const clearReCaptchaToken = () => setReCaptchaToken(null);

  const confirmRegistrationFieldMode = registrationFields?.visible.find(
    field => field.id === 'ConfirmPassword',
  )?.mode;

  const submit = (
    values: Partial<FormikFields>,
    { setSubmitting, resetForm }: FormikHelpers<FormikFields>, //{ setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    let sentValues = {
      ...values,
      ...(values['RegistrationCode'] ? { RegistrationCode: values['RegistrationCode'] } : {}),
      ...(values['Referrer'] ? { Referrer: values['Referrer'] } : {}),
      ...(confirmRegistrationFieldMode === 'optional' ? { ConfirmPassword: values.Password } : {}),
    };

    const MOBILE_REG_NZ = /(^02|^\+642|^03|^\+643)([\d]{7,10})$/;
    const regNzMobile = new RegExp(MOBILE_REG_NZ);
    const { Mobile } = sentValues;
    if (Mobile) {
      if (regNzMobile.test(Mobile)) {
        if (String(Mobile).startsWith('0')) {
          sentValues = { ...sentValues, Mobile: '+64' + String(Mobile).slice(1) };
        }
      }
    }

    if (values['Terms' as keyof FormikFields] === true)
      delete sentValues['Terms' as keyof FormikFields];
    //register api call
    redcatApiFetch({
      path: 'api/v1/register',
      method: 'POST',
      body: mfaToken
        ? {
            ...lodash.omit(sentValues, 'mfaCode'),
            'g-recaptcha-response': reCaptchaToken,
            //@ts-ignore
            tfa_pin: sentValues.mfaCode,
            tfa_token: mfaToken,
          }
        : lodash.omit({ ...sentValues, 'g-recaptcha-response': reCaptchaToken }, 'mfaCode'),
    })
      .then((result: any) => {
        setMfaToken(null);
        registerUser(
          'native',
          result.success,
          orderTotals,
          stagedPurchases.map(purchaseItem => {
            return {
              ...purchaseItem.item,
              totalPrice: purchaseItem.moneyPrice,
            };
          }),
          result.data,
        );

        //register successfully
        resetForm({ values });
        setSubmitting(false);
        logEvent(EVENTS.MEMBER_REGISTER); // log event
        clearReCaptchaToken();

        if (IN_DEVELOPMENT && result.token) {
          setLocalValue('memberAuthToken', result.token);
        }

        dispatch(syncMember());
        dispatch(
          setCurrentModal({
            modalId: MESSAGE_MODAL_ID,
            params: {
              title: t('registration.verificationReminderModal.title'),
              message: t('registration.verificationReminderModal.message'),
              buttonText: t('button.continue'),
            },
          }),
        );
      })
      .catch(e => {
        setSubmitting(false);
        clearReCaptchaToken();
        const data = lodash.get(e, 'details.json', {});
        if (data.error_code === 3) {
          setMfaToken(data.additional_info);
          enqueueSuccessSnackbar('Verification PIN required');
        } else {
          logEvent(EVENTS.MEMBER_REGISTER_FAILED);
          enqueueErrorSnackbar(e);
        }
      });
  };

  // Checking whether we need confirm password
  const isConfirmPasswordRequired =
    registrationFields &&
    registrationFields!.visible.find(field => field.id === INPUT_FIELDS.ConfirmPassword);

  return (
    <div style={styles.innerContainer}>
      <Formik
        validationSchema={
          !mfaToken
            ? registratonFieldSchema!.visible
            : registratonFieldSchema?.visible?.shape({
                mfaCode: Yup.string().required('Required').min(4, 'Too Short'),
              })
        }
        initialValues={{ ...registrationFieldInitialValues.visible, mfaCode: '' }}
        //@ts-ignore
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({ handleSubmit, handleChange, isSubmitting, isValid, dirty }) => {
          return (
            <Form onSubmit={handleSubmit} style={styles.registrationForm}>
              <>
                {!mfaToken
                  ? registrationFields!.visible.map((field: RegisterFields, index: any) => (
                      <RegistrationFields
                        key={index}
                        field={field}
                        showStrengthBar={
                          isConfirmPasswordRequired
                            ? field.id === INPUT_FIELDS.ConfirmPassword
                            : field.id === INPUT_FIELDS.Password
                        }
                      />
                    ))
                  : registrationFields!.visible.map((field: RegisterFields, index: any) => (
                      <RegistrationFields
                        key={index}
                        field={{ ...field, editMode: 'hidden' }}
                        showStrengthBar={
                          isConfirmPasswordRequired
                            ? field.id === INPUT_FIELDS.ConfirmPassword
                            : field.id === INPUT_FIELDS.Password
                        }
                      />
                    ))}
                {Boolean(mfaToken) && (
                  <FormTextField
                    id="mfaCode"
                    name="mfaCode"
                    type="mfaCode"
                    label={t('field.signIn.mfaCode')}
                    required
                    onChange={handleChange}
                  />
                )}
                {enableReCaptcha && !reCaptchaToken && (
                  <div style={styles.captchaContainer}>
                    <ReCAPTCHA
                      sitekey={String(reCaptchaSiteKey)}
                      onChange={onReCaptchaChange}
                      onErrored={onReCaptchaError}
                      size={deviceTypeMobile ? 'compact' : 'normal'}
                    />
                  </div>
                )}

                <StandardButton
                  label={t(mfaToken ? 'verify' : 'button.register')}
                  containerStyle={styles.button}
                  disabled={Boolean(isSubmitting || !isValid || !dirty)}
                  themeKey="signInSubmitButton"
                  onClick={handleSubmit}
                />

                <ScreenLink
                  label={t('link.signIn.prefix')}
                  actionLabel={t('link.signIn.action')}
                  url="#"
                  onClick={() => dispatch(setCurrentModal(SIGN_IN_MODAL_ID))}
                  themeKeyLabel="signInLink"
                  themeKeyActionLabel="signInLinkRight"
                />
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const styles: Styles = {
  passwordStrengthBar: {
    marginTop: '1rem',
    fontSize: '0.7rem',
  },
  modalDesktopContainer: {
    padding: '50px 70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textTransform: 'uppercase',
  },
  innerContainer: {
    minHeight: 'min-content',
    marginTop: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    justifyContent: 'space-between',
  },
  registrationForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  button: {
    color: 'white',
    textTransform: 'uppercase',
    // padding: '15px',
    height: 40,
    marginTop: '1.5rem',
    cursor: 'pointer',
    marginBottom: '1rem',
  },
  link: {
    marginTop: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: '0.8rem',
  },
};

export default RegisterForm;

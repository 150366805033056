import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import TouchableOpacity from '../components/TouchableOpacity';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { BiSliderAlt } from 'react-icons/bi';
import { PAST_ORDERS_SCREEN_ROUTE } from '../screens/PastOrdersScreen';
import { showLoyaltyHeaders } from '../slices/loyaltyHeaders';
import { useLocation } from 'react-router-dom';
import { FILTERS_MODAL_ID } from '../modals/FiltersModal';
import getThemeLookup from '../selectors/getThemeLookup';

const LoyaltyHeaderIcon = () => {
  const deviceType = useAppSelector(state => state.deviceType);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const showFilterIcon = pathname === PAST_ORDERS_SCREEN_ROUTE ? true : false;
  const loyaltyHeaders = useAppSelector(state => state.loyaltyHeaders);
  const currentModal = useAppSelector(state => state.currentModalId);
  const p = useAppSelector(getThemeLookup);

  useEffect(() => {
    if (deviceType === 1) {
      dispatch(showLoyaltyHeaders(false));
    } else {
      dispatch(showLoyaltyHeaders(true));
    }
  }, [deviceType]);

  return (
    <div className={`loyaltyHeaderBar`}>
      {loyaltyHeaders && deviceType === 2 && showFilterIcon && deviceType === 2 && (
        <TouchableOpacity
          onClick={() => {
            if (currentModal === FILTERS_MODAL_ID) {
              dispatch(setCurrentModal(null));
            } else {
              dispatch(setCurrentModal(FILTERS_MODAL_ID));
            }
          }}
        >
          <BiSliderAlt style={{ fontSize: 24, color: p('headerButton', ['color']).color }} />
        </TouchableOpacity>
      )}
    </div>
  );
};

export default LoyaltyHeaderIcon;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setPurchaser } from '../../../actions/currentOrder';

const initialState = null as string | null;

const slice = createSlice({
  name: '$purchaserName',
  initialState,
  reducers: {
    setPurchaserName(state, action: PayloadAction<string | null>) {
      return action.payload;
    },
    adjustPurchaserName(
      state,
      action: PayloadAction<'clear' | 'delete' | string>,
    ) {
      if (action.payload === 'clear') {
        return initialState;
      } else if (action.payload === 'delete') {
        return (state || '').slice(0, -1);
      } else {
        return `${state || ''}${action.payload}`.trimStart();
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(setPurchaser, (state, action) => {
      return action.payload.name;
    });
  },
});

export const { setPurchaserName, adjustPurchaserName } = slice.actions;
export default slice.reducer;

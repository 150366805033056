import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { RiArrowRightFill } from 'react-icons/ri';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import {
  OrderingSelectors,
  OrderingConstants,
  OrderingOperations,
  OrderingHooks,
} from 'polygon-ordering';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getExtremelyShortScreen from '../selectors/getExtremelyShortScreen';
import getAdditionalDeliveryStatusesEnabled from '../selectors/getAdditionalDeliveryStatusesEnabled';
import getHideTimes from '../selectors/getHideTimes';
import { setCurrentModal } from '../thunks/setCurrentModal';
import combineStyles from '../utils/combineStyles';
import { logEvent } from '../utils/analytics';
import { SALE_TYPE_ICONS } from '../constants/saleTypes';
import EVENTS from '../constants/events';
import { INITIAL_SCREEN_ROUTE } from '../hooks/useRoutes';
import ScreenHero from '../components/ScreenHero';
import ScreenFloating from '../components/ScreenFloating';
import NewOrderButton from '../components/NewOrderButton';
import Text from '../components/Text';
import OrderStatus from '../components/OrderStatus';
import PoweredByDoordashDriveBadge from '../components/PoweredByDoordashDriveBadge';
import PoweredByUberDirectBadge from '../components/PoweredByUberDirectBadge';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import StandardButton from '../components/StandardButton';
import ViewOrderDetails from '../components/ViewOrderDetails';
import { deleteFavouriteOrder } from '../libs/polygon-ordering/src/operations';
import { FAVOURITE_NAME_MODAL } from '../modals/FavouriteNameModal';
import getHistoricalOrders from '../libs/polygon-ordering/src/selectors/getHistoricalOrders';
import getSingleOrderDetails from '../libs/polygon-ordering/src/selectors/getSingleOrderDetails';
import getOrderInProgress from '../libs/polygon-ordering/src/selectors/getOrderInProgress';
import RadioCheck from '../components/RadioCheck';
import { REGISTER_MODAL_ID } from '../modals/RegisterModal';

const {
  getSaleDetails,
  getStagedPurchases,
  getSaleType,
  getReceiptItems,
  getTable,
  getDeliveryProvider,
  getMember,
  getLocation,
  getDeliveryAddressString,
  getFavouriteOrders,
  getStagedPurchasesAsShadowItems,
  getOrderTotals,
} = OrderingSelectors;

const { SALE_STATUSES_MAP, READY_TO_COLLECT, PROCESSED_AT_STORE, DELIVERED, DELIVERY_PROVIDERS } =
  OrderingConstants;
const { fetchSaleStatus, setSaleStatus, createFavouriteOrder } = OrderingOperations;

const { useFormattedCurrency } = OrderingHooks;

const CompletedOrderScreen = () => {
  // TODO: Get some confirmation on the comment below
  const FETCH_STATUS_INTERVAL_DURATION = 10000; // should this be configurable?

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const saleDetails = useAppSelector(getSaleDetails);
  const saleType = useAppSelector(getSaleType);
  const receiptItems = useAppSelector(getReceiptItems);
  const table = useAppSelector(getTable);
  const deliveryProvider = useAppSelector(getDeliveryProvider);
  const member = useAppSelector(getMember);
  const additionalDeliveryStatusesEnabled = useAppSelector(getAdditionalDeliveryStatusesEnabled);
  const extremelyShortScreen = useAppSelector(getExtremelyShortScreen);
  const hideTimes = useAppSelector(getHideTimes);
  const tableNumberLabel = useAppSelector(state => state.config.tableNumberLabel);
  const location = useAppSelector(getLocation);
  const deliveryAddress = useAppSelector(getDeliveryAddressString);
  const favouriteOrders = useAppSelector(getFavouriteOrders);
  const orderTotals = useAppSelector(getOrderTotals);

  const loyaltyMemberCardsetId = useAppSelector(state => state.config.loyaltyMemberCardset);
  const cardsets = useAppSelector(state => state.ordering.config.cardsets);
  const loyaltyMemberCardset =
    (loyaltyMemberCardsetId &&
      Object.values(cardsets || {}).find(
        cardset => cardset.alphaPrefix === loyaltyMemberCardsetId,
      )) ||
    undefined;

  const _orderTotals =
    (loyaltyMemberCardset &&
      loyaltyMemberCardset.accumulatePoints &&
      loyaltyMemberCardset.pointsAwardRate &&
      orderTotals?.moneyPrice &&
      // add in the rewards award as if the user was logged in
      !orderTotals.rewardsAward && {
        ...orderTotals,
        rewardsAward: Math.round(orderTotals?.moneyPrice / loyaltyMemberCardset?.pointsAwardRate),
      }) ||
    orderTotals;

  const showPointsPotential = !!_orderTotals?.pointsAward || !!_orderTotals?.rewardsAward;
  const rewardsCurrencyString = useFormattedCurrency({
    cents: _orderTotals?.rewardsAward,
    hideSymbol: true,
  });
  const pointsEarnedString =
    (!!_orderTotals?.rewardsAward && rewardsCurrencyString) ||
    ((_orderTotals?.pointsAward ?? 0) * 100).toString();
  const loyaltyPointsName = useAppSelector(state => state.config.loyaltyPointsName);
  const shadowPurchases = useAppSelector(getStagedPurchasesAsShadowItems);

  const fetchStatusInterval = useRef<NodeJS.Timeout | null>(null);
  const readyInterval = useRef<NodeJS.Timeout | null>(null);

  const [viewOrderDetails, setViewOrderDetails] = useState(false);

  const orderHistory = useAppSelector(getHistoricalOrders);
  const guestOrderingOrderDetails = useAppSelector(getSingleOrderDetails);

  const orderInProgress = useAppSelector(getOrderInProgress);
  const orderDetails = orderInProgress || saleDetails;

  const fetchStatus = () => {
    if (!saleDetails) {
      return;
    }

    if (saleType === SALE_TYPE.CATERING && saleDetails.items?.length !== 0) {
      return;
    }

    if (
      saleDetails.status <
        SALE_STATUSES_MAP[additionalDeliveryStatusesEnabled ? DELIVERED : PROCESSED_AT_STORE] ||
      saleType === SALE_TYPE.CATERING
    ) {
      dispatch(fetchSaleStatus({ authenticationMethod: Boolean(member) ? 'member' : 'none' }));
    } else {
      clearInterval(fetchStatusInterval.current as NodeJS.Timeout);

      if (
        !additionalDeliveryStatusesEnabled &&
        saleDetails.status !== SALE_STATUSES_MAP[READY_TO_COLLECT]
      ) {
        readyInterval.current = setInterval(checkIfReady, 1000);
      }
    }
  };

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    fetchStatusInterval.current = setInterval(fetchStatus, FETCH_STATUS_INTERVAL_DURATION);
    fetchStatus();

    setTimeout(() => {
      setIsMounted(true);
    }, 350);

    return () => {
      clearInterval(fetchStatusInterval.current as NodeJS.Timeout);
      clearInterval(readyInterval.current as NodeJS.Timeout);
    };
  }, []);

  const checkIfReady = () => {
    if (saleDetails!.pickupTime && moment(saleDetails!.pickupTime) <= moment()) {
      //@ts-ignore
      dispatch(setSaleStatus(SALE_STATUSES_MAP[READY_TO_COLLECT]));
      clearInterval(readyInterval.current as NodeJS.Timeout);
    }
  };

  const isDelivery = saleType === SALE_TYPE.DELIVERY;
  const isPickupOrTakeAway =
    saleType === SALE_TYPE.PICKUP ||
    saleType === SALE_TYPE.TAKE_AWAY ||
    saleType === SALE_TYPE.WEB_ORDERING ||
    saleType === SALE_TYPE.CATERING;
  const isDineIn = saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER;

  const ScreenComponent = deviceTypeMobile || extremelyShortScreen ? ScreenHero : ScreenFloating;

  const DeliveryIconComponent = SALE_TYPE_ICONS[SALE_TYPE.DELIVERY];
  const deliveryTrackingLinkIconStyle = combineStyles(
    p('deliveryTrackingLink', ['color', 'fontSize']),
    styles.deliveryTrackingLinkIcon,
  );

  const completedOrderMessage = t(`completedOrderMessage.${saleType}`);

  const historicalOrderItems =
    orderDetails && (member ? shadowPurchases : guestOrderingOrderDetails?.items);

  const tempFavouriteOrders = useRef(favouriteOrders || []);
  const newFavouritedOrder = useRef(favouriteOrders ? favouriteOrders[0] : null);

  const isOrderFavourited = useMemo(() => {
    let favouritesIncreased =
      favouriteOrders && favouriteOrders?.length > tempFavouriteOrders?.current.length;
    if (favouritesIncreased && favouriteOrders) {
      newFavouritedOrder.current = favouriteOrders[0];
    }

    return favouritesIncreased;
  }, [favouriteOrders]);

  if (!saleDetails) {
    return <Redirect to={INITIAL_SCREEN_ROUTE} />;
  }
  if (viewOrderDetails) {
    return <ViewOrderDetails setViewOrderDetails={setViewOrderDetails} />;
  }

  return (
    <React.Fragment>
      <ScreenComponent
        forceHideHero={true}
        parentContainerStyle={
          deviceTypeMobile ? styles.mobileContentContainer : styles.desktopContentContainer
        }
      >
        <div
          style={combineStyles(
            styles.mainContainer,
            !deviceTypeMobile ? styles.mainContainerDesktop : styles.mainContainerMobile,

            p('screen', ['backgroundColor']),
            p('completedOrderScreen', ['backgroundColor']),

            !deviceTypeMobile && p('completedOrderScreenDesktop', ['backgroundColor', 'boxShadow']),
            deviceTypeMobile && p('completedOrderScreenMobile', ['backgroundColor']),
          )}
        >
          {saleType !== SALE_TYPE.CATERING && <OrderStatus />}

          {Boolean(completedOrderMessage) && (
            <div style={styles.completedOrderMessageContainer}>
              <Text themeKey="completedOrderMessage" style={styles.completedOrderMessage}>
                {completedOrderMessage}
              </Text>
            </div>
          )}

          <div style={styles.detailsSection}>
            {isDelivery && Boolean(saleDetails.deliveryTrackingUrl) && (
              <>
                <Text
                  themeKey="deliveryTrackerAccuracyMessage"
                  style={styles.deliveryTrackerAccuracyMessage}
                >
                  {t('deliveryTrackerAccuracyMessage')}
                </Text>

                <Text
                  themeKey="deliveryTrackingLink"
                  style={styles.deliveryTrackingUrl}
                  href={saleDetails.deliveryTrackingUrl}
                  openInNewTab
                  onClick={() => logEvent(EVENTS.OPEN_DELIVERY_TRACKER)}
                >
                  <DeliveryIconComponent style={deliveryTrackingLinkIconStyle} />
                  {t('deliveryTrackingLink')}
                  <RiArrowRightFill style={deliveryTrackingLinkIconStyle} />
                </Text>

                {deliveryProvider?.id === DELIVERY_PROVIDERS.DOORDASH_DRIVE && (
                  <PoweredByDoordashDriveBadge />
                )}

                {deliveryProvider?.id === DELIVERY_PROVIDERS.UBER_DIRECT && (
                  <PoweredByUberDirectBadge />
                )}
              </>
            )}

            <Text themeKey="completedOrderTitle" style={styles.title} block>
              {t('title.orderComplete')}
            </Text>

            {/* This radio check used to be 40 in size like in the Figma
             but having it the same width as the rest makes the body feel
              disproportionate  */}
            <RadioCheck size={70} checked={isMounted}></RadioCheck>

            <div style={styles.orderCompleteDetails}>
              {!hideTimes && !isDineIn && (
                <Text themeKey="orderReadyTime" style={styles.orderCompleteDetailsText}>
                  {t('title.orderReadyTime') +
                    moment(saleDetails.pickupTime).format(
                      saleType === SALE_TYPE.CATERING ? 'Do MMMM YYYY h:mm A' : 'h:mm A',
                    )}
                </Text>
              )}
              {Boolean(saleDetails.folioId) && (
                <Text
                  themeKey="completedOrderHeading"
                  style={styles.orderCompleteDetailsText}
                  block
                >
                  {t('title.orderNumber') + ` ${saleDetails.folioId}`}
                </Text>
              )}
              {(isPickupOrTakeAway || isDineIn) && location && (
                <Text
                  themeKey="completedOrderHeading"
                  style={styles.orderCompleteDetailsText}
                  block
                >
                  {isDineIn ? location.name : t('title.pickUpLocation') + ` ${location.name}`}
                </Text>
              )}
              {/* Delivery address to be tested */}
              {isDelivery && deliveryAddress && (
                <Text
                  themeKey="completedOrderHeading"
                  style={styles.orderCompleteDetailsText}
                  block
                >
                  {t('title.deliveringToAddress') + ` ${deliveryAddress}`}
                </Text>
              )}

              {isDineIn && Boolean(table) && (
                <>
                  <Text themeKey="completedOrderHeading" style={styles.title} block>
                    {tableNumberLabel || t('tableNumber')}
                  </Text>
                  <Text themeKey="completedOrderDetail">{table}</Text>
                </>
              )}
            </div>

            <StandardButton
              themeKey="viewOrderDetailsButton"
              label={t('title.viewOrderDetails')}
              containerStyle={styles.icon}
              onClick={() => setViewOrderDetails(true)}
            />

            {!member && (
              <>
                <Text themeKey="joinLoyaltyTitle" style={styles.joinLoyaltyTitle} block>
                  {t('joinLoyaltyProgram')}
                </Text>

                {showPointsPotential && (
                  <Text
                    themeKey="joinLoyaltyCallMessage"
                    style={styles.joinLoyaltyCallMessage}
                    block
                  >
                    {t('joinLoyaltyCallMessage', {
                      pointsEarned: pointsEarnedString,
                      pointsName: (
                        loyaltyPointsName ??
                        t('loyaltyPointsNameFallback') ??
                        ''
                      ).toLocaleLowerCase(),
                    })}
                  </Text>
                )}

                <StandardButton
                  themeKey="joinNowLoyaltyButton"
                  label={t('button.joinNow')}
                  containerStyle={styles.joinNowLoyaltyButton}
                  onClick={() => dispatch(setCurrentModal(REGISTER_MODAL_ID))}
                />
              </>
            )}

            {member && historicalOrderItems && (
              <StandardButton
                themeKey="addOrderToFavourites"
                containerStyle={styles.addOrderToFavouritesContainer}
                onClick={() => {
                  !isOrderFavourited
                    ? dispatch(
                        setCurrentModal({
                          modalId: FAVOURITE_NAME_MODAL,
                          params: {
                            historicalOrderItems,
                          },
                        }),
                      )
                    : newFavouritedOrder &&
                      dispatch(
                        deleteFavouriteOrder({ id: newFavouritedOrder.current?.id as string }),
                      );
                }}
              >
                {isOrderFavourited ? (
                  <AiFillHeart
                    style={combineStyles(p('activeHeartIcon', ['color']))}
                    fontSize={34}
                  />
                ) : (
                  <AiOutlineHeart fontSize={34} />
                )}

                <Text themeKey="addOrderToFavourites" style={styles.addOrderToFavouritesText}>
                  {t('title.addOrderToFavourites')}
                </Text>
              </StandardButton>
            )}
          </div>
        </div>
      </ScreenComponent>

      <NewOrderButton />
    </React.Fragment>
  );
};

const styles: Styles = {
  orderCompleteDetailsText: {
    lineHeight: '1.8em',
    marginTop: 15,
    textAlign: 'center',
  },

  orderCompleteDetails: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  mobileContentContainer: {
    display: 'flex',
  },

  desktopContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    padding: '50px 30px',
  },

  mainContainer: {
    borderRadius: 5,
    padding: '3em 30px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mainContainerDesktop: {
    maxWidth: 540,
    width: '100%',
    overflow: 'scroll',
  },
  mainContainerMobile: {
    flexGrow: 1,
    padding: '2em 30px 0',
  },

  detailsSection: {
    // marginTop: 10,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2em',
    // height: '80%',
  },

  title: {
    margin: '2em 0 0',
  },

  icon: {
    margin: '15px 0 0 0',
  },

  joinLoyaltyTitle: {
    margin: '20px 0 0 0',
  },

  joinLoyaltyCallMessage: {
    margin: '12px 0 0 0',
    width: '85%',
    textAlign: 'center',
  },

  joinNowLoyaltyButton: {
    width: '50%',
    borderRadius: 5,
    padding: '6px 15px',
  },

  addOrderToFavouritesContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },

  addOrderToFavouritesText: {
    margin: '0 0 0 8px',
  },

  deliveryTrackerAccuracyMessage: {
    marginTop: 30,
    marginBottom: 20,

    textAlign: 'center',
    maxWidth: 330,
  },

  deliveryTrackingUrl: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  location: {
    paddingTop: 0,
  },

  newOrderButtonMobile: {
    margin: '10px 0 30px 0',
  },

  cartContainer: {
    width: '90%',
  },

  deliveryTrackingLinkIcon: {
    marginRight: 10,
    marginLeft: 5,
  },

  completedOrderMessage: {
    marginTop: 15,
  },

  completedOrderMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export default CompletedOrderScreen;

import { createSelector } from 'reselect';

import findItemOrSubstitute from '../utils/ordering/findItemOrSubstitute';

import getCurrentOrder from './getCurrentOrder';
import getItems, { $getItems } from './getItems';
import translateChoiceSelectionsBetweenItems from '../utils/ordering/translateChoiceSelectionsBetweenItems';

const getUnenhancedOpenPurchase = createSelector(
  [getCurrentOrder],
  (order): _Purchase | null => order.openPurchase,
);

export default getUnenhancedOpenPurchase;

// what is this meant to do max?
export const $getUnenhancedOpenPurchase = createSelector(
  [getUnenhancedOpenPurchase, $getItems, getItems],
  (purchase, bufferItems, orderItems): _Purchase | null => {
    if (!bufferItems || !orderItems || !purchase) {
      return null;
    }

    const item = orderItems[purchase.itemId];

    if (!item) {
      return null;
    }

    // is this a good idea? Reverting to using the item name instead of the id?
    // I left it in because I don't understand it, TODO: rethink
    const newItem = findItemOrSubstitute(bufferItems, {
      id: purchase.itemId,
      name: item.name,
    });

    if (!newItem) {
      return null;
    }

    // get the proper size if it's a composite item
    let sizeItem: Item | undefined;
    if (newItem.sizes.length) {
      sizeItem = newItem.sizes.find(
        s => s.id === (purchase.sizeId ?? newItem.default_size),
      );
    }

    // transferred old changepurchaseitem() logic to this function, I think this is what gets the pre-selections for an item into the choice selections object
    // If the item is composite it will use preselections from the default variety, if not it will use the item's preselections
    return {
      ...purchase,
      itemId: newItem.id,
      // only give a size id if the sizeitem object exists
      sizeId: sizeItem?.id,
      choiceSelections: translateChoiceSelectionsBetweenItems(
        purchase.choiceSelections,
        item,
        // only give the size item's selections if the size item exists
        sizeItem ?? newItem,
      ),
    };
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import getItems from '../selectors/getItems';
import { v4 as uuidv4 } from 'uuid';
import purchaseFromItem from '../utils/ordering/purchaseFromItem';
import getNestedItemStockBalancesData from '../selectors/getNestedItemStockBalancesData';

export const openPurchase = createAsyncThunk(
  '$openPurchase',
  async (
    data: {
      itemId: string;
      brandId?: string;
      mimCategoryId?: string;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { itemId, brandId, mimCategoryId } = data;
      const allItems = getItems(getState() as EntireFrontendState);

      if (!allItems) {
        throw new Error('allItems is undefined');
      }

      const item = allItems[itemId];
      const [stockBalanceDataMap] = getNestedItemStockBalancesData(
        undefined,
        undefined,
      )(getState() as EntireFrontendState);

      const purchase = purchaseFromItem(
        item,
        uuidv4(),
        undefined,
        undefined,
        brandId,
        undefined,
        stockBalanceDataMap,
      );

      return { purchase: { mimCategoryId, ...purchase } };
    } catch (e) {
      console.error('Open purchase failed', { e });
      return rejectWithValue(e);
    }
  },
);

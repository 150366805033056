import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../actions/currentOrder';

const initialState = null as null | string;

const slice = createSlice({
  name: '$menuSearchText',
  initialState,
  reducers: {
    setMenuSearchText(state, action: PayloadAction<string | null>) {
      return action.payload ?? initialState;
    },
  },
  // reset search text on order reset
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      return initialState;
    });
  },
});

export const { setMenuSearchText } = slice.actions;

export default slice.reducer;

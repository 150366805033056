import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as ChoiceSetQuantityDiscountConfig | null;

const slice = createSlice({
  name: '$choiceSetQuantityDiscount',
  initialState,
  reducers: {
    setChoiceSetQuantityDiscountConfig(
      state,
      action: PayloadAction<ChoiceSetQuantityDiscountConfig>,
    ) {
      if (
        !action.payload ||
        !action.payload.choiceSetKey ||
        !action.payload.tierSize ||
        !action.payload.discountPlu
      ) {
        return null;
      }

      return action.payload;
    },
  },
});

export const { setChoiceSetQuantityDiscountConfig } = slice.actions;
export default slice.reducer;

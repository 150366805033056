import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSaleDetails, getOrderInProgress } from '../selectors';
import Api, { AuthenticationMethod, FetchParams } from '../utils/Api';
import processSale from '../utils/processors/processSale';
import moment from 'moment';

export const fetchSaleStatus = createAsyncThunk(
  '$fetchSaleStatus',
  async (
    data: {
      authenticationMethod?: AuthenticationMethod;
    },
    { rejectWithValue, getState },
  ) => {
    const { authenticationMethod = 'member' } = data;

    try {
      const saleDetails = getSaleDetails(getState() as EntireFrontendState);
      const orderInProgress = getOrderInProgress(
        getState() as EntireFrontendState,
      );

      const sale = saleDetails || orderInProgress;

      const { transactionId } = sale!;

      if (transactionId == null) {
        throw new Error('no transaction id to look up');
      }

      const params: FetchParams = {
        path: {
          member: `/api/v1/profile/orders?q=${transactionId}`,
          trusted: `/api/v1/auth/orderstatus/${transactionId}`,
          none: `/api/v1/sale/order/${transactionId}`,
        }[authenticationMethod],
        method: 'GET',
      };

      const response = await Api.fetch(params, authenticationMethod);

      let rawSale: RawSale;

      if (['member', 'none'].includes(authenticationMethod)) {
        rawSale = response.data[0];
      } else {
        rawSale = response.data;
      }

      // If the current time surpasses the pickup time then mark the order as ready for collection.
      const pickupReady = moment(rawSale.PickupTime).isBefore(moment());

      const processedSale = processSale(rawSale, pickupReady);

      return {
        saleDetails: processedSale,
      };
    } catch (e) {
      console.warn('Fetch sale status failed', { e });
      return rejectWithValue(e);
    }
  },
);

import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { applyBarcode } from '../../thunks/applyBarcode';
import { fetchGiftCard } from '../../thunks/fetchGiftCard';
import { resetOrder } from '../../actions/currentOrder';

const initialState: _GiftCard | Partial<_GiftCard> = {
  number: '',
  pin: '',
  moneyBalance: null,
  active: null,
  expiryDate: null,
};

const slice = createSlice({
  name: '$giftCard',
  initialState,
  reducers: {
    setGiftCard: (state, action: PayloadAction<Partial<_GiftCard | null>>) => {
      const giftCard = action.payload;

      if (giftCard === null) {
        return initialState;
      }

      return { pin: state.pin, number: state.number, ...giftCard };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, () => {
        return initialState;
      })
      .addMatcher(
        isAnyOf(applyBarcode.fulfilled, fetchGiftCard.fulfilled),
        (state, action) => {
          const { giftCard } = action.payload;

          return { ...state, ...giftCard };
        },
      );
  },
});

export const { setGiftCard } = slice.actions;
export default slice.reducer;

import adjustChoiceSetMaxForQuantityDiscount from './adjustChoiceSetMaxForQuantityDiscount';

export default function adjustChoiceSetsForQuantityDiscount(
  choiceSets: ValidatedChoiceSet[],
  choiceSetDiscountConfig: ChoiceSetQuantityDiscountConfig,
): ValidatedChoiceSet[] {
  const { choiceSetKey, tierSize = 1 } = choiceSetDiscountConfig;
  return choiceSets.map(choiceSet => {
    if (choiceSet.key !== choiceSetKey) {
      return choiceSet;
    }

    const discountQuantity = Math.max(
      0,
      Math.floor(
        choiceSet.quantity / tierSize -
          (choiceSetDiscountConfig.threshold || 0),
      ),
    );

    const adjustedChoiceSet = {
      ...choiceSet,
      choices: choiceSet.choices.map(choice => {
        if (choice.id !== choiceSetDiscountConfig.discountPlu) {
          return choice;
        }

        return {
          ...choice,
          quantity: discountQuantity,
          moneyPrice: discountQuantity * choice.baseMoneyPrice,
        };
      }),
    };

    return adjustChoiceSetMaxForQuantityDiscount(
      adjustedChoiceSet,
      choiceSetKey,
      tierSize,
    );
  });
}

export const BOOST_FAMILY_DETAILS: SDict<{
  id: string;
  name: string;
  swapMinimum: number;
}> = {
  fruit: {
    id: 'fruit',
    name: 'Fruit',
    swapMinimum: 0,
  },
  veggie: {
    id: 'veggie',
    name: 'Veggie',
    swapMinimum: 0,
  },
  liquid: {
    id: 'liquid',
    name: 'Liquid',
    swapMinimum: 1,
  },
  'little-bits': {
    id: 'little-bits',
    name: 'Little Bits',
    swapMinimum: 0,
  },
  yoghurt: {
    id: 'yoghurt',
    name: 'Yoghurt',
    swapMinimum: 0,
  },
  ice: {
    id: 'ice',
    name: 'Ice',
    swapMinimum: 0,
  },
  supplements: {
    id: 'supplements',
    name: 'Boosters',
    swapMinimum: 0,
  },
};

export const RAW_TYPE_TO_VARIETY_MAP = {
  instruction: 1,
  without: 2,
  insteadOf: 8,
  with: 4,
};

export const BOOST_ICE_INGREDIENT_IDS = [
  '5bc96441c614b57dab7b2ce2',
  '5bc96442c614b57dab7b2ce3',
  '5bc96442c614b57dab7b2ce4',
];

export const BOOST_WITHOUT_ICE_INGREDIENT_ID = '5bc96443c614b57dab7b2ce5';

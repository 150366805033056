export default function logEventToConsole (event: LogEvent) {
  const { message, details, level } = event;

  const safeDetails = details || '';

  if (level === 'error') {
    console.error(message, safeDetails);
  } else if (level === 'warning') {
    console.warn(message, safeDetails);
  } else {
    console.info(message, safeDetails);
  }
}

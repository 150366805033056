import { createSelector } from 'reselect';

import getBuffer from './getBuffer';
import getBufferReadyToApply from './getBufferReadyToApply';
import { SALE_TYPE } from '../constants/saleType';

export default createSelector(
  [getBuffer, getBufferReadyToApply],
  (buffer, bufferReadyToApply) =>
    bufferReadyToApply && buffer.saleType === SALE_TYPE.DELIVERY,
);

import React from 'react';
import { useAppSelector } from '../app/hooks';
import { OrderingConstants, OrderingSelectors } from 'polygon-ordering';
import Text from '../components/Text';
import moment from 'moment';
import lodash from 'lodash';
import { OrderingHooks } from 'polygon-ordering';
import TransactionDetails from '../components/TransactionDetails';
import { useTranslation } from 'react-i18next';
import getConfig from '../selectors/getConfig';
import { Row, Col } from 'reactstrap';

const { useFormattedCurrency } = OrderingHooks;
const { getTransactionHistory, getTaxName } = OrderingSelectors;
const { descriptionMap, REDEMPTION_TXN_TYPES, SHOW_TOTAL_MONEY_FOR_TXN_TYPES } = OrderingConstants;

export const TRANSACTIONS_SCREEN = '/loyalty-history';

const TransactionsScreen: React.FC = () => {
  const orders = useAppSelector(getTransactionHistory);
  return (
    <div>
      {orders?.map((order, index) => (
        <Transaction key={index} order={order} />
      ))}
    </div>
  );
};

const Transaction = ({ order }: { order: TransactionHistory }) => {
  const { t } = useTranslation();
  const config = useAppSelector(getConfig);
  const loyaltyPointsName = config?.loyaltyPointsName && lodash.startCase(config.loyaltyPointsName);

  const gst = lodash.sumBy(order.Items, 'GST') || null;
  const gstDollar = useFormattedCurrency({ cents: Number(gst) });

  const orderTotal =
    order?.Items?.reduce((sum, item) => {
      const price = item?.Total || item?.Price * item?.Qty || 0;
      return sum + price;
    }, 0) || 0;

  const formattedOrderTotal = useFormattedCurrency({ dollars: orderTotal / 100 });
  const taxName = useAppSelector(getTaxName);

  const pointsEarnedOrRedeemedLabel = Boolean(order.Points)
    ? REDEMPTION_TXN_TYPES.includes(order.TxnType)
      ? t('pointsRedeemed')
      : t('pointsEarned')
    : null;

  return (
    <div className="p-3 my-2" style={{ backgroundColor: 'whitesmoke', borderRadius: 5 }}>
      <div style={{ paddingRight: 10 }}>
        {order.Store && (
          <>
            <Text themeKey="loyaltyStoreLocation" value={order.Store.toUpperCase()} />{' '}
          </>
        )}
        <Text
          themeKey="loyaltyOrderDate"
          value={moment(order.TxnDate).format('DD/MM/YYYY hh:mma')}
        />

        {order.TxnType && (
          <div>
            <Text
              themeKey="loyaltyOrderTotal"
              value={t(`transactionsDescriptionMap.${order.TxnType}`)}
            />{' '}
          </div>
        )}

        {order.SalesID && (
          <div>
            <Text themeKey="loyaltyOrderItems" value={'ID: ' + order.SalesID.toString()} />{' '}
          </div>
        )}

        <div>
          {order.Items?.map((listItem, index) => (
            <TransactionDetails order={order} transaction={listItem} key={index} />
          ))}

          <div className="mt-3">
            {gst && (
              <div>
                <Row>
                  <Col>
                    <Text themeKey="loyaltyOrderTotal" value={taxName} />
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <Text themeKey="loyaltyOrderTotal" value={gstDollar} />
                  </Col>
                </Row>
              </div>
            )}

            {SHOW_TOTAL_MONEY_FOR_TXN_TYPES.includes(order.TxnType) && (
              <div>
                <Row>
                  <Col>
                    <Text themeKey="loyaltyOrderTotal" value={'Total'} />
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <Text themeKey="loyaltyOrderTotal" value={formattedOrderTotal} />
                  </Col>
                </Row>
              </div>
            )}

            {pointsEarnedOrRedeemedLabel && loyaltyPointsName && (
              <div>
                <Row>
                  <Col>
                    <Text
                      themeKey="loyaltyOrderTotal"
                      value={`${loyaltyPointsName} ${pointsEarnedOrRedeemedLabel}`}
                    />
                  </Col>
                  <Col style={{ textAlign: 'right' }}>
                    <Text themeKey="loyaltyOrderTotal" value={`${order.Points}`} />
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsScreen;

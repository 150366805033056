import { createSelector } from '@reduxjs/toolkit';
import getItems from './getItems';
import { getDayPartMenuConfig } from './config';
import { checkTimeAvailability } from '../utils/ordering/daypartMenuAvailability';

export default (id?: string) =>
  createSelector(
    [getItems, getDayPartMenuConfig],
    (items, { dayPartTimeBracket = 0 }): boolean => {
      if (!items || !id || !items[id]) return true;
      const { availability, days, start, end } = items[id];

      return availability
        ? checkTimeAvailability(
            availability,
            days,
            start,
            end,
            dayPartTimeBracket,
          )
        : true;
    },
  );

import { createSelector } from 'reselect';
import moment from 'moment';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

import { ASAP_TIME } from '../constants';

const getDeliveryTime = createSelector([getCurrentOrder], order =>
  order.saleDetails?.estimatedDeliveryTime
    ? moment(order.saleDetails?.estimatedDeliveryTime).format()
    : order.deliveryTime
    ? moment(order.deliveryTime).format()
    : ASAP_TIME,
);

export default getDeliveryTime;

export const $getDeliveryTime = createSelector(
  [getDeliveryTime, getBuffer],
  (orderDeliveryTime, buffer) => {
    if (buffer.deliveryTime != null) {
      return moment(buffer.deliveryTime).format();
    }

    return orderDeliveryTime;
  },
);

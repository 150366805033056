import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'passwordComplexity',
  initialState: null as number | null,
  reducers: {
    setpasswordComplexity(state, action: PayloadAction<number | null>) {
      return action.payload || 1;
    },
  },
});

export const { setpasswordComplexity } = slice.actions;
export default slice.reducer;

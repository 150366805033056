import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

export default createSelector([getCurrentOrder], order => order.saleType);

export const $getSaleType = createSelector(
  [getCurrentOrder, getBuffer],
  (order, buffer) => {
    if (buffer.saleType != null) {
      return buffer.saleType;
    }

    return order.saleType;
  },
);

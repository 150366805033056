import WebFont from 'webfontloader';

import debug from './debug';
import { joinPath } from './url';

const ASSET_SERVER = 'https://assets.redcatcloud.com.au';

export function loadFonts(families: string[] = [], url = joinPath([ASSET_SERVER, 'fonts'])) {
  const fontConfig = {
    families,
    urls: families.map((font = '') => {
      const lower = font.toLowerCase();
      return joinPath([url, lower, `${lower}.css`]);
    }),
  };

  debug('fontConfig', fontConfig);

  WebFont.load({
    custom: fontConfig,
    fontloading: (familyName, fvd) => {
      debug(`loading font: ${familyName}, ${fvd}`);
    },
    fontactive: (familyName, fvd) => {
      debug(`loaded font: ${familyName}, ${fvd}`);
    },
    fontinactive: (familyName, fvd) => {
      debug(false, `unable to load font: ${familyName}, ${fvd}`, 'error');
    },
  });
}

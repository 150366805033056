import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import debug from '../utils/debug';
import i18next from 'i18next';

export const sendVerificationMedium = createAsyncThunk(
  'sendVerificationEmail',
  async (reCaptchaToken: string | null, { dispatch }) => {
    const path = '/api/v1/profile/resend_verification';
    debug('resendVerification');
    return RedcatApiHandler.authorisedFetch({
      method: 'POST',
      path,
      body: { 'g-recaptcha-response': reCaptchaToken },
    }).catch(err => {
      debug(err);
    });
  },
);

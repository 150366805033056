import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../operations';

const initialState = null as null | number;

const slice = createSlice({
  name: 'menuFilterPrice',
  initialState,
  reducers: {
    setMenuFilterPrice(state, action: PayloadAction<number | null>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, () => {
      return initialState;
    });
  },
});

export const { setMenuFilterPrice } = slice.actions;

export default slice.reducer;

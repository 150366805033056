import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import getUserLocation from '../selectors/getUserLocation';
import { logEvent } from '../utils/analytics';
import EVENTS from '../constants/events';
import UserMapMarker from '../components/UserMapMarker';
import getUsingFallbackLocation from '../selectors/getUsingFallbackLocation';
import LocationMapMarker from './LocationMapMarker';
import { FormikValues, useFormikContext } from 'formik';
import getThemeLookup from '../selectors/getThemeLookup';
import MapWrapper from './MapWrapper';
import { GoogleMap, OverlayView } from '@react-google-maps/api';

const MapView = ({ locations }: { locations: MapLocation[] }) => {
  const p = useAppSelector(getThemeLookup);
  const userCoords = useAppSelector(getUserLocation);
  const initialZoom = useAppSelector(state => state.config.initialZoom);
  const [tilesLoaded, setTilesLoaded] = useState(false);
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [hoveredLocationId, setHoveredLocationId] = useState('');
  const markerColor = p('locationMapMarker', ['color']).color as React.CSSProperties;
  const selectedMarkerColor = p('locationMapMarkerSelected', ['color'])
    .color as React.CSSProperties;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState(0);
  const usingFallbackLocation = useAppSelector(getUsingFallbackLocation);

  const [mapCenter, setMapCenter] = useState({
    lat: userCoords.latitude,
    lng: userCoords.longitude,
  });

  useEffect(() => {
    if (!height) {
      setContainerHeight();
    }
    window.addEventListener('resize', setContainerHeight);
    return () => window.removeEventListener('resize', setContainerHeight);
  }, []);

  const setContainerHeight = () => {
    if (containerRef.current && containerRef.current.clientHeight) {
      setHeight(containerRef?.current?.clientHeight);
    }
  };

  useEffect(() => {
    if (values.resetMapLocation) {
      setMapCenter({
        lat: userCoords.latitude,
        lng: userCoords.longitude,
      });

      setFieldValue('resetMapLocation', false);
    }
  }, [values.resetMapLocation]);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onCenterChanged = React.useCallback(function callback() {
    if (map) {
      setMapCenter({
        lat: map.getCenter()?.lat()!,
        lng: map.getCenter()?.lng()!,
      });
    }
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 178,
        bottom: 0,
      }}
    >
      <div style={{ position: 'relative', height: '100%' }} ref={containerRef}>
        <MapWrapper>
          <GoogleMap
            onLoad={onLoad}
            onTilesLoaded={() => setTilesLoaded(true)}
            onCenterChanged={onCenterChanged}
            zoom={initialZoom}
            center={mapCenter}
            onDragEnd={() => logEvent(EVENTS.PAN_MAP)}
            onZoomChanged={() => logEvent(EVENTS.CHANGE_MAP_ZOOM)}
            options={{
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              clickableIcons: false,
              zoomControlOptions: {
                position: window.google?.maps?.ControlPosition.TOP_RIGHT,
              },
            }}
            mapContainerStyle={{ height }}
          >
            {tilesLoaded &&
              locations.map(location => (
                <OverlayView
                  key={location.id}
                  mapPaneName="overlayMouseTarget"
                  position={{
                    lat: location.latitude!,
                    lng: location.longitude!,
                  }}
                >
                  <LocationMapMarker
                    key={location.id}
                    selected={location.id === values.locationId}
                    hovered={location.id === hoveredLocationId}
                    lat={location.latitude}
                    lng={location.longitude}
                    location={location}
                    selectLocation={() => {
                      if (values.locationId === location.id) {
                        setFieldValue('locationId', '');
                      } else {
                        setFieldValue('locationId', location.id);
                      }
                    }}
                    hoverLocationStart={() => setHoveredLocationId(location.id)}
                    hoverLocationEnd={() => setHoveredLocationId('')}
                    color={markerColor}
                    selectedColor={selectedMarkerColor}
                  />
                </OverlayView>
              ))}

            {!usingFallbackLocation && (
              <OverlayView
                mapPaneName="overlayLayer"
                position={{ lat: userCoords.latitude, lng: userCoords.longitude }}
              >
                <UserMapMarker {...{ lat: userCoords.latitude, lng: userCoords.longitude }} />
              </OverlayView>
            )}
          </GoogleMap>
        </MapWrapper>
      </div>
    </div>
  );
};

export default MapView;

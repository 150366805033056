import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

const getDeliveryProvider = createSelector(
  [getCurrentOrder],
  order => order.deliveryProvider,
);

export default getDeliveryProvider;

export const $getDeliveryProvider = createSelector(
  [getDeliveryProvider, getBuffer],
  (orderDeliveryProvider, buffer) => {
    if (buffer.deliveryProvider != null) {
      return buffer.deliveryProvider;
    }

    return orderDeliveryProvider;
  },
);

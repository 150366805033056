import choiceSelectionsFromBase from './choiceSelectionsFromBase';
import translateChoiceSelectionsBetweenItems from './translateChoiceSelectionsBetweenItems';

export default function changePurchaseItem(
  purchase: _Purchase,
  previousItem: Item,
  nextItem: Item,
): _Purchase {
  return {
    ...purchase,
    sizeId: nextItem.id,
    choiceSelections: choiceSelectionsFromBase(
      nextItem?.choiceSets!,
      undefined,
      {},
    ),
    // choiceSelections: translateChoiceSelectionsBetweenItems(
    //   purchase.choiceSelections as ChoiceSelections | NestedChoiceSelections,
    //   previousItem,
    //   nextItem,
    // ),
  };
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import debug from '../utils/debug';
import { OrderingOperations } from 'polygon-ordering';
import { setCurrentModal } from './setCurrentModal';
import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';
import { RootState } from 'store';
import { ORDER_TYPE_MODAL_ID } from '../modals/OrderTypeModal';
import { DELIVERY_DETAILS_MODAL_ID } from '../modals/DeliveryDetailsModal';
import { LOCATION_MODAL_ID } from '../modals/LocationModal';
import { setHandleURLParamsInProgress } from '../slices/handleURLParamsInProgress';
import { adjustOrder } from './adjustOrder';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { deliveryModeSelected } from '../utils/analytics';

const { setPickupTime } = OrderingOperations;

export const adjustSaleType = createAsyncThunk(
  'adjustSaleType',
  async (
    data: {
      saleType?: number;
      locationId?: string | null;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { saleType, locationId } = data;
    deliveryModeSelected(saleType!);
    debug('adjusting sale type', { saleType });

    try {
      const displayableSaleTypes = getDisplayableSaleTypes(getState() as RootState);
      const modalParams = (getState() as RootState).modalParams;

      const previousModalId = displayableSaleTypes.length > 1 ? ORDER_TYPE_MODAL_ID : null;
      dispatch(setPickupTime(null));

      if (locationId) {
        if (SALE_TYPE.DELIVERY === saleType) {
          dispatch(
            setCurrentModal({
              modalId: DELIVERY_DETAILS_MODAL_ID,
              previousModalId,
              params: { ...modalParams, saleType },
            }),
          );
        } else {
          dispatch(setHandleURLParamsInProgress(true));

          if (saleType === SALE_TYPE.TABLE_ORDER || saleType === SALE_TYPE.DINE_IN) {
            dispatch(
              setCurrentModal({
                modalId: LOCATION_MODAL_ID,
                params: {
                  ...modalParams,
                  saleType,
                  locationId,
                  action: async () => {
                    await dispatch(
                      adjustOrder({
                        saleType,
                        locationId,
                      }),
                    );
                  },
                },
              }),
            );
          } else {
            await dispatch(
              adjustOrder({
                saleType,
                locationId,
              }),
            );
          }

          dispatch(setHandleURLParamsInProgress(false));
        }
      } else {
        dispatch(
          setCurrentModal({
            modalId:
              saleType === SALE_TYPE.DELIVERY ? DELIVERY_DETAILS_MODAL_ID : LOCATION_MODAL_ID,
            previousModalId,
            params: { ...modalParams, saleType },
          }),
        );
      }
    } catch (e) {
      console.error('Adjust sale type thunk failed', e);
      return rejectWithValue(e);
    }
  },
);

import { createSelector } from 'reselect';

import findItemOrSubstitute from '../utils/ordering/findItemOrSubstitute';

import getBuffer from './getBuffer';
import getCurrentOrder from './getCurrentOrder';
import getItems, { $getItems } from './getItems';
import translateChoiceSelectionsBetweenItems from '../utils/ordering/translateChoiceSelectionsBetweenItems';
import getAllLocations from './getAllLocations';
import getFilteredRootCategory from './getFilteredRootCategory';
import lodash from 'lodash';
const getUnenhancedStagedPurchases = createSelector(
  [getCurrentOrder],
  (order): _Purchase[] => order.stagedPurchases,
);

export default getUnenhancedStagedPurchases;

// Our answer lies in here
// what is this meant to do Max?
// Are we comparing current order staged purchases with the new menu and checking whether it exists?
// TODO: Does this code work for olo3 menu like olo2 or is the behaviour different
export const $getUnenhancedStagedPurchases = createSelector(
  [getBuffer, getUnenhancedStagedPurchases, $getItems, getItems],
  (buffer, stagedPurchases, bufferItems, orderItems): _Purchase[] => {
    if (!buffer || !stagedPurchases || !bufferItems || !orderItems) {
      return [];
    }

    const result: Array<_Purchase | undefined> = [];

    stagedPurchases.forEach(purchase => {
      let valid = true;
      const oldItem = purchase.unavailable
        ? purchase.item!
        : orderItems[purchase?.itemId];

      if (!oldItem) {
        valid = false;
      }

      // why do that??
      // When a sale type is changed and the item no longer exists this will find something with the same name
      const newItem =
        (valid &&
          findItemOrSubstitute(bufferItems, {
            id: purchase.itemId,
            name: oldItem.name,
          })) ||
        undefined;

      if (!newItem) {
        valid = false;
      }

      const _newItem = newItem ?? oldItem;

      // if it's a composite item: keep track of the sizeid, otherwise use the default size
      let sizeItem: Item | undefined;
      if (_newItem?.sizes?.length) {
        sizeItem = _newItem?.sizes?.find(
          s => s.id === (purchase?.sizeId ?? _newItem?.default_size),
        );
      }

      console.log({ newItem });

      const oldSizeItem =
        !!purchase?.sizeId &&
        oldItem?.sizes?.find(s => s.id === purchase?.sizeId);

      result.push({
        ...purchase,
        itemId: _newItem?.id,
        // only give a size id if the sizeitem object exists
        sizeId: purchase.sizeId,
        choiceSelections: translateChoiceSelectionsBetweenItems(
          purchase?.choiceSelections,
          oldSizeItem || (oldItem?.choiceSets ? oldItem : undefined),
          // only give the size item's selections if the size item exists
          sizeItem ?? _newItem,
        ),
        unavailable: !valid || undefined,
        item: !valid ? oldItem : undefined,
      });
    });

    return result as _Purchase[];
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setPayLaterRules } from '../reducers/config/payLaterRules';
import Api, { FetchParams } from '../utils/Api';
import processPayLaterRule from '../utils/processors/processPayLaterRule';

export const fetchPayLaterRules = createAsyncThunk(
  '$fetchPayLaterRules',
  async (_data: undefined, { dispatch, rejectWithValue }) => {
    try {
      const params: FetchParams = {
        path: '/api/v1/payattillrules',
        method: 'GET',
      };

      const response = await Api.fetch(params);
      const rawPayLaterRules: RawPayLaterRule[] = response.data;

      const processedPayLaterRules = rawPayLaterRules.map(processPayLaterRule);

      dispatch(setPayLaterRules(processedPayLaterRules));
    } catch (e) {
      console.error('Fetch pay later rules failed', { e });
      return rejectWithValue(e);
    }
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT } from '../../constants';

const initialState = OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT;

const slice = createSlice({
  name: '$openPurchaseLowestBalance',
  initialState,
  reducers: {
    setOpenPurchaseLowestBalance(state, action: PayloadAction<number>) {
      return action.payload;
    },
  },
});

export const { setOpenPurchaseLowestBalance } = slice.actions;
export default slice.reducer;

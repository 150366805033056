import React, { useEffect } from 'react';
import Text from '../components/Text';
import { useTranslation } from 'react-i18next';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import combineStyles from '../utils/combineStyles';
import LoyaltyTitle from '../components/LoyaltyTitle';
import FeedbackItem from '../components/FeedbackItem';

import { FormikValues, useFormikContext } from 'formik';

export const FEEDBACK_MENU_ITEMS_SCREEN = '/feedback/menu-items';

const FeedbackMenuItemsScreen = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  const { values } = useFormikContext<FormikValues>();
  const formValues = values as Feedback;

  useEffect(() => {
    console.log({ formValues });
  }, [formValues]);

  return (
    <div style={styles.container}>
      <LoyaltyTitle
        title={t('title.feedback.menuItems', {
          storeName: formValues.store.storeName,
        })}
        titleTranslated
      />
      <div
        style={{
          marginTop: 30,
        }}
      >
        {formValues.items.map((item: ItemFeedback, index: number) => (
          <FeedbackItem feedbackItem={item} itemIndex={index} key={index} />
        ))}

        {formValues.items.length == 0 && (
          <div
            style={{
              flex: 1,
              justifyContent: 'space-between',
              alignContent: 'center',
            }}
          >
            <div
              style={{
                paddingTop: '10vh',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Text style={{ ...p('defaultText', TEXT_PROPERTIES) }}>
                {t('message.feedbackMenuItemsAllBlacklisted')}
              </Text>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackMenuItemsScreen;

const styles: Styles = {
  container: {
    /* display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '2em', */
  },
};

// Heath: IMO this whole processing file needs refactoring, currently it is very confusing what each function does
//  many of the functions process arrays of units, instead of processing the units themselves
//  this is not adhering to good functional programming conventions
//  the old "default" menu processing files were structured correctly and are much clearer what each function does

import * as lodash from 'lodash';
import { safeString } from '../misc';
import processOrderingImagePath from './processOrderingImagePath';
import processImageMap from './processImageMap';
import Logger from '../Logger';
import { utils } from 'polygon-utils';
const { deterministicSerialiser, sha256 } = utils;

//size description process for multi sizes
function sizeDescriptionFromName(name: string): string {
  return (name.match(/\[(.*?)\]/) || [])[1];
}

function nameWithoutSizeDescription(name: string): string {
  return (name || '').replace(/\[.*?\]/g, '').trim();
}
//process condensed choicesets
// TODO: rethink this, why should these be treated any differently inside the ordering module than any other choice set? imo this should be a ui abstraction not an ordering abstraction because this way we end up with duplicated choice sets in an item (which shouldn't happen in MiM right?)
// for some context: this is when a meal item has a choice set which allows a user to select N items to add to the meal (e.g. choose 3 pizzas, min/max = 3), this code basically duplicates the "choose 3 pizzas" into 3 identical "choose 3 pizzas" choice sets (not choices!), each still have a min/max of 3, but the ui is expected to only allow a user to select 1 for each instead of 3 (even though the choice set object still shows a min/max of 3)
// Heath (14/4/23): trialling setting the duplicated choices min/max to 1 (hopefully won't break existing web ordering logic)
// update: shouldn't break any logic because there's little validation on the quantities of this type of choice set
const processReusedChoiceSet = (
  nestedSet: NestedChoiceSet,
): NestedChoiceSet[] => {
  const processedSets: NestedChoiceSet[] = [];
  const cloneSet = { ...nestedSet };
  if (nestedSet.min && nestedSet.min > 1) {
    const defaultChoiceId = nestedSet.choices.find(
      choice => choice.selected,
    )?.id;
    const isPreselected = !!defaultChoiceId;
    const preselectedIds = nestedSet.choices.reduce((sum, choice) => {
      if (choice.selected) sum.push(choice.id);
      return sum;
    }, [] as string[]);

    cloneSet.min = 1;
    cloneSet.max = 1;
    //process condensed choiceset
    for (let i = 0; i < nestedSet.min; i++) {
      // get the correct nested item to preselect
      const selectionId =
        (isPreselected &&
          (i < preselectedIds.length ? preselectedIds[i] : defaultChoiceId)) ||
        undefined;
      const newSet = {
        ...cloneSet,
        free: i < cloneSet.free ? 1 : 0,
        choices: cloneSet.choices.map((choice, i) => {
          const selected = isPreselected && choice.id === selectionId;
          return {
            ...choice,
            selected,
            baseQuantity: selected ? 1 : 0,
          };
        }),
      };

      processedSets.push({
        ...newSet,
        key: sha256(
          deterministicSerialiser({
            ...newSet,
            key: newSet.key + i.toString(),
          }),
        ),
      });
    }
  } else {
    processedSets.push({ ...nestedSet });
  }

  return processedSets;
};

//process single item
const _processItem = (
  product: RedCat.RawProduct | RedCat.RawCompositeProduct,
  choiceSets: ChoiceSets | NestedChoiceSets,
  excludedChoiceSets: string[],
  mimCategoryId?: string,
): [_Item, (ChoiceSet | NestedChoiceSet)[]] => {
  const rawImageDefault = product.image_large || product.image_url || '';
  const rawName = product.display_name || product.name || '';
  const sizeDescription =
    product.plumodifier || sizeDescriptionFromName(rawName);
  const { choicesets: itemChoiceSets } = product;
  let processedChoiceSets: ((ChoiceSet | NestedChoiceSet) & {
    upsell: boolean;
  })[] = [];

  const conditionalChoiceSets: SDict<string[]> = {}; //checkbox display type
  const _itemChoiceSets = (itemChoiceSets || [])
    // .filter(set => !set.upsell) // TODO: REMOVE THIS WHEN MERGING UPSELLS STUFF
    .map(set => {
      const findChoiceSet = Object.values(choiceSets).find(
        (s: ChoiceSet | NestedChoiceSet) => s.id === set.recid.toString(),
      );
      const itemChoiceSet: ChoiceSet | NestedChoiceSet | undefined = !(
        excludedChoiceSets || []
      ).includes(set.recid.toString())
        ? findChoiceSet
        : undefined;
      if (itemChoiceSet) {
        const tempSet = {
          ...itemChoiceSet,
          name: set.modifier_name ? set.modifier_name : itemChoiceSet!.name,
          upsell: set.upsell,
        };
        return set.upsell
          ? {
              ...tempSet,
              availability: set.availability,
              days: set.days,
              start: set.start,
              end: set.end,
            }
          : tempSet;
      }
    })
    .filter(set => set) as ((NestedChoiceSet | ChoiceSet) & {
    upsell: boolean;
  })[];

  const isNestedItem = Boolean(
    _itemChoiceSets.filter(
      (set: NestedChoiceSet | ChoiceSet) =>
        set &&
        'nestedIngredients' in set &&
        set['nestedIngredients'] &&
        set.displayType !== 'checkbox',
    ).length,
  );

  const upsellChoiceSets: string[] = [];
  _itemChoiceSets.length &&
    _itemChoiceSets.forEach((itemChoiceSet, index) => {
      const key = sha256(
        deterministicSerialiser({
          ...itemChoiceSet!,
          key: product.recid.toString() + itemChoiceSet!.id + index.toString(),
        }),
      );

      if (isNestedItem) {
        const reusedChoiceSets: (NestedChoiceSet & { upsell: boolean })[] =
          itemChoiceSet.upsell
            ? [
                {
                  ...(itemChoiceSet as NestedChoiceSet & { upsell: boolean })!,
                  key,
                },
              ]
            : (processReusedChoiceSet({
                ...(itemChoiceSet as NestedChoiceSet)!,
                key,
              }) as (NestedChoiceSet & { upsell: boolean })[]);

        reusedChoiceSets.forEach(set => {
          if (set.displayType === 'checkbox' && !set.upsell) {
            const { choices } = set;
            choices.forEach(choice => {
              if (!choice.choiceSets) return;
              conditionalChoiceSets[choice.id] = choice.choiceSets.map(
                s => s.key,
              );
            });
          }
        });
        if (itemChoiceSet.upsell && itemChoiceSet.displayType !== 'checkbox')
          upsellChoiceSets.push(key);
        processedChoiceSets = [...processedChoiceSets, ...reusedChoiceSets];
      } else {
        if (itemChoiceSet.upsell) upsellChoiceSets.push(key);
        processedChoiceSets.push({
          ...itemChoiceSet,
          key,
        });
      }
    });
  return [
    {
      id: String(product.recid),
      plucode: (product.plucode && String(product.plucode)) || undefined,
      availability: product.availability,
      available: Boolean(product.available) ? 1 : 0,
      alcoholic: product.alcoholic,
      name: nameWithoutSizeDescription(rawName),
      sizeDescription,
      description: (product.item_info || '').trim() || undefined,
      images: {
        default: processOrderingImagePath(rawImageDefault),
        ...processImageMap(product.image_map),
      },
      baseMoneyPrice: product.price,
      // TODO: remove these excalamation marks, this is causing NaNs to appear because undefined / 100 = NaN
      basePointsPrice: product.redeem_points! / 100,
      basePointsAward: product.award_points! / 100,
      kilojoules: product.kilojoules | 0,
      choiceSets: processedChoiceSets.filter(s => !s.upsell).map(s => s.key),
      conditionalChoiceSets: conditionalChoiceSets,
      days: product.days,
      start: product.start,
      end: product.end,
      upsellChoiceSets,
      category:
        (product.recid_cat !== null && String(product.recid_cat)) || undefined,
      class:
        (product.recid_pcl !== null && String(product.recid_pcl)) || undefined,
      popularChoices: [], //not in use atm
      tags: product.tags,
      sizes: [],
      default_size: '',
      sizes_label: '',
      upsell_title: product.upsell_title,
      mimCategoryId,
    },
    processedChoiceSets,
  ];
};
//process multi sizes
const processSizes = (
  item: RedCat.RawCompositeProduct,
  rawProducts: (RedCat.RawCompositeProduct | RedCat.RawProduct)[] = [],
  choiceSets: ChoiceSets | NestedChoiceSets,
  excludedChoiceSets: string[] = [],
  mimCategoryId?: string,
): [_Item[], (ChoiceSet | NestedChoiceSet)[]] => {
  const _sizes = (item as RedCat.RawCompositeProduct).products.map(
    (p: RedCat.RawCompositeProductOption) => p.recid,
  );
  const processedSizes: _Item[] = [];
  const processedChoiceSets: (ChoiceSet | NestedChoiceSet)[] = [];
  for (let size of _sizes) {
    const product = rawProducts.find(
      (p: RedCat.RawProduct | RedCat.RawCompositeProduct) => p.recid === size,
    );
    if (!product) continue;
    const modifierOptions = item.products.find(m => m.recid === product.recid);
    if (product.type === 'PRODUCT') {
      const [_processedItem, _processedVarietyChoiceSets] = _processItem(
        product,
        choiceSets,
        excludedChoiceSets,
        mimCategoryId,
      );
      // override the name for a composite item variety
      if (modifierOptions?.modifier_name) {
        _processedItem.name = modifierOptions.modifier_name;
      }

      if (modifierOptions?.image_url) {
        _processedItem.images['size'] = modifierOptions.image_url;
      }
      processedSizes.push(_processedItem);
      processedChoiceSets.push(..._processedVarietyChoiceSets);
    }
    //implement nested composite product later
  }
  return [processedSizes, processedChoiceSets];
};

//get mim category id
const getMimCategoryId = <T extends { recid: number }>(
  item: T,
  categories: RedCat.RawCategory[],
): string | undefined => {
  const { recid: id } = item;
  const result = categories.find(c => c.option_ids.includes(id));
  return result ? result.recid.toString() : result;
};

//process menu items
// this should be restructured to process a single item in this function instead of a glorified goto which is what this is
// see comment at top of file
const processItems = (
  rawProducts: (RedCat.RawProduct | RedCat.RawCompositeProduct)[],
  rawChoiceSets: RedCat.RawChoiceSet[],
  excludedChoiceSets: string[] = [],
  rawCategories: RedCat.RawCategory[] = [],
): [_Items, ChoiceSets | NestedChoiceSets] => {
  const processedItems: _Items = {};
  const processedChoiceSets: ChoiceSets | NestedChoiceSets = processChoicesets(
    rawChoiceSets,
    rawProducts,
  );

  const newProcessedChoiceSets: ChoiceSets | NestedChoiceSets = {};
  for (let item of rawProducts) {
    const id = item.recid.toString();
    let processedSizes: _Item[] = [];
    let processedCompositeChoiceSets: (ChoiceSet | NestedChoiceSet)[] = [];
    const mimCategoryId = getMimCategoryId(item, rawCategories);
    if (item.type.valueOf() === 'COMPOSITE_PRODUCT') {
      // this should be handled inside the processitem function, TODO: refactor
      [processedSizes, processedCompositeChoiceSets] = processSizes(
        item as RedCat.RawCompositeProduct,
        rawProducts,
        processedChoiceSets,
        excludedChoiceSets,
        mimCategoryId,
      );

      // skip if composite item has no sizes
      if (!processedSizes.length) {
        continue;
      }

      processedSizes.map(sizeItem => {
        processedItems[sizeItem.id] = processedItems[sizeItem.id] ?? sizeItem;
      });
    }
    // skip item if item is already processed
    if (processedItems[id]) {
      continue;
    }

    const [processedItem, processedSets] = _processItem(
      item,
      processedChoiceSets,
      excludedChoiceSets,
      mimCategoryId,
    );
    processedItems[id] = {
      ...processedItem,
      sizes: processedSizes,
      default_size:
        (item.default_product && item.default_product.toString()) || undefined,
      sizes_label: item.variety_label ?? '',
      mimCategoryId,
    };
    processedSets.forEach(set => {
      newProcessedChoiceSets[set.key] = set;
    });
    processedCompositeChoiceSets.forEach(set => {
      newProcessedChoiceSets[set.key] = set;
    });
  }
  return [processedItems, newProcessedChoiceSets];
};

const processNestedChoiceSets = (
  choiceSets: ChoiceSets | NestedChoiceSets,
  items: _Items,
  rawChoiceSets: RedCat.RawChoiceSet[],
): NestedChoiceSets => {
  const nestedChoiceSets: NestedChoiceSets = Object.values(choiceSets).reduce(
    (acc, current) => {
      return { ...acc, [current.key]: current };
    },
    {},
  );
  const newNestedChoiceSets: NestedChoiceSets = { ...nestedChoiceSets };

  Object.values(nestedChoiceSets).forEach(set => {
    const { choices } = set;
    newNestedChoiceSets[set.key].choices = choices
      .map(choice => {
        const rawSet = rawChoiceSets.find(s => s.recid.toString() === set.id);
        const modiferChoice = (rawSet?.choices! || []).find(
          c => c.recid.toString() === choice.id,
        );
        if (items[choice.id].choiceSets.length) {
          const sets = items[choice.id].choiceSets.map(set => choiceSets[set]);
          return {
            ...choice,
            choiceSets: sets,
            baseMoneyPrice: set.free
              ? 0
              : modiferChoice
              ? modiferChoice.price
              : choice.baseMoneyPrice,
            basePointsAward: modiferChoice
              ? modiferChoice.price > 0
                ? choice.basePointsAward
                : 0
              : 0,
            basePointsPrice: modiferChoice
              ? modiferChoice.price > 0
                ? choice.basePointsPrice
                : 0
              : 0,
          };
        } else {
          return {
            ...choice,
            baseMoneyPrice: set.free
              ? 0
              : modiferChoice
              ? modiferChoice.price
              : choice.baseMoneyPrice,
            basePointsAward: modiferChoice
              ? modiferChoice.price > 0
                ? choice.basePointsAward
                : 0
              : 0,
            basePointsPrice: modiferChoice
              ? modiferChoice.price > 0
                ? choice.basePointsPrice
                : 0
              : 0,
          };
        }
      })
      .filter(e => e) as NestedChoice[];
  });

  return nestedChoiceSets;
};

const safeTrim = (str: string): string => {
  return (str || '').trim();
};
const _processChoice = (
  rawProduct: RedCat.RawProduct & { selected: boolean },
): Choice => {
  const rawImageDefault = rawProduct.image_large || rawProduct.image_url || '';

  return {
    id: String(rawProduct.recid),
    plucode: String(rawProduct.plucode),
    name: (rawProduct.display_name || '').trim(),
    description: safeTrim(rawProduct.item_info),
    kilojoules: rawProduct.kilojoules | 0,
    baseMoneyPrice: rawProduct.price,
    basePointsPrice: rawProduct.redeem_points / 100,
    basePointsAward: rawProduct.award_points / 100,
    baseQuantity: Number(rawProduct.selected),
    images: {
      default: processOrderingImagePath(rawImageDefault),
      ...processImageMap(rawProduct.image_map),
    },
    selected: rawProduct.selected,
    tags: rawProduct.tags,
  } as Choice;
};

//process choices
const processChoices = (
  choices: RedCat.RawChoice[],
  rawProducts: (RedCat.RawCompositeProduct | RedCat.RawProduct)[],
): [Choice[], boolean] => {
  const choiceProducts = choices
    .map(c => {
      const product = rawProducts.find(
        p => p.recid === c.recid && p.type === 'PRODUCT',
      );

      if (product)
        return {
          ...product,
          selected: Boolean(c.selected),
          price: c.price ?? product.price,
          display_name: c.modifier_name
            ? c.modifier_name
            : product.display_name,
        };
    })
    .filter(e => e) as ((RedCat.RawCompositeProduct | RedCat.RawProduct) & {
    selected: boolean;
  })[];
  let nested = false;
  return [
    choiceProducts.map((e: any) => {
      if (e.choicesets.length) {
        nested = true;
        // processNestedChoice(e);
      }
      return _processChoice(e);
    }),
    nested,
  ];
};

const _processChoiceSet = (
  choiceset: RedCat.RawChoiceSet,
): Omit<ChoiceSet, 'choices' | 'nestedIngredients'> => {
  const processed = {
    id: choiceset.recid.toString(),
    name: choiceset.display_name,
    free: choiceset.item_free,
    max: choiceset.maximum,
    min: choiceset.minimum,
    required: !!choiceset.minimum || !!choiceset.required, // TODO: get this fixed in MiM, in the meantime this works because required is a redundant field anyway
    individualMax: choiceset.item_max,
    displayType: safeString(choiceset.display_type),
  };
  return {
    ...processed,
    key: sha256(deterministicSerialiser(processed)),
  };
};

//process menu choicesets
const processChoicesets = (
  rawChoiceSets: RedCat.RawChoiceSet[] = [],
  rawProducts: (RedCat.RawCompositeProduct | RedCat.RawProduct)[],
): ChoiceSets | NestedChoiceSets => {
  const processedChoiceSets: ChoiceSets | NestedChoiceSet = {};
  rawChoiceSets.forEach(choiceset => {
    const [choices, nested] = processChoices(choiceset.choices, rawProducts);

    processedChoiceSets[choiceset.recid] = nested
      ? ({
          ..._processChoiceSet(choiceset),
          choices,
          nestedIngredients: nested,
        } as NestedChoiceSet)
      : {
          ..._processChoiceSet(choiceset),
          choices,
        };
  });

  return processedChoiceSets;
};

//conditional choiceSets(nested choiceset)
// const processConditionalChoiceSets = (
//   items: _Items,
//   sets: ChoiceSets|NestedChoiceSets,
// ): _Items => {
//   Object.entries(items).forEach(([key, value]) => {
//     const { choiceSets } = value;
//     let conditionalChoiceSets: SDict<string[]> = {};
//     choiceSets.forEach(set => {
//       if (sets[set].nestedIngredients) {
//         sets[set].choices.forEach(
//           (choice: Choice & { choiceSets?: ChoiceSet[] }) => {
//             if (choice.choiceSets && choice.choiceSets.length) {
//               conditionalChoiceSets[choice.id] = choice.choiceSets.map(
//                 c => c.id,
//               );
//             }
//           },
//         );
//       }
//     });
//     items[key].conditionalChoiceSets = conditionalChoiceSets;
//   });
//   return items;
// };

//process sub menus
const processSubMenus = (
  catIDs: number[] = [],
  rawCats: RedCat.RawCategory[] = [],
  allItems: _Items = {},
): MenuNode[] => {
  let pocessedSubMenus: MenuNode[] = [];

  pocessedSubMenus = catIDs
    .map(e => {
      const category = rawCats
        // .filter(e => e.type === 'CATEGORY')
        .find(c => c.recid === e) as RedCat.RawCategory;
      if (!category) return;
      let brandId: string = '';
      // negative numbers not falsey
      if (category.recid_brn && category.recid_brn >= 0) {
        brandId = String(category.recid_brn);
      }
      if (category.type === 'CATEGORY') {
        return {
          availability: category.availability,
          days: category.days,
          end: category.end,
          start: category.start,
          id: String(category.recid),
          shortName: category.name,
          name: category.display_name,
          subMenus: category.option_ids.map(p => {
            const subCat = rawCats.find(
              e => e.recid === p,
            ) as RedCat.RawCategory;
            return {
              availability: subCat.availability,
              days: subCat.days,
              end: subCat.end,
              start: subCat.start,
              id: String(subCat.recid),
              shortName: subCat.display_name,
              name: subCat.display_name,
              subMenus: [],
              items: subCat.option_ids
                .map(o => {
                  const product = allItems[String(o)];
                  if (!product) return;
                  // not sure when this gets called with MIM?
                  // TODO: test this?
                  return o.toString();
                  // return product?.type.valueOf() === 'PRODUCT'
                  //   ? o.toString()
                  //   : product.default_product.toString();
                })
                .filter(e => e) as string[],
              images: {
                default: subCat.image_url,
              },
              brandId: String(category.recid_brn || ''),
            };
          }) as MenuNode[] | [],
          items: [],
          images: {},
        };
      } else {
        // category type here is most likely "PRODUCT" which seems to be a mim (non)issue?
        return {
          id: String(category.recid),
          shortName: category.display_name,
          name: category.display_name,
          subMenus: [],
          items: category.option_ids
            .map(o => {
              const product = allItems[String(o)];
              if (!product) return;
              return o.toString();
              // TODO: refactor?
              // old composite thinking: (could just return the array without this map function now tbh)
              // return product?.type.valueOf() === 'PRODUCT'
              //   ? o.toString()
              //   : product.default_product.toString();
            })
            .filter(e => e) as string[],
          images: {
            default: category.image_url,
          },
          brandId: String(category.recid_brn || ''),
          availability: category.availability,
          days: category.days,
          start: category.start,
          end: category.end,
        };
      }
    })
    .filter(e => e) as MenuNode[];

  return pocessedSubMenus;
};

//process upsell choicesets
const processUpsells = (
  upSellChoiceSets: RedCat.RawUpsellChoiceSet[],
  rawChoiceSets: RedCat.RawChoiceSet[],
  rawProducts: (RedCat.RawProduct | RedCat.RawCompositeProduct)[],
): RedCat.UpsellChoiceSet[] => {
  const result: RedCat.UpsellChoiceSet[] = [];
  upSellChoiceSets.forEach(upsellSet => {
    const set = rawChoiceSets.find(
      choiceset => choiceset.recid === upsellSet.recid,
    ) as RedCat.RawChoiceSet;
    if (set) {
      const processedSet = _processChoiceSet(set);
      const [choices] = processChoices(set.choices, rawProducts);
      const processed = { ...processedSet, choices, ...upsellSet };
      const key = sha256(deterministicSerialiser(processed));
      result.push({ ...processed, key });
    }
  });
  return result;
};

//process category information
const processCategoryInfos = (
  categories: RedCat.RawCategory[],
): CategoryInfo[] => {
  return categories.map(cat => ({
    id: cat.recid.toString(),
    shortName: cat.name.split(' ')[0],
    name: cat.name,
    images: {},
    brandId: cat.recid_brn ? cat.recid_brn.toString() : '',
    availability: cat.availability,
    start: cat.start,
    end: cat.end,
    days: cat.days,
  }));
};

export default function processMenu(
  rawMenu: RedCat.RawMenu,
  top?: boolean,
  excludedChoiceSets: string[] = [],
):
  | [
      MenuNode,
      _Items,
      ChoiceSets,
      RedCat.UpsellChoiceSet[],
      string | undefined | null,
      CategoryInfo[],
    ]
  | undefined {
  let allCategoryInfo: CategoryInfo[] = [];
  let allItems: _Items = {};
  let subMenus: MenuNode[] = [];
  let allChoiceSets: ChoiceSets | NestedChoiceSets = {};
  let allUpsells: RedCat.UpsellChoiceSet[] = []; //need to implement
  let menu;
  const name = top ? 'main' : (rawMenu.name || '').trim();

  // console.log({ rawMenu });

  try {
    //process category info
    allCategoryInfo = processCategoryInfos(rawMenu.categories);
    //process menu items
    const [items, choicesets] = processItems(
      rawMenu.products.filter(
        p => p.available || lodash.get(p, 'type') === 'COMPOSITE_PRODUCT',
      ),
      rawMenu.choicesets,
      excludedChoiceSets,
      rawMenu.categories,
    );
    allItems = items;
    //process menu choicesets
    allChoiceSets = choicesets; //processChoicesets(rawMenu.choicesets, rawMenu.products);

    subMenus = processSubMenus(
      rawMenu.category_ids,
      rawMenu.categories,
      allItems,
    );

    menu = {
      id: `${safeString(name)}`,
      shortName: name.split(' ')[0],
      name,
      subMenus,
      items: [],
      colour: rawMenu.BColour ? `#${rawMenu.BColour}` : undefined,
      images: {
        default: processOrderingImagePath(rawMenu.image_url),
        ...processImageMap(rawMenu.image_map),
      },
      availability: false,
      start: null,
      end: null,
      days: [],
    };

    const processedNestedChoiceSets: NestedChoiceSets = processNestedChoiceSets(
      allChoiceSets,
      allItems,
      rawMenu.choicesets,
    );
    allChoiceSets = { ...allChoiceSets, ...processedNestedChoiceSets };

    Object.entries(allItems).forEach(([key, value]) => {
      const { choiceSets } = value;
      const conditionalChoiceSets: SDict<string[]> = {};
      if (choiceSets.length > 0)
        choiceSets.forEach(s => {
          if (allChoiceSets[s].displayType === 'checkbox') {
            const { choices } = allChoiceSets[s] as NestedChoiceSet;
            choices.forEach(c => {
              if (!c.choiceSets) return;
              conditionalChoiceSets[
                c.id as keyof typeof conditionalChoiceSets
              ] = c.choiceSets.map(s => s.key);
            });
            allItems[key].conditionalChoiceSets = conditionalChoiceSets;
            allItems[key].choiceSets = allItems[key].choiceSets.concat(
              ...lodash.values(conditionalChoiceSets),
            );
          }
        });
    });

    allUpsells = processUpsells(
      rawMenu['upsell_checkout_choiceset'] || [],
      rawMenu.choicesets,
      rawMenu.products,
    );
    const upsellTitle = rawMenu['upsell_title'] || '';

    return [
      menu,
      allItems,
      allChoiceSets,
      allUpsells,
      upsellTitle,
      allCategoryInfo,
    ];
  } catch (e) {
    Logger.log(`failed to process top menu `, 'error', e as Error);
    return undefined;
  }
}

import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Text from '../components/Text';
import { Formik } from 'formik';
import StandardButton from '../components/StandardButton';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import * as Yup from 'yup';
import FormTextField from '../components/FormTextField';
import FormSelect from '../components/FormSelect';
import RedcatImage from '../components/RedcatImage';
import getThemeLookup from '../selectors/getThemeLookup';
import ReCAPTCHA from 'react-google-recaptcha';
import combineStyles from '../utils/combineStyles';
import TouchableOpacity from '../components/TouchableOpacity';
import history from '../history';
import { objectFilter } from '../libs/polygon-ordering/src/utils/misc';
import redcatApiFetch from '../api';
import getConfig from '../selectors/getConfig';
import SignInForm from '../components/SignInForm';
import getRegisterProfileFields from '../selectors/getRegisterProfileFields';
import getRegisterProfileSchema from '../selectors/getRegisterProfileSchema';
import RegistrationFields from '../components/RegistrationFields';
import getProfile from '../selectors/getProfile';
import { OrderingSelectors } from 'polygon-ordering';
import { INPUT_FIELDS } from '../constants/inputfields';
const { getMember,getLocationsForDisplay } = OrderingSelectors;

export const LOYALTY_MIGRATION_ROUTE = '/loyalty-migration';

const LoyaltyMigrationScreens: React.FC = () => {
  const { t } = useTranslation();
  const [validationCodes, setValidationCodes] = useState<{ code: string; id: number }>();
  const [finalised, setFinalised] = useState<boolean>(false);

  const member = useAppSelector(getMember);
  const brandImage = useAppSelector(state => state.config.logoImagePath);

  useEffect(() => {
    if (member) {
      history.replace('/');
    }
  }, [member]);

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        {/* logo image */}
        <div style={styles.row}>
          {!!brandImage && <RedcatImage containerStyle={{ width: 110 }} imagePath={brandImage} />}
        </div>
        {member ? (
          <Text style={styles.text} value={t('loyaltyMigration.alreadyLoggedInMessage')} />
        ) : (
          <>
            {!finalised ? (
              !validationCodes ? (
                <ValidationForm onValidate={(code, id) => setValidationCodes({ code, id })} />
              ) : (
                <FinaliseForm
                  validationCodes={validationCodes}
                  onSuccess={() => setFinalised(true)}
                />
              )
            ) : (
              <div style={styles.formContainer}>
                <Text style={styles.text} value={t('loyaltyMigration.signInPage.subtitle')} />
                <SignInForm onSuccess={() => history.replace('/')} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ValidationForm: React.FC<{ onValidate: (code: string, id: number) => void }> = ({
  onValidate,
}) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState(false);

  const captchaRef = useRef<ReCAPTCHA>(null);

  const locationsMasterList = useAppSelector(getLocationsForDisplay);
  const locations = Object.entries(locationsMasterList ?? {}).map(([k, location]) => ({
    name: location.name,
    id: k,
  }));

  const reCaptchaSiteKey = useAppSelector(state => state.config.reCaptchaSiteKey);
  const enableReCaptcha = useAppSelector(state => state.config.enableReCaptcha);

  return (
    <Formik
      initialValues={{ memberNo: '', points: '', lastStore: '', grecaptcha: '' }}
      validationSchema={Yup.object({
        memberNo: Yup.string().required(t('form.required')),
        points: Yup.number().typeError(t('form.onlyNumbersRequired')).required(t('form.required')),
        lastStore: Yup.string().required(t('form.required')),
        grecaptcha: enableReCaptcha ? Yup.string().required(t('form.required')) : Yup.string(),
      })}
      onSubmit={(values, { setFieldError, setFieldValue }) => {
        let success = false;
        let message: null | string = null;
        setSubmitting(true);

        redcatApiFetch({
          path: '/api/v1/member/migrate/validate',
          method: 'POST',
          body: {
            identifier: values.memberNo,
            last_store: values.lastStore,
            points: values.points,
            ['g-recaptcha-response']: values.grecaptcha,
          },
        })
          .then(res => {
            if (res.success) {
              success = true;
              onValidate(res.code, res.id);
            } else {
              message = res.message;
            }
          })
          .catch(e => {
            message = e.message;
          })
          .finally(() => {
            if (success) return;

            setSubmitting(false);
            captchaRef.current?.reset();
            setFieldValue('grecaptcha', '');

            enqueueErrorSnackbar(message ?? 'Could not complete verification.');
          });
      }}
    >
      {({ setFieldValue, submitForm, handleChange, values, isValid }) => {
        return (
          <>
            <div style={styles.formFieldsContainer}>
              <Text style={styles.text} value={t('loyaltyMigration.landingPage.subtitle')} />
              {/* form fields */}
              <FormTextField
                name={'memberNo'}
                type={'text'}
                label={t(`field.loyaltyMigration.memberNo`)}
                shrinkLabel={!!values.memberNo}
                onChange={handleChange}
                disableStyles
              />
              <FormTextField
                name={'points'}
                type={'text'}
                label={t(`field.loyaltyMigration.pointsBalance`)}
                shrinkLabel={!!values.points}
                onChange={handleChange}
                disableStyles
              />
              <FormSelect
                name={'lastStore'}
                label={t(`field.loyaltyMigration.lastStoreVisited`)}
                onChange={handleChange}
                shrinkLabel={!!values.lastStore}
                menuItems={locations}
                disableStyles
              />
            </div>
            {/* captcha */}
            {enableReCaptcha && !!reCaptchaSiteKey && (
              <div style={styles.row}>
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={reCaptchaSiteKey}
                  onChange={t => setFieldValue('grecaptcha', t)}
                />
              </div>
            )}

            {/* submit button */}
            <div style={styles.submitButtonsContainer}>
              <StandardButton
                label={t('loyaltyMigration.landingPage.submitButton')}
                themeKey="migrationFormButton"
                onClick={submitForm}
                disabled={!isValid || submitting}
                showSpinner={submitting}
              />
              <div style={{ textAlign: 'center' }}>
                <Text
                  themeKey="migrationFormLoginPretext"
                  value={t('loyaltyMigration.landingPage.loginPretext')}
                />{' '}
                {/* login link */}
                <TouchableOpacity
                  style={{ display: 'inline-block' }}
                  onClick={() => history.push('/')}
                >
                  <Text
                    themeKey="migrationFormLoginText"
                    value={t('loyaltyMigration.landingPage.loginText')}
                  />
                </TouchableOpacity>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const FinaliseForm: React.FC<{
  validationCodes: { code: string; id: number };
  onSuccess: () => void;
}> = ({ validationCodes, onSuccess }) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const config = useAppSelector(getConfig);
  const [submitting, setSubmitting] = useState(false);

  const locationsMasterList = useAppSelector(getLocationsForDisplay);
  const locations = Object.entries(locationsMasterList ?? {}).map(([k, location]) => ({
    name: location.name,
    id: k,
  }));

  const registrationFields = useAppSelector(getRegisterProfileFields);
  // TODO: change per client
  const registrationFieldsWhitelist = [
    'Mobile',
    'Email',
    'GivenNames',
    'Surname',
    'Password',
    'StoreID',
  ];
  const registratonFieldsSchema = useAppSelector(getRegisterProfileSchema);
  // this is kind of a hack to filter out fields from the schema, it is not well documented how this should be done with yup
  // schema object: {...etc, fields: {a: subschema, b: subschema, ...etc}}
  // object filter is like array.filter but for objects
  let validationSchema = registratonFieldsSchema.visible;
  if (validationSchema) {
    validationSchema = Yup.object().shape(
      objectFilter(validationSchema.fields ?? {}, (v, k) =>
        registrationFieldsWhitelist.includes(k),
      ),
    );
  }

  // Checking whether we need confirm password
  const isConfirmPasswordRequired =
    registrationFields &&
    registrationFields!.visible.find(field => field.id === INPUT_FIELDS.ConfirmPassword);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        GivenNames: '',
        Surname: '',
        Email: '',
        Mobile: '',
        StoreID: '',
        Password: '',
      }}
      onSubmit={values => {
        let success = false;
        let message: null | string = null;
        setSubmitting(true);

        redcatApiFetch({
          path: '/api/v1/member/migrate/finalise',
          method: 'POST',
          body: {
            // some of these fields should probably be named differently but that is a BE Marc laziness issue so not my problem
            id: validationCodes.id,
            migration_code: validationCodes.code,
            givennames: values.GivenNames,
            surname: values.Surname,
            email: values.Email,
            mobile: values.Mobile,
            recid_plo: values.StoreID,
            psw: values.Password,
          },
        })
          .then(res => {
            console.log(res);
            //   {
            //     "data": {
            //         "email": "bobby.robertson@example.com",
            //         "id": 78,
            //         "member_no": "YSA0002143"
            //     },
            //     "success": true
            // }
            if (res.success) {
              success = true;
              // redirect to a login screen with thank you message
              // save the email and member number in the state?
              onSuccess();
            } else {
              message = res.message;
            }
          })
          .catch(console.warn)
          .finally(() => {
            if (success) return;

            setSubmitting(false);
            // if the call didn't succeed or there was an error
            enqueueErrorSnackbar(message ?? 'Something went wrong.');
          });
      }}
    >
      {({ submitForm, handleChange, values, isValid }) => (
        <>
          <div style={styles.formFieldsContainer}>
            <Text style={styles.text} value={t('loyaltyMigration.detailsPage.subtitle')} />
            {/* form fields */}
            <div>
              {registrationFields?.visible
                .filter(field => registrationFieldsWhitelist.includes((field as RegisterFields).id))
                .map((field: RegisterFields, index: any) => (
                  <RegistrationFields
                    key={index}
                    field={field}
                    showStrengthBar={
                      isConfirmPasswordRequired
                        ? field.id === INPUT_FIELDS.ConfirmPassword
                        : field.id === INPUT_FIELDS.Password
                    }
                  />
                ))}
            </div>
            {/* <FormTextField
              name={'givenNames'}
              type={'text'}
              label={'Given Names'}
              shrinkLabel={!!values.givenNames}
              onChange={handleChange}
              disableStyles
            />
            <FormTextField
              name={'surname'}
              type={'text'}
              label={'Surname'}
              shrinkLabel={!!values.surname}
              onChange={handleChange}
              disableStyles
            />
            <FormTextField
              name={'email'}
              type={'text'}
              label={'Email'}
              shrinkLabel={!!values.email}
              onChange={handleChange}
              disableStyles
            />
            <FormTextField
              name={'mobile'}
              type={'text'}
              label={'Mobile'}
              shrinkLabel={!!values.mobile}
              onChange={handleChange}
              disableStyles
            />
            <FormSelect
              name={'favouriteStore'}
              label={'Favourite Store'}
              onChange={handleChange}
              shrinkLabel={!!values.favouriteStore}
              menuItems={locations}
              disableStyles
            />
            <FormTextField
              name={'password'}
              type={'password'}
              label={'Password'}
              onChange={handleChange}
              shrinkLabel={!!values.password}
              disableStyles
            />
            <PasswordStrengthBar
              password={values['password']}
              scoreWordStyle={combineStyles(p('defaultText', TEXT_PROPERTIES), {
                fontSize: '0.6rem',
                opacity: '0.6',
              })}
            /> */}
          </div>

          {/* submit button */}
          <div style={styles.submitButtonsContainer}>
            <StandardButton
              label={t('loyaltyMigration.detailsPage.submitButton')}
              themeKey="migrationFormButton"
              onClick={submitForm}
              disabled={!isValid || submitting}
              showSpinner={submitting}
            />
          </div>
        </>
      )}
    </Formik>
  );
};

const styles: Styles = {
  pageContainer: {},
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 30,
  },
  formFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 20,
  },
  submitButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 20,
  },
  text: {
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
};

export default LoyaltyMigrationScreens;

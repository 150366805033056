// These must be the first lines
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React, { ReactElement, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';

import 'react-perfect-scrollbar/dist/css/styles.css';

import * as serviceWorker from './serviceWorker';

import { store, persistor } from './store';
import storage from 'redux-persist/lib/storage';
import { PERSIST_KEY } from './constants';

import './styles/index.css';
import './styles/perfectScrollbarAdjustments.css';

import { initialiseI18next, LangResource } from 'polygon-utils';
import resources from './constants/strings';

import HistoryRefSaver from './components/HistoryRefSaver';
import SnackbarRefSaver from './components/SnackbarRefSaver';
import App from './components/App';

import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useAppDispatch } from './app/hooks';
import { boot } from './thunks/boot';

let retryCount = 0;
function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  const dispatch = useAppDispatch();
  if (retryCount < 2) {
    // clear state and retry
    retryCount += 1;
    setTimeout(() => {
      dispatch({ type: '$RESET_ROOT_STATE' });
      dispatch(boot());
      resetErrorBoundary();
    }, 3500);
    return (
      <>
        {'Something went wrong :('}
        <br />
        {'Attempting to reload.'}
      </>
    );
  }

  return <>{'Something went wrong :('}</>;
}

// this needs to be initialized ASAP
initialiseI18next(undefined, resources as LangResource);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <ErrorBoundary FallbackComponent={Fallback}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider
          maxSnack={3}
          dense
          // This sets the css class of the generated component once a snackbar is used
          // snackbarrefsaver.tsx handles setting the css class's background colour programmatically
          classes={{
            variantSuccess: 'successSnackbar',
            variantWarning: 'warningSnackbar',
            variantError: 'errorSnackbar',
          }}
        >
          <SnackbarRefSaver />
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <HistoryRefSaver />
            <App />
          </BrowserRouter>
        </SnackbarProvider>
      </PersistGate>
    </ErrorBoundary>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

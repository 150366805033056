import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {} as ProductConfig;

const slice = createSlice({
  name: '$productConfig',
  initialState,
  reducers: {
    setProductConfig(state, action: PayloadAction<ProductConfig>) {
      return action.payload;
    },
  },
});

export const { setProductConfig } = slice.actions;
export default slice.reducer;

import { createSelector } from 'reselect';

import getDeliverySurcharges, {
  $getDeliverySurcharges,
} from './getDeliverySurcharges';

import getOrderingProviderSurcharges, {
  $getOrderingProviderSurcharges,
} from './getOrderingProviderSurcharges';

const get = (delivery: Surcharge[] | null, minimum: Surcharge[] | null) => {
  return [...(delivery || []), ...(minimum || [])];
};

export default createSelector(
  [getDeliverySurcharges, getOrderingProviderSurcharges],
  get,
);

export const $getSurcharges = createSelector(
  [$getDeliverySurcharges, $getOrderingProviderSurcharges],
  get,
);

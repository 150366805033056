import { RiEBike2Fill, RiWalkFill, RiRestaurant2Line, RiRestaurant2Fill } from 'react-icons/ri';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { IconType } from 'react-icons/lib';

interface SALE_TYPE_ICONS {
  [key: number]: IconType;
}

export const SALE_TYPE_ICONS: SALE_TYPE_ICONS = {
  [SALE_TYPE.PICKUP]: RiWalkFill,
  [SALE_TYPE.DELIVERY]: RiEBike2Fill,
  [SALE_TYPE.DINE_IN]: RiRestaurant2Line,
  [SALE_TYPE.TAKE_AWAY]: RiWalkFill,
  [SALE_TYPE.WEB_ORDERING]: RiWalkFill,
  [SALE_TYPE.CATERING]: RiRestaurant2Fill,
  [SALE_TYPE.TABLE_ORDER]: RiRestaurant2Line,
};

import { AnyAction, combineReducers, Reducer } from 'redux';

import applyBarcodeInProgress from './applyBarcodeInProgress';
import creditCardPaymentInformation from './creditCardPaymentInformation';
import deliveryAddress from './deliveryAddress';
import deliveryCoordinates from './deliveryCoordinates';
import deliveryEstimateInProgress from './deliveryEstimateInProgress';
import deliveryNotes from './deliveryNotes';
import deliveryProvider from './deliveryProvider';
import deliverySurcharges from './deliverySurcharges';
import deliveryTime from './deliveryTime';
import giftCard from './giftCard';
import locationId from './locationId';
import menu from './menu';
import minimumOrderInformation from './minimumOrderInformation';
import notes from './notes';
import selectedOffer from './selectedOffer';
import offers from './offers';
import openPurchase from './openPurchase';
import openPurchaseLowestBalance from './openPurchaseLowestBalance';
import orderingProviderSurchargeInstructions from './orderingProviderSurchargeInstructions';
import paymentGatewayConfig from './paymentGatewayConfig';
import pickupTime from './pickupTime';
import promoCode from './promoCode';
import purchaser from './purchaser';
import remainingTotal from './remainingTotal';
import saleDetails from './saleDetails';
import saleInProgress from './saleInProgress';
import saleType from './saleType';
import selectedPaymentMethods from './selectedPaymentMethods';
import stagedPurchases from './stagedPurchases';
import table from './table';
import timeEstimateReturned from './timeEstimateReturned';
import uuid from './uuid';
import validationInProgress from './validationInProgress';
import stockBalances from './stockBalances';
import availableTableNumbers from './availableTableNumbers';
import loyaltyTopUp from './loyaltyTopUp';
import currentSavedCard from './currentSavedCard';
import cvcCheckInProgress from './cvcCheckInProgress';
import orderInProgress from './orderInProgress';
import bestEstId from './bestEstId';
import maxOrderValueRules from './maxOrderValueRules';
import receiptType from './receiptType';
import cvcToken from './cvcToken';

//new dietary options reducer
import dietaryOptions from '../../thunks/fetchDietaryOptions';
import tagsAllergenDietary from '../../thunks/fetchAllergenDietaryTags';
import ageProofSubmitted from './ageProofSubmitted';
import userUnderAge from './userUnderAge';
import eftposTxnInProgress from './eftposTxnInProgress';
import txnInProgressSelectedPayments from './txnInProgressSelectedPayments';
import txnInProgressOrderTotal from './txnInProgressOrderTotal';

//@ts-ignore
const rootReducer: Reducer<Order, AnyAction> = combineReducers({
  currentSavedCard,
  loyaltyTopUp,
  availableTableNumbers,
  applyBarcodeInProgress,
  creditCardPaymentInformation,
  deliveryAddress,
  deliveryCoordinates,
  deliveryEstimateInProgress,
  deliveryNotes,
  deliveryProvider,
  deliverySurcharges,
  deliveryTime,
  giftCard,
  locationId,
  menu,
  receiptType,
  minimumOrderInformation,
  notes,
  selectedOffer,
  offers,
  openPurchase,
  openPurchaseLowestBalance,
  orderingProviderSurchargeInstructions,
  paymentGatewayConfig,
  pickupTime,
  promoCode,
  purchaser,
  remainingTotal,
  saleDetails,
  saleInProgress,
  saleType,
  selectedPaymentMethods,
  stagedPurchases,
  table,
  timeEstimateReturned,
  uuid,
  validationInProgress,
  stockBalances,
  cvcCheckInProgress,
  orderInProgress,
  dietaryOptions,
  tagsAllergenDietary,
  ageProofSubmitted,
  userUnderAge,
  bestEstId,
  maxOrderValueRules,
  cvcToken,
  eftposTxnInProgress,
  txnInProgressSelectedPayments,
  txnInProgressOrderTotal,
});

export default rootReducer;

// NOTE: in order to be usable inside a .d.ts file, this enum
// must not be exported/imported explicitly. This makes it kind of global.
// It must also be a const enum, otherwise it will fail (only at runtime,
// the compiler will lie and say everything is fine)
import { DUMMY } from './dummy';

export enum PAYMENT_METHOD {
  MEMBER_MONEY = 'member-money',
  MEMBER_POINTS = 'member-points',
  MEMBER_REWARDS = 'member-rewards',
  EFTPOS = 'eftpos',
  GIFT_CARD = 'gift-card',
  CREDIT_CARD = 'credit-card',
  SAVED_CARD = 'saved-card',
  APPLE_PAY = 'apple-pay',
  GOOGLE_PAY = 'google-pay',
  ALIPAY = 'alipay',
  FREE = 'free',
  PAY_LATER = 'pay-at-till',
}

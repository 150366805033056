import React, { useEffect } from 'react';
import lodash from 'lodash';

import { DEVICE_TYPES, MOBILE_DISPLAY_WIDTH_THRESHOLD } from '../constants';

import { setDeviceType } from '../slices/deviceType';
import { setDeviceDimensions } from '../slices/deviceDimensions';

import { getDimensions } from '../utils/screen';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const CanaryScreenSize: React.FC = () => {
  useEffect(() => {
    handleResize();
    return () => {
      window.removeEventListener('resize', debouncedHandler);
    };
  });

  const deviceType = useAppSelector(state => state.deviceType);
  const dispatch = useAppDispatch();
  const handleResize = () => {
    const { width, height } = getDimensions();
    dispatch(setDeviceDimensions({ width, height }));

    const newDeviceType =
      width < MOBILE_DISPLAY_WIDTH_THRESHOLD ? DEVICE_TYPES.MOBILE : DEVICE_TYPES.DESKTOP;

    if (newDeviceType !== deviceType) {
      dispatch(setDeviceType(newDeviceType));
    }
  };

  const debouncedHandler = lodash.debounce(handleResize, 100);
  window.addEventListener('resize', debouncedHandler);

  return null;
};

export default CanaryScreenSize;

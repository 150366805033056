import React from 'react';
import Modal from '../components/Modal';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import Wallet from '../components/Wallet';
import RedcatBarcode from '../components/RedcatBarcode';
import RedcatQrCode from '../components/RedcatQrCode';
import getProfile from '../selectors/getProfile';

export const SCAN_CODE_MODAL_ID = 'SCAN_CODE_MODAL_ID';
const { getMember } = OrderingSelectors;

const ScanCodeModal: React.FC = () => {
  const profile = useAppSelector(getProfile);
  const loyaltyTiers = useAppSelector(state => state.loyaltyTiers);
  const tier = loyaltyTiers?.filter(tier => tier.tierName === profile?.loyaltyTierName)[0];
  const enableTieredLoyalty = useAppSelector(state => state.config.enableTieredLoyalty);

  const member = useAppSelector(getMember);
  const barcodediv = useAppSelector(state => state.config.loyaltyBarcodeView);
  const isWalletEnabled = useAppSelector(state => state.config.enableLoyaltyWallet);

  const textStyle = (enableTieredLoyalty && { color: tier?.tierTextColour }) || undefined;

  return (
    <Modal
      backdropStyle={{ backgroundColor: '#00000099' }}
      mobileContainerStyle={{ backgroundColor: 'transparent', pointerEvents: 'none' }}
      wrapperStyle={{ pointerEvents: 'none' }}
      hideFloatingHeader
      clickBackgroundToClose
    >
      <div style={{ pointerEvents: 'auto' }}>
        {barcodediv ? (
          <div
            className="  shadow p-1 position-relative"
            style={{ backgroundColor: 'white', borderRadius: 10, border: '2px solid lightgrey' }}
          >
            <RedcatBarcode
              barcodeOptions={{ value: member?.barcode!, text: member?.memberNumber }}
              showMemberDetails
              textStyle={textStyle}
            />
          </div>
        ) : (
          <RedcatQrCode textStyle={textStyle} />
        )}

        {isWalletEnabled && <Wallet />}
      </div>
    </Modal>
  );
};

const styles: Styles = {};

export default ScanCodeModal;

import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';
// import stagedPurchases, {
//   setStagedPurchases,
//   removeStagedPurchase,
//   addStagedPurchases,
//   adjustStagedPurchaseQuantity,
//   addStagedPurchase,
// } from './stagedPurchases';

const initialState = {
  offerId: null as string | null,
  purchaseId: null as string | null,
  userAdded: false,
};

const slice = createSlice({
  name: '$selectedOffer',
  initialState,
  reducers: {
    setSelectedOffer: (
      state,
      action: PayloadAction<{
        offerId: string | null;
        purchaseId?: string;
        userAdded: boolean;
      } | null>,
    ) => {
      return !action.payload
        ? initialState
        : {
            offerId: action.payload.offerId,
            purchaseId: action.payload.purchaseId ?? null,
            userAdded: action.payload.userAdded,
          };
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      return initialState;
    });
    // .addMatcher(
    //   isAnyOf(
    //     setStagedPurchases,
    //     removeStagedPurchase,
    //     addStagedPurchase,
    //     addStagedPurchases,
    //     adjustStagedPurchaseQuantity,
    //   ),
    //   (state, action) => {
    //     return state;
    //   },
    // );
  },
});

export const { setSelectedOffer } = slice.actions;
export default slice.reducer;

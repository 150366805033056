import React from 'react';
import Text from './Text';
import ModalBackButton from './ModalBackButton';

const ModalHeader = ({
  backClick,
  title,
  showCloseButton = false,
}: {
  backClick: () => void;
  title: string;
  showCloseButton?: boolean;
}) => {
  return (
    <div className="mt-4 mb-4">
      <div className="mx-3" style={{ position: 'absolute' }}>
        <ModalBackButton onClick={backClick} showCloseButton={showCloseButton} />
      </div>

      <div className="text-center">
        <Text value={title} themeKey="modalHeader" />
      </div>
    </div>
  );
};

export default ModalHeader;

import React from 'react';
import SectionTitle from './SectionTitle';
import SubSection from './SubSection';
import RadioCheck from './RadioCheck';

const TableReviewLocation = ({ location }: { location: MapLocation }) => {
  return (
    <div style={{ marginBottom: 25 }}>
      <SectionTitle value="STORE" />
      <div className="m-3">
        <SubSection
          title={location?.name}
          subtitle={location?.address?.combinedLong}
          action={() => {}}
          ActiveIcon={() => <RadioCheck checked size={24} />}
        />
      </div>
    </div>
  );
};

export default TableReviewLocation;

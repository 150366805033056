export const EVENT_CATEGORIES = {
  USER: 'USER',
  FAILURE: 'FAILURE',
  NAVIGATION: 'NAVIGATION',
  SYSTEM: 'SYSTEM',
};

export default {
  IMAGE_LOAD_FAILED: {
    action: 'IMAGE_LOAD_FAILED',
    category: EVENT_CATEGORIES.FAILURE,
  },
  OPEN_MODAL: {
    action: 'OPEN_MODAL',
    category: EVENT_CATEGORIES.NAVIGATION,
  },
  CLOSE_MODAL: {
    action: 'CLOSE_MODAL',
    category: EVENT_CATEGORIES.NAVIGATION,
  },
  ADD_FAVOURITE_TO_ORDER: {
    action: 'ADD_FAVOURITE_TO_ORDER',
    category: EVENT_CATEGORIES.USER,
  },
  ADD_PAST_ORDER_TO_ORDER: {
    action: 'ADD_PAST_ORDER_TO_ORDER',
    category: EVENT_CATEGORIES.USER,
  },
  APPLY_PROMOCODE_FAILED: {
    action: 'APPLY_PROMOCODE_FAILED',
    category: EVENT_CATEGORIES.FAILURE,
  },
  APPLY_PROMOCODE: {
    action: 'APPLY_PROMOCODE',
    category: EVENT_CATEGORIES.USER,
  },
  BOOT: {
    action: 'BOOT',
    category: EVENT_CATEGORIES.SYSTEM,
  },
  CENTER_MAP_ON_USER_LOCATION: {
    action: 'CENTER_MAP_ON_USER_LOCATION',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_DELIVERY_ADDRESS: {
    action: 'CHANGE_DELIVERY_ADDRESS',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_DELIVERY_TIME: {
    action: 'CHANGE_DELIVERY_TIME',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_DRIVER_NOTES: {
    action: 'CHANGE_DRIVER_NOTES',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_LOCATION_BRAND_FILTER: {
    action: 'CHANGE_LOCATION_BRAND_FILTER',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_LOCATION_SEARCH_TEXT: {
    action: 'CHANGE_LOCATION_SEARCH_TEXT',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_MAP_ZOOM: {
    action: 'CHANGE_MAP_ZOOM',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_MENU_BRAND_FILTER: {
    action: 'CHANGE_MENU_BRAND_FILTER',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_MENU_SEARCH_TEXT: {
    action: 'CHANGE_MENU_SEARCH_TEXT',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_OPEN_PURCHASE_INGREDIENTS: {
    action: 'CHANGE_OPEN_PURCHASE_INGREDIENTS',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_OPEN_PURCHASE_ITEM: {
    action: 'CHANGE_OPEN_PURCHASE_ITEM',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_OPEN_PURCHASE_QUANTITY: {
    action: 'CHANGE_OPEN_PURCHASE_QUANTITY',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_ORDER_NOTES: {
    action: 'CHANGE_ORDER_NOTES',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_PAYMENT_METHOD: {
    action: 'CHANGE_PAYMENT_METHOD',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_PICKUP_TIME: {
    action: 'CHANGE_PICKUP_TIME',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_PURCHASE_QUANTITY: {
    action: 'CHANGE_PURCHASE_QUANTITY',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_PURCHASER_DETAILS: {
    action: 'CHANGE_PURCHASER_DETAILS',
    category: EVENT_CATEGORIES.USER,
  },
  CHANGE_REMEMBER_CARD_OPTION: {
    action: 'CHANGE_REMEMBER_CARD_OPTION',
    category: EVENT_CATEGORIES.USER,
  },
  CHECK_DELIVERY_DETAILS: {
    action: 'CHECK_DELIVERY_DETAILS',
    category: EVENT_CATEGORIES.USER,
  },
  CHECKOUT_FAILED: {
    action: 'CHECKOUT_FAILED',
    category: EVENT_CATEGORIES.FAILURE,
  },
  CHECKOUT: {
    action: 'CHECKOUT',
    category: EVENT_CATEGORIES.USER,
  },
  CLOSE_MENU_SEARCH: {
    action: 'CLOSE_MENU_SEARCH',
    category: EVENT_CATEGORIES.USER,
  },
  CONFIRM_DELIVERY_DETAILS: {
    action: 'CONFIRM_DELIVERY_DETAILS',
    category: EVENT_CATEGORIES.USER,
  },
  CREATE_FAVOURITE: {
    action: 'CREATE_FAVOURITE',
    category: EVENT_CATEGORIES.USER,
  },
  DELETE_FAVOURITE: {
    action: 'DELETE_FAVOURITE',
    category: EVENT_CATEGORIES.USER,
  },
  DELIVERY_ADDRESS_NOT_FOUND: {
    action: 'DELIVERY_ADDRESS_NOT_FOUND',
    category: EVENT_CATEGORIES.FAILURE,
  },
  DELIVERY_UNAVAILABLE: {
    action: 'DELIVERY_UNAVAILABLE',
    category: EVENT_CATEGORIES.FAILURE,
  },
  EDIT_DELIVERY_DETAILS: {
    action: 'EDIT_DELIVERY_DETAILS',
    category: EVENT_CATEGORIES.USER,
  },
  EDIT_FAVOURITE: {
    action: 'EDIT_FAVOURITE',
    category: EVENT_CATEGORIES.USER,
  },
  FETCH_GIFT_CARD_FAILED: {
    action: 'FETCH_GIFT_CARD_FAILED',
    category: EVENT_CATEGORIES.FAILURE,
  },
  FETCH_GIFT_CARD: {
    action: 'FETCH_GIFT_CARD',
    category: EVENT_CATEGORIES.USER,
  },
  JUMP_TO_MENU_CATEGORY: {
    action: 'JUMP_TO_MENU_CATEGORY',
    category: EVENT_CATEGORIES.USER,
  },
  LOAD_WITH_URL_PARAMS: {
    action: 'LOAD_WITH_URL_PARAMS',
    category: EVENT_CATEGORIES.SYSTEM,
  },

  //member registeration
  MEMBER_REGISTER: {
    action: 'MEMBER_REGISTER',
    category: EVENT_CATEGORIES.USER,
  },
  MEMBER_REGISTER_FAILED: {
    action: 'MEMBER_REGISTER_FAILED',
    category: EVENT_CATEGORIES.FAILURE,
  },
  OPEN_DELIVERY_TRACKER: {
    action: 'OPEN_DELIVERY_TRACKER',
    category: EVENT_CATEGORIES.NAVIGATION,
  },
  OPEN_EXISTING_PURCHASE: {
    action: 'OPEN_EXISTING_PURCHASE',
    category: EVENT_CATEGORIES.USER,
  },
  OPEN_ITEM: {
    action: 'OPEN_ITEM',
    category: EVENT_CATEGORIES.USER,
  },
  OPEN_MENU_SEARCH: {
    action: 'OPEN_MENU_SEARCH',
    category: EVENT_CATEGORIES.USER,
  },
  PAN_MAP: {
    action: 'PAN_MAP',
    category: EVENT_CATEGORIES.USER,
  },
  PICK_LOCATION_AND_SALE_TYPE: {
    action: 'PICK_LOCATION_AND_SALE_TYPE',
    category: EVENT_CATEGORIES.USER,
  },
  UPDATE_KEY_ORDER_PROPERTY_FAILED: {
    action: 'UPDATE_KEY_ORDER_PROPERTY_FAILED',
    category: EVENT_CATEGORIES.FAILURE,
  },
  PROMOCODE_NOT_FOUND: {
    action: 'PROMOCODE_NOT_FOUND',
    category: EVENT_CATEGORIES.FAILURE,
  },
  REGISTER_NOW: {
    action: 'REGISTER_NOW',
    category: EVENT_CATEGORIES.NAVIGATION,
  },
  REMOVE_OFFER: {
    action: 'REMOVE_OFFER',
    category: EVENT_CATEGORIES.USER,
  },
  REMOVE_PURCHASE: {
    action: 'REMOVE_PURCHASE',
    category: EVENT_CATEGORIES.USER,
  },
  REMOVE_SAVED_CARD: {
    action: 'REMOVE_SAVED_CARD',
    category: EVENT_CATEGORIES.USER,
  },
  RESET_PASSWORD: {
    action: 'RESET_PASSWORD',
    category: EVENT_CATEGORIES.NAVIGATION,
  },
  SAVE_NEW_PURCHASE: {
    action: 'SAVE_NEW_PURCHASE',
    category: EVENT_CATEGORIES.USER,
  },
  SAVE_PURCHASE_CHANGES: {
    action: 'SAVE_PURCHASE_CHANGES',
    category: EVENT_CATEGORIES.USER,
  },
  SELECT_GEOCODED_DELIVERY_ADDRESS: {
    action: 'SELECT_GEOCODED_DELIVERY_ADDRESS',
    category: EVENT_CATEGORIES.USER,
  },
  SELECT_OFFER_FAILED: {
    action: 'SELECT_OFFER_FAILED',
    category: EVENT_CATEGORIES.FAILURE,
  },
  SELECT_OFFER: {
    action: 'SELECT_OFFER',
    category: EVENT_CATEGORIES.USER,
  },
  SET_TABLE_NUMBER: {
    action: 'SET_TABLE_NUMBER',
    category: EVENT_CATEGORIES.USER,
  },
  SIGN_IN_FAILED: {
    action: 'SIGN_IN_FAILED',
    category: EVENT_CATEGORIES.FAILURE,
  },
  SIGN_IN: {
    action: 'SIGN_IN',
    category: EVENT_CATEGORIES.USER,
  },
  SIGN_OUT: {
    action: 'SIGN_OUT',
    category: EVENT_CATEGORIES.USER,
  },
  START_NEW_ORDER: {
    action: 'START_NEW_ORDER',
    category: EVENT_CATEGORIES.USER,
  },
  START_ORDER_FROM_FAVOURITE: {
    action: 'START_ORDER_FROM_FAVOURITE',
    category: EVENT_CATEGORIES.USER,
  },
  START_ORDER_FROM_PAST_ORDER: {
    action: 'START_ORDER_FROM_PAST_ORDER',
    category: EVENT_CATEGORIES.USER,
  },
  UPDATE_MENU: {
    action: 'UPDATE_MENU',
    category: EVENT_CATEGORIES.SYSTEM,
  },
  VALIDATE_ORDER: {
    action: 'VALIDATE_ORDER',
    category: EVENT_CATEGORIES.SYSTEM,
  },
  UPDATE_KEY_ORDER_PROPERTY: {
    action: 'UPDATE_KEY_ORDER_PROPERTY',
    category: EVENT_CATEGORIES.USER,
  },
  VALIDATOR_FAILED: {
    action: 'VALIDATOR FAILED',
    category: EVENT_CATEGORIES.FAILURE,
  },
};

import { connect } from 'react-redux';

import omniMapper from './omniMapper';

// helper interface for redux connect
export default function (
  { mapDispatchToProps = undefined, mapStateToProps = undefined, component, forwardRef = false },
  componentAlt,
) {
  return connect(
    mapStateToProps != null ? state => omniMapper(state, mapStateToProps) : undefined,
    mapDispatchToProps || undefined,
    null,
    { forwardRef },
  )(component || componentAlt);
}

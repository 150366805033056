import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';
import { finaliseSale, sale } from '../../operations';

const initialState: SubPayment[] = [];

const slice = createSlice({
  name: '$txnInProgressSelectedPayments',
  initialState,
  reducers: {
    setTxnInProgressSelectedPayments(
      state,
      action: PayloadAction<SubPayment[]>,
    ) {
      return action.payload;
    },
    updateTxnInProgressSelectedPayments(
      state,
      action: PayloadAction<SubPayment>,
    ) {
      return state
        .filter(subPayment => subPayment.method !== action.payload.method)
        .concat(action.payload);
    },

    resetTxnInProgressSelectedPayments(state, action) {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addMatcher(isAnyOf(sale.fulfilled, finaliseSale.fulfilled), () => []);
  },
});

export const {
  setTxnInProgressSelectedPayments,
  updateTxnInProgressSelectedPayments,
  resetTxnInProgressSelectedPayments,
} = slice.actions;

export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const isMenuMessageModalDisplayed = sessionStorage.getItem('menuMessageModalDisplayed');

const slice = createSlice({
  name: 'menuMessageModalDisplayed',
  initialState: isMenuMessageModalDisplayed ? true : false,
  reducers: {
    setMenuMessageModalDisplayed(state, action: PayloadAction<boolean>) {
      sessionStorage.setItem('menuMessageModalDisplayed', 'true');
      return action.payload;
    },
  },
});

export const { setMenuMessageModalDisplayed } = slice.actions;

export default slice.reducer;

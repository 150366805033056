import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setCurrentModalId } from './currentModalId';

const initialState = null as null | ModalData;

const slice = createSlice({
  name: 'returnModalDetails',
  initialState,
  reducers: {
    setReturnModalDetails(state, action: PayloadAction<null | ModalData>) {
      return action.payload || initialState;
    },
  },

  extraReducers: builder => {
    builder.addCase(setCurrentModalId, (state, action) => {
      if (action.payload == null) {
        return initialState;
      }

      return state;
    });
  },
});

export const { setReturnModalDetails } = slice.actions;

export default slice.reducer;

import { createSelector } from 'reselect';
import * as lodash from 'lodash';
import getItems from './getItems';

export const getAvailableTags = createSelector([getItems], items => {
  let allTags: number[] = [];
  lodash.keys(items).forEach((key: string) => {
    //@ts-ignore
    if (items[key].tags) allTags = [...allTags, ...items[key].tags];
  });
  return lodash.uniq(allTags).sort((a, b) => a - b);
});

export const getItemMaxPrice = createSelector([getItems], items => {
  return items && Object.keys(items).length > 0
    ? lodash.orderBy(
        lodash
          .keys(items)
          .map(key => items[key])
          .filter(item => item.baseMoneyPrice),
        'baseMoneyPrice',
        ['desc'],
      )[0]['baseMoneyPrice'] / 100
    : 0;
});

import { createSelector } from 'reselect';

import getStagedPurchases, { $getStagedPurchases } from './getStagedPurchases';

const get = (stagedPurchases: PurchaseWithTotals[]): number =>
  (stagedPurchases || []).reduce((acc, current) => acc + current.quantity, 0);

export default createSelector([getStagedPurchases], get);

export const $getStagedPurchaseCount = createSelector(
  [$getStagedPurchases],
  get,
);

import React from 'react';
import { useTranslation } from 'react-i18next';
import combineStyles from '../utils/combineStyles';
import { useAppSelector } from '../app/hooks';
import Text from './Text';
import { OrderingConstants } from 'polygon-ordering';

const { ORDERING_PROVIDERS } = OrderingConstants;

const DeliveryTimeWarning = () => {
  const { t } = useTranslation();
  const thresholdOrderingProviders = useAppSelector(
    state => state.config.thresholdOrderingProviders,
  );

  const thesholdPresent = (thresholdOrderingProviders || []).some(
    // eslint-disable-next-line eqeqeq
    (provider: any) => provider == ORDERING_PROVIDERS.REDCAT,
  );
  return (
    <div>
      {thesholdPresent && (
        <div className="px-4" style={{ margin: 15 }}>
          <Text
            themeKey="deliveryTimeMayVaryMessage"
            style={combineStyles(styles.title, styles.deliveryTimeMayVaryMessage)}
            block
            lines={2}
          >
            {t('deliveryTimeMayVaryMessage')}
          </Text>
        </div>
      )}
    </div>
  );
};

export default DeliveryTimeWarning;

const styles: Styles = {
  title: {
    fontSize: 12,
  },
};

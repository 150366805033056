import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearBuffer } from '../../actions/buffer';
import moment from 'moment';

const initialState = null as string | null;

const slice = createSlice({
  name: '$bufferTimeEstimateReturned',
  initialState,
  reducers: {
    setBufferTimeEstimateReturned(state, action: PayloadAction<string | null>) {
      let value = moment().toISOString();
      return value;
    },
  },
  extraReducers: builder => {
    builder.addCase(clearBuffer, (state, action) => {
      return initialState;
    });
  },
});

export const { setBufferTimeEstimateReturned } = slice.actions;
export default slice.reducer;

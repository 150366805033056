import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthenticationMethod } from '../utils/Api';
import Api, { FetchParams } from '../utils/Api';
import processFavouriteOrder from '../utils/processors/processFavouriteOrder';
import { setFavouriteOrders } from '../reducers/favouriteOrders';
import { getMember } from '../selectors';
import { getOrderingProvider } from '../selectors/config';
import { ORDERING_PROVIDER } from '../constants/orderingProvider';

export const fetchFavouriteOrders = createAsyncThunk(
  '$fetchFavouriteOrders',
  async (
    data: {
      authenticationMethod?: AuthenticationMethod;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      const member = getMember(getState() as EntireFrontendState);
      const orderingProvider = getOrderingProvider(
        getState() as EntireFrontendState,
      );

      const { authenticationMethod = member ? 'member' : 'none' } = data;
      const params: FetchParams = {
        path:
          orderingProvider === ORDERING_PROVIDER.KIOSK
            ? `/api/v1/profile/favourites/trusted-pospoints/${member?.memberNumber}`
            : '/api/v1/profile/favourites',
        method: 'GET',
      };

      const response = await Api.fetch(params, authenticationMethod);

      const rawOrders: RawFavouriteOrder[] = response.data;

      const processedOrders = rawOrders.map(processFavouriteOrder);
      dispatch(setFavouriteOrders(processedOrders));
    } catch (e) {
      console.warn('Fetch favourite order failed', { e });
      return rejectWithValue(e);
    }
  },
);

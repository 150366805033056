type Hook = () => void;
type HookKey = "SIGN_OUT";
type HookStore = Record<HookKey, Hook | undefined>;

export default class AuthHook {
  static hooks: HookStore = {} as HookStore;

  static subscribe(hookName: HookKey, handler: Hook) {
    AuthHook.hooks[hookName] = handler;
  }

  static clear(hookName: HookKey) {
    AuthHook.hooks[hookName] = undefined;
  }

  static get(hookName: HookKey) {
    return AuthHook.hooks[hookName];
  }
}

import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';
import { resetOrder } from '../../actions/currentOrder';
import {
  adjustOpenPurchaseChoice,
  setOpenPurchaseChoiceSelections,
  changeOpenPurchaseItem,
  openPurchase,
  openStagedPurchase,
  resetOpenPurchaseChoiceSets,
  stageOpenPurchase,
} from '../../operations';

const initialState = null as _Purchase | null;

const slice = createSlice({
  name: '$openPurchase',
  initialState,
  reducers: {
    setOpenPurchase(state, action: PayloadAction<_Purchase>) {
      return action.payload || initialState;
    },
    adjustOpenPurchaseQuantity(state, action: PayloadAction<number>) {
      if (state) {
        return {
          ...state,
          quantity: state.quantity + action.payload,
        };
      } else {
        return initialState;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addCase(applyBufferData, (state, action) => {
        const { openPurchase } = action.payload;
        return openPurchase === undefined ? state : openPurchase;
      })
      .addMatcher(
        isAnyOf(
          changeOpenPurchaseItem.fulfilled,
          adjustOpenPurchaseChoice.fulfilled,
          resetOpenPurchaseChoiceSets.fulfilled,
          openPurchase.fulfilled,
          openStagedPurchase.fulfilled,
          // olo3:
          setOpenPurchaseChoiceSelections.fulfilled,
        ),
        (state, action) => {
          return action.payload?.purchase || initialState;
        },
      )
      .addMatcher(isAnyOf(stageOpenPurchase.fulfilled), () => initialState);
  },
});

export const { setOpenPurchase, adjustOpenPurchaseQuantity } = slice.actions;
export default slice.reducer;

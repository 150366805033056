import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';

const initialState = false;

const slice = createSlice({
  name: '$userUnderage',
  initialState,
  reducers: {
    setUserUnderage(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, () => {
      return initialState;
    });
  },
});

export const { setUserUnderage } = slice.actions;
export default slice.reducer;

import processFavouriteItem from './processFavouriteItem';

export default function (rawFavourite: RawFavouriteOrder): FavouriteOrder {
  return {
    id: String(rawFavourite.ID),
    name: String(rawFavourite.Description),
    default: Boolean(rawFavourite.Default),
    items: rawFavourite.Items.map(item =>
      processFavouriteItem(item, rawFavourite.MIM ?? false),
    ),
  };
}

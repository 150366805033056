import { createSelector } from 'reselect';

// utils
import { getInitialFieldValues } from '../utils/fields';

import getRegisterProfileFields from './getRegisterProfileFields';

export default createSelector(
  [getRegisterProfileFields, (_: any, url: any) => url],
  (registrationFields, url) => {
    const visible = registrationFields?.visible;
    const hidden = registrationFields?.hidden;
    const registrationCode = url.state?.registrationCode || null;

    const paths = url.pathname.split('/');

    const referringMember = url.pathname.includes('/referringMember/')
      ? paths[paths.length - 1]
      : null;

    return {
      visible: {
        ...getInitialFieldValues(visible),
        ...(registrationCode ? { RegistrationCode: registrationCode } : {}),
        ...(referringMember ? { Referrer: referringMember } : {}),
      },
      hidden: { ...getInitialFieldValues(hidden) },
    };
  },
);

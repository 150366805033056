import React from 'react';
import GiftCardForm from '../components/GiftCardForm';

export const CHECK_BALANCE_ROUTE = '/check-balance';
const GiftCardCheckBalanceScreen: React.FC = () => {
  return (
    <div>
      <GiftCardForm checkBalance />
    </div>
  );
};

export default GiftCardCheckBalanceScreen;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: 'creditLoyaltyEnabled',
  initialState,
  reducers: {
    setCreditLoyaltyEnabled(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setCreditLoyaltyEnabled } = slice.actions;

export default slice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  MIN_CC_AMOUNT_DEFAULT,
  MIN_CC_AMOUNT_NOT_MET,
  PAYMENT_GATEWAY_PAYMENT_METHODS,
} from '../constants';
import {
  getCreditCardPaymentInformation,
  getGiftCard,
  getMember,
  getOrderTotals,
  getSelectedPaymentMethods,
  getLoyaltyTopUpValue,
} from '../selectors';
import lodash from 'lodash';
import { setRemainingTotal } from '../reducers/currentOrder/remainingTotal';
import {
  setSelectedPaymentMethods,
  removeSelectedPaymentMethod,
  addSelectedPaymentMethod,
} from '../reducers/currentOrder/selectedPaymentMethods';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export const updateSelectedPaymentMethods = createAsyncThunk(
  '$updateSelectedPaymentMethods',
  async (
    data: {
      paymentMethod?: PAYMENT_METHOD | null;
      alreadySelected?: boolean | null;
      giftCardValidated?: boolean;
      clearPaymentMethods?: boolean;
      multiPayment?: boolean;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const {
      paymentMethod,
      alreadySelected,
      giftCardValidated,
      clearPaymentMethods,
      multiPayment,
    } = data;

    try {
      // Use creditCardPaymentInformation to see what the minimum credit card charge is
      const creditCardPaymentInformation = getCreditCardPaymentInformation(
        getState() as EntireFrontendState,
      );

      // Get order totals before assigning amounts to each method
      const orderTotals = getOrderTotals(getState() as EntireFrontendState);

      let selectedPaymentMethods = getSelectedPaymentMethods(
        getState() as EntireFrontendState,
      );
      /**
       * Payment methods should act like radio buttons. When you select one another one becomes
       * unselected. This becomes more complicated when in multi-payment mode.
       *
       * A user can use a gift card and/or member cash as a partial payment and pay the rest
       * on credit card.
       *
       * Member points or member rewards cannot be used for partial payment. If a user selects either
       * of these payment methods it should unselect any other payment methods.
       *
       * If a gift card or member cash has enough of a blance to pay the entire order then it cannot
       * be used for partial payment. If a user selects it then is should unselect any other
       * payment methods.
       *
       * https://www.redcatht.com/helpcentre/multiple-payments-for-web-and-app-ordering#multiple-payments
       */
      if (paymentMethod) {
        for (let selectedPaymentMethod of selectedPaymentMethods) {
          if (
            !multiPayment ||
            [
              PAYMENT_METHOD.MEMBER_POINTS,
              PAYMENT_METHOD.MEMBER_REWARDS,
            ].includes(selectedPaymentMethod.method)
          ) {
            dispatch(removeSelectedPaymentMethod(selectedPaymentMethod.method));
          }
          if (
            selectedPaymentMethod.method === PAYMENT_METHOD.GIFT_CARD &&
            paymentMethod === PAYMENT_METHOD.EFTPOS &&
            (selectedPaymentMethod?.amount || 0) >=
              (orderTotals?.discountedMoneyPrice || 0)
          ) {
            dispatch(removeSelectedPaymentMethod(selectedPaymentMethod.method));
          }
          if (
            selectedPaymentMethod.method === PAYMENT_METHOD.MEMBER_MONEY &&
            paymentMethod === PAYMENT_METHOD.EFTPOS &&
            (selectedPaymentMethod?.amount || 0) >=
              (orderTotals?.discountedMoneyPrice || 0)
          ) {
            dispatch(removeSelectedPaymentMethod(selectedPaymentMethod.method));
          }
          if (
            selectedPaymentMethod.method === PAYMENT_METHOD.CREDIT_CARD &&
            paymentMethod === PAYMENT_METHOD.GIFT_CARD &&
            (selectedPaymentMethods.find(
              spm => spm.method === PAYMENT_METHOD.GIFT_CARD,
            )?.amount || 0) >= (orderTotals?.discountedMoneyPrice || 0)
          ) {
            dispatch(removeSelectedPaymentMethod(selectedPaymentMethod.method));
          }
        }
      }

      // This block only runs if we're passing in a paymentMethod
      if (paymentMethod) {
        if (alreadySelected) {
          dispatch(removeSelectedPaymentMethod(paymentMethod));
        } else {
          dispatch(
            addSelectedPaymentMethod({ method: paymentMethod, amount: 0 }),
          );
        }
      }

      selectedPaymentMethods = clearPaymentMethods
        ? []
        : (paymentMethod === PAYMENT_METHOD.MEMBER_POINTS &&
            !alreadySelected) ||
          (paymentMethod === PAYMENT_METHOD.MEMBER_REWARDS &&
            !alreadySelected) ||
          (paymentMethod === PAYMENT_METHOD.PAY_LATER && !alreadySelected)
        ? [{ method: paymentMethod, amount: 0 }]
        : getSelectedPaymentMethods(getState() as EntireFrontendState);

      // Create 2 empty lists, nonPG and PG
      const nonPaymentGatewayMethods: SubPayment[] = [];
      const paymentGatewayMethods: SubPayment[] = [];

      // The final list containing calculated methods and amounts
      const updatedSelectedPaymentMethods = [];

      // Assign selected methods to either nonPG or PG list
      selectedPaymentMethods.forEach((subPayment: any) => {
        if (PAYMENT_GATEWAY_PAYMENT_METHODS.includes(subPayment.method)) {
          paymentGatewayMethods.push(subPayment);
        } else {
          nonPaymentGatewayMethods.push(subPayment);
        }
      });

      // Need to use pointsPrice if paying with points, otherwise discountedMoneyPrice
      let remainingTotal =
        (lodash.find(selectedPaymentMethods, [
          'method',
          PAYMENT_METHOD.MEMBER_POINTS,
        ])
          ? orderTotals!.pointsPrice
          : orderTotals!.discountedMoneyPrice) ||
        getLoyaltyTopUpValue(getState() as EntireFrontendState);

      const minAmount = creditCardPaymentInformation
        ? creditCardPaymentInformation.minAmount
          ? creditCardPaymentInformation.minAmount
          : 0
        : 0;

      const minPercent = creditCardPaymentInformation
        ? creditCardPaymentInformation.minPercent
          ? creditCardPaymentInformation.minPercent
          : 0
        : 0;

      const MIN_CC_AMOUNT = creditCardPaymentInformation
        ? minAmount + remainingTotal! * (minPercent / 100)
        : MIN_CC_AMOUNT_DEFAULT;

      // Perform calculations for nonPG list
      for (const nonPaymentGatewayMethod of nonPaymentGatewayMethods) {
        let balance = 0;
        let amount: number;
        // Get balance based on payment method
        switch (nonPaymentGatewayMethod.method) {
          case PAYMENT_METHOD.MEMBER_MONEY: {
            const member = getMember(getState() as EntireFrontendState);
            balance = member!.moneyBalance;
            break;
          }
          case PAYMENT_METHOD.MEMBER_POINTS:
            const member = getMember(getState() as EntireFrontendState);
            balance = member!.pointsBalance;
            break;
          case PAYMENT_METHOD.MEMBER_REWARDS: {
            const member = getMember(getState() as EntireFrontendState);
            balance = member!.pointsBalance * 100; // points and rewards share the same field
            break;
          }
          case PAYMENT_METHOD.GIFT_CARD: {
            const giftCard = getGiftCard(getState() as EntireFrontendState);
            // If a valid gift card is found, we get the balance, otherwise balance is 0
            balance = giftCard.moneyBalance || 0;
            break;
          }
          case PAYMENT_METHOD.PAY_LATER: {
            balance = remainingTotal;
            break;
          }
          default:
            break;
        }
        // Calculate amount to charge based on balance and remainingTotal
        if (balance && balance >= remainingTotal!) {
          amount = remainingTotal;
        } else {
          // Balance not enough to cover remainingTotal, so points, rewards and pay later cannot be used
          amount = lodash.find(selectedPaymentMethods, [
            (selectedPaymentMethod: any) => {
              return (
                selectedPaymentMethod.method === PAYMENT_METHOD.MEMBER_POINTS ||
                selectedPaymentMethod.method ===
                  PAYMENT_METHOD.MEMBER_REWARDS ||
                selectedPaymentMethod.method === PAYMENT_METHOD.PAY_LATER
              );
            },
          ])
            ? 0
            : balance;
        }

        // Add new object with updated amount to temp list
        updatedSelectedPaymentMethods.push({
          method: nonPaymentGatewayMethod.method,
          amount: amount,
          giftCardValidated: giftCardValidated ? true : undefined,
        });

        // Update local remainingTotal
        remainingTotal -= amount;
      }

      // Calculate remaining charge for PG method (should be only 1 item in list)
      if (paymentGatewayMethods.length > 1) {
        throw new Error('more than 1 payment gateway method selected');
      } else if (paymentGatewayMethods.length === 1) {
        let paymentGatewayAmount = remainingTotal;
        let difference = 0;

        if (remainingTotal < MIN_CC_AMOUNT) {
          if (updatedSelectedPaymentMethods.length === 0) {
            // No other payment methods to deduct from
            throw new Error(MIN_CC_AMOUNT_NOT_MET);
          } else {
            if (orderTotals!.discountedMoneyPrice < MIN_CC_AMOUNT) {
              // Order total is too low
              throw new Error(MIN_CC_AMOUNT_NOT_MET);
            } else {
              if (remainingTotal === 0) {
                // User has at least one non-payment gateway method selected and it's balance is enough to cover the entire order
                paymentGatewayAmount = 0;
              } else {
                // Deduct from other methods to bring payment gateway method above MIN_CC_AMOUNT
                difference = MIN_CC_AMOUNT - remainingTotal;
                paymentGatewayAmount += difference;
                let amountToDeduct = difference;
                let currentIndex = updatedSelectedPaymentMethods.length - 1;
                while (amountToDeduct > 0) {
                  let originalAmount =
                    updatedSelectedPaymentMethods[currentIndex].amount;
                  if (originalAmount < amountToDeduct) {
                    updatedSelectedPaymentMethods[currentIndex].amount = 0;
                    amountToDeduct -= originalAmount;
                    currentIndex--;
                  } else {
                    updatedSelectedPaymentMethods[currentIndex].amount =
                      originalAmount - amountToDeduct;
                    amountToDeduct = 0;
                  }
                }
              }
            }
          }
        } else {
          // Charge remaining to payment gateway method
          paymentGatewayAmount = remainingTotal;
        }

        updatedSelectedPaymentMethods.push({
          method: paymentGatewayMethods[0].method,
          amount: paymentGatewayAmount,
          giftCardValidated: undefined,
        });

        remainingTotal -= paymentGatewayAmount - difference;
      }

      /**
       * Credit card is already selected. User then selects gift card or member
       * money. The gift card or member money balance is enough to clear the whole
       * purchase. Need to therefore deselect credit card or member money.
       */
      if (
        paymentMethod &&
        [PAYMENT_METHOD.GIFT_CARD, PAYMENT_METHOD.MEMBER_MONEY].includes(
          paymentMethod,
        ) &&
        updatedSelectedPaymentMethods
          .map(spm => spm.method)
          .includes(PAYMENT_METHOD.EFTPOS)
      ) {
        if (
          updatedSelectedPaymentMethods.find(
            uspm => uspm.method === PAYMENT_METHOD.EFTPOS,
          )?.amount === 0
        ) {
          dispatch(removeSelectedPaymentMethod(PAYMENT_METHOD.CREDIT_CARD));
          const elementToRemove = updatedSelectedPaymentMethods.find(
            uspm => uspm.method === PAYMENT_METHOD.EFTPOS,
          );
          const index = elementToRemove
            ? updatedSelectedPaymentMethods.indexOf(elementToRemove)
            : -1;
          if (index !== -1) updatedSelectedPaymentMethods.splice(index, 1);
        }
      }

      /**
       * Gift card is already selected. User then selects member money. The
       * member money balance is enough to clear the whole purchase. Need
       * to therefore deselect gift card. The totals need to be updated
       * accordingly.
       */
      if (
        paymentMethod === PAYMENT_METHOD.MEMBER_MONEY &&
        updatedSelectedPaymentMethods
          .map(spm => spm.method)
          .includes(PAYMENT_METHOD.GIFT_CARD)
      ) {
        if (
          updatedSelectedPaymentMethods.find(
            uspm => uspm.method === PAYMENT_METHOD.MEMBER_MONEY,
          )?.amount === 0
        ) {
          dispatch(removeSelectedPaymentMethod(PAYMENT_METHOD.GIFT_CARD));
          const elementToRemove = updatedSelectedPaymentMethods.find(
            uspm => uspm.method === PAYMENT_METHOD.GIFT_CARD,
          );
          const index = elementToRemove
            ? updatedSelectedPaymentMethods.indexOf(elementToRemove)
            : -1;
          if (index !== -1) {
            updatedSelectedPaymentMethods.splice(index, 1);
          }
          for (let i = 0; i < updatedSelectedPaymentMethods.length; i++) {
            if (
              updatedSelectedPaymentMethods[i].method ===
              PAYMENT_METHOD.MEMBER_MONEY
            ) {
              updatedSelectedPaymentMethods[i].amount =
                orderTotals?.discountedMoneyPrice || 0;
              break;
            }
          }
        }
      }

      /**
       * Member money is already selected. User then selects gift card. The
       * gift card balance is enough to clear the whole purchase. Need to
       * therefore deselect member money. The totals need to be updated accordingly.
       */
      if (
        paymentMethod === PAYMENT_METHOD.GIFT_CARD &&
        updatedSelectedPaymentMethods
          .map(spm => spm.method)
          .includes(PAYMENT_METHOD.MEMBER_MONEY)
      ) {
        if (
          updatedSelectedPaymentMethods.find(
            uspm => uspm.method === PAYMENT_METHOD.GIFT_CARD,
          )?.amount === 0
        ) {
          dispatch(removeSelectedPaymentMethod(PAYMENT_METHOD.MEMBER_MONEY));
          const elementToRemove = updatedSelectedPaymentMethods.find(
            uspm => uspm.method === PAYMENT_METHOD.MEMBER_MONEY,
          );
          const index = elementToRemove
            ? updatedSelectedPaymentMethods.indexOf(elementToRemove)
            : -1;
          if (index !== -1) {
            updatedSelectedPaymentMethods.splice(index, 1);
          }
          for (let i = 0; i < updatedSelectedPaymentMethods.length; i++) {
            if (
              updatedSelectedPaymentMethods[i].method ===
              PAYMENT_METHOD.GIFT_CARD
            ) {
              updatedSelectedPaymentMethods[i].amount =
                orderTotals?.discountedMoneyPrice || -1;
              break;
            }
          }
        }
      }

      dispatch(
        setSelectedPaymentMethods(
          clearPaymentMethods ? [] : updatedSelectedPaymentMethods,
        ),
      );

      dispatch(setRemainingTotal(remainingTotal));
    } catch (e) {
      console.warn('Update selected payment method failed', { e });
      return rejectWithValue(e);
    }
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as number | null;

const slice = createSlice({
  name: '$saleInputType',
  initialState,
  reducers: {
    setSaleInputType(state, action: PayloadAction<number | null>) {
      return action.payload;
    },
  },
});

export const { setSaleInputType } = slice.actions;
export default slice.reducer;

import { dollarsToCents } from '../misc';

export default function processReceiptItem(
  rawItem: RawReceiptItem,
): ReceiptItem {
  return {
    id: rawItem.PLUCode,
    price: dollarsToCents(rawItem.Price),
    quantity: rawItem.Qty,
    ingredients: flattenItems(rawItem.Ingredients).map(processReceiptItem),
    name: rawItem.Item,
  };
}

function flattenItems(ingredients: RawReceiptItem[] = []): RawReceiptItem[] {
  const flattenedIngredients: RawReceiptItem[] = [];

  ingredients.forEach(ingredient => {
    const withoutNested = { ...ingredient, Ingredients: [] };

    flattenedIngredients.push(withoutNested);

    ingredient.Ingredients.forEach(nestedIngredient => {
      flattenedIngredients.push(nestedIngredient);
    });
  });

  return flattenedIngredients;
}

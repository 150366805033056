import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setCurrentModalId } from './currentModalId';

const initialState = null as null | ModalDataParams;

const slice = createSlice({
  name: 'modalParams',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      setCurrentModalId,
      (state, action: PayloadAction<ModalData | null | string>) => {
        if (typeof action.payload === 'string') {
          return initialState;
        }

        const { params } = action.payload || {};
        return params || initialState;
      },
    );
  },
});

// export const {} = slice.actions;

export default slice.reducer;

import { centsToDollarString } from '../misc';

export default function applePayItemFromPurchase(
  purchase: PurchaseWithTotals,
): ApplePayItem {
  return {
    label: purchase?.item?.name || 'Item',
    amount: centsToDollarString(purchase.discountedMoneyPrice),
  };
}

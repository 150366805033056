export function convertCoordsToShortKeys(location: LocationCoordinates) {
  return { lat: location.latitude, lng: location.longitude };
}

export const getNearbyStreetName = (givenAddress: any) => {
  let apartmentNumber = '';
  let streetNumber = '';
  let streetName = '';

  for (let i = 0; i < givenAddress.address_components.length; i++) {
    if (givenAddress.address_components[i].types[0] === 'subpremise') {
      apartmentNumber = givenAddress.address_components[i].long_name + '/';
    }

    if (givenAddress.address_components[i].types[0] === 'street_number') {
      streetNumber = givenAddress.address_components[i].long_name;
    }

    if (givenAddress.address_components[i].types[0] === 'route') {
      streetName = givenAddress.address_components[i].short_name;
    }
  }

  return apartmentNumber + streetNumber + ' ' + streetName;
};

export const getNearbyLocality = (givenAddress: any) => {
  let city = '';
  let region = '';
  let postalCode = '';

  for (let i = 0; i < givenAddress.address_components.length; i++) {
    if (givenAddress.address_components[i].types[0] === 'locality') {
      city = givenAddress.address_components[i].long_name;
    }

    if (givenAddress.address_components[i].types[0] === 'administrative_area_level_1') {
      region = givenAddress.address_components[i].short_name;
    }
    if (givenAddress.address_components[i].types[0] === 'postal_code') {
      postalCode = givenAddress.address_components[i].long_name;
    }
  }

  return city + ' ' + region + ' ' + postalCode;
};

import { createSelector } from 'reselect';

import { generateSchemaForFields } from '../utils/fields';

import getOAuthExtraFields from './getOAuthExtraFields';
import getConfig from './getConfig';
import { OrderingSelectors, OrderingConstants } from 'polygon-ordering';

const { getInternationalMobileAllowed, getMobileRegexWhiteList } = OrderingSelectors;
const { MOBILE_REGEX_INT, MOBILE_REGEX_AU } = OrderingConstants;

export default createSelector(
  [getOAuthExtraFields, getConfig, getInternationalMobileAllowed, getMobileRegexWhiteList],
  (oauthFields, config, internationalMobileAllowed, mobileRegexWhiteList) => {
    const { hidden, visible } = oauthFields!;

    const newConfig: ConfigState = {
      ...config,
      internationalMobileAllowed,
      mobileRegexInt: MOBILE_REGEX_INT,
      mobileRegexAU: MOBILE_REGEX_AU,
      mobileRegexWhiteList,
    };
    return {
      visible: generateSchemaForFields(visible, newConfig),
      hidden: generateSchemaForFields(hidden, newConfig),
    };
  },
);

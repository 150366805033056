// Imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Default Payment Country Code State
const defaultPaymentCountryCode = 'AU';

const slice = createSlice({
  name: 'paymentCountryCode',
  initialState: defaultPaymentCountryCode,
  reducers: {
    setPaymentCountryCode(state, action: PayloadAction<string>) {
      return action.payload;
    },
  },
});

export const { setPaymentCountryCode } = slice.actions;

export default slice.reducer;

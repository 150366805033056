import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: '$hideNegativeIngredients',
  initialState,
  reducers: {
    setHideNegativeIngredients(state, action: PayloadAction<boolean>) {
      return Boolean(action.payload);
    },
  },
});

export const { setHideNegativeIngredients } = slice.actions;
export default slice.reducer;

import { createSelector } from 'reselect';
import lodash from 'lodash';

import getSaleDetails from './getSaleDetails';
import getItems from './getItems';

// stub a bunch of things to make TS happy
export default createSelector(
  [getSaleDetails, getItems],
  (saleDetails, allItems = {}): PurchaseWithTotals[] => {
    //@ts-ignore
    return (
      lodash
        .chain(saleDetails)
        .get('items', [])
        //@ts-ignore
        .map(receiptItem => {
          const item = allItems[receiptItem.id] || { name: receiptItem.name };

          return {
            ...receiptItem,

            id: String(receiptItem.id),
            item,
            discountedMoneyPrice: receiptItem.price,
            //@ts-ignore
            choicesWithQuantity: receiptItem.ingredients.map(ingredient => ({
              quantity: ingredient.quantity,
              moneyPrice: ingredient.price,
              id: String(ingredient.id),
              name: ingredient.name,
              description: '',
              baseMoneyPrice: 0,
              basePointsPrice: 0,
              basePointsAward: 0,
              baseQuantity: 0,
              images: {},
            })),

            valid: true,
            multiSized: false,
            popularChoices: [],
            sizes: [],
            choiceSets: [],
            baseMoneyPrice: 0,
            basePointsPrice: 0,
            pointsPrice: 0,
            pointsAward: 0,
            moneyPrice: 0,
            moneyDiscount: 0,
            brandId: receiptItem.brandId,
          };
        })
        .value()
    );
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import createFavouriteItemFromShadowItem, {
  filterSystemPLUs,
} from '../utils/ordering/createFavouriteItemFromShadowItem';
import Api, { FetchParams } from '../utils/Api';
import { fetchFavouriteOrders } from './fetchFavouriteOrders';

export const createFavouriteOrder = createAsyncThunk(
  '$createFavouriteOrder',
  async (
    data: { name: string; items: ShadowItem[] },
    { dispatch, rejectWithValue },
  ) => {
    const { name, items } = data;

    try {
      const params: FetchParams = {
        path: '/api/v1/profile/favourites',
        method: 'POST',
        body: {
          Description: name,
          Items: filterSystemPLUs(
            items.map(shadowItem =>
              createFavouriteItemFromShadowItem(shadowItem),
            ),
          ),
          MIM: true,
        },
      };

      await Api.fetch(params, 'member');
      await dispatch(fetchFavouriteOrders({}));
    } catch (e) {
      console.error('Create favourite order failed', { e });
      return rejectWithValue(e);
    }
  },
);

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightFill, RiAlertFill } from 'react-icons/ri';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';

import { OrderingSelectors } from 'polygon-ordering';

import history from '../history';

import { DESKTOP_MODAL_TYPES } from '../constants';

import { setCurrentModal } from '../thunks/setCurrentModal';

import getInvalidStagedPurchasePresent from '../selectors/getInvalidStagedPurchasePresent';
import getMinimumOrderAmountNotMet from '../selectors/getMinimumOrderAmountNotMet';
import getOrderInProgress from '../selectors/getOrderInProgress';

import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import OrderSummaryColumn from '../components/OrderSummaryColumn';

import { REVIEW_ORDER_SCREEN_ROUTE } from '../screens/ReviewOrderScreen';
import { COMPLETED_ORDER_SCREEN_ROUTE } from '../hooks/useRoutes';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { JOIN_LOYALTY_MODAL } from './JoinLoyaltyModal';

import OrderTotals from '../components/OrderTotals';
import moment from 'moment';

const {
  getOrderReadyToSubmitSansPurchaser,
  getOrderSubmitted,
  getStagedPurchaseCount,
  getCartContainsSoldOutItems,
  getCartContainsInvalidQuantities,
  getShowMaxValue,
  getMember,
  getSurcharges,
  getGuestOrderingAvailable,
  getCartContainUnavailableItems,
} = OrderingSelectors;

export const ORDER_SUMMARY_MODAL_ID = 'ORDER_SUMMARY_MODAL_ID';

const OrderSummaryModal = ({}) => {
  const invalidStagedPurchasePresent = useAppSelector(getInvalidStagedPurchasePresent);
  const minimumOrderAmountNotMet = useAppSelector(getMinimumOrderAmountNotMet);
  const orderReadyToSubmitSansPurchaser = useAppSelector(getOrderReadyToSubmitSansPurchaser);
  const orderInProgress = useAppSelector(getOrderInProgress);
  const orderSubmitted = useAppSelector(getOrderSubmitted);
  const stagedPurchaseCount = useAppSelector(getStagedPurchaseCount);
  const cartContainSoldOutItems = useAppSelector(getCartContainsSoldOutItems);
  const cartContainsInvalidQuantities = useAppSelector(getCartContainsInvalidQuantities);
  const member = useAppSelector(getMember);
  const guestOrderingEnabled = useAppSelector(getGuestOrderingAvailable);
  const showMaxValueMessage = useAppSelector(getShowMaxValue);

  const loyalyDisabled = useAppSelector(state => state.config.disableLoyalty);
  const [timer, setTimer] = useState(false);
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimer(pre => !pre);
    }, 6000);
    return () => clearInterval(timerId);
  }, []);

  const cartContainUnavailableItems = useAppSelector(getCartContainUnavailableItems(timer));

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  if (pathname === COMPLETED_ORDER_SCREEN_ROUTE || !orderInProgress || orderSubmitted) {
    return null;
  }

  const hideCheckoutButton = pathname === REVIEW_ORDER_SCREEN_ROUTE || showMaxValueMessage;

  const hideTotals = false;
  const pointsVisible = false;
  const useMobileScreen = false;
  const surcharges = useAppSelector(getSurcharges);

  return (
    <Modal
      clickBackgroundToClose
      closeButtonLabel={t('button.modalClose.dismiss')}
      desktopModalType={DESKTOP_MODAL_TYPES.CALLOUT}
      desktopMaxWidth={420}
      desktopMinWidth={420}
      desktopContainerStyle={styles.modalDesktopContainer}
      mobileContainerStyle={styles.modalMobileContainer}
    >
      <PerfectScrollbar style={styles.scrollable}>
        <OrderSummaryColumn />
      </PerfectScrollbar>
      {!hideTotals && (Boolean(stagedPurchaseCount) || Boolean(surcharges.length)) && (
        <OrderTotals pointsVisible={pointsVisible} useMobileScreen={useMobileScreen} />
      )}
      {!hideCheckoutButton && (
        <StandardButton
          style={{
            margin: '0.75em',
          }}
          disabled={
            //@ts-ignore
            cartContainSoldOutItems ||
            cartContainsInvalidQuantities.cartContainsInvalidQuantities ||
            cartContainUnavailableItems ||
            minimumOrderAmountNotMet
          }
          themeKey="modalProceedButton"
          label={
            orderReadyToSubmitSansPurchaser
              ? t('button.orderSummary.checkout')
              : t('button.orderSummary.review')
          }
          LeftIconComponent={
            invalidStagedPurchasePresent || (minimumOrderAmountNotMet && stagedPurchaseCount)
              ? RiAlertFill
              : undefined
          }
          onClick={() => {
            if (member || !guestOrderingEnabled) {
              dispatch(setCurrentModal(null));
              history.push(REVIEW_ORDER_SCREEN_ROUTE);
            } else {
              if (!loyalyDisabled) dispatch(setCurrentModal(JOIN_LOYALTY_MODAL));
              else dispatch(setCurrentModal(null));
              history.push(REVIEW_ORDER_SCREEN_ROUTE);
            }
          }}
        />
      )}
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    height: '100%',
    padding: 0,
    gap: 0,
  },
  modalMobileContainer: {
    padding: 0,
    paddingTop: 15,
    gap: 0,
  },

  scrollable: {
    overflowY: 'auto',
    flex: 1,
    //padding: 15,
  },
};

export default OrderSummaryModal;

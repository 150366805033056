import Text from './Text';
import React from 'react';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;

const OrderDetailsPurchasesChoice = ({
  stagedPurchaseChoice,
  nested = false,
}: {
  stagedPurchaseChoice: ChoiceWithQuantity;
  nested?: boolean;
}) => {
  const price = useFormattedCurrency({ cents: stagedPurchaseChoice.baseMoneyPrice });

  return (
    <div style={styles.items}>
      <Text
        themeKey="orderDetailsCart"
        style={{ ...styles.description, paddingLeft: nested ? 20 : 10 }}
        value={stagedPurchaseChoice.name}
      />
      <Text
        themeKey="orderDetailsCart"
        style={styles.qty}
        value={String(stagedPurchaseChoice.quantity)}
      />
      <Text themeKey="orderDetailsCart" style={styles.price} value={price} />
    </div>
  );
};

export default OrderDetailsPurchasesChoice;

const styles = {
  description: { width: '50%' },
  qty: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
  points: {
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  price: {
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  items: { display: 'flex', opacity: 0.6 },
};

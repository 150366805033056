import { createSelector } from 'reselect';

import getSaleType from './getSaleType';
import getDeliverySaleBodyContent from './getDeliverySaleBodyContent';
import getDineInSaleBodyContent from './getDineInSaleBodyContent';
import getTableOrderSaleBodyContent from './getTableOrderSaleBodyContent';
import getPickupSaleBodyContent from './getPickupSaleBodyContent';
import getTakeAwaySaleBodyContent from './getTakeAwaySaleBodyContent';
import getCateringSaleBodyContent from './getCateringSaleBodyContent';

import { SALE_TYPE } from '../constants/saleType';

export default createSelector(
  [
    getSaleType,
    getDeliverySaleBodyContent,
    getPickupSaleBodyContent,
    getTakeAwaySaleBodyContent,
    getDineInSaleBodyContent,
    getTableOrderSaleBodyContent,
    getCateringSaleBodyContent,
  ],
  (saleType, delivery, pickup, takeaway, dinein, tableOrder, catering) => ({
    SaleType: saleType,

    ...(saleType === SALE_TYPE.DELIVERY ? delivery : {}),
    ...(saleType === SALE_TYPE.PICKUP ? pickup : {}),
    ...(saleType === SALE_TYPE.WEB_ORDERING ? pickup : {}), // behave the same as pickup
    ...(saleType === SALE_TYPE.TAKE_AWAY ? takeaway : {}),
    ...(saleType === SALE_TYPE.DINE_IN ? dinein : {}),
    ...(saleType === SALE_TYPE.TABLE_ORDER ? tableOrder : {}),
    ...(saleType === SALE_TYPE.CATERING ? catering : {}),
  }),
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = true;

const slice = createSlice({
  name: '$flattenCategories',
  initialState,
  reducers: {
    setFlattenCategories(state, action: PayloadAction<boolean>) {
      return Boolean(action.payload);
    },
  },
});

export const { setFlattenCategories } = slice.actions;
export default slice.reducer;

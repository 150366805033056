import { createSelector } from 'reselect';

import { OrderingSelectors } from 'polygon-ordering';

const { getSelectedOfferValid } = OrderingSelectors;

export default createSelector([getSelectedOfferValid], selectedOfferValid => {
  // has to be false, not undefined
  return selectedOfferValid === false;
});

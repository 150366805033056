import { createSelector } from 'reselect';

import { OrderingSelectors } from 'polygon-ordering';

import getCurrentSavedCardTokenExists from './getCurrentSavedCardTokenExists';
// import getSelectedPaymentMethods from './getSelectedPaymentMethods';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { RootState } from 'store';

const { getSelectedPaymentMethods } = OrderingSelectors;

const getCurrentSavedCardToken = (state: RootState) => state.currentSavedCardToken;

export default createSelector(
  [getCurrentSavedCardToken, getSelectedPaymentMethods, getCurrentSavedCardTokenExists],
  (currentSavedCardToken, selectedPaymentMethods, currentSavedCardTokenExists) => {
    let selectedPaymentMethodsIncludesSavedCard = selectedPaymentMethods.some(object => {
      if (object.method === PAYMENT_METHOD.SAVED_CARD) {
        return true;
      }
      return false;
    });

    if (!selectedPaymentMethodsIncludesSavedCard) {
      return undefined;
    }

    if (!currentSavedCardTokenExists) {
      return undefined;
    }

    return currentSavedCardToken;
  },
);

import { createSelector } from 'reselect';
import { partitionFieldsByVisibility, isNotExcluded } from '../utils/fields';
import getProfileFields from './getProfileFields';

export default createSelector([getProfileFields], profileFields => {
  if (profileFields) {
    const registerFields: RegisterFields[] = profileFields
      .map(field => ({
        ...field,
        registerMode: undefined,
        editMode: undefined,
        inputType: isNotExcluded(field, 'registerProfileScreen') ? field.inputType : 'hidden',
        mode: field.registerMode,
      }))
      .filter(field => field.mode !== 'disabled');

    return {
      all: registerFields,
      ...partitionFieldsByVisibility(registerFields),
    };
  }
});

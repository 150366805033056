import { createSelector } from 'reselect';

import { generateSchemaForFields } from '../utils/fields';

import getMemberFields from './getMemberFields';
import getConfig from './getConfig';

export default createSelector([getMemberFields, getConfig], (updateFields, config) => {
  const { visible } = updateFields!;

  return {
    visible: generateSchemaForFields(visible, config),
  };
});

import React from 'react';
import RadioCheck from './RadioCheck';
import Text from './Text';
interface IProps {
  choiceSet: ValidatedChoiceSet;
  adjustChoice: (params: AdjustChoiceParams) => void;
}
interface AdjustChoiceParams {
  clear: boolean;
  targetChoiceId: string;
  targetChoiceSetId: string;
}

const ChoiceSetCheckbox: React.FC<IProps> = ({ choiceSet, adjustChoice }) => {
  const choice = choiceSet.choices[0];

  if (!choice) {
    return null;
  }

  const checked = Boolean(choice.quantity);
  return (
    <div style={{ textTransform: 'capitalize' }}>
      <RadioCheck
        label={<Text>{choiceSet.name}</Text>}
        checked={checked}
        onClick={() =>
          adjustChoice({
            targetChoiceSetId: choiceSet.key as string,
            targetChoiceId: choice.id,
            clear: checked,
          })
        }
      />
    </div>
  );
};

export default ChoiceSetCheckbox;

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { partitionFieldsByVisibility, isNotExcluded } from '../utils/fields';

export default createSelector(
  [(state: RootState) => state.config.oauthExtraFields],
  (
    profileFields,
  ): { all: RegisterFields[]; visible: RegisterFields[]; hidden: RegisterFields[] } => {
    const oauthFields: RegisterFields[] = (profileFields || [])
      .map(field => ({
        ...field,
        registerMode: undefined,
        editMode: undefined,
        inputType: field.inputType,
        mode: field.registerMode,
      }))
      .filter(field => field.mode !== 'disabled');

    return {
      all: oauthFields,
      ...partitionFieldsByVisibility(oauthFields),
    };
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProductConfig } from '../selectors';
import { getEftposConfig, getPaymentEnvironment } from '../selectors/config';
import PaymentHooks from '../utils/PaymentHooks';
import { EFTPOS_STATUS_UPDATE } from '../constants';
import Logger from '../utils/Logger';

import { TyroWrapper, TyroWrapperResult } from 'polygon-utils';

export const tyroWrapper = new TyroWrapper(Logger as any);

export const tyroPayments = createAsyncThunk(
  '$tyroPayments',
  async (
    data: {
      amount: number;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { amount } = data;

      const { vendor, name, version } = getProductConfig(
        getState() as EntireFrontendState,
      );
      // const { apiKey } = getEftposConfig(getState() as EntireFrontendState);
      const { merchantId, terminalId, pairingKey, apiKey } = getEftposConfig(
        getState() as EntireFrontendState,
      );

      const paymentEnvironment = getPaymentEnvironment(
        getState() as EntireFrontendState,
      );

      if (
        !vendor ||
        !name ||
        !version ||
        !merchantId ||
        !terminalId ||
        !pairingKey ||
        !apiKey
      ) {
        throw new Error('missing necessary config');
      }

      await tyroWrapper.init(
        paymentEnvironment,
        apiKey,
        vendor,
        name,
        version,
        'thunk',
      );

      const hook: any = PaymentHooks.get(EFTPOS_STATUS_UPDATE);
      const transactionCompleteHook: any = PaymentHooks.get(
        'TRANSACTION_COMPLETED',
      );

      const statusCallback = (statusMsg: string, txnStarted: boolean) => {
        if (hook) {
          hook({ statusMsg: statusMsg, txnStarted: txnStarted });
        }
      };

      // Do not use the old shell beacuse we need a update Tyro Hook
      // Note: the payments will go through but it will hang,
      tyroWrapper.startPaymentTransaction(
        {
          amount: String(amount),
          integratedReceipt: true,
          integratedReceiptWidth: '40', // TODO: lift to config
          mid: parseInt(merchantId),
          tid: parseInt(terminalId),
          integrationKey: pairingKey,
        },
        hook ? statusCallback : undefined,
        transactionCompleteHook,
      );
    } catch (e) {
      console.warn('Tyro payment failed', { e });
      return rejectWithValue(e);
    }
  },
);

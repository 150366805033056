import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'hideTimesForSaleTypes',
  initialState: null as null | number[],
  reducers: {
    setHideTimesForSaleTypes(state, action: PayloadAction<number[]>) {
      return action.payload || null;
    },
  },
});

export const { setHideTimesForSaleTypes } = slice.actions;

export default slice.reducer;

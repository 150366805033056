import lodash from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';
import { applyBufferData } from '../../actions/buffer';

const initialState: Offer[] = [];

const mergeOffers = (previousOffers: Offer[] = [], newOffers: Offer[] = []) =>
  lodash.unionBy(newOffers, previousOffers, 'id');

const slice = createSlice({
  name: '$offers',
  initialState,
  reducers: {
    setOffers: (state, action: PayloadAction<Offer[] | null>) => {
      return action.payload === null ? initialState : action.payload;
    },

    addOffers: (state, action: PayloadAction<Offer[]>) => {
      return mergeOffers(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addCase(applyBufferData, (state, action) => {
        const { offers } = action.payload;
        return offers === undefined ? state : offers || initialState;
      });
  },
});

export const { setOffers, addOffers } = slice.actions;
export default slice.reducer;

import React, { useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import LocationSearch from './LocationSearch';
import MapWrapper from './MapWrapper';
import { getNearbyStreetName, getNearbyLocality } from '../utils/maps';
import { useFormikContext } from 'formik';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';

const { getMember } = OrderingSelectors;

const AddressSearchBox = () => {
  const [map, setMap] = useState<google.maps.places.Autocomplete>();
  const onLoad = React.useCallback(function callback(map: google.maps.places.Autocomplete) {
    setMap(map);
  }, []);
  const member = useAppSelector(getMember);

  const { setFieldValue } = useFormikContext();

  const geocoderCountryRestriction = useAppSelector(
    state => state.config.geocoderCountryRestriction,
  );

  const onPlacesChanged = () => {
    const places = map?.getPlace();
    if (places) {
      setFieldValue('formatted_address', places?.formatted_address);
      setFieldValue('street_name', getNearbyStreetName(places));
      setFieldValue('locality', getNearbyLocality(places));
      setFieldValue('lat', places?.geometry?.location?.lat());
      setFieldValue('lng', places?.geometry?.location?.lng());
      setFieldValue('buttonActive', true);
    }
  };

  return (
    <MapWrapper>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlacesChanged}
        restrictions={{
          country: geocoderCountryRestriction,
        }}
      >
        <LocationSearch placeholder="Enter your address" showSaveSwitch={Boolean(member)} />
      </Autocomplete>
    </MapWrapper>
  );
};

export default React.memo(AddressSearchBox);

interface Options {
  clipEmptyDecimals?: boolean;
  allowCentsMode?: boolean;
  dollarSymbol?: string;
  centSymbol?: string;
}

export default function (cents: number, options: Options = {}): string {
  const { clipEmptyDecimals, allowCentsMode, dollarSymbol = '$', centSymbol = '¢' } = options;

  if (allowCentsMode && cents && cents < 100 && cents > -100) {
    return `${cents}${centSymbol}`;
  }

  let result = '';

  if (cents != null) {
    result = `${cents < 0 ? '−' : ''}${dollarSymbol}${(Math.abs(cents) / 100).toFixed(2)}`;
  }

  if (clipEmptyDecimals && result.endsWith('.00')) {
    result = result.slice(0, -3);
  }

  return result;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'enableRequiredCurrentPasswordForUpdate',
  initialState: false,
  reducers: {
    setEnableRequiredCurrentPasswordForUpdate(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setEnableRequiredCurrentPasswordForUpdate } = slice.actions;

export default slice.reducer;

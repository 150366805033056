import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import SectionTitle from './SectionTitle';
import FormTextField from './FormTextField';
import { useFormikContext } from 'formik';

const DeliveryNotes: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const effectiveDeliveryAddress = useAppSelector(state => state.desiredDeliveryAddress);

  const { handleChange } = useFormikContext();
  return (
    <div className="px-2 mx-3">
      {effectiveDeliveryAddress && (
        <div>
          <SectionTitle value="DELIVERY NOTES" />
          <div className="m-3">
            <FormTextField
              name="deliveryNotes"
              label="Notes"
              onChange={handleChange}
              type="text"
              multiline
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DeliveryNotes;

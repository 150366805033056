import { sumObjectProperties } from '../misc';

export default function calculateTotalsForStagedPurchases(
  stagedPurchases: PurchaseWithTotals[],
): StagedPurchasesTotals {
  return (sumObjectProperties(stagedPurchases, [
    'pointsPrice',
    'pointsAward',
    'moneyPrice',
    'moneyDiscount',
    'discountedMoneyPrice',
  ]) as unknown) as StagedPurchasesTotals;
}

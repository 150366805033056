import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import StandardButton from '../components/StandardButton';
import { useFormikContext } from 'formik';
import FormTextField from '../components/FormTextField';
import { useAppSelector } from '../app/hooks';
import lodash from 'lodash';
import { OrderingSelectors } from 'polygon-ordering';

const { getCurrencySymbol } = OrderingSelectors;

const PurchaseGiftCardOptions: React.FC = () => {
  const { t } = useTranslation();
  const purchaseUnits = useAppSelector(state => state.config.purchaseUnits);
  const defaultPurchaseUnits = ['20', '50', '100', '200'];
  const buttons =
    purchaseUnits && !lodash.isEmpty(purchaseUnits) ? purchaseUnits : defaultPurchaseUnits;
  const formik = useFormikContext<FormikFields>();
  const currencySymbol = useAppSelector(getCurrencySymbol);

  return (
    <Row className=" mx-0 my-3">
      {buttons!.map((button, index) => (
        <Col xs="2" style={{ padding: '0px 0px', paddingLeft: index !== 0 ? 5 : 0 }} key={button}>
          <StandardButton
            label={`${currencySymbol}${button}`}
            themeKey="giftCardValueButton"
            containerStyle={{ padding: 5, height: 40 }}
            onClick={() => {
              formik.setFieldValue('Amount', button);
            }}
          />
        </Col>
      ))}

      <Col style={{ padding: '0px 0px', paddingLeft: 5 }}>
        <FormTextField
          type="text"
          name="Amount"
          label={t('giftCardTopUpCustomValue')}
          onChange={e => {
            formik.setFieldValue('Amount', e.target.value);
          }}
          disableStyles
        />
      </Col>
    </Row>
  );
};

export default PurchaseGiftCardOptions;

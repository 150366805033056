import React, { useState } from 'react';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core';
import getThemeLookup from '../selectors/getThemeLookup';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

import combineStyles from '../utils/combineStyles';

import '../styles/PurchaserDetails.css';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { ORDER_NOT_READY_REASON } from '../libs/polygon-ordering/src/constants';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
const { getPurchaser, getPurchaserInvalidReasons, getRequiredPurchaserDetails } = OrderingSelectors;

interface IProps {
  id: PurchaserDetails;
  handleChange: (value: string) => void;
  touched: {
    [key: string]: boolean;
  };
  type?: string;
  onBlur: (id: string) => void;
  errorReason: ORDER_NOT_READY_REASON;
}

const PurchaserField: React.FC<IProps> = ({
  id,
  handleChange,
  touched,
  errorReason,
  onBlur,
  type,
}) => {
  const purchaser = useAppSelector(getPurchaser);
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const purchaserInvalidReasons = useAppSelector(getPurchaserInvalidReasons);
  const requiredPurchaserDetails = useAppSelector(getRequiredPurchaserDetails);
  const placeholder = purchaser.effective[id];
  const inError = purchaserInvalidReasons.includes(errorReason);
  const required = requiredPurchaserDetails.includes(id);
  const value = purchaser.effective[id] || purchaser[id] || '';
  const showError = touched[id] && inError && Boolean(value);

  const useStyles = makeStyles(() => ({
    errors: {
      '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${p('materialTextFieldErrorBorder', ['color']).color} !important`,
        },
      },
    },
    root: {
      '& ::-webkit-calendar-picker-indicator': {
        filter: `invert(${p('fieldIcons', ['color']).color})`,
      },
      //label - placeholder background color
      '& .MuiFormLabel-root': {
        backgroundColor: p('labelBackgroundColor', ['color']).color,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
      },

      //text field errors
      '&  .MuiFormHelperText-root.Mui-error': {
        backgroundColor: p('helperBackground', ['color']).color,
        color: p('helperColor', ['color']).color,
      },
      // focused color for textfield with variant='outlined'
      '& .MuiOutlinedInput-root': {
        //textfield background color
        background: p('fieldBackground', ['color']).color,

        //text field border unfocused
        '& fieldset': {
          borderColor: p('fieldDefaultBorder', ['color']).color,
        },
        //text field border focused
        '&.Mui-focused fieldset': {
          borderColor: p('fieldBorderFocus', ['color']).color,
        },
        //text field border hover
        '&:hover fieldset': {
          borderColor: p('fieldHoverBorderColor', ['color']).color,
        },
      },
      // label color when unfocused
      '& .MuiInputLabel-root': {
        color: `${p('fieldDefaultBorder', ['color']).color} !important`,
      },
      // label color when focused
      '& .MuiInputLabel-root.Mui-focused': {
        color: `${p('fieldDefaultBorder', ['color']).color} !important`,
      },
      //checkbox
      '&$checked': {
        color: p('checkboxCheckedColor', ['color']).color,
      },
    },

    checked: {},
  }));

  const muiStyles = useStyles();

  const sharedInputProps = {
    InputProps: {
      style: {
        //textfield text filled colors
        color: p('textColor', ['color']).color,
        ...p('formTextFieldInput', ['color']),
      },
    },
    InputLabelProps: {
      style: {
        //textfield label - placeholder colors
        color: p('labelColor', ['color']).color,
      },
    },
  };

  return (
    <TextField
      size="small"
      type={type}
      label={t(`field.purchaserDetails.${id}`)}
      placeholder={placeholder!}
      helperText={
        showError
          ? t(`purchaserDetailInvalid.${id}`)
          : required && !placeholder
          ? `${t('field.requiredWarning')}`
          : ''
      }
      error={showError ? true : required && !placeholder && true}
      value={value}
      onChange={event => handleChange(event?.target?.value)}
      onBlur={() => onBlur(id)}
      fullWidth
      style={styles.inputField}
      variant="outlined"
      required={required}
      InputProps={sharedInputProps.InputProps}
      InputLabelProps={{
        shrink: true,
        ...sharedInputProps.InputLabelProps,
      }}
      className={
        showError
          ? muiStyles.root + ' ' + muiStyles.errors
          : required && !placeholder
          ? muiStyles.root + ' ' + muiStyles.errors
          : muiStyles.root
      }
    />
  );
};

const PurchaserDetails: React.FC = () => {
  const { setPurchaserName, setPurchaserFamilyName, setPurchaserMobile, setPurchaserEmail } =
    OrderingOperations;
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});
  const dispatch = useAppDispatch();

  const handleBlur = (key: string) => {
    if (!touched[key]) {
      setTouched({ ...touched, [key]: true });
    }
  };

  const fields = [
    {
      id: 'name',
      onChange: setPurchaserName,
      errorReason: ORDER_NOT_READY_REASON.PURCHASER_NAME_INVALID,
    },
    {
      id: 'familyName',
      onChange: setPurchaserFamilyName,
      errorReason: ORDER_NOT_READY_REASON.PURCHASER_FAMILY_NAME_INVALID,
    },
    {
      id: 'mobile',
      type: 'tel',
      onChange: setPurchaserMobile,
      errorReason: ORDER_NOT_READY_REASON.PURCHASER_MOBILE_INVALID,
    },
    {
      id: 'email',
      type: 'email',
      onChange: setPurchaserEmail,
      errorReason: ORDER_NOT_READY_REASON.PURCHASER_EMAIL_INVALID,
    },
  ];

  return (
    <div style={combineStyles(styles.mainContainer, styles.mainContainerDesktop)}>
      {fields.map(field => (
        <PurchaserField
          key={field.id}
          id={field.id as PurchaserDetails}
          type={field.type}
          handleChange={(value: string) => {
            dispatch(field.onChange(value));
            logEvent(EVENTS.CHANGE_PURCHASER_DETAILS);
          }}
          errorReason={field.errorReason}
          touched={touched}
          onBlur={handleBlur}
        />
      ))}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    width: '100%',
    //maxWidth: 500,
    padding: '0.75em 2.25em 0',
  },

  inputField: {
    marginTop: 10,
    marginBottom: 10,
  },
  inputLabel: {
    marginTop: -3,
    marginLeft: -5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 4,
  },
  subInput: {
    borderRadius: 4,
  },
};

export default PurchaserDetails;

import { useAppSelector } from '../app/hooks';
import React from 'react';
import getThemeLookup from '../selectors/getThemeLookup';
import Text from './Text';
import { TEXT_PROPERTIES } from '../utils/theme';

const SectionTitle = ({
  value,
  containerStyle = {},
}: {
  value: string;
  containerStyle?: React.CSSProperties;
}) => {
  const p = useAppSelector(getThemeLookup);

  return (
    <div style={containerStyle}>
      <Text style={p('modalSectionTitle', TEXT_PROPERTIES)}>{value}</Text>{' '}
    </div>
  );
};

export default SectionTitle;

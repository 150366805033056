import { createAsyncThunk } from '@reduxjs/toolkit';
import debug from '../utils/debug';
import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import history from '../history';
import { setCurrentModal } from './setCurrentModal';
import { MESSAGE_MODAL_ID } from '../modals/MessageModal';
import i18next from 'i18next';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { RootState } from 'store';
import getConfig from '../selectors/getConfig';
import { MENU_SCREEN_ROUTE, PICK_BRAND_SCREEN_ROUTE } from '../hooks/useRoutes';
import { enqueueErrorSnackbar } from '../utils/snackbar';

import { ITEM_CART_AVAILABILITY_MODAL } from '../modals/ItemCartAvailabilityModal';
const { updateKeyOrderProperty, setPickupTime, removeStagedPurchase } = OrderingOperations;
const { getLocationBrandsWithMenuRepresentation, getBufferWarnings, getStagedPurchases } =
  OrderingSelectors;

export const adjustOrder = createAsyncThunk(
  'adjustOrder',
  async (
    data: {
      saleType?: number;
      locationId?: string;
      confirmLocationDeliveryEstimate?: boolean;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { saleType, locationId, confirmLocationDeliveryEstimate } = data;
    debug('adjusting order', {
      newSaleType: saleType,
      newLocationId: locationId,
      confirmLocationDeliveryEstimate,
    });
    try {
      await dispatch(
        updateKeyOrderProperty({
          saleType,
          locationId,
          confirmLocationDeliveryEstimate,
          autoApply: true,
        }),
      ).unwrap();

      dispatch(setPickupTime(null));

      let popUpTitle = i18next.t(`preSaleMessage.title.${saleType}`);
      let popUpMessage = i18next.t(`preSaleMessage.message.${saleType}`);
      let popUpAction;

      const representedBrands = getLocationBrandsWithMenuRepresentation(
        getState() as EntireFrontendState,
      );

      const multipleBrands = representedBrands.length > 1;

      const deviceTypeMobile = getDeviceTypeMobile(getState() as RootState);
      const enableBrandSelection = getConfig(getState() as RootState).enableBrandSelection;
      const stagedPurchases = getStagedPurchases(getState() as EntireFrontendState);
      const showBrandPicker = deviceTypeMobile && enableBrandSelection && multipleBrands;

      const warnings = getBufferWarnings(getState() as EntireFrontendState);

      const invalidStagedPurchases = stagedPurchases.filter(purchase => !purchase.valid);
      let stagedPurchasesToBeRemovedIds: string[] = [];

      // Storing invalid purchases ids to remove
      if (invalidStagedPurchases.length) {
        const invalidStagedPurchasesIds = invalidStagedPurchases.map(purchase => purchase.id);
        stagedPurchasesToBeRemovedIds = [
          ...stagedPurchasesToBeRemovedIds,
          ...invalidStagedPurchasesIds,
        ];
      }

      // Showing alert and setting staged purchases empty if we have any warnings
      if (warnings && warnings.length) {
        // Storing all unavailable items in one array
        let allUnavailableItems = warnings.reduce((acc: any, obj) => {
          if (obj.unavailableItems) {
            acc.push(...obj.unavailableItems);
          }
          return acc;
        }, []);

        // Handling if there any duplicates
        allUnavailableItems = Array.from(new Set(allUnavailableItems));

        // Getting things that needs to be removed from user cart
        const stagedPurchasesToBeRemoved = stagedPurchases.filter(purchase =>
          allUnavailableItems.includes(purchase.item.id),
        );

        // Storing ids of those items that needs to be removed
        stagedPurchasesToBeRemovedIds = [
          ...stagedPurchasesToBeRemovedIds,
          ...stagedPurchasesToBeRemoved.map(purchase => purchase.id),
        ];
      }

      if (stagedPurchasesToBeRemovedIds.length) {
        // Removing each purchase that needs to be removed from cart
        stagedPurchasesToBeRemovedIds.forEach(async id => {
          await dispatch(removeStagedPurchase(id));
        });

        dispatch(setCurrentModal(null));

        dispatch(
          setCurrentModal({
            modalId: ITEM_CART_AVAILABILITY_MODAL,
            clearReturnModalDetails: true,
          }),
        );
      } else {
        dispatch(setCurrentModal(null));
      }

      // retrieve any purchases which are unavailable since adjustments
      // const unavailablePurchases = getUnavailablePurchases(getState() as EntireFrontendState);
      // if (unavailablePurchases.length) {
      //   popUpTitle = i18next.t('unavailablePurchasesWarning.title');
      //   popUpMessage = i18next.t('unavailablePurchasesWarning.text');
      //   popUpAction = () => dispatch(setCurrentModal({ modalId: ORDER_SUMMARY_MODAL_ID }));
      // }

      const modalParams = (getState() as RootState).modalParams;
      modalParams?.allowRedirect !== false &&
        history.replace(showBrandPicker ? PICK_BRAND_SCREEN_ROUTE : MENU_SCREEN_ROUTE);

      if (popUpTitle !== '' || popUpMessage !== '') {
        dispatch(
          setCurrentModal({
            modalId: MESSAGE_MODAL_ID,
            params: {
              title: popUpTitle,
              message: popUpMessage,
              action: popUpAction,
            },
            clearReturnModalDetails: true,
          }),
        );
      }
    } catch (e) {
      console.error('Adjust order thunk failed', e);
      enqueueErrorSnackbar('Adjust order thunk failed');
      return rejectWithValue(e);
    }
  },
);

export default function choiceSetsWithQuantities(
  choiceSets: ChoiceSet[],
  choiceSelections: ChoiceSelections,
  nested: number = 2,
): ChoiceSetWithQuantity[] {
  const ret = choiceSets?.map(choiceSet => ({
    ...choiceSet,
    choices: choiceSet?.choices?.map(choice => {
      // (because we added the checkbox display type feature quite late)
      // this hack kind of un-nests the nested selections from the make it a meal choice set
      // so that the function can understand it
      // const checkboxSubItemSelections = ;
      // didn't actually do this hack but left the comments, ignore last 4 lines
      const quantity =
        // choiceSelections[nested === 2 ? choiceSet.key : choiceSet.id] || []
        (
          choiceSelections[choiceSet.key] ||
          choiceSelections[choiceSet.id] ||
          []
        ).filter(
          // super dodgy, this 'object' type check is there because of differences in choice selection data structures.
          // TODO: refactor everything, why are nested choice sets handled any differently than normal choice sets?
          selection =>
            typeof selection === 'string'
              ? selection === choice.id
              : (selection as ChoiceWithQuantity).id === choice.id,
        ).length;

      return {
        ...choice,
        quantity,
        moneyPrice: quantity * choice.baseMoneyPrice,
      };
    }),
  }));
  return ret;
}

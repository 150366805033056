import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearBuffer, setBufferReadyToApply } from '../../actions/buffer';

const initialState = null as string | null;

const slice = createSlice({
  name: '$bufferReservationId',
  initialState,
  reducers: {
    reserveBuffer(state, action: PayloadAction<string>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clearBuffer, (state, action) => {
        return action.payload.preserveReservationId ? state : initialState;
      })
      .addCase(setBufferReadyToApply, (state, action) => {
        return initialState;
      });
  },
});

export const { reserveBuffer } = slice.actions;
export default slice.reducer;

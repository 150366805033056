import { createSelector } from 'reselect';

import { getInitialFieldValues } from '../utils/fields';
import { RootState } from 'store';

import getMemberFields from './getMemberFields';

const _getProfile = (state: RootState) => state.profile;

export default createSelector([getMemberFields, _getProfile], (updateFields, profile) => {
  const { visible } = updateFields!;

  return {
    visible: getInitialFieldValues(visible, profile!),
  };
});

import { createSelector } from 'reselect';

import getUnenhancedOpenPurchase from './getUnenhancedOpenPurchase';
import getAvailableItems from './getAvailableItems';

// selector should no longer be needed as I changed item size behaviour
export default createSelector(
  [getAvailableItems, (state: EntireFrontendState, itemId: string) => itemId],
  (allItems, itemId) => {
    if (!itemId || !allItems) return;

    const item = allItems[itemId];

    if (!item.sizes.length) return;

    return item.sizes;
  },
);

import React from 'react';
import Favicon from 'react-favicon';
import { useAppSelector } from '../app/hooks';
import { DEFAULT_FAVICON } from '../constants';

const BrandedFavicon = () => {
  const brandingFavicon = useAppSelector(state => state.config.brandingFavicon);
  return <Favicon url={brandingFavicon || DEFAULT_FAVICON} />;
};

export default BrandedFavicon;

import lodash from 'lodash';
import moment from 'moment';

import { safeDate } from '../misc';
import combineAddressParts from '../ordering/combineAddressParts';
import processTradingHours from './processTradingHours';
import { SALE_TYPE } from '../../constants/saleType';

export default function processLocation(rawLocation: RawLocation): POSLocation {
  const supportedSaleTypes = lodash
    .toPairs(rawLocation.SaleTypes || {})
    .map(([key, value]) => (value ? parseInt(key) : undefined))
    .filter(type => type) as SALE_TYPE[];

  const openingTime = safeDate(rawLocation.OpeningTimeToday);
  const closingTime = safeDate(rawLocation.ClosingTimeToday);

  const serviceSessions: Record<string, ServiceSession[]> = {};

  supportedSaleTypes.forEach(saleType => {
    const saleTypeServiceSessionInfo = (rawLocation.ServiceSessions || {})[
      saleType
    ];

    if (saleTypeServiceSessionInfo != null) {
      serviceSessions[saleType] = processSaleTypeServiceSessionInfo(
        saleTypeServiceSessionInfo,
      );
    } else {
      serviceSessions[saleType] = [
        { start: openingTime as string, end: closingTime as string },
      ];
    }
  });

  const brandIds = (rawLocation.VirtualBrands || []).map(String);

  let physicalBrand;

  if (rawLocation.BrandID != null) {
    physicalBrand = rawLocation.BrandID;
    brandIds.unshift(rawLocation.BrandID);
  }

  const guestOrderingSaleTypes = (rawLocation.GuestOrderingSaleTypes || []).map(
    value => {
      return parseInt(value);
    },
  );

  return {
    id: String(rawLocation.StoreID),
    name: (rawLocation.LocationName || '').trim(),
    latitude: rawLocation.Latitude,
    longitude: rawLocation.Longitude,
    address: {
      address1: rawLocation.Address1,
      address2: rawLocation.Address2,
      postCode: rawLocation.PostCode,
      state: rawLocation.State,
      suburb: rawLocation.Suburb,
      country: rawLocation.Country,
      combinedShort: combineAddressParts([
        rawLocation.Address1,
        rawLocation.Address2,
        rawLocation.Suburb,
      ]),
      combinedLong: combineAddressParts([
        rawLocation.Address1,
        rawLocation.Address2,
        rawLocation.Suburb,
        rawLocation.State,
        rawLocation.PostCode,
      ]),
    },
    averageOrderWaitTime: rawLocation.AvgOrderTime || 0,
    abn: rawLocation.ABN || undefined,
    openNow: rawLocation.OpenNow,
    status: rawLocation.StoreStatus,
    online: rawLocation.StoreStatus === 'Online',

    phone: rawLocation.Phone,
    posType: rawLocation.PosType,
    openingTime,
    closingTime,
    paymentGatewayPublicKey: rawLocation.PaymentGatewayPublicKey,

    outOfSessionOrderingEnabled: Boolean(rawLocation.OrderAfterHours),
    openingTimeTomorrow: rawLocation.OpeningTimeTomorrow,
    supportedSaleTypes,

    serviceSessions: serviceSessions as Record<SALE_TYPE, ServiceSession[]>,
    hidden: Boolean(rawLocation.HiddenStore),

    brandIds,
    physicalBrand,

    tradingHours: processTradingHours(rawLocation.OpeningHours),
    orderLeadTimes: rawLocation.Thresholds?.map(processThreshold) || [],

    guestOrderingEnabled: rawLocation.GuestOrderingEnabled,
    guestOrderingSaleTypes,
    cutoffTresholds: rawLocation.CutoffThresholds || [],
    closingTimeYesterday: rawLocation.ClosingTimeYesterday,
    timeZone: rawLocation.Timezone,
    customHours: rawLocation.CustomHours,
    holidayHours: rawLocation.AllHolidays,

    _isFullData: true,
  };
}

function processThreshold(
  threshold: RawLocationOrderLeadTimeThreshold,
): OrderLeadTimeThreshold {
  return {
    moneyLower: threshold.MoneyLower,
    saleType: threshold.SaleType,
    time: threshold.Time,
  };
}

function processSaleTypeServiceSessionInfo(
  info: RawSaleTypeServiceSessionInfo,
): ServiceSession[] {
  let today = moment().format('dddd').toLowerCase();

  if (info.IsPublicHoliday) {
    today = 'publicholiday';
  }

  return info.Sessions.filter(
    session =>
      session.Day.toString().toLowerCase() === today &&
      session.Start !== 'Closed',
  ).map(session => ({
    start: moment(session.Start, 'HH:mm:ss').format(),
    end: moment(session.End, 'HH:mm:ss').format(),
  }));
}

import { createSelector } from 'reselect';

import { OrderingSelectors } from 'polygon-ordering';
import { RootState } from 'store';

const { getEnableDynamicPaymentGatewayConfig, getPaymentGatewayConfig } = OrderingSelectors;

const getLegacyPaymentGatewayPublicKey = (state: RootState) => state.config.paymentGatewayPublicKey;

export default createSelector(
  [getLegacyPaymentGatewayPublicKey, getEnableDynamicPaymentGatewayConfig, getPaymentGatewayConfig],
  (legacyPaymentGatewayPublicKey, enableDynamicPaymentGatewayConfig, paymentGatewayConfig) => {
    return enableDynamicPaymentGatewayConfig
      ? paymentGatewayConfig?.clientKey // new dyanamic config stripe
      : legacyPaymentGatewayPublicKey;
  },
);

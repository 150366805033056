import { createSelector } from 'reselect';

import processMobileNumber from '../utils/processors/processMobileNumber';

import getPurchaser from './getPurchaser';
import getRequiredPurchaserDetails from './getRequiredPurchaserDetails';
import {
  getInternationalMobileAllowed,
  getMobileRegexWhiteList,
} from './config';

import {
  MOBILE_INVALID_REASON,
  MOBILE_REGEX_AU,
  MOBILE_REGEX_INT,
} from '../constants';

export default createSelector(
  [
    getPurchaser,
    getRequiredPurchaserDetails,
    getInternationalMobileAllowed,
    getMobileRegexWhiteList,
  ],
  (
    purchaser,
    requiredPurchaserDetails,
    internationalMobileAllowed,
    whiteList,
  ): { valid: boolean; reason?: string } => {
    if (!purchaser) {
      return {
        valid: false,
        reason: MOBILE_INVALID_REASON.NOT_PRESENT,
      };
    }

    const required = requiredPurchaserDetails.includes('mobile');

    const mobile = purchaser.effective.mobile || '';

    const processedMobile = processMobileNumber(mobile);

    if (!processedMobile) {
      if (required) {
        return {
          valid: false,
          reason: MOBILE_INVALID_REASON.NOT_PRESENT,
        };
      } else {
        return { valid: true };
      }
    }

    if (
      (processedMobile.startsWith('04') && processedMobile.length < 10) ||
      (processedMobile.startsWith('6') && processedMobile.length < 11) ||
      (processedMobile.startsWith('+') && processedMobile.length < 12)
    ) {
      return {
        valid: false,
        reason: MOBILE_INVALID_REASON.TOO_SHORT,
      };
    }

    if (
      !(
        internationalMobileAllowed
          ? whiteList
            ? new RegExp(whiteList)
            : MOBILE_REGEX_INT
          : MOBILE_REGEX_AU
      ).test(processedMobile)
    ) {
      return {
        valid: false,
        reason: MOBILE_INVALID_REASON.INVALID_SHAPE,
      };
    }

    return { valid: true };
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../actions/currentOrder';

const initialState = null as Member | null;

const slice = createSlice({
  name: '$member',
  initialState,
  reducers: {
    setMemberData(state, action: PayloadAction<Member | null>) {
      const oldMember = { ...state };
      const newMember = action.payload;
      if (!newMember) {
        return newMember;
      }

      const oldStampcardsFilled = oldMember.stampcardsFilled;
      const newStampcardsFilled = newMember.stampcardsFilled;
      // oldMember.hasStampcardReward = true;
      newMember.hasStampcardReward =
        oldMember.hasStampcardReward ||
        (typeof oldStampcardsFilled === 'number' &&
          typeof newStampcardsFilled === 'number' &&
          oldStampcardsFilled < newStampcardsFilled);
      return newMember;
    },
    acknowledgeStampcardReward(state, action) {
      if (!state) return state;

      return {
        ...state,
        hasStampcardReward: false,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      const { preserveMember } = action.payload;
      return preserveMember ? state : initialState;
    });
  },
});

export const { setMemberData, acknowledgeStampcardReward } = slice.actions;
export default slice.reducer;

import { createSelector } from 'reselect';
import lodash from 'lodash';

import purchaseInvalidForPointsSale from '../utils/ordering/purchaseInvalidForPointsSale';

import getMember from './getMember';
import getNonVerifiedMemberPresent from './getNonVerifiedMemberPresent';
import getOutstandingAmountByPaymentMethod from './getOutstandingAmountByPaymentMethod';
import getSaleType from './getSaleType';
import getSelectedOffer from './getSelectedOffer';
import getStagedPurchases from './getStagedPurchases';
import getEnabledPaymentMethods from './getEnabledPaymentMethods';

import { PAYMENT_METHOD_UNAVAILABLE_REASON } from '../constants';
import { PAYMENT_METHOD } from '../constants/paymentMethod';
import { SALE_TYPE } from '../constants/saleType';

export default createSelector(
  [
    getEnabledPaymentMethods,
    getMember,
    getOutstandingAmountByPaymentMethod,
    getNonVerifiedMemberPresent,
    getSaleType,
    getSelectedOffer,
    getStagedPurchases,
  ],
  (
    enabledPaymentMethods,
    member,
    outstandingByPaymentMethod,
    nonVerifiedMemberPresent,
    saleType,
    selectedOffer,
    stagedPurchases,
  ): PAYMENT_METHOD_UNAVAILABLE_REASON[] => {
    const reasons = [];

    if (!enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_POINTS)) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.NOT_ENABLED);
    }

    const outstanding =
      lodash.get(outstandingByPaymentMethod, PAYMENT_METHOD.MEMBER_POINTS) || 0;

    const balance = member?.pointsBalance || 0;

    if (outstanding > balance || !balance) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.INSUFFICIENT_BALANCE);
    }

    if (!Boolean(member)) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_MISSING);
    }

    const validMemberPresent =
      !!member && !nonVerifiedMemberPresent && member.registered;

    if (!validMemberPresent) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_INVALID);
    }

    const invalidPurchaseExists = stagedPurchases.some(
      purchaseInvalidForPointsSale,
    );

    if (invalidPurchaseExists) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.CART_NOT_COMPATIBLE);
    }

    // if (saleType === SALE_TYPE.DELIVERY) {
    //   reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.SALE_TYPE_NOT_COMPATIBLE);
    // }

    if (selectedOffer) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.OFFER_NOT_COMPATIBLE);
    }

    if (!outstanding && stagedPurchases.length) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.TOTAL_INVALID);
    }

    return reasons;
  },
);

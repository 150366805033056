import tallyAndValidateChoiceSets from './tallyAndValidateChoiceSets';
import choiceSetsWithQuantities from './choiceSetsWithQuantities';
import sortChoices from './sortChoices';
export default function nestedChoiceSetsWithQuantities(
  choiceSets: (NestedChoiceSet | ChoiceSet)[],
  choiceSelections: NestedChoiceSelections | ChoiceSelections,
  choiceOrderingMethod: ChoiceOrderingMethod,
): (NestedChoiceSetWithQuantity | ChoiceSetWithQuantity)[] {
  return choiceSets.map((set: NestedChoiceSet | ChoiceSet) => {
    if ('nestedIngredients' in set) {
      const choices = set.choices?.map(choice1 => {
        let moneyPrice = 0;
        let selections: SDict<string[]> = {};
        if (choiceSelections) {
          if (set.key in choiceSelections) {
            if (choice1.id in choiceSelections[set.key]) {
              const temp = (
                choiceSelections as SDict<SDict<SDict<ChoiceWithQuantity[]>>>
              )[set.key][choice1.id];

              if (temp) {
                Object.entries(temp).forEach(([key, value]) => {
                  selections = { ...selections, [key]: value.map(c => c.id) };
                });
              }
            }
          }
        }

        // 90% of the time this function is used, tallyandvalidate is also used to wrap around it
        // TODO: combine into single recursive tally and validate function like we have on kiosk
        const validatedSets = tallyAndValidateChoiceSets(
          choiceSetsWithQuantities(choice1.choiceSets, selections, 0),
        );

        const validated =
          //fix nested choiceset validation error when stardard choice and nested choice under the choiceset
          !choice1.choiceSets || choice1.choiceSets.length === 0
            ? true
            : validatedSets?.reduce(
                (acc, current) => acc && current.valid,
                true,
              );

        // quantity can only be 1 or 0 because it's a nested item
        // this quantity variable is for the current nested item choice set (which is a direct child of the main menu item)
        const quantity =
          (choiceSelections as SDict<SDict<SDict<ChoiceWithQuantity[]>>>)[
            set.key
          ]?.[choice1.id] && validated
            ? 1
            : 0;

        // get the total price of the nested item (the choice) (including the base price and choices)
        if (quantity && validatedSets) {
          moneyPrice =
            choice1.baseMoneyPrice +
            validatedSets.reduce((sum, validatedSet) => {
              let freeChoicesRemaining = validatedSet.free;
              const validatedSetTotalPrice = sortChoices(
                validatedSet.choices,
                choiceOrderingMethod,
              ).reduce((setSum, c) => {
                let freeQuantity: number = 0;
                if (freeChoicesRemaining) {
                  freeQuantity = Math.min(c.quantity, freeChoicesRemaining);
                  freeChoicesRemaining -= freeQuantity;
                }
                const choiceSum =
                  c.baseMoneyPrice * (c.quantity - freeQuantity);
                return setSum + choiceSum;
              }, 0);
              return sum + validatedSetTotalPrice;
            }, 0);
        }
        return {
          ...choice1,
          choiceSets: validatedSets,
          quantity,
          moneyPrice,
        };
      });
      return { ...set, choices } as NestedChoiceSetWithQuantity;
    } else {
      return tallyAndValidateChoiceSets(
        choiceSetsWithQuantities([set as ChoiceSet], {
          [set.key]: Object.values(
            (choiceSelections || {})[set.key] || {},
          )?.map((c: ChoiceWithQuantity) => (typeof c === 'string' ? c : c.id)),
        } as ChoiceSelections),
      )[0];
    }
  });
}

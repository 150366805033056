import React from 'react';
import { useAppSelector } from '../app/hooks';
import SectionTitle from './SectionTitle';
import AddressItem from './AddressItem';

const NearbyLocations = () => {
  const nearbyLocations = useAppSelector(state => state.nearbyLocations);
  return (
    <div className="my-2">
      {nearbyLocations && <SectionTitle value="Nearby" />}
      {nearbyLocations?.map(location => (
        <AddressItem location={location} key={location.formatted_address} />
      ))}
    </div>
  );
};

export default NearbyLocations;

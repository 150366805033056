import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'hideHeroScreenImage',
  initialState: null as boolean | null,
  reducers: {
    setHideHeroScreenImage(state, action: PayloadAction<boolean | null>) {
      return action.payload || null;
    },
  },
});

export const { setHideHeroScreenImage } = slice.actions;

export default slice.reducer;

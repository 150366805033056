import { createSelector } from 'reselect';

import { RECEIVED_AT_STORE, SALE_STATUSES_MAP } from '../constants';

import getCurrentOrder from './getCurrentOrder';

export default createSelector([getCurrentOrder], order => {
  if (!order.saleDetails) {
    return undefined;
  }

  return (
    order.saleDetails.status >= (SALE_STATUSES_MAP[RECEIVED_AT_STORE] as number)
  );
});

import base64 from 'base-64';
interface paymentSenseClient {
  configurePaymentSense: (
    hostAddress: string,
    paymentEnvironment: string,
    apiKey: string,
    terminalId: string,
    eposIdentifier?: string
  ) => void;

  // TODO
  initiatePurchase: (
    requestParams: any, //PaymentSenseInitiatePurchaseParams,
    transactionCallbacks: any //TyroTransactionCallbacks
  ) => void;
  // TODO
  // initiateRefund: (
  //   requestParams: TyroInitiateTransactionParams,
  //   transactionCallbacks: TyroTransactionCallbacks
  // ) => void;
  // TODO
  // cancelCurrentTransaction: (question?: TyroQuestion) => void;
  cancelTxn: (endpoint: string) => void;
}
export class PaymentSense {
  config?: PaymentSenseConfig;
  interfaceDir?: string;
  debugLogs?: boolean;
  loggingHandler: any;

  private authHeader?: string;
  private hostAddress?: string;
  private terminalId?: string;
  private endpoint?: string;

  private txnData?: string;
  private response?: string;

  private currentClient?: paymentSenseClient;
  private receipts?: string[][];
  private transactionInProgress?: boolean;
  private requestedSignature?: boolean;
  private txnStarted?: boolean;

  // Todo import utils
  // private loggingHandler?: LoggingHandler;

  constructor(
    config?: PaymentSenseConfig,
    interfaceDir?: string,
    debugLogs?: boolean,
    loggingHandler?: any //Probably not needed
  ) {
    if (config) {
      this.config = config;
    }

    if (interfaceDir) {
      this.interfaceDir = interfaceDir;
    }

    if (debugLogs) {
      this.debugLogs = debugLogs;
    }

    if (loggingHandler) {
      this.loggingHandler = loggingHandler;
    }
  }

  // static async configurePaymentSense(
  async configurePaymentSense(
    hostAddress: string,
    paymentEnvironment: string,
    apiKey: string,
    terminalId: string,
    eposIdentifier?: string
  ) {
    let endpoint = null;
    const payEnv = paymentEnvironment.toLowerCase();
    const checkTerminal = payEnv === 'simulator' ? 'VCMredcSIP0' : terminalId;

    this.terminalId = terminalId;
    this.hostAddress = hostAddress;

    if (payEnv === 'prod' || payEnv === 'production') {
      endpoint = `https://${hostAddress}.connect.paymentsense.cloud`;
    } else if (payEnv === 'test' || payEnv === 'simulator') {
      endpoint = `https://${hostAddress}.test.connect.paymentsense.cloud`;
    } else {
      endpoint = `https://${hostAddress}.test.connect.paymentsense.cloud`;
    }

    this.endpoint = endpoint;
    this.authHeader = 'Basic ' + base64.encode(eposIdentifier + ':' + apiKey);
    const response = await fetch(endpoint + '/pac/terminals/' + checkTerminal, {
      method: 'GET',
      headers: {
        Authorization: 'Basic ' + base64.encode(eposIdentifier + ':' + apiKey),
        Accept: 'application/connect.v2+json',
        'Content-Type': 'application/json',
        'Software-House-Id': 'SD523B68',
        'Installer-Id': 'SD523B68',
      },
    });

    const result: Promise<TerminalInfo> = await response.json();

    return { ...result, endpoint: this.endpoint };
  }

  async initSale(
    amount: number
    // transactionType?: PACTXN,
    // currency?: string,
    // cardholderNotPresent?: boolean
  ) {
    try {
      const headers = new Headers();
      headers.append('Authorization', this.authHeader!);
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/connect.v2+json');
      headers.append('Software-House-Id', 'SD523B68');
      headers.append('Installer-Id', 'SD523B68');

      const body: SalePacket = {
        transactionType: 'SALE',
        amount: amount,
        currency: 'GBP',
        // cardholderNotPresent: cardholderNotPresent || true,
      };

      const saleResponse = await fetch(
        `${this.endpoint}/pac/terminals/${this.terminalId}/transactions`,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body),
        }
      );
      const data = await saleResponse.json();
      this.txnData = data.location;
      console.log('Fetch chaining from initPurchase() ==> ', data);
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getTxn(txnUrl: string) {
    try {
      const headers = new Headers();
      headers.append('Authorization', this.authHeader!);
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/connect.v2+json');
      headers.append('Software-House-Id', 'SD523B68');
      headers.append('Installer-Id', 'SD523B68');

      const getTxnResponse = await fetch(txnUrl, {
        method: 'GET',
        headers: headers,
      });

      const data = await getTxnResponse.json();
      this.response = data;
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async refundTxn(
    amount: number
    // transactionType?: PACTXN,
    // currency?: string,
    // cardholderNotPresent?: boolean
  ) {
    try {
      const headers = new Headers();
      headers.append('Authorization', this.authHeader!);
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/connect.v2+json');
      headers.append('Software-House-Id', 'SD523B68');
      headers.append('Installer-Id', 'SD523B68');

      const body: SalePacket = {
        transactionType: 'REFUND',
        amount: amount,
        currency: 'GBP',
        // cardholderNotPresent: cardholderNotPresent || true,
      };

      const refundResponse = await fetch(
        `${this.endpoint}/pac/terminals/${this.terminalId}/transactions`,
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body),
        }
      );

      const data = await refundResponse.json();
      // this.txnData = data.location;
      console.log('Fetch chaining from refundPurchase() ==> ', data);
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async cancelTxn(txnUrl: string) {
    try {
      const headers = new Headers();
      headers.append('Authorization', this.authHeader!);
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/connect.v2+json');
      headers.append('Software-House-Id', 'SD523B68');
      headers.append('Installer-Id', 'SD523B68');

      const cancelResponse = await fetch(txnUrl, {
        method: 'DELETE',
        headers: headers,
      });

      // No content is given by api
      if (cancelResponse.status === 204) {
        return;
      } else {
        const data = await cancelResponse.json();
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async signatureVerification(txnUrl: string, verified: boolean) {
    try {
      const headers = new Headers();
      headers.append('Authorization', this.authHeader!);
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/connect.v2+json');
      headers.append('Software-House-Id', 'SD523B68');
      headers.append('Installer-Id', 'SD523B68');

      const body: SignaturePacket = {
        accepted: verified,
      };

      const signatureResponse = await fetch(txnUrl, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(body),
      });

      const data = await signatureResponse.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  // private getTipAmount(response: any): number {
  // Write logic for tips
  // }

  // private getCardData(response: any): CardData {
  //   // Not sure if we want to keep the card data
  // }

  public parseReceipt(receipt: any) {
    let writeBuffer = '';
    let currentLine = '';
    receipt.forEach((item: any, index: number) => {
      const formattedItem = item.value.split(':');
      currentLine = item.value;
      if (formattedItem.length > 1) {
        currentLine = `${formattedItem[0]}${' '.repeat(
          40 - formattedItem[0].length - formattedItem[1].length
        )}${formattedItem[1]}`;
      }
      writeBuffer += `${currentLine}${
        index === receipt.length - 1 ? '' : '\r\n'
      }`;
    });
    console.log(writeBuffer);
    return writeBuffer;
  }
}

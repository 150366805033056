export default function (
  rawInfo: RawMinimumOrderInformation,
): MinimumOrderInformation {
  return {
    surchargeEnabled: Boolean(rawInfo.MinOrderHasSurcharge),
    surchargeDescription: rawInfo.MinOrderSurchargeDesc || null,
    surchargeType: rawInfo.MinOrderSurchargeType || null,
    amount: rawInfo.MinimumOrder as number,
  };
}

import lodash from 'lodash';

import { safeString } from '../misc';

// gets an item based on its id, then plucode, then name
export default (
  items: Items,
  options: { id?: string; name?: string; plucode?: string },
) => {
  let item;

  if (options.id != null) {
    item = items[String(options.id)];
  }

  if (!item && options.plucode) {
    item = Object.values(items).find(val => val.plucode === options.plucode);
  }

  if (!item && options.name) {
    item = lodash
      .values(items)
      .find(i => safeString(i.name) === safeString(options.name));
  }

  return item;
};

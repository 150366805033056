import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { FetchParams } from '../utils/Api';
import { fetchMember } from './fetchMember';

export const saveAddress = createAsyncThunk(
  '$saveAddress',
  async (
    data: {
      SDAddress1: string;
      SDAddress2: string;
      SDAlias: string;
      SDLatitude: number;
      SDLongitude: number;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const params: FetchParams = {
        path: `/api/v1/profile`,
        method: 'PUT',
        body: data,
      };
      await Api.fetch(params, 'member');
      dispatch(fetchMember({ authenticationMethod: 'member' }));
    } catch (e) {
      console.error('Save address thunk failed');
      return rejectWithValue(e);
    }
  },
);

export const deleteSavedAddress = createAsyncThunk(
  '$deleteSavedAddress',
  async (
    data: {
      id: number;
    },
    { dispatch, rejectWithValue },
  ) => {
    const { id } = data;

    try {
      const params: FetchParams = {
        path: `/api/v1/profile/remove_sd_address/${id}`,
        method: 'DELETE',
      };
      await Api.fetch(params, 'member');
      dispatch(fetchMember({ authenticationMethod: 'member' }));
    } catch (e) {
      console.error('Delete save address thunk failed');
      return rejectWithValue(e);
    }
  },
);

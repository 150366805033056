import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: '$SET_INCLUDE_COUPON_DISCOUNT_SURCHARGE',
  initialState: false,
  reducers: {
    setIncludeCouponDiscountSurcharge(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setIncludeCouponDiscountSurcharge } = slice.actions;
export default slice.reducer;

import { createSelector } from 'reselect';
import getOrdering from './getOrdering';
import getAllergenDietaryDetails from './getAllergenDietaryDetails';

export default createSelector(
  [getOrdering, getAllergenDietaryDetails],
  (ordering, tagDetails) => {
    const tempAllergenTags = tagDetails.filter(
      tagDetails =>
        tagDetails.tag_type === 'ALLERGEN' &&
        ordering.tempTags?.includes(tagDetails.recid),
    );
    const tempDieataryTags = tagDetails.filter(
      tagDetails =>
        tagDetails.tag_type === 'DIETARY' &&
        ordering.tempTags?.includes(tagDetails.recid),
    );
    const tempCustomTags = tagDetails.filter(
      tagDetails =>
        tagDetails.tag_type === 'CUSTOM' &&
        ordering.tempTags?.includes(tagDetails.recid),
    );

    return {
      allergenTags: [...tempAllergenTags],
      dietaryTags: [...tempDieataryTags],
      customTags: [...tempCustomTags],
    };
  },
);

import { createSelector } from 'reselect';

import getUnenhancedOpenPurchase from './getUnenhancedOpenPurchase';
import getAvailableItems from './getAvailableItems';

// same deal as getItemSizes, should no longer be needed
export default createSelector(
  [getUnenhancedOpenPurchase, getAvailableItems],
  (openPurchase, allItems) => {
    if (!allItems || !openPurchase) return;

    const openItem = allItems[openPurchase.itemId];

    if (!openItem.sizes.length) return;

    return openItem.sizes;
  },
);

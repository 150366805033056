import { createSelector } from 'reselect';

import { utils } from 'polygon-utils';

import getConfig from './getConfig';

export default createSelector([getConfig], config =>
  config.termsAndConditionsLink
    ? //@ts-ignore
      utils.determineFullLink(config.endpoint, config.termsAndConditionsLink)
    : undefined,
);

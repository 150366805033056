import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSavedCards } from '../slices/savedCards';

export const fetchSavedCards = createAsyncThunk(
  'fetchSavedCards',
  async (_data: undefined, { dispatch }) => {
    const path = '/api/v1/sale/payment_methods';

    return RedcatApiHandler.authorisedFetch({ method: 'POST', path }).then(response => {
      const savedCards: SavedCard[] = [];
      const { paymentMethods } = response.data;

      for (let i = 0; i < paymentMethods.length; i++) {
        if (paymentMethods[i].type === 'card') {
          savedCards.push({
            token: paymentMethods[i].id,
            displayValue: paymentMethods[i].card?.last4,
            gateway: 'stripe',
            customer: paymentMethods[i].customer,
          });
        }
      }

      dispatch(setSavedCards(savedCards));
    });
  },
);

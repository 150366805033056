import React, { useEffect, useMemo, useRef } from 'react';
import Text from '../components/Text';
import { useTranslation } from 'react-i18next';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import LoyaltyTitle from '../components/LoyaltyTitle';
import combineStyles from '../utils/combineStyles';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import useRoutes from '../hooks/useRoutes';
import history from '../history';

import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';

const { fetchFeedback, createFeedback, resetFeedback } = OrderingOperations;
const { getFeedback } = OrderingSelectors;

import { LinearProgress } from '@mui/material';
import StandardButton from '../components/StandardButton';

import { Formik, Form, FormikValues, useFormikContext, FormikProps } from 'formik';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { styled } from '@mui/system';
import { FEEDBACK_DELIVERY_SCREEN } from './FeedbackDeliveryScreen';
import { useState } from 'react';
import {
  enqueueErrorSnackbar,
  enqueueSuccessSnackbar,
  enqueueWarningSnackbar,
} from '../utils/snackbar';
import { RiRefreshLine } from 'react-icons/ri';
import { IoIosArrowBack } from 'react-icons/io';
import TouchableOpacity from '../components/TouchableOpacity';

export const FEEDBACK_SCREEN = '/feedback';

const FeedbackWrapper = styled('div')(({ theme }) => {
  const p = useAppSelector(getThemeLookup);
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '2em',
    justifyContent: 'space-between',
    flexGrow: 1,

    '.MuiLinearProgress-colorPrimary': {
      background: p('progress', ['color']).color,
    },
    '.MuiLinearProgress-barColorPrimary': {
      background: p('progressBar', ['color']).color,
    },
  };
});

const FeedbackScreen = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();

  const { feedbackRoutes } = useRoutes();
  const headerBrandImagePath = useAppSelector(state => state.config.headerBrandImagePath);

  const location = useLocation<Location>();
  const { search } = location;

  const query = useMemo(() => {
    return new URLSearchParams(search);
  }, []);

  /**
   * Preventing accidental refresh
   */
  function blockReload(event: BeforeUnloadEvent) {
    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = '';
  }
  useEffect(() => {
    window.addEventListener('beforeunload', blockReload);

    return () => {
      window.removeEventListener('beforeunload', blockReload);
    };
  }, []);

  const feedbackInitial = useAppSelector(getFeedback);

  // Fetching Feedback boilerplate via url search params:
  useMemo(() => {
    const orderID = query.get('order');
    const uuid = query.get('uuid');

    const fetchFeedbackID: {
      orderID?: string;
      uuid?: string;
    } = {};

    //console.log(fetchFeedbackID);

    if (typeof orderID === 'string') {
      fetchFeedbackID.orderID = orderID;
    }

    if (typeof uuid === 'string') {
      fetchFeedbackID.uuid = uuid;
    }

    if (
      Object.keys(fetchFeedbackID).length === 0 &&
      feedbackInitial &&
      feedbackInitial.recidOrder != -1
    ) {
      setTimeout(() => {
        enqueueWarningSnackbar('Loaded previous feedback form as unfilled');
      });

      /**
       * Note: in the future we could have drafts of each of the forms but this
       * wasn't in the scope, so it's not going to be done right now.
       *
       * In this if statement (if we were to implement it):
       * -> set the values of the form to it's previous Formik values.
       */
      return;
    }

    setTimeout(() => {
      dispatch(fetchFeedback(fetchFeedbackID))
        .unwrap()
        .then(() => {
          //console.log({ feedbackInitial });
          //enqueueSuccessSnackbar('Success');
        })
        .catch(err => {
          console.log(err);
          enqueueErrorSnackbar(err.message);
          setTimeout(() => {
            history.goBack();
          }, 5000);
        });
    });
  }, [query]);

  // This will be used to track the progress of the user
  const feedbackPageRoutes = feedbackRoutes.reduce((result: string[], route) => {
    if ((feedbackInitial && feedbackInitial.delivery) || route.path != FEEDBACK_DELIVERY_SCREEN) {
      // Check for delivery key exists in params
      // -> if it does filter it out of the list of feedback page routes
      // -> however if feedbackInitial does have
      result.push(route.path);
    }

    return result;
  }, []);

  const currentPageIndex = feedbackPageRoutes.indexOf(location.pathname) + 1;
  const delivery = feedbackPageRoutes.includes(FEEDBACK_DELIVERY_SCREEN);
  const progress = (currentPageIndex / feedbackPageRoutes.length) * 100;
  const formRef = useRef<HTMLFormElement>(null);
  const innerFormRef = useRef<FormikProps<Feedback>>(null);

  // This is the next button.
  // We run the submit event to revalidate each section
  // upon successful submit it later runs the next() function
  // the
  const submit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  };

  const nextPage = () => {
    // Go to next page:
    history.push(feedbackPageRoutes[currentPageIndex]);
  };

  /**
   * Schemas
   */
  const ratingSchema = Yup.number().min(1).required();
  const thumbsRatingSchema = Yup.boolean().required();
  const additionalDetailsSchema = Yup.string();

  const storeSubRatingsSchema = feedbackInitial?.store.subRatings
    ? {
        subRatings: Yup.object({
          speed: ratingSchema,
          accuracy: ratingSchema,
          quality: ratingSchema,
        }),
      }
    : null;

  const storeSchema = {
    store: Yup.object({
      storeId: Yup.number().required(),
      rating: ratingSchema,
      storeName: Yup.string().required(),

      ...storeSubRatingsSchema,

      additionalDetails: additionalDetailsSchema,
    }),
  };

  const foodSchema =
    location.pathname == '/feedback/menu-items'
      ? {
          items: Yup.array(
            Yup.object({
              itemRecid: Yup.number().required(),
              itemName: Yup.string(),
              isGood: thumbsRatingSchema,
              additionalDetails: additionalDetailsSchema,
            }),
          ),
        }
      : null;

  const deliverySchema =
    location.pathname == '/feedback/delivery'
      ? {
          delivery: Yup.object({
            deliveryProvider: Yup.string(),
            isGood: thumbsRatingSchema,
            additionalDetails: additionalDetailsSchema,
          }),
        }
      : null;

  const validationSchema = Yup.object({
    ...storeSchema,

    // These sub-schemas only appear when they are on their active screen
    // to validate one screen after the other after a screen passes validation.
    ...foodSchema,
    ...deliverySchema,
  });

  // Posting to the server
  const postData = (values: Feedback) => {
    setTimeout(() => {
      dispatch(createFeedback({ body: values, disableCleanup: true }))
        .unwrap()
        .then(res => {
          enqueueSuccessSnackbar('Posted feedback');
          // When success post request go to complete screen

          if (innerFormRef.current && res.coupon_given) {
            innerFormRef.current.setFieldValue('couponGiven', res.coupon_given);
          }

          nextPage();
        })
        .catch(err => enqueueErrorSnackbar(err.message));
    });
  };

  // on successful submit...
  const next = (values: Feedback) => {
    switch (location.pathname) {
      case '/feedback/menu-items': {
        // if: delivery not in feedback
        if (!delivery) {
          postData(values);
        } else {
          nextPage();
        }
        break;
      }
      case '/feedback/delivery': {
        postData(values);
        break;
      }
      case '/feedback/completed': {
        history.push('/');
        dispatch(resetFeedback());
        break;
      }

      // For all the other screens -> Go to next page
      default: {
        nextPage();
        break;
      }
    }
  };

  return (
    <FeedbackWrapper>
      {progress != 100 && (
        <TouchableOpacity
          style={styles.backButton}
          onClick={() => {
            history.goBack();
          }}
        >
          <IoIosArrowBack style={{ fontSize: 35 }} />
        </TouchableOpacity>
      )}

      {feedbackInitial && feedbackInitial.recidOrder != -1 ? (
        <Formik
          // TODO: preload data in
          initialValues={feedbackInitial}
          enableReinitialize={true}
          onSubmit={next}
          validationSchema={validationSchema}
          validateOnChange
          validateOnMount
          innerRef={innerFormRef}
        >
          {({ errors, values, isValid, validateForm }) => (
            <Form ref={formRef} style={styles.formWrapper}>
              <div style={styles.content}>
                {headerBrandImagePath && (
                  <img
                    className="ImageSquare"
                    style={combineStyles(styles.logo, p('headerBrandingImage', ['maxHeight']))}
                    src={headerBrandImagePath}
                    alt="logo"
                  />
                )}

                <Redirect from={FEEDBACK_SCREEN} to={feedbackPageRoutes[0]} />
                {feedbackRoutes.map(route => (
                  <Route
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    render={() => <route.component />}
                  />
                ))}
              </div>

              <div style={styles.footer}>
                <div style={styles.footerContainer}>
                  <LinearProgress style={styles.progress} variant="determinate" value={progress} />
                  <StandardButton
                    themeKey="modalProceedButton"
                    label={t(`button.feedback.${currentPageIndex > 3 ? 'done' : 'continue'}`)}
                    onClick={submit}
                    //disabled={}
                    style={styles.button}
                    disabled={!isValid}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div style={styles.loadingContainer}>
          <RiRefreshLine className="spinner" style={styles.loadingSpinner} />
          <LoyaltyTitle title={t('title.feedback.loading')} titleTranslated />
        </div>
      )}
    </FeedbackWrapper>
  );
};

export default FeedbackScreen;

const styles: Styles = {
  backButton: {
    position: 'sticky',
    top: 40,
    alignSelf: 'flex-start',
    translate: -50,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space',
    justifyContent: 'space-between',
    flexGrow: 1,
  },

  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },

  loadingSpinner: {
    fontSize: 60,
    marginTop: 16,
  },

  logo: { maxWidth: 180, marginBottom: '1em' },

  progress: {
    width: '100%',
    height: 5,
  },

  footer: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    //position: 'sticky',
    bottom: 30,
    //background: '#fff',
    //padding: '30px 0',
    zIndex: 9999,
  },

  footerContainer: {
    width: '100%',
    maxWidth: 400,
  },

  button: {
    marginTop: '1.5em',
    width: '100%',
  },
};

import lodash from 'lodash';

export default function (offer: Offer, items: Items): Offer {
  // add the ids of any class/category matching items to each offer's targetPLUs
  const newTargetPLUs: Array<string> = [];

  lodash.values(items).forEach(item => {
    if (
      item.plucode &&
      ((item.class && offer.targetPLUClasses.includes(item.class)) ||
        (item.category && offer.targetPLUCategories.includes(item.category)))
    ) {
      newTargetPLUs.push(item.plucode);
    }
  });

  // do the same thing for qualifiers because why not?
  // BOGO stuff seems very haphazardly tacked on to me (Heath 12/2023)

  const newQualifyingPLUs: Array<string> = [];

  lodash.values(items).forEach(item => {
    if (
      item.plucode &&
      ((item.class && offer.qualifyingPLUClasses?.includes(item.class)) ||
        (item.category &&
          offer.qualifyingPLUCategories?.includes(item.category)))
    ) {
      newQualifyingPLUs.push(item.plucode);
    }
  });

  const qualifyingPLUs = lodash.uniq([
    ...(offer.qualifyingPLUs ?? []),
    ...newQualifyingPLUs,
  ]);

  return {
    ...offer,
    targetPLUs: lodash.uniq([...offer.targetPLUs, ...newTargetPLUs]),
    qualifyingPLUs: qualifyingPLUs.length ? qualifyingPLUs : undefined,
  };
}

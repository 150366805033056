import { createAsyncThunk } from '@reduxjs/toolkit';
import PaymentHooks from '../utils/PaymentHooks';
import { getProductConfig, getSelectedPaymentMethods } from '../selectors';
import { getEftposConfig, getPaymentEnvironment } from '../selectors/config';
import { EFTPOS_STATUS_UPDATE, FAILURE_REASON } from '../constants';
import Logger from '../utils/Logger';
import delay from '../utils/misc';
import { sale } from './sale';
import { updateSelectedPaymentMethod } from '../reducers/currentOrder/selectedPaymentMethods';
import { PAYMENT_METHOD } from '../constants/paymentMethod';
import getCurrentOrder from '../selectors/getCurrentOrder';
import moment from 'moment';

export const tyroShellPayments = createAsyncThunk(
  '$tyroShellPayments',
  async (
    data: {
      amount: number;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    let throw_reason: FAILURE_REASON | string | undefined;

    try {
      const currentLocationId = getCurrentOrder(
        getState() as EntireFrontendState,
      ).locationId;
      const {
        terminalId,
        paymentProvider,
        paymentEnvironment,
        hostAddress,
        apiKey,
        eposIdentifier,
        deviceId,
      } = getEftposConfig(getState() as EntireFrontendState);

      Logger.logUpstream({
        DeviceID: deviceId as string,
        StoreID: currentLocationId!,
        Action: 'BEFORE_PAYMENT',
        DateTime: new Date().toLocaleString('en', {
          timeZone: 'Australia/Sydney',
        }),
        Data: {
          Endpoint: 'N/A',
          SentBody: JSON.stringify({
            terminalId,
            paymentProvider,
            paymentEnvironment,
            hostAddress,
            apiKey,
            eposIdentifier,
            deviceId,
          }),
          ResponseCode: null,
          ResponseBody: null,
          ResponseTime: null,
        },
      });

      const { amount } = data;
      // @ts-ignore
      await window.parent._tyro_init(amount);
      const tyroPollStart = moment();

      // Set to 300 for 60s period (Note: this number is based on the delay you set inside the recursive check)
      // Tyro will timeout after 60s so safe to add it for a little longer
      let repeat = 320; // fail safe incase we don't catch some error we should break the recursion

      const tyro_response: any = async () => {
        // Note: this must inside the func otherwise it won't be detected
        const hook: any = PaymentHooks.get(EFTPOS_STATUS_UPDATE);

        //@ts-ignore
        const tyroPacket = window.parent._tyro_response_packet;
        const { result, reason } = tyroPacket;

        //@ts-ignore
        const tyroStatus = window.parent._tyro_payment_status;
        const { statusMsg, txnStarted } = tyroStatus;

        if (hook) {
          hook({ statusMsg: statusMsg, txnStarted: txnStarted });
        }

        if (repeat < 0) {
          // @ts-ignore
          window.parent._tyro_response_packet = '';
          // @ts-ignore
          window.parent._tyro_payment_status = '';

          Logger.log('No EFTPOS provider configured');
          throw_reason = FAILURE_REASON.MISSING_EFTPOS_PROVIDER;
        }

        if (!!reason) {
          // @ts-ignore
          window.parent._tyro_response_packet = '';
          // @ts-ignore
          window.parent._tyro_payment_status = '';

          Logger.log('Payment cancelled by user');
          if (reason === FAILURE_REASON.PAYMENT_CANCELLED) {
            Logger.log('-----  ----- Tyro Cancelled ----- ----', 'info');
            Logger.log(JSON.stringify(result));
            Logger.log('----- ----- ----- ----- ----- -----', 'info');
            throw_reason = reason;
          }
        }

        if (!!result) {
          // NOTE: if we don't do this then we will push incorrect data to authSalePacket for the next trans
          // @ts-ignore
          window.parent._tyro_response_packet = '';
          // @ts-ignore
          window.parent._tyro_payment_status = '';

          Logger.log('-----  ----- Tryo Success Result ----- ----', 'info');
          Logger.log(JSON.stringify(result));
          Logger.log('----- ----- ----- ----- ----- -----', 'info');
          if (result && result.success && result.transactionData) {
            const { transactionData } = result;
            const subPayments = getSelectedPaymentMethods(
              getState() as EntireFrontendState,
            );
            let subPayment = subPayments.filter(
              subPayment => subPayment.method === PAYMENT_METHOD.EFTPOS,
            )[0];
            subPayment = {
              ...subPayment,
              receiptText: transactionData?.customerReceipt,
              cardType: transactionData?.cardType,
              referenceNumber: transactionData?.rrn,
              authorisationCode: transactionData?.authorisationCode,
            };

            const getLatestPaymentMethods = getSelectedPaymentMethods(
              getState() as EntireFrontendState,
            );
            Logger.log(
              '-----  ----- Updated Payment Methods ----- ----',
              'info',
            );
            Logger.log(JSON.stringify(getLatestPaymentMethods));
            Logger.log('----- ----- ----- ----- ----- -----', 'info');

            dispatch(updateSelectedPaymentMethod(subPayment));
            // Step 5: Return a successful flow when payment is processed

            const tyroPollEnd = moment();
            const tyroPollResponseTime = tyroPollEnd.diff(tyroPollStart);

            Logger.logUpstream({
              DeviceID: deviceId as string,
              StoreID: currentLocationId!,
              Action: 'AFTER_PAYMENT',
              DateTime: new Date().toLocaleString('en', {
                timeZone: 'Australia/Sydney',
              }),
              Data: {
                Endpoint: 'N/A',
                SentBody: null,
                ResponseCode: result && 200,
                ResponseBody: JSON.stringify({ result, reason }),
                ResponseTime: tyroPollResponseTime,
              },
            });

            dispatch(
              sale({ route: 'checkout', authenticationMethod: 'trusted' }),
            ).unwrap();
            return;
          } else if (
            result &&
            Object.is(result.success, false) &&
            result.transactionData.result === 'CANCELLED'
          ) {
            Logger.log('-----  ----- Tyro Cancelled ----- ----', 'info');
            Logger.log(JSON.stringify(result));
            Logger.log('----- ----- ----- ----- ----- -----', 'info');

            throw_reason = FAILURE_REASON.PAYMENT_CANCELLED;
          } else if (
            result &&
            Object.is(result.success, false) &&
            result.requestedSignature
          ) {
            Logger.log(
              '-----  ----- Tyro Signature Required ----- ----',
              'info',
            );
            Logger.log(JSON.stringify(result));
            Logger.log('----- ----- ----- ----- ----- -----', 'info');
            throw_reason = FAILURE_REASON.SIGNATURE_REQUIRED;
          } else {
            Logger.log('-----  ----- Tyro Fallback ----- ----', 'info');
            // @ts-ignore
            Logger.log(JSON.stringify(window.parent._tyro_response_packet));
            // @ts-ignore
            Logger.log(JSON.stringify(window.parent._tyro_payment_status));
            Logger.log('----- ----- ----- ----- ----- -----', 'info');
            throw_reason = 'fallback';
          }
        }

        if (throw_reason) {
          const tyroPollEnd = moment();
          const tyroPollResponseTime = tyroPollEnd.diff(tyroPollStart);

          Logger.logUpstream({
            DeviceID: deviceId as string,
            StoreID: currentLocationId!,
            Action: 'AFTER_PAYMENT',
            DateTime: new Date().toLocaleString('en', {
              timeZone: 'Australia/Sydney',
            }),
            Data: {
              Endpoint: 'N/A',
              SentBody: null,
              ResponseCode: result && 200,
              ResponseBody: JSON.stringify({ result, reason }),
              ResponseTime: tyroPollResponseTime,
            },
          });

          return throw_reason;
        }

        repeat -= 1;
        await delay(500);
        return await tyro_response();
      };

      const getFailureReason = await tyro_response();

      if (getFailureReason) {
        throw new Error(getFailureReason);
      }
    } catch (e) {
      console.warn('Tyro shell payment failed', { e });
      Logger.log(`Error ==> Tyro shell payment failed ${e}`);
      return rejectWithValue({
        e,
        reason: throw_reason ? throw_reason : (e as any).message,
      });
    }
  },
);

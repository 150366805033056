import { combineReducers, Reducer, AnyAction } from 'redux';

import cart from './cart';
import notes from './notes';

const rootReducer: Reducer<ShadowOrder, AnyAction> = combineReducers({
  cart,
  notes,
});

export default rootReducer;

import { createSelector } from 'reselect';
import getGiftcardSurcharge from './getGiftcardSurcharge';

export default createSelector(
  [getGiftcardSurcharge, (_: any, value: number) => value],
  (surcharge, value) => {
    return {
      description: surcharge?.ServiceFeeDesc,
      value: surcharge?.ServiceFeeIsPercentage
        ? value === 0
          ? 0
          : value * surcharge.ServiceFee
        : surcharge
        ? surcharge?.ServiceFee! * 100
        : 0,
    };
  },
);

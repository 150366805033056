import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { resetOrder } from '../../actions/currentOrder';

const initialState = uuidv4();

const slice = createSlice({
  name: '$uuid',
  initialState,
  reducers: {
    resetSaleUUID(state, action) {
      return uuidv4();
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      const { uuid } = action.payload;
      return uuid;
    });
  },
});

export const { resetSaleUUID } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HEADER_BRANDING_TYPES } from '../../constants';

const initialState = HEADER_BRANDING_TYPES.LONG;

const slice = createSlice({
  name: 'headerBrandingType',
  initialState: initialState,
  reducers: {
    setHeaderBrandingType(state, action: PayloadAction<number>) {
      return action.payload || initialState;
    },
  },
});

export const { setHeaderBrandingType } = slice.actions;

export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'disableTableNumberSaleBody',
  initialState: false,
  reducers: {
    setDisableTableNumberSaleBody(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});
export const { setDisableTableNumberSaleBody } = slice.actions;
export default slice.reducer;

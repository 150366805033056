import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | number;

const slice = createSlice({
  name: 'menuCategoriesBarHeight',
  initialState,
  reducers: {
    setMenuCategoriesBarHeight(state, action: PayloadAction<number>) {
      return action.payload || initialState;
    },
  },
});

export const { setMenuCategoriesBarHeight } = slice.actions;

export default slice.reducer;

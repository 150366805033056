import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import StandardButton from '../components/StandardButton';
import * as Yup from 'yup';
import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import RadioCheck from './RadioCheck';
import Text from './Text';
import { LANGUAGES } from 'polygon-utils';
import CheckWrapper from './CheckWrapper';
import { Col } from 'reactstrap';
import { createTheme, ThemeProvider } from '@mui/system';

interface IProps {}

const { getLanguage } = OrderingSelectors;
const { setLanguage } = OrderingOperations;

const LanguageSelection: React.FC<IProps> = () => {
  const { t, i18n } = useTranslation();
  const { languages: i18nLanguages, language } = i18n;
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const languageLocal = useAppSelector(getLanguage);

  const handleFormSubmit = (
    { language }: { language: string },
    {
      setSubmitting,
      resetForm,
    }: { setSubmitting: (isSubmitting: boolean) => void; resetForm: () => void },
  ) => {
    dispatch(setLanguage(language));
    i18n.changeLanguage(language);
    setSubmitting(false);
  };

  const schemas = {
    language: Yup.string().required(t('form.required')),
  };
  const validationSchema = Yup.object().shape(schemas);

  const radioCheckStyling = createTheme({
    palette: {
      borderRadius: '100%',
    },
  });

  return (
    <div className="text-center py-2">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ language: languageLocal }}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          submitForm,
          isValid,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            {LANGUAGES.filter(lang => i18nLanguages.includes(lang.code)).map(lang => (
              <div className="position-relative" style={{ margin: 40 }}>
                <ThemeProvider theme={radioCheckStyling}>
                  <CheckWrapper onClick={() => setFieldValue('language', lang.code)}>
                    <Col xs="9" className="text-start">
                      <Text>{t(`languages.${lang.code}`)}</Text>
                    </Col>
                    <Col xs="3">
                      <div
                        className={`check-wrapper ${lang.code === values.language && 'checked'}`}
                      ></div>
                    </Col>
                  </CheckWrapper>
                </ThemeProvider>
              </div>
            ))}
            <div className="position-relative" style={{ marginTop: 100 }}>
              <StandardButton
                containerStyle={styles.button}
                themeKey="loyaltyButton"
                label={t('button.apply')}
                onClick={handleSubmit}
                disabled={isSubmitting || !isValid}
                showSpinner={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default LanguageSelection;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setTransactionHistory } from '../reducers/transactionHistory';
import Api, { AuthenticationMethod, FetchParams } from '../utils/Api';

export const fetchTransactionHistory = createAsyncThunk(
  '$fetchTransactionHistory',
  async (
    data: {
      authenticationMethod?: AuthenticationMethod;
    },
    { dispatch, rejectWithValue },
  ) => {
    const { authenticationMethod = 'member' } = data;

    try {
      const params: FetchParams = {
        path: '/api/v1/profile/transactions?sort=TxnDate&dir=desc&group_sales=true',
        method: 'GET',
      };

      const response = await Api.fetch(params, authenticationMethod);
      const rawTransactions = response.data;
      dispatch(setTransactionHistory(rawTransactions));
    } catch (e) {
      console.warn('Fetch transaction history', { e });
      return rejectWithValue(e);
    }
  },
);

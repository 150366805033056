import { IN_DEVELOPMENT } from '../constants';

import getLocalValue from './getLocalValue';

export function debugEnabled() {
  return (
    IN_DEVELOPMENT ||
    getLocalValue('DEBUG') ||
    window.DEBUG ||
    getLocalValue('debug') ||
    window.debug
  );
}

export default function (...args: any[]) {
  // Performs actions if debug is enabled.
  if (!debugEnabled()) {
    return;
  }

  if (typeof args[0] === 'function') {
    // If the first argument is a function, it is executed using the other arguments.
    args[0](...args.slice(1));
  } else if (args[0] === false) {
    // if the first argument is 'false', treat it as an error
    console.error(...args.slice(1));
  } else {
    // Otherwise it logs all of the arguments.
    console.log(...args);
  }
}

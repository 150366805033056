import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../components/Text';
import LanguageSelection from '../components/LanguageSelection';

export const CHANGE_LANGUAGE_ROUTE = '/change-language';

const ChangeLanguageScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <div className=" my-2">
        <Text value={t('changeLanguageInfo')} themeKey="loyaltyHeaderInfo" />
      </div>
      <LanguageSelection />
    </div>
  );
};

export default ChangeLanguageScreen;

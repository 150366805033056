import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMenu } from './fetchMenu';
import { applyBuffer } from './applyBuffer';
import { reserveOrCheckBuffer } from './reserveOrCheckBuffer';
import { v4 as uuidv4 } from 'uuid';
import { clearBuffer } from '../operations';
import { $getLocationId } from '../selectors/getLocationId';
import { $getSaleType } from '../selectors/getSaleType';
import { setBufferReadyToApply } from '../actions/buffer';
import { fetchMaxOrderValueRules } from './fetchMaxOrderValueRules';

export const updateMenu = createAsyncThunk(
  '$updateMenu',
  async (
    data: {
      autoApply?: boolean;
      isSubFlow?: boolean;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    const { isSubFlow, autoApply } = data;
    let controlsBuffer;
    try {
      const flowId = uuidv4();

      dispatch(reserveOrCheckBuffer({ flowId }));
      controlsBuffer = true;

      if (!isSubFlow) {
        dispatch(clearBuffer({ preserveReservationId: true }));
      }

      const locationId = $getLocationId(getState() as EntireFrontendState);
      const saleType = $getSaleType(getState() as EntireFrontendState);

      if (locationId == null || saleType == null) {
        throw new Error('missing required parameters');
      }

      await dispatch(fetchMaxOrderValueRules());
      await dispatch(fetchMenu()).unwrap();

      if (!isSubFlow) {
        dispatch(setBufferReadyToApply(true));

        if (autoApply) {
          await dispatch(applyBuffer());
        }
      }
    } catch (e) {
      if (!isSubFlow && controlsBuffer) {
        dispatch(clearBuffer({}));
      }
      console.error('Update menu failed', e);
      return rejectWithValue(e);
    }
  },
);

export default function parseWindcaveReceipt(receipt: string) {
  const processReceipt = [];
  let currentLine = '';
  for (let i = 0; i < receipt.length; i++) {
    // TODO: If we want to format the receipt nicely then we can use array of items and add spaces
    let tmpLine = receipt.slice(i, i + 26);
    tmpLine = tmpLine.toString().replace(/\s{2,}/g, '\n');
    const lineSplit = tmpLine.split('\n');
    console.log(lineSplit);
    // TODO -- if formatting is required
    // currentLine = `${formattedItem[0]}${' '.repeat(
    //   40 - formattedItem[0].length - replacePounds.length,
    // )}${replacePounds}`;

    // Length of the receipt lines is 27 so easiest way to do it is splitting the lines
    processReceipt.push(receipt.slice(i, i + 26));
    i = i + 26;
  }
  let formattedReceipt = processReceipt.join('\n');
  // Maybe this is something they need to repalce in the windcave side?
  formattedReceipt = formattedReceipt.replace('APP LABEL', 'Kiosk');
  formattedReceipt = formattedReceipt.replaceAll(' AUD', 'AUD ');

  return formattedReceipt;
}

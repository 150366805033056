import { createSelector } from 'reselect';

import getOrderReadyToSubmit from './getOrderReadyToSubmit';
import getAvailablePaymentMethods from './getAvailablePaymentMethods';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector(
  [getOrderReadyToSubmit, getAvailablePaymentMethods],
  (orderReady, availablePaymentMethods): boolean => {
    return orderReady && availablePaymentMethods.includes(PAYMENT_METHOD.FREE);
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as DeviceDimensions | null;

const slice = createSlice({
  name: 'deviceDimensions',
  initialState,
  reducers: {
    setDeviceDimensions(state, action: PayloadAction<DeviceDimensions>) {
      const { width, height } = action.payload;
      return { width, height };
    },
  },
});

export const { setDeviceDimensions } = slice.actions;

export default slice.reducer;

import { SHA256, MD5, enc as Encoders } from 'crypto-js';

// TODO: remove need for this by revising exception construction logic
interface ApiException {
  details: {
    type?: string;
    json?: {
      error: string;
    };
  };
  message?: string;
}
// TODO: remove need for this by revising exception construction logic
export function outputApiException(apiException: ApiException) {
  if (apiException.details.json) {
    return apiException.details.json.error;
  }

  return apiException.message;
}

export function determineFullLink(endpoint: string, path: string): string {
  if (path && path.startsWith('http')) {
    return path;
  }

  const partialPath = (path || '').replace(/^\/*/, '');
  const partialEndpoint = (endpoint || '').replace(/\/*$/, '');

  return `${partialEndpoint}/${partialPath}`;
}

// TODO: getting 'Expression is always false' warnings. Investigate typing
// TODO: investigate splitting function (current branching complexity is quite high)
export function objectToArray(obj: { [key: string]: any }, arr: any) {
  switch (true) {
    case obj != null && typeof obj === 'object' && obj.constructor === Object:
      Object.keys(obj).forEach(function (key) {
        let keyArr = [];

        if (
          typeof obj[key] === 'object' &&
          obj[key] != null &&
          (obj[key].constructor === Array || obj[key].constructor === Object)
        ) {
          const subArr: any = [];

          switch (obj[key].constructor) {
            case Array:
              obj[key].forEach((obj: { [key: string]: any }) => objectToArray(obj, subArr));
              break;
            case Object:
              objectToArray(obj[key], subArr);
              break;
          }

          keyArr.push(key, subArr.sort());
        } else {
          keyArr = [key && key.toString(), (obj[key] != null && obj[key].toString()) || ''];
        }

        keyArr.length > 0 && arr.push(keyArr);
      });
      break;

    case typeof obj === 'number' || typeof obj === 'boolean' || typeof obj === 'string':
      arr.push(obj.toString());
      break;
  }
  arr.sort();
}

export function sha256(data: string): string {
  let hash = SHA256(data);
  return hash.toString(Encoders.Hex);
}

export function md5(data: string): string {
  let hash = MD5(data);
  return hash.toString(Encoders.Hex);
}

export function caseInsensitiveContains(inputString: string, substring: string): boolean {
  return inputString.toLowerCase().indexOf(substring.toLowerCase()) !== -1;
}

export function deterministicSerialiser(input: any): string {
  const inputType = typeof input;

  switch (inputType) {
    case 'number':
    case 'boolean':
    case 'symbol':
    case 'bigint': {
      return `${input}`;
    }
    case 'string': {
      return `"${input}"`;
    }
    case 'undefined': {
      return 'null';
    }
    case 'object': {
      if (input === null) {
        return 'null';
      } else if (Array.isArray(input)) {
        return `[${input.map(deterministicSerialiser).join(', ')}]`;
      } else {
        const sortedEntries = Object.entries(input).sort((a, b) => a[0].localeCompare(b[0]));

        return `{${sortedEntries
          .map(
            entry => `${deterministicSerialiser(entry[0])}: ${deterministicSerialiser(entry[1])}`,
          )
          .join(', ')}}`;
      }
    }
    case 'function':
    default:
      throw new Error('should not happen');
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | SavedCard[];

const slice = createSlice({
  name: 'savedCards',
  initialState,
  reducers: {
    setSavedCards(state, action: PayloadAction<null | SavedCard[]>) {
      return action.payload || initialState;
    },
    removeSavedCard(state, action: PayloadAction<SavedCard | PaymentGateWayToken>) {
      const { token } = action.payload;
      return state?.filter(card => card.token !== token);
    },
  },
});

export const { setSavedCards, removeSavedCard } = slice.actions;

export default slice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setReturnModalDetails } from '../slices/returnModalDetails';
import { setCurrentModalId } from '../slices/currentModalId';
import { RootState } from 'store';

export const selectReturnModalDetails = (state: RootState) => state.returnModalDetails;
export const selectCurrentModalId = (state: RootState) => state.currentModalId;
export const selectCalloutOffsets = (state: RootState) => state.calloutOffsets;

export const setCurrentModal = createAsyncThunk(
  'setCurrentModal',
  async (data: ModalData | null | string, { dispatch, getState }) => {
    if (data == null) {
      const returnModalDetails = selectReturnModalDetails(getState() as RootState);

      if (returnModalDetails) {
        dispatch(setReturnModalDetails(null));
        dispatch(
          setCurrentModalId({
            modalId: returnModalDetails.modalId,
            calloutOffsets: returnModalDetails.calloutOffsets || undefined,
          }),
        );
      } else {
        dispatch(setCurrentModalId(null));
      }
    } else {
      if (typeof data !== 'string') {
        if (data.preserveReturnModalDetails) {
          const currentModalId = selectCurrentModalId(getState() as RootState);
          const calloutOffsets = selectCalloutOffsets(getState() as RootState);

          dispatch(setReturnModalDetails({ modalId: currentModalId, calloutOffsets }));
        } else if (data.clearReturnModalDetails) {
          dispatch(setReturnModalDetails(null));
        }
      }

      dispatch(setCurrentModalId(data));
    }
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEVICE_TYPES } from '../constants';

const initialState: number = DEVICE_TYPES.MOBILE; // mobile first?

const slice = createSlice({
  name: 'deviceType',
  initialState,
  reducers: {
    setDeviceType(state, action: PayloadAction<number>) {
      return action.payload;
    },
  },
});

export const { setDeviceType } = slice.actions;

export default slice.reducer;

import { getWidth } from './screen';

interface BoundingRectangle {
  left: number;
  right: number;
  bottom: number;
  width: number;
}

export default function (boundingRectangle: BoundingRectangle): {
  topOffset?: number;
  rightOffset?: number;
} {
  const width: number = getWidth();

  const middleX = boundingRectangle.right - boundingRectangle.width / 2;

  const rightOffset = width - middleX;
  const topOffset = boundingRectangle.bottom;

  return { topOffset, rightOffset };
}

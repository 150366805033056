import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import LoyaltyWrapper from '../components/LoyaltyWrapper';
import RedirectAndPreserveSearch from '../components/RedirectAndPreserveSearch';
import PrivateRoute from './PrivateRoute';
import useRoutes, { INITIAL_SCREEN_ROUTE } from '../hooks/useRoutes';
import useInjection from '../hooks/useInjection';
import { PURCHASE_GIFT_CARD_ROUTE } from './PurchaseGiftCardScreen';
import { useAppSelector } from '../app/hooks';
import FeedbackScreen, { FEEDBACK_SCREEN } from '../screens/FeedbackScreen';

const Routes = () => {
  const {
    defaultRoutes,
    loyaltyRoutes,
    loyaltyNoAuthRoutes,
    loyaltyMigrationRoutes,
    unauthorisedRoutesWithWrapper,
    profileRoutes,
  } = useRoutes();

  useInjection();

  return (
    <Switch>
      {defaultRoutes?.map(route => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          render={() => <route.component />}
        />
      ))}

      {[...loyaltyMigrationRoutes, ...unauthorisedRoutesWithWrapper, ...loyaltyNoAuthRoutes]?.map(
        route => (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            render={() => (
              <LoyaltyWrapper hideHero={route.hideHero} showBackOnMobile={route.showBackOnMobile}>
                <route.component />
              </LoyaltyWrapper>
            )}
          />
        ),
      )}

      {loyaltyRoutes?.map(route => (
        <PrivateRoute key={route.path} {...route} />
      ))}

      {profileRoutes.map(route => (
        <PrivateRoute key={route.path} {...route} />
      ))}
      <RedirectAndPreserveSearch to={INITIAL_SCREEN_ROUTE} />
    </Switch>
  );
};

export default Routes;

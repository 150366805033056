import React from 'react';
import Text from './Text';
import { useTranslation } from 'react-i18next';

const LoyaltySubTitle: React.FC<{ title: string | undefined }> = ({ title }) => {
  const { t } = useTranslation();
  return (
    <div className="text-left pt-3">
      <Text themeKey="loyaltySubTitle" value={t(title || '')} />
    </div>
  );
};

export default LoyaltySubTitle;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { OrderingSelectors } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';
import getOrderInProgress from '../selectors/getOrderInProgress';
import combineStyles from '../utils/combineStyles';
import { COMPLETED_ORDER_SCREEN_ROUTE, MENU_SCREEN_ROUTE } from '../hooks/useRoutes';
import ScreenHero from '../components/ScreenHero';
import ScreenFloating from '../components/ScreenFloating';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text';
import RedirectAndPreserveSearch from '../components/RedirectAndPreserveSearch';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Row, Col } from 'reactstrap';
import { IconType } from 'react-icons';
import { RiHeart3Line, RiTimeLine } from 'react-icons/ri';
import { PAST_ORDERS_SCREEN_ROUTE } from './PastOrdersScreen';
import { FAVOURITES_SCREEN_ROUTE } from './FavouritesScreen';
import history from '../history';
import getDisabledLoyaltyRoutes from '../utils/getDisabledLoyaltyRoutes';
import getConfig from '../selectors/getConfig';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';
import SaleTypeButton from '../components/SaleTypeButton';

const { getOrderSubmitted, getMember } = OrderingSelectors;

const InitialScreen = () => {
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const displayableSaleTypes = useAppSelector(getDisplayableSaleTypes);
  const orderInProgress = useAppSelector(getOrderInProgress);
  const orderSubmitted = useAppSelector(getOrderSubmitted);
  const member = useAppSelector(getMember);
  const dispatch = useAppDispatch();
  const config = useAppSelector(getConfig);
  const disabledLoyaltyRoutes = getDisabledLoyaltyRoutes(config);
  const loyaltyDisabled = useAppSelector(state => state.config.disableLoyalty);
  const enableOnlyLoyalty = useAppSelector(state => state.config.enableOnlyLoyalty);
  const { t } = useTranslation();
  const pathname = useLocation();

  useEffect(() => {
    if (!loyaltyDisabled && !member) {
      dispatch(setCurrentModal(SIGN_IN_MODAL_ID));
    }
  }, []);

  const getLocationId = () => {
    let locationId = null;

    if (pathname.state) {
      locationId = pathname.state.locationId;
    }

    return locationId;
  };

  const ScreenComponent = deviceTypeMobile ? ScreenHero : ScreenFloating;

  const columnStyles = combineStyles(styles.column, deviceTypeMobile && styles.columnMobile);

  if (orderSubmitted) {
    return <RedirectAndPreserveSearch to={COMPLETED_ORDER_SCREEN_ROUTE} />;
  }

  if (orderInProgress) {
    return <RedirectAndPreserveSearch to={MENU_SCREEN_ROUTE} />;
  }

  const renderIcon = (Icon: IconType) => {
    return Icon ? (
      <Icon size="30" style={combineStyles(p('saleTypeIcons', ['fontSize', 'color']))} />
    ) : null;
  };

  const showFavourites = !disabledLoyaltyRoutes.includes(FAVOURITES_SCREEN_ROUTE);
  const showPastOrders = !disabledLoyaltyRoutes.includes(PAST_ORDERS_SCREEN_ROUTE);

  return (
    <ScreenComponent>
      <div
        style={combineStyles(
          deviceTypeMobile ? styles.mainContainerMobile : styles.mainContainerDesktop,
          p('screen', ['backgroundColor']),
          p(deviceTypeMobile ? 'initialScreenMobile' : 'initialScreenDesktop', [
            'backgroundColor',
            'boxShadow',
          ]),
        )}
      >
        <div style={columnStyles}>
          {!deviceTypeMobile && (
            <div className="text-center mb-3">
              <Text themeKey="sectionTitle" value={t('title.newOrder')} />
            </div>
          )}

          {(displayableSaleTypes || [])?.map(saleType => (
            <SaleTypeButton saleType={saleType} locationId={getLocationId()} key={saleType} />
          ))}

          {member && !loyaltyDisabled && (
            <Row>
              {showFavourites && (
                <Col className=" m-0 p-0">
                  <StandardButton
                    onClick={() => history.push(FAVOURITES_SCREEN_ROUTE)}
                    label="Favourites"
                    themeKey="pastOrdersButton"
                    LeftIconComponent={RiHeart3Line}
                    leftIconStyle={{
                      fontSize: 25,
                      color: p('saleTypeIcons', ['color']).color,
                    }}
                  />
                </Col>
              )}
              {showPastOrders && (
                <Col className=" m-0 p-0">
                  <StandardButton
                    onClick={() => history.push(PAST_ORDERS_SCREEN_ROUTE)}
                    label="Past Orders"
                    themeKey="pastOrdersButton"
                    LeftIconComponent={RiTimeLine}
                    leftIconStyle={{
                      fontSize: 25,
                      color: p('saleTypeIcons', ['color']).color,
                    }}
                  />
                </Col>
              )}
            </Row>
          )}
        </div>
      </div>
    </ScreenComponent>
  );
};

const styles = {
  mainContainerDesktop: {
    borderRadius: 5,
    padding: 35,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    margin: 'auto', // https://stackoverflow.com/a/33455342/8706204
  },
  mainContainerMobile: {
    flex: 1,
    padding: '10px 25px 100px 25px',
  },
  column: {
    minWidth: 300,
    minHeight: 500,
  },
  columnMobile: {
    padding: '20px 0 0 0',
  },
  button: {
    margin: '20px 20px',
  },
  verticalSeperator: {
    width: 1.5,
    marginLeft: 40,
    marginRight: 40,
  },
};

export default InitialScreen;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';

const initialState = null as TokenisedAddress | null;

const slice = createSlice({
  name: '$deliveryAddress',
  initialState,
  reducers: {
    setDeliveryAddress(state, action: PayloadAction<TokenisedAddress | null>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(applyBufferData, (state, action) => {
      const { deliveryAddress } = action.payload;
      return deliveryAddress === undefined ? state : deliveryAddress;
    });
  },
});

export const { setDeliveryAddress } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {} as MerchantConfig;

const slice = createSlice({
  name: '$merchant',
  initialState,
  reducers: {
    setMerchantConfig(state, action: PayloadAction<MerchantConfig>) {
      return action.payload;
    },
  },
});

export const { setMerchantConfig } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false as boolean;

const slice = createSlice({
  name: '$printerlessConfig',
  initialState,
  reducers: {
    setPrinterless(state, action: PayloadAction<boolean>) {
      return action.payload === true;
    },
  },
});

export const { setPrinterless } = slice.actions;
export default slice.reducer;

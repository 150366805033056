import { createAsyncThunk } from '@reduxjs/toolkit';
import { getChoiceSetDiscountConfig } from '../selectors/config';
import getItems from '../selectors/getItems';
import getUnenhancedOpenPurchase from '../selectors/getUnenhancedOpenPurchase';
import adjustChoiceSetMaxForQuantityDiscount from '../utils/ordering/adjustChoiceSetMaxForQuantityDiscount';
import adjustedSelections from '../utils/ordering/adjustedSelections';

export const adjustOpenPurchaseChoice = createAsyncThunk(
  '$adjustOpenPurchaseChoice',
  async (
    data: {
      targetChoiceSetId: string;
      targetChoiceId?: string;
      clear?: boolean;
      decrease?: boolean;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { targetChoiceSetId, targetChoiceId, clear, decrease } = data;
      const openPurchase = getUnenhancedOpenPurchase(
        getState() as EntireFrontendState,
      )!;

      let adjustedPurchase: _Purchase;

      if (clear) {
        const newTargetSelections = (
          openPurchase!.choiceSelections as ChoiceSelections
        )[targetChoiceSetId].filter(
          c =>
            targetChoiceId !== undefined &&
            targetChoiceId !== (typeof c === 'string' ? c : (c as Choice).id),
        );

        adjustedPurchase = {
          ...openPurchase,
          choiceSelections: {
            ...(openPurchase!.choiceSelections as ChoiceSelections),
            [targetChoiceSetId]: newTargetSelections,
          },
        };
      } else {
        if (!targetChoiceId) {
          throw new Error(
            "targetChoiceId required when 'clear' param is false",
          );
        }

        const items = getItems(getState() as EntireFrontendState);
        const item = items![openPurchase.sizeId ?? openPurchase.itemId];

        let targetChoiceSet = item.choiceSets.find(
          set => set.key === targetChoiceSetId,
        ) as ValidatedChoiceSet;

        const choiceSetDiscountConfig = getChoiceSetDiscountConfig(
          getState() as EntireFrontendState,
        );

        if (choiceSetDiscountConfig) {
          const {
            choiceSetKey: discountChoiceSetId = '',
            tierSize: discountTierSize = 1,
          } = choiceSetDiscountConfig as ChoiceSetQuantityDiscountConfig;

          targetChoiceSet = adjustChoiceSetMaxForQuantityDiscount(
            targetChoiceSet,
            discountChoiceSetId,
            discountTierSize,
          );
        }

        adjustedPurchase = {
          ...openPurchase,
          choiceSelections: adjustedSelections(
            targetChoiceSet.max,
            targetChoiceSet.individualMax,
            openPurchase!.choiceSelections as ChoiceSelections,
            targetChoiceSetId,
            targetChoiceId,
            choiceSetDiscountConfig,
            decrease,
          ),
        };
      }

      return {
        purchase: adjustedPurchase,
      };
    } catch (e) {
      console.error('Adjust open purchase choice failed', { e });
      return rejectWithValue(e);
    }
  },
);

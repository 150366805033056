import React from 'react';
import Text from '../components/Text';
import { useTranslation } from 'react-i18next';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import combineStyles from '../utils/combineStyles';
import LoyaltyTitle from '../components/LoyaltyTitle';

import { FormikValues, useFormikContext } from 'formik';

export const FEEDBACK_COMPLETED_SCREEN = '/feedback/completed';

const FeedbackCompletedScreen = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  const { values } = useFormikContext<FormikValues>();
  const formValues = values as Feedback;

  return (
    <div>
      <LoyaltyTitle
        title={t('title.feedback.thanksTitle')}
        style={{
          marginBottom: '1em',
        }}
        titleTranslated
      />
      <div style={styles.container}>
        <Text style={{ textAlign: 'center' }}>
          {t(
            formValues.couponGiven ? 'title.feedback.couponGiven' : 'title.feedback.thanksMessage',
          )}
        </Text>
      </div>
    </div>
  );
};

export default FeedbackCompletedScreen;

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
};

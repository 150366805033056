import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'loyaltyBarcodeView',
  initialState: true,
  reducers: {
    setLoyaltyBarcodeView(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setLoyaltyBarcodeView } = slice.actions;

export default slice.reducer;

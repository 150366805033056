import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | Date;

const slice = createSlice({
  name: 'loyaltyDateFilters',
  initialState,
  reducers: {
    setLoyaltyDateFilters(state, action: PayloadAction<null | Date>) {
      return action.payload || initialState;
    },
  },
});

export const { setLoyaltyDateFilters } = slice.actions;

export default slice.reducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { IoChevronBack, IoClose } from 'react-icons/io5';
import combineStyles from '../utils/combineStyles';

import getThemeLookup from '../selectors/getThemeLookup';

import ModalFloatingButton from './ModalFloatingButton';
import Text from './Text';
import { useAppSelector } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

interface IProps {
  title?: string;
  onClose?: () => void;
  onGoBack?: () => void;
  locked?: boolean;
  backButtonEnabled?: boolean;
  closeButtonEnabled?: boolean;
  containerStyle?: React.CSSProperties;
  closeButtonLeft?: boolean;
}

const ModalFloatingHeader: React.FC<IProps> = ({
  title,
  onClose,
  onGoBack,
  locked,
  backButtonEnabled,
  closeButtonEnabled = true,
  containerStyle,
  closeButtonLeft,
}) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);

  const backButton = (
    <ModalFloatingButton
      onClick={onGoBack!}
      IconComponent={IoChevronBack}
      buttonStyle={{ paddingRight: 2 }}
      disabled={locked}
    />
  );

  const closeButton = (
    <ModalFloatingButton onClick={onClose!} IconComponent={IoClose} disabled={locked} />
  );

  const modalTitle = <Text themeKey="modalTitle">{title}af;opsdj</Text>;

  return (
    <div style={{ position: 'absolute', width: '100%', pointerEvents: 'none' }}>
      <div
        style={combineStyles(
          {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: closeButtonLeft ? 'flex-end' : 'flex-start',
            alignItems: 'flex-start',
            padding: isMobileDevice ? 5 : 20,
            width: '100%',
            position: 'absolute',
          },
          containerStyle,
        )}
      >
        {isMobileDevice ? (
          <>
            <div style={{ flexShrink: 1 }}>{backButtonEnabled && backButton}</div>
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                width: '100%',
                left: 0,
                boxSizing: 'content-box',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {!!title && modalTitle}
            </div>
            <div style={{ flexShrink: 1 }}>
              {!backButtonEnabled && closeButtonEnabled && closeButton}
            </div>
          </>
        ) : (
          <>
            <div style={{ flexShrink: 1 }}>
              {backButtonEnabled ? backButton : closeButtonEnabled ? closeButton : null}
            </div>
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                width: '100%',
                left: 0,
                boxSizing: 'content-box',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {' '}
              {!!title && modalTitle}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles: Styles = {};

export default ModalFloatingHeader;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { FAILURE_REASON } from '../constants';
import Api, { FetchParams } from '../utils/Api';
import processMember from '../utils/processors/processMember';
import processGiftCard from '../utils/processors/processGiftCard';
import processOffer from '../utils/processors/processOffer';
import moment from 'moment';
import { CARD_TYPE } from '../constants/cardType';

export const fetchPOSPoints = createAsyncThunk(
  '$fetchPOSPoints',
  async (
    data: {
      barcode: string;
      locationId: string;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { barcode, locationId } = data;
    let reason: FetchPOSPointsFailureReasons;
    try {
      const encodedBarcode = encodeURIComponent(barcode);

      const params: FetchParams = {
        path: `/api/v1/stores/${locationId}/trusted-pospoints/${encodedBarcode}`,
        method: 'GET',
      };

      const response = await Api.fetch(params, 'trusted');

      const {
        coupon: rawOffer,
        member: rawMember,
        plu: rawItemDetails,
      } = response.data as {
        coupon?: RawOffer;
        member?: RawMember;
        plu?: RawItemLookupResult;
      };

      let processedOffer;
      let processedMember;
      let processedItemDetails;
      let giftCard;
      let validationResult;

      // The response was successful but there's no member associated with the barcode.
      if (response.success && !rawMember && !rawOffer && !rawItemDetails)
        return { member: rawMember };

      if (!rawOffer && !rawMember && !rawItemDetails) {
        throw new Error('could not find anything for barcode');
      }

      const checkResult = (result: any) => {
        if (result && result.problem) {
          reason = result.reason;
          throw reason;
        }
      };

      const validateEntity = ({
        entity,
        type,
      }: {
        entity: any;
        type: ENTITY_TYPE;
      }) => {
        const { expiryDate, active, available } = entity;
        let reason;
        let problem = false;

        if (expiryDate && moment(expiryDate) < moment()) {
          reason = ExpiredReasons[type];
          problem = true;
        }

        if (!active && !available) {
          reason = InactiveReasons[type];
          problem = true;
        }

        return { reason, problem };
      };

      if (rawItemDetails) {
        processedItemDetails = {
          id: String(rawItemDetails.PLUCode),
          name: rawItemDetails.PLUItem,
        };
      }

      if (rawOffer) {
        processedOffer = processOffer(rawOffer);
        validationResult = validateEntity({
          entity: processedOffer,
          type: ENTITY_TYPE.OFFER,
        });

        checkResult(validationResult);
      }

      if (rawMember) {
        if (rawMember.CardType === CARD_TYPE.GIFT) {
          giftCard = processGiftCard(rawMember);
          validationResult = validateEntity({
            entity: giftCard,
            type: ENTITY_TYPE.GIFT_CARD,
          });
        } else {
          processedMember = processMember(rawMember);
          validationResult = validateEntity({
            entity: processedMember,
            type: ENTITY_TYPE.MEMBER,
          });
        }

        checkResult(validationResult);
      }

      return {
        member: processedMember,
        offer: processedOffer,
        itemDetails: processedItemDetails as ItemLookupResult,
        giftCard,
      };
    } catch (e) {
      return rejectWithValue({ e, reason });
    }
  },
);

enum ENTITY_TYPE {
  GIFT_CARD,
  OFFER,
  MEMBER,
}

const ExpiredReasons = {
  [ENTITY_TYPE.GIFT_CARD]: FAILURE_REASON.EXPIRED_GIFT_CARD,
  [ENTITY_TYPE.OFFER]: FAILURE_REASON.EXPIRED_OFFER,
  [ENTITY_TYPE.MEMBER]: FAILURE_REASON.EXPIRED_MEMBER,
};

const InactiveReasons = {
  [ENTITY_TYPE.GIFT_CARD]: FAILURE_REASON.INACTIVE_GIFT_CARD,
  [ENTITY_TYPE.OFFER]: FAILURE_REASON.INACTIVE_OFFER,
  [ENTITY_TYPE.MEMBER]: FAILURE_REASON.INACTIVE_MEMBER,
};

import { createSelector } from 'reselect';

import getOrderTotals from './getOrderTotals';
import getCutoffTimes from './getCutoffTimes';
import moment from 'moment';

export default createSelector(
  [getOrderTotals, getCutoffTimes],
  (totals, cutofTimes): moment.Moment => {
    const filteredCutoffTimes = cutofTimes?.filter(
      time => time.MoneyLower <= totals?.moneyPrice!,
    );

    const timeRequiredInAdvance = filteredCutoffTimes?.reduce((a, b) => {
      return a + b.Time;
    }, 0)!;

    const tomorrowNow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    const earliestCateringOrderTime = moment(tomorrowNow)
      .add(timeRequiredInAdvance, 'hours')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    return earliestCateringOrderTime;
  },
);

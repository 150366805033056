import { createSelector } from 'reselect';

import getLocations from './getLocations';
import getLocationId, { $getLocationId } from './getLocationId';

const get = (
  allLocations: SDict<POSLocation> | SDict<POSLocationShort> | undefined,
  currentLocationId: string | undefined,
): POSLocation | undefined => {
  if (!allLocations || currentLocationId === undefined) {
    return undefined;
  }
  const location = allLocations[currentLocationId];
  if (!location) return;
  if (!location._isFullData) return;

  return location;
};

export default createSelector([getLocations, getLocationId], get);

export const $getLocation = createSelector([getLocations, $getLocationId], get);

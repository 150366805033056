import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { updateKeyOrderProperty } from '../thunks/updateKeyOrderProperty';

const initialState = false;

const slice = createSlice({
  name: '$keyOrderPropertyUpdateInProgress',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateKeyOrderProperty.pending, () => {
        return true;
      })
      .addMatcher(
        isAnyOf(
          updateKeyOrderProperty.rejected,
          updateKeyOrderProperty.fulfilled,
        ),
        () => {
          return false;
        },
      );
  },
});

export default slice.reducer;

export const FORGOT_PASSWORD_LINK: string = '/auth/loyalty#/forgot';

export const IN_DEVELOPMENT: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const IS_PRE_RELEASE_VERSION: boolean = String(process.env.REACT_APP_PRE_RELEASE) !== '0';

export const DEFAULT_FAVICON: string = `${process.env.PUBLIC_URL}/images/favicon.ico`;

export const THEME_CLIENT_ID: number = 2; // polygon-web-ordering

export const SALE_INPUT_TYPE: number = 301; // polygon-web-ordering

export const DEVICE_TYPES: { DESKTOP: number; MOBILE: number } = { DESKTOP: 1, MOBILE: 2 };

export const DESKTOP_MODAL_TYPES: { DEFAULT: number; CALLOUT: number } = { DEFAULT: 0, CALLOUT: 1 };

export const MOBILE_DISPLAY_WIDTH_THRESHOLD: number = 501;

export const HEADER_BRANDING_TYPES: { LONG: number; SMALL: number } = { LONG: 1, SMALL: 2 };

export const DEFAULT_RE_CAPTCHA_SITE_KEY: string = '6LemUtMUAAAAAPXgllDG4S9857wMBESB3x2a1xWR';

export const PERSIST_KEY: string = 'web-ordering';

export const MENU_TOP_ANCHOR: string = 'MENU_TOP_ANCHOR';

export const BRAND_ICON_PREFIX: string = '/static/img/ordering/';

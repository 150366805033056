import { createAsyncThunk } from '@reduxjs/toolkit';
import getItems from '../selectors/getItems';
import getUnenhancedOpenPurchase from '../selectors/getUnenhancedOpenPurchase';
import choiceSelectionsFromBase from '../utils/ordering/choiceSelectionsFromBase';

export const resetOpenPurchaseChoiceSets = createAsyncThunk(
  '$resetOpenPurchaseChoiceSets',
  async (
    data: {
      targetChoiceSetIds: string[];
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { targetChoiceSetIds } = data;

      const openPurchase = getUnenhancedOpenPurchase(
        getState() as EntireFrontendState,
      );

      let adjustedPurchase: _Purchase;

      const items = getItems(getState() as EntireFrontendState);
      const item = items![openPurchase?.sizeId ?? openPurchase!.itemId] as Item;

      const defaultChoiceSelections = choiceSelectionsFromBase(item.choiceSets);

      let newChoiceSelections: ChoiceSelections = {
        ...(openPurchase!.choiceSelections as ChoiceSelections),
      };

      targetChoiceSetIds.forEach(choiceSetId => {
        newChoiceSelections[choiceSetId] = defaultChoiceSelections[choiceSetId];
      });

      adjustedPurchase = {
        ...openPurchase!,
        choiceSelections: newChoiceSelections,
      };

      return {
        purchase: adjustedPurchase,
      };
    } catch (e) {
      console.error('Reset open purchase choice sets failed', { e });
      return rejectWithValue(e);
    }
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'googleMapsApiKey',
  initialState: null as string | null,
  reducers: {
    setGoogleMapsApiKey(state, action: PayloadAction<string | null>) {
      return action.payload || null;
    },
  },
});

export const { setGoogleMapsApiKey } = slice.actions;

export default slice.reducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import Modal from '../components/Modal';
import Text from '../components/Text';
import { setCurrentModal } from '../thunks/setCurrentModal';
import RedcatImage from '../components/RedcatImage';
import RedcatBarcode from '../components/RedcatBarcode';
import moment from 'moment';
import { RiCloseCircleFill } from 'react-icons/ri';
export const LOYALTY_OFFER_MODAL_ID = 'LOYALTY_OFFER_MODAL_ID';

const LoyaltyOfferModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const offer = useAppSelector(state => state.loyaltySelectedOffer);

  return (
    <Modal
      desktopMaxWidth={350}
      desktopMinWidth={350}
      hideFloatingHeader
      clickBackgroundToClose
      containerStyle={{
        backgroundColor: 'whitesmoke',
        borderRadius: 10,
        marginBottom: '20px',
        padding: 0,
        gap: 0,
      }}
    >
      <div style={{ position: 'relative' }}>
        <RiCloseCircleFill
          onClick={() => dispatch(setCurrentModal(null))}
          style={{ position: 'absolute', top: 5, left: 5, fontSize: 30 }}
          color="white"
        />
        {!!offer?.image && (
          <RedcatImage
            imagePath={offer.image}
            imgFluid
            imageStyle={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
          />
        )}
      </div>
      <div style={{ padding: 10, textAlign: 'center' }}>
        <Text value={offer?.name} style={{ fontWeight: 'bold', fontSize: 12 }} />
        {!!offer?.barcode && (
          <RedcatBarcode barcodeOptions={{ value: offer?.barcode }} showText={true} />
        )}
        <div>
          {!!offer?.startDate && !!offer?.expiryDate && (
            <Text
              value={
                'Valid ' +
                moment(offer?.startDate).format('DD/MM/YYYY') +
                ' - ' +
                moment(offer?.expiryDate).format('DD/MM/YYYY')
              }
              style={{ fontSize: 12 }}
            />
          )}
          <br />

          <Text block value={offer?.longDescription} style={{ fontSize: 12, marginTop: 10 }} />
        </div>
      </div>
    </Modal>
  );
};

const styles: Styles = {};

export default LoyaltyOfferModal;

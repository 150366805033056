import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../operations';

const initialState = null as string | null;

const slice = createSlice({
  name: '$eftposTxnInProgress',
  initialState,
  reducers: {
    setEftposTxnInProgress(state, action: PayloadAction<string | null>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      return initialState;
    });
  },
});

export const { setEftposTxnInProgress } = slice.actions;
export default slice.reducer;

import React from 'react';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector } from '../app/hooks';

interface IProps {
  themeKey?: string;
  style?: React.CSSProperties | null;
  value?: string;
  children?: React.ReactNode;
  ariaHidden?: boolean;
  onClick?: () => void;
  block?: boolean;
  href?: string;
  openInNewTab?: boolean;
  lines?: number;
}

const Text: React.FC<IProps> = ({
  themeKey,
  style,
  value,
  children,
  ariaHidden,
  onClick,
  block,
  href,
  openInNewTab,
}) => {
  const p = useAppSelector(getThemeLookup);

  const props = {
    style: combineStyles(
      p('defaultText', TEXT_PROPERTIES),
      themeKey && p(themeKey, TEXT_PROPERTIES),
      block && { display: 'block' },
      style,
    ),
    'aria-hidden': ariaHidden, // hide from screen readers
    onClick: onClick,
    ...(href && openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {}),
    href,
  };

  if (href) {
    return <a {...props}>{children != null ? children : value}</a>;
  } else {
    return <span {...props}>{children != null ? children : value}</span>;
  }
};

export default Text;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: boolean = false; // mobile first?

const slice = createSlice({
  name: 'enableThemeKeyHints',
  initialState,
  reducers: {
    setEnableThemeKeyHints(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setEnableThemeKeyHints } = slice.actions;

export default slice.reducer;

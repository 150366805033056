import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetEnableUpsellByCheckout } from '../operations';
import getCurrentOrder from '../selectors/getCurrentOrder';
import { setStagedPurchases } from '../reducers/currentOrder/stagedPurchases';
export const removeStagedPurchase = createAsyncThunk(
  'removeStagedPurchase',
  async (id: string, { dispatch, getState, rejectWithValue }) => {
    try {
      const stagedPurchases = getCurrentOrder(
        getState() as EntireFrontendState,
      ).stagedPurchases.filter(purchase => purchase.id !== id);

      dispatch(setStagedPurchases(stagedPurchases));
      if (!(stagedPurchases.length > 0))
        dispatch(resetEnableUpsellByCheckout(true));
    } catch (e) {
      console.error('Remove staged purchase error:', { e });
      return rejectWithValue(e);
    }
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'brandingFavicon',
  initialState: null as null | string,
  reducers: {
    setBrandingFavicon(state, action: PayloadAction<string>) {
      return action.payload || null;
    },
  },
});

export const { setBrandingFavicon } = slice.actions;

export default slice.reducer;

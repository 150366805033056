import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text'; // TODO: rework this to use Text component
import { setCurrentModal } from '../thunks/setCurrentModal';
import combineStyles from '../utils/combineStyles';
import { useAppSelector, useAppDispatch } from '../app/hooks';

export const GIFTCARD_PURCHASE_SUCCESSFUL_MODAL = 'GIFTCARD_PURCHASE_SUCCESSFUL_MODAL';

const GiftcardSuccessModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalParams = useAppSelector(state => state.modalParams);
  const giftcard = modalParams?.giftcard;

  return (
    <Modal
      title={modalParams?.title}
      desktopMaxWidth={350}
      desktopMinWidth={350}
      hideFloatingHeader
    >
      <Text block value={'Payment Complete'} themeKey="messageModalMessage" />
      <Text
        block
        value={`We've sent an email to ${giftcard?.email} with the card details.`}
        themeKey="messageModalMessage"
      />

      <Text block value={`Receipt: ${giftcard?.SalesID} `} themeKey="messageModalMessage" />

      <StandardButton
        label={t('button.ok')}
        onClick={() => dispatch(setCurrentModal(null))}
        themeKey="messageModalButton"
      />
    </Modal>
  );
};

const styles: Styles = {};

export default GiftcardSuccessModal;

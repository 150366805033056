import { createSelector } from 'reselect';
import lodash from 'lodash';
import getLocation from './getLocation';
import getRootCategory from './getRootCategory';

export default createSelector(
  [getRootCategory, getLocation],
  (rootCategory, location) => {
    const categories = rootCategory?.subCategories || [];
    const representedBrandIds = lodash
      .chain(categories)
      .map(category => category.brandId)
      .uniq()
      .value();

    const filteredBrands = (location?.brands || []).filter(brand =>
      representedBrandIds.includes(brand.id),
    );
    const sortedBrands = lodash.sortBy(filteredBrands, 'sortOrder');

    return sortedBrands;
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = 'en';

const slice = createSlice({
  name: '$setLanguage',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      return action.payload;
    },
  },
});

export const { setLanguage } = slice.actions;

export default slice.reducer;

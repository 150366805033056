import { createSelector } from 'reselect';

import getEnabledPaymentMethods from './getEnabledPaymentMethods';
import getPaymentGatewayTokens from './getPaymentGatewayTokens';

import { PAYMENT_METHOD_UNAVAILABLE_REASON } from '../constants';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector(
  [getEnabledPaymentMethods, getPaymentGatewayTokens],
  (enabledPaymentMethods, pgTokens): PAYMENT_METHOD_UNAVAILABLE_REASON[] => {
    const reasons = [];

    if (!enabledPaymentMethods.includes(PAYMENT_METHOD.FREE)) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.NOT_ENABLED);
    }

    if (!pgTokens || !pgTokens.length) {
      reasons.push(PAYMENT_METHOD_UNAVAILABLE_REASON.NO_SAVED_CARDS);
    }

    return reasons;
  },
);

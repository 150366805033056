import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector } from '../app/hooks';

// this needs to be removed
const SnackbarRefSaver: React.FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const p = useAppSelector(getThemeLookup);

  useEffect(() => {
    window._enqueueSnackbar = enqueueSnackbar;
    window._closeSnackbar = closeSnackbar;
  });

  // this is probably one of the worst hacks I've done
  // setting a css property so that the notistack/Mui snackbar element can have a themed background colour
  const successColor = p('successSnackbar', ['backgroundColor']).backgroundColor;
  const warningColor = p('warningSnackbar', ['backgroundColor']).backgroundColor;
  const errorColor = p('errorSnackbar', ['backgroundColor']).backgroundColor;
  useEffect(() => {
    if (successColor) {
      document.documentElement.style.setProperty('--successSnackbarBgColor', successColor);
    }
    if (warningColor) {
      document.documentElement.style.setProperty('--warningSnackbarBgColor', warningColor);
    }
    if (errorColor) {
      document.documentElement.style.setProperty('--errorSnackbarBgColor', errorColor);
    }
  }, [successColor, warningColor, errorColor]);

  return null;
};
export default SnackbarRefSaver;

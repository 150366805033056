import { createSelector } from 'reselect';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

import { OrderingSelectors, OrderingConstants } from 'polygon-ordering';

const { getDeliveryProvider, getSaleType } = OrderingSelectors;

const { DELIVERY_PROVIDERS } = OrderingConstants;

const PROVIDERS_WITH_ADDITIONAL_STATUSES = [
  DELIVERY_PROVIDERS.DOORDASH_DRIVE,
  DELIVERY_PROVIDERS.DRIVE_YELLO,
  DELIVERY_PROVIDERS.UBER_DIRECT,
];

export default createSelector([getDeliveryProvider, getSaleType], (deliveryProvider, saleType) => {
  if (deliveryProvider) {
    return (
      saleType === SALE_TYPE.DELIVERY &&
      PROVIDERS_WITH_ADDITIONAL_STATUSES.includes(deliveryProvider.id)
    );
  }
});

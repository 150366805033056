export default function adjustChoiceSetMaxForQuantityDiscount(
  choiceSet: ValidatedChoiceSet,
  discountChoiceSetId: string,
  discountTierSize: number,
): ValidatedChoiceSet {
  if (!choiceSet.max) {
    return choiceSet;
  }

  if (choiceSet.name.toLowerCase() !== discountChoiceSetId.toLowerCase()) {
    return choiceSet;
  }

  const { max } = choiceSet;

  const newMax = max - Math.floor((max + 1) / (discountTierSize + 1));

  return {
    ...choiceSet,
    max: newMax,
  };
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSaleDetails } from '../reducers/currentOrder/saleDetails';
import { resetSaleUUID } from '../reducers/currentOrder/uuid';
import { getEnableDuplicateSaleProtection } from '../selectors/config';
import getOrderUUID from '../selectors/getOrderUUID';
import Api, { AuthenticationMethod, FetchParams } from '../utils/Api';
import processSale from '../utils/processors/processSale';

export const duplicateSaleProtection = createAsyncThunk(
  '$duplicateSaleProtection',
  async (
    data: {
      authenticationMethod?: AuthenticationMethod;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const { authenticationMethod } = data;

      const enableDuplicateSaleProtection = getEnableDuplicateSaleProtection(
        getState() as EntireFrontendState,
      );
      const uuid = getOrderUUID(getState() as EntireFrontendState);

      if (!enableDuplicateSaleProtection || !uuid) {
        return;
      }

      const checkParams: FetchParams = {
        path: {
          trusted: `/api/v1/auth/orderstatus/uuid/${uuid}`,
          member: `/api/v1/profile/orders?qf=uuid&qv=${uuid}`,
          none: `/api/v1/sale/order/uuid/${uuid}`,
        }[authenticationMethod ?? 'none'],
        method: 'GET',
      };

      const response = await Api.fetch(checkParams, authenticationMethod);

      let rawSale = response.data;

      if (rawSale?.StatusID === 225) {
        dispatch(resetSaleUUID({}));
        return;
      }

      if (Array.isArray(rawSale)) {
        if (rawSale.length === 0) {
          return {
            charged: false,
          };
        }
        rawSale = rawSale[0];
      }
      const processedSale = processSale(rawSale);
      dispatch(setSaleDetails(processedSale));
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { AuthenticationMethod, FetchParams } from '../utils/Api';
import getLocationId from '../selectors/getLocationId';
import getMember from '../selectors/getMember';
import lodash from 'lodash';
import { setMember } from './setMember';

export const fetchMember = createAsyncThunk(
  '$fetchMember',
  async (
    data: {
      memberNumber?: string;
      locationId?: string;
      authenticationMethod: AuthenticationMethod;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { memberNumber, locationId, authenticationMethod } = data;
    try {
      let locationIdSafe = locationId;
      if (authenticationMethod === 'trusted') {
        if (!locationIdSafe) {
          locationIdSafe = getLocationId(getState() as EntireFrontendState);

          if (!locationIdSafe) {
            throw new Error('no location id');
          }
        }
      }

      let memberNumberSafe = memberNumber;

      if (!memberNumberSafe && authenticationMethod !== 'member') {
        const member = getMember(getState() as EntireFrontendState);
        memberNumberSafe = lodash.get(member, 'memberNumber', undefined);

        if (!memberNumberSafe) {
          throw new Error('no member number');
        }
      }

      const params: FetchParams = {
        path:
          authenticationMethod === 'trusted'
            ? `/api/v1/pos-points?StoreID=${locationIdSafe}&Member=${encodeURIComponent(
                memberNumberSafe || '',
              )}`
            : '/api/v1/profile',
        method: 'GET',
      };

      const response = await Api.fetch(params, authenticationMethod);

      await dispatch(setMember({ rawMember: response.data }));
    } catch (e) {
      console.error('Fetch member failed.', e);
      return rejectWithValue(e);
    }
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAttempts } from '../selectors/config';
import getValidateBody from '../selectors/getValidateBody';
import { SALE_TYPE } from '../constants/saleType';
import { getLocation } from '../selectors';
import smoothPickupTime from '../utils/ordering/smoothPickupTime';
import lodash from 'lodash';
import Logger from '../utils/Logger';
import Api, { AuthenticationMethod, FetchParams } from '../utils/Api';
import { FAILURE_REASON } from '../constants';

export const validateOrder = createAsyncThunk(
  '$validateOrder',
  async (
    data: {
      authenticationMethod?: AuthenticationMethod;
    },
    { getState, rejectWithValue },
  ) => {
    const { authenticationMethod = 'trusted' } = data;
    let response;
    let valid = false;
    let reason;
    let errorText: string = '';
    try {
      const attemptsConfig = getAttempts(getState() as EntireFrontendState);
      let attemptsRemaining = attemptsConfig.validateOrder;

      const baseBody = getValidateBody(getState() as EntireFrontendState);

      const body: any = {
        ...baseBody,
      };

      if (body.SaleType === SALE_TYPE.TABLE_ORDER) {
        body.PickupTime = 'ASAP';
      }

      if (body.PickupTime != undefined) {
        const location = getLocation(getState() as EntireFrontendState);
        body.PickupTime = smoothPickupTime(
          lodash.get(baseBody, 'PickupTime'),
          location?.averageOrderWaitTime!,
        );
      }

      Logger.log('validating order:', undefined, JSON.stringify(body));
      Logger.log(JSON.stringify(body, null, 2));

      const params: FetchParams = {
        path: {
          trusted: '/api/v1/sale/validate_cart',
          member: '/api/v1/sale/validate_cart',
          none: '/api/v1/sale/validate_cart/nonmember',
        }[authenticationMethod],
        method: 'POST',
        body,
        applicationErrorAllowed: true,
      };

      while (attemptsRemaining) {
        attemptsRemaining -= 1;

        response = await Api.fetch(params, authenticationMethod);

        Logger.log('validation response:', undefined, response);
        valid = response.success;
        if (valid) {
          break;
        }
      }

      // process validation error
      if (!valid) {
        reason = FAILURE_REASON.UNKNOWN;
        errorText = lodash.get(response, 'error', '');

        let extraInfo = lodash.get(response, 'extra_info');
        //@ts-ignore
        extraInfo = Array.isArray(extraInfo) ? extraInfo : [];

        let firstValidationMsg;

        try {
          //@ts-ignore
          firstValidationMsg = extraInfo
            //@ts-ignore
            .map(
              (err?: { msg?: string }) =>
                lodash.get(err, 'msg', '').trim() || undefined,
            )
            .filter((msg?: string) => Boolean(msg))[0];
        } catch (e) {
          Logger.log('problem parsing extra info');
        }

        if (firstValidationMsg) {
          errorText += `: ${firstValidationMsg}`;
        }

        if (errorText.includes('uncontactable')) {
          reason = FAILURE_REASON.LOCATION_OFFLINE;
        } else if (
          errorText.includes('hours') ||
          errorText.includes('closed')
        ) {
          reason = FAILURE_REASON.LOCATION_CLOSED;
        }
      }

      return {
        valid,
        reason,
        errorText,
      };
    } catch (e) {
      console.error('Validate order failed', e);
      return rejectWithValue({
        e,
        reason:
          lodash.get(e, 'details.type', '') === 'connectivity'
            ? FAILURE_REASON.FETCH_FAILED
            : undefined,
        errorText,
      });
    }
  },
);

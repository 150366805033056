import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { processProfile } from '../utils/processors/processConfig';

const initialState = null as null | Profile;

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    storeProfile(state, action: PayloadAction<RawMember>) {
      return processProfile(action.payload) as Profile;
    },
    clearProfile(state, action) {
      return initialState;
    },
  },
});

export const { storeProfile, clearProfile } = slice.actions;

export default slice.reducer;

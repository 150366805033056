import { createAsyncThunk } from '@reduxjs/toolkit';
import { reserveBuffer } from '../reducers/buffer/bufferReservationId';
import getBufferReservationId from '../selectors/getBufferReservationId';

export const reserveOrCheckBuffer = createAsyncThunk(
  '$reserveOrCheckBuffer',
  async (
    data: {
      flowId: string;
      supressThrow?: boolean;
    },
    { dispatch, getState },
  ) => {
    const { flowId, supressThrow = false } = data;

    try {
      const bufferReservationId = getBufferReservationId(
        getState() as EntireFrontendState,
      );

      if (bufferReservationId == null) {
        dispatch(reserveBuffer(flowId));
        return true;
      } else if (bufferReservationId === flowId) {
        return true;
      } else if (supressThrow) {
        return false;
      } else {
        throw new Error('buffer already in use');
      }
    } catch (e) {
      console.log(e);
    }
  },
);

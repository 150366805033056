import React from 'react';
import SectionTitle from './SectionTitle';
import TableReviewLocation from './TableReviewLocation';
import { AiFillCloseCircle } from 'react-icons/ai';
import FormTextField from './FormTextField';
import TouchableOpacity from './TouchableOpacity';
import { useFormikContext } from 'formik';

const TableNumberReview = ({ location }: { location: MapLocation }) => {
  const { setFieldValue, handleChange } = useFormikContext();
  return (
    <div className="p-2 m-3">
      <TableReviewLocation location={location} />
      <SectionTitle value="TABLE NUMBER" />
      <div className="m-3">
        <FormTextField
          name="table"
          type="number"
          label="Table"
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <TouchableOpacity onClick={() => setFieldValue('table', '')}>
                <AiFillCloseCircle color="silver" size={20} />
              </TouchableOpacity>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default TableNumberReview;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import PaymentMethodProvider from './PaymentMethodProvider';
import { setSavedCardToken } from '../slices/savedCardToken';
import StandardButton from './StandardButton';
import getThemeLookup from '../selectors/getThemeLookup';
import { RiCloseCircleFill } from 'react-icons/ri';
import { OrderingOperations } from 'polygon-ordering';
import Text from './Text';
import { useFormikContext } from 'formik';
import { processPayment, process3ds } from '../thunks/processPayment';
import { loadStripe } from '@stripe/stripe-js';
import { removeSavedCard } from '../slices/savedCards';
import { setPaymentFormFilled } from '../slices/paymentFormFilled';
import { setPaymentFormInUse } from '../slices/paymentFormInUse';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import { useStripe, useElements, CardCvcElement } from '@stripe/react-stripe-js';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import { OrderingSelectors } from 'polygon-ordering';
import handleCVCChange from '../utils/handleCVCChange';

const { getCalculatedGiftcardSurcharge, getEnableStripeCvcRevalidation } = OrderingSelectors;

const { removePaymentGatewayToken, setCvcToken } = OrderingOperations;
function padCardDigits(last4: number, d = 'X'): string {
  const four = `${d}${d}${d}${d}`;
  return `${four}-${four}-${four}-${last4}`;
}
const SavedCardProvider = ({
  savedCard,
  route,
}: {
  savedCard: PaymentGateWayToken;
  route: string;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const paymentMethod = useAppSelector(state => state.paymentMethod);
  const savedCardToken = useAppSelector(state => state.savedCardToken);
  const p = useAppSelector(getThemeLookup);
  const paymentFormInUse = useAppSelector(state => state.paymentFormInUse);
  const hqGatewayKey = useAppSelector(state => state.config.hqGatewayKey);
  const merchantAccount = useAppSelector(state => state.config.stripeMerchantAccount);
  const stripe = useStripe();
  const elements = useElements();
  const paymentFormFilled = useAppSelector(state => state.paymentFormFilled);
  const savedCards = useAppSelector(state => state.savedCards);
  const enableCvcCheck = useAppSelector(getEnableStripeCvcRevalidation);

  const formik = useFormikContext<FormikFields>();
  const { values } = formik;

  const surcharge = useAppSelector(state =>
    getCalculatedGiftcardSurcharge(state, Number(values.Amount)),
  );

  const handleFormSubmit = () => {
    formik.setSubmitting(true);
    const data = {
      values: formik.values,
      token: null,
      route,
      giftcardSurcharge: surcharge.value,
    };
    dispatch(processPayment(data)).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        formik.resetForm();
      } else {
        if (response.payload.details.json.error_code === 206) {
          const { modal } = response.payload;
          const { additional_info } = response.payload.details.json;
          handle3ds(additional_info, modal); // 3ds required
        } else {
          enqueueErrorSnackbar(response.payload.message);
        }
      }
      formik.setSubmitting(false);
    });
  };

  const handle3ds = async (info: any, modal: string) => {
    try {
      const { orderInfo } = info;
      const stripe2 = await loadStripe(hqGatewayKey!, { stripeAccount: merchantAccount! });
      const result = await stripe2?.handleCardAction(info.action.client_secret);

      if (result) {
        const { paymentIntent, error } = result;

        if (error) {
          dispatch(setPaymentFormInUse(false));
          enqueueErrorSnackbar(error.message!);
          return;
        }

        await dispatch(
          process3ds({
            orderInfo,
            actionResponse: {
              payment_intent_id: paymentIntent?.id!,
            },
            modal,
          }),
        ).then((res: any) => {
          if (res.type === 'process3ds/fulfilled') {
            formik.resetForm();
          }
        });
      }
    } catch (err) {
      enqueueErrorSnackbar(err as Error);
    }
  };

  useEffect(() => {
    if (
      paymentFormInUse &&
      paymentMethod?.method === PAYMENT_METHOD.SAVED_CARD &&
      savedCardToken === savedCard.token
    ) {
      handleFormSubmit();
    }
  }, [paymentFormInUse]);

  if (!stripe || !elements) {
    return null;
  }

  return (
    <PaymentMethodProvider
      method={PAYMENT_METHOD.SAVED_CARD}
      selected={() => {
        if (
          paymentMethod?.method === PAYMENT_METHOD.SAVED_CARD &&
          savedCardToken === savedCard.token
        ) {
          return true;
        } else {
          return false;
        }
      }}
      additionalBehaviour={() => {
        dispatch(setSavedCardToken(savedCard.token));
      }}
      subtitle={
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '-0.75em',
            }}
          >
            <StandardButton
              invisibleContainer
              label={t('button.removeSavedCard')}
              themeKey="removeSavedCardButton"
              LeftIconComponent={RiCloseCircleFill}
              iconStyle={p('removeSavedCardButtonIcon', ['color', 'fontSize'])}
              onClick={() => {
                if (window.confirm(`Remove saved card ending in ${savedCard.displayValue}?`)) {
                  dispatch(removePaymentGatewayToken({ token: savedCard.token }))
                    .unwrap()
                    .then(() => logEvent(EVENTS.REMOVE_SAVED_CARD));

                  dispatch(removeSavedCard(savedCard));
                  if (savedCard.token === savedCardToken) {
                    dispatch(setSavedCardToken(null));
                    dispatch(setPaymentFormFilled(false));
                  }
                }
              }}
            />
          </div>
          {enableCvcCheck &&
            paymentMethod?.method === PAYMENT_METHOD.SAVED_CARD &&
            savedCardToken === savedCard.token && (
              <div style={styles.contentContainer}>
                <div style={styles.input}>
                  <CardCvcElement
                    options={{
                      style: {
                        base: {
                          backgroundColor: 'transparent',
                        },
                      },
                    }}
                    onChange={event => {
                      if (event.complete) {
                        handleCVCChange({
                          stripe,
                          elements,
                          CardCvcElement,
                          onSuccess: token => dispatch(setCvcToken(token)),
                        });
                        dispatch(setPaymentFormFilled(true));
                      } else {
                        if (paymentFormFilled) {
                          dispatch(setPaymentFormFilled(false));
                        }
                        dispatch(setCvcToken(null));
                      }
                    }}
                  />
                </div>
              </div>
            )}
        </div>
      }
      right={
        <Text themeKey="savedCardNumber">{padCardDigits(Number(savedCard.displayValue))}</Text>
      }
    />
  );
};

export default SavedCardProvider;

const styles = {
  contentContainer: {
    minHeight: 57,
  },
  input: {
    borderRadius: 4,
    border: '1px solid #c4c4c4',
    padding: '10px 12px',
  },
};

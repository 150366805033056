// This is the default theme.

// Many components can be themed even further than they are here.
// Inspect the styles and themeKeys of a component to see what is possible.

// Some components have a style hierarchy.
// E.g. defaultText > sectionTitle > cartCount

// Anything not specified (undefined) using the higher priority themeKey will fallback
// to the value provided by one of the lower priority themeKeys.

const DEFAULT_FONT_FAMILY = 'Roboto';

const GREEN = '#1D9B06';

const TRANSPARENT = 'transparent';
const BLACK = '#000000';
const WHITE = '#FFFFFF';
const OFF_BLACK = '#1B1B1B';
const OFF_WHITE = '#fbfbfb';
const SUCCESS = '#259B36';
const DANGER = '#C21213';
const WARNING = '#ffc107';
const DARK = '#343a40';
const MUTED = '#6c757d';
const GREY = '#E6E6E6';
const LIGHTESTGREY = '#EBEBEB';
const LIGHTGREY = '#DFDFDF';
const LINK = '#007bff';
const OUTLINE_VARIANT = '#737373';
const RED = '#C21213';
/**
 * Overridable Constants
 *
 * - Literally behaves like constants, but they can be
 * swapped out in theme overrides
 * - These can be overidden on polygon central too via
 * theming or by runtimeConfig and devThemeOverrides.
 *
 * > Note:
 * Component theme keys (i.e: dropDownInputError) will
 * override these constants
 *
 * Concept 8 has an example on how to set these constants.
 */

// temporary
const c = {
  color: {
    primary: RED,
    secondary: RED,

    onPrimary: WHITE,

    success: SUCCESS,
    danger: DANGER,
    warning: WARNING,

    onSurface: BLACK,
    outline: LIGHTGREY,
    outlineVariant: OUTLINE_VARIANT,

    surface: WHITE,
    surfaceVariant: LIGHTESTGREY,
  },

  type: {
    heading: {
      size: 22,
      weight: 500,
    },
    subHeading: {
      size: 20,
      weight: 500,
    },
    subHeading2: {
      size: 18,
      weight: 500,
    },
    subHeading3: {
      size: 14,
      weight: 600,
    },
    body: {
      size: 16,
      weight: 400,
    },
  },
};

const DefaultThemes = {
  color: {
    headerHamburgerButton: WHITE,
    completedOrderMessage: BLACK,
    barcodeMemberNumber: BLACK,
    closeIconColor: BLACK,
    fieldIcons: 0,
    textColor: BLACK,
    labelColor: 'rgba(0,0,0,0.6)', //focused or not
    formLabel: 'rgba(0,0,0,0.6)',
    fieldBorderFocus: 'blue',
    fieldDefaultBorder: 'silver',
    fieldHoverBorderColor: 'blue',
    fieldBackground: TRANSPARENT,
    helperColor: RED,
    helperBackground: TRANSPARENT,
    labelBackgroundColor: TRANSPARENT,
    materialTextFieldErrorBorder: RED,
    materialDatePickerPlaceholderColor: 'rgba(0,0,0,0.6)',
    caretColor: BLACK,
    checkboxLabelColor: BLACK,
    defaultCheckboxColor: BLACK,
    checkboxCheckedColor: RED,
    checkboxUncheckedColor: 'rgba(0,0,0,0.1)',
    filterTitle: BLACK,
    dateFilterTitle: BLACK,
    filterIconColors: BLACK,
    loyaltyHeaderInfo: BLACK,
    loyaltySubTitle: BLACK,
    loyaltyHeader: BLACK,
    loyaltyBalanceText: BLACK,
    loyaltyBalanceDefault: BLACK,
    loyaltyWalletTitle: BLACK,
    memberMoney: BLACK,
    memberPointsTitle: BLACK,
    balanceAmountMemberModal: BLACK,
    loyaltyGreeting: BLACK,
    loyaltyRing: 'indianred',
    giftCardValueButton: 'rgb(120,120,120)',
    badgeOptionDefault: BLACK,
    badgeOptionActive: WHITE,
    activeHeartIcon: 'indianred',
    resetFilters: 'grey',
    paginationActive: WHITE,
    paginationDefault: BLACK,
    loyaltySaleType: 'indianred',
    loyaltyUserDetails: 'indianred',
    balanceAmountCheckout: WHITE,
    balanceIconCheckout: WHITE,
    balanceIconMemberModal: BLACK,
    balanceLabelCheckout: WHITE,
    balanceLabelMemberModal: BLACK,
    banner: WHITE,
    cartCount: GREEN,
    categoryBarCurrentIndicator: WHITE,
    menuBrandsBarCurrentIndicator: WHITE,
    locationBrandBarCurrentIndicator: GREEN,
    categoryBarLabel: WHITE,
    menuBrandsBarLabel: WHITE,
    locationBrandBarLabel: BLACK,
    checkoutButton: WHITE,
    checkoutSignInPrompt: WHITE,
    checkoutSignInPromptIcon: WHITE,
    checkoutSignInPromptReason: WHITE,
    choiceSetCheckbox: WHITE,
    completedOrderTitle: GREEN,
    defaultButton: WHITE,
    defaultText: BLACK,
    deliveryDetailAddress: 'rgba(0,0,0,0.8)',
    deliveryDetailTime: 'rgba(0,0,0,0.8)',
    deliveryDetailTimeETA: GREEN,
    deliveryEstimateDetail: 'rgba(0,0,0,0.7)',
    deliveryTrackingLink: GREEN,
    desiredDeliveryAddressNotFoundIcon: 'orange',
    emptyCartMessage: 'rgba(0,0,0,0.85)',
    giftCardApplyButton: WHITE,
    giftCardApplyButtonIcon: GREEN,
    headerBrandingName: WHITE,
    headerCartIcon: OFF_WHITE,
    headerQuantity: WHITE,
    locationDetail: 'rgba(0,0,0,0.8)',
    locationDetailIcon: GREEN,
    lookupPromoCodeButton: WHITE,
    memberBalancesTitleCheckout: WHITE,
    menuCategoryHeadingCount: 'rgba(0,0,0,0.5)',
    menuItemDescription: 'rgba(0,0,0,0.7)',
    menuItemEnergySuffix: 'rgba(0,0,0,0.6)',
    menuItemSizeDescription: 'rgba(0,0,0,0.6)',
    menuSearchResultCount: 'rgba(0,0,0,0.6)',
    minimumOrderAmount: GREEN,
    modalButtonIcon: BLACK,
    modalButtonLabel: BLACK,
    modalFloatingTitle: WHITE,
    modalTitle: BLACK,
    noGuestOrderingLink: GREEN,
    offerInfoButton: GREEN,
    orderMessageIcon: 'orange',
    orderPropertyButton: BLACK,
    orderPropertyButtonIcon: GREEN,
    orderTotalDeemphasised: 'rgba(0,0,0,0.6)',
    paymentMethodIcon: GREEN,
    purchaseEditorDescription: 'rgba(0,0,0,0.7)',
    purchaseEditorEnergySuffix: 'rgba(0,0,0,0.6)',
    purchaseEditorEnergy: 'rgba(0,0,0,0.6)',
    quantityPickerIcon: '#00ff00',
    removeOfferButton: WHITE,
    removeSavedCardButton: 'rgba(0,0,0,0.8)',
    removeSavedCardButtonIcon: GREEN,
    saveCardIcon: GREEN,
    saveCardLabel: 'rgba(0,0,0,0.8)',
    savedCardNumber: 'rgba(0,0,0,0.6)',
    paymentMethodAvailableBalance: 'rgba(0,0,0,0.6)',
    scrollForMoreIndicator: WHITE,
    sectionTitle: BLACK,
    selectedTagButton: WHITE,
    signInLink: BLACK,
    stagedPurchaseButton: 'rgba(0,0,0,0.9)',
    stagedPurchaseButtonIcon: GREEN,
    stagedPurchaseMultiplicationSymbol: GREEN,
    stagedPurchaseSubLine: 'rgba(0,0,0,0.8)',
    tagButton: BLACK,
    purchaserInvalidIcon: 'orange',
    locationMapMarker: '#E22C29',
    locationMapMarkerSelected: '#18a702',
    timeInErrorMessage: 'orange',
    deliveryDetailEditIcon: GREEN,
    menuLocationDetailsName: BLACK,
    menuLocationDetailsMobile: WHITE,
    fastOrderButton: WHITE,
    appLoadingSpinner: WHITE,
    fallbackToPickupButton: BLACK,
    fraudPreventionMessageIcon: 'orange',
    // orderStatusStepIcon: BLACK,
    // orderStatusStepIconCompleted: GREEN,
    noKnownUserLocationMessage: BLACK,
    deliveryTimeMayVaryMessage: '#FFCC00',
    memberButton: WHITE,
    memberLoyaltyButtons: BLACK,
    signOutButton: BLACK,
    activeMemberButton: BLACK,
    activeSubButton: BLACK,
    activeMemberButtonLoyaltySideBar: 'indianred',
    activeSubRoute: 'indianred',
    defaultDropdownMenuButton: BLACK,
    defaultSubButton: BLACK,
    defaultMemberButtonLoyaltySideBar: BLACK,
    defaultSubRoute: BLACK,
    sliderIcon: BLACK,
    filterText: BLACK,
    orderOnlineButton: 'red',
    loyaltyMigrationMenuButton: BLACK,
    createNewFavourite: 'white',
    cartItemError: '#E22C29',
    cartItemErrorAlert: '#E22C29',
    cartItemWarning: '#ff9800',
    cartItemWarningAlert: '#ff9800',
    STOCK_BALANCE_THRESHOLD_0: '#E22C29',
    STOCK_BALANCE_THRESHOLD_1: '#ff9800',
    STOCK_BALANCE_THRESHOLD_2: BLACK,
    STOCK_BALANCE_THRESHOLD_3: BLACK,
    STOCK_BALANCE_THRESHOLD_4: BLACK,
    //olo3
    saleTypeIcons: RED,
    pickSaleTypeDescription: RED,
    pastOrdersButton: BLACK,
    viewCartButton: WHITE,
    warningMessage: 'silver',
    transparentButton: RED,
    messageModalBorderButton: RED,
    bannerLeft: BLACK,
    bannerRight: BLACK,
    underageButton: RED,
    orderStatusStepIcon: 'lightgrey',
    orderStatusStepCompleted: WHITE,
    viewOrderDetailsButton: '#C21213',
    addOrderToFavourites: '#C21213',
    viewOrderDetailsTitle: '#C21213',
    viewOrderInvoiceButton: '#C21213',
    joinNowLoyaltyButton: '#C21213',
    cancelMenuSearchButton: 'rgba(0,0,0,0.75)',
    menuLocationDetailsNameMobile: 'white',
    menuLocationDetailsSaleTypeMobile: 'white',
    sectionTitleHeader: WHITE,
    modalSectionTitle: 'silver',
    clearAllButton: 'grey',
    unavailablePurchaseButton: RED,
    cvcHelper: '#6c757d',
    check: '#CF2634',
    bannerLabelIcon: RED,
    customTagWarning: WHITE,

    automaticOfferText: RED,

    // new account screen stuff
    accountCardName: RED,
    stampcardProgressCounter: RED,

    accountBalanceAmount: RED,
    accountBalanceAmount_cr: RED,

    migrationFormLoginText: RED,

    progress: c.color.surfaceVariant,
    progressBar: c.color.primary,
    ratingFilled: c.color.primary,
    ratingUnFilled: c.color.surfaceVariant,
    starLabel: c.color.outlineVariant,

    choiceChecked: c.color.onPrimary,
    loyaltyOrderDate: c.color.outlineVariant,
    headerButton: c.color.outlineVariant,
    textButton: c.color.primary,
    outlineRoundButton: c.color.primary,
  },
  backgroundColor: {
    // these three theme keys cost me 6 hours
    successSnackbar: '#43a047',
    warningSnackbar: '#ff9800',
    errorSnackbar: '#d32f2f',

    menuLocationDetails: WHITE,
    orderOnlineButton: TRANSPARENT,
    loyaltyMigrationMenuButton: TRANSPARENT,
    createNewFavourite: 'red',
    loyaltyMobileBackground: 'white',
    loyaltyFilterModals: 'white',
    loyaltyModals: 'white',
    loyaltyBackground: 'whitesmoke',
    giftCardValueButton: TRANSPARENT,
    badgeOptionDefault: WHITE,
    badgeOptionActive: BLACK,
    orderOnline: 'rgb(195,21,22)',
    paginationActive: BLACK,
    paginationDefault: TRANSPARENT,
    app: WHITE,
    appLoadingOverlay: 'rgba(0,0,0,0.75)',
    input: WHITE,
    balancesSeparatorCheckout: WHITE,
    balancesSeparatorMemberModal: BLACK,
    banner: OFF_BLACK,
    bannerWithProblem: '#bdbdbd',
    cancelMenuSearchButton: TRANSPARENT,
    fallbackToPickupButton: TRANSPARENT,
    // checkoutButton: GREEN,
    choiceSetCheckbox: '#CF2634',
    // check: '#CF2634',
    defaultButton: GREEN, // '#1D1D1D',
    // deliveryTimeSelect: WHITE,
    // giftCardApplyButton: TRANSPARENT,
    headerBarFloating: 'rgba(0,0,0,0.8)',
    headerBarHero: OFF_BLACK,
    headerQuantity: 'green',
    initialScreenVerticalSeparator: BLACK,
    // locationListElement: WHITE,
    locationListElementSelected: 'rgba(22, 151, 2, 0.1)',
    locationSearchBar: '#F8F8F8',
    // lookupPromoCodeButton: GREEN,
    menuCategoriesBar: GREEN,
    menuBrandsBar: '#CF2634', //'#2C4A8E', // TODO: fix
    // modalDesktop: WHITE,
    // modalMobile: WHITE,
    menuSearchBar: WHITE, // GREEN?
    modalButtonIcon: WHITE,
    modalOverlay: 'rgba(0,0,0,0.5)',
    modalOverlayCallout: 'rgba(0,0,0,0.3)',
    modalProceedButton: '#CF2634',
    // newOrderButton: GREEN,
    // orderPickupTimeSelect: WHITE,
    orderTotalsSeperator: 'rgba(0,0,0,0.05)',
    removeOfferButton: '#CF2634',
    screen: WHITE,
    scrollForMoreIndicator: '#28dd08',
    selectedTagButton: GREEN,
    // signInSubmitButton: 'green',
    signOutButton: TRANSPARENT,
    tagButton: TRANSPARENT,
    reviewOrderScreenDesktopSideOuter: 'rgba(0,0,0,0.6)',
    menuItem: WHITE,
    fastOrderButton: OFF_BLACK,
    locationBrandBar: WHITE,
    noKnownUserLocationMessage: '#ffc107',
    menuItemOpacityBackstop: 'white',
    cartItemErrorAlert: '#E22C29',
    cartItemWarningAlert: '#ff9800',
    memberButton: TRANSPARENT,
    memberLoyaltyButtons: TRANSPARENT,
    activeMemberButton: TRANSPARENT,
    activeSubButton: TRANSPARENT,
    defaultDropdownMenuButton: TRANSPARENT,
    defaultSubButton: TRANSPARENT,
    activeMemberButtonLoyaltySideBar: TRANSPARENT,
    defaultMemberButtonLoyaltySideBar: TRANSPARENT,
    defaultSubRoute: TRANSPARENT,
    activeSubRoute: TRANSPARENT,
    loyaltyCheckoutButton: 'indianred',
    quantityPickerIcon: '#CF2634',
    quantityPickerDisabled: '#bdbdbd',

    //olo3
    pastOrdersButton: TRANSPARENT,
    transparentButton: TRANSPARENT,
    messageModalBorderButton: TRANSPARENT,
    choiceCheckbox: '#C4C4C4',
    underageButton: TRANSPARENT,
    orderStatusStepCompleted: '#C21213',
    addOrderToFavourites: TRANSPARENT,
    viewOrderDetailsButton: TRANSPARENT,
    viewOrderInvoiceButton: TRANSPARENT,
    orderStatusStepIcon: '#C21213',
    orderStatusStepsConnector: '#C21213',
    joinNowLoyaltyButton: TRANSPARENT,
    purchaseEditorBottomSection: WHITE,

    tagWarning: RED,
    customTagWarning: OFF_BLACK,

    accountCardContainer: WHITE,
    defaultStamp: LIGHTGREY,
    defaultStampFulfilled: RED,
    defaultStampRewardIndicator: '#ffc107',
    stampImageContainer: LIGHTGREY,
    stampImageContainerFulfilled: RED,

    creditLoyaltyProgressDefault: LIGHTGREY,
    creditLoyaltyProgress: RED,
    itemChoiceActive: '#CF2634',
    outlineRoundButton: TRANSPARENT,
  },
  borderColor: {
    defaultBorder: '#dfdfdf',
  },
  fontFamily: {
    defaultText: DEFAULT_FONT_FAMILY,
    signInLink: DEFAULT_FONT_FAMILY, // TODO: rework this to use Text component
  },
  fontSize: {
    headerHamburgerButton: 24,
    subFeedback: c.type.subHeading2.size,
    checkoutTitle: 22,
    completedOrderMessage: 22,
    barcodeMemberNumber: 14,
    giftCardValueButton: 15,
    badgeOptionDefault: 15,
    badgeOptionActive: 15,
    loyaltySubTitle: 15,
    orderOnline: 15,
    loyaltyHeaderInfo: 13,
    loyaltyDefaultText: 11,
    filterSaleTypes: 11,
    dateFilterTitle: 11,
    loyaltySubHeaders: 11,
    filterTitle: 22,
    resetFilters: 10,
    loyaltyOrderTotal: 16,
    loyaltyOrderItems: 16,
    loyaltyStoreLocation: 18,
    loyaltySaleType: 18,
    loyaltyOrderDate: 16,
    loyaltyHeader: 22,
    loyaltyUserDetails: 14,
    defaultBarcode: 14,
    loyaltyBalanceDefault: 35,
    loyaltyRingView: 60,
    loyaltyGreeting: 20,
    appLoadingSpinner: 150,
    balanceAmountCheckout: 20,
    balanceAmountMemberModal: 20,
    balanceIconCheckout: 45,
    balanceIconMemberModal: 45,
    balanceLabelCheckout: 13,
    balanceLabelMemberModal: 13,
    checkoutButton: 15,
    checkoutSignInPrompt: 20,
    checkoutSignInPromptIcon: 40,
    choiceSetCheckbox: 20,
    choiceSetCheckboxIcon: 30,
    completedOrderDetail: 30,
    completedOrderTitle: 22,
    completedOrderHeading: c.type.subHeading2.size,
    defaultButton: 16,
    dynamicHeaderMemberButton: '3.5vw',
    defaultText: 16,
    deliveryDetailAddress: 15,
    deliveryDetailTime: 17,
    deliveryDetailTimeETA: 17,
    deliveryTimeSelect: 15,
    deliveryTrackingLink: 22,
    editDeliveryEstimateButton: 15,
    emptyCartMessage: 15,
    giftCardApplyButton: 15,
    headerBackButton: 23,
    headerBackButtonIcon: 30,
    headerBrandingNameDesktop: 25,
    headerBrandingNameMobile: 15,
    headerCartIcon: 30,
    dynamicCartIcon: '5vw',
    menuSearchIcon: 24,
    locationDetail: 12,
    locationDetailIcon: 14,
    locationListElementAddress: 13,
    locationListElementDetail: 13,
    locationListElementDistance: 12,
    locationSearchResultCount: 10,
    memberBalancesTitleCheckout: 20,
    menuCategoryHeading: 25,
    menuCategoryHeadingCount: 25,
    menuItemSizeDescription: 12,
    menuSearchResultCount: 13,
    modalButtonIcon: 30,
    modalButtonLabel: 16,
    modalFloatingTitle: 20,
    modalProceedButton: 15,
    modalTitle: 30,
    newOrderButton: 15,
    noResultsInMenuSearchMessage: 20,
    orderMessage: 13,
    orderMessageIcon: 15,
    orderOfferButton: 16,
    orderPickupTimeSelect: 15,
    orderTableNumber: c.type.subHeading2.size,
    orderTotal: 14,
    orderTotalDeemphasised: 12,
    paymentMethod: 16,
    paymentMethodIcon: 22,
    paymentMethodUnavailableReason: 10,
    purchaseEditorEnergy: 16,
    quantityPicker: 20,
    quantityPickerIcon: 25,
    removeSavedCardButton: 12,
    cvcHelper: 12,
    saveCardLabel: 14,
    savedCardNumber: 10,
    paymentMethodAvailableBalance: 10,
    sectionTitle: 22,
    signInLink: 16,
    stagedPurchase: 16,
    stagedPurchaseButton: 11,
    stagedPurchaseButtonIcon: 15,
    stagedPurchaseSubLine: 13,
    tagButton: 15,
    timeInErrorMessage: 13,
    deliveryDetailEdit: 13,
    deliveryDetailEditIcon: 16,
    // giftCardApplyButtonIcon: c.type.subHeading2.size,
    menuLocationDetailsName: 10,
    locationBrandBarLabel: 15,
    fallbackToPickupButton: 19,
    fraudPreventionMessageIcon: 40,
    // orderStatusStepIcon: c.type.subHeading2.size,
    noKnownUserLocationMessageIcon: c.type.subHeading2.size,
    memberLoyaltyButtons: 16,
    signOutButton: 14,
    activeSubButton: 12,
    activeMemberButton: 14,
    orderOnlineButton: 14,
    loyaltyMigrationMenuButton: 14,
    defaultDropdownMenuButton: 14,
    defaultSubButton: 12,
    activeMemberButtonLoyaltySideBar: 14,
    activeSubRoute: 12,
    defaultMemberButtonLoyaltySideBar: 14,
    defaultSubRoute: 12,

    //olo3
    saleTypeIcons: 19,
    pickSaleTypeDescription: 13,
    pastOrdersButton: 14,
    modalHeader: c.type.subHeading2.size,
    shortAddress: 12,
    viewCartButton: 14,
    menuLocationDetailsSaleType: 12,
    menuLocationDetailsSaleTypeMobile: '0.8em',
    noGuestOrderingLink: 14,
    warningMessage: 12,
    transparentButton: 16,
    underageButton: 14,
    addOrderToFavourites: c.type.subHeading2.size,
    viewOrderDetailsButton: 17,
    joinLoyaltyTitle: 20,
    sectionTitleHeader: 22,
    modalSectionTitle: 14,
    modalSubSection: 16,
    clearAllButton: 12,
    menuFilterTitle: 15,
    menuItemPrice: 13,
    removeFiltersWarnings: 13,
    viewOrderDetailsLocation: 14,
    viewOrderDetailsTitle: 14,
    orderDetailsCart: 12,
    viewOrderInvoiceButton: 13,

    automaticOfferText: 11,

    // new accounts page keys
    accountCardName: 18,
    stampcardProgressCounter: 16,

    accountBalanceAmount: 40,
    // cr for credit loyalty
    accountBalanceAmount_cr: 36,

    migrationFormButton: 18,
    migrationFormLoginPretext: 14,
    migrationFormLoginText: 14,
  },
  fontWeight: {
    transparentButton: '500',
    subFeedback: c.type.subHeading2.weight,
    completedOrderMessage: '500',
    barcodeMemberNumber: '500',
    badgeOptionDefault: '400',
    badgeOptionActive: '500',
    loyaltySubTitle: '500',
    dateFilterTitle: '500',
    loyaltySubHeaders: '500',
    filterTitle: '500',

    loyaltySaleType: '600',
    loyaltyStoreLocation: '600',
    loyaltyOrderDate: '400',
    loyaltyOrderTotal: '600',

    loyaltyHeader: '500',
    loyaltyUserDetails: '500',
    loyaltyWalletTitle: '500',
    loyaltyBalanceDefault: '500',
    loyaltyGreeting: '500',
    activeSubRoute: '500',
    activeSubButton: '500',
    activeMemberButtonLoyaltySideBar: '500',
    defaultMemberButtonLoyaltySideBar: '500',
    defaultSubRoute: '500',
    activeMemberButton: '500',
    defaultDropdownMenuButton: '400',
    orderOnlineButton: '500',
    loyaltyMigrationMenuButton: '500',
    defaultSubButton: '400',
    signOutButton: '400',
    balanceAmountMemberModal: '500',
    banner: '500',
    cartCount: '700',
    categoryBarLabel: '500',
    menuBrandsBarLabel: '500',
    menuBrandsBarLabelCurrent: '700',
    locationBrandBarLabel: '400',
    checkoutSignInPrompt: '500',
    choiceSetCheckbox: '500',
    completedOrderDetail: '700',
    completedOrderTitle: '500',
    completedOrderHeading: '700',
    defaultButton: '500',
    deliveryDetailTime: '500',
    deliveryDetailTimeETA: '500',
    deliveryTimeSelect: '500',
    deliveryTrackingLink: '700',
    // giftCardApplyButton: '400',
    headerBackButton: '500',
    headerMemberButton: '500',
    headerPickupTimeSelect: '500',
    headerTableNumber: '500',
    locationListElementAddress: '300',
    menuItemEnergySuffix: '500',
    menuItemName: '500',
    menuItemPrice: 'bold',
    minimumOrderAmount: '700',
    modalButtonLabel: '500',
    modalFloatingTitle: '500',
    modalProceedButton: '500',
    modalTitle: '500',
    newOrderButton: '500',
    noGuestOrderingLink: '700',
    offerName: '500',
    orderMessage: '500',
    orderPickupTimeSelect: '500',
    orderPropertyButton: '500',
    orderTableNumber: '500',
    orderTotal: '700',
    orderTotalDeemphasised: '500',
    purchaseEditorEnergySuffix: '500',
    purchaseEditorName: '500',
    removeSavedCardButton: '400',
    sectionTitle: '500',
    stagedPurchase: '500',
    stagedPurchaseButton: '400',
    stagedPurchaseMultiplicationSymbol: '700',
    tagButton: '400',
    deliveryDetailEdit: '500',
    // menuLocationDetailsName: '700',
    STOCK_BALANCE_THRESHOLD_0: '500',
    STOCK_BALANCE_THRESHOLD_1: '500',
    STOCK_BALANCE_THRESHOLD_2: '500',
    STOCK_BALANCE_THRESHOLD_3: '500',
    STOCK_BALANCE_THRESHOLD_4: '500',

    //olo3
    pickSaleType: '500',
    pickSaleTypeDescription: '500',
    pastOrdersButton: '500',
    modalHeader: '600',
    viewCartButton: '500',
    menuLocationDetailsSaleType: '500',
    orderStatusStepIcon: '600',
    viewOrderDetailsTitle: 'bold',
    viewOrderDetailsLocation: 'bold',
    addOrderToFavourites: '500',
    joinLoyaltyTitle: '500',
    sectionTitleHeader: '500',
    modalSectionTitle: 'bold',
    //modalSubSection: 'bold',
    menuFilterTitle: '500',

    // stampcards / account page
    accountCardName: '500',
    stampcardProgressCounter: '700',
    accountBalanceAmount: '700',
    accountBalanceAmount_cr: '700',
  },
  letterSpacing: {
    menuItemEnergySuffix: 1,
    purchaseEditorEnergySuffix: 1,
    savedCardNumber: 1,
    stagedPurchaseButton: 0.25,
  },
  opacity: {
    giftCardValueButton: 1,
    badgeOptionDefault: 1,
    badgeOptionActive: 1,
    orderOnline: 1,
    categoryBarLabel: 0.75,
    categoryBarLabelCurrent: 1,
    locationSearchResultCount: 0.9,
    memberLoyaltyButtons: 1,
    activeSubButton: 1,
    activeMemberButton: 1,
    activeSubRoute: 1,
    activeMemberButtonLoyaltySideBar: 1,
    defaultDropdownMenuButton: 1,
    orderOnlineButton: 1,
    defaultSubButton: 1,
    defaultMemberButtonLoyaltySideBar: 1,
    defaultSubRoute: 1,
    createNewFavourite: 1,
    unavailablePurchaseText: 0.65,

    stampImageUnfulfilled: 0.65,
  },
  padding: {
    badgeOptionDefault: '5px 5px',
    badgeOptionActive: '5px 5px',
    banner: '0px 20px',
    cancelMenuSearchButton: '0 10px',
    defaultButton: 15,
    deliveryTimeSelect: '5px',
    editDeliveryEstimateButton: 10,
    giftCardApplyButton: '11px 20px 11px 20px',
    headerBackButton: 0,
    headerBar: '10px 15px',
    headerCartIcon: '5px 12px',
    headerMemberButton: 10,
    headerPickupTimeSelect: '3px 10px',
    headerTableNumber: '7px 0px',
    memberOffers: 13,
    newOrderButton: '0.925em 1.25em',
    modalProceedButton: '0.925em 1.25em',
    orderPickupTimeSelect: '5px',
    orderTableNumber: 10,
    removeSavedCardButton: '5px 0',
    stagedPurchaseButton: 0,
    tagButton: '9px 11px',
    continueButton: 5,
    quantityPickerIcon: 5,

    // olo3
    addOrderToFavourites: 'none',
    viewOrderDetailsButton: 'none',
    viewOrderInvoiceButton: 'none',
  },
  paddingLeft: {
    //activeMemberButton: '25px',
    //defaultDropdownMenuButton: '25px',
    //orderOnlineButton: '25px',
    purchaseEditorEnergySuffix: 2,
  },
  gap: {
    headerBar: 10,
  },
  borderRadius: {
    giftCardValueButton: 5,
    badgeOptionDefault: 20,
    badgeOptionActive: 20,
    choiceSetCheckbox: 0,
    defaultButton: 2,
    deliveryTimeSelect: 5,
    modalProceedButton: 5,
    orderPickupTimeSelect: 5,
    tagButton: 20,
    giftCardApplyButton: 5,
    orderOnline: 5,
    quantityPickerIcon: '50%',

    accountCardContainer: 20,
    defaultStamp: 20,

    stampImageContainer: 75,

    // olo3
    messageModalButton: 5,
    messageModalBorderButton: 5,
    modalButtonIcon: '50%',
    outlineRoundButton: 25,
  },
  border: {
    loyaltyFilterBorder: `1px solid ${c.color.outlineVariant}`,
    outlineRoundButton: `1px solid ${c.color.primary}`,
    cardBorder: `1px solid ${c.color.outline}`,
    giftCardValueButton: '1px solid silver',
    banner: '1px solid rgba(255, 255, 255, 0.1)',
    defaultButton: 'none',
    deliveryTimeSelect: '1px solid rgba(0, 0, 0, 0.1)',
    //modalProceedButton: '1px solid rgba(0, 0, 0, 0.1)',
    orderPickupTimeSelect: '1px solid rgba(0, 0, 0, 0.1)',
    orderTableNumber: '1px solid rgba(0, 0, 0, 0.35)',
    scrollForMoreIndicator: '1px solid rgba(0, 0, 0, 0.1)',
    selectedTagButton: '1px solid transparent',
    tagButton: '1px solid rgba(0, 0, 0, 0.1)',

    //olo3
    messageModalBorderButton: '2px solid red',
    joinNowLoyaltyButton: '2px solid red',
    defaultBorder: '1px solid #dfdfdf',
    selected: '1px solid #CF2634',
  },
  borderLeft: {
    defaultBorder: '1px solid #dfdfdf',
    activeMemberButton: '5px solid indianred',
  },
  borderTop: {
    defaultBorder: '1px solid #dfdfdf',
  },
  borderRight: {
    defaultBorder: '1px solid #dfdfdf',
  },
  borderBottom: {
    defaultBorder: '1px solid #dfdfdf',
  },
  outline: {
    menuItemHovered: '1.5px solid black',
  },
  boxShadow: {
    giftCardValueButton: 'none',
    badgeOptionDefault: 'none',
    badgeOptionActive: 'none',
    // https://tailwindcss.com/docs/box-shadow#outer-shadow
    cancelMenuSearchButton: 'none',
    fallbackToPickupButton: 'none',
    completedOrderScreenDesktop:
      '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    //defaultButton: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    // giftCardApplyButton: 'none',
    //.headerBar: '0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.09)',
    initialScreenDesktop: 'rgba(55, 71, 79, 0.35) 0 6px 40px -6px',
    menuCategoriesBar: 'rgba(0, 0, 0, 0.1) 0px 4px 16px -1px, rgba(0, 0, 0, 0.09) 0px 2px 2px -1px',
    modalCallout:
      '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    reviewOrderScreenDesktop: '6px 6px 20px -6px rgba(0,0,0,0.75)',
    scrollForMoreIndicator: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    tagButton: 'none',
    memberLoyaltyButtons: 'none',
    signOutButton: 'none',
    activeMemberButton: 'none',
    activeSubButton: 'none',
    defaultDropdownMenuButton: 'none',
    orderOnlineButton: 'none',
    loyaltyMigrationMenuButton: 'none',
    defaultSubButton: 'none',
    activeMemberButtonLoyaltySideBar: 'none',
    defaultMemberButtonLoyaltySideBar: 'none',
    defaultSubRoute: 'none',
    activeSubRoute: 'none',

    //olo3
    pastOrdersButton: 'none',
    transparentButton: 'none',
    messageModalBorderButton: 'none',
    addOrderToFavourites: 'none',
    viewOrderDetailsButton: 'none',
    viewOrderInvoiceButton: 'none',

    // stampcard
    accountCardContainer: '0 4px 4px 0 #00000040',
  },
  textDecoration: {
    deliveryTrackingLink: 'none',
    activeMemberButtonLoyaltySideBar: 'underline',
    activeSubRoute: 'underline',
  },
  maxHeight: {
    headerBrandingImage: '100%',
  },
  width: {
    stampImageContainer: 55,
  },
  height: {
    purchaseEditorItemImage: 160,
    stampImageContainer: 55,
    migrationFormButton: 50,
  },
  textAlign: {
    defaultBarcode: 'right',
    menuLocationDetailsSaleTypeMobile: 'center',
    messageModalMessage: 'center',
  },
  aspectRatio: {
    menuItemImage: '6/5',
  },
};

export default DefaultThemes;

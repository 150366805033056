import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearBuffer } from '../../actions/buffer';

const initialState = null as LocationDeliveryEstimate[] | null;

const slice = createSlice({
  name: '$bufferLocationDeliveryEstimate',
  initialState,
  reducers: {
    setBufferLocationDeliveryEstimates(
      state,
      action: PayloadAction<LocationDeliveryEstimate[]>,
    ) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(clearBuffer, (state, action) => {
      return initialState;
    });
  },
});

export const { setBufferLocationDeliveryEstimates } = slice.actions;
export default slice.reducer;

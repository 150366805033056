import { createSelector } from 'reselect';
import { RootState } from 'store';

const getFallbackLocation = (state: RootState) => state.config.fallbackLocation;
const getCurrentUserLocation = (state: RootState) => state.currentUserLocation;

export default createSelector(
  [getFallbackLocation, getCurrentUserLocation],
  (fallbackLocation, current) => {
    return current || fallbackLocation;
  },
);

import React from 'react';

import combineStyles from '../utils/combineStyles';

import getThemeLookup from '../selectors/getThemeLookup';

import TouchableOpacity from './TouchableOpacity';
import Text from './Text';
import { useAppSelector } from '../app/hooks';
import { IconType } from 'react-icons/lib';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

interface IProps {
  label?: string;
  onClick: () => void;
  disabled?: boolean;
  ariaLabel?: string;
  IconComponent: IconType;
  buttonStyle?: React.CSSProperties;
}

const ModalFloatingButton: React.FC<IProps> = ({
  onClick,
  IconComponent,
  disabled,
  buttonStyle,
}) => {
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);
  const p = useAppSelector(getThemeLookup);
  return (
    <TouchableOpacity
      style={combineStyles(styles.mainContainer, isMobileDevice && { transform: `scale(0.8)` })}
      disabledStyle={styles.disabled}
      onClick={onClick}
      disabled={disabled}
      className="modalFloatingButton"
    >
      {Boolean(IconComponent) && (
        <IconComponent
          style={combineStyles(
            p('modalButtonIcon', ['color', 'fontSize', 'backgroundColor', 'borderRadius']),
            buttonStyle,
          )}
        />
      )}
    </TouchableOpacity>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 100000,
    pointerEvents: 'auto',
  },
  label: {
    marginTop: 7,
  },
  disabled: {
    opacity: 0, // I'm not sure what looks better / is less confusing
  },
};

export default ModalFloatingButton;

// import { logEventToConsole } from 'polygon-utils';
import { RedcatApiHandler } from 'polygon-utils';
import { makeErrorSerialisable } from './sagas';
import { ApiResponse } from '../utils/Api';

type LogLevel = 'warning' | 'info' | 'error' | 'upstream';

type ActionLabel =
  | 'FECTH_MENU'
  | 'BEFORE_PAYMENT'
  | 'AFTER_PAYMENT'
  | 'SUBMIT_SALE'
  | 'SALE_RESPONSE';

type paramsPacket = {
  Endpoint: string;
  SentBody: string | null;
  ResponseCode: string | number | null;
  ResponseBody: ApiResponse | string | null;
  ResponseTime: string | Date | number | null;
  AdditionalInfo?: string;
};

type StreamDataPacket = {
  DeviceID: string;
  StoreID: string;
  Action: ActionLabel;
  DateTime: string | Date | number;
  Data: paramsPacket;
};

interface LogEvent {
  message: string | StreamDataPacket;
  level: LogLevel;
  details?: {};
}

// TODO: work out circular dependency that makes the "logEventToConsole"
// come out as `undefined`
function logEventToConsole(event: LogEvent) {
  const { message, details, level } = event;

  const safeDetails = details || '';

  if (level === 'error') {
    console.error(message, safeDetails);
  } else if (level === 'warning') {
    console.warn(message, safeDetails);
  } else {
    console.info(message, safeDetails);
  }
}
export default class Logger {
  static handler?: any;

  static setHandler(handler: any) {
    Logger.handler = handler;
  }

  static log(message: string, level: LogLevel = 'info', details?: {}) {
    const serialisableDetails = details
      ? makeErrorSerialisable(details as Error)
      : undefined;

    if (Logger.handler) {
      Logger.handler.log(message, level, serialisableDetails);
    } else {
      logEventToConsole({ message, level, details: serialisableDetails });
    }
  }

  static logUpstream(
    message: StreamDataPacket,
    level: LogLevel = 'upstream',
    details?: {},
  ) {
    // When running in the browser we don't need to push logs to PC. Only when we run this in the shell
    if (Logger.handler) {
      const body: StreamDataPacket = message;
      RedcatApiHandler.fetch({
        method: 'POST',
        path: '/api/v1/kiosk/logging',
        body: body,
      }).then(res => {
        console.log(res);
        console.log(res.code);
      });

      // Logger.handler.log(message, level);
    } else {
      logEventToConsole({ message, level });
    }
  }
}

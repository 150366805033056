import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Text from './Text';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setLoyaltyDateFilters } from '../slices/loyaltyDateFilters';
import { useTranslation } from 'react-i18next';

const DateFilters: React.FC = () => {
  const orderDate = useAppSelector(state => state.loyaltyDateFilters);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <div>
      <Text value={t('title.loyaltyDateString')} themeKey="dateFilterTitle" />

      <DatePicker
        className="w-100"
        selected={orderDate}
        dateFormat="dd/MM/yyyy"
        placeholderText={moment(new Date()).format('DD/MM/YYYY')}
        onChange={(date: Date) => dispatch(setLoyaltyDateFilters(date))}
      />
    </div>
  );
};

export default DateFilters;

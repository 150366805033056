import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';
import { finaliseSale, sale } from '../../operations';

const initialState: SubPayment[] = [];

const slice = createSlice({
  name: '$selectedPaymentMethods',
  initialState,
  reducers: {
    setSelectedPaymentMethods(state, action: PayloadAction<SubPayment[]>) {
      return action.payload;
    },
    addSelectedPaymentMethod(state, action: PayloadAction<SubPayment>) {
      return [...state, action.payload];
    },
    removeSelectedPaymentMethod(state, action: PayloadAction<PAYMENT_METHOD>) {
      const newState = state.filter(subPayment => {
        return subPayment.method !== action.payload;
      });

      return newState;
    },
    updateSelectedPaymentMethod(state, action: PayloadAction<SubPayment>) {
      return state
        .filter(subPayment => subPayment.method !== action.payload.method)
        .concat(action.payload);
    },

    resetSelectedPaymentMethods(state, action) {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addMatcher(isAnyOf(sale.fulfilled, finaliseSale.fulfilled), () => []);
  },
});

export const {
  setSelectedPaymentMethods,
  addSelectedPaymentMethod,
  removeSelectedPaymentMethod,
  updateSelectedPaymentMethod,
  resetSelectedPaymentMethods,
} = slice.actions;
export default slice.reducer;

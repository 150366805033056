import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: '$apiHandlerReady',
  initialState,
  reducers: {
    setApiHandlerReady(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setApiHandlerReady } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'stampcardConfig',
  initialState: {
    enabled: false,
    stampsPerCard: null,
    stampDefaultImage: null,
    stampRewardImageMap: {},
    redeemText: null,
  } as StampcardConfig,
  reducers: {
    setStampcardConfig(state, action: PayloadAction<StampcardConfig>) {
      return action.payload;
    },
  },
});

export const { setStampcardConfig } = slice.actions;

export default slice.reducer;

import { removeUndefinedKeys } from '../../utils/misc';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSaleStatus } from '../../operations';
import { setSaleDetails, setSaleStatus } from './saleDetails';

const initialState = null as Sale | null;

const slice = createSlice({
  name: '$orderInProgress',
  initialState,
  reducers: {
    resetOrderInProgress(state, action) {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSaleStatus.fulfilled, (state, action) => {
        const { saleDetails } = action.payload;
        return state
          ? { ...state, ...removeUndefinedKeys(saleDetails) }
          : saleDetails || initialState;
      })
      .addCase(setSaleDetails, (state, action: PayloadAction<Sale>) => {
        return action.payload;
      })
      .addCase(setSaleStatus, (state, action: PayloadAction<number>) => {
        return {
          ...state,
          status: action.payload,
        };
      });
  },
});

export const { resetOrderInProgress } = slice.actions;
export default slice.reducer;

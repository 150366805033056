import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { GoogleMap, Marker } from '@react-google-maps/api';
import MapWrapper from './MapWrapper';
import CurrentLocationMarker from './CurrentLocationMarker';
import getUsingFallbackLocation from '../selectors/getUsingFallbackLocation';
import { logEvent } from '../utils/analytics';
import EVENTS from '../constants/events';
import getUserLocation from '../selectors/getUserLocation';
import { setMapCenter } from '../slices/mapCenter';
import { setNearbyLocations } from '../slices/nearbyLocations';
import { getNearbyLocality, getNearbyStreetName } from '../utils/maps';

const Map = ({
  mapDivStyle,
  mapContainerStyle,
  showNearbyLocations = false,
  markerPosition,
}: {
  mapContainerStyle: React.CSSProperties;
  mapDivStyle: React.CSSProperties;
  showNearbyLocations?: boolean;
  markerPosition?: null | {
    lat: number;
    lng: number;
  };
}) => {
  const dispatch = useAppDispatch();
  const mapCenterChanged = useAppSelector(state => state.mapCenter);
  const userCoords = useAppSelector(getUserLocation);
  const [tilesLoaded, setTilesLoaded] = useState(false);
  const usingFallbackLocation = useAppSelector(getUsingFallbackLocation);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const [center, setCenter] = useState({
    lat: markerPosition ? markerPosition.lat : userCoords.latitude,
    lng: markerPosition ? markerPosition.lng : userCoords.longitude,
  });

  const onCenterChanged = React.useCallback(function callback() {
    if (map) {
      setCenter({
        lat: map.getCenter()?.lat()!,
        lng: map.getCenter()?.lng()!,
      });
    }
  }, []);

  useEffect(() => {
    if (map && showNearbyLocations && userCoords && !usingFallbackLocation) {
      getNearby();
    }
  }, [map]);

  const getNearby = async () => {
    var nearbyLocations = await new google.maps.Geocoder().geocode({
      location: {
        lat: userCoords.latitude,
        lng: userCoords.longitude,
      },
    });

    if (nearbyLocations.results) {
      const addresses = nearbyLocations.results.slice(0, 2).map(location => {
        return {
          formatted_address: location.formatted_address,
          street_name: getNearbyStreetName(location),
          locality: getNearbyLocality(location),
          lat: location.geometry.location.lat(),
          lng: location.geometry.location.lng(),
        };
      });

      dispatch(setNearbyLocations(addresses));
    }
  };

  //map center updated
  useEffect(() => {
    if (mapCenterChanged) {
      updateMapCenter(mapCenterChanged?.latitude, mapCenterChanged?.longitude);
    }
  }, [mapCenterChanged]);

  const updateMapCenter = (lat: number, lng: number) => {
    if (map) {
      map.panTo({
        lat,
        lng,
      });
      dispatch(setMapCenter(null));
    }
  };

  return (
    <div style={mapDivStyle}>
      <MapWrapper>
        <GoogleMap
          onLoad={onLoad}
          onTilesLoaded={() => setTilesLoaded(true)}
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={10}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            zoomControlOptions: {
              position: window.google?.maps?.ControlPosition.TOP_RIGHT,
            },
          }}
          onDragEnd={() => logEvent(EVENTS.PAN_MAP)}
          onZoomChanged={() => logEvent(EVENTS.CHANGE_MAP_ZOOM)}
          onCenterChanged={onCenterChanged}
        >
          {!usingFallbackLocation && tilesLoaded && <CurrentLocationMarker />}
          {markerPosition && <Marker position={markerPosition} />}
        </GoogleMap>
      </MapWrapper>
    </div>
  );
};

export default React.memo(Map);

import { createSelector } from 'reselect';
import lodash from 'lodash';

import getOrdering from './getOrdering';
import getLocations from './getLocations';

export default createSelector(
  [getOrdering, getLocations],
  (ordering, locations): EnhancedHistoricalOrder[] | undefined => {
    if (!ordering.historicalOrders) {
      return undefined;
    }

    const orders = ordering.historicalOrders.map(order => {
      const location = lodash.get(locations, order.locationId);

      const locationName = lodash.get(location, 'name');

      return {
        ...order,
        locationName: locationName || 'Unknown Location',
      };
    });

    return lodash.orderBy(orders, 'id', ['desc']);
  },
);

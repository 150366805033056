import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as number | null;

const slice = createSlice({
  name: 'orderDate',
  initialState,
  reducers: {
    setOrderDate(state, action: PayloadAction<number | null>) {
      const { payload } = action;

      return payload == null ? initialState : payload;
    },
  },
});

export const { setOrderDate } = slice.actions;

export default slice.reducer;

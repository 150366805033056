import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = '';

const slice = createSlice({
  name: '$deviceId',
  initialState,
  reducers: {
    setDeviceId(state, action: PayloadAction<string>) {
      return action.payload;
    },
  },
});

export const { setDeviceId } = slice.actions;
export default slice.reducer;

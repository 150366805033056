import { createSelector } from 'reselect';
import lodash from 'lodash';

// utils
import { fieldConfig } from '../utils/fields';
import { RootState } from 'store';

const _getProfileFields = (state: RootState) => state.config.profileFields;

export default createSelector([_getProfileFields], profileFields => {
  if (profileFields) {
    const fields: CombinedFields[] = profileFields?.map(field => {
      return {
        ...field,
        ...fieldConfig[field.id],
        description:
          (field.description || '').trim() ||
          (lodash.get(fieldConfig, field.id, {}) as ConfigFields).description,
      };
    });

    return fields;
  }
});

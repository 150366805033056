import { createAsyncThunk } from '@reduxjs/toolkit';
import getItems from '../selectors/getItems';
import getUnenhancedOpenPurchase from '../selectors/getUnenhancedOpenPurchase';
import changePurchaseItem from '../utils/ordering/changePurchaseItem';

// change the size of a composite item to the one specified by "sizeId"
export const changeOpenPurchaseItem = createAsyncThunk(
  '$changeOpenPurchaseItem',
  async (
    data: {
      sizeId: string;
      choiceSelections?: ChoiceSelections | NestedChoiceSelections;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { sizeId, choiceSelections } = data;
      const unenhancedOpenPurchase = getUnenhancedOpenPurchase(
        getState() as EntireFrontendState,
      );
      if (!unenhancedOpenPurchase) return;
      const openPurchase = {
        ...unenhancedOpenPurchase,
        choiceSelections,
      };
      const allItems = getItems(getState() as EntireFrontendState);

      if (!allItems || !openPurchase) return;

      // check if the item is composite (this may be redundant but may help a hypothetical future dev)
      const rootItem = allItems[openPurchase.itemId];
      if (!rootItem.sizes.length) {
        return rejectWithValue(
          `Item ${rootItem.id} is not composite (and hence cannot change varieties).`,
        );
      }

      const oldSizeItem = openPurchase.sizeId
        ? rootItem.sizes.find(s => s.id === openPurchase.sizeId)
        : undefined;
      // this should never really happen
      if (!oldSizeItem) {
        return rejectWithValue(
          `Composite item does not contain size ${openPurchase.sizeId}.`,
        );
      }

      // get new size item
      const newSizeItem = rootItem.sizes.find(s => s.id === sizeId);
      if (!newSizeItem) {
        return rejectWithValue(
          `Composite item does not contain size ${sizeId}.`,
        );
      }

      const adjustedPurchase = changePurchaseItem(
        openPurchase,
        oldSizeItem,
        newSizeItem,
      );

      return {
        purchase: adjustedPurchase,
      };
    } catch (e) {
      console.error('Change open purchase item failed', { e });
      return rejectWithValue(e);
    }
  },
);

export const trueOrUndefined = (value: boolean | undefined) =>
  value || value === undefined;

// TODO: move to utils
export const makeErrorSerialisable = (e: Error): SerialisableException => ({
  ...e,
  message: e.message,
  stack: e.stack,
  name: e.name,
});

export function mapBooleanToNumber(v: boolean) {
  return v ? 1 : 0;
}

export function blankOrString(s: string, blank: boolean) {
  return blank ? '' : s;
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { FetchParams } from '../utils/Api';

export const fetchInvoice = createAsyncThunk(
  '$fetchInvoice',
  async (
    data: {
      invoiceID: string;
    },
    { rejectWithValue },
  ) => {
    const { invoiceID } = data;
    try {
      const params: FetchParams = {
        path: `/api/v1/profile/orders/invoice/${invoiceID}`,
        method: 'GET',
      };

      return await Api.fetch(params, 'member');
    } catch (e) {
      console.warn('Fetch invoice failed', { e });
      return rejectWithValue(e);
    }
  },
);

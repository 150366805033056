import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';
import { resetOrder } from '../../actions/currentOrder';

const initialState = null as MinimumOrderInformation | null;

const slice = createSlice({
  name: '$minimumOrderInformation',
  initialState,
  reducers: {
    setMinimumOrderInformation(
      state,
      action: PayloadAction<MinimumOrderInformation | null>,
    ) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addCase(applyBufferData, (state, action) => {
        const { minimumOrderInformation } = action.payload;
        return minimumOrderInformation === undefined
          ? state
          : minimumOrderInformation;
      });
  },
});

export const { setMinimumOrderInformation } = slice.actions;
export default slice.reducer;

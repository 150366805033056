import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../operations';

const initialState = false;

const slice = createSlice({
  name: '$ageProofSubmitted',
  initialState,
  reducers: {
    setAgeProofSubmitted(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      const { preserveMember } = action.payload;
      return preserveMember ? state : initialState;
    });
  },
});

export const { setAgeProofSubmitted } = slice.actions;

export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = [] as GoogleMapsLocation[];

const slice = createSlice({
  name: 'recentLocations',
  initialState,
  reducers: {
    addToRecentLocations(state, action: PayloadAction<GoogleMapsLocation>) {
      return [...state, action.payload];
    },
  },
});

export const { addToRecentLocations } = slice.actions;

export default slice.reducer;

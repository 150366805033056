import identicalPurchase from './identicalPurchase';

export default function sameAndIdenticalPurchase(
  purchaseA: _Purchase,
  purchaseB: _Purchase,
): boolean {
  return (
    purchaseA.id === purchaseB.id && identicalPurchase(purchaseA, purchaseB)
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import getUnenhancedStagedPurchases from '../selectors/getUnenhancedStagedPurchases';

export const openStagedPurchase = createAsyncThunk(
  '$openStagedPurchase',
  async (
    data: {
      purchaseId: string;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { purchaseId } = data;

      const stagedPurchases = getUnenhancedStagedPurchases(
        getState() as EntireFrontendState,
      );
      const purchase = stagedPurchases.find(p => p.id === purchaseId);

      return { purchase };
    } catch (e) {
      console.error('Open staged purchase failed', { e });
      return rejectWithValue(e);
    }
  },
);

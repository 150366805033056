import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { Drawer as MaterialDrawer } from '@material-ui/core';
import { setCurrentDrawer } from '../slices/currentDrawer';
import { makeStyles } from '@material-ui/core';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import CloseIcon from '../components/CloseIcon';
import combineStyles from '../utils/combineStyles';

const Drawer = ({
  position,
  children,
  open,
  size,
  closeButtonPosition = 'right',
}: {
  position: 'left' | 'right';
  children: React.ReactNode;
  open: boolean;
  size?: string;
  closeButtonPosition?: 'left' | 'right';
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  const useStyles = makeStyles({
    paper: {
      width: deviceTypeMobile ? '100%' : size ? `${size}px` : '280px',
      backgroundColor: 'whitesmoke',
    },
  });

  const classes = useStyles();

  return (
    <MaterialDrawer
      anchor={position}
      open={open}
      onClose={() => dispatch(setCurrentDrawer(null))}
      classes={{ paper: classes.paper }}
    >
      <div style={{ width: '100%' }}>
        <div
          style={combineStyles(
            closeButtonPosition === 'right' ? styles.rightCloseButton : styles.leftClose,
            {
              position: 'absolute',
            },
          )}
        >
          <CloseIcon onClick={() => dispatch(setCurrentDrawer(null))} />
        </div>
        <div style={{ paddingTop: 30 }}>{children}</div>
      </div>
    </MaterialDrawer>
  );
};

export default Drawer;

const styles: Styles = {
  leftClose: {
    left: 10,
  },
  rightCloseButton: {
    right: 15,
    top: 5,
  },
};

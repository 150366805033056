import React from 'react';
import { Redirect } from 'react-router-dom';

interface IProps {
  to: string;
}

export default ({ to, ...additionalProps }: IProps) => (
  <Redirect
    {...additionalProps}
    to={{
      pathname: to,
      search: new URL(window.location.href).search,
    }}
  />
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'enableFacebookLogin',
  initialState: true,
  reducers: {
    setEnableFacebookLogin: (_, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { setEnableFacebookLogin } = slice.actions;
export default slice.reducer;

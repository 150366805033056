import { createAsyncThunk } from '@reduxjs/toolkit';
import { $getLocationId } from '../selectors/getLocationId';
import Api, { FetchParams } from '../utils/Api';
import { setStockBalances } from '../reducers/currentOrder/stockBalances';

export const fetchStockBalances = createAsyncThunk(
  '$fetchStockBalances',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    try {
      const locationId = $getLocationId(getState() as EntireFrontendState);

      if (locationId == null) {
        throw new Error('No location id, skipping fetch stock balance');
      }
      const params: FetchParams = {
        path: `/api/v1/stores/${locationId}/pluqty`,
        method: 'GET',
      };

      const response = await Api.fetch(params);
      dispatch(setStockBalances(response.data.StockBalances as StockBalance[]));
    } catch (e) {
      console.warn('Fetch stock balances failed', e);
      return rejectWithValue(e);
    }
  },
);

import React from 'react';
import { useAppSelector } from '../app/hooks';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;
const PastOrderItem = ({ item, order }: { item: SaleItem; order: EnhancedHistoricalOrder }) => {
  const deviceType = useAppSelector(state => state.deviceType);
  const price = useFormattedCurrency({ cents: item.moneyPrice });
  return (
    <tr>
      <td
        className="fw-bold border-0 p-0 pt-2"
        style={{ fontSize: 11, width: deviceType === 1 ? '40%' : '80%' }}
      >
        {item.name}
      </td>
      {deviceType !== 2 && (
        <>
          <td className="border-0 p-0 text-center pt-2" style={{ fontSize: 11, width: '20%' }}>
            {item.quantity}
          </td>
          {!!order.pointsAward && (
            <td className="border-0 p-0 pt-2" style={{ fontSize: 11, width: '20%' }}>
              {item.redeemPoints ? item.redeemPoints / 10 : 0}
            </td>
          )}
        </>
      )}

      <td className="border-0 p-0 pt-2" style={{ fontSize: 11, width: '20%', textAlign: 'right' }}>
        {price}
      </td>
    </tr>
  );
};

export default PastOrderItem;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProductConfig, getSelectedPaymentMethods } from '../selectors';
import { getEftposConfig, getPaymentEnvironment } from '../selectors/config';
import PaymentHooks from '../utils/PaymentHooks';
import { EFTPOS_STATUS_UPDATE, FAILURE_REASON } from '../constants';
import Logger from '../utils/Logger';
// @ts-ignore
import { RedcatPaymentHandler } from 'polygon-payments';
import delay from '../utils/misc';
import { sale } from './sale';
import { updateSelectedPaymentMethod } from '../reducers/currentOrder/selectedPaymentMethods';
import parseDojoReceipt from '../utils/processors/processDojoReceipt';
import { PAYMENT_METHOD } from '../constants/paymentMethod';
import { setEftposTxnInProgress } from '../reducers/currentOrder/eftposTxnInProgress';
import i18next from 'i18next';
import getCurrentOrder from '../selectors/getCurrentOrder';
import moment from 'moment';

export const dojoPayments = createAsyncThunk(
  '$dojoPayments',
  async (
    data: {
      amount: number;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    let reason: FAILURE_REASON | undefined;

    try {
      const { amount } = data;
      const { vendor, name, version } = getProductConfig(
        getState() as EntireFrontendState,
      );
      const currentSelectedPayment = getSelectedPaymentMethods(
        getState() as EntireFrontendState,
      );
      const currentLocationId = getCurrentOrder(
        getState() as EntireFrontendState,
      ).locationId;

      const {
        terminalId,
        paymentProvider,
        paymentEnvironment,
        hostAddress,
        apiKey,
        eposIdentifier,
        deviceId,
      } = getEftposConfig(getState() as EntireFrontendState);

      // This condition should never be reached
      if (
        !vendor ||
        !name ||
        !version ||
        !terminalId ||
        !apiKey ||
        !hostAddress
      ) {
        reason = FAILURE_REASON.MISSING_EFTPOS_CONFIG;
        throw new Error('missing necessary config');
      }

      // Step 1: Initalise the Payment Handler with config
      const redcatPayment = new RedcatPaymentHandler();

      redcatPayment.init({
        provider: paymentProvider!,
        config: {
          hostAddress: hostAddress,
          paymentEnvironment: paymentEnvironment,
          apiKey: apiKey,
          terminalId: terminalId,
          eposIdentifier: eposIdentifier,
        },
        interfaceDir: './',
        debugLogs: true,
      });

      const timeStartInitPayment = moment();

      // Step 2: Inquire and check the connection the terminal
      const checkEftposConnection = await redcatPayment.inquire();

      Logger.logUpstream({
        DeviceID: deviceId as string,
        StoreID: currentLocationId!,
        Action: 'BEFORE_PAYMENT',
        DateTime: new Date().toLocaleString('en', {
          timeZone: 'Australia/Sydney',
        }),
        Data: {
          Endpoint: hostAddress,
          SentBody: JSON.stringify({
            paymentProvider: paymentProvider!,
            hostAddress: hostAddress,
            paymentEnvironment: paymentEnvironment,
            apiKey: apiKey,
            terminalId: terminalId,
            eposIdentifier: eposIdentifier,
          }),
          ResponseCode: checkEftposConnection && 200,
          ResponseBody: checkEftposConnection,
          ResponseTime: null,
        },
      });

      //wait for the response to come back using yield
      const activeTerminal: any = await checkEftposConnection;

      // when terminal is unreachable / or offline??
      if (activeTerminal?.messages?.error || activeTerminal?.userMessage) {
        // terminalUnavailableHook({ dojoResponse: activeTerminal });
        reason = FAILURE_REASON.PAYMENT_TIME_OUT;
      }

      if (activeTerminal && activeTerminal.status === 'AVAILABLE') {
        const getTransInfo = await redcatPayment.initatePayment(amount);

        const txnEndpoint =
          activeTerminal.endpoint +
          `/pac/terminals/${terminalId}/transactions/` +
          getTransInfo.requestId;
        const hook: any = PaymentHooks.get(EFTPOS_STATUS_UPDATE);

        hook({ statusMsg: 'Starting transaction...', txnStarted: true });

        const timeEndInitPayment = moment();
        const dojoInitResponseTime =
          timeEndInitPayment.diff(timeStartInitPayment);

        Logger.logUpstream({
          DeviceID: deviceId as string,
          StoreID: currentLocationId!,
          Action: 'BEFORE_PAYMENT',
          DateTime: new Date().toLocaleString('en', {
            timeZone: 'Australia/Sydney',
          }),
          Data: {
            Endpoint: txnEndpoint,
            SentBody: null,
            ResponseCode: getTransInfo && 200,
            ResponseBody: getTransInfo,
            ResponseTime: dojoInitResponseTime,
          },
        });

        let cardTapped = false;
        let byPassInternetDropOut = 0;
        const dojoTxnPollStart = moment();

        const checkTxnRecurse = async (): Promise<
          FAILURE_REASON | undefined | void
        > => {
          PaymentHooks.subscribe('CANCEL_TRANSACTION', data => {
            if (data.cancelled) {
              Logger.log(
                '===> #### Cancel Transaction Clicked ###',
                'info',
                reason,
              );

              redcatPayment.cancelTransaction(txnEndpoint);
              reason = FAILURE_REASON.PAYMENT_CANCELLED;
            }
          });

          const txnData: any = await redcatPayment.checkTransaction(
            txnEndpoint,
          );

          if (txnData === undefined) {
            if (byPassInternetDropOut < 10) {
              byPassInternetDropOut += 1;
            } else {
              dispatch(setEftposTxnInProgress(txnEndpoint));
              Logger.log(
                '===> #### Retry Logic - Internet Drop Out ###',
                'info',
                txnData,
              );
              Logger.log(JSON.stringify(txnData), 'info');
              reason = FAILURE_REASON.FETCH_FAILED;
            }
          }

          const status: string = txnData?.notifications[0] as string;
          const enchancedStatus = i18next.t(`dojoNotification.${status}`);

          // Transaction cannot be cancelled until the card is tapped
          if (status === 'PLEASE_WAIT' || status === 'REMOVE_CARD') {
            cardTapped = true;
          }

          if (cardTapped) {
            hook({ statusMsg: enchancedStatus, txnStarted: true });
          } else {
            hook({ statusMsg: enchancedStatus, txnStarted: false });
          }

          if (txnData?.cardholderVerificationMethod === 'SIGNATURE') {
            await redcatPayment.signatureVerification(
              false,
              txnEndpoint + '/signature',
            );
          }

          if (txnData?.transactionResult === 'SUCCESSFUL') {
            // console.log({ txnData });
            const subPayments = getSelectedPaymentMethods(
              getState() as EntireFrontendState,
            );
            let subPayment = subPayments.filter(
              subPayment => subPayment.method === PAYMENT_METHOD.EFTPOS,
            )[0];
            subPayment = {
              ...subPayment,
              receiptText: parseDojoReceipt(txnData?.receiptLines?.CUSTOMER),
              cardType: txnData?.cardSchemeName,
              referenceNumber: txnData?.transactionId,
              authorisationCode: txnData?.authCode,
            };

            const latestSubPayments = getSelectedPaymentMethods(
              getState() as EntireFrontendState,
            );
            Logger.log('===> #### DOJO SUCCESSFUL ###', 'info', txnData);
            Logger.log(
              '===> Latest SubPayments',
              'info',
              JSON.stringify(latestSubPayments),
            );
            Logger.log(JSON.stringify(txnData), 'info');
            Logger.log(
              '===> #### Payment Info ###',
              'info',
              currentSelectedPayment,
            );
            Logger.log(JSON.stringify(currentSelectedPayment), 'info');

            const dojoTxnPollEnd = moment();
            const dojoPollResponseTime = dojoTxnPollEnd.diff(dojoTxnPollStart);

            Logger.logUpstream({
              DeviceID: deviceId as string,
              StoreID: currentLocationId!,
              Action: 'AFTER_PAYMENT',
              DateTime: new Date().toLocaleString('en', {
                timeZone: 'Australia/Sydney',
              }),
              Data: {
                Endpoint: txnEndpoint,
                SentBody: null,
                ResponseCode: txnData && 200,
                ResponseBody: txnData,
                ResponseTime: dojoPollResponseTime,
              },
            });

            dispatch(updateSelectedPaymentMethod(subPayment));
            // Step 5: Return a successful flow when payment is processed
            dispatch(
              sale({ route: 'checkout', authenticationMethod: 'trusted' }),
            );
            return;
          } else if (txnData?.transactionResult === 'DECLINED') {
            reason = FAILURE_REASON.PAYMENT_DECLINED;
            Logger.log('===> #### PAYMENT_DECLINED ###', 'info', reason);
            Logger.log(
              '===> #### Dojo Data ###',
              'info',
              JSON.stringify(txnData),
            );
          } else if (txnData?.transactionResult === 'UNSUCCESSFUL') {
            reason = FAILURE_REASON.PAYMENT_UNSUCCESSFUL;
            Logger.log('===> #### PAYMENT_UNSUCCESSFUL ###', 'info', reason);
            Logger.log(
              '===> #### Dojo Data ###',
              'info',
              JSON.stringify(txnData),
            );
          } else if (txnData?.transactionResult === 'TIMED_OUT') {
            reason = FAILURE_REASON.PAYMENT_TIME_OUT;
            Logger.log('===> #### PAYMENT_TIME_OUT ###', 'info', reason);
            Logger.log(
              '===> #### Dojo Data ###',
              'info',
              JSON.stringify(txnData),
            );
          } else if (
            txnData?.transactionResult === 'VOID' &&
            txnData.notifications.includes(
              'SIGNATURE_VERIFICATION_PROCESS_COULD_NOT_BE_COMPLETED',
            )
          ) {
            reason = FAILURE_REASON.SIGNATURE_REQUIRED;
            Logger.log('===> #### SIGNATURE_REQUIRED ###', 'info', reason);
            Logger.log(
              '===> #### Dojo Data ###',
              'info',
              JSON.stringify(txnData),
            );
          } else {
            if (activeTerminal && activeTerminal.status === 'BUSY') {
              reason = FAILURE_REASON.PAYMENT_TIME_OUT;
              Logger.log(
                '===> #### TRANSACTION STILL GOING ON ###',
                'info',
                reason,
              );
              Logger.log(
                '===> #### Dojo Data ###',
                'info',
                JSON.stringify(txnData),
              );
              await redcatPayment.cancelTransaction(txnEndpoint!);
            }
          }

          if (reason) {
            Logger.log('-----  ----- Failed Payment ----- ----', 'info');
            Logger.log(JSON.stringify(txnData), 'info');
            Logger.log('----- ----- ----- ----- ----- -----', 'info');
            const dojoTxnPoolEnd = moment();
            const dojoPoolResponseTime = dojoTxnPoolEnd.diff(dojoTxnPollStart);

            Logger.logUpstream({
              DeviceID: deviceId as string,
              StoreID: currentLocationId!,
              Action: 'AFTER_PAYMENT',
              DateTime: new Date().toLocaleString('en', {
                timeZone: 'Australia/Sydney',
              }),
              Data: {
                Endpoint: txnEndpoint,
                SentBody: null,
                ResponseCode: txnData && 200,
                ResponseBody: txnData,
                ResponseTime: dojoPoolResponseTime,
              },
            });

            return reason;
          }

          await delay(500);
          return await checkTxnRecurse();
        };

        const getFailureReason = await checkTxnRecurse();

        if (getFailureReason) {
          throw new Error(getFailureReason);
        }
      }
    } catch (e) {
      console.warn('Dojo payment failed', { e });
      Logger.log(`Error ===> Dojo payment failed ${e}`);
      return rejectWithValue({
        e,
        reason: reason ? reason : (e as any).message,
      });
    }
  },
);

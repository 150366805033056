import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { applyBarcode } from '../../thunks/applyBarcode';
import { resetOrder } from '../../actions/currentOrder';

const initialState = false;

const slice = createSlice({
  name: '$applyBarcodeInProgress',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(applyBarcode.pending, () => true)
      .addCase(resetOrder, () => initialState)
      .addMatcher(
        isAnyOf(applyBarcode.fulfilled, applyBarcode.rejected),
        () => false,
      );
  },
});

export default slice.reducer;

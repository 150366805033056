import { createSelector } from 'reselect';

import getSelectedOffer from './getSelectedOffer';
import getSelectedOfferHasEffect from './getSelectedOfferHasEffect';

export default createSelector(
  [getSelectedOffer, getSelectedOfferHasEffect],
  (selectedOffer, hasEffect): boolean | undefined => {
    if (selectedOffer === undefined || hasEffect === undefined) {
      return undefined;
    }

    return hasEffect;
  },
);

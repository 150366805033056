import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import ScreenHero from '../components/ScreenHero';
import RedcatImage from '../components/RedcatImage';
import { BRAND_ICON_PREFIX } from '../constants';
import Text from '../components/Text';
import TouchableOpacity from '../components/TouchableOpacity';
import { MENU_SCREEN_ROUTE } from '../hooks/useRoutes';
import history from '../history';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';

const { setCurrentMenuBrandId } = OrderingOperations;
const { getLocationBrandsWithMenuRepresentation } = OrderingSelectors;

const PickBrand = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const representedBrands = useAppSelector(getLocationBrandsWithMenuRepresentation);
  const brandTabs = [...representedBrands];

  return (
    <ScreenHero>
      <div className="my-3">
        <div style={{ textAlign: 'center' }} className="my-3">
          <Text>Order from multiple brands</Text>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {brandTabs.map(brand => (
            <div key={brand.id} style={{ width: '50%', padding: 20 }}>
              <TouchableOpacity
                onClick={async () => {
                  dispatch(setCurrentMenuBrandId(brand.id));
                  history.replace(MENU_SCREEN_ROUTE);
                }}
              >
                <RedcatImage
                  imagePath={brand.imagePath}
                  alt={brand.name}
                  pathPrefix={BRAND_ICON_PREFIX}
                  imgFluid
                />
                <div style={{ textAlign: 'center' }}>
                  <Text>{brand.name}</Text>
                </div>
              </TouchableOpacity>
            </div>
          ))}
        </div>
      </div>
    </ScreenHero>
  );
};

export default PickBrand;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'upsellTitle',
  initialState: '',
  reducers: {
    setUpsellTitle(undefined, action: PayloadAction<string>) {
      return action.payload;
    },
  },
});

export const { setUpsellTitle } = slice.actions;
export default slice.reducer;

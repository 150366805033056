import { createSelector } from 'reselect';

import applePayItemFromPurchase from '../utils/ordering/applePayItemFromPurchase';
import { centsToDollarString } from '../utils/misc';

import getStagedPurchases from './getStagedPurchases';
import { getMerchantConfig } from './config';
import getSelectedPaymentMethods from './getSelectedPaymentMethods';
import { getItemPaymentTotal } from '../utils/misc';

export default createSelector(
  [getStagedPurchases, getMerchantConfig, getSelectedPaymentMethods],
  (
    purchases = [],
    merchantConfig,
    getSelectedPaymentMethods: any[],
  ): ApplePayItems => [
    ...purchases.map(applePayItemFromPurchase),
    {
      label: merchantConfig.merchantName || 'Total',
      amount: centsToDollarString(
        getItemPaymentTotal(getSelectedPaymentMethods, 'apple-pay'),
      ),
    },
  ],
);

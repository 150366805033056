import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';

const initialState = null as MaxOrderValue[] | null;

const slice = createSlice({
  name: '$maxOrderValueRules',
  initialState,
  reducers: {
    setMaxOrderValues(state, action: PayloadAction<MaxOrderValue[] | null>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      return initialState;
    });
  },
});

export const { setMaxOrderValues } = slice.actions;
export default slice.reducer;

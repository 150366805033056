import SignInForm from '../components/SignInForm';
import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import ScreenHero from '../components/ScreenHero';
import ScreenFloating from '../components/ScreenFloating';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import { Redirect } from 'react-router-dom';
import { OrderingSelectors } from 'polygon-ordering';
import { REWARDS_SCREEN_ROUTE } from './RewardsScreen';
import delay from '../utils/delay';
import { setCurrentModal } from '../thunks/setCurrentModal';
import history from '../history';

const { getMember } = OrderingSelectors;

export const REGISTER_ROUTE = '/';

const RegisterScreen: React.FC = () => {
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const ScreenComponent = deviceTypeMobile ? ScreenHero : ScreenFloating;
  const isOnlyLoyaltyEnabled = useAppSelector(state => state.config.enableOnlyLoyalty);
  const member = useAppSelector(getMember);

  return isOnlyLoyaltyEnabled && member ? (
    <Redirect to={REWARDS_SCREEN_ROUTE} />
  ) : (
    <ScreenComponent>
      <div
        style={combineStyles(
          deviceTypeMobile ? styles.mainContainerMobile : styles.mainContainerDesktop,
          p('screen', ['backgroundColor']),
          p(deviceTypeMobile ? 'initialScreenMobile' : 'initialScreenDesktop', [
            'backgroundColor',
            'boxShadow',
          ]),
        )}
      >
        <SignInForm
          onSuccess={async () => {
            await delay(2000);
            dispatch(setCurrentModal(null));
            if (isOnlyLoyaltyEnabled) {
              history.push(REWARDS_SCREEN_ROUTE);
            }
          }}
        />
      </div>
    </ScreenComponent>
  );
};

export default RegisterScreen;

const styles = {
  mainContainerDesktop: {
    borderRadius: 2,
    padding: 35,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',

    margin: 'auto', // https://stackoverflow.com/a/33455342/8706204
  },
  mainContainerMobile: {
    flex: 1,
    padding: '10px 25px 100px 25px',
  },
};

import React, { useCallback, useEffect } from 'react';
import Text from '../components/Text';
import { useTranslation } from 'react-i18next';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import combineStyles from '../utils/combineStyles';
import LoyaltyTitle from '../components/LoyaltyTitle';
import RatingThumbs from '../components/RatingThumbs';
import { TextField } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';

export const FEEDBACK_DELIVERY_SCREEN = '/feedback/delivery';

const FeedbackDeliveryScreen = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  const { values, setFieldValue, validateForm, handleChange, handleBlur } =
    useFormikContext<FormikValues>();
  const formValues = values as Feedback;
  const deliveryValues = formValues.delivery;

  const setIsGoodCallback = useCallback((newValue: boolean) => {
    setFieldValue('delivery.isGood', newValue);
  }, []);

  useEffect(() => {
    validateForm();
  }, []);

  return (
    <div style={styles.container}>
      <LoyaltyTitle
        title={t('title.feedback.delivery', {
          storeName: formValues.store.storeName,
        })}
        titleTranslated
      />
      {deliveryValues && (
        <div>
          <div
            style={{
              margin: '30px 0',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <RatingThumbs
              isGood={deliveryValues.isGood}
              setIsGood={setIsGoodCallback}
              size={55}
              padding={12}
              spacing={45}
            />
          </div>
          {deliveryValues.isGood != null && (
            <div
              style={{
                marginTop: 30,
              }}
            >
              <TextField
                multiline
                //placeholderTextColor={p('offerPickerPlaceholderText', ['color']).color}
                //returnKeyType="go"
                rows={6}
                style={combineStyles(
                  { minHeight: 200, width: '100%' },
                  p('defaultText', TEXT_PROPERTIES),
                )}
                //activeOutlineColor={p('activeOutline', ['color']).color}
                //outlineColor={p('outline', ['borderColor']).borderColor}
                label={t('form.placeholder.feedback.delivery')}
                onChange={handleChange('delivery.additionalDetails')}
                onBlur={handleBlur('delivery.additionalDetails')}
                value={deliveryValues.additionalDetails}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FeedbackDeliveryScreen;

const styles: Styles = {
  container: {
    /* display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '2em', */
  },
};

// not sure if this file is ever used in new OLO 3?

import lodash from 'lodash';

import determineStockBalanceThreshold from './determineStockBalanceThreshold';
import { OrderingConstants, OrderingSelectors } from 'polygon-ordering';

import { store } from '../store';

const { STOCK_BALANCE_THRESHOLDS } = OrderingConstants;

const { getStockBalances, getStagedPurchases } = OrderingSelectors;

export default function (itemID) {
  const state = store.getState();
  const stockBalances = getStockBalances(state);
  const stagedPurchases = getStagedPurchases(state);

  const stockBalanceObject = lodash.find(stockBalances, ['PLUCode', parseInt(itemID)]);
  const itemBalance = Math.floor(stockBalanceObject?.Balance);

  let cartQuantity = 0;
  let trackStagedItems = {};

  // TODO: Get this to handle arbitrary levels of choices e.g. meal deal -> burger -> ingredient
  stagedPurchases.map(stagedPurchase => {
    lodash.update(trackStagedItems, stagedPurchase.plucode, n => {
      return n ? n + stagedPurchase.quantity : stagedPurchase.quantity;
    });

    if (stagedPurchase.plucode === itemID) {
      cartQuantity += stagedPurchase.quantity;
    }
    if (stagedPurchase.choicesWithQuantity.length) {
      stagedPurchase.choicesWithQuantity.map(choiceWithQuantity => {
        lodash.update(trackStagedItems, choiceWithQuantity.plucode, n => {
          return n
            ? n + choiceWithQuantity.quantity * stagedPurchase.quantity
            : choiceWithQuantity.quantity * stagedPurchase.quantity;
        });

        if (choiceWithQuantity.plucode === itemID) {
          cartQuantity += choiceWithQuantity.quantity * stagedPurchase.quantity;
        }
      });
    }
  });

  const cartAdjustedBalance = itemBalance - cartQuantity;
  const stockBalanceThreshold = determineStockBalanceThreshold(itemBalance);

  //this logic to ensure choiceset does not have sold out labels
  const stockBalanceThresholdFromCartAdjustedBalance =
    determineStockBalanceThreshold(cartAdjustedBalance);

  const soldOut =
    stockBalanceThreshold &&
    stockBalanceThreshold === STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0;

  const soldOutByCartSelections =
    stockBalanceThresholdFromCartAdjustedBalance &&
    stockBalanceThresholdFromCartAdjustedBalance ===
      STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0;

  return {
    itemBalance: itemBalance >= 0 ? itemBalance : undefined,
    cartAdjustedBalance: cartAdjustedBalance >= 0 ? cartAdjustedBalance : undefined,
    stockBalanceThreshold: stockBalanceThresholdFromCartAdjustedBalance,
    soldOut: soldOut,
    soldOutByCartSelections: soldOutByCartSelections,
  };
}

import React from 'react';
import getSurcharges from '../selectors/getSurcharges';
import Text from './Text';
import { useAppSelector } from '../app/hooks';
import Line from './Line';

const Surcharges = () => {
  const surcharges = useAppSelector(getSurcharges);

  return (
    <div>
      {surcharges.map(surcharge => (
        <div className="py-1" key={surcharge.description}>
          <Line
            label={surcharge.description}
            amount={(Number(surcharge.amount))}
            deemphasised
          />
        </div>
      ))}
    </div>
  );
};

export default Surcharges;

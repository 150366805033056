import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'oAuthDetails',
  initialState: null as OAuthDetails | null,
  reducers: {
    setOAuthDetails: (_, action: PayloadAction<OAuthDetails | null>) => {
      return action.payload;
    },
  },
});

export const { setOAuthDetails } = slice.actions;
export default slice.reducer;

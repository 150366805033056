import { createSelector } from 'reselect';
import getOrderTotals from './getOrderTotals';
import getMaxOrderValue from './getMaxOrderValueRule';

export default createSelector(
  [getOrderTotals, getMaxOrderValue],
  (orderTotals, rule) => {
    return rule
      ? (orderTotals?.discountedMoneyPrice || 0) > rule.MaximumOrder
      : false;
  },
);

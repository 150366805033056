import { createSelector } from 'reselect';

import getBuffer from './getBuffer';
import getCurrentOrder from './getCurrentOrder';

const getOrderingProviderSurchargeInstructions = createSelector(
  [getCurrentOrder],
  order => {
    return order.orderingProviderSurchargeInstructions;
  },
);

export default getOrderingProviderSurchargeInstructions;

export const $getOrderingProviderSurchargeInstructions = createSelector(
  [getOrderingProviderSurchargeInstructions, getBuffer],
  (instructions, buffer) => {
    if (buffer.orderingProviderSurchargeInstructions) {
      return buffer.orderingProviderSurchargeInstructions;
    }

    return instructions;
  },
);

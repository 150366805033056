import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { ORDER_SUMMARY_MODAL_ID } from '../modals/OrderSummaryModal';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import Text from './Text';
import { useLocation } from 'react-router-dom';
import { MENU_SCREEN_ROUTE } from '../hooks/useRoutes';
import StandardButton from './StandardButton';
import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';

import { CSSTransition } from 'react-transition-group';
import { styled } from '@mui/system';
import { MESSAGE_MODAL_ID } from '../modals/MessageModal';

const StandardButtonWrapper = styled('div')(({ theme }) => ({
  //position: 'fixed',
  transition: 'all 450ms cubic-bezier(0.95, 0.09, 0.01, 0.91)',

  '.view-cart-enter': {
    transform: 'translate(0, 5em)',
  },
  '.view-cart-enter-active': {
    transition: 'all 450ms cubic-bezier(0.95, 0.09, 0.01, 0.91)',
    transform: 'translate(0, 0)',
  },
  '.view-cart-exit': {
    opacity: 1,
    transform: 'translate(0, 0)',
  },
  '.view-cart-exit-active': {
    opacity: 0,
    transform: 'translate(0, 5em)',
    transition: 'all 450ms cubic-bezier(0.83, 0.09, 0.01, 0.91)',
  },
}));

const { useFormattedCurrency } = OrderingHooks;
const { getOrderTotals, getStagedPurchaseCount, getUnavailablePurchases } = OrderingSelectors;

const ViewCartButton = ({ handleViewCart }: { handleViewCart: () => void }) => {
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const orderTotals = useAppSelector(getOrderTotals);
  const stagedPurchaseCount = useAppSelector(getStagedPurchaseCount) as number;
  const unavailablePurchaseCount = (useAppSelector(getUnavailablePurchases) as _Purchase[]).reduce(
    (sum: number, obj: _Purchase) => sum + obj.quantity,
    0,
  );
  const someUnavailable = !!unavailablePurchaseCount;

  useEffect(() => {
    if (!someUnavailable) return;
    dispatch(
      setCurrentModal({
        modalId: MESSAGE_MODAL_ID,
        params: {
          title: t('unavailablePurchasesWarning.title'),
          message: t('unavailablePurchasesWarning.text'),
          action: () => dispatch(setCurrentModal({ modalId: ORDER_SUMMARY_MODAL_ID })),
        },
        clearReturnModalDetails: true,
      }),
    );
  }, [someUnavailable]);

  const labelStyle = combineStyles(styles.label, p('defaultButton', TEXT_PROPERTIES), {
    fontSize: 14,
    paddingLeft: 5,
    paddingRight: 5,
  });

  const moneyPrice = useFormattedCurrency({ cents: orderTotals?.discountedMoneyPrice });

  return (
    <div>
      {stagedPurchaseCount !== 0 && (
        <StandardButtonWrapper>
          {pathname == MENU_SCREEN_ROUTE && stagedPurchaseCount + unavailablePurchaseCount != 0}
          <div>
            <StandardButton
              containerStyle={combineStyles(
                styles.buttonContainer,
                p('viewCartButton', CONTAINER_PROPERTIES),
              )}
              onClick={handleViewCart}
              leftContainer={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    width: 25,
                    border: '1px solid white',
                  }}
                >
                  <Text themeKey="viewCartButton">{stagedPurchaseCount}</Text>{' '}
                </div>
              }
              rightContainer={<Text themeKey="viewCartButton">{moneyPrice}</Text>}
            >
              <div style={styles.labelContainer}>
                <Text style={labelStyle}>{`${t('button.viewCart')} `}</Text>
              </div>
            </StandardButton>
          </div>
        </StandardButtonWrapper>
      )}
    </div>
  );
};

export default ViewCartButton;

const styles: Styles = {
  buttonContainer: {
    padding: '0.75em 0.75em',
    borderRadius: 5,
    width: 250,
    flex: 1,
    justifyContent: 'space-between',
    bottom: '1em',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%,0)',
  },
  labelContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text'; // TODO: rework this to use Text component
import { useAppDispatch, useAppSelector } from '../app/hooks';
export const MESSAGE_MODAL_ID = 'MESSAGE_MODAL_ID';

const MessageModal = ({
  title,
  message,
  titleStyle,
  desktopMaxWidth = 500,
  desktopMinWidth = 500,
  handleClose,
}: {
  desktopMaxWidth: number;
  desktopMinWidth: number;
  title?: string;
  message?: string;
  titleStyle?: React.CSSProperties;
  handleClose?: () => void;
  closeButtonStyle?: React.CSSProperties;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalParams = useAppSelector(state => state.modalParams);

  return (
    <Modal
      title={title ? title : modalParams?.title}
      desktopMaxWidth={desktopMaxWidth}
      desktopMinWidth={desktopMinWidth}
      desktopContainerStyle={styles.modalDesktopContainer}
      // hideFloatingHeader
      titleStyle={titleStyle}
      handleClose={handleClose}
      wrapperStyle={styles.wrapperStyle}
      mobileContainerStyle={styles.mobileContainerStyle}
    >
      {(modalParams?.message || message) && (
        <Text block value={modalParams?.message ?? message} themeKey="messageModalMessage" />
      )}

      <StandardButton
        label={modalParams?.buttonText ?? t('button.ok')}
        onClick={modalParams?.action ?? (() => dispatch(setCurrentModal(null)))}
        themeKey="messageModalButton"
        style={{ marginTop: 'auto' }}
      />
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    gap: 25,
    paddingBottom: 40,
  },
  wrapperStyle: {
    height: '80%',
  },
  mobileContainerStyle: {
    paddingTop: 100,
    height: '100%',
  },
};

export default MessageModal;

import { createSelector } from 'reselect';

import calculateTotalsForPurchase from '../utils/ordering/calculateTotalsForPurchase';
import enhancePurchase from '../utils/ordering/enhancePurchase';

import getUnenhancedOpenPurchase from './getUnenhancedOpenPurchase';
import { getChoiceOrderingMethod, getChoiceSetDiscountConfig } from './config';
import getMenu from './getMenu';

export default createSelector(
  [
    getMenu,
    getUnenhancedOpenPurchase,
    getChoiceOrderingMethod,
    getChoiceSetDiscountConfig,
  ],
  (
    menu,
    openPurchase,
    choiceOrderingMethod,
    choiceSetDiscountConfig,
  ): PurchaseWithTotals | undefined => {
    if (!menu || !openPurchase || !choiceOrderingMethod) return;

    // attach the full item object to the purchase object (among other things)
    // this also sums all the item and selected choice prices
    const enhanced = enhancePurchase(
      openPurchase,
      menu,
      choiceOrderingMethod,
      choiceSetDiscountConfig,
    );

    if (!enhanced) return;

    return {
      ...enhanced,
      ...calculateTotalsForPurchase(
        enhanced,
        choiceOrderingMethod,
        false,
        choiceSetDiscountConfig,
      ),
    };
  },
);

import { createSelector } from 'reselect';
import lodash from 'lodash';

import getLocations from './getLocations';
import { getShowHiddenLocations } from './config';

export default createSelector(
  [getLocations, getShowHiddenLocations],
  (
    locations,
    showHiddenLocations,
  ): SDict<POSLocation> | SDict<POSLocationShort> => {
    const locationsForDisplay: SDict<POSLocation | POSLocationShort> = {};

    // wtf is this Max?
    const blacklist = [0, undefined, null];

    lodash.values(locations || {}).forEach(location => {
      if (
        // isn't this equivalent to !!location.latitude
        // also why wouldn't we have lat/lon here, and why is it being filtered here Max?
        !blacklist.includes(location.latitude) &&
        !blacklist.includes(location.longitude) &&
        (!location.hidden || showHiddenLocations)
      ) {
        locationsForDisplay[location.id] = location;
      }
    });

    return locationsForDisplay as SDict<POSLocation> | SDict<POSLocationShort>;
  },
);

// I am confident that this is equivalent:
// import { objectFilter } from '../utils/misc';
// createSelector(
//   [getLocations, getShowHiddenLocations],
//   (
//     locations,
//     showHiddenLocations,
//   ): SDict<POSLocation> | SDict<POSLocationShort> => {
//     return objectFilter<POSLocation | POSLocationShort>(
//       locations ?? {},
//       location =>
//         !!location.latitude &&
//         !!location.longitude &&
//         (!location.hidden || showHiddenLocations),
//     ) as SDict<POSLocation> | SDict<POSLocationShort>;
//   },
// );

import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import toDollarString from '../utils/toDollarString';
import Text from './Text';
import { FormikValues, useFormikContext } from 'formik';

const { getCalculatedGiftcardSurcharge } = OrderingSelectors;

const GiftcardSurcharge = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const surcharge = useAppSelector(state =>
    getCalculatedGiftcardSurcharge(state, Number(values.Amount.replace(/[^0-9]/g, ''))),
  );
  const surchargeRef = useRef<number>();

  useEffect(() => {
    if (surchargeRef.current !== surcharge.value) {
      surchargeRef.current = surcharge.value;
      setFieldValue('surcharge', surcharge.value);
    }
  }, [surcharge]);

  return surcharge && surcharge.value !== 0 ? (
    <div style={styles.surchargeContainer}>
      <Text themeKey="stagedPurchase">
        {surcharge.description ? surcharge.description : t('surchargeNameFallback')}
      </Text>
      <Text themeKey="stagedPurchase">
        {toDollarString(surcharge.value!, { clipEmptyDecimals: true })}
      </Text>
    </div>
  ) : (
    <></>
  );
};

export default GiftcardSurcharge;

const styles: Styles = {
  surchargeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 5,
  },
};

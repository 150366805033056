import { createSelector } from 'reselect';
import lodash from 'lodash';
import { OrderingSelectors } from 'polygon-ordering';

const { getFilteredRootCategory } = OrderingSelectors;

export default createSelector([getFilteredRootCategory], filteredRootCategory => {
  let totalItemCount = 0;

  lodash.get(filteredRootCategory, 'subCategories', []).forEach(subCategory => {
    totalItemCount += subCategory.items.length;
  });

  return totalItemCount;
});

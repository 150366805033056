import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'disabledLoyaltyRoutes',
  initialState: null as null | string[],
  reducers: {
    setDisabledLoyaltyRoutes(state, action: PayloadAction<string[]>) {
      return action.payload;
    },
  },
});

export const { setDisabledLoyaltyRoutes } = slice.actions;
export default slice.reducer;

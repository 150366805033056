import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as SALE_TYPE[] | null;

const slice = createSlice({
  name: '$enabledSaleTypes',
  initialState,
  reducers: {
    setEnabledSaleTypes(state, action: PayloadAction<SALE_TYPE[]>) {
      if (!action.payload) {
        return initialState;
      }

      return action.payload.map(saleType =>
        typeof saleType === 'number' ? saleType : parseInt(saleType),
      );
    },
  },
});

export const { setEnabledSaleTypes } = slice.actions;
export default slice.reducer;

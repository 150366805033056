// TODO: type input as action: Action, state: EntireFrontendState
type Hook = (...args: any[]) => boolean | void | Promise<boolean>;

export default class LifecycleHooks {
  static hooks: Record<string, Hook | undefined> = {};

  static subscribe(hookName: string, handler: Hook) {
    LifecycleHooks.hooks[hookName] = handler;
  }

  static clear(hookName: string) {
    LifecycleHooks.hooks[hookName] = undefined;
  }

  static get(hookName: string) {
    const fallback = () => true;
    const hook = LifecycleHooks.hooks[hookName] || fallback;
    return hook;
  }
}

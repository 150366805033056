import processOrderingImagePath from './processOrderingImagePath';
export default function processImageMap(imageMap?: SDict<RawImageMapEntry>) {
  const result: SDict<string> = {};

  Object.entries(imageMap || {}).forEach(entry => {
    result[entry[0]] = processOrderingImagePath(entry[1].path) as string;
  });

  return result;
}

import { createSelector } from 'reselect';

import getOrdering from './getOrdering';
import getLocation from './getLocation';
import { PosType } from '../constants/PosType';

export default createSelector(
  [getOrdering, getLocation],
  (ordering, location) => {
    const { futureOrderingLimit, enableFutureOrdering } = ordering.config;

    if (location === undefined) {
      return 0;
    }

    const { posType } = location as POSLocation;

    return enableFutureOrdering && posType !== PosType.LEGACY_POS
      ? futureOrderingLimit
      : 0;
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { FetchParams, AuthenticationMethod } from '../utils/Api';
import processPaymentGatewayToken from '../utils/processors/processPaymentGatewayToken';
import { getEnableDynamicPaymentGatewayConfig } from '../selectors';
import { setPaymentGatewayConfigPaymentMethods } from '../reducers/currentOrder/paymentGatewayConfig';

export const removePaymentGatewayToken = createAsyncThunk(
  '$removePaymentGatewayToken',
  async (
    data: {
      token: string;
      authenticationMethod?: AuthenticationMethod;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { token, authenticationMethod = 'member' } = data;

    try {
      const params: FetchParams = {
        path: `/api/v1/profile/pgtokens/${token}`,
        method: 'DELETE',
      };

      const response = await Api.fetch(params, authenticationMethod);

      const enableDynamicPaymentGatewayConfig =
        getEnableDynamicPaymentGatewayConfig(getState() as EntireFrontendState);

      if (enableDynamicPaymentGatewayConfig) {
        dispatch(setPaymentGatewayConfigPaymentMethods(response.data));
      } else {
        const rawTokens: RawPaymentGatewayToken[] = response.data;
        const processedTokens = rawTokens.map(processPaymentGatewayToken);

        return {
          token: processedTokens,
        };
      }
    } catch (e) {
      console.error('Remove payment gateway token failed', { e });
      return rejectWithValue(e);
    }
  },
);

import { createSelector } from 'reselect';

import getSelectedOffer, { $getSelectedOffer } from './getSelectedOffer';
import getOrderTotals, { $getOrderTotals } from './getOrderTotals';
import getCouponMinimumSpendingInclusive from './getCouponMinimumSpendingInclusive';
import getSelectedOfferValid from './getSelectedOfferValid';

const get = (
  selectedOffer: Offer | undefined,
  orderTotals: OrderTotals | undefined,
  isOfferValid: boolean | undefined,
  couponDiscountInclusive: boolean,
): number => {
  if (orderTotals === undefined || !isOfferValid || !selectedOffer) {
    return 0;
  }

  //minimum order coupon
  if (selectedOffer.MinOrderValue) {
    if (couponDiscountInclusive) {
      return Math.max(
        0,
        selectedOffer.MinOrderValue * 100 - orderTotals.discountedMoneyPrice,
      );
    } else {
      return Math.max(
        0,
        selectedOffer.MinOrderValue * 100 - orderTotals.purchasesMoneyPrice,
      );
    }
  }

  return 0;
};

export default createSelector(
  [
    getSelectedOffer,
    getOrderTotals,
    getSelectedOfferValid,
    getCouponMinimumSpendingInclusive,
  ],
  get,
);

export const $getSelectedOfferMiniValueValid = createSelector(
  [
    $getSelectedOffer,
    $getOrderTotals,
    getSelectedOfferValid,
    getCouponMinimumSpendingInclusive,
  ],
  get,
);

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import Modal from '../components/Modal';
import Text from '../components/Text';
import StandardButton from '../components/StandardButton';
import { OrderingOperations } from 'polygon-ordering';
import moment from 'moment';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from '../utils/snackbar';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import { Formik } from 'formik';
import FormTextField from '../components/FormTextField';

export const FAVOURITE_NAME_MODAL = 'FAVOURITE_NAME_MODAL';

const { createFavouriteOrder } = OrderingOperations;

const FavouriteNameModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const orderItems = useAppSelector(state => state.modalParams)?.historicalOrderItems;

  return (
    <Modal
      clickBackgroundToClose
      desktopMaxWidth={400}
      desktopMinWidth={400}
      closeButtonLabel={t('button.modalClose.dismiss')}
      containerStyle={styles.containerStyle}
      desktopMinHeight={300}
      title={t('favourite.createNewFavouriteModal.title')}
    >
      <Formik
        initialValues={{
          title: '',
        }}
        onSubmit={({ title }: { title: string }) => {
          dispatch(
            createFavouriteOrder({
              //@ts-ignore
              items: orderItems || [],
              name: title || moment().format('DD/MM/YYYY hh:mma'),
            }),
          )
            .unwrap()
            .then(() => {
              logEvent(EVENTS.CREATE_FAVOURITE);
              dispatch(setCurrentModal(null));
              enqueueSuccessSnackbar(t('snackbarMessages.success'));
            })
            .catch(e => {
              enqueueErrorSnackbar(e.message);
            });
        }}
      >
        {({ handleChange, handleSubmit }) => (
          <div style={{ width: '100%' }}>
            <Text themeKey="orderMessage" value={t('favourite.createNewFavouriteModal.message')} />
            <div style={styles.inputSection}>
              <FormTextField
                name="title"
                type="text"
                label={t('field.name')}
                onChange={handleChange}
              />
            </div>
            <div className="mt-2">
              <StandardButton
                containerStyle={styles.button}
                themeKey="createNewFavourite"
                label={t('favourite.createNewFavouriteModal.button')}
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

const styles: Styles = {
  containerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    height: 40,
  },
};

export default FavouriteNameModal;

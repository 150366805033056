import { createAsyncThunk } from '@reduxjs/toolkit';
import { FAILURE_REASON } from '../constants';
import {
  removeStagedPurchase,
  setSelectedOffer,
  stagePurchaseFromItem,
} from '../operations';
import { getOffers, getSelectedOffer, getStagedPurchases } from '../selectors';
import { OFFER_TYPE } from '../constants/offerType';
import getItems from '../selectors/getItems';
import { findMap } from '../utils/misc';

export const selectOffer = createAsyncThunk(
  '$selectOffer',
  async (
    data: {
      offerId: string | null;
      userAdded?: boolean;
    },
    { dispatch, rejectWithValue, getState },
  ) => {
    let reason: FailureReason;
    let purchaseStaged;
    let ignoredPurchaseId;

    const {
      offerId: existingSelectedOfferId,
      purchaseId: existingPurchaseId,
      userAdded: userAddedExistingOffer,
    } = (getState() as EntireFrontendState).ordering.currentOrder.selectedOffer;

    try {
      const { offerId, userAdded = true } = data;

      dispatch(setSelectedOffer({ offerId, userAdded }));

      if (offerId != null) {
        const offers = getOffers(getState() as EntireFrontendState);
        const targetOffer = offers.find(offer => offer.id === offerId);

        if (!targetOffer) {
          reason = FAILURE_REASON.OFFER_NOT_PRESENT_AND_AVAILABLE;
          throw new Error('no promo code');
        }

        if (targetOffer.type === OFFER_TYPE.PERCENTAGE_OFF_ITEM_POS_ENFORCED) {
          const targetItemPlucode = targetOffer.targetPLUs[0];
          const allItems = getItems(getState() as EntireFrontendState);
          const targetItemId =
            (!!allItems &&
              findMap(Object.values(allItems), item => {
                if (!item.plucode) return;
                if (item.plucode !== targetItemPlucode) return;
                return item.id;
              })) ||
            undefined;

          const stagedPurchases = getStagedPurchases(
            getState() as EntireFrontendState,
          );

          const existing = stagedPurchases.find(
            purchase => purchase.plucode === targetItemPlucode,
          );

          if (!existing) {
            if (targetItemId) {
              const purchaseId = await dispatch(
                stagePurchaseFromItem({ itemId: targetItemId }),
              ).unwrap();
              dispatch(
                setSelectedOffer({
                  offerId,
                  purchaseId,
                  userAdded,
                }),
              );
              purchaseStaged = true;
              ignoredPurchaseId = purchaseId;
            }
          } else {
            ignoredPurchaseId = existing.id;
          }
        }
      }

      if (existingSelectedOfferId !== offerId) {
        if (!!existingPurchaseId && existingPurchaseId !== ignoredPurchaseId) {
          // remove the existing purchase as it was staged by a forced PLU coupon
          dispatch(removeStagedPurchase(existingPurchaseId));
        }
      }

      return {
        purchaseStaged: !!purchaseStaged,
        offerId,
      };
    } catch (e) {
      console.error('Selecting an offer failed', { e });
      return rejectWithValue({ e, reason });
    }
  },
);

export type FailureReason =
  | undefined
  | FAILURE_REASON.OFFER_NOT_PRESENT_AND_AVAILABLE
  | FAILURE_REASON.COULD_NOT_STAGE_PURCHASE;

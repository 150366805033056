import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

import combineTokenisedAddress from '../utils/ordering/combineTokenisedAddress';

const getDeliveryAddressString = createSelector([getCurrentOrder], (order):
  | string
  | undefined => {
  if (order.deliveryAddress != null) {
    return combineTokenisedAddress(order.deliveryAddress);
  }

  return undefined;
});

export default getDeliveryAddressString;

export const $getDeliveryAddressString = createSelector(
  [getDeliveryAddressString, getBuffer],
  (orderAddress, buffer): string | undefined => {
    if (buffer.deliveryAddress != null) {
      return combineTokenisedAddress(buffer.deliveryAddress);
    }

    return orderAddress;
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { SALE_TYPE } from '../constants/saleType';
import { resetOrder } from '../operations';
import { setLocationId } from '../reducers/currentOrder/locationId';
import { setSaleType } from '../reducers/currentOrder/saleType';

export const startNewOrder = createAsyncThunk(
  '$startNewOrder',
  async (
    data: {
      preserveMember?: boolean;
      saleType?: SALE_TYPE;
      locationId?: string;
      uuid?: string;
      lifecycleEventParams?: any;
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const {
        preserveMember = false,
        saleType,
        locationId,
        uuid,
        lifecycleEventParams,
      } = data;

      dispatch(resetOrder({ preserveMember, uuid }));

      if (saleType != null) {
        dispatch(setSaleType(saleType));
      }

      if (locationId !== null) {
        dispatch(setLocationId(locationId!));
      }

      return {
        lifecycleEventParams,
      };
    } catch (e) {
      console.error('Start new order failed', { e });
      return rejectWithValue(e);
    }
  },
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RedcatApiHandler } from 'polygon-utils';
import { setAvailableTableNumbers } from '../reducers/currentOrder/availableTableNumbers';

// validate only if its successful - this is a relaxed API call,
// we only use the this data for validation if we get anything
export const fetchAvailableTableNumbers = createAsyncThunk(
  '$fetchAvailableTableNumbers',
  async (
    data: {
      locationId: string;
    },
    { dispatch },
  ) => {
    const { locationId } = data;
    const path = `api/v1/stores/${locationId}/table`;
    return RedcatApiHandler.fetch({ method: 'GET', path })
      .then(response => {
        if (response.success) {
          const { data } = response?.data;

          const tableNumbers =
            (data as RawTableNumbers[])?.map(item => item.Name) || [];

          dispatch(setAvailableTableNumbers(tableNumbers));
        }
      })
      .catch(err => {
        console.log(err.message);
      });
  },
);

import React from 'react';
import clsx from 'clsx';
import combineStyles from '../utils/combineStyles';
import '../styles/MapMarker.css';

interface IProps {
  color?: React.CSSProperties;
  style?: React.CSSProperties;
  darkDot?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  lat?: any;
  lng?: any;
}

const MapMarker: React.FC<IProps> = ({
  darkDot,
  color,
  onClick,
  onMouseEnter,
  onMouseLeave,
  style,
}) => {
  return (
    <>
      <div
        className={clsx('pin', darkDot ? 'pin-darkdot' : 'pin-lightdot')}
        style={combineStyles(styles.marker, { background: color }, style)}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </>
  );
};

const styles = {
  marker: {
    cursor: 'pointer',
  },
};

export default MapMarker;

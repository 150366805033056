import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiSearchLine, RiCloseLine } from 'react-icons/ri';
import EVENTS from '../constants/events';
import combineStyles from '../utils/combineStyles';
import { logEvent } from '../utils/analytics';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import TouchableOpacity from './TouchableOpacity';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';

const { setMenuSearchText } = OrderingOperations;
const { getMenuSearchText } = OrderingSelectors;

interface IProps {
  toggleSearchActive?: () => void;
  hideCloseButton?: boolean;
}

const MenuSearchBar: React.FC<IProps> = ({ toggleSearchActive, hideCloseButton = false }) => {
  const p = useAppSelector(getThemeLookup);
  const menuSearchText = useAppSelector(getMenuSearchText);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Cancel button function
  const cancelMenuSearch = () => {
    dispatch(setMenuSearchText(null));
    toggleSearchActive?.();
  };

  useEffect(() => {
    // when the search bar is mounted set the search text to ''? so as to not show any items
    if (menuSearchText === null) {
      dispatch(setMenuSearchText(''));
    }

    // // When search component is unmounted make search not active and clear the search bar.
    // return () => {
    //   cancelMenuSearch();
    // };
  }, []);

  return (
    <div style={styles.mainContainer}>
      <div style={combineStyles(styles.top, !deviceTypeMobile && styles.topDesktop)}>
        <div style={styles.inputWrapper}>
          <RiSearchLine style={combineStyles(styles.searchIcon, p('searchBarIcon', TEXT_PROPERTIES))} />

          <input
            style={combineStyles(styles.input, p('searchBar', CONTAINER_PROPERTIES))}
            type="text"
            aria-label="Search Menu"
            value={menuSearchText ?? ''} // always have to provide a string (or react gives up control)
            placeholder={t('placeholder.menuSearch')}
            onChange={event => {
              const value = event?.target?.value;
              dispatch(setMenuSearchText(value));
              logEvent(EVENTS.CHANGE_MENU_SEARCH_TEXT, { label: value });
            }}
            autoFocus
          />

          {!hideCloseButton && (
            <TouchableOpacity
              style={combineStyles(styles.clearIconContainer, p('searchCloseButton', TEXT_PROPERTIES))}
              onClick={cancelMenuSearch}
              ariaLabel="clear search"
            >
              <RiCloseLine />
            </TouchableOpacity>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
  },
  top: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchIcon: {
    position: 'absolute',
    left: '1em',
    color: 'rgba(0, 0, 0, 0.8)',
  },
  clearIconContainer: {
    position: 'absolute',
    right: 10,
    color: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20,
  },
  inputWrapper: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    borderRadius: 30,
    padding: 8,
    paddingLeft: '2.75em',
    outline: 'none',
    fontSize: 16,

    border: '1px solid #DFDFDF',
    WebkitAppearance: 'none',
    width: '100%', // without this it won't compress down past a certain size
  },
};

export default MenuSearchBar;

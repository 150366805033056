import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiPencilFill } from 'react-icons/ri';
import { OrderingSelectors } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';
import { ORDER_TYPE_MODAL_ID } from '../modals/OrderTypeModal';
import StandardButton from './StandardButton';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Text from './Text';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

const { getSaleType, getLocation, getTable } = OrderingSelectors;

const OrderPropertySaleType = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const saleType = useAppSelector(getSaleType);
  const displayableSaleTypes = useAppSelector(getDisplayableSaleTypes);
  const dispatch = useAppDispatch();
  const location = useAppSelector(getLocation);
  const tableNumber = useAppSelector(getTable);

  if (saleType == null) {
    return null;
  }

  const saleTypeEditable = displayableSaleTypes.length > 1;

  switch (saleType) {
    case SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER:
      return (
        <Text
          themeKey="sectionTitle"
          value={tableNumber ? t('table') + ` ${tableNumber}` : 'Please select a table'}
          style={{ textAlign: 'center' }}
        />
      );
    case SALE_TYPE.DELIVERY:
      return (
        <Text style={{ textAlign: 'center' }} themeKey="orderPropertyButton">
          {t(`saleType.${saleType}.name`)}
        </Text>
      );
    default:
      return (
        <Text style={{ textAlign: 'center' }} themeKey="orderPropertyButton">
          {t(`saleType.${saleType}.name`) + ` - ${location?.name}`}
        </Text>
      );
  }
};

export default OrderPropertySaleType;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'cvcToken',
  initialState: null as string | null,
  reducers: {
    setCvcToken(state, action: PayloadAction<string | null>) {
      return action.payload || null;
    },
  },
});

export const { setCvcToken } = slice.actions;

export default slice.reducer;

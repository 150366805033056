import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | SubPayment;

const slice = createSlice({
  name: 'paymentMethod',
  initialState,
  reducers: {
    setPaymentMethod(state, action: PayloadAction<SubPayment | null>) {
      return action.payload || initialState;
    },
  },
});

export const { setPaymentMethod } = slice.actions;

export default slice.reducer;

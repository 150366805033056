import { createSelector } from 'reselect';

import validateGiftCard, {
  VALID,
  VALID_IMPORTED,
} from '../utils/ordering/validateGiftCard';

import getCurrentOrder from './getCurrentOrder';
import { getImportedGiftCardRegex } from './config';

export default createSelector(
  [getCurrentOrder, getImportedGiftCardRegex],
  (order, importedGiftCardRegex): GiftCard => {
    const { giftCard } = order;
    //@ts-ignore
    const validType = validateGiftCard(giftCard, importedGiftCardRegex);
    return {
      number: giftCard.number || '',
      pin: giftCard.pin || '',
      valid: Boolean(validType),
      validImported: validType === VALID_IMPORTED,
      validRedcat: validType === VALID,
      moneyBalance:
        giftCard.moneyBalance == null ? undefined : giftCard.moneyBalance,
      active: giftCard.active == null ? undefined : giftCard.active,
    };
  },
);

import React, { useMemo } from 'react';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import RedcatImage from './RedcatImage';
import MenuItemDescriptions from './MenuItemDescriptions';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES } from '../utils/theme';

const { getStockBalanceDataByItem, getOpenPurchase } = OrderingSelectors;

const MenuItemMobile = ({
  item,
  availabilityTimeString = '',
}: {
  item: Item;
  availabilityTimeString: string;
}) => {
  const p = useAppSelector(getThemeLookup);
  const stockBalanceData = useAppSelector(
    getStockBalanceDataByItem(item, useAppSelector(getOpenPurchase) as PurchaseWithTotals),
  );
  const checkIfDisabled =
    stockBalanceData && (stockBalanceData.soldOut || stockBalanceData.soldOutByCartSelections);

  const imagePath = useMemo(
    () => item?.images?.default ?? item.sizes.find(s => s.id === item.default_size)?.images.default,
    [item],
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div>
        <RedcatImage
          alt={item.name}
          imagePath={imagePath}
          containerStyle={combineStyles(
            Boolean(checkIfDisabled) && styles.menuItemOpacity,
            styles.imageContainer,
            p('menuItemImage', CONTAINER_PROPERTIES),
          )}
          imageStyle={combineStyles({ borderRadius: 10 }, p('menuItemImage', ['objectFit']))}
        />
      </div>
      <div style={{ marginLeft: imagePath ? 20 : 0, flexGrow: '1' }}>
        <MenuItemDescriptions item={item} availabilityTimeString={availabilityTimeString} />
      </div>
    </div>
  );
};

export default MenuItemMobile;

const styles = {
  menuItemOpacity: {
    opacity: 0.5,
  },
  imageContainer: {
    height: 130,
    width: 130,
    alignItems: 'flex-start',
  },
};

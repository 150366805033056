import React, { useEffect } from 'react';

import { logModal } from '../utils/analytics';

import { useAppDispatch, useAppSelector } from '../app/hooks';

// import CategoriesModal, { CATEGORIES_MODAL_ID } from '../modals/CategoriesModal';
import DeliveryDetailsModal, { DELIVERY_DETAILS_MODAL_ID } from './DeliveryDetailsModal';
import MessageModal, { MESSAGE_MODAL_ID } from './MessageModal';
import OfferModal, { OFFER_MODAL_ID } from './OfferModal';
import OrderSummaryModal, { ORDER_SUMMARY_MODAL_ID } from './OrderSummaryModal';
import OrderTypeModal, { ORDER_TYPE_MODAL_ID } from './OrderTypeModal';
// import PurchaseEditorModal, { PURCHASE_EDITOR_MODAL_ID } from './PurchaseEditorModal';
import PurchaseEditorModal, { PURCHASE_EDITOR_MODAL_ID } from './PurchaseEditorModalV2';
import StagedPurchaseEditorModal, {
  STAGED_PURCHASE_EDITOR_MODAL_ID,
} from '../modals/StagedPurchaseEditorModal';

import SignInModal, { SIGN_IN_MODAL_ID } from './SignInModal';
import ForgetPassword, { RESET_PASSWORD_MODAL_ID } from './ResetPasswordModal';
import RegisterModal, { REGISTER_MODAL_ID } from './RegisterModal';
import FiltersModal, { FILTERS_MODAL_ID } from './FiltersModal';
import LoyaltyOfferModal, { LOYALTY_OFFER_MODAL_ID } from './LoyaltyOfferModal';
import FavouriteNameModal, { FAVOURITE_NAME_MODAL } from './FavouriteNameModal';
import GiftcardSuccessModal, { GIFTCARD_PURCHASE_SUCCESSFUL_MODAL } from './GiftcardSuccessModal';
import LocationModal, { LOCATION_MODAL_ID } from './LocationModal';
import AgeProofModal, { PROOF_OF_AGE_MODAL } from './AgeProofModal';
import JoinLoyaltyModal, { JOIN_LOYALTY_MODAL } from './JoinLoyaltyModal';
import ContextProvider from '../components/NestedChoiceSetsContext';
import MenuSearchModal, { MENU_SEARCH_MODAL } from './MenuSearchModal';
import MenuFilterModal, { MENU_FILTERS_MODAL_ID } from './MenuFiltersModal';
import UpsellModal, { UPSELL_MODAL } from './Upsells';
import { setCurrentModal } from '../thunks/setCurrentModal';
import ScanCodeModal, { SCAN_CODE_MODAL_ID } from './ScanCodeModal';
import OAuthExtraInfoModal, { OAUTH_EXTRA_INFO_MODAL_ID } from './OAuthExtraInfoModal';
import ItemCartAvailabilityModal, {
  ITEM_CART_AVAILABILITY_MODAL,
} from './ItemCartAvailabilityModal';

const MODALS: ModalsType = {
  [DELIVERY_DETAILS_MODAL_ID]: DeliveryDetailsModal,
  [LOCATION_MODAL_ID]: LocationModal,
  [MESSAGE_MODAL_ID]: MessageModal,
  [OFFER_MODAL_ID]: OfferModal,
  [ORDER_SUMMARY_MODAL_ID]: OrderSummaryModal,
  [ORDER_TYPE_MODAL_ID]: OrderTypeModal,
  [PURCHASE_EDITOR_MODAL_ID]: PurchaseEditorModal,
  [STAGED_PURCHASE_EDITOR_MODAL_ID]: StagedPurchaseEditorModal,
  [SIGN_IN_MODAL_ID]: SignInModal,
  [RESET_PASSWORD_MODAL_ID]: ForgetPassword,
  [REGISTER_MODAL_ID]: RegisterModal,
  [FILTERS_MODAL_ID]: FiltersModal,
  [LOYALTY_OFFER_MODAL_ID]: LoyaltyOfferModal,
  [FAVOURITE_NAME_MODAL]: FavouriteNameModal,
  [GIFTCARD_PURCHASE_SUCCESSFUL_MODAL]: GiftcardSuccessModal,
  [PROOF_OF_AGE_MODAL]: AgeProofModal,
  [ITEM_CART_AVAILABILITY_MODAL]: ItemCartAvailabilityModal,
  [JOIN_LOYALTY_MODAL]: JoinLoyaltyModal,
  [MENU_SEARCH_MODAL]: MenuSearchModal,
  [MENU_FILTERS_MODAL_ID]: MenuFilterModal,
  [UPSELL_MODAL]: UpsellModal,
  [SCAN_CODE_MODAL_ID]: ScanCodeModal,
  [OAUTH_EXTRA_INFO_MODAL_ID]: OAuthExtraInfoModal,
};

const Modals = () => {
  const dispatch = useAppDispatch();
  const currentModalId = useAppSelector(state => state.currentModalId);
  const modalParams = useAppSelector(state => state.modalParams);

  useEffect(() => {
    if (currentModalId === UPSELL_MODAL) dispatch(setCurrentModal(null));
  }, []);
  useEffect(() => {
    // dispatch(setCurrentModal({ modalId: JOIN_LOYALTY_MODAL }));
    logModal(currentModalId);
  }, [currentModalId]);

  if (!currentModalId) {
    return null;
  }

  const Component = MODALS[currentModalId];

  if (!Component) {
    return null;
  }

  if (currentModalId === PURCHASE_EDITOR_MODAL_ID) {
    return (
      <ContextProvider>
        <Component params={modalParams || {}} />
      </ContextProvider>
    );
  } else return <Component params={modalParams || {}} />;
};

export default Modals;

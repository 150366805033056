import lodash from 'lodash';
import choiceSetDiscountPlus from './choiceSetDiscountPlus';

export function adjustedNestedSelections(data: {
  max: number | undefined;
  individualMax: number | undefined;
  previousChoiceSelections: SDict<ChoiceWithQuantity[]>;
  targetChoiceSetId: string;
  targetChoice: ChoiceWithQuantity;
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig;
  decrease?: boolean;
  shouldClear?: boolean;
}): SDict<ChoiceWithQuantity[]> {
  const {
    max,
    individualMax,
    previousChoiceSelections,
    targetChoiceSetId,
    targetChoice,
    choiceSetDiscountConfig,
    decrease,
    shouldClear,
  } = data;

  if (choiceSetDiscountConfig) {
    const { useChoiceSetDiscountMap, choiceSetDiscountMap, discountPlu } =
      choiceSetDiscountConfig;
    if (!useChoiceSetDiscountMap && discountPlu) {
      if (targetChoice.id === discountPlu) return previousChoiceSelections;
    }
    if (useChoiceSetDiscountMap && choiceSetDiscountMap) {
      const { plus } = choiceSetDiscountPlus(choiceSetDiscountMap);
      if (plus.includes(targetChoice.id)) return previousChoiceSelections;
    }
  }

  const targetSelections = previousChoiceSelections[targetChoiceSetId];

  if (shouldClear) {
    return {
      ...previousChoiceSelections,
      [targetChoiceSetId]: previousChoiceSelections[targetChoiceSetId].filter(
        c => c.id !== targetChoice.id,
      ),
    };
  }

  const targetQuantity =
    lodash.sumBy(targetSelections, c => (c.id === targetChoice.id ? 1 : 0)) +
    (decrease ? -1 : 1);

  if (individualMax && targetQuantity > individualMax) {
    return previousChoiceSelections;
  }
  //decrease quantity
  if (decrease) {
    const index = targetSelections.findIndex(
      selection => selection.id === targetChoice.id,
    );

    if (index !== -1) {
      const newTargetsWithRemoved = [...targetSelections];
      newTargetsWithRemoved.splice(index, 1);
      return {
        ...previousChoiceSelections,
        [targetChoiceSetId]: newTargetsWithRemoved,
      };
    }
    return {
      ...previousChoiceSelections,
      [targetChoiceSetId]: targetSelections,
    };
  }

  // get currect selections array from purchase and add a copy of the choice id to the array

  const newTargetSelections = [
    ...(previousChoiceSelections[targetChoiceSetId] || []),
    targetChoice,
  ];

  if (!max) {
    return {
      ...previousChoiceSelections,
      [targetChoiceSetId]: newTargetSelections,
    };
  }

  // if we are going to be clipping the array,
  // removing the oldest selection (for a different choice)
  // feels most natural to users
  if (
    newTargetSelections.length > max &&
    newTargetSelections[0].id === targetChoice.id
  ) {
    const index = newTargetSelections.findIndex(
      selection => selection.id !== targetChoice.id,
    );
    if (index !== -1) {
      newTargetSelections.splice(index, 1);
    }
  }

  return {
    ...previousChoiceSelections,
    [targetChoiceSetId]: newTargetSelections.slice(-max),
  };
}

//nested choice selection
export default function adjustedSelections(
  max: number | undefined,
  individualMax: number | undefined,
  previousChoiceSelections: ChoiceSelections,
  targetChoiceSetId: string,
  targetChoiceId: string,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
  decrease?: boolean,
): ChoiceSelections {
  if (choiceSetDiscountConfig) {
    const { useChoiceSetDiscountMap, choiceSetDiscountMap, discountPlu } =
      choiceSetDiscountConfig;
    if (!useChoiceSetDiscountMap && discountPlu) {
      if (targetChoiceId === discountPlu) return previousChoiceSelections;
    }
    if (useChoiceSetDiscountMap && choiceSetDiscountMap) {
      const { plus } = choiceSetDiscountPlus(choiceSetDiscountMap);
      if (plus.includes(targetChoiceId)) return previousChoiceSelections;
    }
  }

  const targetSelections = previousChoiceSelections[targetChoiceSetId];
  const targetQuantity =
    lodash.sumBy(targetSelections, id =>
      // hack
      (typeof id === 'string' ? id : (id as Choice).id) === targetChoiceId
        ? 1
        : 0,
    ) + (decrease ? -1 : 1);

  if (individualMax && targetQuantity > individualMax) {
    return previousChoiceSelections;
  }

  //decrease quantity
  if (decrease) {
    const index = targetSelections.findIndex(
      selection =>
        (typeof selection === 'string'
          ? selection
          : (selection as Choice).id) === targetChoiceId,
    );

    const arr = [...targetSelections];
    if (index > -1) arr.splice(index, 1);

    return {
      ...previousChoiceSelections,
      [targetChoiceSetId]: arr,
    };
  }

  // get currect selections array from purchase and add a copy of the choice id to the array

  const newTargetSelections = [
    ...(previousChoiceSelections[targetChoiceSetId] || []),
    targetChoiceId,
  ];

  if (!max) {
    return {
      ...previousChoiceSelections,
      [targetChoiceSetId]: newTargetSelections,
    };
  }

  // if we are going to be clipping the array,
  // removing the oldest selection (for a different choice)
  // feels most natural to users

  if (
    newTargetSelections.length > max &&
    (typeof newTargetSelections[0] === 'string'
      ? newTargetSelections[0]
      : (newTargetSelections[0] as Choice).id) === targetChoiceId
  ) {
    const index = newTargetSelections.findIndex(
      selection =>
        (typeof selection === 'string'
          ? selection
          : (selection as Choice).id) !== targetChoiceId,
    );
    if (index !== -1) {
      newTargetSelections.splice(index, 1);
    }
  }

  return {
    ...previousChoiceSelections,
    [targetChoiceSetId]: newTargetSelections.slice(-max),
  };
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import getUnenhancedOpenPurchase from '../selectors/getUnenhancedOpenPurchase';

// MiM-friendly function to set the choice selections tree (any getopenpurchase calls should be updated automatically with validity due to the matcher logic)
export const setOpenPurchaseChoiceSelections = createAsyncThunk(
  '$setOpenPurchaseChoiceSelections',
  async (
    data: {
      newSelections: NestedChoiceSelections;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      const { newSelections } = data;

      if (!newSelections) {
        throw new Error(
          'newSelections required to replace old choiceSelections',
        );
      }

      const openPurchase = getUnenhancedOpenPurchase(
        getState() as EntireFrontendState,
      )!;

      const adjustedPurchase = {
        ...openPurchase,
        choiceSelections: newSelections,
      };

      return {
        purchase: adjustedPurchase,
      };
    } catch (e) {
      console.error('Set open purchase choice selections failed', { e });
      return rejectWithValue(e);
    }
  },
);

export default function purchaseInvalidForPointsSale(
  purchase: PurchaseWithTotals,
) {
  return purchase.choiceSets.some(({ choices }) => {
    const zeroPointsPriceChoiceExists = choices.some(
      // this checks the choiceSets in the purchase
      choice =>
        !!choice.quantity &&
        choice.basePointsPrice === 0 &&
        choice.baseMoneyPrice !== 0,
    );

    return zeroPointsPriceChoiceExists;
  });
}

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { OrderingOperations, OrderingSelectors, OrderingConstants } from 'polygon-ordering';
import combineStyles from '../utils/combineStyles';
import toDollarString from '../utils/toDollarString';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Modal from '../components/Modal';
import TouchableOpacity from '../components/TouchableOpacity';
import StandardButton from '../components/StandardButton';
import RadioCheck from '../components/RadioCheck';
import RedcatImage from '../components/RedcatImage';
import Text from '../components/Text';
import Energy from '../components/Energy';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DESKTOP_MODAL_TYPES } from '../constants';

export const UPSELL_MODAL = 'UPSELL_MODAL';
const { getEnabledUpsellByCheckout, getUpsellUnavailableDisplayType } = OrderingSelectors;
const { stageOpenPurchase, resetEnableUpsellByCheckout } = OrderingOperations;
const { UPSELL_ITEM_DISPLAY_TYPE } = OrderingConstants;

const UpsellModal: React.FC<{
  params: {
    items: (Item & { upsellAvailable: boolean })[];
    title: string;
    checkoutUpsell?: boolean;
  };
}> = ({ params }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobileDevice = useAppSelector(getDeviceTypeMobile);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const enableUpsellByCheckout = useAppSelector(getEnabledUpsellByCheckout);
  const upsellItemHideGrey = useAppSelector(getUpsellUnavailableDisplayType);
  const { checkoutUpsell } = params;
  useEffect(() => {
    if (!!checkoutUpsell && !!enableUpsellByCheckout) dispatch(resetEnableUpsellByCheckout(false));
  }, [checkoutUpsell]);
  const handleClick = (item: Item & { upsellAvailable: boolean }) => {
    const idx = selectedItems.findIndex(s => s === item.id);
    if (idx > -1) {
      setSelectedItems(selectedItems.filter(s => s !== item.id));
    } else {
      setSelectedItems([...selectedItems, item.id]);
    }
  };

  const handleButtonClick = () => {
    selectedItems.forEach(itemId => {
      //upSellStaging is used for handling purchase stage and modal display.
      //there will be no opurchase data in redux if add a upsell item to cart. the upsell item purchase data will be
      //generated in stageOpenPurchase rather than from openPuchase
      dispatch(stageOpenPurchase({ itemId, upSellStaging: true }));
    });
    dispatch(setCurrentModal(null));
  };
  const { items, title } = params;
  if (!items || !(items.length > 0)) return null;

  return (
    <Modal
      title={title}
      desktopContainerStyle={styles.modalDesktopContainer}
      headerContainerStyle={isMobileDevice ? { justifyContent: 'flex-end' } : undefined}
      closeButtonLabel={t('button.modalClose.dismiss')}
      desktopModalType={checkoutUpsell ? DESKTOP_MODAL_TYPES.CALLOUT : undefined}
      desktopMaxWidth={checkoutUpsell ? 420 : 600}
      desktopMinWidth={checkoutUpsell ? 420 : 400}
      mobileContainerStyle={styles.modalMobileContainer}
      titleStyle={checkoutUpsell ? { textAlign: 'center', paddingTop: 15 } : undefined}
    >
      <PerfectScrollbar style={styles.scrollable}>
        <div style={styles.upsellItems}>
          {(items as (Item & { upsellAvailable: boolean })[]).map(item => (
            <UpsellItem
              key={item.id}
              item={item}
              onClick={() => handleClick(item)}
              selected={selectedItems.includes(item.id)}
              isMobileDevice={isMobileDevice}
              availabilitydisplayType={upsellItemHideGrey}
            />
          ))}
        </div>
      </PerfectScrollbar>
      <div style={styles.button}>
        <StandardButton
          label={t(
            selectedItems.length > 0
              ? 'button.purchaseEditor.new'
              : checkoutUpsell
              ? 'button.continueCheckout'
              : 'button.continue',
          )}
          onClick={handleButtonClick}
          style={{
            maxWidth: isMobileDevice ? '100%' : '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </div>
    </Modal>
  );
};

const UpsellItem: React.FC<{
  item: Item & { upsellAvailable: boolean };
  onClick: (value: Item & { upsellAvailable: boolean }) => void;
  stockBalanceData?: StockBalanceData;
  selected: boolean;
  isMobileDevice?: boolean;
  availabilitydisplayType: number;
}> = ({
  item,
  onClick,
  stockBalanceData,
  selected,
  isMobileDevice,
  availabilitydisplayType = 1,
}) => {
  const { t } = useTranslation();
  const handleClick = (item: Item & { upsellAvailable: boolean }) => {
    onClick(item);
  };
  const unavailableDisplayType = !item.upsellAvailable ? availabilitydisplayType : undefined;
  return unavailableDisplayType === UPSELL_ITEM_DISPLAY_TYPE.HIDE ? null : (
    <TouchableOpacity
      onClick={() => handleClick(item)}
      style={combineStyles(
        styles.item,
        unavailableDisplayType === UPSELL_ITEM_DISPLAY_TYPE.GREYOUT && { opacity: 0.5 },
      )}
      disabled={unavailableDisplayType === UPSELL_ITEM_DISPLAY_TYPE.GREYOUT}
    >
      <div style={styles.itemDetails}>
        <div style={isMobileDevice ? styles.mobileImageSize : styles.desktopImageSize}>
          <RedcatImage
            alt={item.name}
            imagePath={item?.images?.default}
            size={40}
            imageStyle={styles.imageContainer}
            containerStyle={isMobileDevice ? styles.mobileImageSize : styles.desktopImageSize}
          />
        </div>
        <ItemDescription item={item} renderItem={() => renderUpsellItem(item)} />
      </div>
      <div style={styles.buttonContainer}>{<RadioCheck size={40} checked={selected} />}</div>
      <div
        style={combineStyles(styles.stockBalanceThreshold, { width: isMobileDevice ? 108 : 120 })}
      >
        {stockBalanceData?.stockBalanceThreshold && (
          <Text themeKey={stockBalanceData.stockBalanceThreshold} style={styles.stockBalanceText}>
            {t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
          </Text>
        )}
      </div>
    </TouchableOpacity>
  );
};

interface IProp<T> {
  item: T;
  renderItem: (item: T) => JSX.Element;
}
const ItemDescription = <T,>({ item, renderItem }: IProp<T>) => {
  return renderItem(item);
};

const renderUpsellItem = (item: Item & { upsellAvailable: boolean }) => {
  const priceString = item.baseMoneyPrice > 0 ? toDollarString(item.baseMoneyPrice) : '';
  const kilojoulesShowing = (item.kilojoules || 0) > 0 ? item.kilojoules : '';
  return (
    <div style={styles.description}>
      <Text themeKey="menuItemName" value={item.name} style={styles.name} />

      {!!priceString && <Text themeKey="menuItemPrice">{priceString}</Text>}

      {!!kilojoulesShowing && <Energy kilojouleCount={kilojoulesShowing} />}
    </div>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    padding: '50px 10px 10px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textTransform: 'capitalize',
  },
  mobileImageSize: { width: 60, height: 60 },
  desktopImageSize: { width: 108, height: 108 },
  mainContainer: {},
  upsellItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    padding: 30,
  },
  button: { paddingBottom: 10 },
  itemDetails: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
  },
  stockBalanceThreshold: {
    position: 'absolute',
    backgroundColor: 'white',
    textAlign: 'center',
  },
  scrollable: {
    overflowY: 'auto',
    flex: 1,
  },
  modalCheckoutDesktopContainer: {
    height: '100%',
    padding: 0,
    gap: 0,
  },
  modalCheckoutMobileContainer: {
    padding: 0,
    paddingTop: 15,
    gap: 0,
  },
  imageContainer: { borderRadius: '10%' },
};

export default UpsellModal;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const initialState: string[] = [];

const slice = createSlice({
  name: 'locationWhitelist',
  initialState,
  reducers: {
    setLocationWhitelist(state, action: PayloadAction<string[]>) {
      return action.payload || initialState;
    },
  },
});

export const { setLocationWhitelist } = slice.actions;

export default slice.reducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Row, Col } from 'reactstrap';
import Text from './Text';
import FormTextField from './FormTextField';

const NonMemberGiftCardFields: React.FC = () => {
  const { t } = useTranslation();
  const formik = useFormikContext<FormikFields>();

  const fields = [
    {
      name: 'FirstName',
      type: 'text',
      label: t('placeholder.firstName'),
      onChange: (e: any) => formik.setFieldValue('FirstName', e.target.value),
    },
    {
      name: 'LastName',
      type: 'text',
      label: t('placeholder.lastName'),
      onChange: (e: any) => formik.setFieldValue('LastName', e.target.value),
    },
    {
      name: 'Email',
      type: 'email',
      label: t('placeholder.email'),
      onChange: (e: any) => formik.setFieldValue('Email', e.target.value),
    },
    {
      name: 'ConfirmEmail',
      type: 'email',
      label: t('placeholder.confirmEmail'),
      onChange: (e: any) => formik.setFieldValue('ConfirmEmail', e.target.value),
    },
  ];

  return (
    <Row className=" mx-0 my-3">
      <Col className="p-0">
        <Text value={t('myGiftCardDetails')} style={{ fontSize: 15 }} themeKey="recipientDetails" />
        {fields.map(field => (
          <FormTextField
            key={field.name}
            name={field.name}
            type={field.type}
            label={field.label}
            onChange={field.onChange}
          />
        ))}
      </Col>
    </Row>
  );
};

export default NonMemberGiftCardFields;

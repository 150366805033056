import { combineReducers } from 'redux';

import buffer from './buffer';
import config from './config';
import currentOrder from './currentOrder';
import favouriteOrders from './favouriteOrders';
import historicalOrders from './historicalOrders';
import locations from './locations';
import member from './member';
import paymentGatewayTokens from './paymentGatewayTokens';
import shadowOrder from './shadowOrder';
import transactionHistory from './transactionHistory';
import keyOrderPropertyUpdateInProgress from './keyOrderPropertyUpdateInProgress';
import singleOrderDetails from './singleOrderDetails';
import menuFilterTags from './menuFilterTags';
import menuFilterPrice from './menuFilterPrice';
import tempTags from './tempTags';
import currentMenuBrandId from './currentMenuBrandId';
import menuSearchText from './menuSearchText';
import giftcardSurcharge from './giftcardSurcharge';
import feedback from './feedback';

import menu from './menu';
import cachedMenu from './cachedMenu';
import enableMultiplePayments from './enableMultiplePayments';

const rootReducer = combineReducers({
  buffer,
  config,
  currentOrder,
  favouriteOrders,
  historicalOrders,
  locations,
  member,
  paymentGatewayTokens,
  shadowOrder,
  transactionHistory,
  keyOrderPropertyUpdateInProgress,
  singleOrderDetails,
  menuFilterTags,
  menuFilterPrice,
  tempTags,
  currentMenuBrandId,
  menuSearchText,
  giftcardSurcharge,
  enableMultiplePayments,
  feedback,
});

export const localforageReducer = combineReducers({
  menu,
  cachedMenu,
});

export const cachedMenuReducer = combineReducers({
  cachedMenu,
});

export default rootReducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as
  | null
  | SDict<POSLocation>
  | SDict<POSLocationShort>;

const slice = createSlice({
  name: '$locations',
  initialState,
  reducers: {
    setLocations(
      state,
      action: PayloadAction<{ locations: SDict<POSLocation>; merge: boolean }>,
    ) {
      const { locations, merge } = action.payload;
      if (state === null || !merge) {
        return locations;
      } else {
        return {
          ...state,
          ...locations,
        } as SDict<POSLocation>;
      }
    },

    setReducedLocations: (
      state,
      action: PayloadAction<{ locationsShort: SDict<POSLocationShort> }>,
    ) => {
      const { locationsShort } = action.payload;

      if (!state || !Object.values(state).some(loc => loc._isFullData)) {
        return locationsShort as SDict<POSLocationShort>;
      }
    },
  },
});

export const { setLocations, setReducedLocations } = slice.actions;

export default slice.reducer;

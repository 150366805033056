import React from 'react';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import getShowMinExcludesSurchargeMessage from '../selectors/getShowMinExcludesSurchargeMessage';
import getMinimumOrderAmountNotMet from '../selectors/getMinimumOrderAmountNotMet';
import Text from './Text';
import OrderPropertySaleType from './OrderPropertySaleType';
import OrderOffer from './OrderOffer';
import Cart from './Cart';
import OrderMessages from './OrderMessages';
import { useAppSelector } from '../app/hooks';
import OrderNotes from './OrderNotes';
import Divider from '@mui/material/Divider';

const { useFormattedCurrency } = OrderingHooks;

const { getMinimumOrderInformation, getMaxOrderValue, getShowMaxValue } = OrderingSelectors;

interface IProps {
  hideKeyOrderProperties?: boolean;
  hideTotals?: boolean;
  hideSurcharges?: boolean;
  pointsVisible?: boolean;
  useMobileScreen?: boolean | null;
}

const OrderSummaryColumn: React.FC<IProps> = ({ hideKeyOrderProperties, useMobileScreen }) => {
  const { t } = useTranslation();
  const minimumOrderAmountNotMet = useAppSelector(getMinimumOrderAmountNotMet);
  const minimumOrderInformation = useAppSelector(getMinimumOrderInformation);
  const showMinExcludesSurchargeMessage = useAppSelector(getShowMinExcludesSurchargeMessage);
  const disableOffers = useAppSelector(state => state.config.disableOffers);
  const maxOrderValueRule = useAppSelector(getMaxOrderValue);
  const maxOrderAmountNotMet = useAppSelector(getShowMaxValue);
  const enableOrderNotes = useAppSelector(state => state.config.enableOrderNotes);

  const minimumAmount = lodash.get(minimumOrderInformation, 'amount', 0);
  const minimumAmountPrice = useFormattedCurrency({ cents: minimumAmount, hideZeroCents: true });
  const maxAmountPrice = useFormattedCurrency({
    cents: maxOrderValueRule?.MaximumOrder,
    hideZeroCents: true,
  });

  return (
    <div style={styles.container}>
      {!hideKeyOrderProperties && (
        <div style={{ marginBottom: 40, marginTop: 40, textAlign: 'center' }}>
          <div style={styles.cartTitleContainer}>
            <Text themeKey="sectionTitle">{t('title.cart')}</Text>

            {maxOrderAmountNotMet && (
              <Text themeKey="minimumOrderAmount" style={styles.minimumOrder}>
                {`${maxAmountPrice} ${t('maximumOrderAmountLabel')}${
                  showMinExcludesSurchargeMessage ? '*' : ''
                }`}
              </Text>
            )}

            {minimumOrderAmountNotMet && (
              <Text themeKey="minimumOrderAmount" style={styles.minimumOrder}>
                {`${minimumAmountPrice} ${t('minimumOrderAmountLabel')}${
                  showMinExcludesSurchargeMessage ? '*' : ''
                }`}
              </Text>
            )}
          </div>
          <OrderPropertySaleType />
        </div>
      )}

      <Cart />

      <OrderMessages />

      {!useMobileScreen && !disableOffers && (
        <div style={styles.offerSection}>
          <OrderOffer />
          {enableOrderNotes && <OrderNotes />}
          <Divider sx={{ mt: 2, mb: 2 }} />
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  control: {
    paddingTop: 15,
    paddingBottom: 15,
  },

  tableNumber: {
    width: 120,
  },

  cartTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
  },

  offerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  minimumOrder: {
    marginRight: 9,
  },

  deliveryDetailsSection: {
    marginBottom: 15,
  },

  offerSection: {
    margin: '1em 0',
  },
  container: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 9999,
  },
};

export default OrderSummaryColumn;

import { createSelector } from '@reduxjs/toolkit';
import getAllCategoryInfos from './getAllCategoryInfos';
import { checkCategoryAvailability } from '../utils/ordering/daypartMenuAvailability';

export default (id: string) =>
  createSelector([getAllCategoryInfos], (categories): [boolean, string] => {
    const category = (categories || []).find(cat => cat.id === id);
    if (!category) return [true, ''];
    return checkCategoryAvailability(category);
  });

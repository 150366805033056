import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import lodash from 'lodash';

const initialState = [] as number[];

const slice = createSlice({
  name: '$tempTags',
  initialState,
  reducers: {
    setTempTags(state, action: PayloadAction<number[] | null>) {
      if (action.payload === null) {
        return initialState;
      }

      return lodash.union(action.payload, initialState);
    },
    removeTempTags(state, action: PayloadAction<number[]>) {
      return state.filter(tag => !action.payload.includes(tag));
    },

    resetTempTags: () => initialState,
  },
});

export const { setTempTags, removeTempTags, resetTempTags } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';
import { resetOrder } from '../../actions/currentOrder';
import { produce } from 'immer';

const initialState = null as PaymentGatewayConfig | null;

const slice = createSlice({
  name: '$paymentGatewayConfig',
  initialState,
  reducers: {
    setPaymentGatewayConfig(
      state,
      action: PayloadAction<PaymentGatewayConfig | null>,
    ) {
      return action.payload;
    },
    setPaymentGatewayConfigPaymentMethods(state, action: PayloadAction<any>) {
      return produce(state, draftState => {
        if (draftState !== null)
          draftState.data.paymentMethods = action.payload;
      });
    },
  },

  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addCase(applyBufferData, (state, action) => {
        const { paymentGatewayConfig } = action.payload;
        return paymentGatewayConfig === undefined
          ? state
          : paymentGatewayConfig;
      });
  },
});

export const {
  setPaymentGatewayConfig,
  setPaymentGatewayConfigPaymentMethods,
} = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setDesiredDeliveryAddress } from './desiredDeliveryAddress';

const initialState = null as null | boolean;

const slice = createSlice({
  name: 'desiredDeliveryAddressNotFound',
  initialState,
  reducers: {
    setDesiredDeliveryAddressNotFound(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(setDesiredDeliveryAddress, (state, action) => {
      return initialState;
    });
  },
});

export const { setDesiredDeliveryAddressNotFound } = slice.actions;

export default slice.reducer;

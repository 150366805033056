import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | string;

const slice = createSlice({
  name: '$currentMenuBrandId',
  initialState,
  reducers: {
    setCurrentMenuBrandId(state, action: PayloadAction<string | null>) {
      return action.payload || initialState;
    },
  },
});

export const { setCurrentMenuBrandId } = slice.actions;

export default slice.reducer;

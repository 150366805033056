import { createAsyncThunk } from '@reduxjs/toolkit';
import lodash from 'lodash';
import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';
import debug from '../utils/debug';
import { logUser } from '../utils/analytics';
import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';
import { RootState } from '../store';
import { fetchProfile } from './fetchProfile';
import checkAuthTokenPresent from '../utils/checkAuthTokenPresent';
import { setCurrentModal } from './setCurrentModal';
import { fetchLoyaltyTiers } from './fetchLoyaltyTiers';

const { fetchMember, removeMember } = OrderingOperations;
const { getMember } = OrderingSelectors;

export const syncMember = createAsyncThunk(
  'syncMember',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    const authPresent = checkAuthTokenPresent();
    debug('syncing member', { authPresent });

    try {
      if (!authPresent) {
        debug('removing member');
        logUser(null);
        dispatch(removeMember());

        return;
      }

      dispatch(fetchProfile());
      dispatch(fetchLoyaltyTiers());

      const previousMemberData = getMember(getState() as RootState);

      await dispatch(fetchMember({ authenticationMethod: 'member' }));

      const newMemberData = getMember(getState() as RootState);

      const signedIn = !previousMemberData && newMemberData;
      const changedUser =
        previousMemberData && newMemberData && previousMemberData.id !== newMemberData.id;

      if ((signedIn || changedUser) && newMemberData) {
        logUser(newMemberData?.memberNumber, newMemberData);
      }

      const currentModalId = (getState() as RootState).currentModalId;

      // if (currentModalId === SIGN_IN_MODAL_ID) {
      //   dispatch(setCurrentModal(null));
      // }
    } catch (e) {
      debug(false, 'member sync failed', { result: e });
      return rejectWithValue(e);
    }
  },
);

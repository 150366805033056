import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as StockBalance[] | null;

const slice = createSlice({
  name: '$stockBalances',
  initialState,
  reducers: {
    setStockBalances(state, action: PayloadAction<StockBalance[]>) {
      return action.payload;
    },
  },
});

export const { setStockBalances } = slice.actions;
export default slice.reducer;

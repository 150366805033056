import { createSelector } from 'reselect';

import { OrderingSelectors } from 'polygon-ordering';

const { getLocation, getSaleType, getRootCategory } = OrderingSelectors;

export default createSelector(
  [getLocation, getSaleType, getRootCategory],
  (location, saleType, rootCategory) => {
    return Boolean(location && saleType && rootCategory);
  },
);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';

import Text from './Text';
import PaymentMethod from './PaymentMethod';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { useAppSelector } from '../app/hooks';

const { getMember, getUnavailableReasonsMemberRewards } = OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;

const PaymentMethodMemberRewards: React.FC = () => {
  const { t } = useTranslation();
  const member = useAppSelector(getMember);
  const unavailableReasonsMemberRewards = useAppSelector(getUnavailableReasonsMemberRewards);
  const rewardsBalance = useFormattedCurrency({
    dollars: Number(member?.pointsBalance), // in a rewards system the points balance is a decimal number equivalent to dollars
    hideSymbol: true,
  });

  if (!member) {
    return null;
  }

  const reason = unavailableReasonsMemberRewards[0];

  return (
    <PaymentMethod
      method={PAYMENT_METHOD.MEMBER_REWARDS}
      right={
        <Text themeKey="paymentMethodAvailableBalance">
          {t('rewardsAvailable') + rewardsBalance}
        </Text>
      }
      subtitle={
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {reason ? (
            <Text themeKey="paymentMethodUnavailableReason">
              {t(`paymentMethodUnavailableReason.${reason}`)}
            </Text>
          ) : (
            <Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>
          )}
        </div>
      }
    ></PaymentMethod>
  );
};

export default PaymentMethodMemberRewards;

import moment from 'moment';
// NOTE: takes and returns moment instances
export default function (
  earliestMoment: moment.Moment,
  latestMoment: moment.Moment,
  minuteStep: number = 1,
) {
  const approxEnd = latestMoment.clone().add(1, 'hour').startOf('hour');

  const results = [];

  let i = earliestMoment.clone().startOf('hour');

  while (i <= approxEnd) {
    results.push(i.clone());
    i = i.add(minuteStep, 'minute');
  }

  return results.filter(time => time >= earliestMoment && time <= latestMoment);
}

export function generateMomentsInRangeFDO(
  earliestMoment: moment.Moment,
  latestMoment: moment.Moment,
  minuteStep: number = 1,
  openingSession: any = undefined,
) {
  if (openingSession && openingSession.length > 0) {
    // multi opening session in one day. example 00:00 - 6:00, 12:00 - 23:00
    let results: moment.Moment[] = [];
    openingSession.forEach((session: { [key: string]: string }) => {
      const temp = [];
      const { start, end } = session;
      let i = moment(start).clone().startOf('hour');
      while (i <= moment(end)) {
        temp.push(i.clone());
        i = i.add(minuteStep, 'minute');
      }
      results = [...results, ...temp.filter(time => time >= moment(start) && time <= moment(end))];
    });
    return results;
  } else {
    const approxEnd = latestMoment.clone().add(1, 'hour').startOf('hour');

    const results = [];

    let i = earliestMoment.clone().startOf('hour');

    while (i <= approxEnd) {
      results.push(i.clone());
      i = i.add(minuteStep, 'minute');
    }

    return results.filter(time => time >= earliestMoment && time <= latestMoment);
  }
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../components/Text';
import GiftCardForm from '../components/GiftCardForm';

export const REPLACE_CARD_ROUTE = '/replace-card';

const ReplaceCardScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className=" my-2">
        <Text value={t('replaceCardInfo')} themeKey="loyaltyHeaderInfo" />
      </div>
      <GiftCardForm />
    </div>
  );
};

export default ReplaceCardScreen;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { applyBufferData } from '../../actions/buffer';
import { resetOrder } from '../../actions/currentOrder';

const initialState = null as Menu | null;

const slice = createSlice({
  name: '$menu',
  initialState,
  reducers: {
    setMenu(state, action: PayloadAction<Menu | null>) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addCase(applyBufferData, (state, action) => {
        const { menu } = action.payload;
        return menu === undefined ? state : menu;
      });
  },
});

export const { setMenu } = slice.actions;
export default slice.reducer;

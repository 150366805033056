import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';

const getMaxOrderValueRules = createSelector(
  [getCurrentOrder],
  order => order.maxOrderValueRules,
);

export default getMaxOrderValueRules;

import { CardCvcElementComponent } from '@stripe/react-stripe-js';
import { StripeElements, Stripe } from '@stripe/stripe-js/types/stripe-js';

export default function async({
  stripe,
  elements,
  CardCvcElement,
  onSuccess,
}: {
  stripe: Stripe | null;
  elements: StripeElements | null;
  CardCvcElement: CardCvcElementComponent;
  onSuccess(data: string): void;
}) {
  console.log('cvc check change');

  if (!stripe || !elements) {
    // Stripe.js has not yet loaded or CVC is not complete.
    // Make sure to disable form submission until Stripe.js has loaded.
    return;
  }

  try {
    const cvcElement = elements.getElement(CardCvcElement);

    if (cvcElement == null) {
      console.error("CVC element doesn't exist");
      return;
    }

    stripe.createToken('cvc_update', cvcElement).then(function (result) {
      // Handle result.error or result.token
      if (result.error) {
        // Handle error
        console.error(result.error.message);
      } else {
        // Send the token to your server for further processing
        const cvcUpdateToken = result.token.id;
        // Now you can use cvcUpdateToken to update the CVC on the server side
        // console.log('CVC Update Token:', cvcUpdateToken);
        onSuccess(cvcUpdateToken);
      }
    });
  } catch (error) {
    // Handle unexpected errors
    console.error(error.message);
  }
}

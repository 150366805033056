import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../operations';

const initialState = '' as ('pos' | 'email' | 'none') | '';

const slice = createSlice({
  name: '$receiptType',
  initialState,
  reducers: {
    setReceiptType(
      state,
      action: PayloadAction<('pos' | 'email' | 'none') | ''>,
    ) {
      return action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      return initialState;
    });
  },
});

export const { setReceiptType } = slice.actions;

export default slice.reducer;

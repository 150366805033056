import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'enableUpsellByItem',
  initialState: false,
  reducers: {
    setEnableUpsellByItem(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setEnableUpsellByItem } = slice.actions;
export default slice.reducer;

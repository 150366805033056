export const INVALID = 0;
export const VALID = 1;
export const VALID_IMPORTED = 2;

export default function validateGiftCard(
  giftCard: GiftCard,
  importedGiftCardRegex: string | null | undefined,
): number {
  const minLength = 10;
  const maxLength = 11;
  const pinLength = 6;

  const safeNumber = giftCard.number || '';
  const safePin = giftCard.pin || '';

  let result = VALID;

  if (safeNumber.length < minLength) {
    result = INVALID;
  }

  if (safeNumber.length > maxLength) {
    result = INVALID;
  }

  if (safePin.length !== pinLength) {
    result = INVALID;
  }

  if (
    result === INVALID &&
    importedGiftCardRegex &&
    new RegExp(importedGiftCardRegex).test(safeNumber)
  ) {
    result = VALID_IMPORTED;
  }

  return result;
}

import { createSelector } from 'reselect';

import { OrderingSelectors } from 'polygon-ordering';
import { RootState } from 'store';

const { getSaleType } = OrderingSelectors;

const getHideTimesForSaleTypes = (state: RootState) => state.config.hideTimesForSaleTypes;

export default createSelector(
  [getHideTimesForSaleTypes, getSaleType],
  (hideTimesForSaleTypes, saleType) => {
    if (hideTimesForSaleTypes == null) {
      return false;
    }

    if (hideTimesForSaleTypes.includes(saleType)) {
      return true;
    }

    return false;
  },
);

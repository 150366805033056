import { ORDER_TYPE_MODAL_ID } from '../modals/OrderTypeModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightSLine } from 'react-icons/ri';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import SubSection from './SubSection';
import { OrderingSelectors } from 'polygon-ordering';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import getThemeLookup from '../selectors/getThemeLookup';

const { getSaleType, getDeliveryAddressString, getTable } = OrderingSelectors;

const OrderTypeSummary: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const saleType = useAppSelector(getSaleType);
  const deliveryAddress = useAppSelector(getDeliveryAddressString);
  const table = useAppSelector(getTable);

  const isDelivery = saleType === SALE_TYPE.DELIVERY;
  const isPickupOrTakeAway =
    saleType === SALE_TYPE.CATERING ||
    saleType === SALE_TYPE.PICKUP ||
    saleType === SALE_TYPE.TAKE_AWAY ||
    saleType === SALE_TYPE.WEB_ORDERING;
  const isDineIn = saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER;

  const displayTableText = table ? `Ordering to table ${table}` : 'Please select a table';

  return (
    <div style={{ padding: '0 3.5em' }}>
      <SubSection
        title={
          isPickupOrTakeAway || isDelivery
            ? t(`saleType.${saleType}.name`)
            : isDineIn
            ? displayTableText
            : ''
        }
        subtitle={isDelivery ? deliveryAddress! : ''}
        action={() => {
          dispatch(
            setCurrentModal({
              modalId: ORDER_TYPE_MODAL_ID,
              params: { allowRedirect: false },
            }),
          );
        }}
        ActiveIcon={() => <RiArrowRightSLine color={p('modalSubSection', ['color']).color} />}
      />
    </div>
  );
};

export default OrderTypeSummary;

import { createSelector } from 'reselect';

import createShadowPurchaseFromPurchase from '../utils/ordering/createShadowPurchaseFromPurchase';

import getStagedPurchases from './getStagedPurchases';

export default createSelector([getStagedPurchases], enhancedStagedPurchases => {
  return (enhancedStagedPurchases || []).map(purchase =>
    createShadowPurchaseFromPurchase(
      purchase.item,
      purchase.quantity,
      purchase.choiceSets,
      purchase.sizeId,
    ),
  );
});

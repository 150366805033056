import { createSelector } from 'reselect';

import { OrderingSelectors } from 'polygon-ordering';

import getCurrentSavedCardTokenExists from './getCurrentSavedCardTokenExists';
import { RootState } from 'store';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';

const { getAvailablePaymentMethods } = OrderingSelectors;

const getCurrentPaymentMethod = (state: RootState) => state.currentPaymentMethod;

export default createSelector(
  [getCurrentPaymentMethod, getAvailablePaymentMethods, getCurrentSavedCardTokenExists],
  (currentPaymentMethod, availablePaymentMethods, currentSavedCardTokenExists) => {
    if (availablePaymentMethods.includes(PAYMENT_METHOD.FREE)) {
      return PAYMENT_METHOD.FREE;
    }

    if (currentPaymentMethod === PAYMENT_METHOD.SAVED_CARD && !currentSavedCardTokenExists) {
      return availablePaymentMethods[0];
    }

    if (
      (currentPaymentMethod && availablePaymentMethods.includes(currentPaymentMethod)) ||
      currentPaymentMethod === PAYMENT_METHOD.GIFT_CARD
    ) {
      return currentPaymentMethod;
    } else if (availablePaymentMethods.includes(PAYMENT_METHOD.CREDIT_CARD)) {
      return PAYMENT_METHOD.CREDIT_CARD;
    } else {
      return availablePaymentMethods[0];
    }
  },
);

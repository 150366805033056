import React from 'react';
import { RiInformationLine } from 'react-icons/ri';

import lodash from 'lodash';

import { OrderingSelectors } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';

import TouchableOpacity from './TouchableOpacity';
import { useAppSelector } from '../app/hooks';

const { getSelectedOffer } = OrderingSelectors;

export const ORDER_SUMMARY_MODAL_ID = 'ORDER_SUMMARY_MODAL_ID';

// TODO: work out how to show contents to the user without losing the current modal
// also needs to work on mobile

const OfferInfoButton: React.FC = () => {
  const p = useAppSelector(getThemeLookup);
  const selectedOffer = useAppSelector(getSelectedOffer);

  const selectedOfferDetails = lodash.get(selectedOffer, 'longDescription', null);

  return Boolean(selectedOfferDetails) ? (
    <TouchableOpacity onClick={() => alert(selectedOfferDetails)}>
      <RiInformationLine style={p('offerInfoButton', ['color', 'fontSize'])} />
    </TouchableOpacity>
  ) : null;
};

export default OfferInfoButton;

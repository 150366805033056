import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import Banner from './Banner';
import { useAppSelector } from '../app/hooks';

interface IProps {
  choiceSet: SizeChoiceSet;
  collapsed?: boolean;
  toggleCollapsed: (id: string) => void;
}

interface SizeChoiceSet {
  id: string;
  name?: string;
  free?: number;
  max?: number;
  min?: number;
  valid?: boolean;
  quantity: number;
  displayType?: string;
  freeMobile?: string;
  freeDesktop?: string;
  minDesktop?: string;
  maxDesktop?: string;
  minMobile?: string;
  maxMobile?: string;
  choices?: Item[];
}

interface Item {
  baseMoneyPrice?: number;
  id?: string;
  kilojoules?: number;
  name?: string;
  quantity?: number;
  sizeDescription?: string;
}

const ChoiceSetBanner: React.FC<IProps> = ({ choiceSet, collapsed, toggleCollapsed }) => {
  const { t } = useTranslation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const collapsableChoiceSetsEnabled = useAppSelector(
    state => state.config.collapsableChoiceSetsEnabled,
  );

  const hideFreeChoiceMessage = useAppSelector((state=>state.config.hideFreeChoiceMessage))

  if (choiceSet.displayType === 'checkbox') {
    return null; // checkboxes don't need a banner
  }

  const overMax = choiceSet.max && choiceSet.max < choiceSet.quantity;
  const underMin = choiceSet.min && choiceSet.min > choiceSet.quantity;

  let rightLabel = '';
  let leftLabel = '';

  if (choiceSet.free) {
    leftLabel = `${t(deviceTypeMobile ? 'choiceSet.freeMobile' : 'choiceSet.freeDesktop')}: ${
      choiceSet.free
    }`;
  }

  if (overMax) {
    leftLabel = `${t(deviceTypeMobile ? 'choiceSet.maxMobile' : 'choiceSet.maxDesktop')}: ${
      choiceSet.max
    }`;
  } else if (underMin) {
    leftLabel = `${t(deviceTypeMobile ? 'choiceSet.minMobile' : 'choiceSet.minDesktop')}: ${
      choiceSet.min
    }`;
  }

  if (choiceSet.max) {
    rightLabel = `${choiceSet.quantity} / ${choiceSet.max}`;
  } else {
    rightLabel = `${choiceSet.quantity}`;
  }

  let label = `${choiceSet.valid ? '' : '*'}${choiceSet.name}${
    choiceSet.free && !hideFreeChoiceMessage ? ` (${choiceSet.free} free)` : ''
  }`;

  const sideLabelStyle = overMax || underMin ? styles.emphasized : undefined;
  return (
    <Banner
      sticky
      label={label}
      LabelIconComponent={
        collapsableChoiceSetsEnabled ? (collapsed ? RiArrowDownSFill : RiArrowUpSFill) : undefined
      }
      leftLabel={leftLabel}
      rightLabel={rightLabel}
      leftLabelStyle={sideLabelStyle}
      rightLabelStyle={sideLabelStyle}
      containerStyle={styles.container}
      problem={!choiceSet.valid}
      onClick={collapsableChoiceSetsEnabled ? () => toggleCollapsed(choiceSet.id) : undefined}
    />
  );
};

const styles: Styles = {
  emphasized: {
    fontWeight: 900,
  },
  container: {
    zIndex: 5, // to be higher than textShadows inside sibling choice set components
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
};

export default ChoiceSetBanner;

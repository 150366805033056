import combineAddressParts from './combineAddressParts';

export default function combineTokenisedAddress({
  unit,
  streetNumber,
  streetName,
  suburb,
  state,
}: TokenisedAddress): string {
  return combineAddressParts([
    ...(streetNumber != null && unit != null
      ? [`${unit}/${streetNumber}`]
      : [unit, streetNumber]),
    streetName,
    suburb,
    state,
  ]);
}

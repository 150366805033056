import React from 'react';

import ChoiceSetCheckbox from './ChoiceSetCheckbox';
import ChoiceSetTags from './ChoiceSetTags';

const ChoiceSet: React.FC<{
  choiceSet: ValidatedChoiceSet;
  adjustOpenPurchaseChoice: (params: AdjustChoice) => void;
  hideNegativeIngredients: boolean;
}> = ({ choiceSet, adjustOpenPurchaseChoice, hideNegativeIngredients }) => {
  const Component =
    choiceSet.displayType && choiceSet.displayType === 'checkbox'
      ? ChoiceSetCheckbox
      : ChoiceSetTags;

  const choiceSetWithoutNegativePricedChoices = {
    ...choiceSet,
    choices: choiceSet.choices.filter((choice: Choice) => choice.baseMoneyPrice >= 0),
  };

  return (
    <Component
      adjustChoice={adjustOpenPurchaseChoice}
      choiceSet={hideNegativeIngredients ? choiceSetWithoutNegativePricedChoices : choiceSet}
    />
  );
};

export default ChoiceSet;

import { createSelector } from 'reselect';
import lodash from 'lodash';
import enhanceMenuNode from '../utils/ordering/enhanceMenuNode';
import flattenCategories from '../utils/ordering/flattenCategories';
import getMenu from './getMenu';
import { getFlattenCategories } from './config';
import getItems from './getItems';
import getLocation from './getLocation';

export default createSelector(
  [getMenu, getFlattenCategories, getItems, getLocation],
  (
    orderMenu,
    shouldFlattenCategories,
    allItems,
    location,
  ): Category | undefined => {
    if (!orderMenu || !allItems) {
      return undefined;
    }

    let enhanced = enhanceMenuNode(orderMenu.rootMenuNode, allItems);

    if (shouldFlattenCategories) {
      enhanced = {
        ...enhanced,
        subCategories: lodash.uniqBy(flattenCategories(enhanced), 'id'),
      };
    }

    const subCategoriesRepresentedByLocation = enhanced.subCategories.filter(
      cat => location?.brandIds.includes(cat?.brandId as string),
    );

    return {
      ...enhanced,
      subCategories: subCategoriesRepresentedByLocation.length
        ? subCategoriesRepresentedByLocation
        : enhanced.subCategories,
    };
  },
);

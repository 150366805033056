import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchParams } from '../utils/Api';
import { $getLocationId } from '../selectors/getLocationId';
import { RedcatApiHandler } from 'polygon-utils';
import { setMaxOrderValues } from '../reducers/currentOrder/maxOrderValueRules';

export const fetchMaxOrderValueRules = createAsyncThunk(
  '$fetchMaxOrderValueRules',
  async (_data: undefined, { rejectWithValue, getState, dispatch }) => {
    try {
      const locationId = $getLocationId(getState() as EntireFrontendState);

      const params: FetchParams = {
        path: `/api/v1/max_order_total/order/${locationId}`,
        method: 'GET',
      };

      const response = await RedcatApiHandler.fetch(params);
      dispatch(setMaxOrderValues(response.data));
    } catch (e) {
      console.warn('Fetch sale status failed', { e });
      return rejectWithValue(e);
    }
  },
);

import base64 from 'base-64';
import { XMLParser, XMLBuilder, XMLValidator } from 'fast-xml-parser';

interface paymentSenseClient {
  configurePaymentSense: (
    hostAddress: string,
    paymentEnvironment: string,
    apiKey: string,
    terminalId: string,
    eposIdentifier?: string
  ) => void;

  // TODO
  initiatePurchase: (
    requestParams: any, //PaymentSenseInitiatePurchaseParams,
    transactionCallbacks: any //TyroTransactionCallbacks
  ) => void;
  // TODO
  // initiateRefund: (
  //   requestParams: TyroInitiateTransactionParams,
  //   transactionCallbacks: TyroTransactionCallbacks
  // ) => void;
  // TODO
  // cancelCurrentTransaction: (question?: TyroQuestion) => void;
  cancelTxn: (endpoint: string) => void;
}

function generateRandomString(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYabcdefghijklmnopqrstuvwxy';

  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

function generateRef() {
  const dateTime: string = new Date().toISOString();
  const ref: string =
    generateRandomString(18) +
    '' +
    dateTime.split('T')[0].replace(/[-]/g, '') +
    'z';
  // eslint-disable-next-line no-useless-escape
  dateTime.split('T')[1].replace(/[:\.]/g, '');
  return ref;
}

export class Windcave {
  config?: WindcaveConfig;
  interfaceDir?: string;
  debugLogs?: boolean;
  loggingHandler: any;

  private authHeader?: string;
  private userName?: string;
  private apiKey?: string;
  private terminalId?: string;
  private endpoint?: string;
  private txnRef?: string;
  private txnAmount?: number;

  private txnData?: string;
  private response?: string;

  private currentClient?: paymentSenseClient;
  private receipts?: string[][];
  private transactionInProgress?: boolean;
  private requestedSignature?: boolean;
  private txnStarted?: boolean;

  // Todo import utils
  // private loggingHandler?: LoggingHandler;

  constructor(
    config?: WindcaveConfig,
    interfaceDir?: string,
    debugLogs?: boolean,
    loggingHandler?: any //Probably not needed
  ) {
    if (config) {
      this.config = config;
    }

    if (interfaceDir) {
      this.interfaceDir = interfaceDir;
    }

    if (debugLogs) {
      this.debugLogs = debugLogs;
    }

    if (loggingHandler) {
      this.loggingHandler = loggingHandler;
    }
  }

  // static async configurePaymentSense(
  async configurePaymentProvider(
    userName: string,
    terminalId: string,
    paymentEnvironment: string,
    apiKey: string
  ) {
    console.log(userName, terminalId, paymentEnvironment, apiKey);
    let endpoint = null;
    const payEnv = paymentEnvironment?.toLowerCase();
    if (payEnv === 'prod' || payEnv === 'production') {
      endpoint = 'https://sec.windcave.com/hit/pos.aspx';
    } else if (payEnv === 'test' || payEnv === 'simulator') {
      endpoint = 'https://uat.windcave.com/hit/pos.aspx';
    } else {
      endpoint = 'https://uat.windcave.com/hit/pos.aspx';
    }

    if (payEnv === undefined) {
      endpoint = 'https://uat.windcave.com/hit/pos.aspx';
    }

    console.log(endpoint, payEnv);

    this.terminalId = terminalId;
    this.endpoint = endpoint;
    this.userName = userName;
    this.apiKey = apiKey;
    this.authHeader = 'Basic ' + base64.encode(userName + ':' + apiKey);

    // 1. Build the xml
    const jsData = [
      {
        Scr: [
          {
            TxnType: [
              {
                '#text': 'PinpadDisplay',
              },
            ],
          },
          {
            Station: [
              {
                '#text': this.terminalId,
              },
            ],
          },
          {
            CmdSeq: [
              {
                '#text': '100',
              },
            ],
          },
          {
            PromptId: [
              {
                '#text': 201,
              },
            ],
          },
          {
            Timeout: [
              {
                '#text': 10,
              },
            ],
          },
        ],
        ':@': {
          '@_action': 'doScrHIT',
          '@_user': this.userName,
          '@_key': this.apiKey,
        },
      },
    ];
    const xmlBuildOptions = {
      processEntities: false,
      format: true,
      ignoreAttributes: false,
      preserveOrder: true,
    };
    const builder = new XMLBuilder(xmlBuildOptions);
    const xmlBody = builder.build(jsData);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: 'Basic ' + base64.encode(userName + ':' + apiKey),
      },
      body: xmlBody,
    })
      .then((response) => response.text())
      .then((data) => {
        // 3. Make a JS object from XML
        const XMLParserOptions = {
          ignoreAttributes: false,
          // parseAttributeValue: true,
          allowBooleanAttributes: true,
        };
        const parser = new XMLParser(XMLParserOptions);
        const output = parser.parse(data);
        // console.log({ output });
        return output;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async initWindcaveSale(
    amount: number,
    productInfo: any,
    // currency?: string,
    logStuff?: (stringData: string) => void
  ) {
    try {
      const { vendor, name, version, deviceId } = productInfo;
      // 1. Build the xml
      const jsData = [
        {
          Scr: [
            {
              Amount: [
                {
                  '#text': amount,
                },
              ],
            },
            {
              Cur: [
                {
                  '#text': 'AUD', //TODO: support multi currency
                },
              ],
            },
            {
              TxnType: [
                {
                  '#text': 'Purchase',
                },
              ],
            },
            {
              Station: [
                {
                  '#text': this.terminalId,
                },
              ],
            },
            {
              TxnRef: [
                {
                  '#text': generateRef(),
                },
              ],
            },
            {
              DeviceId: [
                {
                  '#text': deviceId,
                },
              ],
            },
            {
              PosName: [
                {
                  '#text': name,
                },
              ],
            },
            {
              PosVersion: [
                {
                  '#text': version,
                },
              ],
            },
            {
              VendorId: [
                {
                  '#text': vendor,
                },
              ],
            },
            {
              MyRef: [
                {
                  '#text': 'MyReference',
                },
              ],
            },
          ],
          ':@': {
            '@_action': 'doScrHIT',
            '@_user': this.userName,
            '@_key': this.apiKey,
          },
        },
      ];
      const xmlBuildOptions = {
        processEntities: false,
        format: true,
        ignoreAttributes: false,
        preserveOrder: true,
      };
      const builder = new XMLBuilder(xmlBuildOptions);
      const xmlBody = builder.build(jsData);

      this.txnAmount = amount;
      logStuff && logStuff(JSON.stringify(xmlBody));
      const headers = new Headers();
      headers.append('Authorization', this.authHeader!);

      const now = new Date();
      console.log('Before fetch -->', now.toLocaleTimeString('en-US'));

      console.log('Init Sale..', this.endpoint);
      // 2. Send it to the Api
      const saleResponse = await fetch(this.endpoint!, {
        method: 'POST',
        headers: headers,
        body: xmlBody,
      });

      const apiCall = new Date();
      console.log('After fetch --> ', apiCall.toLocaleTimeString('en-US'));

      const data = await saleResponse.text();

      logStuff && logStuff(JSON.stringify(data));
      const XMLParserOptions = {
        ignoreAttributes: false,
        // parseAttributeValue: true,
        allowBooleanAttributes: true,
      };
      const parser = new XMLParser(XMLParserOptions);
      const saleReponse = parser.parse(data);
      this.txnRef = saleReponse?.Scr?.TxnRef;

      const result = new Date();
      console.log('Processing data --> ', result.toLocaleTimeString('en-US'));

      return saleReponse;
    } catch (err) {
      console.log(err);
    }
  }

  // 3. Query transaction Call
  async getTxn(ref?: string) {
    try {
      // 1. Build the xml
      const jsData = [
        {
          Scr: [
            {
              TxnType: [
                {
                  '#text': 'Status',
                },
              ],
            },
            {
              Station: [
                {
                  '#text': this.terminalId,
                },
              ],
            },
            {
              TxnRef: [
                {
                  '#text': this.txnRef,
                },
              ],
            },
          ],
          ':@': {
            '@_action': 'doScrHIT',
            '@_user': this.userName,
            '@_key': this.apiKey,
          },
        },
      ];
      const xmlBuildOptions = {
        processEntities: false,
        format: true,
        ignoreAttributes: false,
        preserveOrder: true,
      };
      const builder = new XMLBuilder(xmlBuildOptions);
      const xmlBody = builder.build(jsData);

      const headers = new Headers();
      headers.append('Authorization', this.authHeader!);

      const txnStatus = await fetch(this.endpoint!, {
        method: 'POST',
        headers: headers,
        body: xmlBody,
      });

      const data = await txnStatus.text();
      const XMLParserOptions = {
        ignoreAttributes: false,
        // parseAttributeValue: true,
        allowBooleanAttributes: true,
      };
      const parser = new XMLParser(XMLParserOptions);
      const statusResponse = parser.parse(data);
      this.txnRef = statusResponse?.Scr?.TxnRef;

      return statusResponse;
    } catch (err) {
      console.log(err);
    }
  }

  async signatureVerification(verified: boolean, txnRef?: string) {
    try {
      // 1. Build the xml
      const jsData = [
        {
          Scr: [
            {
              TxnType: [
                {
                  '#text': 'UI',
                },
              ],
            },
            {
              Station: [
                {
                  '#text': this.terminalId,
                },
              ],
            },
            {
              UiType: [
                {
                  '#text': 'Bn',
                },
              ],
            },
            {
              Name: [
                {
                  '#text': 'B2',
                },
              ],
            },
            {
              Val: [
                {
                  '#text': 'NO',
                },
              ],
            },
            {
              TxnRef: [
                {
                  '#text': txnRef ? txnRef : this.txnRef,
                },
              ],
            },
          ],
          ':@': {
            '@_action': 'doScrHIT',
            '@_user': this.userName,
            '@_key': this.apiKey,
          },
        },
      ];
      const xmlBuildOptions = {
        processEntities: false,
        format: true,
        ignoreAttributes: false,
        preserveOrder: true,
      };
      const builder = new XMLBuilder(xmlBuildOptions);
      const xmlBody = builder.build(jsData);

      const headers = new Headers();
      headers.append('Authorization', this.authHeader!);
      return await fetch(this.endpoint!, {
        method: 'POST',
        headers: headers,
        body: xmlBody,
      })
        .then((response) => response.text())
        .then((data) => {
          // 3. Make a JS object from XML
          const XMLParserOptions = {
            ignoreAttributes: false,
            // parseAttributeValue: true,
            allowBooleanAttributes: true,
          };
          const parser = new XMLParser(XMLParserOptions);
          const output = parser.parse(data);
          // console.log('Status:', output?.Scr?.DL1);
          return output;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  }

  // 4. Refund??
}

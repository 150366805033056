import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

const getDeliveryAddress = createSelector(
  [getCurrentOrder],
  order => order.deliveryAddress,
);

export default getDeliveryAddress;

export const $getDeliveryAddress = createSelector(
  [getDeliveryAddress, getBuffer],
  (orderDeliveryAddress, buffer) => {
    if (buffer.deliveryAddress != null) {
      return buffer.deliveryAddress;
    }

    return orderDeliveryAddress;
  },
);

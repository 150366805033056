import { createSelector } from 'reselect';

import { OrderingSelectors } from 'polygon-ordering';

import getDisplayableLocations from './getDisplayableLocations';

const { getEnabledSaleTypes } = OrderingSelectors;

export default createSelector(
  [getDisplayableLocations, getEnabledSaleTypes],
  (displayableLocations, enabledSaleTypes) =>
    enabledSaleTypes.filter(saleType =>
      displayableLocations.some(location => location.supportedSaleTypes.includes(saleType)),
    ),
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStagedPurchases, getOffers } from '../selectors';
import { selectOffer } from './selectOffer';
import moment from 'moment';
import { filterMap } from '../utils/misc';
import isOfferValidForPurchases from '../utils/ordering/isOfferValidForPurchases';
import getItems from '../selectors/getItems';

export const applyAutomaticOffer = createAsyncThunk(
  '$applyAutomaticOffer',
  async (data: {}, { dispatch, getState, rejectWithValue }) => {
    const allItems = getItems(getState() as EntireFrontendState) ?? {};

    const {
      offerId: alreadySelectedOfferId,
      purchaseId: omittedPurchaseId,
      userAdded: userAddedExistingOffer,
    } = (getState() as EntireFrontendState).ordering.currentOrder.selectedOffer;
    const offers = getOffers(getState() as EntireFrontendState);
    const stagedPurchases = getStagedPurchases(
      getState() as EntireFrontendState,
    );

    const processedPurchases = filterMap(stagedPurchases, p => {
      if (p.id === omittedPurchaseId) {
        if (p.quantity <= 1) {
          return;
        } else {
          return { ...p, quantity: p.quantity - 1 };
        }
      } else {
        return p;
      }
    });

    const processedOffers = offers
      .filter(
        // important bit of logic: basically only look at offers which are enforced, or automatic but only if the user hasn't added a coupon manually
        // (o.automatic && (!alreadySelectedOfferId || !userAddedExistingOffer)),
        // changed my mind, if a user removes a coupon then the "userAdded" field will be true without an alreadyselectedoffer present so both being there isn't correct
        o => o.enforced || (o.automatic && !userAddedExistingOffer),
        //  && o.id !== alreadySelectedOfferId
      )
      .sort((a, b) => {
        if (a.enforced && !b.enforced) {
          return -1;
        } else if (!a.enforced && b.enforced) {
          return 1;
        } else {
          const aExpiry = moment(a.expiryDate);
          const bExpiry = moment(b.expiryDate);
          return aExpiry.diff(bExpiry);
        }
      });

    // get which automatic coupons are valid for the current cart:
    // for each [...(enforced in order of expiry), ...(automatic in order of expiry)]:
    //  if valid given current cart:
    //   apply the coupon and break from loop

    let switchOfferToNull = !userAddedExistingOffer;
    for (const offer of processedOffers) {
      if (isOfferValidForPurchases(offer, processedPurchases, allItems)) {
        dispatch(selectOffer({ offerId: offer.id, userAdded: false }));
        switchOfferToNull = false;
        break;
      }
    }

    if (switchOfferToNull) {
      dispatch(selectOffer({ offerId: null, userAdded: false }));
    }
  },
);

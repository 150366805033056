import { createAsyncThunk } from '@reduxjs/toolkit';
import debug from '../utils/debug';
import { OrderingOperations } from 'polygon-ordering';
import history from '../history';
import { INITIAL_SCREEN_ROUTE } from '../hooks/useRoutes';

const { setPickupTime } = OrderingOperations;

export const adjustLocation = createAsyncThunk(
  'adjustLocation',
  async (
    data: {
      locationId?: number;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { locationId } = data;
    debug('adjusting location', { locationId });

    try {
      history.push(INITIAL_SCREEN_ROUTE);
      dispatch(setPickupTime(null));
    } catch (e) {
      console.error('Adjust location thunk failed', e);
      return rejectWithValue(e);
    }
  },
);

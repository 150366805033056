import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';
import { fetchDeliveryEstimate } from '../../thunks/fetchDeliveryEstimate';

const initialState = false;

const slice = createSlice({
  name: '$deliveryEstimateInProgress',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetOrder, (state, action) => {
        return initialState;
      })
      .addCase(fetchDeliveryEstimate.pending, () => {
        return true;
      })
      .addMatcher(
        isAnyOf(
          fetchDeliveryEstimate.rejected,
          fetchDeliveryEstimate.fulfilled,
        ),
        () => {
          return false;
        },
      );
  },
});

export default slice.reducer;

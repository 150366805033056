import { createSelector } from 'reselect';

import createCartItemFromPurchase from '../utils/ordering/createCartItemFromPurchase';

import getStagedPurchases from './getStagedPurchases';
import getRootCategory from './getRootCategory';
import getBoostIngredients from './getBoostIngredients';
import { getChoiceOrderingMethod, getBrands } from './config';

export default createSelector(
  [
    getStagedPurchases,
    getChoiceOrderingMethod,
    getBrands,
    getRootCategory,
    getBoostIngredients,
  ],
  (
    enhancedStagedPurchases,
    choiceOrderingMethod,
    brands,
    rootCategory,
    boostIngredients,
  ) => {
    if (choiceOrderingMethod === null) {
      return [];
    }

    return (enhancedStagedPurchases || []).map(p => ({
      mimCategoryId: p.mimCategoryId,
      ...createCartItemFromPurchase(
        p,
        choiceOrderingMethod,
        brands,
        rootCategory,
        boostIngredients,
      ),
    }));
  },
);

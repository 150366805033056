import React from 'react';

const PoweredByDoordashDriveBadge: React.FC = () => (
  <svg width="166px" height="14px" viewBox="0 0 166 14" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1.000000, -6.000000)">
        <g>
          <g
            transform="translate(83.000000, 8.000000)"
            fill="#FF3008"
            fillRule="evenodd"
            strokeWidth="1"
          >
            <path
              d="M16.6881864,2.3943662 C15.9194676,0.943661972 14.4239601,0.0422535211 12.7886855,0.0422535211 L0.461231281,0.0422535211 C0.223627288,0.0422535211 0.0419301165,0.23943662 0.0419301165,0.478873239 C0.0419301165,0.591549296 0.0838602329,0.704225352 0.167720466,0.774647887 L2.85124792,3.49295775 C3.08885191,3.73239437 3.41031614,3.87323944 3.74575707,3.87323944 L12.4392679,3.87323944 C13.0542429,3.87323944 13.571381,4.36619718 13.571381,4.98591549 C13.571381,5.6056338 13.0821963,6.12676056 12.4672213,6.12676056 L6.47121464,6.12676056 C6.23361065,6.12676056 6.05191348,6.32394366 6.05191348,6.56338028 C6.05191348,6.67605634 6.09384359,6.78873239 6.17770383,6.85915493 L8.84725458,9.57746479 C9.08485857,9.81690141 9.4063228,9.95774648 9.74176373,9.95774648 L12.4532446,9.95774648 C15.9893511,9.95774648 18.6589018,6.15492958 16.6881864,2.3943662"
              id="Path"
              fillRule="nonzero"
            ></path>{' '}
            <path
              d="M22.5723794,2.83098592 L22.5723794,7.16901408 L23.6765391,7.16901408 C24.8505824,7.15492958 25.7870216,6.18309859 25.7730449,5 C25.8009983,3.84507042 24.8925125,2.87323944 23.7464226,2.83098592 C23.7184692,2.83098592 23.7044925,2.83098592 23.6765391,2.83098592 L22.5723794,2.83098592 Z M23.6765391,1.47887324 C25.7311148,1.47887324 27.2825291,3.04225352 27.2825291,5 C27.2825291,6.95774648 25.7311148,8.53521127 23.6765391,8.53521127 L21.2445923,8.53521127 C21.1467554,8.53521127 21.0768719,8.45070423 21.0768719,8.36619718 L21.0768719,1.66197183 C21.0768719,1.56338028 21.1607321,1.49295775 21.2445923,1.49295775 L23.6765391,1.47887324 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>{' '}
            <path
              d="M32.5517471,7.32394366 C33.8236273,7.32394366 34.8579035,6.29577465 34.8579035,5.01408451 C34.8579035,3.73239437 33.837604,2.69014085 32.5657238,2.69014085 C31.2938436,2.69014085 30.2595674,3.71830986 30.2595674,5 C30.2595674,6.28169014 31.2938436,7.30985915 32.5517471,7.32394366 M32.5517471,1.32394366 C34.7041597,1.32394366 36.353411,3 36.353411,5 C36.353411,7.01408451 34.7041597,8.67605634 32.5517471,8.67605634 C30.3993344,8.67605634 28.7640599,7.01408451 28.7640599,5 C28.7640599,2.98591549 30.4133111,1.32394366 32.5517471,1.32394366"
              id="Shape"
              fillRule="nonzero"
            ></path>{' '}
            <path
              d="M41.6086522,7.32394366 C42.8805324,7.32394366 43.9008319,6.28169014 43.9008319,5 C43.9008319,3.71830986 42.8665557,2.69014085 41.5946755,2.69014085 C40.3227953,2.69014085 39.3024958,3.73239437 39.3024958,5 C39.3024958,6.28169014 40.336772,7.32394366 41.6086522,7.32394366 M41.6086522,1.32394366 C43.7610649,1.32394366 45.4103161,2.98591549 45.4103161,5 C45.4103161,7.01408451 43.7470882,8.67605634 41.6086522,8.67605634 C39.4702163,8.67605634 37.8069884,7.01408451 37.8069884,5 C37.8069884,2.98591549 39.4562396,1.32394366 41.6086522,1.32394366"
              id="Shape"
              fillRule="nonzero"
            ></path>{' '}
            <path
              d="M50.4419301,2.83098592 L48.8905158,2.83098592 L48.8905158,4.69014085 L50.4419301,4.69014085 C50.9450915,4.71830986 51.3643927,4.32394366 51.3923461,3.81690141 C51.3923461,3.8028169 51.3923461,3.78873239 51.3923461,3.78873239 C51.4063228,3.28169014 51.014975,2.84507042 50.5118136,2.83098592 C50.4838602,2.83098592 50.4559068,2.83098592 50.4419301,2.83098592 M47.3950083,1.66197183 C47.3950083,1.56338028 47.4788686,1.49295775 47.5627288,1.49295775 L50.4838602,1.49295775 C51.8815308,1.49295775 52.8878536,2.52112676 52.8878536,3.81690141 C52.9018303,4.69014085 52.4126456,5.49295775 51.6439268,5.88732394 L52.9856905,8.28169014 C53.0415973,8.36619718 53.0136439,8.46478873 52.9437604,8.52112676 C52.915807,8.54929577 52.8738769,8.54929577 52.8319468,8.54929577 L51.6439268,8.54929577 C51.5740433,8.54929577 51.5181364,8.50704225 51.490183,8.45070423 L50.1903494,6.07042254 L48.8905158,6.07042254 L48.8905158,8.35211268 C48.8905158,8.45070423 48.8066556,8.52112676 48.7227953,8.52112676 L47.5906822,8.52112676 C47.4928453,8.52112676 47.4229617,8.43661972 47.4229617,8.35211268 L47.3950083,1.66197183 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>{' '}
            <path
              d="M56.8013311,2.87323944 L56.8013311,7.21126761 L57.9054908,7.21126761 C59.0795341,7.1971831 60.0159734,6.22535211 60.0019967,5.04225352 C60.0299501,3.88732394 59.1354409,2.91549296 57.9753744,2.87323944 C57.947421,2.87323944 57.9194676,2.87323944 57.9054908,2.87323944 L56.8013311,2.87323944 Z M57.8915141,1.50704225 C59.9460899,1.50704225 61.4975042,3.07042254 61.4975042,5.04225352 C61.4975042,7.01408451 59.9460899,8.57746479 57.8915141,8.57746479 L55.4735441,8.57746479 C55.3757072,8.57746479 55.3058236,8.49295775 55.3058236,8.4084507 L55.3058236,1.69014085 C55.3058236,1.5915493 55.3896839,1.52112676 55.4735441,1.52112676 L57.8915141,1.50704225 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>{' '}
            <path
              d="M66.0678869,3.23943662 L65.2153078,5.56338028 L66.9204659,5.56338028 L66.0678869,3.23943662 Z M64.7261231,6.85915493 L64.1670549,8.4084507 C64.1391015,8.47887324 64.069218,8.53521127 63.9993344,8.53521127 L62.7973378,8.53521127 C62.6995008,8.54929577 62.6156406,8.47887324 62.6016639,8.38028169 C62.6016639,8.35211268 62.6016639,8.32394366 62.6156406,8.29577465 L65.2292845,1.6056338 C65.2572379,1.53521127 65.3271215,1.47887324 65.397005,1.49295775 L66.7527454,1.49295775 C66.8366057,1.49295775 66.9064892,1.53521127 66.9204659,1.6056338 L69.5341098,8.29577465 C69.5760399,8.38028169 69.5341098,8.49295775 69.4502496,8.53521127 C69.4222962,8.54929577 69.3943428,8.54929577 69.3663894,8.54929577 L68.1643927,8.54929577 C68.0805324,8.54929577 68.0106489,8.50704225 67.9966722,8.42253521 L67.437604,6.87323944 L64.7261231,6.87323944 L64.7261231,6.85915493 Z"
              id="Shape"
              fillRule="nonzero"
            ></path>{' '}
            <path
              d="M70.9317804,3.38028169 C70.9317804,2.26760563 71.8821963,1.30985915 73.3777038,1.30985915 C74.1883527,1.29577465 74.9570715,1.5915493 75.5580699,2.14084507 C75.6279534,2.21126761 75.6279534,2.32394366 75.5720466,2.3943662 C75.5720466,2.3943662 75.5720466,2.3943662 75.5580699,2.4084507 L74.887188,3.08450704 C74.8173045,3.15492958 74.7054908,3.15492958 74.6356073,3.08450704 C74.3141431,2.78873239 73.9088186,2.63380282 73.4755408,2.61971831 C72.8745424,2.61971831 72.4272879,2.97183099 72.4272879,3.38028169 C72.4272879,4.69014085 75.977371,3.92957746 75.977371,6.45070423 C75.977371,7.73239437 75.0129784,8.69014085 73.321797,8.69014085 C72.371381,8.70422535 71.4628952,8.33802817 70.7920133,7.66197183 C70.7221298,7.5915493 70.7221298,7.47887324 70.7780366,7.4084507 C70.7780366,7.4084507 70.7780366,7.4084507 70.7920133,7.3943662 L71.4349418,6.74647887 C71.5048253,6.67605634 71.6166389,6.67605634 71.6865225,6.74647887 C72.0918469,7.14084507 72.6509151,7.36619718 73.2099834,7.38028169 C73.9647255,7.38028169 74.4539101,6.97183099 74.4539101,6.46478873 C74.4678869,5.15492958 70.9317804,5.91549296 70.9317804,3.38028169"
              id="Path"
              fillRule="nonzero"
            ></path>{' '}
            <path
              d="M82.4206323,1.64788732 L82.4206323,4.29577465 L79.4156406,4.29577465 L79.4156406,1.64788732 C79.4156406,1.54929577 79.3317804,1.47887324 79.2479201,1.47887324 L78.115807,1.47887324 C78.01797,1.47887324 77.9480865,1.56338028 77.9480865,1.64788732 L77.9480865,8.33802817 C77.9480865,8.43661972 78.0319468,8.50704225 78.115807,8.50704225 L79.2479201,8.50704225 C79.3457571,8.50704225 79.4156406,8.42253521 79.4156406,8.33802817 L79.4156406,5.64788732 L82.4206323,5.64788732 L82.4206323,8.33802817 C82.4206323,8.43661972 82.5044925,8.50704225 82.5883527,8.50704225 L83.7204659,8.50704225 C83.8183028,8.50704225 83.8881864,8.42253521 83.8881864,8.33802817 L83.8881864,1.64788732 C83.8881864,1.54929577 83.8043261,1.47887324 83.7204659,1.47887324 L82.5883527,1.47887324 C82.4905158,1.47887324 82.4206323,1.54929577 82.4206323,1.64788732 Z"
              id="Path"
              fillRule="nonzero"
            ></path>
          </g>{' '}
          <text
            fontFamily=".AppleSystemUIFont"
            fontSize="14"
            fontWeight="normal"
            line-spacing="24"
            fill="#7C7E80"
          >
            <tspan x="0" y="16">
              Powered by
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export default PoweredByDoordashDriveBadge;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: '$internationalMobileAllowed',
  initialState,
  reducers: {
    setInternationalMobileAllowed(state, action: PayloadAction<boolean>) {
      return Boolean(action.payload);
    },
  },
});

export const { setInternationalMobileAllowed } = slice.actions;
export default slice.reducer;

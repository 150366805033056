import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'orderingCurrency',
  initialState: 'aud',
  reducers: {
    setOrderingCurrency(state, action: PayloadAction<string>) {
      return action.payload.toLowerCase();
    },
  },
});

export const { setOrderingCurrency } = slice.actions;

export default slice.reducer;

import React from 'react';
import Text from './Text';
import { useTranslation } from 'react-i18next';
import combineStyles from '../utils/combineStyles';
const LoyaltyTitle: React.FC<{
  title: string;
  titleTranslated?: boolean;
  style?: React.CSSProperties;
}> = ({ title, titleTranslated, style }) => {
  const { t } = useTranslation();

  return (
    <div className="text-center" style={combineStyles(styles.loyaltyHeaderWrapper, style)}>
      <Text themeKey="loyaltyHeader" value={titleTranslated ? title : t(title)} />
    </div>
  );
};

const styles: Styles = {
  loyaltyHeaderWrapper: {
    margin: '2em 0 2em',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default LoyaltyTitle;

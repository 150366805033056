import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | number | string;

const slice = createSlice({
  name: 'loyaltyFilters',
  initialState,
  reducers: {
    setLoyaltyFilters(state, action: PayloadAction<null | number | string>) {
      return action.payload || initialState;
    },
  },
});

export const { setLoyaltyFilters } = slice.actions;

export default slice.reducer;

export enum PLATFORM {
  WEB = 'web',
  MOBILE = 'mobile',
  NODEJS = 'nodejs',
}

const getPlatform = () => {
  if (typeof document !== 'undefined') {
    return PLATFORM.WEB;
  } else if (
    typeof navigator !== 'undefined' &&
    navigator.product === 'ReactNative'
  ) {
    return PLATFORM.MOBILE;
  } else {
    return PLATFORM.NODEJS;
  }
};

export default getPlatform;

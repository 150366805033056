import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../operations';

const initialState = null as null | number[];

const slice = createSlice({
  name: 'menuFilterTags',
  initialState,
  reducers: {
    setMenuFilterTags(state, action: PayloadAction<number[] | null>) {
      return action.payload || initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, () => {
      return initialState;
    });
  },
});

export const { setMenuFilterTags } = slice.actions;

export default slice.reducer;

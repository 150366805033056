import { createAsyncThunk } from '@reduxjs/toolkit';
import Api, { FetchParams } from '../utils/Api';
import processGiftCard from '../utils/processors/processGiftCard';

export const fetchGiftCard = createAsyncThunk(
  '$fetchGiftCard',
  async (
    data: {
      giftCard: GiftCard;
    },
    { rejectWithValue },
  ) => {
    const { giftCard } = data;
    try {
      const params: FetchParams = {
        path: '/api/v1/members/giftcard_balance',
        method: 'POST',
        body: {
          VerifyPIN: giftCard.pin,
          MemberNo: giftCard.number,
        },
      };

      const response = await Api.fetch(params);

      const processedGiftCard = processGiftCard(response.data);

      return {
        giftCard: processedGiftCard,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

import { useAppSelector } from '../app/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from './Text';
import getThemeLookup from '../selectors/getThemeLookup';
import { OrderingSelectors } from 'polygon-ordering';

const { getEnergyDisplayTypes } = OrderingSelectors;

const KJ_TO_KCAL = 0.239;

const Energy: React.FC<{ kilojouleCount: number; style?: React.CSSProperties }> = ({
  kilojouleCount,
  style,
}) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  const displayTypes = useAppSelector(getEnergyDisplayTypes);

  return (
    <>
      {!!displayTypes?.length && (
        <Text themeKey="purchaseEditorEnergy" style={style}>
          {displayTypes?.map((displayType, i, arr) => {
            let displayNum = kilojouleCount;
            let displaySuffix = t('energy.kilojouleSuffix');
            switch (displayType) {
              case 'KILOJOULES':
                displayNum = kilojouleCount;
                displaySuffix = t('energy.kilojouleSuffix');
                break;
              case 'CALORIES':
                displayNum = kilojouleCount * KJ_TO_KCAL;
                displaySuffix = t('energy.calorieSuffix');
                break;
            }

            displayNum = Math.round(displayNum);

            return (
              <React.Fragment key={displayType}>
                {displayNum}
                <Text
                  themeKey="menuItemEnergySuffix"
                  style={p('purchaseEditorEnergySuffix', ['paddingLeft'])}
                >
                  {displaySuffix}
                </Text>
                {i + 1 < arr.length && ' / '}
              </React.Fragment>
            );
          })}
        </Text>
      )}
    </>
  );
};

export default Energy;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { setBufferPaymentGatewayConfig } from '../reducers/buffer/paymentGatewayConfig';
import { getMember } from '../selectors';
import getLocationId from '../selectors/getLocationId';
import Api, { FetchParams, ApiResponse } from '../utils/Api';
import { setPaymentGatewayConfig } from '../reducers/currentOrder/paymentGatewayConfig';

export const fetchPaymentGatewayConfig = createAsyncThunk(
  '$fetchPaymentGatewayConfig',
  async (
    data: {
      locationId?: string;
      bufferMode?: boolean;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const { locationId: locationIdParam, bufferMode = false } = data;

    try {
      const member = getMember(getState() as EntireFrontendState);
      const currentLocationId = getLocationId(
        getState() as EntireFrontendState,
      );

      const locationId = locationIdParam ?? currentLocationId; //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing#:~:text=The%20nullish%20coalescing%20operator%20(%20%3F%3F,its%20left%2Dhand%20side%20operand.

      if (!locationId) {
        throw new Error('no location id, skipping fetchPaymentGatewayConfig');
      }

      const params: FetchParams = {
        path: member
          ? '/api/v1/sale/payment_methods'
          : '/api/v1/sale/payment_methods/nonmember/',
        method: 'POST',
        body: {
          StoreID: parseInt(locationId),
        },
      };

      const response: ApiResponse = await Api.fetch(
        params,
        member ? 'member' : 'none',
      );

      if (bufferMode) {
        dispatch(
          setBufferPaymentGatewayConfig({
            clientKey: response.clientKey,
            clientConnectKey: response.clientConnectKey,
            publicEncryptionKey: response.publicEncryptionKey,
            data: response.data,
            environment: response.environment,
            merchantAccount: response.merchantAccount,
            version: response.version,
          }),
        );
      } else {
        dispatch(
          setPaymentGatewayConfig({
            clientKey: response.clientKey,
            clientConnectKey: response.clientConnectKey,
            publicEncryptionKey: response.publicEncryptionKey,
            data: response.data,
            environment: response.environment,
            merchantAccount: response.merchantAccount,
            version: response.version,
          }),
        );
      }
    } catch (e) {
      console.log('Fetch payment gateway config failed.', { e });
      return rejectWithValue(e);
    }
  },
);

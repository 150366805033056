export const computeTagsForItem = (
  item: Item,
  itemSelections: NestedChoiceSelections | SDict<string[]>,
  allTags: TagAllergenDietary[],
): number[] => {
  // if any selection has an allergen, include it in the computed tags
  // if all selections include a dietary tag then include it

  // helper functions, these should probably be in the ordering module
  const tagsFromIds = (ids: number[]): TagAllergenDietary[] => {
    return ids.reduce((sum, id) => {
      const tagData = allTags?.find(tag => tag.recid === id);
      tagData && sum.push(tagData);
      return sum;
    }, [] as TagAllergenDietary[]);
  };
  const seperateTags = (
    tags: TagAllergenDietary[],
  ): {
    allergenTags: TagAllergenDietary[];
    dietaryTags: TagAllergenDietary[];
    customTags: TagAllergenDietary[];
  } => {
    const buckets: {
      allergenTags: TagAllergenDietary[];
      dietaryTags: TagAllergenDietary[];
      customTags: TagAllergenDietary[];
    } = { allergenTags: [], dietaryTags: [], customTags: [] };
    tags?.forEach(tag => {
      switch (tag.tag_type) {
        case 'ALLERGEN':
          buckets.allergenTags.push(tag);
          break;
        case 'DIETARY':
          buckets.dietaryTags.push(tag);
          break;
        case 'CUSTOM':
          buckets.customTags.push(tag);
          break;
      }
    });
    return buckets;
  };

  const {
    allergenTags: allAllergenTags,
    dietaryTags: allDietaryTags,
    customTags: allCustomTags,
  } = seperateTags(allTags);

  const tagsLists: number[][] = [];

  tagsLists.push(item.tags ?? []);

  itemSelections &&
    item.choiceSets?.forEach(choiceSet => {
      const choiceSetSelections =
        itemSelections[choiceSet.id] ??
        itemSelections[choiceSet.key] ??
        undefined;
      if (!choiceSetSelections) return;

      if ('nestedIngredients' in choiceSet && choiceSet.nestedIngredients) {
        // nestedchoiceset / choice set with nested items inside
        // should really recurse here
        Object.entries(choiceSetSelections).forEach(
          ([id, selections]: [string, SDict<ChoiceWithQuantity[]>]) => {
            // theoretically this should only happen once, unless the choice set formats are changed to not unroll nested item sets in the menu processing function
            const nestedItem = choiceSet.choices.find(c => c.id === id);
            if (!nestedItem) return;

            Object.entries(selections).forEach(
              ([choiceSetId, nestedChoiceSetSelections]) => {
                const choiceSet = nestedItem?.choiceSets.find(
                  c => c.id === choiceSetId,
                );
                if (!choiceSet) return;
                nestedChoiceSetSelections.forEach(c => {
                  const choice = choiceSet.choices.find(
                    choiceObj =>
                      choiceObj.id ===
                      (typeof c === 'string'
                        ? c
                        : (c as ChoiceWithQuantity).id),
                  );
                  tagsLists.push(choice?.tags ?? []);
                });
              },
            );
            tagsLists.push(nestedItem?.tags ?? []);
          },
        );
      } else {
        // normal choice set
        (choiceSetSelections as ChoiceWithQuantity[]).forEach(c => {
          const choice = choiceSet.choices.find(
            choiceObj =>
              choiceObj.id ===
              (typeof c === 'string' ? c : (c as ChoiceWithQuantity).id),
          );
          tagsLists.push(choice?.tags ?? []);
        });
      }
    });

  let commonDietaries = new Set<number>(allDietaryTags.map(t => t.recid));
  const summedAllergens = new Set<number>();

  let anyDietaries = false;

  tagsLists.forEach((tagIds, index) => {
    const tags = seperateTags(tagsFromIds(tagIds));
    // TODO: decision making on this?
    // if there are no dietary tags then assume it fits within all diets and skips the check

    // unless it's the first element in the list (the root item)
    if (tags.dietaryTags.length || index === 0) {
      anyDietaries = true;
      // set intersection
      commonDietaries = new Set(
        tags.dietaryTags
          .filter(tag => commonDietaries.has(tag.recid))
          .map(t => t.recid),
      );
    }
    // set union
    tags.allergenTags.forEach(tag => summedAllergens.add(tag.recid));
    // TODO: what to do with custom tags?
    // currently they are ignored for choices
  });

  if (!anyDietaries) {
    commonDietaries.clear();
  }

  const { customTags } = seperateTags(tagsFromIds(item.tags ?? []));
  return [
    ...commonDietaries,
    ...summedAllergens,
    ...customTags.map(ct => ct.recid),
  ];
};

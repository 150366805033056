import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetOrder } from '../../actions/currentOrder';

const initialState = null as string | null;

const slice = createSlice({
  name: '$table',
  initialState,
  reducers: {
    setTable(state, action: PayloadAction<string | null>) {
      return action.payload;
    },
    adjustTable(state, action: PayloadAction<'clear' | 'delete' | string>) {
      if (action.payload === 'clear') {
        return initialState;
      }

      if (action.payload === 'delete') {
        return (state || '').slice(0, -1);
      }

      return `${state || ''}${action.payload}`.trimStart();
    },
  },
  extraReducers: builder => {
    builder.addCase(resetOrder, (state, action) => {
      return initialState;
    });
  },
});

export const { setTable, adjustTable } = slice.actions;

export default slice.reducer;

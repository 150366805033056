import { createSelector } from '@reduxjs/toolkit';
import {
  checkTimeAvailability,
  calculateItemAvailabilityTime,
} from '../utils/ordering';
import { getMenu, getCategoryAvailabilityById } from './';

export default (item: Item, mimCategoryId?: string, dayInFull?: boolean) =>
  createSelector(
    [getMenu, getCategoryAvailabilityById(mimCategoryId || '')],
    (menu, [catAvailability, catAvailibilityString]): [boolean, string] => {
      if (!item || !menu) return [false, ''];

      if (!catAvailability) return [false, catAvailibilityString];
      const { start, end, days, availability } = item;
      return [
        checkTimeAvailability(availability, days, start, end),
        calculateItemAvailabilityTime(
          availability,
          days,
          start,
          end,
          dayInFull,
        ),
      ];
    },
  );

import { useAppSelector } from '../app/hooks';
//@ts-ignore
import postscribe from 'postscribe';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useRoutes from './useRoutes';
import TagManager from 'react-gtm-module';

const useInjection = () => {
  const { defaultRoutes, loyaltyRoutes } = useRoutes();

  // Injection scripts:
  const orderingScriptInjection = useAppSelector(state => state.config.orderingScriptInjection);
  const loyaltyScriptInjection = useAppSelector(state => state.config.loyaltyScriptInjection);
  const loyaltyHtmlHeadInjection = useAppSelector(state => state.config.loyaltyHtmlHeadInjection);
  const googleTagManagerKey = useAppSelector(state => state.config.googleTagManagerKey);

  // This is used to detect which type of page the user is on to then inject the correct scripts.
  const routeLocation = useLocation();
  // This is used to store the previous script that initially ran so it could be used to compare if it has already ran.
  const initialScript = useRef('');
  const queuedScript = useRef('');
  // This is used to allow Google Tag Manager to work whenever it's called in analytics.ts
  if (googleTagManagerKey && !window.googleTagManager) window.googleTagManager = true;

  useEffect(() => {
    const isLoyalty = loyaltyRoutes.some(route => route.path == routeLocation.pathname);
    const isOrder = defaultRoutes.some(route => route.path == routeLocation.pathname);

    window.requestAnimationFrame(() => {
      // Google Tag:
      // console.log('Google Tag Manager Key:', googleTagManagerKey);
      if (googleTagManagerKey && googleTagManagerKey != '') {
        const tagManagerArgs = {
          // Our own Google Tag ID for testing:
          // TEST == 'GTM-T9KP5W2',
          // Put 'TEST' in pc to see if it's working fine.

          gtmId: googleTagManagerKey != 'TEST' ? googleTagManagerKey : 'GTM-T9KP5W2',

          dataLayer: {
            page: routeLocation,
          },
        };

        TagManager.initialize(tagManagerArgs);
      }

      const injectedScriptWrapper = document.querySelector('#injected-scripts');

      if (isLoyalty) {
        // Injecting into bottom of the body
        if (loyaltyScriptInjection != null) {
          queuedScript.current = loyaltyScriptInjection;
        }
        // Injecting into the head
        if (loyaltyHtmlHeadInjection != null) {
          // Stuff into the head
        }
      } else if (isOrder && orderingScriptInjection != null) {
        queuedScript.current = orderingScriptInjection;
      }

      // if #injected-scripts is not undefined and doesn't have the same code in it, delete everything in it an run new scripts
      if (
        injectedScriptWrapper != null &&
        (initialScript.current == '' || initialScript.current != queuedScript.current)
      ) {
        injectedScriptWrapper.innerHTML = '';
        postscribe('#injected-scripts', queuedScript.current);
        initialScript.current = queuedScript.current;
      }
    });
  }, [routeLocation]);

  return null;
};
export default useInjection;

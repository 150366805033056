import { createSelector } from 'reselect';

import { OrderingSelectors, OrderingConstants } from 'polygon-ordering';

import getAdditionalDeliveryStatusesEnabled from './getAdditionalDeliveryStatusesEnabled';

const { getSaleDetails } = OrderingSelectors;

const {
  ORDER_SUBMITTED,
  RECEIVED_AT_STORE,
  PROCESSED_AT_STORE,
  READY_TO_COLLECT,
  PICKED_UP_BY_DRIVER,
  DELIVERED,
  SALE_STATUSES_MAP,
} = OrderingConstants;

const steps = [
  ORDER_SUBMITTED,
  RECEIVED_AT_STORE,
  PROCESSED_AT_STORE,
  READY_TO_COLLECT,
  PICKED_UP_BY_DRIVER,
  DELIVERED,
].map(step => ({ id: SALE_STATUSES_MAP[step], value: step }));

const getSaleStatus = createSelector([getSaleDetails], saleDetails => saleDetails?.status);

export default createSelector(
  [getSaleStatus, getAdditionalDeliveryStatusesEnabled],
  (highestCompletedStatusId, additionalDeliveryStatusesEnabled) => {
    return steps
      .filter(step => {
        if (additionalDeliveryStatusesEnabled) {
          return step.value !== READY_TO_COLLECT;
        } else {
          return ![PICKED_UP_BY_DRIVER, DELIVERED].includes(step.value);
        }
      })
      .map(step => ({
        ...step,
        //@ts-ignore
        complete: highestCompletedStatusId >= step.id,
        inProgress:
          step.value === READY_TO_COLLECT
            ? highestCompletedStatusId === SALE_STATUSES_MAP[PROCESSED_AT_STORE]
            : //@ts-ignore
              highestCompletedStatusId + 1 === step.id,
      }));
  },
);

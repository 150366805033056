import { createSelector } from 'reselect';

import { ASAP_TIME } from '../constants';

import getPickupTime from './getPickupTime';
import getSaleType from './getSaleType';
import { SALE_TYPE } from '../constants/saleType';

export default createSelector(
  [getPickupTime, getSaleType],
  (pickupTime, saleType) => {
    if (SALE_TYPE.DINE_IN === saleType) {
      return ASAP_TIME;
    }

    return pickupTime || ASAP_TIME;
  },
);

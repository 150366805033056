import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';
import getLocalForage from './getLocalForage';
import getPlatform, { PLATFORM } from '../utils/getPlatform';

const platform = getPlatform();

const getMenu = createSelector(
  [getCurrentOrder, getLocalForage],
  (currentOrder: Order, localForage: LocalForageState): Menu | undefined =>
    platform == PLATFORM.WEB
      ? localForage.menu || undefined
      : currentOrder.menu || undefined,
);

export default getMenu;

export const $getMenu = createSelector(
  [getBuffer, getMenu],
  (buffer: OrderingBuffer, menuItems?: Menu) => {
    return buffer.menu || menuItems || undefined;
  },
);

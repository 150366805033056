import { useTranslation } from 'react-i18next';
import React from 'react';
import Text from './Text';
import { OrderingHooks } from 'polygon-ordering';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector } from '../app/hooks';

const { useFormattedCurrency } = OrderingHooks;

const OrderDetailsSubPayments = ({
  selectedPaymentMethod,
}: {
  selectedPaymentMethod: SubPayment;
}) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const price = useFormattedCurrency({ cents: selectedPaymentMethod.amount });
  return (
    <div>
      <Text
        style={combineStyles({ fontSize: 12 }, p('orderDetailsSubPaymentsText', ['color']))}
        value={t(`paymentMethod.${selectedPaymentMethod.method}`)}
      />
      <Text style={combineStyles({ fontSize: 12 }, p('orderDetailsSubPaymentsText', ['color']))}>
        {price}
      </Text>
    </div>
  );
};

export default OrderDetailsSubPayments;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';

import debug from '../utils/debug';

import { RootState } from 'store';
import alertForValidation, { customerConfirm } from '../utils/alertForFailedValidation';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

const { validateOrder, updateKeyOrderProperty } = OrderingOperations;
const { getSaleType, getMember } = OrderingSelectors;

export const validate = createAsyncThunk(
  'validate',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    debug('validate');

    let proceedToFinalise = true;

    try {
      const saleType = getSaleType(getState() as RootState);
      const memberPresent = Boolean(getMember(getState() as RootState));

      const { valid, errorText, reason } = await dispatch(
        validateOrder({ authenticationMethod: memberPresent ? 'member' : 'none' }),
      ).unwrap();

      if (!valid) {
        if (saleType !== SALE_TYPE.DELIVERY) {
          alertForValidation(reason!, errorText);
        } else {
          await dispatch(updateKeyOrderProperty({ autoApply: true, updateDeliveryEstimate: true }));

          const secondValidation = await dispatch(
            validateOrder({ authenticationMethod: memberPresent ? 'member' : 'none' }),
          ).unwrap();

          if (!secondValidation.valid) {
            alertForValidation(secondValidation.reason!, secondValidation.errorText);
          } else {
            customerConfirm();
          }
        }

        proceedToFinalise = false;
      }

      return {
        proceedToFinalise,
      };
    } catch (e) {
      console.error('Validate thunk failed', e);
      return rejectWithValue(e);
    }
  },
);

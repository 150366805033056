import { createAsyncThunk } from '@reduxjs/toolkit';
import lodash from 'lodash';
import {
  getCurrentSavedCard,
  getEnableDynamicPaymentGatewayConfig,
  getLocation,
  getLoyaltyTopUpValue,
  getMember,
  getRememberCreditCard,
  getSelectedPaymentMethods,
  getReceiptType,
} from '../selectors';
import getSaleBody from '../selectors/getSaleBody';
import { AuthenticationMethod } from '../utils/Api';
import smoothPickupTime from '../utils/ordering/smoothPickupTime';
import { PAYMENT_METHOD } from '../constants/paymentMethod';
import { SALE_TYPE } from '../constants/saleType';
const PAYMENT_GATEWAY_METHODS = [
  PAYMENT_METHOD.APPLE_PAY,
  PAYMENT_METHOD.GOOGLE_PAY,
  PAYMENT_METHOD.CREDIT_CARD,
  PAYMENT_METHOD.EFTPOS,
  PAYMENT_METHOD.SAVED_CARD,
  PAYMENT_METHOD.ALIPAY,
];

export const constructPaymentRequest = createAsyncThunk(
  '$constructPaymentRequest',
  async (
    data: {
      authenticationMethod?: AuthenticationMethod;
      route?: 'topup' | 'checkout';
      receiptType?: string;
    },
    { getState, rejectWithValue },
  ) => {
    try {
      let body: any = {};
      let path = '';
      const { route = 'checkout', authenticationMethod = 'none' } = data;

      const receiptType = getReceiptType(getState() as EntireFrontendState);

      const total = getLoyaltyTopUpValue(getState() as EntireFrontendState);
      const member = getMember(getState() as EntireFrontendState);
      const selectedPaymentMethods = getSelectedPaymentMethods(
        getState() as EntireFrontendState,
      );
      const payment_gateway = selectedPaymentMethods.filter(options =>
        PAYMENT_GATEWAY_METHODS.includes(options.method),
      )[0];

      const currentSavedCardToken = getCurrentSavedCard(
        getState() as EntireFrontendState,
      );
      const enableDynamicPaymentGatewayConfig =
        getEnableDynamicPaymentGatewayConfig(getState() as EntireFrontendState);

      const rememberCreditCard =
        getRememberCreditCard(getState() as EntireFrontendState) &&
        payment_gateway?.method === PAYMENT_METHOD.CREDIT_CARD;

      if (route === 'checkout') {
        const baseBody = getSaleBody(getState() as EntireFrontendState);

        if (baseBody.Payments) {
          if (baseBody.Payments.length === 1) {
            const { Payments, ...baseBodyWithoutPayments } = baseBody;
            body = {
              ...baseBodyWithoutPayments,
              ...baseBody.Payments[0],
              printer_settings: {
                KioskPrintReceiptType: receiptType,
              },
            };
          } else if (baseBody.Payments.length > 1) {
            body = {
              ...baseBody,
              MultiplePaymentMode: 'PART',
              printer_settings: {
                KioskPrintReceiptType: receiptType,
              },
            };
          }
        } else {
          throw new Error('payment method is undefined');
        }

        if (body.SaleType === SALE_TYPE.TABLE_ORDER) {
          body.PickupTime = 'ASAP';
        }

        if (body.PickupTime != undefined) {
          const location = getLocation(getState() as EntireFrontendState);

          body.PickupTime = smoothPickupTime(
            lodash.get(baseBody, 'PickupTime'),
            location!.averageOrderWaitTime,
          );
        }

        path = {
          trusted: '/api/v1/authsale2',
          member: '/api/v1/sale',
          none: '/api/v1/sale/nonmember',
        }[authenticationMethod];
      }

      if (route === 'topup') {
        body = {
          ...body,
          HCTopupAmount: total,
          recid_member: member?.id,
          PaymentType: 'cc',
          PaymentInfo: {
            ...(payment_gateway.method === PAYMENT_METHOD.SAVED_CARD
              ? { saved_pg_token: currentSavedCardToken }
              : { id: payment_gateway.token }),
            remember_pg_token: rememberCreditCard || undefined,
            last_four: rememberCreditCard
              ? payment_gateway.lastFour
              : undefined,
            useIntents: enableDynamicPaymentGatewayConfig,
            paymentMethodType: payment_gateway.method,
          },
        };

        path = '/api/v1/sale';
      }

      return {
        body,
        path,
      };
    } catch (e) {
      console.error('Cant construct payment request', { e });
      return rejectWithValue(e);
    }
  },
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'stockPollingInterval',
  initialState: 60,
  reducers: {
    setStockPollingInterval(state, action: PayloadAction<number>) {
      return action.payload || 60;
    },
  },
});

export const { setStockPollingInterval } = slice.actions;

export default slice.reducer;

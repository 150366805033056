import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBufferReadyToApply } from '../selectors';
import { $getLocationId } from '../selectors/getLocationId';
import { $getSaleType } from '../selectors/getSaleType';
import getMenu, { $getMenu } from '../selectors/getMenu';
import { $getUnenhancedOffers } from '../selectors/getUnenhancedOffers';
import { $getUnenhancedStagedPurchases } from '../selectors/getUnenhancedStagedPurchases';
import { $getUnenhancedOpenPurchase } from '../selectors/getUnenhancedOpenPurchase';
import { $getDeliveryAddress } from '../selectors/getDeliveryAddress';
import { $getDeliveryTime } from '../selectors/getDeliveryTime';
import { $getTimeEstimateReturned } from '../selectors/getTimeEstimateReturned';
import { $getDeliveryProvider } from '../selectors/getDeliveryProvider';
import { $getDeliverySurcharges } from '../selectors/getDeliverySurcharges';
import { $getMinimumOrderInformation } from '../selectors/getMinimumOrderInformation';
import { $getOrderingProviderSurchargeInstructions } from '../selectors/getOrderingProviderSurchargeInstructions';
import { $getCreditCardPaymentInformation } from '../selectors/getCreditCardPaymentInformation';
import { $getPaymentGatewayConfig } from '../selectors/getPaymentGatewayConfig';
import { applyBufferData } from '../actions/buffer';
import { clearBuffer } from '../operations';
import { applyShadowOrder } from './applyShadowOrder';
import { SALE_TYPE } from '../constants/saleType';
import Logger from '../utils/Logger';

import getPlatform, { PLATFORM } from '../utils/getPlatform';

export const applyBuffer = createAsyncThunk(
  '$applyBuffer',
  async (_data: undefined, { dispatch, getState, rejectWithValue }) => {
    const readyToApply = getBufferReadyToApply(
      getState() as EntireFrontendState,
    );

    try {
      if (!readyToApply) {
        throw new Error('buffer not ready to apply');
      }

      const locationId = $getLocationId(getState() as EntireFrontendState);
      const saleType = $getSaleType(getState() as EntireFrontendState);
      const menu = $getMenu(getState() as EntireFrontendState);
      const offers = $getUnenhancedOffers(getState() as EntireFrontendState);
      const stagedPurchases = $getUnenhancedStagedPurchases(
        getState() as EntireFrontendState,
      );
      const openPurchase = $getUnenhancedOpenPurchase(
        getState() as EntireFrontendState,
      );
      const deliveryAddress = $getDeliveryAddress(
        getState() as EntireFrontendState,
      );
      const deliveryTime = $getDeliveryTime(getState() as EntireFrontendState);
      const timeEstimateReturned = $getTimeEstimateReturned(
        getState() as EntireFrontendState,
      );
      const deliveryProvider = $getDeliveryProvider(
        getState() as EntireFrontendState,
      );
      const deliverySurcharges = $getDeliverySurcharges(
        getState() as EntireFrontendState,
      );
      const minimumOrderInformation = $getMinimumOrderInformation(
        getState() as EntireFrontendState,
      );
      const orderingProviderSurchargeInstructions =
        $getOrderingProviderSurchargeInstructions(
          getState() as EntireFrontendState,
        );
      const creditCardPaymentInformation = $getCreditCardPaymentInformation(
        getState() as EntireFrontendState,
      );
      const paymentGatewayConfig = $getPaymentGatewayConfig(
        getState() as EntireFrontendState,
      );

      const nullIfNotDelivery = <V>(value: V) =>
        saleType === SALE_TYPE.DELIVERY ? value : null;

      const platform = getPlatform();

      dispatch(
        applyBufferData({
          locationId,
          saleType,
          menu: platform == PLATFORM.WEB ? undefined : menu,
          offers,
          stagedPurchases,
          openPurchase: openPurchase || undefined,
          deliveryAddress: nullIfNotDelivery(deliveryAddress),
          deliveryTime: nullIfNotDelivery(deliveryTime),
          timeEstimateReturned: nullIfNotDelivery(timeEstimateReturned),
          deliveryProvider: nullIfNotDelivery(deliveryProvider),
          deliverySurcharges: nullIfNotDelivery(deliverySurcharges),
          minimumOrderInformation,
          orderingProviderSurchargeInstructions,
          creditCardPaymentInformation,
          paymentGatewayConfig,
        }),
      );

      // TODO: MUST comment this out to avoid race condition of buffer, why are we clearning this?
      // maybe we need to do it at the top
      // await dispatch(clearBuffer({}));

      const orderMenu = getMenu(getState() as EntireFrontendState);

      if (orderMenu) {
        try {
          dispatch(applyShadowOrder());
        } catch (e) {
          Logger.log(
            'applyShadowOrderFlow failed but applyBuffer will proceed',
          );
        }
      }
    } catch (e) {
      console.error('Apply buffer failed', { e });
      return rejectWithValue(e);
    }
  },
);

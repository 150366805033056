import { createSlice } from '@reduxjs/toolkit';

import { setCurrentModalId } from './currentModalId';

const initialState = null as CallOutOffSets | null;

const slice = createSlice({
  name: 'calloutOffsets',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setCurrentModalId, (state, action) => {
      if (typeof action.payload === 'string') {
        return initialState;
      }

      const { calloutOffsets } = action.payload || {};
      return calloutOffsets || initialState;
    });
  },
});

export default slice.reducer;

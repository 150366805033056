import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProductConfig, getSelectedPaymentMethods } from '../selectors';
import { getDeviceId, getEftposConfig } from '../selectors/config';
import PaymentHooks from '../utils/PaymentHooks';
import {
  WINDCAVE_NOTIFICATION,
  EFTPOS_STATUS_UPDATE,
  FAILURE_REASON,
} from '../constants';
import Logger from '../utils/Logger';
// @ts-ignore
import { RedcatPaymentHandler } from 'polygon-payments';
import delay, { centsToDollars } from '../utils/misc';
import { sale } from './sale';
import { updateSelectedPaymentMethod } from '../reducers/currentOrder/selectedPaymentMethods';
import parseWindcaveReceipt from '../utils/processors/processWindcaveReceipt';
import { PAYMENT_METHOD } from '../constants/paymentMethod';
import getCurrentOrder from '../selectors/getCurrentOrder';
import moment from 'moment';

export const windcavePayments = createAsyncThunk(
  '$windcavePayments',
  async (
    data: {
      amount: number;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    let reason: FAILURE_REASON | undefined;

    try {
      const { amount } = data;
      const { vendor, name, version } = getProductConfig(
        getState() as EntireFrontendState,
      );
      const fallBackDeviceId = '1'; // TODO: olo3Payments?? #windcave to fix
      const eftposConfig = getEftposConfig(getState() as EntireFrontendState);
      const currentSelectedPayment = getSelectedPaymentMethods(
        getState() as EntireFrontendState,
      );

      const currentLocationId = getCurrentOrder(
        getState() as EntireFrontendState,
      ).locationId;

      const {
        terminalId,
        paymentProvider,
        paymentEnvironment,
        hostAddress,
        apiKey,
        eposIdentifier,
        username, // misisng value in the browser
        deviceId,
      } = getEftposConfig(getState() as EntireFrontendState);

      // This condition should never be reached
      if (!vendor || !name || !version || !terminalId || !apiKey || !username) {
        reason = FAILURE_REASON.MISSING_EFTPOS_CONFIG;
        throw new Error('missing necessary config');
      }

      // Step 1: Initalise the Payment Handler with config
      const redcatPayment = new RedcatPaymentHandler();

      console.log({
        hostAddress: hostAddress,
        paymentEnvironment: paymentEnvironment,
        apiKey: apiKey,
        terminalId: terminalId,
        eposIdentifier: eposIdentifier,
        username: username,
      });

      const timeStartInitPayment = moment();

      redcatPayment.init({
        provider: paymentProvider!,
        config: {
          hostAddress: hostAddress,
          paymentEnvironment: paymentEnvironment,
          apiKey: apiKey,
          terminalId: terminalId,
          eposIdentifier: eposIdentifier,
          user: username,
        },
        interfaceDir: './',
        debugLogs: true,
      });

      // Step 2: Inquire and check the connection the terminal
      const checkEftposConnection = await redcatPayment.inquire();

      const timeEndInitPayment = moment();
      const windcaveInitResponseTime =
        timeEndInitPayment.diff(timeStartInitPayment);

      Logger.logUpstream({
        DeviceID: deviceId as string,
        StoreID: currentLocationId!,
        Action: 'BEFORE_PAYMENT',
        DateTime: new Date().toLocaleString('en', {
          timeZone: 'Australia/Sydney',
        }),
        Data: {
          Endpoint: JSON.stringify({
            terminalId: terminalId,
            paymentEnvironment: paymentEnvironment,
          }),
          SentBody: JSON.stringify({
            paymentProvider: paymentProvider!,
            hostAddress: hostAddress,
            paymentEnvironment: paymentEnvironment,
            apiKey: apiKey,
            terminalId: terminalId,
            eposIdentifier: eposIdentifier,
            user: username,
          }),
          ResponseCode: checkEftposConnection && 200,
          ResponseBody: checkEftposConnection,
          ResponseTime: windcaveInitResponseTime,
        },
      });

      //wait for the response to come back using yield
      const activeTerminal: any = checkEftposConnection;

      // Step 3: Payment
      const actualAmount = centsToDollars(amount);
      const productInfo = {
        vendor,
        name,
        version,
        deviceId: eftposConfig.deviceId
          ? eftposConfig.deviceId
          : fallBackDeviceId,
      };

      let apiResult = await redcatPayment.initatePayment(
        actualAmount!,
        productInfo,
      );
      console.log('Windcave purchase: ', { apiResult });

      let byPassInternetDropOut = 0;
      let cardTapped = false;
      const windcavePollStart = moment();

      const checkTxnRecurse: any = async () => {
        const hook: any = PaymentHooks.get(EFTPOS_STATUS_UPDATE);

        PaymentHooks.subscribe('CANCEL_TRANSACTION', data => {
          if (data.cancelled) {
            reason = FAILURE_REASON.PAYMENT_CANCELLED;
          }
        });

        apiResult = await redcatPayment.checkTransaction();
        if (apiResult?.Scr?.TxnStatusId) {
          console.log(apiResult?.Scr?.TxnStatusId);
          hook({
            statusMsg:
              apiResult?.Scr?.TxnStatusId === 8
                ? WINDCAVE_NOTIFICATION[apiResult?.Scr?.TxnStatusId] +
                  apiResult?.Scr?.DL2
                : WINDCAVE_NOTIFICATION[apiResult?.Scr?.TxnStatusId],
            txnStarted: true,
          });
        } else {
          // statusMsg: 'Follow the prompts on the terminal',
          console.log('Transaction in progress — Please refer to terminal');
          hook({
            statusMsg: 'Transaction in progress — Please refer to terminal',
            txnStarted: true,
          });
        }

        // break recursion
        if (apiResult === undefined) {
          if (byPassInternetDropOut < 5) {
            byPassInternetDropOut = byPassInternetDropOut + 1;
            Logger.log('check internet connection... ' + byPassInternetDropOut);
          } else {
            Logger.log('===> #### apiResult undefined... ###', 'info');
            Logger.log(JSON.stringify(apiResult), 'info');
            reason = FAILURE_REASON.PAYMENT_TIME_OUT;
            return;
          }
        }

        // break recursion
        if (
          (apiResult && apiResult?.Scr?.Result?.RC === 'PJ') ||
          (apiResult &&
            apiResult?.Scr?.DL1 === 'HIT START FAILED' &&
            apiResult?.Scr?.ReCo === 'TS')
        ) {
          Logger.log('===> #### apiResult FAILED REASON ###', 'info');
          Logger.log(JSON.stringify(apiResult), 'info');
          reason = FAILURE_REASON.PAYMENT_TIME_OUT;
          return;
        }

        //   Pass response to the FE
        // windcavePaymentUpdate({ windcaveResponse: apiResult });

        if (apiResult?.Scr?.DL1 === 'SIGNATURE OK?') {
          // #windcave maube enhance the txn response
          // windcaveSignatureVoid({ voidTxn: true });
          reason = FAILURE_REASON.SIGNATURE_REQUIRED;
          await redcatPayment.signatureVerification(false);
          Logger.log(JSON.stringify(apiResult));
          await delay(500);
          return await checkTxnRecurse();
        }

        if (
          apiResult?.Scr?.DL1 === 'HIT START FAILED' &&
          apiResult?.Scr?.ReCo === 'PG'
        ) {
          reason = FAILURE_REASON.PAYMENT_TIMED_OUT;
          Logger.log(JSON.stringify(apiResult));
          // Terminal is offline and we cannot reach it
        } else if (
          apiResult?.Scr?.DL1 === 'INIT TIMEOUT' &&
          apiResult?.Scr?.DL2 === 'CANCELLED'
        ) {
          reason = FAILURE_REASON.PAYMENT_TIMED_OUT;
          Logger.log(JSON.stringify(apiResult));
        } else if (apiResult?.Scr?.DL1 === 'TIMEOUT') {
          reason = FAILURE_REASON.PAYMENT_TIMED_OUT;
          Logger.log(JSON.stringify(apiResult));
        }
        // TODO: how was this tested?
        // Incorrect Pin and decline transaction
        else if (apiResult?.Scr?.DL1 === 'DECLINED') {
          reason = FAILURE_REASON.PAYMENT_DECLINED;
          Logger.log(JSON.stringify(apiResult));
        }
        // TODO: how was this tested?
        else if (apiResult?.Scr?.DL1 === 'ICC DECLINED') {
          reason = FAILURE_REASON.PAYMENT_DECLINED;
          Logger.log(JSON.stringify(apiResult));
        }
        // Transaction was cancelled or card pulled out way to quickly
        else if (
          apiResult?.Scr?.DL1 === 'TRANSACTION' &&
          apiResult?.Scr?.DL2 === 'CANCELLED'
        ) {
          reason = FAILURE_REASON.PAYMENT_CANCELLED;
          Logger.log(JSON.stringify(apiResult));
        } else if (
          apiResult?.Scr?.DL1 === 'CARD REMOVED' &&
          apiResult?.Scr?.DL2 === 'DECLINED'
        ) {
          reason = FAILURE_REASON.CARD_REMOVED;
          Logger.log(JSON.stringify(apiResult));
        } else if (apiResult?.Scr?.DL1 === 'APPROVED') {
          // console.log({ txnData });
          const subPayments = getSelectedPaymentMethods(
            getState() as EntireFrontendState,
          );
          let subPayment = subPayments.filter(
            subPayment => subPayment.method === PAYMENT_METHOD.EFTPOS,
          )[0];
          subPayment = {
            ...subPayment,
            method: subPayment.method,
            amount: subPayment.amount,
            cardType: apiResult?.Scr?.Result?.CT,
            receiptText: parseWindcaveReceipt(apiResult?.Scr?.Rcpt),
            referenceNumber: apiResult?.Scr.Result?.TR,
            authorisationCode: String(apiResult?.Scr?.Result?.AC),
            Receipt: apiResult?.Scr.Result?.TR,
          };

          Logger.log('===> #### WINDCAVE SUCCESSFUL ###', 'info', apiResult);
          Logger.log(JSON.stringify(apiResult), 'info');
          Logger.log(
            '===> #### Payment Info ###',
            'info',
            currentSelectedPayment,
          );
          Logger.log(JSON.stringify(currentSelectedPayment), 'info');
          dispatch(updateSelectedPaymentMethod(subPayment));

          const windcavePollEnd = moment();
          const windcavePollResponseTime =
            windcavePollEnd.diff(windcavePollStart);

          Logger.logUpstream({
            DeviceID: deviceId as string,
            StoreID: currentLocationId!,
            Action: 'AFTER_PAYMENT',
            DateTime: new Date().toLocaleString('en', {
              timeZone: 'Australia/Sydney',
            }),
            Data: {
              Endpoint: apiResult?.Scr?.TxnRef,
              SentBody: null,
              ResponseCode: apiResult && 200,
              ResponseBody: apiResult,
              ResponseTime: windcavePollResponseTime,
            },
          });

          dispatch(
            sale({ route: 'checkout', authenticationMethod: 'trusted' }),
          ).unwrap();
          Logger.log(JSON.stringify(apiResult));
          return;
        }

        if (reason) {
          const windcavePollEnd = moment();
          const windcavePollResponseTime =
            windcavePollEnd.diff(windcavePollStart);

          Logger.logUpstream({
            DeviceID: deviceId as string,
            StoreID: currentLocationId!,
            Action: 'AFTER_PAYMENT',
            DateTime: new Date().toLocaleString('en', {
              timeZone: 'Australia/Sydney',
            }),
            Data: {
              Endpoint: apiResult?.Scr?.TxnRef,
              SentBody: null,
              ResponseCode: apiResult && 200,
              ResponseBody: apiResult,
              ResponseTime: windcavePollResponseTime,
            },
          });

          return reason;
        }

        await delay(500);
        return await checkTxnRecurse();
      };

      const getFailureReason = await checkTxnRecurse();

      if (getFailureReason) {
        throw new Error(getFailureReason);
      }
    } catch (e) {
      console.warn('Windcave payment failed', { e });
      return rejectWithValue({
        e,
        reason: reason ? reason : (e as any).message,
      });
    }
  },
);

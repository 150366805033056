import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

const getPaymentGatewayConfig = createSelector([getCurrentOrder], order => {
  return order.paymentGatewayConfig;
});

export default getPaymentGatewayConfig;

export const $getPaymentGatewayConfig = createSelector(
  [getPaymentGatewayConfig, getBuffer],
  (paymentGatewayConfig, buffer) => {
    if (buffer.paymentGatewayConfig) {
      return buffer.paymentGatewayConfig;
    }

    return paymentGatewayConfig;
  },
);

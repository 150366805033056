import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as string | null;

const slice = createSlice({
  name: 'currentDrawer',
  initialState,
  reducers: {
    setCurrentDrawer(state, action: PayloadAction<string | null>) {
      return action.payload;
    },
  },
});

export const { setCurrentDrawer } = slice.actions;

export default slice.reducer;

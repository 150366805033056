import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as string | null;

const slice = createSlice({
  name: 'loyaltyMemberCardset',
  initialState,
  reducers: {
    setLoyaltyMemberCardset(state, action: PayloadAction<string | null>) {
      return action.payload;
    },
  },
});

export const { setLoyaltyMemberCardset } = slice.actions;

export default slice.reducer;

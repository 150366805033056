import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import QRCode from 'react-qr-code';
import { Row, Col } from 'reactstrap';
import Text from '../components/Text';
import { OrderingSelectors } from 'polygon-ordering';

const { getMember } = OrderingSelectors;

const RedcatQrCode: React.FC<{ textStyle?: React.CSSProperties }> = ({ textStyle }) => {
  const { t } = useTranslation();
  const member = useAppSelector(getMember);

  return (
    <Row>
      <Col xs="7" className="mx-auto">
        <div
          className=" p-3 shadow"
          style={{ backgroundColor: 'white', borderRadius: 10, border: '2px solid lightgrey' }}
        >
          <div className="text-center">
            <QRCode value={member?.barcode!} size={130} />
          </div>
          <Text
            themeKey="loyaltyUserDetails"
            style={textStyle}
            value={`${member?.givenName?.toUpperCase()} ${member?.surname?.toUpperCase()}`}
          />{' '}
          <br />
          <Text value={`${member?.memberNumber}`} themeKey="barcodeMemberNumber" />
        </div>
      </Col>
    </Row>
  );
};

export default RedcatQrCode;

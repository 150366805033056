import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = null;
const slice = createSlice({
  name: 'dayPartMenu',
  initialState,
  reducers: {
    setDayPartMenuConfig(state, action: PayloadAction<any>) {
      return action.payload;
    },
  },
});
export const { setDayPartMenuConfig } = slice.actions;
export default slice.reducer;

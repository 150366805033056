import { createSelector } from 'reselect';

import getPurchaserInvalidReasons from './getPurchaserInvalidReasons';

export default createSelector(
  [getPurchaserInvalidReasons],
  (reasons): boolean => {
    return reasons.length === 0;
  },
);

import lodash from 'lodash';

import determineDiscountsForPurchase from './determineDiscountsForPurchase';

export default function applyOfferToStagedPurchases(
  purchasesWithTotals: PurchaseWithTotals[],
  selectedOffer: Offer,
  couponOrderingMethod: CouponOrderingMethod,
): PurchaseWithTotals[] {
  const discounts: DiscountDelta[] = [];

  purchasesWithTotals.forEach(purchase => {
    discounts.push(...determineDiscountsForPurchase(purchase, selectedOffer));
  });

  const sortDirection = {
    cheapest: 'desc',
    expensive: 'asc',
  }[couponOrderingMethod];

  const chosenDiscount = lodash.orderBy(
    discounts,
    'amount',
    sortDirection as any,
  )[0];

  if (!chosenDiscount) {
    return purchasesWithTotals;
  }

  const adjusted = purchasesWithTotals.map(purchase => {
    if (purchase.id !== chosenDiscount.purchaseId) {
      return purchase;
    }

    const discountedMoneyPrice = purchase.moneyPrice - chosenDiscount.amount;

    return {
      ...purchase,
      moneyDiscount: chosenDiscount.amount,
      discountedMoneyPrice,
    };
  });

  return adjusted;
}

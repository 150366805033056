import { createSelector } from 'reselect';

import { dollarsToCents } from '../utils/misc';

import { getConfig, getPayLaterRules } from './config';

import getMember from './getMember';
import getLocation from './getLocation';

import getOrderTotals from './getOrderTotals';
import getSaleType from './getSaleType';
import { PAYMENT_METHOD } from '../constants/paymentMethod';

export default createSelector(
  [
    getConfig,
    getSaleType,
    getLocation,
    getPayLaterRules,
    getMember,
    getOrderTotals,
  ],
  (
    config,
    saleType,
    location,
    payLaterRules,
    member,
    orderTotals,
  ): PAYMENT_METHOD[] => {
    let payLaterDisabled = !config.enablePayLater;

    // Pay Later is enabled by a global config as well as a rules CRUDL
    if (!payLaterDisabled && payLaterRules?.length) {
      payLaterDisabled = location
        ? !evaluateRules(payLaterRules, location, saleType, member, orderTotals)
        : true;
    }

    return config.enabledPaymentMethods.filter(method => {
      if (payLaterDisabled && method == PAYMENT_METHOD.PAY_LATER) {
        return false;
      }
      return true;
    });
  },
);

function generateHeirarchy(payLaterRules: PayLaterRule[]) {
  let heirarchyMap: {
    [key: string]: { [key: string]: { [key: string]: {} } };
  } = {};
  payLaterRules.forEach(rule => {
    const state = rule.state || 'null';
    const location = rule.locationId || 'null';
    const saleType = rule.saleType || 'null';
    if (state in heirarchyMap) {
      if (location in heirarchyMap[state]) {
        heirarchyMap[state][location][saleType] = rule;
      } else {
        const saleTypeMap = { [saleType]: rule };
        heirarchyMap[state][location] = saleTypeMap;
      }
    } else {
      const saleTypeMap = { [saleType]: rule };
      const locationMap = { [location]: saleTypeMap };
      heirarchyMap[state] = locationMap;
    }
  });
  return heirarchyMap;
}

function evaluateRules(
  payLaterRules: PayLaterRule[],
  location: POSLocation,
  saleType: SALE_TYPE,
  member: Member | undefined,
  orderTotals: OrderTotals | undefined,
) {
  const heirarchyMap = generateHeirarchy(payLaterRules);

  const stateMatches = [];
  const state = location.address.state || '';
  let stateRules = heirarchyMap[state];
  if (stateRules) {
    stateMatches.push(stateRules);
  }
  stateRules = heirarchyMap['null'];
  if (stateRules) {
    stateMatches.push(stateRules);
  }
  if (!stateMatches) {
    return false;
  }

  const locationMatches: any[] = [];
  stateMatches.forEach(stateMatch => {
    let locationRules = stateMatch[location.id];
    if (locationRules) {
      locationMatches.push(locationRules);
    }
    locationRules = stateMatch['null'];
    if (locationRules) {
      locationMatches.push(locationRules);
    }
  });
  if (!locationMatches.length) {
    return false;
  }

  const saleTypeMatches: any[] = [];
  locationMatches.forEach(locationMatch => {
    let saleTypeRules = locationMatch[saleType];
    if (saleTypeRules) {
      saleTypeMatches.push(saleTypeRules);
    }
    saleTypeRules = locationMatch['null'];
    if (saleTypeRules) {
      saleTypeMatches.push(saleTypeRules);
    }
  });
  if (!saleTypeMatches.length) {
    return false;
  }

  const finalRules = [...saleTypeMatches].sort((ruleA, ruleB) => {
    let keyA = score(ruleA, location, saleType);
    let keyB = score(ruleB, location, saleType);
    if (keyA < keyB) {
      return -1;
    }
    if (keyA > keyB) {
      return 1;
    }
    return 0;
  });

  const finalRulesReversed = [...finalRules].reverse();

  const rule = finalRulesReversed[0];

  const memberOnlyBool =
    member || !rule.memberOnly || rule.memberOnly == undefined;
  const maximumOrderBool =
    rule.maximumOrder == undefined ||
    (rule.maximumOrder > 0 &&
      orderTotals &&
      orderTotals.discountedMoneyPrice <= dollarsToCents(rule.maximumOrder));
  if (memberOnlyBool && maximumOrderBool) {
    return true;
  } else {
    return false;
  }
}

function score(rule: PayLaterRule, location: POSLocation, saleType: SALE_TYPE) {
  let score = 0;
  if (rule.state == location.address.state) {
    score += 2;
  }
  if (rule.locationId == location.id) {
    score += 3;
  }
  if (rule.saleType == saleType) {
    score++;
  }
  return score;
}

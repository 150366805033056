import moment from 'moment';

import { ASAP_TIME } from '../../constants';

export default function smoothPickupTime(
  pickupTime: string | null | undefined,
  averageOrderWaitTime: number,
) {
  if (
    !pickupTime ||
    pickupTime === ASAP_TIME ||
    moment(pickupTime).isBefore(moment().add(averageOrderWaitTime, 'minutes'))
  ) {
    return ASAP_TIME;
  }

  return pickupTime;
}

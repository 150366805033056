import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ORDERING_PROVIDER } from '../../constants/orderingProvider';

const initialState = ORDERING_PROVIDER.REDCAT as ORDERING_PROVIDER | null;

const slice = createSlice({
  name: '$orderingProvider',
  initialState,
  reducers: {
    setOrderingProvider(state, action: PayloadAction<ORDERING_PROVIDER>) {
      return action.payload;
    },
  },
});

export const { setOrderingProvider } = slice.actions;
export default slice.reducer;

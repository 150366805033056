import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingHooks, OrderingOperations } from 'polygon-ordering';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import Text from '../components/Text';
import { Row, Col } from 'reactstrap';
import Filters from '../components/Filters';
import TouchableOpacity from '../components/TouchableOpacity';
import moment from 'moment';
import SinglePastOrder from '../components/SinglePastOrder';
import { showLoyaltyHeaders } from '../slices/loyaltyHeaders';
import { IoIosArrowBack } from 'react-icons/io';
import ReorderButton from '../components/ReorderButton';
import LoyaltyTitle from '../components/LoyaltyTitle';
import getThemeLookup from '../selectors/getThemeLookup';
import { uuidv4 } from '../utils/misc';

const { useFormattedCurrency } = OrderingHooks;
const { getHistoricalOrders } = OrderingSelectors;
const { fetchHistoricalOrders } = OrderingOperations;

export const PAST_ORDERS_SCREEN_ROUTE = '/past-orders';

const Order: React.FC<{ order: EnhancedHistoricalOrder }> = ({ order }) => {
  const { t } = useTranslation();
  const isOnlyLoyaltyEnabled = useAppSelector(state => state.config.enableOnlyLoyalty);
  const orderTotal = useFormattedCurrency({ cents: order.moneyPrice });
  const p = useAppSelector(getThemeLookup);

  return (
    <div className="p-4" style={{ border: p('cardBorder', ['border']).border, borderRadius: 5 }}>
      <div style={styles.orderHeadingWrapper}>
        <div style={styles.orderHeadingText}>
          <Text
            style={styles.loyaltySaleType}
            themeKey="loyaltySaleType"
            value={t(`saleType.${order.saleType}.name`).toUpperCase()}
          />
          {order.locationName && (
            <>
              <Text themeKey="loyaltyStoreLocation" value={order.locationName} />{' '}
            </>
          )}
        </div>
        <Text themeKey="loyaltyOrderDate" value={order.orderDate} />
      </div>

      <Row>
        <Col xs="8">
          <div>
            <div>
              {order.items.map((item, index) => (
                <div key={index}>
                  <Text themeKey="loyaltyOrderItems" value={item.name} />{' '}
                  <Text themeKey="loyaltyOrderItems" value={'x' + item.quantity.toString()} />
                </div>
              ))}
              <Text themeKey="loyaltyOrderTotal" value={orderTotal} />
            </div>
          </div>
        </Col>
        <Col
          xs="4"
          className="position-relative"
          style={{
            paddingTop: '2em',
          }}
        >
          {!isOnlyLoyaltyEnabled && (
            <div className="position-absolute" style={{ bottom: 0, right: 10 }}>
              <ReorderButton order={order} />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

const HistoricalOrder = () => {
  const [selectedOrder, setSelectedOrder] = useState<EnhancedHistoricalOrder | null>(null);
  const selectedFilter = useAppSelector(state => state.loyaltyFilters);
  const dispatch = useAppDispatch();
  const date = useAppSelector(state => state.loyaltyDateFilters);

  const deviceType = useAppSelector(state => state.deviceType);
  const historicalOrders = useAppSelector(getHistoricalOrders);
  let filteredHistoricalOrders = selectedFilter
    ? historicalOrders?.filter(order => order.saleType === selectedFilter)
    : historicalOrders;

  if (date) {
    filteredHistoricalOrders = filteredHistoricalOrders?.filter(order => {
      return (
        moment(order.orderDate, 'DD/MM/YYYY').format('DD/MM/YYYY') ===
        moment(date).format('DD/MM/YYYY')
      );
    });
  }

  useEffect(() => {
    dispatch(fetchHistoricalOrders({}));
  }, []);

  return (
    <div>
      {!selectedOrder && (
        <div style={{ position: 'relative' }}>
          <LoyaltyTitle title="pastOrdersHeader" />

          {deviceType === 1 && <Filters />}
        </div>
      )}

      {selectedOrder ? (
        <div>
          <TouchableOpacity
            style={{ paddingTop: 25 }}
            onClick={() => {
              dispatch(showLoyaltyHeaders(true));
              setSelectedOrder(null);
            }}
          >
            <IoIosArrowBack style={{ fontSize: 35 }} />
          </TouchableOpacity>
          <SinglePastOrder selectedOrder={selectedOrder} />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
          {filteredHistoricalOrders?.map(order => (
            <TouchableOpacity
              key={order.id}
              onClick={() => {
                setSelectedOrder(order);
                dispatch(showLoyaltyHeaders(false));
              }}
            >
              <Order order={order} />
            </TouchableOpacity>
          ))}
        </div>
      )}
    </div>
  );
};

const PastOrdersScreen = () => {
  return (
    <div className=" position-relative">
      <HistoricalOrder />
    </div>
  );
};

const styles: Styles = {
  orderHeadingWrapper: {
    marginBottom: '1em',
  },
  orderHeadingText: {
    display: 'flex',
    gap: '0.5em',
  },
  loyaltySaleType: {
    flexShrink: 0,
  },
};

export default PastOrdersScreen;

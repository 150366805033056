import React from 'react';
import combineStyles from '../utils/combineStyles';

const FloatingFooter = ({
  children,
  containerStyle,
}: {
  children: JSX.Element | JSX.Element[];
  containerStyle?: React.CSSProperties;
}) => {
  return (
    <div style={styles.footer}>
      <div style={combineStyles([styles.footerContainer, containerStyle])}>{children}</div>
    </div>
  );
};

export default FloatingFooter;

const styles: Styles = {
  footer: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    position: 'sticky',
    bottom: 30,
    zIndex: 100,
  },

  footerContainer: {
    width: '100%',
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export const descriptionMap: DescriptionMap = {
  1: 'Sale',
  2: 'Redeem Points',
  3: 'Top Up',
  4: 'Redeem Money',
  5: 'Points Increase',
  6: 'Points Decrease',
  7: 'Money Increase',
  8: 'Money Decrease',
  9: 'Sale Reward',
  10: 'Redeem Reward',
  66: 'Birthday Gift',
};

export const REDEMPTION_TXN_TYPES = [2, 10, 4];

export const SHOW_TOTAL_MONEY_FOR_TXN_TYPES = [1, 2, 3, 4, 9, 10, 66];

export default function processTradingHours(
  rawHours: RawOpeningHours,
): TradingHours[] {
  const days = [
    { id: 'Monday' },
    { id: 'Tuesday' },
    { id: 'Wednesday' },
    { id: 'Thursday' },
    { id: 'Friday' },
    { id: 'Saturday' },
    { id: 'Sunday' },
    { id: 'Publicholiday', override: 'P. Holidays' },
  ];

  const hours: TradingHours[] = [];

  days.forEach(day => {
    //@ts-ignore
    const match = rawHours[day.id as Day];

    if (match) {
      hours.push({
        name: day.override || day.id,
        closed: match.OpeningTime === 'Closed',
        openingTime: match.OpeningTime,
        closingTime: match.ClosingTime,
      });
    }
  });

  return hours;
}

import { safeDate } from '../misc';

import processMinimumOrderInformation from './processMinimumOrderInformation';
import processHolidaySurchargeInformation from './processHolidaySurchargeInformation';
import processServiceFeeInformation from './processServiceFeeInformation';
import processSurcharge from './processSurcharge';
import moment from 'moment';

export default function processLocationDeliveryEstimate(
  rawDeliveryEstimate: RawLocationDeliveryEstimate,
): LocationDeliveryEstimate {
  const minimumOrderInformation = processMinimumOrderInformation(
    rawDeliveryEstimate.MinimumOrderInfo,
  );

  const orderingProviderSurchargeInstructions: SurchargeInstruction[] = [];

  if (rawDeliveryEstimate.HolidaySurchargeInfo) {
    orderingProviderSurchargeInstructions.push(
      processHolidaySurchargeInformation(
        rawDeliveryEstimate.HolidaySurchargeInfo,
      ),
    );
  }

  if (rawDeliveryEstimate.ServiceFeeInfo) {
    orderingProviderSurchargeInstructions.push(
      processServiceFeeInformation(rawDeliveryEstimate.ServiceFeeInfo),
    );
  }

  return {
    deliveryProvider: {
      id: rawDeliveryEstimate.DeliveryProvider as number,
      name: rawDeliveryEstimate.DeliveryProviderDescription as string,
    },
    locationId: (rawDeliveryEstimate.StoreId
      ? String(rawDeliveryEstimate.StoreId)
      : undefined) as string,
    bestDeliveryTime: safeDate(rawDeliveryEstimate.BestDeliveryTime) as string,
    surcharges: (rawDeliveryEstimate.Surcharges || []).map(processSurcharge),
    minimumOrderInformation,
    orderingProviderSurchargeInstructions,
    timeEstimateReturned: safeDate(moment().format()) as string,
    bestEstId: rawDeliveryEstimate.BestEstID,
  };
}

import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'enableStockPolling',
  initialState: false,
  reducers: {
    setEnableStockPolling(state, action) {
      return Boolean(action.payload);
    },
  },
});

export const { setEnableStockPolling } = slice.actions;

export default slice.reducer;

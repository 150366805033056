import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: '$enableFutureOrdering',
  initialState,
  reducers: {
    setFutureOrdering(state, action: PayloadAction<boolean>) {
      return Boolean(action.payload);
    },
  },
});

export const { setFutureOrdering } = slice.actions;
export default slice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'oauthExtraFields',
  initialState: [] as ProfileFields[],
  reducers: {
    setOauthExtraFields: (_, action: PayloadAction<ProfileFields[]>) => {
      return action.payload;
    },
  },
});

export const { setOauthExtraFields } = slice.actions;
export default slice.reducer;

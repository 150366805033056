import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { logScreen } from '../utils/analytics';

const CanaryCurrentScreen: React.FC = () => {
  let location = useLocation();

  useEffect(() => {
    logScreen(location.pathname);
  }, [location]);

  return null;
};

export default CanaryCurrentScreen;

import { createSelector } from 'reselect';

import getUserLocation from './getUserLocation';
import { RootState } from 'store';

const getFallbackLocation = (state: RootState) => state.config.fallbackLocation;

export default createSelector(
  [getFallbackLocation, getUserLocation],
  (fallbackLocation, current) => {
    return current === fallbackLocation;
  },
);

import { createSelector } from 'reselect';

import getStagedPurchasesValidity from './getStagedPurchasesValidity';
import getStagedPurchaseCount from './getStagedPurchaseCount';
import getSelectedOfferValid from './getSelectedOfferValid';
import getSaleType from './getSaleType';
import getTable from './getTable';
import getPurchaserInvalidReasons from './getPurchaserInvalidReasons';
import getMinimumOrderInformation from './getMinimumOrderInformation';
import { getEnableDineInNameMode } from './config';

import { ORDER_NOT_READY_REASON } from '../constants';
import { SALE_TYPE } from '../constants/saleType';

import getSelectedOfferMiniSpendingDiffTotalPrice from './getSelectedOfferMiniSpendingDiffTotalPrice';
import getMinimumSurchargeAmount from './getMinimumSurchargeAmount';
import getPickupTime from './getPickupTime';

export default createSelector(
  [
    getPurchaserInvalidReasons,
    getStagedPurchasesValidity,
    getStagedPurchaseCount,
    getSelectedOfferValid,
    getSaleType,
    getTable,
    getMinimumOrderInformation,
    getEnableDineInNameMode,
    getSelectedOfferMiniSpendingDiffTotalPrice, //offer minimum value validate
    getMinimumSurchargeAmount,
    getPickupTime,
  ],
  (
    purchaserReasons,
    stagedPurchasesValidity,
    stagedPurchaseCount,
    selectedOfferValid,
    saleType,
    table,
    minimumOrderInformation,
    enableDineInNameMode,
    offerMiniValueValid,
    miniSurchargeAmount,
    pickupTime,
  ) => {
    const reasons: ORDER_NOT_READY_REASON[] = [...purchaserReasons];

    if (!stagedPurchasesValidity) {
      reasons.push(ORDER_NOT_READY_REASON.PURCHASE_INVALID);
    }

    if (!stagedPurchaseCount) {
      reasons.push(ORDER_NOT_READY_REASON.EMPTY_CART);
    }

    if (selectedOfferValid === false) {
      reasons.push(ORDER_NOT_READY_REASON.OFFER_INVALID);
    }

    if (saleType === SALE_TYPE.DINE_IN && !table) {
      reasons.push(ORDER_NOT_READY_REASON.TABLE_REQUIRED);
    }

    if (saleType === SALE_TYPE.TABLE_ORDER && !table) {
      reasons.push(ORDER_NOT_READY_REASON.TABLE_REQUIRED);
    }

    if (saleType === SALE_TYPE.CATERING && !pickupTime) {
      reasons.push(ORDER_NOT_READY_REASON.PICKUP_TIME_REQUIRED);
    }

    if (miniSurchargeAmount > 0 && !minimumOrderInformation?.surchargeEnabled) {
      reasons.push(ORDER_NOT_READY_REASON.MINIMUM_ORDER_NOT_MET);
    }
    if (selectedOfferValid === true && offerMiniValueValid > 0) {
      reasons.push(ORDER_NOT_READY_REASON.OFFER_MINIMUM_NOT_MET);
    }

    return reasons;
  },
);

export function getWidth() {
  const widths = [window.innerWidth];

  if (window.screen?.width) {
    widths.push(window.screen?.width);
  }

  return Math.min(...widths);
}

export function getHeight() {
  const heights = [window.innerHeight];

  if (window.screen?.height) {
    heights.push(window.screen?.height);
  }

  return Math.min(...heights);
}

export function getDimensions() {
  return {
    height: getHeight(),
    width: getWidth(),
  };
}

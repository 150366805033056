import { createSelector } from 'reselect';

import { MEMBER_PAYMENT_METHODS } from '../constants';

import getEnabledPaymentMethods from './getEnabledPaymentMethods';

export default createSelector(
  [getEnabledPaymentMethods],
  (enabledPaymentMethods): boolean =>
    MEMBER_PAYMENT_METHODS.some(method =>
      enabledPaymentMethods.includes(method),
    ),
);

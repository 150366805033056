export default function parseDojoReceipt(receipt: DojoReceipt) {
  let writeBuffer = '';
  let currentLine = '';
  receipt.forEach((item: dojoReceiptLine, index: number) => {
    const formattedItem = item.value.split(':');
    currentLine = item.value;
    if (formattedItem.length > 1) {
      const replacePounds = formattedItem[1].replace('£', 'GBP ');
      currentLine = `${formattedItem[0]}${' '.repeat(
        40 - formattedItem[0].length - replacePounds.length,
      )}${replacePounds}`;
    } else {
      const currentLineLowerCase = currentLine.toLowerCase();
      if (
        currentLineLowerCase.includes('amount') ||
        currentLineLowerCase.includes('gratuity') ||
        currentLineLowerCase.includes('cashback') ||
        currentLineLowerCase.includes('total')
      ) {
        // What is the actual format of this when it comes from dojo?
        // Why is this needed?
        // Could probably be a string split job instead of a string character in place operation
        let fixUpCurrentLine = currentLineLowerCase.replace(/\s\s+/g, ',');
        fixUpCurrentLine = fixUpCurrentLine.replace('£', ' GBP ');
        const formattedLine = fixUpCurrentLine.split(',');
        let lineText = formattedLine[0];
        const firstLetter = lineText.slice(0, 1);
        lineText =
          firstLetter.toUpperCase() + lineText.slice(1, lineText.length);
        currentLine = `${lineText}${' '.repeat(
          40 - lineText.length - formattedLine[1].length,
        )}${formattedLine[1]}`;
      }
    }
    writeBuffer += `${currentLine}${
      index === receipt.length - 1 ? '' : '\r\n'
    }`;
  });
  console.log(writeBuffer);
  return writeBuffer;
}
